// Libraries
import styled from 'styled-components'
import { theme } from 'lib/styled-components/theme'

// Components
import { Body } from 'heeds-ds'

type CalendarProps = {
  longerCalendar?: boolean
  startTime?: number
  endTime?: number
  weekHeight?: number
  distance?: number
  show?: boolean
  active?: boolean
  minutes?: number
  fullScreen?: boolean
  outlined?: boolean
  margin?: string
  display?: boolean
  timeListLength?: number
  saved?: boolean
  leftMargin?: number
  timePosition?: number
  sameTime?: boolean
  leftSpace?: boolean
  noMargin?: boolean
  AvatarCard?: boolean
  firstAvatar?: boolean
  borderRadius?: string
}

export const Container = styled.div<CalendarProps>`
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background: ${({ theme }) => theme.colors.surface.default};
  box-shadow: -1px 0px 20px rgba(23, 24, 24, 0.05),
    0px 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
`

export const CalendarContainer = styled.div`
  width: 100%;
  position: relative;
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 16px 29px;
  border-radius: 24px 24px 0 0;
  background-color: ${({ theme }) => theme.colors.surface.default};
  button {
    padding: 8px 16px;
  }
`

export const Title = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

export const CalendarBody = styled.div`
  display: flex;
  width: 100%;
`

export const WeekDays = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  background-color: ${({ theme }) =>
    theme.colors.surface.highlightSubduedHovered};
  padding-left: 55px;
  p {
    margin: 6px 0px;
    text-align: center;
    width: calc(100% / 7);
  }
`

export const WeekTop = styled.div`
  display: flex;
  position: absolute;
  top: 20px;
  right: 0;
  padding: 8px 5px 0 57px;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 24;
  p {
    text-align: left;
    padding-left: 8px;
  }
`

export const Days = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px 0 55px;
  width: calc(100% / 7);
`

export const Day = styled.div`
  width: 100%;
`

export const NextPreviousButton = styled.div`
  cursor: pointer;
`

export const TimeColumn = styled.div<CalendarProps>`
  border-left: 1px solid ${({ theme }) => theme.colors.border.input};
  border-right: 1px solid ${({ theme }) => theme.colors.border.input};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: ${({ fullScreen }) =>
    fullScreen ? '0 0 24px 24px' : '0 0 15px 15px'};
  display: flex;
  height: 520px;
  overflow-y: auto;
  position: relative;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    margin: 25px 0;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.icon.hovered};
    border-radius: 6px;
  }
`

export const TimeList = styled.div`
  padding-top: 28px;
  width: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
`

export const AthletesTime = styled.div<CalendarProps>`
  width: calc(100% - 55px);
  height: calc(51.8px * ${({ weekHeight }) => weekHeight});
  display: flex;
  scroll-margin-inline-start: 200px;
  position: absolute;
  left: 55px;
`

export const CalendarBodyInner = styled.div`
  width: 100%;
`

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-right: 24px;
`

export const DisplayTypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`

export const MoreContainer = styled.div`
  bottom: 2px;
  left: 8px;
  z-index: 22;
  :hover {
    display: none;
  }
`

export const AthleteNameAndTime = styled.div`
  z-index: 20;
`

export const AthleteNameCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px 8px;
  gap: 4px;
  width: 100%;
  height: 16px;
  background: ${({ theme }) => theme.colors.surface.selectedHovered};
  border-left: 2px solid ${({ theme }) => theme.colors.text.secondary};
  border-radius: 0px 4px 4px 0px;
  margin-bottom: 4px;
`

export const AthleteListContainer = styled.div`
  margin-top: 24px;
  overflow-y: scroll;
  height: 90%;
  ::-webkit-scrollbar {
    width: 6px;
    margin-left: 16px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.icon.hovered};
    border-radius: 6px;
  }
`

export const CardTimes = styled.div<CalendarProps>`
  display: ${({ display }) => (display ? 'block' : 'none')};
  position: absolute;
  background: ${({ theme }) => theme.colors.surface.default};
  box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.3);
  border-radius: 24px;
  height: 511px;
  padding: 24px;
  min-width: 338px;
  z-index: 33;
  right: ${({ distance }) =>
    distance === 3
      ? '0'
      : distance === 4
      ? '0'
      : distance === 5
      ? '0'
      : distance === 6
      ? '0'
      : ''};
  left: ${({ distance }) => (distance === 0 && 1 && 2 ? '0' : 'auto')};
  margin-left: ${({ distance }) =>
    distance === 0
      ? 'calc((100%/7) + 80px)'
      : distance === 1
      ? 'calc((100%/7) * 2 + 75px)'
      : distance === 2
      ? 'calc((100%/7) * 3 + 70px)'
      : '0'};
  margin-right: ${({ distance }) =>
    distance === 3
      ? 'calc((100%/7) * 4 )'
      : distance === 4
      ? 'calc((100%/7) * 3 + 10px)'
      : distance === 5
      ? 'calc((100%/7) * 2 + 20px)'
      : distance === 6 && 'calc((100%/7 + 30px))'};
  bottom: 10px;
`

export const IconWrapper = styled.div`
  cursor: pointer;
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 5px;
`

export const DayCardTop = styled.div`
  display: flex;
  align-items: center;
`

export const DayCard = styled.div<CalendarProps>`
  width: 95%;
  background: ${({ saved, theme }) =>
    saved
      ? theme.colors.surface.selectedHovered
      : theme.colors.surface.criticalSubdued};
  border-left: 2px solid
    ${({ saved, theme }) =>
      saved ? theme.colors.text.secondary : theme.colors.text.critical};
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: ${({ noMargin }) => (noMargin ? '0 16px' : '0 16px 16px 16px')};
  margin-bottom: ${({ noMargin }) => (noMargin ? -1 : 14)}px;
`

export const DayColumn = styled.div`
  scroll-margin-inline-start: 200px;
  position: relative;
  width: calc(100% / 7);
  border-left: 1px solid ${({ theme }) => theme.colors.border.input};
  border-right: 1px solid ${({ theme }) => theme.colors.border.input};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.input};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
`

export const HourLine = styled.div<CalendarProps>`
  position: absolute;
  display: ${({ show }) => (show ? 'block' : 'none')};
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.border.input};
  margin-top: 35px;
  top: ${({ distance }) => distance && distance * 51.932}px;
  left: 0;
  ::after {
    content: '';
    position: absolute;
    width: 100%;
    top: ${({ minutes }) => minutes}px;
    height: ${({ active }) => active && 2}px;
    background-color: ${({ theme }) => theme.colors.text.secondary};
    z-index: 30;
  }
  ::before {
    content: '';
    position: absolute;
    top: calc(${({ minutes }) => minutes}px - 4px);
    left: -6px;
    height: ${({ active }) => active && 10}px;
    width: ${({ active }) => active && 10}px;
    background-color: ${({ theme }) => theme.colors.text.secondary};
    border-radius: 100px;
    z-index: 30;
  }
`

export const TimeCard = styled.div<CalendarProps>`
  position: absolute;
  width: ${({ leftSpace, timePosition }) =>
    timePosition && leftSpace ? 95 - 5 * (timePosition / 2) : 95}%;
  margin-left: ${({ leftSpace, timePosition }) =>
    timePosition && leftSpace ? 5 * (timePosition / 2) : 0}px;
  height: ${({ startTime, endTime, timeListLength }) =>
    startTime && endTime && endTime === 1
      ? timeListLength && (timeListLength - startTime) * 51.932
      : endTime && startTime && (endTime - startTime) * 51.932}px;
  top: ${({ startTime }) => startTime && startTime * 51.932 - 16}px;
  background: ${({ saved, theme }) =>
    saved
      ? theme.colors.surface.selectedHovered
      : theme.colors.surface.criticalSubdued};
  border-left: 2px solid
    ${({ saved, theme }) =>
      saved ? theme.colors.text.secondary : theme.colors.text.critical};
  border-radius: 0px 4px 4px 0px;
  z-index: ${({ timePosition }) => timePosition && timePosition + 10};
  box-shadow: 0px 3.5px 5.5px
    ${({ leftSpace }) =>
      leftSpace ? 'rgba(0, 0, 0, 0.08)' : 'rgba(0, 0, 0, 0)'};
`

export const AvatarContainer = styled.div<CalendarProps>`
  display: flex;
  position: relative;
  height: ${({ AvatarCard }) => (AvatarCard ? 20 : 40)}px;
  width: ${({ AvatarCard }) => (AvatarCard ? 0 : 100)}%;
`

export const AvatarInner = styled.div<CalendarProps>`
  position: absolute;
  left: ${({ leftMargin }) => leftMargin && leftMargin * 16}px;
`

export const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0 4px 8px;
`

export const TimeCardWrapper = styled.div<CalendarProps>`
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: ${({ saved, leftSpace }) => (saved ? 20 : leftSpace ? 30 : 10)};
`

export const EllipsisText = styled(Body).attrs(() => ({
  type: 'copy4',
  weight: '800',
  color: theme.colors.text.secondary,
  margin: '16px 0 4px 8px',
}))`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
