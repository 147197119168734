// Models
import IStore from './models'

// Libraries
import { PersistConfig, persistReducer } from 'redux-persist'
import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga'
import persistStore from 'redux-persist/es/persistStore'
import storage from 'redux-persist/es/storage'

// Misc
import appReducer from './reducers'
import sagas from './sagas'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const persistConfig: PersistConfig<IStore> = {
  key: 'state',
  storage,
  whitelist: ['auth', 'profile', 'workoutModel'],
}

const persistedReducer = persistReducer(persistConfig, (state, action) => {
  if (action.type === 'APP/RESET') {
    state = undefined
  }

  return appReducer(state, action)
})

const sagaMiddleware: SagaMiddleware = createSagaMiddleware()

const composeEnhancers: typeof compose =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
)

sagaMiddleware.run(sagas)

export const persistor = persistStore(store)

export default store
