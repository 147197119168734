// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body } from 'heeds-ds'

export const Container = styled(Aligner).attrs({
  flex: 'row',
  gap: '0.8rem',
  padding: '2.4rem 1.6rem',
  align: 'center',
})`
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 1.5rem;
  box-shadow: 0px 2px 5px 0px rgba(43, 42, 99, 0.12);
  width: auto;
  border: 1px solid #ededed;
  margin: 8px 24px;
`

export const Creator = styled(Body).attrs(({ theme }) => ({
  color: theme.colors.text.subdued,
  type: 'copy3',
  weight: 400,
}))``

export const Title = styled(Body).attrs(({ theme }) => ({
  color: theme.colors.text.secondary,
  type: 'copy3',
  weight: 600,
}))``

export const TitlesContainer = styled(Aligner).attrs({
  flex: 'column',
  gap: '0.8rem',
})``

export const OptionsContainer = styled.div`
  margin: 0 0 0 auto;
`
