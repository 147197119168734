// Libraries
import styled from 'styled-components'

export const Label = styled.label`
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.fontSize.copy4};
  font-weight: 600;
  cursor: pointer;
`
