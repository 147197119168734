// Libraries
import { conformToMask } from 'react-text-mask'

// Misc
import { maskCollection } from 'heeds-ds/src/utils/mask'

export const birthDateMask = (value: string): string => {
  return value
    ?.replace(/\D+/g, '')
    ?.replace(/^(\d{2})(\d)/, '$1/$2')
    ?.replace(/^(\d{2}\/\d{2})(\d)/, '$1/$2')
    ?.replace(/^(\d{2}\/\d{2}\/\d{4})\d+?$/, '$1')
}

export const cepMask = (value: string): string => {
  return value
    .replace(/\D+/g, '')
    .replace(/^(\d{0,5})$/g, '$1')
    .replace(/^(\d{5})(\d{1,3})$/g, '$1-$2')
    .replace(/^(\d{5})(\d{3})\d+?$/, '$1-$2')
}

export const crefMask = (value: string) => {
  return value
    .replace(/^(\d{0,6})$/i, '$1')
    .replace(/^(\d{6})(\d)$/i, '$1')
    .replace(/^(\d{0,5})([a-z]|[!"#$%&'()*+,-./:;?@[\\\]_`{|}~])+$/i, '$1')
    .replace(/^(\d{0,6})([!"#$%&'()*+,./:;?@[\\\]_`{|}~])+$/, '$1')
    .replace(/^(\d{6})([a-z])$/i, '$1-$2')
    .replace(/^(\d{6}-)([a-z])$/i, '$1$2')
    .replace(/^(\d{6}-)(\d|[!"#$%&'()*+,-./:;?@[\\\]_`{|}~])$/i, '$1')
    .replace(/^(\d{6}-[a-z])(\w|[!"#$%&'()*+,-./:;?@[\\\]_`{|}~])+$/i, '$1')
    .replace(/^(\d{6}-[a-z])([a-z]{1,2})$/i, '$1/$2')
}

export function currencyMask(value: string) {
  return conformToMask(value, maskCollection.currency.mask).conformedValue
}

export const onlyLettersMask = (value: string): string => {
  return value?.replace(/\d+|^\s+$/g, '')?.replace(/\s+/g, ' ')
}

export const phoneMask = (value: string): string => {
  return value
    ?.replace(/\D+/g, '')
    ?.replace(/(\d)/, '($1')
    ?.replace(/(\()(\d{2})/, '$1$2')
    ?.replace(/(\()(\d{2})(\d)/, '$1$2) $3')
    ?.replace(/(\))(\d{1,5})/, '$1 $2')
    ?.replace(/(\d{5})(\d{1,4})/, '$1-$2')
    ?.replace(/(-\d{4})\d+?$/, '$1')
}

export const timeMask = (value: string): string => {
  return value
    ?.replace(/[^\d:]/, '')
    ?.replace(/^[^0-2]/, '')
    ?.replace(/^([2-9])[4-9]/, '$1')
    ?.replace(/^(\d)[:]/, '$1')
    ?.replace(/^(\d{2})([0-5])/, '$1:$2')
    ?.replace(/^(\d{2}[:])[^0-5]/, '$1')
    ?.replace(/^([01][0-9]|2[0-3])[^:]/, '$1')
    ?.replace(/^(\d{2}:[0-5])[^0-9]/, '$1')
    ?.replace(/^(\d{2}:\d[0-9])./, '$1')
}

export const timeRestrictedFormatMask = (value: string): string => {
  return value
    ?.replace(/\D/, '')
    ?.replace(/[^\dh]/, '')
    ?.replace(/^[^0-2]/, '')
    ?.replace(/^([2-9])[4-9]/, '$1')
    ?.replace(/^(\d)[h]/, '$1')
    ?.replace(/^(\d{2})([03])/, '$1h$2')
    ?.replace(/^(\d{2}[h])[^03]/, '$1')
    ?.replace(/^([01][0-9]|2[0-3])[^h]/, '$1')
    ?.replace(/^(\d{2}h[03])[^0]/, '$1')
    ?.replace(/^(\d{2}h[03][0])\d+/, '$1')
}
