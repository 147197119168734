// Models
import { IAthleteSchedule, ScheduleId } from 'services/athleteSchedule/@types'
import { IAppointmentTime, IRecurringTimes } from 'storage/profile/models'
import { IFormFields } from '.'
import {
  IPostSchedulesFormattedPayload,
  ISchedule,
} from 'storage/athleteSchedule/models'
import { TWeekdays } from 'models'

// Libs
import { UseFormReset } from 'react-hook-form'

type ConflictItem = {
  day: TWeekdays
  id: ScheduleId
}

interface TimeRange {
  start: string
  end: string
}

const _getKeysWithTrueValue = (obj: IRecurringTimes): string[] =>
  Object.keys(obj).filter((key) => obj[key as keyof IRecurringTimes] === true)

/*
 * Finding conflicts on recurring mode
 */
function _hasTimeConflict(
  timeRange1: TimeRange,
  timeRange2: TimeRange,
): boolean {
  const startDate1 = new Date(`2000-01-01T${timeRange1.start}:00`)
  const endDate1 = new Date(`2000-01-01T${timeRange1.end}:00`)
  const startDate2 = new Date(`2000-01-01T${timeRange2.start}:00`)
  const endDate2 = new Date(`2000-01-01T${timeRange2.end}:00`)

  return startDate1 < endDate2 && endDate1 > startDate2
}

export const findRecurringScheduleConflicts = (
  personalSchedule: IAthleteSchedule[],
  formSchedules: Pick<IAppointmentTime, 'days' | 'start' | 'end'>,
) => {
  const conflicts: ConflictItem[] = []

  const coincidingTimes = personalSchedule.filter(
    (schedule) =>
      schedule.start &&
      schedule.end &&
      _hasTimeConflict(formSchedules, {
        start: schedule.start.slice(0, 5),
        end: schedule.end.slice(0, 5),
      }),
  )
  coincidingTimes.forEach(({ id, recurring_schedule }) => {
    if (id && formSchedules.days && recurring_schedule) {
      formSchedules.days
        .filter((day) => recurring_schedule[day])
        .forEach((day) => conflicts.push({ day, id }))
    }
  })

  return conflicts
}

/*
 * Finding conflicts on custom mode
 */
function _getConflictingDays(
  personalSchedule: IAthleteSchedule,
  formSchedules: IAppointmentTime,
): TWeekdays[] | null {
  if (!personalSchedule.recurring_schedule) return null
  if (
    !formSchedules.start ||
    !formSchedules.end ||
    !personalSchedule.start ||
    !personalSchedule.start
  )
    return null

  const startDate1 = new Date(`2000-01-01T${formSchedules.start}:00`)
  const endDate1 = new Date(`2000-01-01T${formSchedules.end}:00`)
  const startDate2 = new Date(
    `2000-01-01T${personalSchedule.start?.slice(0, 5)}:00`,
  )
  const endDate2 = new Date(
    `2000-01-01T${personalSchedule.end?.slice(0, 5)}:00`,
  )

  if (startDate1 >= endDate2 || endDate1 <= startDate2) {
    return null
  }

  // Check if any day in personalSchedule's recurring_schedule matches the day in formSchedules
  return _getKeysWithTrueValue(personalSchedule.recurring_schedule).filter(
    (day) => day === formSchedules.day,
  ) as TWeekdays[]
}

export const findScheduleConflicts = (
  personalSchedule: IAthleteSchedule[],
  formSchedules: IFormFields,
) => {
  if (!personalSchedule.length) return

  const conflicts: ConflictItem[] = []
  const formSchedulesArray = Object.values(formSchedules)

  personalSchedule.forEach((pSchedule) => {
    formSchedulesArray.forEach((fSchedule) => {
      const conflictingDays = _getConflictingDays(pSchedule, fSchedule)
      if (fSchedule && conflictingDays) {
        conflictingDays.forEach((day) => {
          conflicts.push({ day, id: pSchedule.id ?? 0 })
        })
      }
    })
  })

  return conflicts
}

/*
 * Function to reset the hook-form when schedules are loaded from api
 */
export const resetLoadedSchedules = (
  reset: UseFormReset<IFormFields>,
  schedules?: ISchedule[],
) => {
  if (!schedules) return

  const firstSchedule = schedules[0]
  const recurringDays = firstSchedule.recurringSchedule
  const isRecurrent = recurringDays && recurringDays.length > 2

  const formFields: IFormFields = {
    isRecurrent,
    allDays: isRecurrent
      ? {
          days: firstSchedule.recurringSchedule,
          end: firstSchedule.end,
          start: firstSchedule.start,
        }
      : undefined,
  }
  schedules.forEach(({ end = '', start = '', day, recurringSchedule }) => {
    if (isRecurrent) {
      recurringSchedule?.forEach((day) => {
        formFields[day] = {
          day,
          end,
          start,
        }
      })
    } else if (day) {
      formFields[day] = {
        day,
        end,
        start,
      }
    }
  })

  reset(formFields)
}

/*
 * Function to reset the hook-form when schedules are loaded from api
 */
export const formatSubmitPayload = (
  formdata: IFormFields,
  callback: (data: IPostSchedulesFormattedPayload) => void,
) => {
  const { allDays, isRecurrent, location, ...weekdays } = formdata

  if (isRecurrent) {
    if (!allDays?.days?.length || !allDays?.end || !allDays.end) return

    return callback({
      location,
      schedules: [
        {
          recurringSchedule: allDays.days,
          start: allDays.start,
          end: allDays.end,
        },
      ],
    })
  } else {
    const days = Object.values(weekdays).flatMap((day) => {
      if (!day.day) return []

      return {
        recurringSchedule: [day.day],
        start: day.start,
        end: day.end,
      }
    })

    return callback({
      location,
      schedules: days,
    })
  }
}
