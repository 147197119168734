// Models
import { Props as ButtonProps } from 'heeds-ds/src/components/Button/Button'

//React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { useMediaQuery } from 'hooks'

// Components
import * as Styled from '../styled'

type buttonProps = ButtonProps & {
  name: string
}

type Props = {
  onClose?: () => void
  primaryButton?: buttonProps
  secondaryButton?: buttonProps
}

const Footer: FC<Props> = ({
  onClose,
  primaryButton,
  secondaryButton,
}: Props) => {
  const { breakpoints } = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)

  const buttonSize = isDesktop ? 'xsmall' : 'small'

  return (
    <Styled.Footer>
      {secondaryButton && (
        <Styled.SecondaryButton
          onClick={onClose}
          size={buttonSize}
          {...secondaryButton}
        >
          {secondaryButton?.name}
        </Styled.SecondaryButton>
      )}
      {primaryButton && (
        <Styled.PrimaryButton size={buttonSize} {...primaryButton}>
          {primaryButton?.name}
        </Styled.PrimaryButton>
      )}
    </Styled.Footer>
  )
}

export default Footer
