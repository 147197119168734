// React
import { FC } from 'react'

// Components
import { Circle, Container, Text } from './styled'

// Constants
const defaultColors = ['#4361ee', '#4cc9f0', '#4895ef', '#3f37c9']

type CircleChartProps = {
  items?: Item[]
  maxValue?: number
  rounded?: boolean
  showPercent?: boolean
  showTotal?: boolean
  size?: number
  totalFontSize?: string
  totalTextColor?: string
  trackColor?: string
  trackWidth?: number
  unity?: string
}

type CircleItemProps = {
  color: string
  offSet: number
  rounded: boolean
  trackWidth: number
  value: number
}

interface Item {
  color?: string
  displayValue?: string
  label: string
  value: number
}

const CircleItem = ({
  color,
  offSet,
  rounded,
  trackWidth,
  value,
}: CircleItemProps) => {
  const dashArr = (value: number): string => {
    let adjustPercent = value
    if (rounded) {
      adjustPercent = value === 100 ? value : value >= 2 ? value - 1 : 0
    }
    return `${adjustPercent} ${100 - adjustPercent}`
  }

  return (
    <Circle
      data-testid={`item-${value}`}
      dashArray={dashArr(value)}
      dashOffset={offSet}
      strokeLinecap={rounded ? 'round' : 'inherit'}
      style={{ transition: 'stroke 1s ease-in-out' }}
      trackColor={color}
      trackWidth={trackWidth}
    />
  )
}

const CircleChart: FC<CircleChartProps> = (props: CircleChartProps) => {
  const {
    items = [{ value: 10, label: 'Example' }],
    maxValue = 100,
    rounded = true,
    showPercent = true,
    showTotal = true,
    size = 220,
    totalFontSize = '18px',
    totalTextColor = 'black',
    trackColor = '#141517',
    trackWidth = 5,
    unity,
  } = props
  let currPercentTotal = 0
  let currUnitTotal = 0

  const vb = '0 0 40 40'

  return (
    <Container data-testid="circle-chart">
      <svg width={size} height={size}>
        <svg viewBox={vb}>
          <Circle
            data-testid="empty-circle"
            stroke={trackColor}
            strokeWidth={trackWidth}
          />
          {items.map((item: Item, index) => {
            const offSet = 25 - currPercentTotal
            const percentValue = (item.value * 100) / maxValue
            currPercentTotal += percentValue
            currUnitTotal += item.value

            return (
              <CircleItem
                color={
                  item?.color || defaultColors[index % defaultColors.length]
                }
                // data-testid={'circle-' + index}
                key={index}
                offSet={offSet}
                rounded={rounded}
                trackWidth={trackWidth}
                value={percentValue}
              />
            )
          })}
        </svg>
        {showTotal && (
          <Text
            data-testid="total"
            dominantBaseline="middle"
            fill={totalTextColor}
            fontSize={totalFontSize}
            x="50%"
            y="50%"
          >
            {showPercent ? `${currPercentTotal}%` : `${currUnitTotal}${unity}`}
          </Text>
        )}
      </svg>
    </Container>
  )
}

export default CircleChart
