// Libraries
import styled from 'styled-components'

export const Container = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    display: grid;
    grid-template-columns: 9.2rem 1fr;
    grid-gap: 0.2rem;
    background-color: white;
  }
`

export const Content = styled.div<{ showHeader?: boolean }>`
  width: 100%;
  height: ${({ showHeader }) =>
    showHeader ? 'calc(100vh - 9.3rem)' : '100vh'};
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.background.default};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    height: 100vh;
  }
`
