// Models
import { FieldType } from 'components/WorkoutSetFormTag'
import { IExerciseData } from 'storage/exercise/models'
import {
  IFormInputs,
  IModelInput,
  IWorkoutSetInput,
  TFormValues,
  TSetSpecialSet,
  TSpecialSetInput,
} from 'models'

// React
import { FC, useCallback, useMemo } from 'react'

// Libraries
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { generatePath, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'

// Misc
import { prepareModelsToRedux } from 'filters/workouts'
import { success } from 'storage/workoutModel/duck'
import { urls } from 'routes/paths'

// Components
import * as Styled from './styled'
import { WorkoutSets } from 'components'
import WorkoutModelHeader from 'components/WorkoutModelHeader'

interface ISetToExerciseFormInputs {
  cadence: string
  repetition: string
  weight: string
}

export interface ISpecialSetFormInputs {
  exercises: ISetToExerciseFormInputs[]
  comments: string
  interval: string
  quantity: string
}

type Props = {
  addOrRemoveFieldFromWorkoutSetToExercise: (
    modelId: string,
    workoutSetId: string,
    exerciseIndex: number,
    field: FieldType,
    add?: boolean,
  ) => void
  addWorkoutModel: (model: IModelInput) => void
  addWorkoutSet: (modelId: string, set: IWorkoutSetInput) => void
  duplicateWorkoutModel: () => void
  headerVisible?: boolean
  methods: UseFormReturn<IFormInputs, TFormValues>
  openExerciseGifModal: (exercise: IExerciseData) => void
  openWorkoutModelTemplateModal?: () => void
  removeExerciseFromWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    exerciseIndex: number,
  ) => void
  removeWorkoutModel: () => void
  removeWorkoutSet: (modelId: string, workoutSetId: string) => void
  renameWorkoutModel: () => void
  replicateWorkoutSetFields: (modelId: string, set: IWorkoutSetInput) => void
  selectWorkoutModel: (index: number) => void
  selectedModelIndex: number
  setSpecialSet: TSetSpecialSet
  specialSet?: TSpecialSetInput
  updateWorkoutModel: (modelId: string, updatedModel: IModelInput) => void
  updateWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    updatedWorkoutSet: IWorkoutSetInput,
  ) => void
  stateModels: IModelInput[]
}

const WorkoutModels: FC<Props> = ({
  addWorkoutModel,
  duplicateWorkoutModel,
  headerVisible = true,
  methods,
  removeWorkoutModel,
  removeWorkoutSet,
  renameWorkoutModel,
  selectWorkoutModel,
  selectedModelIndex,
  setSpecialSet,
  specialSet,
  stateModels,
  ...props
}) => {
  const { id = '', routine_id = '' } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const renderForm = useMemo(
    () =>
      stateModels.map((field, index) => (
        <Styled.ShowList
          key={field.id}
          isVisible={index === selectedModelIndex}
        >
          <WorkoutSets
            {...props}
            formIndex={index}
            onEditSpecialSet={() => undefined}
            removeWorkoutSet={removeWorkoutSet}
            selectedModelIndex={selectedModelIndex}
            setSpecialSet={setSpecialSet}
            stateModels={stateModels}
            specialSet={specialSet}
          />
        </Styled.ShowList>
      )),
    [
      props,
      removeWorkoutSet,
      selectedModelIndex,
      setSpecialSet,
      specialSet,
      stateModels,
    ],
  )

  const onSubmit = useCallback(
    (formData: IFormInputs) => {
      const reduxData = prepareModelsToRedux(formData, stateModels)

      dispatch(success({ workoutModels: reduxData }))

      navigate(generatePath(urls.workoutRoutineReview, { id, routine_id }))
    },
    [dispatch, id, navigate, routine_id, stateModels],
  )

  return (
    <Styled.Container>
      {headerVisible && (
        <WorkoutModelHeader
          addWorkoutModel={addWorkoutModel}
          duplicateWorkoutModel={duplicateWorkoutModel}
          models={stateModels}
          removeWorkoutModel={removeWorkoutModel}
          renameWorkoutModel={renameWorkoutModel}
          selectWorkoutModel={selectWorkoutModel}
          selectedModelIndex={selectedModelIndex}
          specialSet={specialSet}
        />
      )}

      <FormProvider {...methods}>
        <Styled.Form id="sets-form" onSubmit={methods.handleSubmit(onSubmit)}>
          {renderForm}
        </Styled.Form>
      </FormProvider>
    </Styled.Container>
  )
}

export default WorkoutModels
