// Models
import { TAthleteCalendar } from 'models'

// React
import { FC } from 'react'

// Components
import * as Styled from './styled'
import { Aligner, Body } from 'heeds-ds'

type Props = {
  isNew: boolean
  athlete: TAthleteCalendar
}

const AthleteCard: FC<Props> = (props: Props) => {
  const { athlete, isNew } = props

  return (
    <Styled.AthleteNameCard saved={isNew}>
      <Styled.AthleteNameAndTime>
        <Styled.EllipsisText>{athlete.athlete}</Styled.EllipsisText>
        <Aligner>
          <Body type="small" margin="4px 0 0 0">
            {athlete.start} - {athlete.end}
          </Body>
        </Aligner>
      </Styled.AthleteNameAndTime>
    </Styled.AthleteNameCard>
  )
}

export default AthleteCard
