// Models
import { IAthleteInfoState } from 'storage/athleteInfo/models'
import { IFinancialData } from 'models'
import IStore from 'lib/redux/models'

// React
import { FC, useLayoutEffect } from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { athleteModalSendReminderSchema } from 'schemas'
import { buttonClickTracking } from 'utils/tracking'

// Components
import * as Styled from './styled'
import { RadioButton } from 'heeds-ds'
import { Modal } from 'components/modals'

// Constants
const DEFAULT_MESSAGE = `Olá, *nome do aluno*.
    
Passando para te lembrar do pagamento do seu plano no CADU.
          
Qualquer informação que você precisar, entre em contato com a gente que te ajudamos.
          
Ótimo dia para você.`

const DEFAULT_VALUES = {
  notificationType: 'app',
  subject: 'late',
  message: '',
}

interface FormInputs {
  notificationType: string
  subject: string
  message: string
}

type Props = {
  financial?: IFinancialData
  visible: boolean
}

const ModalSendReminder: FC<Props> = ({ visible }) => {
  const { info } = useSelector<IStore, IAthleteInfoState>(
    (state) => state.athleteInfo,
  )

  const methods = useForm<FormInputs>({
    defaultValues: DEFAULT_VALUES,
    delayError: 800,
    mode: 'onChange',
    resolver: yupResolver(athleteModalSendReminderSchema),
    reValidateMode: 'onChange',
  })
  const { register, reset, handleSubmit } = methods

  const onSubmit = (formData: FormInputs) => {
    console.log('🚀 ~ file: index.tsx:69 ~ onSubmit ~ formData:', formData)
    // TODO: criar endpoint para enviar o lembrete
  }

  useLayoutEffect(() => {
    if (visible) {
      reset({
        notificationType: 'app',
        subject: 'late',
        message: DEFAULT_MESSAGE.replace(
          '*nome do aluno*',
          info?.name || '*nome do aluno*',
        ),
      })
    }
  }, [info?.name, reset, visible])

  return (
    <Modal showExit>
      <Styled.Container data-testid="modal-send-reminder-container">
        <Styled.Header>
          <Styled.Title>Enviar lembrete</Styled.Title>
        </Styled.Header>

        <FormProvider {...methods}>
          <Styled.Form onSubmit={handleSubmit(onSubmit)}>
            <Styled.Description>
              Você pode enviar uma mensagem pré-definida para o seu aluno
              diretamente, selecione a forma de envio, o assunto e clique em
              enviar.
            </Styled.Description>

            <Styled.Subtitle>
              Selecione por onde o aluno receberá a notificação
            </Styled.Subtitle>

            <RadioButton
              {...register('notificationType')}
              customLabel={['Notificações do App', 'E-mail', 'Whatsapp']}
              displayError={false}
              gap="1.6rem"
              margin="1.6rem 0 3.2rem"
              options={['app', 'email', 'whats']}
              refName={register('notificationType').ref}
              scale="small"
              textRadio
            />

            <Styled.Subtitle>Selecione o assunto</Styled.Subtitle>

            <RadioButton
              {...register('subject')}
              customLabel={[
                'Pagamento atrasado',
                'Pagamento realizado',
                'Próximo ao vencimento',
              ]}
              displayError={false}
              gap="1.6rem"
              margin="1.6rem 0 5rem"
              options={['late', 'due', 'next']}
              refName={register('subject').ref}
              scale="small"
              textRadio
            />

            <Styled.Subtitle>Corpo da Mensagem</Styled.Subtitle>

            <Styled.BodyMessage
              {...register('message')}
              data-testid="modal-send-reminder-textarea"
              name="message"
            />

            <Styled.SaveButton
              track={buttonClickTracking}
              trackName="send_financial_reminder"
            >
              Enviar Mensagem
            </Styled.SaveButton>
          </Styled.Form>
        </FormProvider>
      </Styled.Container>
    </Modal>
  )
}

export default ModalSendReminder
