import styled from 'styled-components'

type ModalProps = {
  position?: string
  backgroundColor?: string
  visible?: boolean
}

export const Description = styled.div<ModalProps>`
  color: ${({ theme }) => theme.colors.text.subdued};
  font-size: ${({ theme }) => theme.fontSize.copy4};
  line-height: 16px;
`

export const IconContainer = styled.div<ModalProps>`
  align-items: center;
  background: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.colors.surface.highlightSubdued};
  border-radius: 3px;
  display: flex;
  height: 56px;
  justify-content: center;
  min-width: 56px;
`

export const Row = styled.div`
  display: flex;
`

export const TextContainer = styled.div<ModalProps>`
  padding-left: 24px;
`

export const Title = styled.div<ModalProps>`
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${({ theme }) => theme.fontSize.copy1};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`
