// Models
import {
  IAthletePlan,
  ICreateAthletePlans,
  ILoadAthletePlans,
  IUpdateAthletePlans,
} from 'services/athletePlans/@types'
import { IBaseState } from 'lib/redux/models'
import { IPaginatedResponse } from 'models/api'

export enum EAthletePlansActionTypes {
  CLEANUP = 'ATHLETE_PLANS/CLEANUP',
  FAILURE = 'ATHLETE_PLANS/FAILURE',
  FULFILL = 'ATHLETE_PLANS/FULFILL',
  REQUEST = 'ATHLETE_PLANS/REQUEST',
  SUCCESS = 'ATHLETE_PLANS/SUCCESS',
  LOAD_PLANS = 'ATHLETE_PLANS/LOAD_PLANS',
  CREATE_PLANS = 'ATHLETE_PLANS/CREATE_PLANS',
  UPDATE_PLANS = 'ATHLETE_PLANS/UPDATE_PLANS',
}

export interface IAthletePlansState extends IBaseState, IPaginatedResponse {
  plans?: IAthletePlan[]
}

export interface ICreateAthletePlansPayload extends ICreateAthletePlans {
  successCallback?: () => void
}

export interface ILoadAthletePlansPayload extends ILoadAthletePlans {
  successCallback?: () => void
}

export interface IUpdateAthletePlansPayload extends IUpdateAthletePlans {
  successCallback?: () => void
}
