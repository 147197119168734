// Models
import {
  EFinancialStatusToPortuguese,
  EPaymentTypeToPortuguese,
  EPlanTypeToPortuguese,
} from 'models'
import { IFinancialData } from 'models'
import { TMenuDropdownOption } from 'heeds-ds/src/models'

// React
import { FC, useContext } from 'react'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { currencyMask } from 'utils/masks'

// Libraries
import { ThemeContext } from 'styled-components'

// Components
import * as Styled from './styled'
import { DropdownMenu, IconButton } from 'heeds-ds'

type Props = {
  financial: IFinancialData
  onAddPayment: () => void
  onSendReminder: () => void
}

const FinancialRow: FC<Props> = ({
  financial,
  onAddPayment,
  onSendReminder,
}) => {
  const {
    due_date,
    payment_status,
    plan_periodicity,
    plan_value,
    payday,
    payment,
  } = financial
  const theme = useContext(ThemeContext)

  const statusColor = {
    paid: theme.colors.tag.success,
    pendent: theme.colors.tag.critical,
    due_date: theme.colors.tag.warning,
  }
  const dueDateColor = {
    paid: theme.colors.text.default,
    pendent: theme.colors.text.critical,
    due_date: theme.colors.text.default,
  }
  const options: TMenuDropdownOption[] = [
    {
      icon: 'add',
      label: 'Adicionar Pagamento',
      onClick: onAddPayment,
    },
    {
      icon: 'notificationsNone',
      label: 'Enviar Lembrete',
      onClick: onSendReminder,
    },
  ]

  return (
    <Styled.Row data-testid="row">
      <Styled.Cell>
        <Styled.TextMedium data-testid="planTypeColumn">
          {EPlanTypeToPortuguese[plan_periodicity]}
        </Styled.TextMedium>
      </Styled.Cell>

      <Styled.Cell>
        <Styled.TextMedium data-testid="planValueColumn">
          {currencyMask(`${plan_value}`)}
        </Styled.TextMedium>
      </Styled.Cell>

      <Styled.Cell data-testid="name">
        <Styled.TextMedium data-testid="paydayColumn">
          {payday || '-'}
        </Styled.TextMedium>
      </Styled.Cell>

      <Styled.Cell>
        <Styled.TextMedium data-testid="paymentColumn">
          {EPaymentTypeToPortuguese[payment || 'empty']}
        </Styled.TextMedium>
      </Styled.Cell>

      <Styled.Cell>
        <Styled.TextMedium
          color={dueDateColor[payment_status]}
          data-testid="dueDateColumn"
        >
          {due_date}
        </Styled.TextMedium>
      </Styled.Cell>

      <Styled.Cell>
        <Styled.TextBackground status={payment_status}>
          <Styled.TextMedium
            color={statusColor[payment_status]}
            data-testid="statusColumn"
          >
            {EFinancialStatusToPortuguese[payment_status]}
          </Styled.TextMedium>
        </Styled.TextBackground>
      </Styled.Cell>

      <Styled.Cell onClick={(e) => e.stopPropagation()}>
        <DropdownMenu items={options}>
          <IconButton
            iconName="moreVertical"
            size="small"
            track={buttonClickTracking}
            trackName="open_financial_row_options"
          />
        </DropdownMenu>
      </Styled.Cell>
    </Styled.Row>
  )
}

export default FinancialRow
