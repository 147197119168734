// Models
import { FieldType } from '../..'

// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { useMediaQuery } from 'hooks'

// Components
import * as Styled from './styled'

const SPANS: Record<FieldKey, Array<number>> = {
  cadence: [1, 2],
  comment: [3, 2],
  interval: [1, 2],
  quantity: [1, 1],
  repetitions: [1, 1],
  speed: [1, 2],
  time: [1, 2],
  weight: [1, 2],
}

type FieldKey = FieldType | 'quantity' | 'interval'

type InputProps = {
  prefix: string
  readonly?: boolean
}

type Props = InputProps & {
  fixed?: boolean
  onRemove?: () => void
  type: FieldKey
}

const InputCadence: FC<InputProps> = ({ prefix, readonly }) => {
  return (
    <Styled.Input
      label="Cadência"
      mask="cadence"
      name={`${prefix}.cadence`}
      placeholder="0000"
      readOnly={readonly}
    />
  )
}

const InputComment: FC<InputProps> = ({ prefix, readonly }) => {
  return (
    <Styled.Input
      label="Comentários"
      name={`${prefix}.comment`}
      placeholder="Esse espaço tem como função passar orientações para o seu aluno."
      readOnly={readonly}
    />
  )
}

const InputInterval: FC<InputProps> = ({ prefix, readonly }) => {
  const name = `${prefix}.interval`
  return (
    // TODO: quando o backend for atualizado para receber measure, descomentar esta parte do código
    // <Aligner align="flex-end" gap="0.8rem">
    <Styled.Input
      label="Descanso"
      mask="seconds"
      name={`${name}.value`}
      placeholder="0"
      readOnly={readonly}
    />

    // <Styled.SelectOption
    //   disabled={readonly}
    //   name={`${name}.measure`}
    //   options={TIME_MEASUREMENT_OPTIONS}
    //   placeholder="Seg"
    // />
    // </Aligner>
  )
}

const InputQuantity: FC<InputProps> = ({ prefix, readonly }) => {
  return (
    <Styled.Input
      label="Série"
      mask="integer"
      name={`${prefix}.quantity`}
      placeholder="0"
      readOnly={readonly}
    />
  )
}

const InputRepetitions: FC<InputProps> = ({ prefix, readonly }) => {
  return (
    <Styled.Input
      label="Repetição"
      mask="repetitions"
      name={`${prefix}.repetitions`}
      placeholder="0 - 0"
      readOnly={readonly}
    />
  )
}

const InputSpeed: FC<InputProps> = ({ prefix, readonly }) => {
  return (
    <Styled.Input
      label="Velocidade"
      mask="seconds"
      name={`${prefix}.speed`}
      placeholder="0 seg"
      readOnly={readonly}
    />
  )
}

const InputTime: FC<InputProps> = ({ prefix, readonly }) => {
  return (
    <Styled.Input
      label="Tempo"
      mask="seconds"
      name={`${prefix}.time`}
      placeholder="0 seg"
      readOnly={readonly}
    />
  )
}

const InputWeight: FC<InputProps> = ({ prefix, readonly }) => {
  return (
    <Styled.Input
      label="Carga"
      mask="weight"
      name={`${prefix}.weight`}
      placeholder="kg"
      readOnly={readonly}
    />
  )
}

const GenericInput: FC<Props> = ({ type, ...props }) => {
  const { breakpoints } = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)

  const FIELDS: Record<FieldKey, JSX.Element> = {
    cadence: <InputCadence {...props} />,
    comment: <InputComment {...props} />,
    interval: <InputInterval {...props} />,
    quantity: <InputQuantity {...props} />,
    repetitions: <InputRepetitions {...props} />,
    speed: <InputSpeed {...props} />,
    time: <InputTime {...props} />,
    weight: <InputWeight {...props} />,
  }

  return (
    <Styled.FieldContainer span={SPANS[type][isDesktop ? 0 : 1]}>
      {FIELDS[type]}
    </Styled.FieldContainer>
  )
}

export default GenericInput
