// Models
import {
  IGetAthletesPayload,
  IGetAthletesResponse,
} from 'services/athletes/@types'
import { IAction } from 'lib/redux/models'

// Libraries
import { SagaIterator } from 'redux-saga'
import { all, takeLatest } from 'redux-saga/effects'
import { call, put } from 'redux-saga/effects'

// Misc
import { getAthletes } from 'services/athletes'
import {
  failure,
  fulfill,
  request,
  success,
  successLoadMore,
  Types,
} from '../duck'

export function* loadAthletesSaga(action: IAction<IGetAthletesPayload>) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IGetAthletesResponse = yield call(
        getAthletes,
        action.payload,
      )
      yield put(success({ athletes: response }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* loadMoreAthletesSaga(action: IAction<IGetAthletesPayload>) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IGetAthletesResponse = yield call(
        getAthletes,
        action.payload,
      )
      yield put(successLoadMore(response))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export default function* athletesSaga(): SagaIterator {
  yield all([
    takeLatest(Types.GET_ATHLETES, loadAthletesSaga),
    takeLatest(Types.GET_MORE_ATHLETES, loadMoreAthletesSaga),
  ])
}
