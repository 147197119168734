// Models
import { EAnamneseAnswerStatusToPortuguese } from 'models'
import { IAnamneseTemplateState } from 'storage/assessmentAnamneseTemplate/models'
import { IAthleteSendAnamneseTemplate } from 'services/assessmentAnamneseTemplate/@types'
import { FormProvider, useForm } from 'react-hook-form'
import IStore from 'lib/redux/models'

// React
import {
  FC,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { anamneseModalShareTemplateSchema } from 'schemas'
import { buttonClickTracking } from 'utils/tracking'
import { triggerLoadAnamneseTemplatesSent } from 'storage/assessmentAnamneseTemplate/duck'
import { useMediaQuery, useModal } from 'hooks'

// Components
import * as Styled from './styled'
import {
  Aligner,
  Body,
  Button,
  Checkbox,
  CommonList,
  InputSearch,
  LoadingSpinner,
  Subtitle,
  Tag,
} from 'heeds-ds'
import { Modal } from 'components'

interface ISelectedAthlete {
  label: number
  active: boolean
}

export interface IModalNewRoutineInputs {
  selectAll: boolean
  selectedAthlete?: ISelectedAthlete[]
}

type Props = {
  anamneseTemplateId?: string
  onSend: (id: number[]) => void
  width?: string
}

const ModalShareAnamneses: FC<Props> = ({
  anamneseTemplateId,
  onSend,
  width,
}) => {
  const { closeModal } = useModal()
  const { colors, breakpoints } = useContext(ThemeContext)
  const { athletesSendTemplate, loading, error } = useSelector<
    IStore,
    IAnamneseTemplateState
  >(({ assessmentAnamneseTemplate }) => assessmentAnamneseTemplate)
  const dispatch = useDispatch()
  const isTablet = useMediaQuery(`(min-width: ${breakpoints.tablet}px)`)

  const [search, setSearch] = useState('')

  const formMethods = useForm<IModalNewRoutineInputs>({
    resolver: yupResolver(anamneseModalShareTemplateSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    delayError: 800,
  })
  const {
    formState: { isValid },
    handleSubmit,
    setValue,
    watch,
  } = formMethods

  const selectAll = watch('selectAll')

  const athleteList = useMemo(() => {
    return (
      athletesSendTemplate?.send_list.filter((send) =>
        send.name.includes(search),
      ) || []
    )
  }, [athletesSendTemplate?.send_list, search])

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  const onSubmit = (formData: IModalNewRoutineInputs) => {
    const selectedAthletes =
      formData.selectedAthlete?.reduce((acc: number[], value) => {
        if (value.active) return [...acc, value.label]

        return acc
      }, []) || []

    onSend(selectedAthletes)
    closeModal()
  }

  const renderItems = (item: IAthleteSendAnamneseTemplate) => {
    const prefix = `selectedAthlete.${[item.athlete_id]}.active`
    return (
      <Tag
        active={Boolean(watch(`selectedAthlete`)?.[item.athlete_id]?.active)}
        key={item.athlete_id}
        margin={isTablet ? '0 0 0.8rem' : '0'}
      >
        <Styled.AthleteNameContainer>
          <Checkbox id={prefix} name={prefix} />

          <Styled.EllipsisText>{item.name}</Styled.EllipsisText>
        </Styled.AthleteNameContainer>

        <Body type="copy4">
          {EAnamneseAnswerStatusToPortuguese[item.status]}
        </Body>
      </Tag>
    )
  }

  useEffect(() => {
    const allSelected = athletesSendTemplate?.send_list.reduce(
      (accumulator: ISelectedAthlete[], athlete) => {
        accumulator[athlete.athlete_id] = {
          label: athlete.athlete_id,
          active: selectAll,
        }

        return accumulator
      },
      [],
    )

    setValue('selectedAthlete', allSelected)
  }, [athletesSendTemplate?.send_list, selectAll, setValue])

  useLayoutEffect(() => {
    if (
      anamneseTemplateId &&
      !loading &&
      !error &&
      (!athletesSendTemplate || athletesSendTemplate.id !== anamneseTemplateId)
    )
      dispatch(triggerLoadAnamneseTemplatesSent(anamneseTemplateId))
  }, [anamneseTemplateId, athletesSendTemplate, dispatch, error, loading])

  return (
    <Modal onClose={closeModal}>
      <FormProvider {...formMethods}>
        <Styled.Container width={width}>
          <Styled.CardTop>
            <Subtitle type="sh2" weight={600}>
              Lista de Alunos
            </Subtitle>
          </Styled.CardTop>

          <Body type="copy1" color={colors.text.subdued}>
            Selecione o aluno que receberá o formulário.
          </Body>

          <InputSearch
            margin="3.2rem 0 2.4rem"
            onChange={onSearchChange}
            value={search}
          />

          <Aligner justify="flex-end" margin="0 0 32px 0">
            <Checkbox
              id="selectAll"
              label="Selecionar todos"
              name="selectAll"
            />
          </Aligner>

          <Styled.AthleteList>
            {loading ? (
              <LoadingSpinner color={colors.icon.hovered} size={48} />
            ) : (
              <CommonList<IAthleteSendAnamneseTemplate>
                columns={[]}
                data={athleteList}
                hasMore={false}
                height="29rem"
                onEndReached={() => undefined}
                renderItem={renderItems}
                showHeader={false}
              />
            )}
          </Styled.AthleteList>

          <Button
            disabled={!isValid}
            margin="0"
            onClick={handleSubmit(onSubmit)}
            size="small"
            track={buttonClickTracking}
            trackName={`cancel_modal_new_routine`}
            width="100%"
          >
            Enviar
          </Button>
        </Styled.Container>
      </FormProvider>
    </Modal>
  )
}

export default ModalShareAnamneses
