// Models
import { IAuthState } from 'storage/auth/models'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useEffect, useLayoutEffect, useMemo } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Components
import * as Block from 'blocks/auth/AthleteSignupConfirmation'
import * as Styled from './styled'
import { LogoFull, LogoMin } from 'heeds-ds'

// Misc
import { cleanup, registerEmailConfirmation } from 'storage/auth/duck'
import { urls } from 'routes/paths'
import { useMediaQuery } from 'heeds-ds/src/hooks'

// Assets
import backgroundImage from 'assets/images/athlete-signup-background-02.svg'
import logoFull from 'assets/images/logo-cadu-beta.svg'
import logoMini from 'assets/images/logo-cadu-mini.svg'

const AthleteSignupConfirmation: FC = () => {
  const { token } = useParams()
  const { error, userData } = useSelector<IStore, IAuthState>(
    ({ auth }) => auth,
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const renderContent = useMemo(() => {
    if (token === 'email' && !userData?.registered_with_email_from_invite) {
      return <Block.NeedsConfirmation />
    }
    if (token === 'expired') {
      return <Block.ExpiredInvitation />
    }
    if (token === 'acesso') {
      return <Block.RegistrationCompleted />
    }
    if (error) {
      return <Block.ExpiredConfirmation />
    }
    return <Block.SuccessfullyConfirmed />
  }, [error, token, userData?.registered_with_email_from_invite])

  useEffect(() => {
    if (token && !['email', 'expired'].includes(token)) {
      dispatch(cleanup())
      dispatch(registerEmailConfirmation(token))
    }
  }, [dispatch, token])

  useLayoutEffect(() => {
    if (!token) {
      navigate(urls.login)
    }
  }, [navigate, token])

  return (
    <Styled.Container>
      <Styled.Header>
        {isDesktop ? <LogoFull src={logoFull} /> : <LogoMin src={logoMini} />}
      </Styled.Header>

      <Styled.Content>
        <Styled.LayoutImage src={backgroundImage} />
        <Styled.FormContainer>{renderContent}</Styled.FormContainer>
      </Styled.Content>
    </Styled.Container>
  )
}

export default AthleteSignupConfirmation
