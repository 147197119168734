//Models
import { TButtonSizes } from '../../models'

// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import usePagination from '../../hooks/usePagination'

// Custom
import * as Styled from './styled'
import { Aligner } from '../../components/UI/Containers'
import { Body } from '../../components/UI/Typography'
import { Button } from '../../components/Button'
import Icon from '../../components/UI/Icons'

type Props = {
  count: number
  maxLength?: number
  boundaryCount?: number
  siblingCount?: number
  size?: TButtonSizes
}

const Pagination: FC<Props> = (props: Props) => {
  const { maxLength, boundaryCount, count, siblingCount, size } = props
  const { nextPage, setPageParam, pages, previousPage, page } = usePagination(
    count,
    maxLength,
    boundaryCount,
    siblingCount,
  )
  const theme = useContext(ThemeContext)

  return (
    <Styled.Container>
      <Styled.IconWrapper
        onClick={() => previousPage(parseInt(page || '1') - 1)}
      >
        <Icon
          iconName="chevronLeft"
          size={24}
          color={
            parseInt(page || '1') <= 1
              ? theme.colors.icon.disabled
              : theme.colors.icon.default
          }
        />
      </Styled.IconWrapper>
      <Styled.InnerContainer size={size}>
        <Aligner
          justify="flex-start"
          align="center"
          data-testid="paginationComponent"
        >
          {pages.map((pageNumber, index) => {
            return (
              <Styled.ButtonWrapper
                key={index}
                data-testid={`pageButton${index}`}
              >
                <Button
                  data-testid="pageButton"
                  radius="100px"
                  size={size}
                  key={index}
                  variation={
                    parseInt(page || '1') === pageNumber ? 'main' : 'borderless'
                  }
                  onClick={() =>
                    pageNumber === -1 ? null : setPageParam(pageNumber)
                  }
                >
                  {pageNumber === -1 ? (
                    <Icon
                      iconName="moreHorizontal"
                      color={theme.colors.text.subdued}
                    />
                  ) : (
                    <Body
                      type="copy3"
                      weight={600}
                      color={
                        parseInt(page || '1') === pageNumber
                          ? theme.colors.text.onPrimary
                          : theme.colors.text.subdued
                      }
                    >
                      {pageNumber}
                    </Body>
                  )}
                </Button>
              </Styled.ButtonWrapper>
            )
          })}
        </Aligner>
      </Styled.InnerContainer>
      <Styled.IconWrapper onClick={() => nextPage(parseInt(page || '1') + 1)}>
        <Icon
          iconName="chevronRight"
          size={24}
          color={
            parseInt(page || '1') === count
              ? theme.colors.icon.disabled
              : theme.colors.icon.default
          }
        />
      </Styled.IconWrapper>
    </Styled.Container>
  )
}

export default Pagination
