// React
import { ReactNode, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import InfiniteScroll from 'react-infinite-scroll-component'

// Components
import * as Styled from './styled'
import { Aligner } from '../../components/UI/Containers'
import Pagination from '../../components/Pagination'

type TColumns = {
  title: string
  width?: string
}

type Props<TGeneric> = {
  columns: TColumns[]
  data: TGeneric[]
  hideLine?: boolean
  listLength: number
  pageSize: number
  paginationGap?: number
  pagination?: boolean
  renderRow: (item: TGeneric) => ReactNode
  rowGap?: string
  onEndReached?: () => void
  listTableComponent?: ReactNode | ReactNode[]
  height?: string
}

const Table = <TGeneric extends object>({
  columns,
  data,
  hideLine,
  listLength,
  pageSize,
  paginationGap,
  pagination,
  listTableComponent,
  onEndReached,
  renderRow,
  rowGap,
  height,
}: Props<TGeneric>) => {
  const theme = useContext(ThemeContext)

  const count = listLength / pageSize

  const fetchMoreData = () => {
    onEndReached && onEndReached()
  }

  return (
    <Styled.InfiniteScrollContainer id="scrollableDiv" height={height}>
      <InfiniteScroll
        dataLength={data.length}
        next={fetchMoreData}
        hasMore={data.length < listLength}
        loader={listTableComponent}
        scrollableTarget="scrollableDiv"
      >
        <Styled.TableWrapper flex="column">
          <Styled.TableContainer data-testid="tableComponent" rowGap={rowGap}>
            <Styled.TableHead>
              <Styled.TableLine>
                {columns.map((title, index) => {
                  return (
                    <Styled.LineSection key={index} lineHeader>
                      <Aligner width={title.width} margin="0">
                        <Styled.Content color={theme.colors.text.subdued}>
                          {title.title}
                        </Styled.Content>
                      </Aligner>
                    </Styled.LineSection>
                  )
                })}
              </Styled.TableLine>
            </Styled.TableHead>
            <Styled.TableBody hideLine={hideLine}>
              {data.map((item) => renderRow(item))}
            </Styled.TableBody>
          </Styled.TableContainer>
          {count > 1 && pagination && (
            <Styled.PaginationWrapper paginationGap={paginationGap}>
              <Pagination count={count} size="xsmall" />
            </Styled.PaginationWrapper>
          )}
        </Styled.TableWrapper>
      </InfiniteScroll>
    </Styled.InfiniteScrollContainer>
  )
}

export default Table
