// Models
import { IFinancialState } from 'storage/financial/models'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useEffect, useLayoutEffect, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'

// Misc
import { success } from 'storage/financial/duck'
import { useMediaQuery, useMenu } from 'hooks'

// Components
import * as Block from 'blocks/dashboard/financial'
import * as Styled from './styled'
import { Aligner, Loading, RadioButton } from 'heeds-ds'
import { InDevelopment } from 'views/general'

// Assets
import { ATHLETES, METRICS } from './mock'

const FinancialGeneral: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { athletes, metrics, loading } = useSelector<IStore, IFinancialState>(
    (state) => state.financial,
  )
  const { setPagename } = useMenu()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const [searchValue] = useState('')

  const methods = useForm()
  const { watch, register } = methods

  const additionScreenUnderConstruction = true
  const nameParam = searchParams.get('name')
  const planField = watch('filterType')
  const planParam = searchParams.get('plan')
  // const statusParam = searchParams.get('status')

  // const onFiltersChange = (filters: string[]) => {
  //   const query = searchParams
  //   const formatedFilters = filters.join(',')

  //   if (statusParam) {
  //     if (formatedFilters !== '') query.set('status', formatedFilters)
  //     else query.delete('status')
  //   } else {
  //     if (formatedFilters !== '') query.append('status', formatedFilters)
  //   }

  //   setSearchParams(query)
  // }

  // const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   event.preventDefault()
  //   setSearchValue(event.target.value)
  // }

  useEffect(() => {
    if (planField) {
      const query = searchParams

      if (planParam) {
        if (planField) query.set('plan', planField)
        else query.delete('plan')
      } else {
        if (planField) query.append('plan', planField)
      }

      setSearchParams(query)
    }
  }, [planField, planParam, searchParams, setSearchParams])

  useEffect(() => {
    const value = searchValue

    const timeOut = setTimeout(() => {
      if (value === searchValue) {
        const query = searchParams

        if (nameParam) {
          if (searchValue !== '') query.set('name', searchValue)
          else query.delete('name')
        } else {
          if (searchValue !== '') query.append('name', searchValue)
        }

        setSearchParams(query)
      }
    }, 1000)

    return () => clearTimeout(timeOut)
  }, [nameParam, searchParams, searchValue, setSearchParams])

  useLayoutEffect(() => {
    if (!metrics) {
      dispatch(success({ metrics: METRICS }))
    }
    if (!athletes) {
      dispatch(success({ athletes: { results: ATHLETES } }))
    }
  }, [athletes, dispatch, metrics])

  useLayoutEffect(() => {
    setPagename('Financeiro')

    return () => setPagename('Dashboard')
  }, [setPagename])

  if (loading) {
    return <Loading active />
  }

  if (additionScreenUnderConstruction) {
    return <InDevelopment pagename="Financeiro" />
  }

  return (
    <Styled.Container>
      <Aligner justify="flex-end">
        <RadioButton
          margin={isDesktop ? '0 0 1.2rem' : '0 0 0.8rem'}
          name="filterType"
          onChange={register('filterType').onChange}
          options={['monthly', 'quarterly', 'semiannual', 'yearly']}
          customLabel={['Mensal', 'Trimestral', 'Semestral', 'Anual']}
          refName={register('filterType').ref}
          textRadio
          width="50rem"
          hideOptions
          gap={isDesktop ? '2.4rem' : '0.8rem'}
          displayError={false}
        />
      </Aligner>

      <Block.FinancialMetrics {...metrics} />

      {/* <InputSearch
        clear={() => setSearchValue('')}
        displayError={false}
        filters={['Pendente', 'Pago', 'A Vencer']}
        flex={isDesktop ? 'row' : 'column'}
        margin="0.8rem 0 0"
        onChange={onSearchChange}
        onClickItem={onFiltersChange}
        scale="small"
        value={searchValue}
        width="37.5rem"
      /> */}

      <Block.FinancialAthletes athletes={athletes?.results} />
    </Styled.Container>
  )
}

export default FinancialGeneral
