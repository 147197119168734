// React
import { FC, ReactNode, useContext, useLayoutEffect } from 'react'

// Libraries
import { Outlet } from 'react-router-dom'

// Misc
import { urls } from 'routes/paths'
import { useMediaQuery, useMenu } from 'hooks'

// Components
import { Container, Content } from './styled'
import { Steps, Subtitle } from 'heeds-ds'
import { ThemeContext } from 'styled-components'

type Props = {
  children?: ReactNode | ReactNode[]
}

const STEPS = [
  { label: 'HORÁRIOS', path: urls.profileSchedule },
  { label: 'PLANOS', path: urls.profilePlans },
]

const ProfileLayout: FC<Props> = ({ children }) => {
  const { breakpoints } = useContext(ThemeContext)
  const { setPagename } = useMenu()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)

  useLayoutEffect(() => {
    setPagename('Configurações')

    return () => setPagename('Dashboard')
  }, [setPagename])

  return (
    <Container>
      {isDesktop && (
        <Subtitle type="sh1" weight={700}>
          Configurações
        </Subtitle>
      )}
      <Content>
        <Steps disabled={false} steps={STEPS} />
        {children || <Outlet />}
      </Content>
    </Container>
  )
}

export default ProfileLayout
