// Models
import { IProfileState } from 'storage/profile/models'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useLayoutEffect } from 'react'

// Misc
import { buttonClickTracking } from 'utils/tracking'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { getPersonalProfile } from 'storage/profile/duck'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import { Aligner, FormButton, Loading, Tooltip } from 'heeds-ds'
import {
  Circle,
  FormContainer,
  ItemList,
  Label,
  LabelLegend,
  PlanContainer,
  PlanInfo,
  PlanInfoDescription,
  PlanInfoTitle,
  TooltipContainer,
  TooltipLabel,
  TooltipTitle,
} from './styled'
import ContentBox from 'components/ContentBox'
import ProgressBar from 'components/ProgressBar'

// Assets
import { BRL } from 'lib/currency'
import { mockIndividualList, mockPlanInfo, mockTotalList } from './mock'
import CircleChart from 'components/Charts/Circle'

export interface IFormInputs {
  name?: string
  email?: string
  phone_number?: string
  birthdate?: string
  sex?: string
  cref?: string
  cep?: string
  city?: string
  address?: string
  complement?: string
  instagram?: string
}

type StorageProps = {
  label: string
  value: number
}

type ItemProps = {
  name: string
  storage: StorageProps[]
  total: number
}

const ProfileStorage: FC = () => {
  const { profile, loading } = useSelector<IStore, IProfileState>(
    (state) => state.profile,
  )
  const theme = useContext(ThemeContext)
  const backgrounds = [theme.colors.text.critical, theme.colors.text.secondary]
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    if (profile === undefined) {
      dispatch(getPersonalProfile())
    }
  }, [dispatch, profile])

  if (loading) {
    return <Loading active />
  }

  const renderStorageChart = (item: ItemProps) => {
    return (
      <TooltipContainer>
        <Aligner flex="column">
          <TooltipTitle>{item.name}</TooltipTitle>
          {item.storage.map((storageItem, index) => {
            return (
              <TooltipLabel key={storageItem.label}>
                <LabelLegend color={backgrounds[index]} />
                {storageItem.value}mb {storageItem.label}
              </TooltipLabel>
            )
          })}
        </Aligner>
        <Circle>
          <CircleChart
            trackColor={theme.colors.background.selected}
            maxValue={200}
            size={120}
            unity="mb"
            showPercent={false}
            items={item.storage.map((storageItem, index) => {
              const { value, label } = storageItem
              return {
                value,
                label,
                color: backgrounds[index],
                displayValue: 'string',
              }
            })}
          />
        </Circle>
      </TooltipContainer>
    )
  }

  const renderIndividualListItems = (
    itemList: ItemProps[],
    margin?: string,
  ) => {
    return itemList.map((item) => {
      return (
        <Tooltip key={item.name} content={renderStorageChart(item)}>
          <ItemList margin={margin}>
            <Label>{item.name}</Label>
            <Label>{`${item.total}mb`} </Label>
          </ItemList>
        </Tooltip>
      )
    })
  }

  const renderTotalListItems = (itemList: StorageProps[], margin?: string) => {
    return itemList.map((item) => {
      return (
        <ItemList key={item.label} margin={margin}>
          <Label>{item.label}</Label>
          <Label>{`${item.value}gb`}</Label>
        </ItemList>
      )
    })
  }

  return (
    <FormContainer>
      <ContentBox title="Plano contratado">
        <PlanContainer>
          <PlanInfo area="plan">
            <PlanInfoTitle>Plano</PlanInfoTitle>
            <PlanInfoDescription>{mockPlanInfo.name}</PlanInfoDescription>
          </PlanInfo>

          <PlanInfo area="limit">
            <PlanInfoTitle>Limite Mensal</PlanInfoTitle>
            <PlanInfoDescription>
              {mockPlanInfo.monthlyLimit} alunos
            </PlanInfoDescription>
          </PlanInfo>

          <PlanInfo area="storage">
            <PlanInfoTitle>Armazenamento</PlanInfoTitle>
            <PlanInfoDescription>{mockPlanInfo.storage}gb</PlanInfoDescription>
          </PlanInfo>

          <PlanInfo area="cost">
            <PlanInfoTitle>Custo</PlanInfoTitle>
            <PlanInfoDescription>
              {BRL(mockPlanInfo.monthlyCost).format()}
            </PlanInfoDescription>
          </PlanInfo>

          <PlanInfo area="button">
            <Aligner justify="center" align="center">
              <FormButton
                onClick={() => undefined}
                size="small"
                track={buttonClickTracking}
                trackName="no_action"
                variation={isDesktop ? 'borderless' : 'outlined'}
              >
                Mudar o plano
              </FormButton>
            </Aligner>
          </PlanInfo>
        </PlanContainer>
      </ContentBox>

      <Aligner
        flex={isDesktop ? 'row' : 'column'}
        style={{
          gap: isDesktop ? '24px' : '0px',
          paddingBottom: isDesktop ? '0px' : '80px',
        }}
      >
        <ContentBox
          title="Armazenamento total"
          collapsible={!isDesktop}
          startsOpen={isDesktop}
          description={
            <ProgressBar
              margin={'32px 0 0 0'}
              max={30}
              maxLabel="total"
              unity="gb"
              value={1.04}
              valueLabel="utilizado"
            />
          }
        >
          {renderTotalListItems(mockTotalList)}
        </ContentBox>
        <ContentBox title="Armazenamento individual">
          {renderIndividualListItems(mockIndividualList, '16px')}
        </ContentBox>
      </Aligner>
    </FormContainer>
  )
}

export default ProfileStorage
