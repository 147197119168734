//Models
import { TAthleteCalendar } from 'models'

//React
import { FC, useContext, useEffect, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Components
import * as Styled from './styled'
import { Aligner, Avatar, Body, Icon } from 'heeds-ds'

type Props = {
  conflict?: boolean
  conflictList: TAthleteCalendar[]
  handleConflictedTime?: (item: TAthleteCalendar) => void
}

const ConflictCard: FC<Props> = ({
  conflict,
  conflictList,
  handleConflictedTime,
}: Props) => {
  const theme = useContext(ThemeContext)
  const [showConflictCard, setShowConflictCard] = useState(0)

  useEffect(() => {
    setShowConflictCard(0)
  }, [conflictList])

  const isConflicting = conflictList?.length > 0 && conflict

  return (
    <Aligner flex="column">
      <Aligner
        width={isConflicting ? '390px' : '100%'}
        align="center"
        justify="center"
      >
        {
          <Body
            data-testid="conflict-message"
            type="copy3"
            color={theme.colors.text.default}
            align={isConflicting ? 'left' : 'center'}
            margin={isConflicting ? '20px  0 24px 0' : '110px 0 68px 0'}
          >
            {isConflicting
              ? 'O horário atual que você escolheu está em conflito com outro aluno já adicionado:'
              : 'Não existe conflito com esse horário.'}
          </Body>
        }
      </Aligner>
      <Aligner align="center" justify="center">
        {conflictList?.map((item, index) => {
          return index === showConflictCard ? (
            <Aligner key={index} align="center" justify="center">
              {showConflictCard > 0 ? (
                <Styled.IconWrapper
                  margin="0 22px 0 0"
                  onClick={() => setShowConflictCard(showConflictCard - 1)}
                >
                  <Icon
                    iconName="chevronLeft"
                    color={theme.colors.icon.default}
                  />
                </Styled.IconWrapper>
              ) : (
                <Aligner width="42px" />
              )}
              <Styled.ConflictCard
                data-testid="conflict_card"
                newAthlete={item.new}
                onClick={() => handleConflictedTime?.(item)}
              >
                <Aligner>
                  <Styled.AvatarWarper>
                    <Avatar
                      scale="small"
                      name={item.athlete.toString()}
                      margin="0 24px 0 0"
                    />
                  </Styled.AvatarWarper>
                  <Aligner align="flex-start" flex="column">
                    <Body
                      type="copy1"
                      weight={700}
                      margin="0 0 8px 0"
                      color={theme.colors.text.secondary}
                    >
                      {item.athlete}
                    </Body>
                    <Body
                      type="copy2"
                      color={theme.colors.text.secondary}
                      margin="0 0 8px 0"
                    >
                      {item.day}, {item.start}-{item.end}
                    </Body>
                    <Body type="copy2" color={theme.colors.text.secondary}>
                      {item.location}
                    </Body>
                  </Aligner>
                </Aligner>
              </Styled.ConflictCard>
              {conflictList.length - 1 > showConflictCard ? (
                <Styled.IconWrapper
                  margin="0 0 0 22px"
                  onClick={() => setShowConflictCard(showConflictCard + 1)}
                >
                  <Icon
                    iconName="chevronRight"
                    color={theme.colors.icon.default}
                  />
                </Styled.IconWrapper>
              ) : (
                <Aligner width="42px" />
              )}
            </Aligner>
          ) : null
        })}
      </Aligner>
    </Aligner>
  )
}

export default ConflictCard
