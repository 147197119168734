import styled from 'styled-components'

type ContainerProps = {
  flex?: 'row' | 'column'
  align?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-evenly'
  justify?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-evenly'
  margin?: string
}

export const Aligner = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: ${({ flex }) => flex || 'row'};
  align-items: ${({ align }) => align || 'initial'};
  justify-content: ${({ justify }) => justify || 'initial'};
  margin: ${({ margin }) => margin || 0};
`

export const SimpleContainer = styled.div``

export const ModalContainer = styled.div`
  padding: 24px 0;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0 24px;
    width: 602px;
  }
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px 56px;
`

export const ModalHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 30px 16px 16px;
  position: relative;
  ::after {
    background-color: ${({ theme }) => theme.colors.icon.disabled};
    content: '';
    height: 1.5px;
    opacity: 0.3;
    position: absolute;
    top: 100%;
    width: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 24px 16px 16px;
  }
`
