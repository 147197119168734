// Mmodels
import { TBaseBlockStatus } from '.'

// Libraries
import styled, { css } from 'styled-components'

// Components
import { Aligner, Body, Icon, IconButton } from 'heeds-ds'

interface Props {
  borderTop?: boolean
  status?: TBaseBlockStatus
}

export const Container = styled(Aligner).attrs({
  align: 'center',
  flex: 'column',
})<Props>`
  padding: 1.6rem;

  ${({ borderTop, status, theme }) => css`
    background: ${theme.colors.surface.default};
    border: ${status === 'drag' ? 1 : 0}px solid ${theme.colors.text.disabled};
    border-radius: ${borderTop ? '0 0 0.8rem 0.8rem' : '0.8rem'};
    border-top: ${borderTop ? 2 : status === 'drag' ? 1 : 0}px solid
      ${theme.colors.text[status === 'drag' ? 'disabled' : 'secondary']};
    box-shadow: 0px 0px ${status !== 'default' ? 15 : 0}px 0px
      rgba(43, 42, 99, 0.16);

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 1.6rem 2.4rem;

      :hover {
        background: ${theme.colors.surface.subdued};
      }
    }
  `};
`

export const Header = styled(Aligner).attrs({
  justify: 'space-between',
})<Props>`
  padding: 0 0 1.6rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0 0 2.4rem;
  }
`

export const DeleteButton = styled(IconButton).attrs({
  cancel: true,
  iconName: 'delete',
  margin: '0',
  size: 'small',
})``

export const DragContainer = styled(Aligner).attrs({ width: 'fit-content' })`
  cursor: grab;
`

export const DragIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.colors.icon.disabled,
  iconName: 'dragIndicator',
  size: 24,
}))``

export const DuplicateButton = styled(IconButton).attrs({
  iconName: 'contentCopy',
  margin: '0',
  size: 'small',
})``

export const TypeIcon = styled(Icon).attrs(({ iconName, theme }) => ({
  color: theme.colors.icon.hovered,
  iconName: iconName || 'title',
  size: 16,
}))``

export const TypeTitle = styled(Body).attrs(({ theme }) => ({
  color: theme.colors.text.subdued,
  type: 'copy4',
}))``
