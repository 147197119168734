// React
import { FC } from 'react'

// Components
import * as Styled from '../styled'

// Assets
import errorImage from 'assets/images/email-fail.png'

const ExpiredInvitation: FC = () => {
  return (
    <Styled.Container>
      <Styled.Image src={errorImage} />

      <Styled.TitleS>Convite expirado</Styled.TitleS>

      <Styled.SubtitleS>
        O convite enviado pelo seu personal expirou.
      </Styled.SubtitleS>

      <Styled.SmallText>
        Entre em contato com ele para reenviar.
      </Styled.SmallText>
    </Styled.Container>
  )
}

export default ExpiredInvitation
