// Libraries
import * as yup from 'yup'

// Misc
import { BRL } from 'lib/currency'
import { birthDateRegex, phoneRegex } from 'utils/regex'
import { commonScheduleSchema, dateValidationSchema } from './general'
import { getDateFromPastYears } from 'utils/functions'
import {
  testDueDateIsRequired,
  testDueDayIsRequired,
  testFrequencyIsRequired,
  testIsStartDateBeforeDueDate,
} from './@utils'

// Contants
const FIFT_YEARS_BEFORE_NOW = getDateFromPastYears(50)

export const athleteFinancialSchema = yup.object().shape({
  autoRenovation: yup.boolean(),
  discount: yup
    .number()
    .transform((_, value) => BRL(value).value)
    .notRequired(),
  frequency: yup
    .string()
    .test('isRequired', 'Entre com a frequencia', testFrequencyIsRequired)
    .nullable(),
  start_date: dateValidationSchema
    .min(
      FIFT_YEARS_BEFORE_NOW,
      `Desculpe, só é possível selecionar a partir de ${FIFT_YEARS_BEFORE_NOW.getFullYear()}`,
    )
    .required('Digite a data de início'),
  due_date_date: dateValidationSchema
    .test('isRequired', 'Escolha uma data', testDueDateIsRequired)
    .test(
      'isStartDateBeforeDueDate',
      'A data de pagamento deve ser posterior a data de início do plano',
      testIsStartDateBeforeDueDate,
    )
    .nullable(),
  due_date_day: yup
    .string()
    .test('isRequired', 'Escolha uma dia', testDueDayIsRequired),
  status: yup.string(),
  athlete: yup.number(),
  personal: yup.number(),
  plan: yup.number(),
  plans_extras: yup.array(),
  spread: yup.boolean(),
})

export const athleteInformationSchema = yup.object().shape({
  name: yup.string().required('Digite o nome'),
  email: yup.string().email('Email inválido').required('Digite o email'),
  phone_number: yup
    .string()
    .matches(phoneRegex, {
      message: 'Digite um número válido',
      excludeEmptyString: true,
    })
    .notRequired(),
  birthdate: yup
    .string()
    .required('Digite a data de nascimento')
    .matches(birthDateRegex, 'Digite uma data de nascimento válida'),
  gender: yup.string().required(),
  emergency_contact_name: yup.string(),
  emergency_contact_phone_number: yup
    .string()
    .matches(phoneRegex, {
      message: 'Digite um número válido',
      excludeEmptyString: true,
    })
    .notRequired(),
  cep: yup.string(),
  city: yup.string(),
  state: yup.string(),
  address: yup.string(),
  complement: yup.string(),
  instagram: yup.string(),
})

export const athleteModalinviteSchema = yup.object().shape({
  name: yup.string(),
  email: yup.string().email('Email inválido').required('Digite o email'),
  anamnesis_template_id: yup.string(),
})

export const athleteModalPaymentRegisterSchema = yup.object().shape({
  payment_type: yup.string().required(),
  value: yup.number().required('Digite o valor da mensalidade'),
  due_date: dateValidationSchema
    .typeError('Digite uma data válida')
    .required('Digite a data de pagamento'),
})

export const athleteModalSendReminderSchema = yup.object().shape({
  notificationType: yup.string().required(),
  subject: yup.string().required(),
  message: yup.string().required('Digite uma mensagem'),
})

export const athleteScheduleSchema = yup.object().shape({
  ...commonScheduleSchema,
  location: yup.string(),
})
