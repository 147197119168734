// Libraries
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 1.8rem 2.7rem;
  background-color: transparent;
  display: grid;
  grid-template-columns: 20px 1fr 20px;
  justify-items: center;
  align-items: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: none;
  }
`

export const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
