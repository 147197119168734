// Models
import { TPaymentStatus } from 'models'

// Libraries
import styled from 'styled-components'

// Components
import { Aligner } from 'heeds-ds'

type Props = {
  status?: TPaymentStatus
}

enum EBackgrondColor {
  due_date = 'warningSubduedHovered',
  paid = 'successSubduedHovered',
  pendent = 'criticalDefault',
}

export const Container = styled(Aligner).attrs(() => ({
  align: 'center',
  justify: 'space-between',
  padding: '1.6rem 2.4rem',
}))`
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 15px;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  margin: 0 0 1.6rem;
`

export const StatusContainer = styled.div<Props>`
  background: ${({ status, theme }) =>
    theme.colors.surface[EBackgrondColor[status || 'paid']]};
  border-radius: 16px;
  padding: 0.8rem 1.6rem;
  white-space: nowrap;
`
