// Libraries
import { StyleSheet, Font } from '@react-pdf/renderer'

// Misc
import { theme } from 'lib/styled-components/theme'

// Assets
import RobotoBold from 'assets/fonts/roboto-bold.ttf'
import RobotoMedium from 'assets/fonts/roboto-medium.ttf'
import RobotoRegular from 'assets/fonts/roboto-regular.ttf'

Font.register({
  family: 'Roboto',
  fonts: [
    { src: RobotoRegular, fontWeight: 400 },
    { src: RobotoMedium, fontWeight: 500 },
    { src: RobotoBold, fontWeight: 700 },
  ],
})

export const Styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 35,
    position: 'relative',
  },
  topLine: {
    backgroundColor: theme.colors.text.secondary,
    height: 8,
    marginBottom: 32,
    marginTop: -35,
  },
  image: {
    height: '45px',
    width: '117px',
  },
  header: {
    flexDirection: 'column',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    gap: '32px',
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: 'Roboto',
    color: theme.colors.text.secondary,
  },
  textView: { margin: '104px 60px 72px 60px', display: 'flex', gap: '16px' },
  text: {
    fontSize: 12,
    fontWeight: 400,
    fontFamily: 'Roboto',
    color: theme.colors.text.default,
  },
  dateAndLocalView: { alignItems: 'flex-end', margin: '0px 96px 96px 96px' },
  dateAndLocalText: {
    width: '50%',
    textAlign: 'right',
    borderTop: `1px solid ${theme.colors.border.default}`,
    padding: '4px 0 0 0',
    fontSize: 10,
    fontWeight: 400,
    fontFamily: 'Roboto',
    color: theme.colors.text.subdued,
  },
  signatureView: {
    alignItems: 'center',
    margin: '0px 96px 0px 96px',
    gap: '64px',
  },
  signatureText: {
    width: '100%',
    textAlign: 'center',
    borderTop: `1px solid ${theme.colors.border.default}`,
    padding: '4px 0 0 0',
    fontSize: 10,
    fontWeight: 400,
    fontFamily: 'Roboto',
    color: theme.colors.text.default,
  },
  footerView: {
    alignItems: 'center',
    borderTop: `2px solid ${theme.colors.border.input}`,
    position: 'absolute',
    bottom: 40,
    left: 40,
    right: 40,
  },
  footerText: {
    textAlign: 'center',
    padding: '8px 0 0 0',
    fontSize: 10,
    fontWeight: 500,
    fontFamily: 'Roboto',
    color: theme.colors.text.default,
  },
  footerSubText: {
    textAlign: 'center',
    fontSize: 10,
    fontWeight: 500,
    fontFamily: 'Roboto',
    color: theme.colors.text.subdued,
  },
})
