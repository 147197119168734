// Libraries
import styled, { css } from 'styled-components'

// Components
import { Aligner, Icon } from 'heeds-ds'

interface Props {
  disabled?: boolean
}

export const ArrowIcon = styled(Icon).attrs({
  iconName: 'arrowRight',
  size: 24,
})``

export const CardContainer = styled(Aligner).attrs({
  align: 'center',
  padding: '2.4rem',
})`
  border-radius: 1.5rem;
  box-shadow: 0px 0px 15px 0px rgba(43, 42, 99, 0.16);
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2.4rem;
  overflow-y: auto;
  padding: 8rem 2.4rem 2.4rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 4rem 4.8rem 0 4rem;
  }
`

export const CustomIcon = styled.img<Props>`
  height: 3.2rem;
  width: 3.2rem;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`

export const ModelIcon = styled(Icon).attrs({
  size: 32,
})``
