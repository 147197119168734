// React
import { FC, useContext } from 'react'

// Lib
import { ThemeContext } from 'styled-components'

// Components
import * as Styled from './styled'
import { Body } from 'components/UI/Typography'

type Props = {
  day?: string
  end?: string
  location?: string
  start?: string
}

const AthleteHoursCard: FC<Props> = ({ day, end, location, start }) => {
  const theme = useContext(ThemeContext)

  return (
    <Styled.Container data-testid="container">
      <Body type="copy4" color={theme.colors.text.secondary} weight={600}>
        {day}
      </Body>
      <Body type="copy4" color={theme.colors.text.subdued}>
        {start} às {end}
      </Body>
      <Body type="copy4" color={theme.colors.text.subdued}>
        {location}
      </Body>
    </Styled.Container>
  )
}

export default AthleteHoursCard
