// Models
import { IAction } from 'lib/redux/models'
import { IAthleteInfo } from 'services/athleteInfo/@types'
import {
  IAthletePayload,
  IGetAthletePayload,
  IUpdateAthletePayload,
} from '../models'

// Libraries
import { SagaIterator } from 'redux-saga'
import { all, takeLatest } from 'redux-saga/effects'
import { call, put } from 'redux-saga/effects'

// Misc
import { failure, fulfill, request, success, Types } from '../duck'
import {
  createAthlete,
  loadAthleteInformation,
  updateAthleteInformation,
} from 'services/athleteInfo'
import {
  triggerToastError,
  triggerToastSuccess,
  triggerToastWarning,
} from 'storage/general/duck'

export function* createAthleteSaga(action: IAction<IAthletePayload>) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, ...updatePayload } = action.payload

      const response: IAthleteInfo = yield call(createAthlete, updatePayload)

      yield put(success({ info: response }))

      successCallback?.(response.id)
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* getAthleteSaga(action: IAction<IGetAthletePayload>) {
  yield put(request())
  try {
    if (action.payload) {
      const { id, successCallback } = action.payload
      const response: IAthleteInfo = yield call(loadAthleteInformation, id)

      yield put(success({ info: response }))

      successCallback?.(response)
    }
  } catch (error: unknown) {
    const err = error as Error
    if (err.message === '404') {
      yield put(triggerToastWarning({ customTitle: 'ID do atleta inválido' }))
    }
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* updateAthleteSaga(action: IAction<IUpdateAthletePayload>) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, ...updatePayload } = action.payload

      const response: IAthleteInfo = yield call(
        updateAthleteInformation,
        updatePayload,
      )

      yield put(success({ info: response }))
      yield put(
        triggerToastSuccess({
          message: 'Dados salvos com sucesso',
        }),
      )

      successCallback?.(response.id)
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(
      triggerToastError({
        customTitle: 'Não conseguimos salvar seu aluno.',
        message: 'Confira os dados do aluno e clique em “salvar” novamente.',
      }),
    )
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export default function* athleteSaga(): SagaIterator {
  yield all([
    takeLatest(Types.CREATE_ATHLETE, createAthleteSaga),
    takeLatest(Types.GET_ATHLETE, getAthleteSaga),
    takeLatest(Types.UPDATE_ATHLETE, updateAthleteSaga),
  ])
}
