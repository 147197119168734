import { Aligner, Body } from 'heeds-ds'
import styled from 'styled-components'

export const Container = styled(Aligner)`
  flex-direction: column;
  flex: 1;
  gap: 1.6rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 4rem 4.8rem 2.4rem 4rem;
  }
`

export const Header = styled(Aligner)`
  flex-direction: column;
  gap: 0.8rem;
  padding: 0 2.4rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    align-items: center;
    flex-direction: row;
    gap: 2.4rem;
    padding: 0;
  }
`

export const Profile = styled(Aligner)`
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 0.8rem;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  gap: 1.6rem;
  padding: 1.6rem 2.4rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    box-shadow: 0px 3.5px 5.5px 0px #00000005;
    min-width: 42.5rem;
    width: 42.5rem;
  }
`

export const Name = styled(Body).attrs({
  type: 'copy3',
  weight: 600,
})``

export const Plan = styled(Body).attrs(({ theme }) => ({
  type: 'copy3',
  color: theme.colors.text.subdued,
}))``

export const Nav = styled(Aligner)`
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 0.8rem;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  box-shadow: 0px 3.5px 5.5px 0px #00000005;
  height: 7.6rem;
`

export const Content = styled(Aligner)`
  flex-direction: column;
  flex: 1;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    background: ${({ theme }) => theme.colors.surface.default};
    border-radius: 0.8rem;
    border: 1px solid ${({ theme }) => theme.colors.border.input};
    flex-direction: row;
    max-height: calc(100vh - 15.6rem);
  }
`
