// Libraries
import { MaskedInputProps } from 'react-text-mask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

// Constants
const REGEX_SYMBOLS: Record<string, RegExp> = {
  A: /[A-Z]/,
  a: /[a-z]/,
  x: /[A-Za-z]/,
  '9': /\d/,
}

export type TMasks =
  | 'cadence'
  | 'cep'
  | 'currency'
  | 'cref'
  | 'date'
  | 'integer'
  | 'number'
  | 'none'
  | 'percentage'
  | 'phone'
  | 'repetitions'
  | 'seconds'
  | 'time'
  | 'weight'

type TMaskCollection = Record<TMasks, MaskedInputProps>

const _mapToArray = (mask: string) => {
  return mask
    .split('')
    .map((char) => (REGEX_SYMBOLS[char] ? REGEX_SYMBOLS[char] : char))
}

export const maskCollection: TMaskCollection = {
  cadence: {
    mask: createNumberMask({
      prefix: '',
      suffix: '',
      allowDecimal: false,
      thousandsSeparatorSymbol: '',
    }),
    maxLength: 4,
  },
  cep: { mask: _mapToArray('99999-999') },
  cref: { mask: _mapToArray('999999-x') },
  none: { mask: false },
  phone: {
    mask: (value) => {
      const rawValue = value.replace(/[^a-zA-Z0-9]/g, '')
      return _mapToArray(
        rawValue.length <= 10 ? '(99) 9999-9999' : '(99) 99999-9999',
      )
    },
  },
  currency: {
    mask: createNumberMask({
      prefix: 'R$ ',
      allowDecimal: true,
      thousandsSeparatorSymbol: '.',
      decimalSymbol: ',',
      decimalLimit: 2,
    }),
  },
  date: {
    mask: _mapToArray('99/99/9999'),
    pipe: createAutoCorrectedDatePipe('dd/mm/yyyy'),
    placeholderChar: ' ',
  },
  integer: {
    mask: createNumberMask({
      prefix: '',
      suffix: '',
      allowDecimal: false,
      thousandsSeparatorSymbol: '',
    }),
  },
  number: {
    mask: createNumberMask({
      prefix: '',
      suffix: '',
      allowDecimal: true,
      thousandsSeparatorSymbol: '.',
      decimalSymbol: ',',
    }),
  },
  percentage: {
    mask: createNumberMask({
      prefix: '',
      suffix: '%',
      allowDecimal: true,
      thousandsSeparatorSymbol: '.',
      decimalSymbol: ',',
    }),
  },
  repetitions: {
    mask: (value) => {
      const maskFormat = value
        .replace(/[^0-9-]/g, '')
        .replace(/(?<=-\d*)-/, '')
        .replace(/\d/, '9')
      return _mapToArray(maskFormat)
    },
  },
  seconds: {
    mask: createNumberMask({
      prefix: '',
      suffix: ' seg',
      allowDecimal: true,
      thousandsSeparatorSymbol: '.',
      decimalSymbol: ',',
    }),
  },
  time: {
    mask: _mapToArray('99:99'),
  },
  weight: {
    mask: createNumberMask({
      prefix: '',
      suffix: ' kg',
      allowDecimal: true,
      thousandsSeparatorSymbol: '.',
      decimalSymbol: ',',
    }),
  },
}

export const dateMask = (value: string): string => {
  return value
    ?.replace(/\D+/g, '')
    ?.replace(/^(\d{2})(\d)/, '$1/$2')
    ?.replace(/^(\d{2}\/\d{2})(\d)/, '$1/$2')
    ?.replace(/^(\d{2}\/\d{2}\/\d{4})\d+?$/, '$1')
}
