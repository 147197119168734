// Models
import { ISubscriptionData } from 'storage/financialSubscription/models'
import { ISubscriptionDeactivatePayload } from './@types'

// Misc
import api from 'lib/axios/api'

export const deactivateFinancialSubscription = async (
  formData: ISubscriptionDeactivatePayload,
): Promise<ISubscriptionData> => {
  try {
    const { id, ...restForm } = formData
    const response = await api.patch(
      `financial/subscription/${id}/deactivate/`,
      restForm,
    )
    const data: ISubscriptionData = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const activateFinancialSubscription = async (
  formData: ISubscriptionDeactivatePayload,
): Promise<ISubscriptionData> => {
  try {
    const { id, ...restForm } = formData
    const response = await api.patch(
      `financial/subscription/${id}/activate/`,
      restForm,
    )
    const data: ISubscriptionData = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}
