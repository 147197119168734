// Libraries
import {
  AuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth'

// Misc
import { EFirebaseProviderOptions, ILoginPayload } from 'storage/auth/models'
import app from 'lib/firebase'
import { FirebaseError } from 'firebase/app'
import { firebaseErrors } from 'utils/constants'

const getTokenWithSocialProvider = async (
  authProvider: AuthProvider,
): Promise<string> => {
  const auth = getAuth(app)

  const userCredential = await signInWithPopup(auth, authProvider)
  return userCredential.user.getIdToken()
}

const getTokenEmailPassword = async (
  email: string,
  password: string,
): Promise<string> => {
  const auth = getAuth(app)
  const userCredential = await signInWithEmailAndPassword(auth, email, password)
  return userCredential.user.getIdToken()
}

const getTokenWithApple = () =>
  getTokenWithSocialProvider(new OAuthProvider(EFirebaseProviderOptions.APPLE))

const getTokenWithGoogle = () =>
  getTokenWithSocialProvider(new GoogleAuthProvider())

const getTokenWithFacebook = () =>
  getTokenWithSocialProvider(new FacebookAuthProvider())

export const getTokenBasedOnProvider = async (
  formData: ILoginPayload,
): Promise<string> => {
  const { provider, email, password } = formData
  try {
    let token = ''
    if (provider === EFirebaseProviderOptions.EMAIL && email && password) {
      token = await getTokenEmailPassword(email, password)
    } else if (provider === EFirebaseProviderOptions.GOOGLE) {
      token = await getTokenWithGoogle()
    } else if (provider === EFirebaseProviderOptions.FACEBOOK) {
      token = await getTokenWithFacebook()
    } else if (provider === EFirebaseProviderOptions.APPLE) {
      token = await getTokenWithApple()
    }
    return token
  } catch (error: unknown) {
    if (error instanceof FirebaseError) {
      throw Error(firebaseErrors[error.code as keyof typeof firebaseErrors])
    } else {
      throw Error('Algo de errado aconteceu.')
    }
  }
}

export const logoutFirebase = () => {
  const auth = getAuth(app)
  auth.signOut()
}
