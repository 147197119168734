// Models
import { IStatementOfResponsibilityPDF } from 'models'

// React
import React, { FC } from 'react'

//Libraries
import { Document, Image, Page, Text, View } from '@react-pdf/renderer'

// Components
import { Styles } from './styled'

// Assets
import logoFull from 'assets/images/logo-cadu.png'

type Props = {
  data: IStatementOfResponsibilityPDF
}

const StatementOfResponsibilityPDF: FC<Props> = ({ data }) => {
  const { name, cref } = data
  return (
    <Document>
      <Page style={Styles.body}>
        <View style={Styles.topLine} />

        <View style={Styles.header}>
          <Image style={Styles.image} src={logoFull} />
          <Text style={Styles.title}>
            Termo de Responsabilidade para Prática de Atividade Física
          </Text>
        </View>

        <View style={Styles.textView}>
          <Text style={Styles.text}>
            Estou ciente de que é recomendável conversar com um médico antes de
            aumentar meu nível atual de atividade física, por ter respondido
            “sim” a uma ou mais perguntas do “Questionário de Prontidão para
            Atividade Física” (PAR-Q).
          </Text>
          <Text style={Styles.text}>
            Assumo plena responsabilidade por qualquer atividade física
            praticada sem o atendimento a essa recomendação.
          </Text>
          <Text style={Styles.text}>
            Declaro estar ciente de que, se houver algum impedimento médico ou
            físico para a participação das atividades, deverei levar tal fato ao
            conhecimento dos responsáveis.
          </Text>
        </View>

        <View style={Styles.dateAndLocalView}>
          <Text style={Styles.dateAndLocalText}>Local e data</Text>
        </View>

        <View style={Styles.signatureView}>
          <Text style={Styles.signatureText}>Assinatura do aluno</Text>
          <Text style={Styles.signatureText}>Assinatura do responsável</Text>
        </View>

        <View style={Styles.footerView}>
          <Text style={Styles.footerText}>{name}</Text>
          <Text style={Styles.footerSubText}>
            Personal Trainer - CREF {cref}
          </Text>
        </View>
      </Page>
    </Document>
  )
}

export default StatementOfResponsibilityPDF
