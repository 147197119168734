// Models
import { TPaymentInputs, TPreparePaymentToPayload } from './models'

// Libraries
import moment from 'moment'

export const preparePaymentToPayload: TPreparePaymentToPayload = (
  dataForm: TPaymentInputs,
) => {
  const { due_date, ...dataRest } = dataForm

  const newRoutine = {
    ...dataRest,
    due_date: moment(due_date).format('YYYY-MM-DD'),
  }

  return newRoutine
}
