// Models
import { IAction } from 'lib/redux/models'
import { IPhysicalAssessment } from '../models'
import {
  ILoadPhysicalAssessmentsPayload,
  ILoadPhysicalAssessmentsResponse,
  IUpdatePhysicalAssessmentPayload,
  TCreatePhysicalAssessmentPayload,
} from 'services/assessmentPhysicalAssessment/@types'

// Libraries
import { SagaIterator } from 'redux-saga'
import { all, takeLatest } from 'redux-saga/effects'
import { call, put } from 'redux-saga/effects'

// Misc
import {
  createPhysicalAssessment,
  deletePhysicalAssessment,
  getPhysicalAssessment,
  getPhysicalAssessments,
  partilaUpdatePhysicalAssessment,
  updatePhysicalAssessment,
} from 'services/assessmentPhysicalAssessment'
import {
  failure,
  fulfill,
  request,
  success,
  successLoadMore,
  Types,
} from '../duck'

export function* createPhysicalAssessmentSaga(
  action: IAction<TCreatePhysicalAssessmentPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IPhysicalAssessment = yield call(
        createPhysicalAssessment,
        action.payload,
      )

      yield put(success({ physicalAssessment: response }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* getPhysicalAssessmentSaga(action: IAction<number>) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IPhysicalAssessment = yield call(
        getPhysicalAssessment,
        action.payload,
      )

      yield put(success({ physicalAssessment: response }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* updatePhysicalAssessmentSaga(
  action: IAction<IPhysicalAssessment>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IPhysicalAssessment = yield call(
        updatePhysicalAssessment,
        action.payload,
      )

      yield put(success({ physicalAssessment: response }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* partialUpdatePhysicalAssessmentSaga(
  action: IAction<IUpdatePhysicalAssessmentPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IPhysicalAssessment = yield call(
        partilaUpdatePhysicalAssessment,
        action.payload,
      )

      yield put(success({ physicalAssessment: response }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* deletePhysicalAssessmentSaga(action: IAction<number>) {
  yield put(request())
  try {
    if (action.payload) {
      yield call(deletePhysicalAssessment, action.payload)
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* loadPhysicalAssessmentsSaga(
  action: IAction<ILoadPhysicalAssessmentsPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { results, ...paginateResponse }: ILoadPhysicalAssessmentsResponse =
        yield call(getPhysicalAssessments, action.payload)

      yield put(success({ ...paginateResponse, physicalAssessments: results }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* loadMorePhysicalAssessmentsSaga(
  action: IAction<ILoadPhysicalAssessmentsPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: ILoadPhysicalAssessmentsResponse = yield call(
        getPhysicalAssessments,
        action.payload,
      )

      yield put(successLoadMore(response))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export default function* PhysicalAssessmentSaga(): SagaIterator {
  yield all([
    takeLatest(Types.CREATE, createPhysicalAssessmentSaga),
    takeLatest(Types.GET, getPhysicalAssessmentSaga),
    takeLatest(Types.UPDATE, updatePhysicalAssessmentSaga),
    takeLatest(Types.PARTIAL_UPDATE, partialUpdatePhysicalAssessmentSaga),
    takeLatest(Types.DELETE, deletePhysicalAssessmentSaga),
    takeLatest(Types.LOAD, loadPhysicalAssessmentsSaga),
    takeLatest(Types.LOAD_MORE, loadMorePhysicalAssessmentsSaga),
  ])
}
