// Models
import { TRoutineDificulty, TRoutineGoal } from 'models'

// React
import { FC, useContext, useMemo } from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { useFilters } from 'hooks'
import { useMediaQuery } from 'hooks'

// Components
import * as Styled from './styled'
import { Body, Button, FormButton, InputText, Select, Subtitle } from 'heeds-ds'
import { Modal } from 'components'

// Constants
import {
  DATE_RANGE_OPTIONS,
  DIFFICULTY_OPTIONS,
  GOAL_OPTIONS,
} from 'utils/constants'

interface IFormFields {
  date_range?: string
  difficulty?: TRoutineDificulty
  goal?: TRoutineGoal
  name?: string
}

type Props = {
  handleCancel: () => void
  onConfirm: (filters: IFormFields) => void
  type: 'atlhete' | 'global'
}

const ModalAthleteRoutineFilters: FC<Props> = ({
  handleCancel,
  onConfirm,
  type = 'atlhete',
}) => {
  const { filters } = useFilters<IFormFields>(window.location.search)
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  const defaultValues = useMemo(
    () =>
      Object.entries(filters).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value.toString() }),
        {},
      ),
    [filters],
  )

  const methods = useForm<IFormFields>({ defaultValues })
  const { handleSubmit } = methods

  const onSubmit = (formData: IFormFields) => onConfirm(formData)

  const renderTitle = useMemo(
    () =>
      isDesktop ? (
        <Subtitle type="sh2" weight={600}>
          Filtro
        </Subtitle>
      ) : (
        <Body weight={600}>Filtro</Body>
      ),
    [isDesktop],
  )

  return (
    <Modal onClose={handleCancel}>
      <FormProvider {...methods}>
        <Styled.Container onSubmit={handleSubmit(onSubmit)}>
          <Styled.CardTop>{renderTitle}</Styled.CardTop>

          <Styled.BodyContainer>
            Selecione as opções que deseja filtrar as rotinas.
          </Styled.BodyContainer>

          {type === 'atlhete' ? (
            <Select
              label="Objetivo da rotina"
              margin="3.2rem 0 0"
              name="goal"
              options={GOAL_OPTIONS}
              placeholder="Digite ou selecione o objetivo"
              scale="small"
            />
          ) : (
            <InputText
              label="Nome do aluno"
              margin="3.2rem 0 0"
              name="athlete_name"
              placeholder="Digite o nome do aluno"
              scale="small"
            />
          )}

          <Select
            label="Nível"
            margin="2rem 0 1.6rem"
            name="difficulty"
            options={DIFFICULTY_OPTIONS}
            placeholder="Iniciante, intermediário..."
            scale="small"
          />

          <Select
            label="Período"
            margin="2rem 0 0"
            name="date_range"
            options={DATE_RANGE_OPTIONS}
            placeholder="Últimos 3 meses"
            scale="small"
          />

          <Styled.ButtonsContainer>
            <Button
              cancel
              margin="0"
              onClick={handleCancel}
              size="small"
              track={buttonClickTracking}
              trackName={`cancel_modal_filter`}
              variation="borderless"
            >
              Cancelar
            </Button>

            <FormButton
              margin="0"
              size="small"
              track={buttonClickTracking}
              trackName={`confirm_modal_filter`}
              width="100%"
            >
              Filtrar
            </FormButton>
          </Styled.ButtonsContainer>
        </Styled.Container>
      </FormProvider>
    </Modal>
  )
}

export default ModalAthleteRoutineFilters
