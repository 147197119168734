// React
import { FC, useContext } from 'react'

import { ThemeContext } from 'styled-components'

import { useMediaQuery } from 'heeds-ds/src/hooks'

// Components
import * as Styled from './styled'
import { Aligner, Skeleton } from 'heeds-ds'

const Loader: FC = () => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  return (
    <Styled.LoadContainer data-testid="row">
      {isDesktop ? (
        <Aligner gap="8rem" margin="0 0 0 5.5rem">
          <Skeleton width="30rem" height="1.6rem" />
          <Skeleton width="15rem" height="1.6rem" />
          <Skeleton width="10rem" height="1.6rem" margin="0 0 0 10rem" />
        </Aligner>
      ) : (
        <Aligner flex="column" gap="0.8rem">
          <Skeleton width="18rem" height="2.1rem" />
          <Skeleton width="6rem" height="1.9rem" />
        </Aligner>
      )}
    </Styled.LoadContainer>
  )
}

export default Loader
