// Libraries
import styled from 'styled-components'
import { css } from 'styled-components'

// Components
import { Body } from 'heeds-ds'

type Props = {
  collapsed?: boolean
  hasDescription?: boolean
  height?: string
  margin?: string
  maxWidth?: string
  padding?: string
  startsOpen?: boolean
}

export const Container = styled.div<Props>`
  ${({ collapsed, hasDescription, height, margin, padding, theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    height: ${height || 'auto'};
    margin: ${margin || '0'};
    max-height: ${collapsed ? (hasDescription ? 'none' : '68px') : '100%'};
    padding: ${padding || '2.4rem'};
    transition: all 0.3s ease;
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: grid;
      grid-template-columns: 30% 70%;
    }
  `};
`

export const Content = styled.div<Props>`
  ${({ collapsed, maxWidth }) => css`
    display: ${collapsed ? 'none' : 'block'};
    max-height: ${collapsed ? '0' : '100%'};
    max-width: ${maxWidth || '100%'};
  `}
`

export const Description = styled.div`
  color: ${({ theme }) => theme.colors.text.subdued};
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: ${({ theme }) => theme.fontSize.copy3};
  line-height: 19px;
  margin-top: 8px;
`

export const Title = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.default,
  type: 'copy3',
  weight: 700,
}))``

export const TopContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Header = styled.div<Props>`
  display: flex;
  gap: 2rem;
  justify-content: ${({ collapsed }) =>
    collapsed || !collapsed ? 'space-between' : 'flex-start'};
  width: 100%;
`
