// Models
import { EFirebaseProviderOptions, IAuthState } from 'storage/auth/models'
import IStore from 'lib/redux/models'

// React
import { FC, ReactNode, useContext, useLayoutEffect } from 'react'

// Libraries
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

// Misc
import { urls } from 'routes/paths'
import { useMediaQuery, useMenu } from 'hooks'

// Components
import { Container, Content } from './styled'
import { Steps, Subtitle } from 'heeds-ds'
import { ThemeContext } from 'styled-components'

type Props = {
  children?: ReactNode | ReactNode[]
}

const STEPS = [
  { label: 'DADOS GERAIS', path: urls.profileGeneral },
  { label: 'SENHA', path: urls.profilePassword },
]

const ProfileLayout: FC<Props> = ({ children }) => {
  const { userData } = useSelector<IStore, IAuthState>((state) => state.auth)
  const { setPagename } = useMenu()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const filteredSteps = STEPS.filter((step) => {
    if (userData?.provider === EFirebaseProviderOptions.EMAIL) return true
    return step.label !== 'SENHA'
  })

  useLayoutEffect(() => {
    setPagename('Perfil')

    return () => setPagename('Dashboard')
  }, [setPagename])

  return (
    <Container>
      {isDesktop && (
        <Subtitle type="sh1" weight={700}>
          Meu Perfil
        </Subtitle>
      )}
      <Content>
        <Steps disabled={false} steps={filteredSteps} />
        {children || <Outlet />}
      </Content>
    </Container>
  )
}

export default ProfileLayout
