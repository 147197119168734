// Models
import { IBaseState } from 'lib/redux/models'

export enum EFinancialSubscriptionActionTypes {
  CLEANUP = 'FINANCIAL_SUBSCRIPTION/CLEANUP',
  FAILURE = 'FINANCIAL_SUBSCRIPTION/FAILURE',
  FULFILL = 'FINANCIAL_SUBSCRIPTION/FULFILL',
  REQUEST = 'FINANCIAL_SUBSCRIPTION/REQUEST',
  SUCCESS = 'FINANCIAL_SUBSCRIPTION/SUCCESS',
  SUBSCRIPTION_DEACTIVATE = 'FINANCIAL_SUBSCRIPTION/DEACTIVATE',
  SUBSCRIPTION_ACTIVATE = 'FINANCIAL_SUBSCRIPTION/ACTIVATE',
}

export interface IFinancialSubscriptionState extends IBaseState {
  subscription?: ISubscriptionData
}

export interface ISubscriptionData {
  auto_renew: boolean
  discount: number
  frequency: number
  due_date: string
  start_date: string
  status: TSubscriptionStatus
  athlete: number
  personal: number
  plan: number
  plans_extras: Set<number>
  spread: boolean
}

export type TSubscriptionStatus = 'active' | 'inactive'
