// Models
import { IBaseState } from 'lib/redux/models'
import { IPaginatedResponse } from 'models/api'
import { ILoadAnamneseTemplatesSendResponse } from 'services/assessmentAnamneseTemplate/@types'
import { IAnamneseSection } from 'models/anamnese'

export enum EAnamneseTemplateActionTypes {
  CLEANUP = 'ANAMNESE_TEMPLATE/CLEANUP',
  FAILURE = 'ANAMNESE_TEMPLATE/FAILURE',
  FULFILL = 'ANAMNESE_TEMPLATE/FULFILL',
  REQUEST = 'ANAMNESE_TEMPLATE/REQUEST',
  SUCCESS = 'ANAMNESE_TEMPLATE/SUCCESS',
  CREATE = 'ANAMNESE_TEMPLATE/CREATE',
  GET = 'ANAMNESE_TEMPLATE/GET',
  UPDATE = 'ANAMNESE_TEMPLATE/UPDATE',
  PARTIAL_UPDATE = 'ANAMNESE_TEMPLATE/PARTIAL_UPDATE',
  DELETE = 'ANAMNESE_TEMPLATE/DELETE',
  LOAD = 'ANAMNESE_TEMPLATE/LOAD',
  LOAD_MORE = 'ANAMNESE_TEMPLATE/LOAD_MORE',
  LOAD_SENT = 'ANAMNESE_TEMPLATE/LOAD_SENT',
  SEND = 'ANAMNESE_TEMPLATE/SEND',
  SUCCESS_LOAD_MORE = 'ANAMNESE_TEMPLATE/SUCCESS_LOAD_MORE',
}

export interface IAnamneseTemplateState extends IBaseState, IPaginatedResponse {
  anamneseTemplate?: IAnamneseTemplate
  anamneseTemplates?: IAnamneseTemplate[]
  athletesSendTemplate?: ILoadAnamneseTemplatesSendResponse
}

export interface IAnamneseTemplate {
  date?: string
  description?: string
  id: string
  is_being_used?: boolean
  personal: number
  sections: IAnamneseSection[]
  title: string
}
