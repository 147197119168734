// Models
import { IAnamneseQuestion } from 'models/anamnese'

// Components
import * as Styled from './styled'
import { Aligner, Body, Icon, InputCheckbox, Radio } from 'heeds-ds'
import { dateFormatEnglishToPortuguese } from 'utils/functions'

type Props = Pick<IAnamneseQuestion, 'allowed_values' | 'answers'> & {
  hasAnswer?: boolean
}

const DateAnswer = ({ answers = [] }: Props) => (
  <Aligner gap="0.8rem" align="center">
    <Styled.InputWithIcon width="250px">
      <Styled.InputText
        disabled
        value={dateFormatEnglishToPortuguese(answers[0])}
        placeholder="A data do seu aluno vem aqui"
        width="250px"
      />
      <Styled.IconWrapper>
        <Icon iconName="calendar" size={20} />
      </Styled.IconWrapper>
    </Styled.InputWithIcon>
  </Aligner>
)

const DropdownList = ({ answers = [] }: Props) => (
  <Aligner gap="0.8rem" align="center">
    <Styled.InputWithIcon width="250px">
      <Styled.InputText
        disabled
        value={answers[0]}
        placeholder="A escolha do seu aluno vem aqui"
        width="250px"
      />
      <Styled.IconWrapper>
        <Icon iconName="expandMore" size={20} />
      </Styled.IconWrapper>
    </Styled.InputWithIcon>
  </Aligner>
)

const Measures = ({ allowed_values = [], answers = [] }: Props) => (
  <Aligner gap="0.8rem" align="center">
    <Styled.InputText
      disabled
      value={answers[0]}
      placeholder="A medida do seu aluno vem aqui"
      width="220px"
    />
    <Body type="copy3">{allowed_values[0]}</Body>
  </Aligner>
)

const MultipleChoices = ({ allowed_values = [], answers = [] }: Props) => (
  <Aligner
    gap={allowed_values.length > 2 ? '1.6rem' : '4rem'}
    flex={allowed_values.length > 2 ? 'column' : 'row'}
  >
    {allowed_values?.map((answerItem) => {
      return (
        <Radio
          disabled
          key={answerItem}
          label={answerItem}
          checked={answers.includes(answerItem)}
        />
      )
    })}
  </Aligner>
)

const SelectBox = ({ allowed_values = [], answers = [] }: Props) => (
  <Aligner gap="0.8rem" align="center">
    <Styled.SelectBoxContainer>
      {allowed_values.map((item) => (
        <Styled.Label key={item}>
          <InputCheckbox checked={answers.includes(item)} disabled />
          {item}
        </Styled.Label>
      ))}
    </Styled.SelectBoxContainer>
  </Aligner>
)

const TextAnswer = ({ answers = [] }: Props) => (
  <Aligner gap="0.8rem" align="center">
    <Styled.InputText
      scale="small"
      disabled
      value={answers[0]}
      placeholder="A resposta do seu aluno vem aqui"
    />
  </Aligner>
)

export {
  DateAnswer,
  DropdownList,
  Measures,
  MultipleChoices,
  SelectBox,
  TextAnswer,
}
