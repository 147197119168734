// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body, Icon } from 'heeds-ds'

// Assets
import imageEmpty from 'assets/images/GymEmpty.png'

type Props = {
  isDesktop?: boolean
}

export const AddIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.colors.background.default,
  iconName: 'add',
}))``

export const ContainerBottom = styled.div`
  backdrop-filter: blur(1.5px);
  bottom: 0px;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 1.2rem 3.2rem 2.4rem 3.2rem;
  position: fixed;
  width: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    background-color: ${({ theme }) => theme.colors.surface.default};
    border-radius: 1.5rem;
    box-shadow: 0px 4px 80px 0px rgba(43, 42, 99, 0.1);
    padding: 0;
  }
`

export const EmptyContainer = styled(Aligner).attrs(() => ({
  align: 'center',
  flex: 'column',
  gap: '3.2rem',
  justify: 'center',
}))<Props>`
  flex-grow: 1;
`

export const EmptyText = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.subdued,
  type: 'copy3',
}))`
  text-align: center;
  width: 26.8rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
  }
`

export const FiltersScrollContainer = styled(Aligner).attrs(() => ({
  justify: 'center',
  align: 'center',
}))`
  min-height: 6.6rem;
  padding: 1.6rem 0 1.2rem;
  overflow-x: scroll;
  overflow-y: hidden;
  transition: height 0.5s;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    min-height: 5rem;
    padding: 0.8rem 2.4rem;
  }
`

export const GymEmpty = styled.div`
  background: url(${imageEmpty}) center no-repeat;
  height: 25rem;
  width: 100%;
`

export const SearchContainer = styled(Aligner).attrs(() => ({
  align: 'center',
  gap: '3.2rem',
}))`
  flex-direction: column;
  padding: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: row;
    padding: 2.4rem 2.4rem 1.6rem;
  }
`
