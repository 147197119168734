// React
import React, { forwardRef } from 'react'

// Components
import * as Styled from './styled'
import Icon from '../../../UI/Icons'

type InputAttrs = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'>

type Props = InputAttrs & {
  'data-testid'?: string
  isFocused?: boolean
  scale?: 'large' | 'medium'
}

const InputCheckbox = forwardRef<HTMLInputElement, Props>(
  ({ checked, scale = 'medium', disabled, isFocused, ...attributes }, ref) => (
    <Styled.Checkbox
      aria-checked={checked}
      aria-disabled={disabled}
      data-testid="styled-input"
      focused={isFocused}
      role="checkbox"
      size={scale}
    >
      <Styled.IconWrapper size={scale}>
        <Icon
          size={scale === 'medium' ? 18 : 24}
          color={checked ? 'white' : '#00000000'}
          iconName="checkmark"
        />
      </Styled.IconWrapper>
      <Styled.Input
        {...attributes}
        checked={checked}
        disabled={disabled}
        ref={ref}
        type="checkbox"
      />
    </Styled.Checkbox>
  ),
)
InputCheckbox.displayName = 'InputCheckbox'

export default InputCheckbox
