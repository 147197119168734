// React
import { forwardRef } from 'react'

// Components
import * as Styled from './styled'
import { Props as ButtonProps } from '../BaseButton'
import StylizedIcons from '../../../components/UI/Icons/StylizedIcons'

export type Props = ButtonProps & {
  provider?: 'google' | 'facebook' | 'apple'
}

const SSOButton = forwardRef<HTMLButtonElement, Props>((props: Props, ref) => {
  const { provider = 'google' } = props
  return (
    <Styled.SocialButton
      ref={ref}
      type="button"
      data-testid="SSOButton"
      {...props}
    >
      <StylizedIcons iconName={provider} size={24} viewBox={24} />
    </Styled.SocialButton>
  )
})
SSOButton.displayName = 'SSOButton'

export default SSOButton
