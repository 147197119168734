// Libraries
import { StyleSheet, Font } from '@react-pdf/renderer'

// Assets
import RobotoBold from 'assets/fonts/roboto-bold.ttf'
import RobotoMedium from 'assets/fonts/roboto-medium.ttf'
import RobotoRegular from 'assets/fonts/roboto-regular.ttf'

Font.register({
  family: 'Roboto',
  fonts: [
    { src: RobotoRegular, fontWeight: 400 },
    { src: RobotoMedium, fontWeight: 500 },
    { src: RobotoBold, fontWeight: 700 },
  ],
})

export const Styles = StyleSheet.create({
  topLine: {
    backgroundColor: '#2B2A63',
    height: 8,
    marginBottom: 32,
    marginTop: -35,
  },
  workoutTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 24,
    paddingBottom: 16,
    marginTop: 26,
    width: '100%',
    gap: 57,
  },
  line: {
    backgroundColor: '#2B2A63',
    height: 2,
    width: '100%',
    marginLeft: 57,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 35,
  },
  copy4: {
    maxWidth: '250px',
    fontSize: 14,
    fontFamily: 'Roboto',
    color: '#2B2A63',
    fontWeight: 700,
    backgroundColor: '#FFFFFF',
  },
  small: {
    fontSize: 10,
    fontFamily: 'Roboto',
    color: '#6D7175',
  },
  smallTitle: {
    fontSize: 10,
    fontFamily: 'Roboto',
    color: '#6D7175',
    marginBottom: 16,
  },
  smallBlue: {
    fontSize: 10,
    fontFamily: 'Roboto',
    color: '#2B2A63',
    fontWeight: 500,
  },
  smallBlack: {
    fontSize: 10,
    fontFamily: 'Roboto',
    color: '#202223',
  },
  image: {
    height: '45px',
    width: '117px',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 24,
    paddingRight: 37,
    paddingBottom: 14,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  TableWrapper: {
    paddingHorizontal: 24,
    flexDirection: 'row',
    alignItems: 'center',
  },
  workoutTableInner: {
    marginTop: 16,
    flexDirection: 'row',
  },
  Column: {
    flexDirection: 'column',
  },
  workoutTableContainer: {
    flexDirection: 'row',
  },
  workoutLine: {
    marginTop: 16,
  },
  workoutLineLines: {
    backgroundColor: '#F6F6F7',
    height: 1,
    marginBottom: 8,
    marginHorizontal: 24,
  },
})
