import { TWeekdays } from 'models'

type TRecurringTimes = {
  fri?: boolean
  mon?: boolean
  sat?: boolean
  sun?: boolean
  thu?: boolean
  tue?: boolean
  wed?: boolean
}

type daysType = {
  day: number
  isToday: boolean
  month: number
  weekDay: number
  year: number
}

export const WEEKDAYS: TWeekdays[] = [
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
  'sun',
]

export const DAYS_NUMBER: Record<number, TWeekdays> = {
  0: 'sun',
  1: 'mon',
  2: 'tue',
  3: 'wed',
  4: 'thu',
  5: 'fri',
  6: 'sat',
}

export const weekDays = (type?: string) => {
  const baseDate = new Date(Date.UTC(2017, 0, 2))
  const weekDays = []
  for (let i = 0; i < 7; i++) {
    weekDays.push(
      baseDate.toLocaleDateString('defaut', {
        weekday: type === 'long' ? 'long' : 'short',
      }),
    )
    baseDate.setDate(baseDate.getDate() + 1)
  }
  return weekDays
}

export const rangeGenerator = (start: number, end: number) => {
  const range = Array(end - start + 1)
    .fill(start)
    .map((item, index) => item + index)
  return range
}

export const generateHoursInterval = (
  startHourInMinute: number,
  endHourInMinute: number,
  interval: number,
) => {
  const times = []
  for (let i = 0; startHourInMinute <= 24 * 60; i++) {
    if (startHourInMinute > endHourInMinute) break
    const hh = Math.floor(startHourInMinute / 60)
    const mm = startHourInMinute % 60
    mm === 0
      ? (times[i] = (hh % 24) + ':00')
      : (times[i] = (hh % 24) + ':' + mm)
    startHourInMinute = startHourInMinute + interval
  }
  return [
    ...times.slice(0, times.length - 1),
    times[times.length - 1] === '0h' ? '23h59' : times[times.length - 1],
  ]
}

export const addLeftZero = (num: number, totalLength: number) => {
  if (num < 0) {
    const withoutMinus = String(num).slice(1)
    return '-' + withoutMinus.padStart(totalLength, '0')
  }
  return String(num).padStart(totalLength, '0')
}

export const getMonthName = (month: number, language?: string) => {
  const date = new Date()
  date.setMonth(month)
  return date.toLocaleString(language === 'english' ? 'en-GB' : 'default', {
    month: 'long',
  })
}

export const getMonthLength = (month: number, year: number) => {
  return new Date(year, month, 0).getDate()
}

export const handleFirstDayOfTheMonth = (weekDay: string) => {
  switch (weekDay) {
    case 'Sun':
      return 0
    case 'Mon':
      return 1
    case 'Tue':
      return 2
    case 'Wed':
      return 3
    case 'Thu':
      return 4
    case 'Fri':
      return 5
    case 'Sat':
      return 6
  }
}

export const weekdayName = (weekDay?: number, type?: string) => {
  const arrayOfWeekdays = weekDays(type === 'long' ? 'long' : 'short')
  const dateObj = new Date() || weekDay
  const weekdayNumber = dateObj.getDay()
  const weekdayName = arrayOfWeekdays[weekdayNumber]
  return weekdayName
}

export const selectedWeekdayName = (weekDay: number, type?: string) => {
  switch (weekDay) {
    case 0:
      return weekDays(type)[0]
    case 1:
      return weekDays(type)[1]
    case 2:
      return weekDays(type)[2]
    case 3:
      return weekDays(type)[3]
    case 4:
      return weekDays(type)[4]
    case 5:
      return weekDays(type)[5]
    case 6:
      return weekDays(type)[6]
  }
}

export const addDays = (date: string | number | Date, days: number) => {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return `${result.getFullYear()}-${result.getMonth()}-${result.getDate()}`
}

export const getDatesBetweenDates = (month: number, year: number) => {
  const date = new Date(year, month, 1)
  let days: daysType[] = []
  while (date.getUTCMonth() === month) {
    days = [
      ...days,
      {
        day: new Date(date).getDate(),
        year: new Date(date).getFullYear(),
        month: new Date(date).getMonth() + 1,
        weekDay: new Date(date).getDay(),
        isToday: new Date(date).getDate() === new Date().getDate(),
      },
    ]
    date.setDate(date.getDate() + 1)
  }
  return days
}

export const GenerateMonthDisplayCalendar = (
  entryMonth: number,
  entryYear: number,
) => {
  const MonthBeforeList = getDatesBetweenDates(
    entryMonth - 2 === -1 ? 11 : entryMonth - 2,
    entryMonth - 1 === 0 ? entryYear - 1 : entryYear,
  )
  const currentMonthList = getDatesBetweenDates(entryMonth - 1, entryYear)
  const MonthAfterList = getDatesBetweenDates(
    entryMonth === 12 ? 0 : entryMonth,
    entryMonth === 12 ? entryYear + 1 : entryYear,
  )
  const monthAfterListSliced = MonthAfterList.slice(
    0,
    6 - currentMonthList[currentMonthList.length - 1]?.weekDay,
  )
  const MonthBeforeListSliced =
    currentMonthList[0]?.weekDay === 0
      ? []
      : MonthBeforeList.slice(-Math.abs(currentMonthList[0]?.weekDay))

  const MonthAll = [
    ...MonthBeforeListSliced,
    ...currentMonthList,
    ...monthAfterListSliced,
  ]

  return MonthAll
}

export const weekYear = (theDate: string) => {
  const currentDate = new Date(theDate)
  const year = new Date(currentDate.getFullYear(), 0, 1)
  const days = Math.floor(
    (currentDate.valueOf() - year.valueOf()) / (24 * 60 * 60 * 1000),
  )
  const week = Math.ceil((currentDate.getDay() + 1 + days) / 7)
  return week
}

export const ConvertRecurringTime = (recurringTimes: TRecurringTimes) => {
  const conflict = [
    { index: 0, active: recurringTimes?.sun },
    { index: 1, active: recurringTimes?.mon },
    { index: 2, active: recurringTimes?.tue },
    { index: 3, active: recurringTimes?.wed },
    { index: 4, active: recurringTimes?.thu },
    { index: 5, active: recurringTimes?.fri },
    { index: 6, active: recurringTimes?.sat },
  ]
    .filter((value) => {
      return value.active === true
    })
    .map((value) => {
      return value.index
    })
  return conflict
}

export const handleRecurringDaysBooleanList = (
  selectedDays: string | string[],
) => {
  const recurringDays = {
    sun: selectedDays?.includes('sun'),
    mon: selectedDays?.includes('mon'),
    tue: selectedDays?.includes('tue'),
    wed: selectedDays?.includes('wed'),
    thu: selectedDays?.includes('thu'),
    fri: selectedDays?.includes('fri'),
    sat: selectedDays?.includes('sat'),
  }
  return recurringDays
}

export const handleDaysNumber = (selectedDays: string | string[]) => {
  const daysNumber = [
    { index: 0, active: selectedDays?.includes('sun'), label: 'Dom' },
    { index: 1, active: selectedDays?.includes('mon'), label: 'Seg' },
    { index: 2, active: selectedDays?.includes('tue'), label: 'Ter' },
    { index: 3, active: selectedDays?.includes('wed'), label: 'Qua' },
    { index: 4, active: selectedDays?.includes('thu'), label: 'Qui' },
    { index: 5, active: selectedDays?.includes('fri'), label: 'Sex' },
    { index: 6, active: selectedDays?.includes('sat'), label: 'Sab' },
  ].filter(function (value) {
    return value.active === true
  })

  return daysNumber
}

export const handleDisplayDays = (selectedDays: string | string[]) => {
  const labels = handleDaysNumber(selectedDays)
    .map((value) => {
      return value.label
    })
    ?.toString()
  const listLabels = handleDaysNumber(selectedDays)
    .map((value) => {
      return value.label
    })
    ?.toString()
    .replace(/,/g, '/')

  switch (labels) {
    case 'Dom' || 'sun':
      return 'Domingos'
    case 'Seg' || 'mon':
      return 'Segundas'
    case 'Ter' || 'tue':
      return 'Terças'
    case 'Qua' || 'wed':
      return 'Quartas'
    case 'Qui' || 'thu':
      return 'Quintas'
    case 'Sex' || 'fri':
      return 'Sextas'
    case 'Sab' || 'sat':
      return 'Sabados'
    case 'Dom,Sab' || 'sun,sat':
      return 'Fins de Semana'
    case 'Seg,Ter,Qua,Qui,Sex' || 'mon,tue,wed,thu,fri':
      return 'Dias da semana'
    case 'Dom,Seg,Ter,Qua,Qui,Sex,Sab' || 'sun,mon,tue,wed,thu,fri,sat':
      return 'Todos os dias'
    case 'Dom,2' || 'sun,2':
      return 'Todos os dias'
    default:
      return listLabels
  }
}

export const handleSelectedDays = (
  days: number[],
  selectedDays?: string | string[],
): TWeekdays[] => {
  const allDays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
  const selectedDaysEng = days
    ?.map((value) => {
      return DAYS_NUMBER[value]
    })
    ?.filter((value) => {
      return selectedDays
        ? selectedDays.includes(value)
        : allDays.includes(value)
    })

  return selectedDaysEng
}

export const upperCaseFirstLetter = (value: string) => {
  return value[0].toUpperCase() + value.substring(1)
}
