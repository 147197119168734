// Models
import { IAthleteOverview } from 'services/athleteOverview/@types'
import { IBaseState } from 'lib/redux/models'

export enum EAthleteOverviewActionTypes {
  CLEANUP = 'ATHLETE_OVERVIEW/CLEANUP',
  FAILURE = 'ATHLETE_OVERVIEW/FAILURE',
  FULFILL = 'ATHLETE_OVERVIEW/FULFILL',
  REQUEST = 'ATHLETE_OVERVIEW/REQUEST',
  SUCCESS = 'ATHLETE_OVERVIEW/SUCCESS',
  GET_OVERVIEW = 'ATHLETE_OVERVIEW/GET_OVERVIEW',
}

export interface IAthleteOverviewState extends IBaseState {
  overview?: IAthleteOverview
}
