// Libraries
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    padding: 40px 48px 0px 48px;
  }
`

export const Content = styled.div`
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 2.4rem 2.4rem 0 0;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  min-height: calc(100vh - 7rem);
  padding: 0rem 0rem 6.2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    border-radius: 1.5rem;
    gap: 1.6rem;
    min-height: auto;
    padding: 4rem 2.4rem 6.2rem 4rem;
  }
`
