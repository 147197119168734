// Models
import {
  IWorkoutModelData,
  IWorkoutModelState,
} from 'storage/workoutModel/models'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useEffect, useState } from 'react'

// Libraries
import { useDispatch, useSelector } from 'react-redux'
import { ThemeContext } from 'styled-components'

// Misc
import { getMuscleGroups } from 'utils/functions'
import { triggerListWorkoutModelTemplate } from 'storage/workoutModel/duck'
import { useDebounceFunction, useModal } from 'hooks'

// Components
import * as Styled from './styled'
import { Body, Input, InputSearch, List, Tag } from 'heeds-ds'
import { Modal } from 'components/modals'

// Constants
const page_size = 7

type Props = {
  onSelectWorkoutModel?: (item: IWorkoutModelData) => void
}

const ModalWorkoutModelTemplates: FC<Props> = ({ onSelectWorkoutModel }) => {
  const { workoutModelsTemplates, loading } = useSelector<
    IStore,
    IWorkoutModelState
  >((state) => state.workoutModel)
  const { closeModal } = useModal()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)

  const [selectedModel, setSelectedModel] = useState<IWorkoutModelData>()
  const [search, setSearch] = useState('')

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
    debouncedLoad(event.target.value)
  }

  const handleEndReached = () => {
    if (workoutModelsTemplates && !loading && workoutModelsTemplates?.next) {
      const query = {
        name: search,
        page: workoutModelsTemplates.next,
        page_size,
      }
      dispatch(triggerListWorkoutModelTemplate(query))
    }
  }

  const handleReloadAthletes = (name: string) => {
    const query = {
      name: name,
      page_size,
    }
    dispatch(triggerListWorkoutModelTemplate(query))
  }

  const debouncedLoad = useDebounceFunction<(newFilters: string) => void>(
    handleReloadAthletes,
    1000,
  )

  const handleAddWorkoutModel = () => {
    selectedModel && onSelectWorkoutModel?.(selectedModel)
    closeModal()
  }

  const secondaryButton = {
    onClick: closeModal,
    name: 'Cancelar',
  }
  const primaryButton = {
    onClick: handleAddWorkoutModel,
    name: 'Adicionar',
    disabled: !selectedModel,
  }

  const renderItem = (item: IWorkoutModelData) => {
    return (
      <Styled.TagContainer>
        <Tag
          active={selectedModel?.id === item.id}
          key={item.id}
          onClick={() => setSelectedModel(item)}
        >
          <Styled.TagInner>
            <Styled.RadioContainer>
              <label htmlFor={item.name}>
                <Input
                  data-testid={`radioTagInput${item.id}`}
                  type="radio"
                  id={item.name}
                  value={item.id}
                  onChange={() => setSelectedModel(item)}
                  checked={selectedModel?.id === item.id}
                />
              </label>
            </Styled.RadioContainer>
            <Styled.TagLabelContainer>
              <Body
                type="copy3"
                color={theme.colors.text.secondary}
                weight={600}
              >
                {item.name}
              </Body>
              <Body
                type="copy4"
                color={theme.colors.text.secondary}
                weight={400}
              >
                {getMuscleGroups(item)}
              </Body>
            </Styled.TagLabelContainer>
          </Styled.TagInner>
        </Tag>
      </Styled.TagContainer>
    )
  }

  useEffect(() => {
    if (workoutModelsTemplates === undefined) {
      dispatch(triggerListWorkoutModelTemplate({}))
    }
  }, [dispatch, workoutModelsTemplates])

  return (
    <Modal
      title="Adicionar modelo de treino"
      description="Selecione qual treino você vai adicionar para o seu aluno."
      secondaryButton={secondaryButton}
      primaryButton={primaryButton}
    >
      <Styled.Content>
        <InputSearch
          onChange={onSearchChange}
          value={search}
          placeholder="Pesquisar"
        />
        <Styled.ScrollModal>
          <List<IWorkoutModelData>
            data={workoutModelsTemplates?.results || []}
            keyExtractor={({ id }) => id}
            renderItem={renderItem}
            onEndReached={handleEndReached}
          />
        </Styled.ScrollModal>
      </Styled.Content>
    </Modal>
  )
}

export default ModalWorkoutModelTemplates
