// Libraries
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { PersistGate } from 'redux-persist/integration/react'

// Misc
import { theme } from 'lib/styled-components/theme'
import { ModalProvider } from 'hooks/useModal'
import { MenuProvider } from 'hooks/useMenu'
import { ToastProvider } from 'hooks/useToast'
import store, { persistor } from 'lib/redux'

// Fonts
import '@fontsource/barlow'
import '@fontsource/roboto'

ChartJS.register(ArcElement, Tooltip, Legend)

interface IProps {
  children: JSX.Element | JSX.Element[]
}

function AppProviders({ children }: IProps) {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <ModalProvider>
            <ToastProvider>
              <MenuProvider>{children}</MenuProvider>
            </ToastProvider>
          </ModalProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default AppProviders
