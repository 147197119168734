// Libraries
import styled from 'styled-components'

export const FormContainer = styled.form`
  width: 100%;
  padding-bottom: 1.6rem;
`

export const Image = styled.img`
  width: 90%;
`
