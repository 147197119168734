// Libraries
import styled from 'styled-components'

type OptionsProps = {
  alignment: 'row' | 'column'
  disabled?: boolean
}

export const OptionsWrapper = styled.div<OptionsProps>`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
  display: flex;
  gap: ${({ alignment }) => (alignment === 'row' ? 4 : 2.4)}rem;
  flex-direction: ${({ alignment }) => alignment};
  margin-top: 1.2rem;
`
