// React
import { FC } from 'react'

// Misc
import { buttonClickTracking } from 'utils/tracking'

// Components
import * as Styled from '../styled'
import { LinkButton } from 'heeds-ds'

// Assets
import appStoreImage from 'assets/images/apple-logo.svg'
import googlePlayImage from 'assets/images/google-play-logo.svg'
import successImage from 'assets/images/done-all.png'

// Constants
const APP_STORE = 'https://apps.apple.com/br/app/cadu-fit/id6450973922'
const GOOGLE_PLAY =
  'https://play.google.com/store/apps/details?id=fit.cadu&pcampaignid=web_share'

const SuccessfullyConfirmed: FC = () => {
  return (
    <Styled.Container>
      <Styled.Image src={successImage} />

      <Styled.TitleS>Deu tudo certo!</Styled.TitleS>

      <Styled.SubtitleS>
        Seu email foi confirmado no nosso sistema.
      </Styled.SubtitleS>

      <Styled.SmallText>
        Acesse o CADU através do nosso aplicativo.
      </Styled.SmallText>

      <Styled.AlignButtons>
        <LinkButton
          size="xsmall"
          to={GOOGLE_PLAY}
          track={buttonClickTracking}
          trackName="open_app_store"
          variation="outlined"
        >
          <Styled.ImageStores src={googlePlayImage} />
          Google Play
        </LinkButton>

        <LinkButton
          size="xsmall"
          to={APP_STORE}
          track={buttonClickTracking}
          trackName="open_app_store"
          variation="outlined"
        >
          <Styled.ImageStores src={appStoreImage} />
          App Store
        </LinkButton>
      </Styled.AlignButtons>
    </Styled.Container>
  )
}

export default SuccessfullyConfirmed
