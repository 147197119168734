// Models
import { TDisplayModes } from '.'

// Libraries
import styled, { css } from 'styled-components'

interface ICalendarProps {
  displayMode: TDisplayModes
}

interface IDayProps {
  edge?: boolean
  endDay?: boolean
  interval?: boolean
  isToday?: boolean
  startDay?: boolean
}

const dayInteractions = css`
  &[aria-selected='true'] {
    color: #fff;
    background-color: ${({ theme }) => theme.colors.action.secondaryDefault};
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  }
  :hover {
    color: #fff;
    background-color: ${({ theme }) => theme.colors.interactive.hovered};
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  }
  :focus {
    border: 1px solid black;
  }
`

export const CalendarGrid = styled.div.attrs({ role: 'grid' })<ICalendarProps>`
  display: grid;
  grid-template-columns: ${({ displayMode }) =>
    displayMode === 'day' ? 'repeat(7, 36px)' : 'repeat(4, 62px)'};
  width: 100%;
`

export const CalendarItem = styled.button<IDayProps>`
  cursor: pointer;
  outline: none;
  color: ${({ isToday, theme }) =>
    isToday ? theme.colors.badge.purpleText : theme.colors.text.default};
  background-color: ${({ isToday, theme }) =>
    isToday ? theme.colors.badge.purpleBackground : 'transparent'};
  border: none;
  border-radius: 4px;
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-weight: ${({ isToday, theme }) =>
    theme.fontWeight[isToday ? 'semiBold' : 'regular']};
  height: 52px;
  width: 62px;

  ${dayInteractions}
`

export const Container = styled.div`
  user-select: none;
  width: fit-content;
  padding: 20px;
  background-color: white;
`

export const DayItem = styled.button.attrs({
  role: 'gridcell',
  type: 'button',
})<IDayProps>`
  cursor: pointer;
  outline: none;
  border: none;
  width: 36px;
  height: 30px;
  font-size: ${({ edge }) => (edge ? 10 : 12)}px;
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-weight: ${({ isToday, theme }) =>
    theme.fontWeight[isToday ? 'semiBold' : 'regular']};
  color: ${({ edge, isToday, theme }) =>
    edge
      ? theme.colors.text.disabled
      : isToday
      ? theme.colors.badge.purpleText
      : theme.colors.text.default};
  background-color: ${({ interval, isToday, theme }) =>
    interval || isToday ? theme.colors.badge.purpleBackground : 'transparent'};

  border-radius: ${({ interval }) => (interval ? 0 : '4px')};
  ${({ startDay }) => startDay && 'border-radius: 15px 0 0 15px;'};
  ${({ endDay }) => endDay && 'border-radius: 0 15px 15px 0;'};

  :focus {
    border: 1px solid black;
  }
  ${({ edge }) => !edge && dayInteractions}
`

export const Header = styled.div<ICalendarProps>`
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${({ displayMode }) => (displayMode !== 'day' ? '0 0 16px' : '0')};
`

export const HeaderIcon = styled.button.attrs(() => ({
  type: 'button',
}))`
  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  :focus {
    border: 1px solid black;
    border-radius: 3px;
  }
`

export const Title = styled.button.attrs(() => ({
  type: 'button',
}))`
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.text.secondary};
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: ${({ theme }) => theme.fontSize.copy3};
  line-height: ${({ theme }) => theme.lineHeight.copy4};
  padding: 4px 8px;

  :focus {
    border: 1px solid black;
    border-radius: 3px;
  }
  :hover {
    color: ${({ theme }) => theme.colors.text.default};
  }
`

export const Weekday = styled.p`
  outline: none;
  border: none;
  border-radius: 4px;
  width: 36px;
  height: 30px;
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  color: ${({ theme }) => theme.colors.icon.hovered};
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`
