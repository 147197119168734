import styled from 'styled-components'

// Misc
import { ModalPosition } from './index'

interface ModalProps {
  padding?: string
  horizontalAlign?: ModalPosition
  verticalAlign?: ModalPosition
  visible?: boolean
  width?: string
}

interface TypeProps extends ModalProps {
  type: string
}

export const Background = styled.div<ModalProps>`
  align-items: ${({ verticalAlign }) => verticalAlign || 'center'};
  background: rgba(23, 27, 55, 0.35);
  display: flex;
  height: 100vh;
  justify-content: ${({ horizontalAlign }) => horizontalAlign || 'center'};
  left: 0;
  position: fixed;
  top: 0;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  width: 100vw;
  z-index: 99;
`

export const CloseButton = styled.div<ModalProps>`
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 16px;
`

export const Container = styled.div<TypeProps>`
  background: ${({ theme }) => theme.colors.surface.default};
  box-shadow: ${({ type }) =>
    type === 'modal'
      ? '0px 2px 19px rgba(109, 113, 117, 0.3);'
      : '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08)'};
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  width: ${({ width }) => width || '545px'};
  padding: ${({ padding }) => padding || '3.6rem 4rem'};
  position: relative;
`
