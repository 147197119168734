// Libraries
import styled from 'styled-components'

type RadioButtonProps = {
  disabled?: boolean
  radio?: boolean
  active?: boolean
  textRadio?: boolean
  checked?: boolean
  column?: boolean
  gap?: string
  scale?: 'small' | 'medium' | 'large'
}

enum HeightScale {
  small = '4rem',
  medium = '4.8rem',
  large = '5.6',
}

export const Wrapper = styled.div<RadioButtonProps>`
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  gap: ${({ gap }) => gap || '4rem'};
  overflow-x: scroll;
  width: ${({ radio }) => (radio ? 'auto' : '100%')};
  min-height: ${({ scale }) => HeightScale[scale || 'medium']};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    overflow-x: hidden;
  }
`

export const WrapperInner = styled.div<RadioButtonProps>`
  width: 100%;
  display: flex;
  justify-content: ${({ textRadio }) =>
    textRadio ? 'flex-start' : 'space-between'};
  width: ${({ radio }) => (radio ? 'auto' : '100%')};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    justify-content: flex-start;
  }
`

export const Input = styled.input<RadioButtonProps>`
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2.5px solid ${({ theme }) => theme.colors.icon.default};
  border-radius: 100%;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  display: ${({ textRadio }) => textRadio && 'none'};
  cursor: pointer;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 20px;
    height: 20px;
  }
  ::after {
    content: '';
    position: absolute;
    border-radius: 100%;
    width: 65%;
    height: 65%;
  }
  :hover {
    border: 2.5px solid ${({ theme }) => theme.colors.icon.hovered};
    ::after {
      background-color: ${({ theme, disabled }) =>
        disabled ? theme.colors.icon.disabled : theme.colors.icon.hovered};
    }
  }
  :checked {
    ::after {
      background-color: ${({ theme }) => theme.colors.icon.default};
    }
    :hover {
      border: 2.5px solid
        ${({ theme, disabled }) =>
          disabled ? theme.colors.icon.disabled : theme.colors.icon.hovered};
      ::after {
        background-color: ${({ theme }) => theme.colors.icon.hovered};
      }
    }
    :focus {
      border: 2.5px solid ${({ theme }) => theme.colors.focusedDefault};
      ::after {
        background-color: ${({ theme }) => theme.colors.focusedDefault};
      }
    }
  }
  :disabled {
    cursor: not-allowed;
    border: 2.5px solid ${({ theme }) => theme.colors.icon.disabled};
    :hover {
      ::after {
        background-color: transparent;
      }
    }
    :checked {
      ::after {
        background-color: ${({ theme }) => theme.colors.icon.disabled};
      }
    }
  }
`

export const TextRadioWrapper = styled.div<RadioButtonProps>`
  padding: ${({ scale }) =>
    scale && scale === 'small' ? '0.8rem 1.6rem' : '1.6rem'};
  background: ${({ active, theme }) =>
    active ? theme.colors.text.secondary : theme.colors.surface.default};
  border: 1px solid
    ${({ active, theme }) =>
      active ? theme.colors.text.secondary : theme.colors.border.input};
  border-radius: 15px;
  white-space: nowrap;
`

export const Label = styled.label<RadioButtonProps>`
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: ${({ theme }) => theme.fontSize.copy4};
  line-height: 24px;
  margin: 0;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.text.disabled : theme.colors.text.default};
`
