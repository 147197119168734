// Libraries
import styled, { css } from 'styled-components'

// Components
import { Aligner, Body } from 'heeds-ds'

type Props = {
  mode: 'edit' | 'normal'
}

enum EBackground {
  edit = 'default',
  normal = 'subdued',
}

export const Container = styled(Aligner).attrs(() => ({
  align: 'center',
  justify: 'space-between',
}))<Props>`
  border-radius: 2px;

  ${({ mode, theme }) => css`
    background: ${theme.colors.surface[EBackground[mode]]};
    padding: ${mode === 'edit' ? '0' : '1.6rem 2.4rem 1.6rem 1.6rem'};
  `}

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    ${({ mode, theme }) =>
      mode === 'normal' &&
      css`
        :hover {
          background: ${theme.colors.surface.default};
          border-left: 4px solid ${theme.colors.icon.critical};
        }
      `}
  }
`

export const Description = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.disabled,
  type: 'copy4',
}))``

export const IconButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  align-items: center;
  background: none;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0.8rem;
`

export const Location = styled(Body).attrs((props) => ({
  weight: 600,
  type: 'copy3',
  color: props.theme.colors.text.subdued,
}))``

export const NormalStyle = css<Props>`
  padding: 1.6rem 2.4rem 1.6rem 1.6rem;
`

export const Price = styled(Body).attrs((props) => ({
  weight: 600,
  type: 'copy3',
  color: props.theme.colors.text.default,
}))`
  white-space: nowrap;
`

export const Title = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.secondary,
  type: 'copy3',
  weight: 600,
}))`
  white-space: nowrap;
`
