// Models
import { IAthleteOverview } from './@types'

// Misc
import api from 'lib/axios/api'

export const getAthleteOverview = async (
  id: number,
): Promise<IAthleteOverview> => {
  try {
    const { data }: { data: IAthleteOverview } = await api.get(
      `athletes/${id}/overview`,
    )

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}
