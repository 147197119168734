// Models
import { IconsType } from '../../UI/Icons/values'
import { TButtonTrakingEvent } from '../../../models'

// React
import { useContext, forwardRef } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useLocation } from 'react-router-dom'

// Components
import { Props as ButtonProps } from '../BaseButton'
import { Button } from './styled'
import Icon from '../../UI/Icons'

export type Props = ButtonProps & {
  iconName?: IconsType
  track?: (event: TButtonTrakingEvent) => void
  trackName?: string
}

enum EIconSizes {
  xsmall = 12,
  small = 18,
  medium = 20,
  large = 24,
}

const IconButton = forwardRef<HTMLButtonElement, Props>((props: Props, ref) => {
  const {
    disabled,
    cancel,
    size = 'medium',
    variation = 'outlined',
    iconName,
    track,
    trackName,
  } = props
  const { search } = useLocation()
  const theme = useContext(ThemeContext)

  const iconColors = {
    main: theme.colors.icon.onPrimary,
    outlined: theme.colors.icon[disabled ? 'disabled' : 'default'],
    borderless: theme.colors.interactive[disabled ? 'disabled' : 'default'],
    success: theme.colors.icon.onPrimary,
    cancel: theme.colors.icon.critical,
  }

  const tracking = track
    ? () =>
        track({
          eventName: 'click',
          action: `click_button_${trackName}`,
          location: window.location.pathname + search,
        })
    : undefined

  return (
    <Button
      ref={ref}
      data-testid="IconButton"
      type="button"
      onlyIcon
      tracking={tracking}
      variation={variation}
      {...props}
    >
      <Icon
        size={EIconSizes[size]}
        color={iconColors[cancel ? 'cancel' : variation]}
        iconName={iconName}
      ></Icon>
    </Button>
  )
})
IconButton.displayName = 'Button'

export default IconButton
