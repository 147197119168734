// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body } from 'heeds-ds'

type Props = {
  active?: boolean
}

export const Container = styled(Aligner)`
  gap: 16px;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    background: ${({ theme }) => theme.colors.surface.default};
    border-radius: 15px;
    box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
    gap: 4rem;
    justify-content: center;
    overflow: hidden;
    padding: 18px 24px;
  }
`

export const Label = styled(Body).attrs(() => ({
  type: 'copy3',
  weight: 700,
}))<Props>`
  color: ${({ active, theme }) =>
    theme.colors.text[active ? 'secondary' : 'subdued']};
  white-space: nowrap;
`

export const Line = styled.div<Props>`
  background: ${({ active, theme }) =>
    active ? theme.colors.text.secondary : theme.colors.surface.neutralDefault};
  border-radius: 4px;
  height: 4px;
`

export const Tab = styled(Aligner).attrs(() => ({
  flex: 'column',
}))`
  gap: 8px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    cursor: pointer;
    gap: 16px;
    width: fit-content;
  }
`
