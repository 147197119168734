// Libs
import * as Tabs from '@radix-ui/react-tabs'
import styled, { css } from 'styled-components'

// Components
import { Body, LinkButton } from 'heeds-ds'

export const Comments = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-height: 15rem;
    padding: 1.6rem;
    border-radius: 3px;
    border: 1px solid ${theme.colors.border.input};
    font-size: 1.4rem;
    font-family: ${theme.fontFamily.roboto};
    color: ${theme.colors.text.default};
    overflow-wrap: break-word;
  `}
`

export const Wrapper = styled(Tabs.Root)`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  padding: 2.4rem;
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: 15px;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
`

export const TabsList = styled(Tabs.List)`
  flex-shrink: 0;
  display: flex;
  gap: 4rem;
  margin-bottom: 2.4rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 1.6rem;
  }
`

export const Tab = styled(Tabs.Trigger)`
  ${({ theme }) => css`
    cursor: pointer;
    appearance: none;
    border: none;
    position: relative;
    background-color: transparent;
    color: ${theme.colors.text.secondary};
    font-family: ${theme.fontFamily.roboto};
    font-weight: 700;
    font-size: 14px;
    text-transform: capitalize;
    line-height: 16px;
    padding-bottom: 1.6rem;
    ::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      border-radius: 2px;
      background-color: ${theme.colors.text.secondary};
    }
    &[data-state='inactive'] {
      color: ${theme.colors.text.subdued};
      ::before {
        background-color: ${theme.colors.surface.neutralDefault};
      }
    }

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      font-size: 12px;
      line-height: 14px;
      padding: 0.8rem;
    }
  `}
`

export const TabContent = styled(Tabs.Content)`
  flex: 1;
  padding-bottom: 44px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding-bottom: 68px;
  }
`

export const ActionButton = styled(LinkButton)`
  width: 100%;
  padding: 1.2rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`

export const EmptyText = styled(Body).attrs({ type: 'copy3' })`
  color: ${({ theme }) => theme.colors.text.disabled};
  text-align: center;
  margin: 4rem auto;
`
