import styled from 'styled-components'

type CalendarProps = {
  outlined?: boolean
  saved?: boolean
  margin?: string
}

export const CalendarContainer = styled.div`
  width: 100%;
  height: 73vh;
  position: relative;
  overflow: scroll;
  margin-top: 20px;
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 16px 29px;
  border-radius: 24px 24px 0 0;
  background-color: ${({ theme }) => theme.colors.surface.default};
`

export const HeaderTitleContainer = styled.div`
  margin: 18px 0 48px;
  ::after {
    content: '';
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.background.hovered};
    position: absolute;
    left: 0;
    margin-top: 16px;
  }
`

export const Title = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

export const CalendarBody = styled.div`
  display: flex;
  width: 100%;
  padding: 0 16px;
`

export const WeekDays = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  background-color: ${({ theme }) =>
    theme.colors.surface.highlightSubduedHovered};
  p {
    margin: 6px 0px;
    text-align: center;
    width: calc(100% / 7);
  }
`

export const Days = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: 20px;
`

export const Day = styled.div<CalendarProps>`
  cursor: pointer;
  position: relative;
  width: calc(100% / 7);
  height: 42px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`

export const CalendarBodyInner = styled.div`
  width: 100%;
`

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
`

export const DisplayTypeContainer = styled.div<CalendarProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  button {
    padding: 8px 14px;
    border: 1px solid
      ${({ outlined, theme }) =>
        outlined ? theme.colors.border.input : theme.colors.text.secondary};
  }
`

export const Tag = styled.div<CalendarProps>`
  display: flex;
  flex-direction: row;
  row-gap: 4px;
  margin: 0 1px;
  width: 4px;
  height: 4px;
  background: ${({ saved, theme }) =>
    saved ? theme.colors.text.critical : theme.colors.text.default};
  border-radius: 10px;
`

export const AddNewAthlete = styled.div`
  margin-left: auto;
`

export const AthletesTag = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`
