// Models
import { IAction } from 'lib/redux/models'
import { EWorkoutModelActionTypes, IWorkoutModelState } from '../models'
import {
  IWorkoutCreateModelPayload,
  IWorkoutListModelPayload,
  IWorkoutGetModelPayload,
  IWorkoutUpdateModelPayload,
  IWorkoutDeleteModelPayload,
  IWorkoutCreateModelTemplatePayload,
} from 'services/workoutModel/@types'

// ACTION TYPES
export const Types = {
  CLEANUP: EWorkoutModelActionTypes.CLEANUP,
  FAILURE: EWorkoutModelActionTypes.FAILURE,
  FULFILL: EWorkoutModelActionTypes.FULFILL,
  REQUEST: EWorkoutModelActionTypes.REQUEST,
  SUCCESS: EWorkoutModelActionTypes.SUCCESS,
  CREATE_WORKOUT_MODEL: EWorkoutModelActionTypes.CREATE_WORKOUT_MODEL,
  CREATE_WORKOUT_MODEL_TEMPLATE:
    EWorkoutModelActionTypes.CREATE_WORKOUT_MODEL_TEMPLATE,
  DELETE_WORKOUT_MODEL: EWorkoutModelActionTypes.DELETE_WORKOUT_MODEL,
  GET_WORKOUT_MODEL: EWorkoutModelActionTypes.GET_WORKOUT_MODEL,
  LIST_WORKOUT_MODEL_TEMPLATE:
    EWorkoutModelActionTypes.LIST_WORKOUT_MODEL_TEMPLATE,
  LIST_WORKOUT_MODEL: EWorkoutModelActionTypes.LIST_WORKOUT_MODEL,
  UPDATE_WORKOUT_MODEL: EWorkoutModelActionTypes.UPDATE_WORKOUT_MODEL,
  REMOVE_FROM_STATE: EWorkoutModelActionTypes.REMOVE_FROM_STATE,
  REMOVE_TEMPLATE_FROM_STATE:
    EWorkoutModelActionTypes.REMOVE_TEMPLATE_FROM_STATE,
  CLEAR_MODELS: EWorkoutModelActionTypes.CLEAR_MODELS,
}

// INITIAL STATE
const initialState: IWorkoutModelState = {}

// REDUCER
export default (
  state: IWorkoutModelState = initialState,
  action?: IAction<unknown>,
): IWorkoutModelState => {
  switch (action?.type) {
    case Types.CLEANUP:
      return {
        ...state,
        error: undefined,
        loading: undefined,
        refreshing: undefined,
        workoutModels: undefined,
        workoutModelSuccess: undefined,
      }
    case Types.FAILURE:
      return {
        ...state,
        error: action.payload as string,
      }
    case Types.FULFILL:
      return {
        ...state,
        loading: false,
      }
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.SUCCESS:
      return {
        ...state,
        ...(action?.payload as IWorkoutModelState),
      }
    case Types.CLEAR_MODELS:
      return {
        ...state,
        workoutModels: undefined,
      }
    case Types.REMOVE_FROM_STATE: {
      const { model_pk } = action.payload as { model_pk: string }
      const newmodels =
        state.models?.results?.filter((model) => model.id !== model_pk) || []
      return {
        ...state,
        models: {
          ...state.models,
          results: [...newmodels],
        },
      }
    }
    case Types.REMOVE_TEMPLATE_FROM_STATE: {
      const { model_pk } = action.payload as { model_pk: string }
      const templates = state.workoutModelsTemplates?.results || []
      const newmodels = templates.filter((model) => model.id !== model_pk)
      return {
        ...state,
        workoutModelsTemplates: {
          ...state.workoutModelsTemplates,
          results: [...newmodels],
        },
      }
    }
    default:
      return state
  }
}

// BASE ACTIONS
export const cleanup = (): IAction<undefined> => {
  return {
    type: Types.CLEANUP,
  }
}

export const failure = (payload: string): IAction<string> => {
  return {
    type: Types.FAILURE,
    payload,
  }
}

export const fulfill = (): IAction<undefined> => {
  return {
    type: Types.FULFILL,
  }
}

export const request = (): IAction<undefined> => {
  return {
    type: Types.REQUEST,
  }
}

export const success = (
  payload: IWorkoutModelState,
): IAction<IWorkoutModelState> => {
  return {
    type: Types.SUCCESS,
    payload,
  }
}

// CUSTOM ACTIONS
export const removeModelFromState = (
  payload: IWorkoutDeleteModelPayload,
): IAction<IWorkoutDeleteModelPayload> => {
  return {
    type: Types.REMOVE_FROM_STATE,
    payload,
  }
}

export const removeModelTemplateFromState = (
  payload: IWorkoutDeleteModelPayload,
): IAction<IWorkoutDeleteModelPayload> => {
  return {
    type: Types.REMOVE_TEMPLATE_FROM_STATE,
    payload,
  }
}

export const triggerClearWorkoutModels = (): IAction<unknown> => {
  return {
    type: Types.CLEAR_MODELS,
  }
}

export const triggerListWorkoutsModel = (
  payload: IWorkoutListModelPayload,
): IAction<IWorkoutListModelPayload> => {
  return {
    type: Types.LIST_WORKOUT_MODEL,
    payload,
  }
}

export const triggerCreateWorkoutsModel = (
  payload: IWorkoutCreateModelPayload,
): IAction<IWorkoutCreateModelPayload> => {
  return {
    type: Types.CREATE_WORKOUT_MODEL,
    payload,
  }
}

export const triggerGetWorkoutsModel = (
  payload: IWorkoutGetModelPayload,
): IAction<IWorkoutGetModelPayload> => {
  return {
    type: Types.GET_WORKOUT_MODEL,
    payload,
  }
}

export const triggerCreateWorkoutModelTemplate = (
  payload: IWorkoutCreateModelTemplatePayload,
): IAction<IWorkoutCreateModelTemplatePayload> => {
  return {
    type: Types.CREATE_WORKOUT_MODEL_TEMPLATE,
    payload,
  }
}

export const triggerListWorkoutModelTemplate = (
  payload: IWorkoutListModelPayload,
): IAction<IWorkoutListModelPayload> => {
  return {
    type: Types.LIST_WORKOUT_MODEL_TEMPLATE,
    payload,
  }
}

export const triggerUpdateWorkoutsModel = (
  payload: IWorkoutUpdateModelPayload,
): IAction<IWorkoutUpdateModelPayload> => {
  return {
    type: Types.UPDATE_WORKOUT_MODEL,
    payload,
  }
}

export const triggerDeleteWorkoutsModel = (
  payload: IWorkoutDeleteModelPayload,
): IAction<IWorkoutDeleteModelPayload> => {
  return {
    type: Types.DELETE_WORKOUT_MODEL,
    payload,
  }
}
