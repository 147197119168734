// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import * as Styled from './styled'
import { Body } from 'heeds-ds'

// Assets
import appleLogo from 'assets/images/apple-logo.svg'
import desktopAccessDisabled from 'assets/images/desktop-access-disabled.svg'
import error404 from 'assets/images/404-error.svg'
import googlePlayLogo from 'assets/images/google-play-logo.svg'
import oopsFooter from 'assets/images/oops-footer.svg'
import oopsHeader from 'assets/images/oops-header.svg'

const Unauthorized: FC = () => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  return (
    <Styled.Container backgroundImage={error404}>
      <Styled.LogoHeader src={oopsHeader} />
      <Styled.Content>
        <Styled.AceessDisabled src={desktopAccessDisabled} />
        <Styled.Title>
          {isDesktop ? ' Não é por aqui...' : 'Oops!'}
        </Styled.Title>
        <Styled.Subtitle>
          {isDesktop
            ? 'Você não tem acesso ao sistema por esse link.'
            : 'Você não tem acesso ao sistema por aqui.'}
        </Styled.Subtitle>
        <Body
          align="center"
          color={theme.colors.text.subdued}
          margin="56px 0 0 0"
          type={isDesktop ? 'copy2' : 'copy3'}
        >
          Seu acesso é exclusivamente pelo aplicativo. Escolha a loja do seu
          dispositivo.
        </Body>
        <Styled.ButtonContainer>
          <Styled.Button href="https://play.google.com/store/apps/details?id=fit.cadu&pcampaignid=web_share">
            <Styled.LogoImage src={googlePlayLogo} />
            Google Play
          </Styled.Button>
          <Styled.Button href="https://apps.apple.com/us/app/cadu-fit/id6450973922">
            <Styled.LogoImage src={appleLogo} />
            App Store
          </Styled.Button>
        </Styled.ButtonContainer>
      </Styled.Content>
      <Styled.LogoFooter src={oopsFooter} />
    </Styled.Container>
  )
}

export default Unauthorized
