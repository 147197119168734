// Models
import { AthleteId } from 'models/api'
import { IAthleteSchedule } from 'services/athleteSchedule/@types'
import { IBaseState } from 'lib/redux/models'
import { TPlanType, TWeekdays } from 'models'

export enum EAthleteScheduleActionTypes {
  CLEANUP = 'ATHLETE_SCHEDULE/CLEANUP',
  FAILURE = 'ATHLETE_SCHEDULE/FAILURE',
  FULFILL = 'ATHLETE_SCHEDULE/FULFILL',
  REQUEST = 'ATHLETE_SCHEDULE/REQUEST',
  SUCCESS = 'ATHLETE_SCHEDULE/SUCCESS',
  GET_ATHLETE_SCHEDULES = 'ATHLETE_SCHEDULE/GET_ATHLETE_SCHEDULES',
  POST_ATHLETE_SCHEDULES = 'ATHLETE_SCHEDULE/POST_ATHLETE_SCHEDULES',
}

export interface IAthleteScheduleState extends IBaseState, IAthleteSchedules {}

export interface IAthleteSchedules {
  athlete?: AthleteId
  athleteName?: string
  athleteSubscriptionEndDate?: string
  athleteSubscriptionPlanType?: TPlanType
  athleteSubscriptionStartDate?: string
  location?: string
  personal?: number
  otherAthletesSchedules?: IAthleteSchedule[]
  schedules?: ISchedule[]
}

export interface ISchedule {
  day?: TWeekdays
  end: string
  id: number
  recurringSchedule?: TWeekdays[]
  start: string
}

/*
 * ACTIONS MODELS
 */
export interface IGetSchedulesPayload {
  athlete: AthleteId
  callback: (res: IAthleteSchedules) => void
}

export interface IPostSchedulesFormattedPayload {
  athlete?: AthleteId
  location?: string
  schedules?: Omit<ISchedule, 'id'>[]
}
