// React
import { FC } from 'react'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { theme } from 'lib/styled-components/theme'

// Custom
import * as Styled from './styled'
import { Body, Subtitle } from 'components/UI/Typography'
import { Avatar, IconButton, IconsType } from 'heeds-ds'

type Props = {
  name?: string
  photo?: string
  icon?: IconsType
  subTitle?: string
}

const Profile: FC<Props> = ({ name, photo, icon, subTitle }) => {
  return (
    <Styled.Container data-testid="profile">
      <Styled.ProfileBackground>
        <Avatar
          margin="0 23px 0 0"
          name={name ?? ''}
          scale="small"
          src={photo}
        />
        <Styled.TextWrapper>
          <Subtitle type="sh3" weight={500}>
            {name ?? 'Perfil do aluno'}
          </Subtitle>
          <Body type="copy1" weight={500}>
            {name ?? 'Perfil do aluno'}
          </Body>
          <Body type="copy4" weight={400} color={theme.colors.text.subdued}>
            {subTitle}
          </Body>
        </Styled.TextWrapper>

        {icon && (
          <IconButton
            iconName={icon}
            size="small"
            track={buttonClickTracking}
            trackName={`custom_profile_action_${icon}`}
            variation="main"
          />
        )}
      </Styled.ProfileBackground>
    </Styled.Container>
  )
}

export default Profile
