// React
import { FC, useContext } from 'react'

import { ThemeContext } from 'styled-components'

import { useMediaQuery } from 'heeds-ds/src/hooks'

// Components
import * as Styled from './styled'
import { Aligner, Skeleton } from 'heeds-ds'

const Loader: FC = () => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  return (
    <Styled.LoadContainer data-testid="row">
      {isDesktop ? (
        <>
          <Skeleton width="120px" height="16px" />
          <Skeleton width="66px" height="16px" />
          <Skeleton width="70px" height="16px" />
          <Skeleton width="70px" height="16px" />
          <Styled.LoadStatus />
        </>
      ) : (
        <>
          <Aligner flex="column" gap="8px">
            <Skeleton width="60px" height="16px" />
            <Skeleton width="130px" height="14px" />
          </Aligner>

          <Styled.LoadStatus />
        </>
      )}
    </Styled.LoadContainer>
  )
}

export default Loader
