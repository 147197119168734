// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useMediaQuery } from 'hooks'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { urls } from 'routes/paths'

// Components
import * as Styled from './styled'
import { LinkButton } from 'heeds-ds'
import { Subtitle, Title } from 'components/UI/Typography'

// Assets
import error404 from 'assets/images/404-error.svg'
import logoMinBeta from 'assets/images/logo-cadu-mini-beta.svg'
import warning from 'assets/images/warning.svg'

const NotFound: FC = () => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  return (
    <Styled.Container backgroundImage={error404}>
      <Styled.ContainerLogo>
        <Styled.Logo src={logoMinBeta} />
      </Styled.ContainerLogo>
      <Styled.Content>
        <Styled.Alert src={warning} />
        <Title
          align="center"
          type={isDesktop ? 'h1' : 'h3'}
          weight={theme.fontWeight.semiBold}
        >
          Tem certeza que é por aqui?
        </Title>
        <Subtitle align="center" color={theme.colors.text.subdued} type="sh3">
          Não encontramos o que você está procurando, talvez se você voltar para
          o início...
        </Subtitle>
        <LinkButton
          margin={`${isDesktop ? '5.6rem ' : ''}0 0`}
          size="small"
          to={urls.home}
          track={buttonClickTracking}
          trackName="navigate_to_home"
          variation="borderless"
        >
          Voltar para a página inicial
        </LinkButton>
      </Styled.Content>
    </Styled.Container>
  )
}

export default NotFound
