// Libraries
import * as Dropdown from '@radix-ui/react-dropdown-menu'
import { css } from 'styled-components'
import styled from 'styled-components'

// Components
import { Aligner, Body } from 'heeds-ds'

export const Container = styled(Aligner).attrs({
  justify: 'space-between',
  gap: '2.4rem',
})`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 1.6rem 4rem;
  }
`

export const ModelItem = styled(Aligner).attrs({
  align: 'center',
  justify: 'space-between',
  gap: '1.6rem',
})`
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  padding: 1.6rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 1.2rem 1.6rem;
  }
`

export const ModelContent = styled(Aligner).attrs({
  align: 'center',
})`
  gap: 1.6rem;
  max-width: 55rem;
`

export const ModelName = styled(Body).attrs({
  type: 'copy2',
  weight: 700,
})``

export const Content = styled(Dropdown.Content)`
  animation-duration: 500ms;
  animation-name: slideDownAndFade;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 12px;
  box-shadow: 0px 4px 80px 0px #0000001a;
  max-width: 55rem;
  width: calc(100vw - 5.4rem);
  padding: 0;
  position: absolute;
  right: -8.7rem;
  top: 2rem;
  will-change: transform, opacity;
  z-index: 10;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    max-width: 43.6rem;
  }

  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

export const Item = styled(Dropdown.Item)`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  min-height: 4rem;
  outline: none;
  padding: 2.4rem;
  transition: all 0.1s ease-in-out;
  user-select: none;
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.colors.text.default};
    font-family: ${theme.fontFamily.roboto};
    font-size: ${theme.fontSize.copy2};
    font-weight: ${theme.fontWeight.semiBold};

    :hover {
      background: ${theme.colors.badge.purpleBackground};
    }
  `};

  :first-child {
    border-radius: 12px 12px 0 0;
  }
  :last-child {
    border-radius: 0 0 12px 12px;
  }
`

export const Portal = styled.div``

export const AddButton = styled.p`
  align-items: center;
  gap: 1rem;
  display: flex;
  justify-content: center;
  line-height: 2.8rem;
  margin: 0 auto;
  white-space: nowrap;

  ${({ theme }) => css`
    color: ${theme.colors.interactive.default};
    font-family: ${theme.fontFamily.roboto};
    font-size: ${theme.fontSize.copy3};
    font-weight: ${theme.fontWeight.semiBold};

    :hover {
      color: ${theme.colors.interactive.hovered};
      svg path {
        fill: ${theme.colors.interactive.hovered};
      }
    }
  `}
`
