import { Filters } from 'hooks/useFilters'

import queryString, { StringifyOptions } from 'query-string'

export const parseQueryParams = (stringFilters: string) => {
  const filters = queryString.parse(stringFilters, {
    parseBooleans: false,
    parseNumbers: true,
  })

  for (const key in filters) {
    const filter = filters[key]
    filters[key] = Array.isArray(filter) ? [...filter] : [filter]
  }

  return filters as Filters
}

export const objectToQueryString = (
  object: object,
  arrayFormat: StringifyOptions['arrayFormat'] = 'comma',
) => {
  return queryString.stringify(object, {
    arrayFormat,
    skipNull: true,
  })
}
