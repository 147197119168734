// Libraries
import styled, { css } from 'styled-components'

// Components
import { Aligner } from 'components/UI/Containers'
import { Body } from 'components/UI/Typography'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    padding: 2.4rem;
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin: 0 auto;
      padding: 4rem;
    `}
  }
`

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column-reverse;
    gap: 1.6rem;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-direction: row;
      gap: 4.8rem;
    }
  `}
`

export const Block = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background: white;
    border-radius: 0.8rem;
    border: 1px solid ${theme.colors.border.input};
    box-shadow: 0px 2px 15px 0px rgba(43, 42, 99, 0.07);
    display: flex;
    flex-direction: column;
    height: fit-content;
    padding: 2.4rem;
    width: 100%;
    z-index: 1;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      max-width: 37.9rem;
    }
  `}
`

export const Header = styled(Aligner).attrs(() => ({
  align: 'center',
  justify: 'space-between',
}))`
  ${({ theme }) => css`
    background: white;
    border-radius: 1.5rem;
    border: 1px solid ${theme.colors.border.input};
    box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
    padding: 1.7rem 2.4rem;
  `}
`

export const Title = styled(Body).attrs(() => ({
  type: 'copy1',
  weight: 700,
}))`
  font-family: ${({ theme }) => theme.fontFamily.barlow};
  width: 100%;
`

export const Name = styled(Body).attrs((props) => ({
  margin: '0 0 0 0.8rem',
  type: 'copy1',
  weight: 500,
  whiteSpace: true,
  ...props,
}))``
