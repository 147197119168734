// Models
import {
  EAnamneseQuestionTypeToPortuguese,
  TAnamneseQuestionType,
} from 'models'

// React
import { FC, useContext, useMemo } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { useMediaQuery } from 'hooks'

// Components
import * as Styled from './styled'
import { Body, Button, Icon, Tooltip } from 'heeds-ds'

// Assets
import { ICON_TYPES } from 'components/form/BaseBlock'

interface Props {
  addQuestion: () => void
  addSection: () => void
  onFinish: () => void
  onSelect: (type: TAnamneseQuestionType) => void
}

const AnamneseBottomMenu: FC<Props> = ({
  addQuestion,
  addSection,
  onFinish,
  onSelect,
}) => {
  const { breakpoints, colors } = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)

  const renderOptions = useMemo(() => {
    return Object.entries(EAnamneseQuestionTypeToPortuguese).map(
      ([type, title]) => {
        const questionType = type as TAnamneseQuestionType

        return (
          <Styled.Item
            key={questionType}
            onClick={() => onSelect(questionType)}
          >
            <Icon iconName={ICON_TYPES[questionType]} />

            <Styled.Title>{title}</Styled.Title>
          </Styled.Item>
        )
      },
    )
  }, [onSelect])

  const sectionTooltipContent = (
    <Body type="copy3" color={colors.text.onPrimary}>
      Adicionar seção
    </Body>
  )

  return (
    <Styled.Container>
      <Styled.ButtonContainer>
        <Tooltip
          baseColor={colors.icon.default}
          borderRadius="0.4rem"
          content={sectionTooltipContent}
          delay={100}
          padding="1rem 2.4rem"
          shadow="0px 0px 0px 0px rgba(43, 42, 99, 0.1)"
        >
          <Styled.Button
            iconName="horizontalSplit"
            onClick={addSection}
            track={buttonClickTracking}
            trackName="add_new_section"
          />
        </Tooltip>

        {!isDesktop && <Styled.Label>Adicionar Seção</Styled.Label>}
      </Styled.ButtonContainer>

      {isDesktop ? (
        <Tooltip
          arrowSize={20}
          content={renderOptions}
          delay={100}
          side="top"
          borderRadius="0.4rem"
          padding="0"
          shadow="0px 4px 80px 0px rgba(43, 42, 99, 0.1)"
        >
          <Button
            margin="0"
            size="small"
            track={buttonClickTracking}
            trackName="add_new_question"
          >
            Adicionar pergunta
          </Button>
        </Tooltip>
      ) : (
        <>
          <Styled.ButtonContainer>
            <Styled.Button
              iconName="add"
              onClick={addQuestion}
              track={buttonClickTracking}
              trackName="add_new_question"
              variation="main"
            />

            <Styled.Label>Perguntas</Styled.Label>
          </Styled.ButtonContainer>

          <Styled.ButtonContainer>
            <Styled.Button
              iconName="check"
              onClick={onFinish}
              track={buttonClickTracking}
              trackName="create_new_anamnese"
            />

            <Styled.Label>Concluir</Styled.Label>
          </Styled.ButtonContainer>
        </>
      )}
    </Styled.Container>
  )
}

export default AnamneseBottomMenu
