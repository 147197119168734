// Models
import { IAthleteInfoState } from 'storage/athleteInfo/models'
import { IExerciseData } from 'storage/exercise/models'
import { IFormInputs, IModelInput, TWorkoutPDF } from 'models'
import { IWorkoutModelState } from 'storage/workoutModel/models'
import { IWorkoutRoutineState } from 'storage/workoutRoutine/models'
import IStore from 'lib/redux/models'

// React
import React, {
  FC,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { ThemeContext } from 'styled-components'
import {
  Navigate,
  generatePath,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { pdf } from '@react-pdf/renderer'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { cleanup, triggerLoadWorkoutRoutine } from 'storage/workoutRoutine/duck'
import { prepareModelsToDisplay } from 'filters/workouts'
import {
  success,
  triggerDeleteWorkoutsModel,
  triggerListWorkoutsModel,
} from 'storage/workoutModel/duck'
import { triggerLoadAthlete } from 'storage/athleteInfo/duck'
import { triggerLoadAthletePlans } from 'storage/athletePlans/duck'
import { uid } from 'utils/functions'
import { urls } from 'routes/paths'
import { useMediaQuery, useModal } from 'hooks'

// Components
import { Loading } from 'heeds-ds'
import { ModalDelete, ModalExerciseImage, WorkoutPDF } from 'components'
import Desktop from './desktop'
import Mobile from './mobile'

const WorkoutRoutine: FC = () => {
  const { loading: loadingAthleteInfo } = useSelector<
    IStore,
    IAthleteInfoState
  >((state) => state.athleteInfo)
  const { loading: loadingModels, models } = useSelector<
    IStore,
    IWorkoutModelState
  >((state) => state.workoutModel)
  const {
    error,
    loading: loadingRoutine,
    workoutRoutine,
  } = useSelector<IStore, IWorkoutRoutineState>((state) => state.workoutRoutine)
  const { id, routine_id } = useParams()
  const { openModal, closeModal, isVisible } = useModal()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const [selectedModel, setSelectedModel] = useState(0)
  const [stateModels, setStateModels] = useState<IModelInput[]>()
  const [selectedExercise, setSelectedExercise] = useState<IExerciseData>()

  const methods = useForm<IFormInputs>({
    defaultValues: { models: stateModels },
  })

  const goBack = () => {
    navigate(generatePath(urls.athleteRoutines, { id }), { replace: true })
  }

  const handleAddModel = () => {
    let models: IModelInput[] = [
      {
        id: 'NEW' + uid(),
        name: 'Treino A',
        workout_set: {},
        workout_routine: routine_id ? Number(routine_id) : undefined,
      },
    ]
    if (stateModels?.length) models = stateModels

    dispatch(success({ workoutModels: models }))
    navigate(generatePath(urls.workoutRoutineModels, { id, routine_id }))
  }

  const handleEditModel = (modelIndex: number) => {
    dispatch(success({ workoutModels: stateModels }))
    navigate({
      pathname: generatePath(urls.workoutRoutineModels, { id, routine_id }),
      search: `treino-selecionado=${modelIndex}`,
    })
  }

  const downloadPDFModel = async (modelId: string) => {
    if (workoutRoutine) {
      const model = models?.results.filter((model) => model.id === modelId)
      const pdfData: TWorkoutPDF = { routine: workoutRoutine, workouts: model }
      const blobPDF = await pdf(<WorkoutPDF data={pdfData} />).toBlob()

      const element = document.createElement('a')
      element.href = URL.createObjectURL(blobPDF)
      element.download = `${model?.[0].name || 'fileName'}.pdf`
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    }
  }

  const onRemoveModel = (model_pk: string) => {
    if (stateModels) {
      const modelIndex = stateModels.findIndex((model) => model.id === model_pk)
      setSelectedModel(modelIndex)
      openModal('delete-exercise-modal')
    }
  }

  const deleteModel = () => {
    const model = stateModels?.find((_, index) => index === selectedModel)
    model && dispatch(triggerDeleteWorkoutsModel({ model_pk: model.id }))
    closeModal()
    setSelectedModel(0)
  }

  const openExerciseGifModal = (exercise: IExerciseData) => {
    setSelectedExercise(exercise)
    openModal('mobile-exercise-gif-modal')
  }

  const workoutProps = {
    downloadPDFModel,
    handleAddModel,
    handleEditModel,
    onRemoveModel,
    openExerciseGifModal,
    stateModels,
    workoutModels: models?.results,
    workoutRoutine,
  }

  useEffect(() => {
    if (models?.results) setStateModels(prepareModelsToDisplay(models.results))
  }, [models?.results])

  useLayoutEffect(() => {
    if (id && routine_id) {
      const athlete_pk = parseInt(id)
      const routine_pk = parseInt(routine_id)

      dispatch(triggerLoadAthlete({ id: athlete_pk }))
      dispatch(triggerLoadAthletePlans({ id: athlete_pk }))
      dispatch(triggerLoadWorkoutRoutine({ routine_pk }))
      dispatch(triggerListWorkoutsModel({ routine_pk }))
    }
  }, [dispatch, id, routine_id])

  if (error) {
    navigate(-1)
    dispatch(cleanup())
  }

  if (isNaN(parseInt(id ?? '')) || isNaN(parseInt(routine_id ?? ''))) {
    return <Navigate to={urls.athletes} replace />
  }

  if (loadingAthleteInfo || loadingModels || loadingRoutine) {
    return <Loading active />
  }

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        {isDesktop ? (
          <Desktop
            {...workoutProps}
            goBack={goBack}
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
          />
        ) : (
          <Mobile {...workoutProps} />
        )}
      </FormProvider>

      {isVisible === 'mobile-exercise-gif-modal' && (
        <ModalExerciseImage
          exercise={selectedExercise}
          handleClose={closeModal}
        />
      )}

      {isVisible === 'delete-exercise-modal' && (
        <ModalDelete
          confirmTitle="Excluir"
          description="Tem certeza que deseja excluir este treino?"
          onConfirm={deleteModel}
          title="Excluir Treino"
        />
      )}
    </React.Fragment>
  )
}

export default WorkoutRoutine
