// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body } from 'heeds-ds'

type Props = {
  isVisible?: boolean
  left?: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ExerciseCount = styled(Body).attrs((props) => ({
  type: 'copy5',
  color: props.theme.colors.interactive.default,
}))`
  position: absolute;
  right: 2.4rem;
  top: 7rem;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

export const Form = styled.form`
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 1.5rem;
  flex: 1;
  height: calc(100% - 9rem);
  overflow-y: scroll;
  padding: 2.4rem 3.4rem 2.4rem 4rem;
`

export const ScrollButton = styled.button<Props>`
  align-items: center;
  background: linear-gradient(
    ${({ left }) => (left ? 'to left' : 'to right')},
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.2) 5%,
    rgba(255, 255, 255, 1) 50%
  );
  border: none;
  border-radius: ${({ left }) => (left ? '15px 0 0 15px' : '0 15px 15px 0')};
  cursor: pointer;
  display: flex;
  height: 6.6rem;
  justify-content: flex-end;
  padding: 0.8rem;
  position: absolute;
  top: 0;
  width: 5rem;
  ${({ left }) => (left ? 'left: 0' : 'right: 5.6rem')};
`

export const ScrollTabs = styled.div`
  max-width: calc(100% - 5.6rem);
  overflow-y: scroll;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
`

export const Tabs = styled(Aligner).attrs(() => ({
  gap: '1.6rem',
  padding: '1.6rem 2.4rem',
}))`
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 1.5rem;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  width: max-content;
`

export const TabsContainer = styled(Aligner).attrs(() => ({
  align: 'center',
  gap: '2.4rem',
  margin: '0 0 2.4rem',
}))`
  position: relative;
`

export const ShowList = styled.div<Props>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`
