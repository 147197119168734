// Libraries
import styled, { css } from 'styled-components'

// Components
import { Aligner, Body, IconButton } from 'heeds-ds'

interface Props {
  active?: boolean
  indicator?: number
  padding?: string
  positioning?: number
  show?: boolean
  white?: boolean
}

export const Button = styled(IconButton).attrs(({ variation }) => ({
  margin: '0',
  size: 'large',
  variation: variation || 'outlined',
}))``

export const ButtonContainer = styled(Aligner).attrs({
  align: 'center',
  flex: 'column',
  gap: '0.8rem',
})`
  width: min-content;
`

export const Container = styled(Aligner).attrs({ justify: 'space-between' })`
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 2rem 2rem 0 0;
  bottom: 0;
  box-shadow: 0px 0px 25px 0px rgba(43, 42, 99, 0.15);
  right: 0;
  padding: 1.6rem 5rem;
  position: fixed;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    backdrop-filter: blur(1.5px);
    background: none;
    border-radius: none;
    box-shadow: none;
    gap: 3.2rem;
    justify-content: center;
    padding: 1.2rem;
    right: 4rem;
    width: calc(100vw - 17.2rem);
  }
`

export const Label = styled(Body).attrs({
  align: 'center',
  type: 'copy5',
  weight: 600,
})``

export const Title = styled(Body).attrs({
  type: 'copy3',
  weight: 600,
})<Props>`
  :hover {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`

export const Item = styled(Aligner).attrs({
  align: 'center',
  gap: '1.6rem',
  padding: '1.6rem',
})`
  cursor: pointer;

  ${({ theme }) => css`
    background: ${theme.colors.surface.default};

    :hover {
      background: ${theme.colors.surface.hovered};

      path {
        fill: ${theme.colors.icon.hovered};
      }
    }
    border-radius: 0.4rem;

    :not(:first-child) {
      border-top: 1px solid ${theme.colors.border.input};
    }
  `}
`
