// Libraries
import styled from 'styled-components'

export const Container = styled.form`
  background-color: ${({ theme }) => theme.colors.surface.default};
  display: flex;
  flex: 1;
  padding: 4rem 3.7rem 9rem;
  height: calc(100vh - 21.3rem);

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    border-radius: 0 0 1.5rem 1.5rem;
    padding: 2.4rem 3.2rem 5rem 2.4rem;
  }
`

export const Grid = styled.div`
  display: grid;
  gap: 2.4rem;
  height: fit-content;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 3.2rem;
    grid-template-columns: repeat(auto-fill, 33.8rem);
  }
`
