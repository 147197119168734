// Models
import IStore from 'lib/redux/models'

// React
import {
  FC,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { preparePlansToDisplay, preparePlansToPayload } from 'filters/profile'
import { profilePlansSchema } from 'schemas'
import { useModal } from 'hooks'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import * as Blocks from 'blocks/dashboard/profile/ProfilePlans'
import * as Styled from './styled'
import { Body, Button, Icon } from 'heeds-ds'
import {
  FooterActionButton,
  ModalConfirmation,
  NewContentBox,
} from 'components'
import {
  triggerCreateProfilePlan,
  triggerGetProfilePlan,
} from 'storage/financial/duck'
import { IAuthState } from 'storage/auth/models'
import { IFinancialState } from 'storage/financial/models'
import { ICreateFinancialPlanPayload } from 'services/financial/@types'

type TValue = 'monthly' | 'quarterly' | 'semiannual' | 'yearly'

interface IOnlinePlanValues {
  id: number
  label: string
  value: TValue
  showFrequency: boolean
}

interface TValues {
  custom: string[]
  standard: string
}

interface IPlanValues {
  id?: number
  active?: boolean
  value?: TValues
  status: string
  has_subscriptions?: boolean
  has_active_subscriptions?: boolean
}

interface IPlanTypes {
  online?: IPlanValues
  in_person?: IPlanValues
}

export interface IPlansForm {
  monthly?: IPlanTypes
  quarterly?: IPlanTypes
  semiannual?: IPlanTypes
  yearly?: IPlanTypes
  global?: IPlanTypes
}

export type TModalProps = {
  title: string
  description: string
  confirmTitle: string
  onConfirm: () => void
  onCancel?: () => void
  longerDescription?: string
}

const ProfilePlans: FC = () => {
  const dispatch = useDispatch()
  const { planList } = useSelector<IStore, IFinancialState>(
    ({ financial }) => financial,
  )

  const { userData } = useSelector<IStore, IAuthState>(({ auth }) => auth)
  const { openModal, isVisible, closeModal } = useModal()

  const [selectedPlan, setSelectedPlan] = useState('')
  const [payload, setPayload] = useState<ICreateFinancialPlanPayload>()

  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const formMethods = useForm<IPlansForm>({
    resolver: yupResolver(profilePlansSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    delayError: 800,
  })
  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { dirtyFields },
  } = formMethods

  const plansValues: IOnlinePlanValues[] = useMemo(() => {
    return [
      {
        id: 0,
        label: 'Mensal',
        value: 'monthly',
        showFrequency: false,
      },
      {
        id: 1,
        label: 'Trimestral',
        value: 'quarterly',
        showFrequency: false,
      },
      {
        id: 2,
        label: 'Semestral',
        value: 'semiannual',
        showFrequency: false,
      },
      {
        id: 3,
        label: 'Anual',
        value: 'yearly',
        showFrequency: false,
      },
    ]
  }, [])

  const handleSubmitWithChangedValue = () => {
    closeModal()
    payload && dispatch(triggerCreateProfilePlan(payload))
  }

  const onSubmit = (data: IPlansForm) => {
    const plans = preparePlansToPayload(data)
    const priceHasChanged = plansValues
      .map((plan) => {
        return (
          watch(`${plan.value}.in_person.has_active_subscriptions`) === true &&
          dirtyFields[plan.value]?.in_person?.value?.custom?.includes(true)
        )
      })
      .includes(true)

    const payload = {
      profile_pk: userData?.profileId || 0,
      priceChanged: priceHasChanged,
      plans: plans[0],
      successCallback: () =>
        dispatch(
          triggerGetProfilePlan({ profile_pk: userData?.profileId || 0 }),
        ),
    }
    setPayload(payload)

    if (priceHasChanged) {
      return openModal('price-changed-confirmation-modal')
    }

    userData?.profileId && dispatch(triggerCreateProfilePlan(payload))
  }

  useEffect(() => {
    if (planList?.results) {
      const data = preparePlansToDisplay(planList.results)
      reset(data)
    }
  }, [planList, reset])

  useEffect(() => {
    if (userData?.profileId) {
      dispatch(triggerGetProfilePlan({ profile_pk: userData?.profileId || 0 }))
    }
  }, [dispatch, userData])

  useLayoutEffect(() => {
    plansValues.map((plan) => {
      Boolean(watch(`${plan.value}.in_person.active`)) === false &&
        setValue(`${plan.value}.in_person.value.custom`, Array(7).fill(''))
    })
  }, [plansValues, setValue, watch])

  return (
    <Styled.Container>
      <Styled.Content>
        <FormProvider {...formMethods}>
          <Styled.Form onSubmit={handleSubmit(onSubmit)} id="plans-form">
            <NewContentBox
              title="Tipos de plano"
              description={
                !isDesktop ? (
                  <Body
                    color={theme.colors.text.disabled}
                    margin="0.8rem 0 0"
                    type="copy2"
                    weight={400}
                  >
                    Selecione seu tipo de plano e adicione o valor para cada um.
                    Esse valor será apresentado no financeiro do seu aluno.
                  </Body>
                ) : (
                  'Selecione seu tipo de plano e adicione o valor para cada um. Esse valor será apresentado no financeiro do seu aluno.'
                )
              }
            >
              <Styled.InputsGrid>
                <Blocks.PlanTypes selectedPlan={(a) => setSelectedPlan(a)} />
                <Blocks.CustomPlans
                  setShowCustomPlanModal={() => openModal('custom-plan-modal')}
                  display={selectedPlan === 'PRESENCIAL'}
                  data={plansValues}
                />
                {selectedPlan === 'PRESENCIAL' ? (
                  <Button
                    onClick={() => openModal('custom-plan-modal')}
                    size="xsmall"
                    variation="outlined"
                    margin="0"
                  >
                    <Icon iconName="add" color={theme.colors.text.default} />
                    Adicionar plano personalizado
                  </Button>
                ) : null}
              </Styled.InputsGrid>
            </NewContentBox>
            {isVisible === 'custom-plan-modal' && (
              <Blocks.CustomPlanModal
                data={plansValues}
                submit={handleSubmit(onSubmit)}
              />
            )}
            {isVisible === 'price-changed-confirmation-modal' && (
              <ModalConfirmation
                description={
                  'Você modificou o valor de um plano existente, todos os seus alunos ativos que são assinantes serão notificados. '
                }
                onConfirm={handleSubmitWithChangedValue}
                title={'Alteração de valor no plano'}
                confirmTitle="Continuar"
              />
            )}
            <FooterActionButton primaryTitleButton="Salvar" />
          </Styled.Form>
        </FormProvider>
      </Styled.Content>
    </Styled.Container>
  )
}

export default ProfilePlans
