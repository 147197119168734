import styled from 'styled-components'

type ToggleProps = {
  disabled?: boolean
}

export const Switch = styled.div<ToggleProps>`
  background: ${({ theme, disabled }) =>
    disabled ? theme.colors.icon.disabled : theme.colors.border.shadowSubdued};
  border-radius: 32px;
  height: 24px;
  padding: 4px;
  position: relative;
  transition: 300ms all;
  width: 48px;
  ::before {
    content: '';
    background: white;
    border-radius: 14px;
    height: 14px;
    left: 5px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    transition: 300ms all;
    width: 14px;
  }
`
export const Input = styled.input<ToggleProps>`
  display: none;
  opacity: 0;
  position: absolute;
  :checked + ${Switch} {
    background: ${({ theme, disabled }) =>
      disabled ? theme.colors.icon.disabled : theme.colors.icon.default};
    :hover {
      background: ${({ theme, disabled }) =>
        disabled ? theme.colors.icon.disabled : theme.colors.icon.hovered};
    }
    ::before {
      transform: translate(25px, -50%);
    }
  }
`
export const Label = styled.label<ToggleProps>`
  align-items: center;
  display: flex;
  gap: 10px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`
