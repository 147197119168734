// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body, Icon } from 'heeds-ds'

export const AddIcon = styled(Icon).attrs((props) => ({
  color: props.theme.colors.text.onPrimary,
  iconName: 'add',
}))``

export const Container = styled(Aligner).attrs(() => ({
  align: 'center',
  justify: 'space-between',
}))`
  border-left: 4px solid ${({ theme }) => theme.colors.icon.default};
  border-radius: 2px;
  padding: 1.2rem 3.2rem 1.2rem 2.4rem;
`

export const Title = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.secondary,
  type: 'copy2',
  weight: 600,
}))``
