// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useController, useFormContext } from 'react-hook-form'

// Misc
import { useMediaQuery } from 'hooks'

// Components
import * as Styled from './styled'
import { Aligner } from 'heeds-ds'

interface Props {
  listNumber?: number
  name: string
  onRemove: () => void
  showDelete?: boolean
  type?: 'radio' | 'check'
}

const EditableOption: FC<Props> = ({
  listNumber,
  name,
  onRemove,
  showDelete = true,
  type = 'radio',
}) => {
  const { control } = useFormContext()
  const {
    field: { onChange, value },
  } = useController({ control, name, defaultValue: '' })
  const theme = useContext(ThemeContext)
  const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  return (
    <Aligner align="center" gap="0.8rem">
      {listNumber ? (
        <Styled.NumericIndicator>{listNumber}</Styled.NumericIndicator>
      ) : (
        <Styled.SelectionIndicator type={type} />
      )}

      <Styled.Input
        onChange={onChange}
        onFocus={(event) => event.target.select()}
        value={value}
      />

      {showDelete && (
        <Styled.ClickArea onClick={onRemove}>
          <Styled.DeleteIcon size={isTablet ? 16 : 20} />
        </Styled.ClickArea>
      )}
    </Aligner>
  )
}

export default EditableOption
