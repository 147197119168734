// Models
import { TAthleteCalendar } from 'models'

// React
import { FC, useState, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import {
  getMonthName,
  upperCaseFirstLetter,
  weekDays,
} from 'utils/calendarUtils'
import { useModal } from 'hooks'

// Components
import * as Styled from './styled'
import { Button, Body, IconButton, LoadingSpinner } from 'heeds-ds'
import AthleteCard from './AthleteCard'
import TimesCard from '../TimeCard'

type MonthType = {
  allAthletesList: TAthleteCalendar[]
  currentWeek: number
  day: number
  id: number
  isToday: boolean
  month: number
  sameTimeList: TAthleteCalendar[]
  weekDay: number
  year: number
}

type calendarType = {
  id: number
  isToday: boolean
  currentWeek: number
  day: number
  month: number
  weekDay: number
  year: number
  allAthletesList: TAthleteCalendar[]
  sameTimeList: TAthleteCalendar[]
}

type Props = {
  displayMode: (type: string) => void
  dateCallback: (initialDate: string) => void
  fullScreenMode: boolean
  savedAthletes: TAthleteCalendar[]
  startDate: string
  finishDate: string
  calendarList: calendarType[]
  loading?: boolean
}

const MonthView: FC<Props> = (props: Props) => {
  const {
    dateCallback,
    displayMode,
    startDate,
    fullScreenMode,
    calendarList,
    savedAthletes,
    loading,
  } = props
  const { closeModal } = useModal()
  const [startYear, startMonth, startDay] = startDate?.split('-') || ''
  const date = new Date()
  const theme = useContext(ThemeContext)
  const allAthletes = savedAthletes.sort(
    (a, b) =>
      parseInt(a.start.split('h').toString()) -
      parseInt(b.start.split('h').toString()),
  )

  const [entryDate, setEntryDate] = useState([
    parseInt(startDay) || date.getDate(),
    parseInt(startMonth) || date.getMonth() + 1,
    parseInt(startYear) || date.getFullYear(),
  ])
  const [entryDay, entryMonth, entryYear] = entryDate
  const [selectedDate, setSelectedDate] = useState(entryDate)
  const [selectedDay, selectedMonth, selectedYear] = selectedDate
  const [showCard, setShowCard] = useState(false)
  const [selectedDayOfTheWeek, setSelectedDayOfTheWeek] = useState(
    new Date(startDate).getDay() || date.getDay(),
  )

  const handlePreviousNext = (previous?: boolean) => {
    setShowCard(false)
    setSelectedDayOfTheWeek(7)
    setEntryDate([
      entryDay,
      previous ? entryMonth - 1 : entryMonth + 1,
      entryYear,
    ])
    if (entryMonth === 1 && previous) {
      setEntryDate([entryDay, 12, entryYear - 1])
      dateCallback && dateCallback(`${entryYear - 1}-12-1`)
    } else {
      previous &&
        dateCallback &&
        dateCallback(`${entryYear}-${entryMonth - 1}-1`)
    }
    if (entryMonth === 12 && !previous) {
      setEntryDate([entryDay, 1, entryYear + 1])
      dateCallback && dateCallback(`${entryYear + 1}-1-1`)
    } else {
      !previous &&
        dateCallback &&
        dateCallback(`${entryYear}-${entryMonth + 1}-1`)
    }
  }

  const handleDay = (
    day: number,
    month: number,
    year: number,
    weekDay: number,
    showCard: boolean,
  ) => {
    setSelectedDate([day, month, year])
    getMonthName(month)
    setSelectedDayOfTheWeek(weekDay)
    setShowCard(showCard)
    month === entryMonth &&
      dateCallback &&
      dateCallback(`${year}-${month}-${day}`)
  }

  const renderHeader = () => {
    return (
      <Styled.Header>
        <Styled.DateContainer>
          <IconButton
            iconName="chevronLeft"
            onClick={() => handlePreviousNext(true)}
            size="xsmall"
            track={buttonClickTracking}
            trackName="display_previous_month"
          />
          <Styled.Title>
            <Body type="copy2" weight={500} color={theme.colors.text.secondary}>
              {upperCaseFirstLetter(getMonthName(parseInt(startMonth) - 1)) +
                ' de ' +
                parseInt(startYear)}
            </Body>
          </Styled.Title>
          <IconButton
            iconName="chevronRight"
            onClick={() => handlePreviousNext()}
            size="xsmall"
            track={buttonClickTracking}
            trackName="display_next_month"
          />
        </Styled.DateContainer>
        <Styled.DisplayTypeContainer>
          <Button
            onClick={() => displayMode?.('month')}
            radius="15px"
            size="xsmall"
            track={buttonClickTracking}
            trackName="display_month_view_in_calendar"
          >
            <Body
              type="copy4"
              fontFamily="roboto"
              color={theme.colors.text.onPrimary}
            >
              Mensal
            </Body>
          </Button>
          <Button
            onClick={() => displayMode?.('week')}
            radius="15px"
            size="xsmall"
            track={buttonClickTracking}
            trackName="display_week_view_in_calendar"
            variation="outlined"
          >
            <Body
              type="copy4"
              fontFamily="roboto"
              color={theme.colors.text.default}
            >
              Semanal
            </Body>
          </Button>
          <IconButton
            iconName="close"
            margin="0"
            onClick={closeModal}
            size="small"
            track={buttonClickTracking}
            trackName="close_calendar_modal"
          />
        </Styled.DisplayTypeContainer>
      </Styled.Header>
    )
  }

  const renderMonthList = (month: MonthType[]) => {
    const longCalendar = month.length <= 36
    return (
      <Styled.CalendarBody>
        <Styled.TimeColumn fullScreen={fullScreenMode} />
        <Styled.CalendarBodyInner>
          <Styled.WeekDays>
            {weekDays('long').map((week, index) => {
              const weekSplited = week.split('-feira')
              return (
                <Body
                  type="small"
                  key={index}
                  weight={index === selectedDayOfTheWeek ? 700 : 400}
                  color={
                    index === selectedDayOfTheWeek
                      ? theme.colors.text.secondary
                      : theme.colors.text.subdued
                  }
                >
                  {weekSplited.toString().toLocaleUpperCase().split(',')}
                </Body>
              )
            })}
          </Styled.WeekDays>
          <Styled.Days>
            {month?.map((item, index) => {
              const isSelected =
                item.day === selectedDay &&
                item.month === selectedMonth &&
                item.year === selectedYear
              return (
                <Styled.Day
                  longerCalendar={longCalendar ? false : true}
                  key={index}
                  onClick={() =>
                    handleDay(
                      item.day,
                      item.month,
                      item.year,
                      item.weekDay,
                      item.allAthletesList.length > 0,
                    )
                  }
                >
                  <Styled.DayContainer isSelected={isSelected}>
                    <Styled.DayWrapper isSelected={isSelected}>
                      <Body
                        type="small"
                        weight={isSelected ? 700 : 400}
                        color={
                          isSelected
                            ? theme.colors.text.onPrimary
                            : theme.colors.text.subdued
                        }
                      >
                        {item.day}
                      </Body>
                    </Styled.DayWrapper>
                    <Body
                      type="small"
                      weight={isSelected ? 700 : 400}
                      color={
                        isSelected
                          ? theme.colors.text.secondary
                          : theme.colors.text.subdued
                      }
                    >
                      {item.day === 1
                        ? getMonthName(item.month - 1)[0].toUpperCase() +
                          getMonthName(item.month - 1).substring(1)[0] +
                          getMonthName(item.month - 1).substring(1)[1]
                        : null}
                    </Body>
                  </Styled.DayContainer>

                  {item.allAthletesList
                    .slice(0, longCalendar ? 2 : 1)
                    ?.map((itemSavedAthlete: TAthleteCalendar) => {
                      return (
                        <AthleteCard
                          key={itemSavedAthlete.id}
                          isNew={itemSavedAthlete.new}
                          athlete={itemSavedAthlete}
                        />
                      )
                    })}
                  <Styled.MoreContainer longerCalendar={!longCalendar}>
                    {item.allAthletesList?.length > (longCalendar ? 2 : 1) && (
                      <Body type="small" color={theme.colors.text.disabled}>
                        e mais{' '}
                        {item.allAthletesList?.length - (longCalendar ? 2 : 1)}
                      </Body>
                    )}
                  </Styled.MoreContainer>
                </Styled.Day>
              )
            })}
          </Styled.Days>
        </Styled.CalendarBodyInner>
      </Styled.CalendarBody>
    )
  }

  return (
    <Styled.CalendarContainer
      data-testid="monthView"
      fullScreen={fullScreenMode}
    >
      {renderHeader()}
      {loading ? (
        <Styled.loadingContainer>
          <LoadingSpinner size={50} color={theme.colors.icon.default} />
        </Styled.loadingContainer>
      ) : (
        renderMonthList(calendarList)
      )}
      <TimesCard
        week={selectedDayOfTheWeek}
        show={showCard}
        athleteList={allAthletes}
        selectedDate={selectedDate}
        onClick={() => setShowCard(false)}
      />
    </Styled.CalendarContainer>
  )
}

export default MonthView
