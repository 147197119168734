import { IFinancialData } from 'models'

export const FINANCIALS: IFinancialData[] = [
  {
    id: 1,
    plan_periodicity: 'monthly',
    plan_value: 220,
    payday: undefined,
    payment: undefined,
    due_date: '09/01/2023',
    payment_status: 'due_date',
  },
  {
    id: 2,
    plan_periodicity: 'monthly',
    plan_value: 220,
    payday: undefined,
    payment: undefined,
    due_date: '09/12/2022',
    payment_status: 'pendent',
  },
  {
    id: 3,
    plan_periodicity: 'monthly',
    plan_value: 220,
    payday: undefined,
    payment: undefined,
    due_date: '09/11/2022',
    payment_status: 'pendent',
  },
  {
    id: 4,
    plan_periodicity: 'monthly',
    plan_value: 220,
    payday: '08/10/2022',
    payment: 'cash',
    due_date: '09/10/2022',
    payment_status: 'paid',
  },
  {
    id: 5,
    plan_periodicity: 'monthly',
    plan_value: 220,
    payday: '08/09/2022',
    payment: 'billet',
    due_date: '09/09/2022',
    payment_status: 'paid',
  },
  {
    id: 6,
    plan_periodicity: 'monthly',
    plan_value: 220,
    payday: '08/08/2022',
    payment: 'billet',
    due_date: '09/08/2022',
    payment_status: 'paid',
  },
]
