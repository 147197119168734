// React
import { FC, useContext, useMemo, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import {
  createYearsRange,
  getShowingDays,
  MONTHS_COLLECTION,
} from '../../utils/helpers/date'

// Components
import * as Styled from './styled'
import CalendarDayPage from './CalendarDayPage'
import CalendarMonthPage from './CalendarMonthPage'
import CalendarYearPage from './CalendarYearPage'
import Icon from '../UI/Icons'

// Constants
const TODAY = new Date()
TODAY.setHours(0, 0, 0, 0)

export type TDisplayModes = 'day' | 'month' | 'year'

type Props = {
  inputDate?: Date | null
  inputEndDate?: Date | null
  range?: boolean
  setInputDate?: React.Dispatch<React.SetStateAction<Date | undefined>>
  setInputEndDate?: React.Dispatch<React.SetStateAction<Date | undefined>>
}

// TODO: fazer testes
const Calendar: FC<Props> = ({
  inputDate,
  inputEndDate,
  range,
  setInputDate,
  setInputEndDate,
}) => {
  const theme = useContext(ThemeContext)
  const initialDisplay = {
    month: inputDate ? inputDate.getMonth() : TODAY.getMonth(),
    year: inputDate ? inputDate.getFullYear() : TODAY.getFullYear(),
  }

  const [displayingMonth, setDisplayingMonth] = useState(initialDisplay.month)
  const [displayingYear, setDisplayingYear] = useState(initialDisplay.year)
  const [displayMode, setDisplayMode] = useState<TDisplayModes>('day')
  const [stateDate, setStateDate] = useState<Date>()
  const [stateEndDate, setStateEndDate] = useState<Date>()

  const currentDate = inputDate || stateDate
  const currentSetDate = setInputDate || setStateDate
  const currentEndDate = inputEndDate || stateEndDate
  const currentSetEndDate = setInputEndDate || setStateEndDate

  const daysDisplayed = useMemo(
    () => getShowingDays(displayingMonth, displayingYear),
    [displayingMonth, displayingYear],
  )

  const decreaseDisplayMode = () => {
    const modesArray: TDisplayModes[] = ['year', 'month', 'day']

    setDisplayMode((previousMode) => {
      const index = modesArray.indexOf(previousMode)

      return modesArray[index === 0 ? 0 : index - 1]
    })
  }

  const handleArrowsAction = (type: 'increase' | 'decrease') => {
    const modifier = type === 'increase' ? 1 : -1
    if (displayMode === 'day') {
      const auxDate = new Date(displayingYear, displayingMonth + 1 * modifier)
      setDisplayingMonth(auxDate.getMonth())
      setDisplayingYear(auxDate.getFullYear())
    }
    if (displayMode === 'month') {
      setDisplayingYear((prev) => prev + 1 * modifier)
    }
    if (displayMode === 'year') {
      setDisplayingYear((prev) => prev + 12 * modifier)
    }
  }

  const renderTitle = (displayMode: TDisplayModes) => {
    if (displayMode === 'day') {
      return `${MONTHS_COLLECTION[displayingMonth].full} ${displayingYear}`
    }
    if (displayMode === 'month') {
      return displayingYear
    }
    if (displayMode === 'year') {
      return `${createYearsRange(displayingYear)[0]} - ${
        createYearsRange(displayingYear)[11]
      }`
    }
  }

  return (
    <Styled.Container>
      <Styled.Header displayMode={displayMode}>
        <Styled.HeaderIcon onClick={() => handleArrowsAction('decrease')}>
          <Icon
            color={theme.colors.icon.subdued}
            iconName="arrowLeftLong"
            size={20}
          />
        </Styled.HeaderIcon>

        <Styled.Title onClick={decreaseDisplayMode}>
          {renderTitle(displayMode)}
        </Styled.Title>

        <Styled.HeaderIcon onClick={() => handleArrowsAction('increase')}>
          <Icon
            color={theme.colors.icon.subdued}
            iconName="arrowRightLong"
            size={20}
          />
        </Styled.HeaderIcon>
      </Styled.Header>

      <Styled.CalendarGrid displayMode={displayMode}>
        {displayMode === 'day' && (
          <CalendarDayPage
            days={daysDisplayed}
            displayingMonth={displayingMonth}
            displayingYear={displayingYear}
            endDate={currentEndDate}
            range={range}
            setDisplayingMonth={setDisplayingMonth}
            setDisplayingYear={setDisplayingYear}
            setEndDate={currentSetEndDate}
            setStartDate={currentSetDate}
            startDate={currentDate}
            today={TODAY}
          />
        )}

        {displayMode === 'month' && (
          <CalendarMonthPage
            displayingYear={displayingYear}
            setDisplayingMonth={setDisplayingMonth}
            setDisplayMode={setDisplayMode}
            startDate={currentDate}
            today={TODAY}
          />
        )}

        {displayMode === 'year' && (
          <CalendarYearPage
            displayMode={displayMode}
            displayingYear={displayingYear}
            setDisplayingYear={setDisplayingYear}
            setDisplayMode={setDisplayMode}
            startDate={currentDate}
            today={TODAY}
          />
        )}
      </Styled.CalendarGrid>
    </Styled.Container>
  )
}

export default Calendar
