// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body } from 'heeds-ds'

type Props = {
  height?: string
  width?: string
}

export const Container = styled(Aligner).attrs(() => ({
  flex: 'column',
  gap: '2.4rem',
  padding: '1.6rem 1.6rem 0 2.4rem',
}))<Props>`
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 15px;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  height: ${({ height }) => height || '100%'};
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    min-width: ${({ width }) => width || '100%'};
  }
`

export const ContainerTitle = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.secondary,
  type: 'copy3',
  weight: 600,
}))``

export const Date = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.subdued,
  type: 'copy5',
}))``

export const EmptyContainer = styled(Aligner).attrs(() => ({
  padding: '0 0 0.8rem',
  justify: 'center',
  align: 'center',
}))`
  height: calc(100% - 4.8rem);
`

export const EmptyText = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.disabled,
  type: 'copy3',
}))``

export const InfoContent = styled(Aligner).attrs(() => ({
  flex: 'column',
  gap: '0.6rem',
}))``

export const InfoItem = styled(Aligner).attrs(() => ({
  align: 'center',
  gap: '1.6rem',
  margin: '0 0 1.6rem',
}))``

export const Message = styled(Body).attrs(() => ({
  type: 'copy4',
  weight: 600,
}))``
