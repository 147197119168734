// Libraries
import styled from 'styled-components'

type Props = {
  active?: boolean
  last?: boolean
}

export const FormContainer = styled.form``

export const FormInnerContainerBottom = styled.div<Props>`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`

export const FormInnerDiv = styled.div<Props>`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: flex;
    justify-content: space-between;
    :last-child {
      ::after {
        content: '';
        width: ${({ last }) => (last ? '100' : '0')}%;
      }
    }
  }
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 30px 39px 80px;
  background-color: ${({ theme }) => theme.colors.surface.default};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    background-color: transparent;
    padding: 0;
    button {
      margin: 0;
    }
  }
`

export const HalfContainer = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: space-between;
  :nth-child(1) {
    margin-right: 24px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 50%;
  }
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-direction: row;
  }
`

export const NextPreviousContainer = styled.div`
  display: flex;
  grid-column-gap: 32px;
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.border.input};
`

export const ValueButton = styled.div`
  button {
    padding: 0;
  }
`
