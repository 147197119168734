// Misc
import { dateRegex } from 'utils/regex'

// Constants
const _MS_PER_DAY = 1000 * 60 * 60 * 24

// TODO: criar testes
interface INomenclatureCollection {
  [key: number]: { full: string; short?: string; symbol?: string }
}

export const MONTHS_COLLECTION: INomenclatureCollection = {
  0: { full: 'Janeiro', short: 'Jan' },
  1: { full: 'Feveiro', short: 'Fev' },
  2: { full: 'Março', short: 'Mar' },
  3: { full: 'Abril', short: 'Abr' },
  4: { full: 'Maio', short: 'Mai' },
  5: { full: 'Junho', short: 'Jun' },
  6: { full: 'Julho', short: 'Jul' },
  7: { full: 'Agosto', short: 'Ago' },
  8: { full: 'Setembro', short: 'Set' },
  9: { full: 'Outubro', short: 'Out' },
  10: { full: 'Novembro', short: 'Nov' },
  11: { full: 'Dezembro', short: 'Dez' },
}

export const WEEKDAYS_COLLECTION: INomenclatureCollection = {
  0: { full: 'Domingo', short: 'Dom', symbol: 'D' },
  1: { full: 'Segunda', short: 'Seg', symbol: 'S' },
  2: { full: 'Terça', short: 'Ter', symbol: 'T' },
  3: { full: 'Quarta', short: 'Qua', symbol: 'Q' },
  4: { full: 'Quinta', short: 'Qui', symbol: 'Q' },
  5: { full: 'Sexta', short: 'Sex', symbol: 'S' },
  6: { full: 'Sábado', short: 'Sab', symbol: 'S' },
}

export const createYearsRange = (year: number) => {
  return Array.from({ length: 12 }, (_, i) => year + i - 6)
}

export const createDatesInteval = (start: Date, end: Date) => {
  const distance = getDatesDistance(start, end)

  if (distance <= 1) return []

  return Array.from({ length: distance - 1 }, (_, i) =>
    new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate() + i + 1,
    ).toDateString(),
  )
}

export const getAllDatesInMonth = (month: number, year: number) => {
  return Array.from(
    { length: new Date(year, month + 1, 0).getDate() },
    (_, i) => new Date(year, month, i + 1),
  )
}

export const getNextMonthDays = (lenght: number, month: number, year: number) =>
  Array.from(
    { length: Math.abs(lenght) },
    (_, i) => new Date(year, month + 1, i + 1),
  )

export const getPreviousMonthDays = (
  lenght: number,
  month: number,
  year: number,
) =>
  Array.from(
    { length: Math.abs(lenght) },
    (_, i) => new Date(year, month, i + 1 - lenght),
  )

export const getShowingDays = (month: number, year: number) => {
  const monthDays = getAllDatesInMonth(month, year)
  const previousMonthShowingDays = getPreviousMonthDays(
    monthDays[0].getDay(),
    month,
    year,
  )
  const nextMonthShowingDays = getNextMonthDays(
    6 - monthDays[monthDays.length - 1].getDay(),
    month,
    year,
  )

  return [...previousMonthShowingDays, ...monthDays, ...nextMonthShowingDays]
}

export const getDatesDistance = (startDay: Date, endDay: Date) => {
  const differenceInTime = endDay.getTime() - startDay.getTime()
  const differenceInDays = differenceInTime / _MS_PER_DAY

  return Math.ceil(differenceInDays)
}

export const formatToLocale = (date: Date) => date.toLocaleDateString('pt-br')

export const formatToDateObject = (date: string) => {
  const isValid = new RegExp(dateRegex).test(date)
  if (!isValid) return null

  const dateNumbers = date.split('/')
  return new Date(+dateNumbers[2], +dateNumbers[1] - 1, +dateNumbers[0])
}
