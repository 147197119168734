// Models
import { TMenuDropdownOption } from 'heeds-ds/src/models'
import { IWorkoutModelData } from 'storage/workoutModel/models'

// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'

// Components
import * as Styled from './styled'
import { DropdownMenu, IconButton } from 'heeds-ds'

export type Props = {
  modelTemplate: IWorkoutModelData
  onClick?: (anamneseId: string) => void
  onEdit?: (modelID: string) => void
  onRemove?: () => void
}

const Mobile: FC<Props> = ({ modelTemplate, onClick, onEdit, onRemove }) => {
  const { colors } = useContext(ThemeContext)

  const { name, personal_profile, id, date } = modelTemplate

  const options: TMenuDropdownOption[] = [
    {
      icon: 'edit',
      label: 'Editar',
      onClick: () => onEdit?.(id),
    },
    {
      color: colors.text.critical,
      icon: 'delete',
      label: 'Excluir',
      onClick: () => onRemove?.(),
      disabled: name === 'Model modelo',
    },
  ]

  return (
    <Styled.Container data-testid="routine-item" onClick={() => onClick?.(id)}>
      <Styled.TitlesContainer>
        <Styled.Title>{name}</Styled.Title>
        <Styled.Creator>
          {personal_profile !== 0 ? 'Você' : 'CADU (sistema)'}
        </Styled.Creator>
        {date && <Styled.Creator>{date}</Styled.Creator>}
      </Styled.TitlesContainer>
      <Styled.OptionsContainer onClick={(e) => e.stopPropagation()}>
        <DropdownMenu items={options}>
          <IconButton
            size="small"
            iconName="moreVertical"
            margin="0"
            track={buttonClickTracking}
            trackName="open_model_model_options"
          />
        </DropdownMenu>
      </Styled.OptionsContainer>
    </Styled.Container>
  )
}

export default Mobile
