// Models
import { EAthleteStatusToPortuguese, TAthleteStatus } from 'models'
import { theme } from 'lib/styled-components/theme'

// React
import { FC, useContext } from 'react'

// Lib
import { ThemeContext } from 'styled-components'

// Components
import * as Styled from './styled'
import { Body } from 'heeds-ds'

// Constants
const statusColors = {
  active: 'green',
  inactive: 'grey',
  invited: 'yellow',
}

type BadgeColors = keyof typeof theme.colors.badge

type Props = {
  status?: TAthleteStatus
  margin?: string
}

const StatusTag: FC<Props> = ({ status = 'inactive', margin }) => {
  const theme = useContext(ThemeContext)

  const backgroundColor = `${statusColors[status]}Background` as BadgeColors
  const textColor = `${statusColors[status]}Text` as BadgeColors

  return (
    <Styled.Container
      background={backgroundColor}
      margin={margin}
      data-testid="status-tag"
    >
      <Body color={theme.colors.badge[textColor]} type="copy5" weight={600}>
        {EAthleteStatusToPortuguese[status]}
      </Body>
    </Styled.Container>
  )
}

export default StatusTag
