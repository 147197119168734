import styled, { css } from 'styled-components'

// Components
import BaseButton from '../BaseButton'

enum ButtonPaddingsOnlyIcon {
  xsmall = '7px',
  small = '8px',
  medium = '9px',
  large = '10px',
}

export const Button = styled(BaseButton).attrs(({ size }) => ({
  buttonStyles: css`
    border-radius: 10px;
    padding: ${ButtonPaddingsOnlyIcon[size ?? 'medium']};
  `,
}))`
  ${({ theme, variation, cancel }) => {
    if (variation === 'main') {
      return css`
        :hover {
          background-color: ${({ theme }) =>
            cancel
              ? theme.colors.interactive.criticalHovered
              : theme.colors.icon.hovered};
        }
        :active {
          background-color: ${({ theme }) =>
            cancel
              ? theme.colors.interactive.criticalDepressed
              : theme.colors.icon.pressed};
        }
        :focus {
          background-color: ${({ theme }) =>
            cancel ? theme.colors.icon.critical : ''};
          box-shadow: 0 0 4px rgba(43, 42, 99, 1);
        }
        :disabled {
          background-color: ${({ theme }) =>
            cancel
              ? theme.colors.interactive.criticalDisabled
              : theme.colors.icon.disabled};
        }
      `
    } else if (variation === 'outlined') {
      return css`
        border: solid 1px ${({ theme }) => theme.colors.border.input};
        background-color: ${theme.colors.surface.default};
        :hover {
          border: solid 1px ${({ theme }) => theme.colors.border.input};
          background-color: ${({ theme }) => theme.colors.surface.hovered};
        }
        :active {
          background-color: ${({ theme }) =>
            theme.colors.surface.selectedPressed};
        }
        :focus {
          box-shadow: 0 0 4px rgba(43, 42, 99, 1);
        }
        :disabled {
          background-color: ${({ theme }) =>
            theme.colors.surface.neutralDisabled};
        }
      `
    }
  }}
  ${({ buttonStyles, variation }) => (variation === 'main' ? buttonStyles : '')}
`
