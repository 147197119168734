export const capitalizeFirstLetter = (text?: string) => {
  if (!text) return ''
  return text[0].toUpperCase() + text.slice(1)
}

export const toPascalCase = (text: string) => {
  return text
    .replace(/[-_]+/g, ' ')
    .split(' ')
    .map((word) => {
      return word[0].toUpperCase() + word.slice(1).toLowerCase()
    })
    .join(' ')
}
