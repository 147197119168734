// Libraries
import styled from 'styled-components'

type ActionProps = {
  actionColor?: string
  active?: boolean
  initial?: boolean
  margin?: string
  maxHeight?: string
  paddingSize?: string
  shadow?: boolean
}

export const Container = styled.div<ActionProps>`
  outline: none;
  display: flex;
  gap: 8px;
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ paddingSize }) => paddingSize || 0};
  scroll-snap-type: x mandatory;
  width: 100%;
  ::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  }
  ${({ active }) => active && 'overflow-x: scroll'};
  ${({ shadow }) =>
    shadow && 'box-shadow: 0px 4px 20px rgba(99, 90, 108, 0.1)'};
`

export const ChildrenContainer = styled.div<ActionProps>`
  min-width: 100%;
  scroll-snap-align: start;
`

export const ActionsContainer = styled.div<ActionProps>`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  scroll-snap-align: start;
  max-height: ${({ maxHeight }) => maxHeight || 'auto'};
`

export const Action = styled.div<ActionProps>`
  align-items: center;
  background: ${({ actionColor, theme }) =>
    actionColor || theme.colors.interactive.default};
  display: flex;
  height: 100%;
  justify-content: center;
  width: 72px;
  :first-child {
    border-radius: 8px 0 0 8px;
  }
`
