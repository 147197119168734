import * as SelectPrimitive from '@radix-ui/react-select'
import styled from 'styled-components'

type Props = {
  borderColor: string
  scale: 'small' | 'medium' | 'large'
  width?: number
}

enum EPaddings {
  small = '1rem 1.2rem',
  medium = '1.2rem 1.6rem',
  large = '1.8rem 1.6rem',
}

export const Content = styled(SelectPrimitive.Content).attrs({
  position: 'popper',
  sideOffset: 2,
})`
  z-index: 50;
  min-width: 8rem;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transform-origin: var(--radix-select-content-transform-origin);
  animation: slideDownAndFade 0.2s ease-out;

  * {
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.icon.hovered};
      border-radius: 6px;
    }
  }

  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: scaleY(10%);
    }
    to {
      opacity: 1;
      transform: scaleY(100%);
    }
  }
`

export const Icon = styled(SelectPrimitive.Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.icon.default};

  transform: rotatez(0);
  transition: all 0.1s ease-in-out;
`

export const List = styled(SelectPrimitive.Viewport)`
  padding: 0.2rem;
  height: var(--radix-select-trigger-height);
  max-height: 40vh;
  width: 100%;
  min-width: var(--radix-select-trigger-width);
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
`

export const Option = styled(SelectPrimitive.Item)`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.4rem;
  padding: 1.2rem 1.6rem;
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.text.default};

  :hover {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    background-color: ${({ theme }) =>
      theme.colors.surface.highlightSubduedHovered};
  }
  &[data-highlighted] {
    background-color: ${({ theme }) =>
      theme.colors.surface.highlightSubduedHovered};
    outline: none;
  }
  &[aria-state='checked'] {
    background-color: ${({ theme }) =>
      theme.colors.surface.highlightSubduedHovered};
    font-weight: 700;
  }
`

export const Trigger = styled(SelectPrimitive.Trigger)<Props>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.default};
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  background-color: ${({ theme }) => theme.colors.surface.default};
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 3px;
  outline: none;
  padding: ${({ scale }) => EPaddings[scale]};
  transition: all 0.3s ease;
  user-select: none;

  :focus {
    box-shadow: 0px 0px 5px
      ${({ theme }) => theme.colors.action.secondaryDefault};
  }
  &[data-state='open'] {
    border: 1px solid ${({ theme }) => theme.colors.icon.default};
    ${Icon} {
      transform: rotateZ(180deg);
    }
  }
  &[data-disabled] {
    cursor: not-allowed;
    background: ${({ theme }) => theme.colors.surface.disabled};
    color: ${({ theme }) => theme.colors.text.subdued};
  }

  &[data-placeholder] {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.text.disabled : theme.colors.text.subdued};
  }
`

export const OptionText = SelectPrimitive.ItemText

export const Value = SelectPrimitive.Value

export const Wrapper = styled(SelectPrimitive.Root)`
  width: 100%;
`
