// React
import { FC, useContext, useEffect, useState } from 'react'

// Libraries
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import jwtDecode from 'jwt-decode'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { resendConfirmationEmail } from 'storage/auth/duck'
import { urls } from 'routes/paths'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import { Aligner, Button, Toast } from 'heeds-ds'
import * as Styled from '../styled'

// Assets
import emailFail from 'assets/images/email-fail.png'

type EmailToken = {
  email: string
}

const ExpiredConfirmation: FC = () => {
  const theme = useContext(ThemeContext)
  const { token } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)
  const { email }: EmailToken = jwtDecode(token ? token : '')

  const [showToast, setShowToast] = useState(true)

  const handleResendEmail = () => {
    dispatch(resendConfirmationEmail(email))
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowToast(false)
    }, 3000)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <Aligner flex="column">
      <Styled.Image src={emailFail} />
      <Aligner flex="column" align="center">
        <Styled.TitleMain>E-mail expirado</Styled.TitleMain>
        <Styled.SubTitleMain margin="4rem 0 2.4rem 0">
          O link de confirmação de cadastro que foi enviado para o seu e-mail
          expirou.
        </Styled.SubTitleMain>
        <Styled.BodyMain>
          Clique em reenviar e-mail aqui embaixo para receber um novo.
        </Styled.BodyMain>
      </Aligner>
      <Aligner flex="column" align="center" margin="5.6rem 0 0 0">
        <Button
          size="medium"
          track={buttonClickTracking}
          trackName="resend_email"
          onClick={handleResendEmail}
          width={isDesktop ? 'auto' : '100%'}
        >
          Reenviar e-mail
        </Button>
        <Button
          variation="borderless"
          size="small"
          margin={isDesktop ? '3.2rem 0 0 0' : '4rem 0 0 0'}
          track={buttonClickTracking}
          trackName="access_my_account"
          onClick={() => navigate(urls.login)}
        >
          Entrar na minha conta
        </Button>
      </Aligner>
      <Toast
        show={showToast}
        variation="warning"
        customTitle="Aguarde 5 minutos para reenviar uma solicitação."
      />
    </Aligner>
  )
}

export default ExpiredConfirmation
