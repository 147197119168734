// Models
import { TPaymentStatus } from 'models'

// Libraries
import styled from 'styled-components'

// Components
import { Body } from 'heeds-ds'

type Props = {
  disabled?: boolean
  status?: TPaymentStatus
}

enum EBackgrondColor {
  due_date = 'warningSubduedHovered',
  paid = 'successSubduedHovered',
  pendent = 'criticalDefault',
}

export const Cell = styled.td`
  padding: 2rem 1px;
`

export const Row = styled.tr`
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  :hover {
    box-shadow: -5px 0px 8px rgb(0 0 0 / 1.5%);
  }
`

export const TextBackground = styled.div<Props>`
  background: ${({ status, theme }) =>
    theme.colors.surface[EBackgrondColor[status || 'paid']]};
  border-radius: 16px;
  padding: 8px 16px;
  width: fit-content;
`

export const TextMedium = styled(Body).attrs(() => ({
  type: 'copy5',
  weight: 600,
}))``

export const TextSoft = styled(Body).attrs((props) => ({
  type: 'copy5',
  weight: 400,
  color: props.theme.colors.text.subdued,
}))``
