// React
import { FC, HTMLAttributes, ReactNode, useContext, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Components
import { Aligner, SimpleContainer } from '../UI/Containers'
import { CollapsableContainer, Container } from './styled'
import Icon from '../UI/Icons'

type Props = HTMLAttributes<HTMLDivElement> & {
  active?: boolean
  children?: ReactNode | ReactNode[]
  disabled?: boolean
  expandable?: boolean
  internalContent?: ReactNode | ReactNode[]
  margin?: string
  padding?: string
  borderPosition?: 'top' | 'right' | 'bottom' | 'left'
}

const Tag: FC<Props> = (props: Props) => {
  const {
    active,
    children,
    disabled,
    expandable,
    internalContent,
    margin,
    padding,
    borderPosition = 'left',
    ...divProps
  } = props
  const [open, setOpen] = useState(false)
  const theme = useContext(ThemeContext)

  const toggleExpand = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    setOpen((prev) => !prev)
  }

  return (
    <Container
      data-testid="container"
      active={active}
      disabled={disabled}
      margin={margin}
      padding={padding}
      borderPosition={borderPosition}
      {...divProps}
    >
      <Aligner justify="space-between">
        {children}
        {expandable && (
          <SimpleContainer
            style={{ cursor: 'pointer' }}
            onClick={toggleExpand}
            data-testid="expand-icon"
          >
            <Icon
              color={theme.colors.icon.default}
              iconName={open ? 'expandLess' : 'expandMore'}
              size={20}
            />
          </SimpleContainer>
        )}
      </Aligner>
      {expandable && (
        <CollapsableContainer
          data-testid="collapsable-container"
          open={open}
          onClick={toggleExpand}
        >
          {internalContent}
        </CollapsableContainer>
      )}
    </Container>
  )
}

export default Tag
