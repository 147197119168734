// Models
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'

// React
import { FC } from 'react'

// Libs
import { generatePath } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { cleanup } from 'storage/workoutRoutine/duck'
import { urls } from 'routes/paths'

// Components
import * as Styled from './styled'
import AthleteRoutineCard from 'components/AthleteRoutineCard'

type Props = {
  athleteId: number
  lastRoutine?: IWorkoutRoutine
  comments?: string
}

const ProfileTabs: FC<Props> = ({ athleteId, comments, lastRoutine }) => {
  const dispatch = useDispatch()
  const hasRoutine = lastRoutine && Object.keys(lastRoutine).length
  const routinePath = hasRoutine
    ? generatePath(urls.athleteRoutines, { id: athleteId })
    : generatePath(urls.workoutRoutineCreate, { id: athleteId })

  return (
    <Styled.Wrapper defaultValue="routines">
      <Styled.TabsList data-testid="tabslist">
        <Styled.Tab value="routines">Rotinas</Styled.Tab>
        <Styled.Tab value="comments">Comentários</Styled.Tab>
      </Styled.TabsList>

      <Styled.TabContent value="routines">
        {!!hasRoutine && (
          <AthleteRoutineCard routine={lastRoutine} id={athleteId} />
        )}
        {!hasRoutine && (
          <Styled.EmptyText>
            Ainda não existe rotina adicionada.
          </Styled.EmptyText>
        )}
        <Styled.ActionButton
          to={routinePath}
          onClick={() => !hasRoutine && dispatch(cleanup())}
          size="small"
          variation="borderless"
          track={buttonClickTracking}
          trackName={`athlete_profile_${
            hasRoutine ? 'view_routines' : 'create_new_routine'
          }`}
        >
          {hasRoutine ? 'Visualizar todas as rotinas' : 'Cadastrar nova rotina'}
        </Styled.ActionButton>
      </Styled.TabContent>

      <Styled.TabContent value="comments">
        {!!comments && <Styled.Comments>{comments}</Styled.Comments>}
        {!comments && (
          <Styled.EmptyText>Ainda não existe comentário.</Styled.EmptyText>
        )}
      </Styled.TabContent>
    </Styled.Wrapper>
  )
}

export default ProfileTabs
