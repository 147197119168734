// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { useMediaQuery } from 'hooks'

// Components
import * as Styled from './styled'
import { Button, IconButton } from 'heeds-ds'

type Props = {
  onClick: () => void
}

const ProfileNewExtraWorkoutTag: FC<Props> = ({ onClick }) => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  return (
    <Styled.Container>
      <Styled.Title>Crie aqui o seu treino</Styled.Title>

      {isDesktop ? (
        <Button
          onClick={onClick}
          size="small"
          track={buttonClickTracking}
          trackName="open_extra_plan_form"
        >
          <Styled.AddIcon />
          {'Adicionar treino'}
        </Button>
      ) : (
        <IconButton
          iconName="add"
          onClick={onClick}
          track={buttonClickTracking}
          trackName="open_extra_plan_form"
          variation="main"
        />
      )}
    </Styled.Container>
  )
}

export default ProfileNewExtraWorkoutTag
