// Libraries
import styled from 'styled-components'

type DayProps = {
  active?: boolean
  conflict?: boolean
}

export const Day = styled.button<DayProps>`
  user-select: none;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.surface.default : 'transparent'};
  border: 2px solid
    ${({ active, theme, conflict }) =>
      conflict
        ? theme.colors.icon.critical
        : active
        ? theme.colors.icon.default
        : 'transparent'};
  font-size: ${({ theme }) => theme.fontSize.copy5};
  padding: 12px 16px;
  border-radius: 7px;
  cursor: pointer;
  :focus {
    outline-color: ${({ theme }) => theme.colors.focusedDefault};
  }
`

export const DaysContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  gap: 8px;
  display: flex;
  padding-bottom: 0.8rem;
  margin-top: 0.4rem;
`
