// React
import { FC } from 'react'

// Components
import { IconsPath, IconsType } from './values'

type Props = {
  iconName: IconsType
  size?: number
  viewBox?: number
}

const StylizedIcons: FC<Props> = ({
  iconName,
  size = 42,
  viewBox = 42,
}: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${viewBox} ${viewBox}`}
      fill="none"
    >
      <g>
        {IconsPath[iconName].iconPaths.map((item, index) => {
          return (
            <path
              key={index}
              d={item}
              fill={IconsPath[iconName].colors[index]}
            />
          )
        })}
      </g>
    </svg>
  )
}

export default StylizedIcons
