// Models

// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { useMediaQuery } from 'hooks'

// Components
import * as Styled from '../styled'
import { LinkButton } from 'heeds-ds'

// Assets
import appStoreImage from 'assets/images/apple-logo.svg'
import googlePlayImage from 'assets/images/google-play-logo.svg'
import successImage from 'assets/images/done-all.png'

// Constants
const APP_STORE = 'https://apps.apple.com/br/app/cadu-fit/id6450973922'
const GOOGLE_PLAY =
  'https://play.google.com/store/apps/details?id=fit.cadu&pcampaignid=web_share'
const INFO = {
  true: {
    title:
      'Seu acesso ao sistema é exclusivamente através do nosso aplicativo.',
    subtitle: 'Escolha a loja do seu dispositivo.',
  },
  false: {
    title: 'Agora você faz parte do nosso time.',
    subtitle:
      'Acesse o nosso sistema pelo aplicativo. Escolha a loja do seu dispositivo.',
  },
}

const RegistrationCompleted: FC = () => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(
    `(min-width: ${theme.breakpoints.desktopSmall}px)`,
  )

  return (
    <Styled.Container>
      <Styled.Image src={successImage} />

      <Styled.TitleS>Cadastro realizado!</Styled.TitleS>

      <Styled.SubtitleS>{INFO[`${isDesktop}`].title}</Styled.SubtitleS>

      <Styled.SmallText>{INFO[`${isDesktop}`].subtitle}</Styled.SmallText>

      <Styled.AlignButtons>
        <LinkButton
          size="xsmall"
          to={GOOGLE_PLAY}
          track={buttonClickTracking}
          trackName="open_external_link_google_play"
          variation="outlined"
        >
          <Styled.ImageStores src={googlePlayImage} />
          Google Play
        </LinkButton>

        <LinkButton
          size="xsmall"
          to={APP_STORE}
          track={buttonClickTracking}
          trackName="open_external_link_app_store"
          variation="outlined"
        >
          <Styled.ImageStores src={appStoreImage} />
          App Store
        </LinkButton>
      </Styled.AlignButtons>
    </Styled.Container>
  )
}

export default RegistrationCompleted
