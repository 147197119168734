// Models
import { EPlanModelToPortuguese } from 'models'
import { IAthleteItem } from 'services/athletes/@types'
import { TMenuDropdownOption } from 'heeds-ds/src/models'

// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Custom
import * as Styled from './styled'
import { Aligner, Avatar, Body, Swipe } from 'heeds-ds'

export type Props = {
  item: IAthleteItem
  onClick: () => void
  onSwipeClick: () => void
  handleClose: () => void
  mobileOptions?: TMenuDropdownOption[]
  showMoreModal: boolean
}

const Mobile: FC<Props> = ({
  item: { name, plan_model, status },
  onClick,
  onSwipeClick,
}) => {
  const theme = useContext(ThemeContext)

  return (
    <Swipe
      active
      actions={[
        {
          icon: 'moreVertical',
          color: theme.colors.interactive.default,
          onClick: onSwipeClick,
        },
      ]}
    >
      <Styled.InnerCard onClick={onClick} data-testid="card">
        <Avatar disabled={!status} name={name ?? ''} scale="xSmall" />
        <Aligner flex="column">
          <Body
            type="copy4"
            weight={700}
            margin="0 0 8px 0"
            color={theme.colors.text[status ? 'default' : 'disabled']}
          >
            {name}
          </Body>
          <Body
            type="copy4"
            color={theme.colors.text[status ? 'default' : 'disabled']}
          >
            {plan_model && EPlanModelToPortuguese[plan_model]}
          </Body>
        </Aligner>
        {!status && (
          <Styled.TextBackground>
            <Body
              type="copy4"
              color={theme.colors.text.disabled}
              margin="0 0 0 auto"
              weight={500}
            >
              Desativado
            </Body>
          </Styled.TextBackground>
        )}
      </Styled.InnerCard>
    </Swipe>
  )
}

export default Mobile
