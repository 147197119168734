// Libraries
import styled from 'styled-components'

// Components
import { Body } from 'heeds-ds'

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.surface.default};
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: 15px;
  box-shadow: 0px 2px 5px 0px rgba(43, 42, 99, 0.12);
  padding: 2.4rem 1.6rem;
  margin: 0.4rem 0rem;
  width: 100%;
`

export const Text = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.subdued,
  type: 'copy3',
  weight: 500,
}))``

export const Title = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.secondary,
  margin: '0 0 0.8rem',
  type: 'copy3',
  weight: 700,
}))``
