// Libraries
import styled from 'styled-components'
import { Tag } from 'heeds-ds'

export const CustomPlanTag = styled(Tag).attrs(() => ({}))`
  cursor: pointer;
  pointer-events: all;
  padding: 30px;
`

export const TagWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  width: 100%;
  margin: 0 0 16px 0;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 0;
  }
`

export const TagOptionsWrapper = styled.div`
  display: none;
  position: absolute;
  top: 12px;
  right: 24px;
  gap: 24px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: flex;
  }
`
