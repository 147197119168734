// Models
import { IAction } from 'lib/redux/models'
import { ISubscriptionData } from '../models'
import { ISubscriptionDeactivatePayload } from 'services/financialSubscription/@types'

// Libraries
import { SagaIterator } from 'redux-saga'
import { all, takeLatest } from 'redux-saga/effects'
import { call, put } from 'redux-saga/effects'

// Misc
import {
  activateFinancialSubscription,
  deactivateFinancialSubscription,
} from 'services/financialSubscription'
import { failure, fulfill, request, success, Types } from '../duck'
import { triggerToastError } from 'storage/general/duck'

export function* deactivateFinancialSubscriptionSaga(
  action: IAction<ISubscriptionDeactivatePayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback } = action.payload
      const response: ISubscriptionData = yield call(
        deactivateFinancialSubscription,
        action.payload,
      )
      yield put(success({ subscription: response }))

      if (successCallback) {
        successCallback()
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* activateFinancialSubscriptionSaga(
  action: IAction<ISubscriptionDeactivatePayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback } = action.payload
      const response: ISubscriptionData = yield call(
        activateFinancialSubscription,
        action.payload,
      )
      yield put(success({ subscription: response }))

      if (successCallback) {
        successCallback()
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export default function* financialAthletesSaga(): SagaIterator {
  yield all([
    takeLatest(
      Types.SUBSCRIPTION_DEACTIVATE,
      deactivateFinancialSubscriptionSaga,
    ),
  ])
  yield all([
    takeLatest(Types.SUBSCRIPTION_ACTIVATE, activateFinancialSubscriptionSaga),
  ])
}
