// Models
import { IBrasilAbertoResponse, IViaCepResponse } from './@types'

// Misc
import { externalApi } from 'lib/axios/api'

export const getCepByViaCep = async (
  zipcode: string,
): Promise<IViaCepResponse> => {
  try {
    const { data }: { data: IViaCepResponse } = await externalApi.get(
      `https://viacep.com.br/ws/${zipcode}/json/`,
    )

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const getCepByBrasilAberto = async (
  zipcode: string,
): Promise<IBrasilAbertoResponse> => {
  try {
    const { data }: { data: IBrasilAbertoResponse } = await externalApi.get(
      `https://api.brasilaberto.com/v1/zipcode/${zipcode}`,
    )

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}
