// React
import { ChangeEvent, FC } from 'react'

// Components
import * as Styled from './styled'
import { useController, useFormContext } from 'react-hook-form'

type Props = {
  disabled?: boolean
  name: string
}

const Switch: FC<Props> = (props: Props) => {
  const { disabled, name } = props

  const { control } = useFormContext()
  const {
    field: { onChange, ref, value },
  } = useController({
    name,
    control,
  })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked)
  }

  return (
    <Styled.Label disabled={disabled}>
      <Styled.Input
        data-testid="switch"
        checked={value}
        disabled={disabled}
        type="checkbox"
        onChange={handleChange}
        name={name}
        ref={ref}
      />
      <Styled.Switch disabled={disabled} />
    </Styled.Label>
  )
}

export default Switch
