// React
import { FC, useContext, useEffect, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Custom
import {
  Container,
  Content,
  Description,
  Header,
  IconContainer,
  TopContainer,
} from './styled'
import { Body } from 'components/UI/Typography'
import { Icon } from 'heeds-ds'

type Props = {
  children?: React.ReactNode | React.ReactNode[]
  collapsible?: boolean
  description?: React.ReactNode
  height?: string
  margin?: string
  mobileCard?: boolean
  padding?: string
  startsOpen?: boolean
  title?: React.ReactNode | React.ReactNode[]
}

const ContentBox: FC<Props> = ({
  children,
  collapsible,
  description,
  height,
  margin,
  mobileCard,
  padding,
  startsOpen = false,
  title,
}) => {
  const [collapsed, setCollapsed] = useState(!startsOpen)
  const theme = useContext(ThemeContext)

  const toggleCollapse = (event: React.MouseEvent) => {
    event.stopPropagation()
    collapsible && setCollapsed((prev: boolean) => !prev)
  }

  useEffect(() => {
    !startsOpen && setCollapsed(true)
  }, [startsOpen])

  useEffect(() => {
    !collapsible && setCollapsed(false)
  }, [collapsible])

  return (
    <Container
      collapsed={collapsed}
      data-testid="container"
      hasDescription={description !== undefined}
      height={height}
      margin={margin}
      mobileCard={mobileCard}
      onClick={(event) => collapsed && toggleCollapse(event)}
      startsOpen={startsOpen}
    >
      <TopContainer
        collapsed={collapsed}
        data-testid="tapContainer"
        onClick={toggleCollapse}
        margin={margin}
      >
        <Header collapsible={collapsible}>
          <Body
            data-testid="body"
            color={theme.colors.text.secondary}
            type="copy3"
            weight={700}
          >
            {title}
          </Body>
          {collapsible && (
            <IconContainer>
              <Icon
                color={theme.colors.icon.default}
                iconName={collapsed ? 'expandMore' : 'expandLess'}
              />
            </IconContainer>
          )}
        </Header>
        {description && <Description>{description}</Description>}
      </TopContainer>
      <Content collapsed={collapsed} data-testid="content" padding={padding}>
        {children}
      </Content>
    </Container>
  )
}

export default ContentBox
