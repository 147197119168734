// Models
import { BodyVariationsTypes } from 'lib/styled-components/models'

// React
import { FC, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// Components
import * as Styled from './styled'

export interface Tab {
  label: string
  to: string
}

interface Props {
  avoidNextNavigation?: boolean
  blockNavigation?: boolean
  blockedIndexes?: number[]
  fontSize?: BodyVariationsTypes
  items: Tab[]
  itemsPadding?: string
  padding?: string
  progress?: boolean
  showBlockedIcon?: boolean
}

const TabNavigator: FC<Props> = ({
  avoidNextNavigation,
  blockNavigation,
  blockedIndexes,
  fontSize,
  items,
  itemsPadding,
  padding,
  progress,
  showBlockedIcon = false,
}) => {
  const { pathname, state = {} } = useLocation()
  const navigate = useNavigate()
  const container = useRef<HTMLDivElement>(null)

  const [scrollAtRight, setScrollAtRight] = useState(false)

  const handleScroll = () => {
    const hiddenBlur =
      container?.current &&
      container.current.scrollLeft + container.current.offsetWidth ===
        container.current.scrollWidth

    if (hiddenBlur) {
      setScrollAtRight(true)
    } else {
      setScrollAtRight(false)
    }
  }

  useEffect(() => {
    setScrollAtRight(
      (container?.current &&
        window.innerWidth > container.current?.scrollWidth) ||
        false,
    )
  }, [])

  useEffect(() => {
    const containerRef = container.current

    containerRef?.addEventListener('scroll', handleScroll)
    return () => {
      containerRef?.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const currentIndex = items.findIndex((item) => item.to === pathname)

  const handleNavigation = (index: number) => {
    if (blockedIndexes) {
      return blockedIndexes.includes(index)
    }
    if (avoidNextNavigation) {
      return index > currentIndex
    }
    if (blockNavigation) {
      return index !== currentIndex
    }
    return false
  }

  return (
    <Styled.Container
      padding={padding}
      progress={progress ? true : undefined}
      scrollAtRight={scrollAtRight}
      ref={container}
    >
      {items.map((item, index) => (
        <Styled.TabItem
          aria-current={index === currentIndex ? 'page' : false}
          aria-disabled={handleNavigation(index)}
          fill={progress && index < currentIndex ? true : undefined}
          fontSize={fontSize}
          itemsPadding={itemsPadding}
          key={item.label}
          onClick={() => navigate(item.to)}
          progress={progress ? true : undefined}
          state={state}
          to={item.to}
        >
          {showBlockedIcon && blockedIndexes?.includes(index) && (
            <Styled.LockIcon />
          )}
          {item.label}
        </Styled.TabItem>
      ))}
    </Styled.Container>
  )
}

export default TabNavigator
