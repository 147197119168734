// Libraries
import styled from 'styled-components'

// Components
import { Body } from 'heeds-ds'

// Assets
import anamneseCardImage from 'assets/images/evaluation-card-anamnese.png'

export const Card = styled.div`
  align-items: center;
  background: linear-gradient(#2b2a63, #22234b99, #15192800),
    url(${anamneseCardImage}) no-repeat center;
  background-size: cover;
  border-radius: 16px;
  display: flex;
  height: 94px;
  justify-content: space-between;
  padding: 24px;
`

export const Title = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.onPrimary,
  type: 'copy1',
  weight: 700,
}))``
