// Libraries
import styled from 'styled-components'

// Components
import { Aligner } from 'heeds-ds'

type Props = {
  isVisible?: boolean
}

export const Content = styled.div`
  margin: 16px 32px;
  position: relative;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    min-width: 572px;
  }
`

export const ScrollModal = styled(Aligner).attrs(() => ({
  padding: '0 2.4rem 0 0',
  flex: 'column',
  gap: '16px',
}))`
  max-height: 40vh;
  overflow: scroll;
  width: 677px;

  ::-webkit-scrollbar {
    background: ${({ theme }) => theme.colors.action.secondaryDisabled};
    border-radius: 6px;
    height: 0;
    margin-left: 1px;
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.action.secondaryDefault};
    border-radius: 6px;
  }
`

export const ShowList = styled.div<Props>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`

export const TagContainer = styled.div`
  margin-bottom: 8px;
  width: 100%;
`

export const TagInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
`

export const TagLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`

export const RadioContainer = styled.div``
