// React
import React, { ReactNode, useCallback, useState } from 'react'

// Libraries
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

// Components
import DraggableWrapper from './DraggableWrapper'

export type Props<T extends { id: string }> = {
  items: T[]
  moveItem?: (dragIndex: number, hoverIndex: number) => void
  renderItem: (
    item: T,
    dragRef: React.RefObject<HTMLDivElement>,
    index: number,
    isDragging: boolean,
  ) => ReactNode
}

const DraggableArea = <T extends { id: string }>({
  items,
  moveItem,
  renderItem,
}: Props<T>) => {
  const [innerItems, setInnerItems] = useState<T[]>(items)

  const selectedList = moveItem ? items : innerItems

  const defaultMoveItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newItems = [...innerItems]
      const dragItem = newItems[dragIndex]
      newItems.splice(dragIndex, 1)
      newItems.splice(hoverIndex, 0, dragItem)
      setInnerItems(newItems)
    },
    [innerItems],
  )

  const backendForDevice = window.matchMedia('(pointer: coarse)').matches
    ? TouchBackend
    : HTML5Backend

  return (
    <DndProvider backend={backendForDevice} data-testid="droparea">
      {selectedList.map((item, index) => {
        return (
          <DraggableWrapper
            key={item.id}
            index={index}
            moveItem={moveItem || defaultMoveItem}
            renderItem={(dragRef, isDragging) =>
              renderItem(item, dragRef, index, isDragging)
            }
          />
        )
      })}
    </DndProvider>
  )
}

export default DraggableArea
