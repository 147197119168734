// Libraries
import styled, { css } from 'styled-components'

type MenuProps = {
  open?: boolean
}

export const Backdrop = styled.div<MenuProps>`
  ${({ theme, open }) => css`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #2b2a63;
    opacity: ${open ? 0.54 : 0};
    visibility: ${open ? 'visible' : 'hidden'};
    transition: all 0.3s ease-in-out;
    z-index: 18;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: none;
    }
  `}
`

export const Container = styled.div<MenuProps>`
  ${({ theme, open }) => css`
    width: 100vw;
    max-height: 90vh;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 5.6rem 1.6rem;
    background-color: ${theme.colors.surface.default};
    box-shadow: 2px 2px 13px 1px rgba(205, 205, 205, 0.25);
    border-radius: 2.4rem 2.4rem 0 0;
    z-index: 19;
    transition: all 0.2s ease-in-out;
    transform: translateY(${open ? '0' : '100%'});
    visibility: ${open ? 'visible' : 'hidden'};
    overflow-y: auto;
  `}
`

export const GestureBar = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 3.7rem;
    position: absolute;
    top: 0;
    left: 0;
    ::after {
      user-select: none;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
      width: 32px;
      height: 5px;
      border-radius: 3px;
      background-color: ${theme.colors.icon.default};
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: none;
    }
  `}
`

export const Sidebar = styled.div`
  position: sticky;
  top: 0;
  bottom: 0;
  left: 0;
  width: 92px;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  justify-items: center;
  padding: 4rem 0.8rem;
  gap: 4rem;
  z-index: 2;
`
