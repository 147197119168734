// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body, Button } from 'heeds-ds'

// Assets
import zigzagArrow from 'assets/images/zigzag-arrow.svg'

export const AddModelButton = styled(Button).attrs(() => ({
  margin: '0',
  size: 'small',
  variation: 'borderless',
}))``

export const ContentPage = styled(Aligner).attrs(() => ({
  gap: '24px',
}))`
  position: relative;
`

export const EmptyModels = styled(Aligner).attrs(() => ({
  align: 'center',
  justify: 'center',
}))`
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 0;
`

export const EmptyText = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.subdued,
  type: 'copy3',
}))``

export const GoBackButton = styled(Button).attrs(() => ({
  margin: '0',
  size: 'small',
  variation: 'borderless',
}))``

export const LeftColumn = styled.div`
  width: calc(40% - 12px);
  z-index: 1;
`

export const RightColumn = styled.div`
  width: calc(60% - 12px);
`

export const ZigZagArrow = styled.div`
  align-items: center;
  background: url(${zigzagArrow}) no-repeat center right;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
`
