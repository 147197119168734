// Libraries
import styled from 'styled-components'

// Custom
import { Body } from '../UI/Typography'
import { NavLink } from 'react-router-dom'

type Props = {
  fill?: number
  length?: number
  progress?: boolean
  disabled?: boolean
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  ::after {
    content: '';
    height: 100%;
    width: 5%;
    position: absolute;
    right: 0;
    background-image: linear-gradient(
      to right,
      rgba(255, 0, 0, 0),
      ${({ theme }) => theme.colors.surface.default}
    );
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    ::after {
      content: '';
      width: 0px;
    }
  }
`

export const StepsContainer = styled.div<Props>`
  position: relative;
  display: flex;
  width: 100%;
  ${Body} {
    margin: 0 15px;
    cursor: pointer;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: ${({ progress }) =>
      progress ? 'space-between' : 'center'};
    margin-bottom: 16px;
    ${Body} {
      margin: 0px;
    }
    ::after {
      content: '';
    }
  }
`

export const StepsBackground = styled.div<Props>`
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  position: relative;
  background-color: ${({ theme }) => theme.colors.surface.default};
  padding: 30px 24px 15px 24px;
  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    overflow-x: clip;
    border-radius: 15px;
    padding: ${({ progress }) => (progress ? '16px 24px' : '16px 24px 0px')};
  }
`

export const StepBarBackground = styled.div<Props>`
  position: ${({ progress }) => (progress ? 'absolute' : 'block')};
  left: 14px;
  top: 80%;
  height: 5px;
  background: ${({ theme }) => theme.colors.surface.neutralDefault};
  border-radius: 4px;
  margin: ${({ progress }) => (progress ? '0px 10px 0 10px' : '10px 0 0 0')};
  width: ${({ length }) => length}%;
  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    width: 24px;
    height: 1px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
    position: block;
    margin-top: ${({ progress }) => (progress ? '0px' : '16px')};
    left: 0px;
    position: ${({ progress }) => (progress ? 'relative' : 'block')};
    width: 100%;
  }
`

export const ProgressBar = styled.div<Props>`
  height: 100%;
  width: ${({ fill, progress }) => (progress ? fill : 0)}%;
  background: ${({ theme }) => theme.colors.action.secondaryDefault};
  border-radius: 4px;
`

export const StepsWrapper = styled(NavLink)<Props>`
  width: 100%;
  margin: ${({ progress }) => (progress ? '16px 10px' : '0px 10px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  pointer-events: ${({ disabled }) =>
    disabled || disabled === undefined ? 'none' : ''};
  :last-child {
    margin: ${({ progress }) => (progress ? '16px 10px' : '0 0 0 10px')};
  }
  :first-child {
    margin: ${({ progress }) => (progress ? '16px 10px 16px 0' : '0 10px 0 0')};
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 0 10px;
    align-items: flex-start;
    :last-child {
      margin: 0 0 0 10px;
    }
    :first-child {
      margin: 0 10px 0 0;
    }
  }
  ${Body} {
    color: ${({ theme }) => theme.colors.text.subdued};
  }
  &.active {
    ${ProgressBar} {
      width: 100%;
    }
    ${Body} {
      color: ${({ theme }) => theme.colors.text.secondary};
    }
  }
`
