import { theme } from 'lib/styled-components/theme'
import styled, { keyframes } from 'styled-components'

type toastProps = {
  show?: boolean
  color?: 'success' | 'warning' | 'info' | 'error'
  margin?: string
}

const popFromTop = keyframes`
 0% {transform: scale(0.1) translateX(-100px); opacity: 0.3;}
 30% {transform: scale(1) translateX(0px); opacity: 1;}
`

const BorderColors = {
  success: theme.colors.icon.success,
  warning: theme.colors.icon.warning,
  info: theme.colors.icon.highlight,
  error: theme.colors.icon.critical,
}

export const ToastWrapper = styled.div<toastProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  width: min(95%, 40rem);
  right: 1.6rem;
  top: 3.2rem;
  background: ${({ theme }) => theme.colors.surface.default};
  border-left: 4px solid
    ${({ color }) => (color ? BorderColors[color] : BorderColors.info)};
  box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
    0px 8px 12px rgba(9, 30, 66, 0.12);
  animation-delay: 0.8s;
  animation: ${popFromTop} 0.8s ease-in-out;
  padding: 1.6rem 2.4rem;
  border-radius: 3px;
  z-index: 999;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    right: 50%;
    top: 0.8rem;
    transform: translateX(50%);
  }
`

export const IconWrapper = styled.div<toastProps>`
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  cursor: pointer;
`
