// Libraries
import { Subtitle, Title } from 'components/UI/Typography'
import styled, { css } from 'styled-components'

export const Form = styled.form`
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 0 3.8rem;
  }
`

export const MainTitle = styled(Title).attrs(() => ({
  align: 'center',
  type: 'h1',
}))`
  ${({ theme }) => css`
    color: ${theme.colors.text.default};
    font-weight: ${theme.fontWeight.semiBold};
    margin: 0 0 4rem 0;
    @media screen and (max-width: ${theme.breakpoints.desktop}px) {
      font-size: ${theme.fontSize.sh1};
      line-height: ${theme.lineHeight.sh1};
      margin: 0 0 5.6rem 0;
    }
  `}
`

export const SubText = styled(Subtitle).attrs(() => ({
  align: 'center',
  type: 'sh3',
}))`
  ${({ theme }) => css`
    margin: 0 0 4rem 0;
    color: ${theme.colors.text.subdued};
    @media screen and (max-width: ${theme.breakpoints.desktop}px) {
      font-size: ${theme.fontSize.copy1};
      line-height: ${theme.lineHeight.sh3};
    }
  `}
`
