// Libraries
import styled from 'styled-components'

// Components
import { Aligner } from 'heeds-ds'

export const Form = styled.form`
  display: none;
`

export const FormContent = styled(Aligner).attrs(() => ({
  flex: 'column',
}))`
  min-height: calc(100vh - 40.2rem);

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    min-height: calc(100vh - 32.9rem);
    width: 57rem;
  }
`
