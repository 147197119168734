// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body, Icon, IconButton } from 'heeds-ds'

export const ButtonContainer = styled(Aligner).attrs({
  padding: '1.2rem 6.4rem',
  justify: 'center',
})`
  position: fixed;
  bottom: 0;
  backdrop-filter: blur(1.5px);
`

export const Container = styled(Aligner).attrs({
  flex: 'column',
  padding: '0rem 0 9.2rem',
})`
  flex-grow: 1;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 2.4rem;
    height: 100vh;
    padding: 4rem 4.8rem 0 4rem;
  }
`

export const Content = styled(Aligner).attrs({
  flex: 'column',
})`
  flex-grow: 1;
  overflow-y: auto;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    background: ${({ theme }) => theme.colors.surface.default};
    border-radius: 1.5rem;
    box-shadow: 0px 4px 80px 0px rgba(43, 42, 99, 0.1);
  }
`

export const ContentHeader = styled(Aligner).attrs({
  align: 'center',
  gap: '2.4rem',
  justify: 'space-between',
  padding: '2.4rem 2.4rem 1.6rem',
})``

export const SwapVertButton = styled(IconButton).attrs({
  iconName: 'swapVert',
  margin: '0',
  size: 'small',
  variation: 'main',
})``

export const SwapVertIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.colors.interactive.default,
  iconName: 'swapVert',
}))``

export const ModalContainer = styled(Aligner).attrs({
  flex: 'column',
  padding: '2.2rem 0 8rem',
})`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 3.2rem;
    width: 47rem;
  }
`

export const ModalContent = styled(Aligner).attrs({
  flex: 'column',
  padding: '0 4rem',
})`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0;
  }
`

export const ModalTitle = styled(Body).attrs({
  align: 'center',
  margin: '0 0 4rem',
  weight: 600,
})`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.input};
  padding: 0 0 1.6rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    border-bottom: none;
    font-size: ${({ theme }) => theme.fontSize.sh2};
    padding: 0;
    text-align: left;
  }
`

export const ModalDescription = styled(Body).attrs(({ theme }) => ({
  color: theme.colors.text.subdued,
  margin: '0 0 3.2rem',
}))``
