// React
import { FC, useContext } from 'react'

import { ThemeContext } from 'styled-components'

import { useMediaQuery } from 'heeds-ds/src/hooks'

// Components
import { Skeleton } from 'heeds-ds'
import { Cell, Row } from './desktop/styled'
import { Container, OptionsContainer, TitlesContainer } from './mobile/styled'

const Loader: FC = () => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  return isDesktop ? (
    <Row>
      <Cell>
        <Skeleton width="25rem" height="1.6rem" />
      </Cell>
      <Cell>
        <Skeleton width="15rem" height="1.6rem" />
      </Cell>
      <Cell>
        <Skeleton width="10rem" height="1.6rem" />
      </Cell>
      <Cell>
        <Skeleton width="3.6rem" height="3.6rem" />
      </Cell>
    </Row>
  ) : (
    <Container>
      <TitlesContainer>
        <Skeleton width="18rem" height="2.1rem" />
        <Skeleton width="6rem" height="1.9rem" />
      </TitlesContainer>
      <OptionsContainer>
        <Skeleton width="3.6rem" height="3.6rem" />
      </OptionsContainer>
    </Container>
  )
}

export default Loader
