//React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

//Components
import { Aligner, SimpleContainer } from '../index'
import { Body, GenericTextAction } from '../UI/Typography'
import { IconWrapper, ToastWrapper } from './styled'
import Icon from '../UI/Icons'

type Props = {
  actionText?: string
  closeToast?: () => void
  customTitle?: string
  href?: string
  show: boolean
  text?: string
  variation?: 'success' | 'warning' | 'info' | 'error'
}

enum ETitles {
  error = 'Algo deu errado',
  info = 'Aviso',
  success = 'Sucesso!',
  warning = 'Atenção',
}

enum EIcons {
  error = 'circleX',
  info = 'circleInfo',
  success = 'circleCheck',
  warning = 'circleWarning',
}

const Toast: FC<Props> = (props: Props) => {
  const theme = useContext(ThemeContext)
  const {
    show = true,
    text,
    variation = 'success',
    href,
    actionText,
    closeToast,
    customTitle,
  } = props
  const colors = {
    success: theme.colors.text.success,
    warning: theme.colors.text.warning,
    info: theme.colors.text.highlight,
    error: theme.colors.text.critical,
  }

  return (
    <ToastWrapper show={show} color={variation} data-testid="toast">
      <Aligner gap="1.6rem">
        <Icon
          iconName={EIcons[variation]}
          size={24}
          color={colors[variation]}
        />

        <SimpleContainer>
          <Body
            type="copy4"
            color={colors[variation]}
            weight={700}
            margin="0.4rem 0 0"
          >
            {customTitle ? customTitle : ETitles[variation]}
          </Body>
          <Body
            type="copy4"
            color={theme.colors.text.subdued}
            margin="0.8rem 0 0"
          >
            {text}
          </Body>
          {href && (
            <Aligner margin="1.6rem 0 0">
              <GenericTextAction href={href}>{actionText}</GenericTextAction>
            </Aligner>
          )}
        </SimpleContainer>
      </Aligner>
      <IconWrapper onClick={closeToast} data-testid="toastCloseButton">
        <Icon iconName="close" size={24} color={theme.colors.icon.disabled} />
      </IconWrapper>
    </ToastWrapper>
  )
}

export default Toast
