// Libraries
import styled from 'styled-components'

type Props = {
  value?: number
  margin?: string
}

export const Background = styled.div`
  background: ${({ theme }) => theme.colors.surface.neutralDefault};
  border-radius: 8px;
  height: 8px;
  width: 100%;
`
export const Container = styled.div<Props>`
  margin: ${({ margin }) => `${margin || 0}`};
  width: 100%;
`

export const Label = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: ${({ theme }) => theme.fontSize.copy3};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  width: 100%;
`

export const Progress = styled.div<Props>`
  background: ${({ theme }) => theme.colors.icon.default};
  border-radius: 8px;
  width: ${({ value }) => `${value || 0}%`};
  height: 8px;
`
