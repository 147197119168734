// Models
import {
  TypeCREF,
  TypeGenders,
  TWeekdays,
  TPlanModel,
  TValue,
  TPlanType,
} from 'models'
import { EFirebaseProviderOptions } from 'storage/auth/models'
import { IBaseState } from 'lib/redux/models'
import { TPlanPeriodicity } from 'services/financial/@types'

export enum EProfileActionTypes {
  CLEANUP = 'PROFILE/CLEANUP',
  FAILURE = 'PROFILE/FAILURE',
  FULFILL = 'PROFILE/FULFILL',
  REQUEST = 'PROFILE/REQUEST',
  SUCCESS = 'PROFILE/SUCCESS',
  GET_PERSONAL_APPOINTMENT_TIME = 'PROFILE/GET_PERSONAL_PROFILE_APPOINTMENT_TIME',
  GET_PERSONAL_PROFILE = 'PROFILE/GET_PERSONAL_PROFILE',
  CREATE_OR_UPDATE_PERSONAL_APPOINTMENT_TIME = 'PROFILE/CREATE_OR_UPDATE_PERSONAL_APPOINTMENT_TIME',
  CREATE_PERSONAL_PROFILE = 'PROFILE/CREATE_PERSONAL_PROFILE',
  UPDATE_PERSONAL_PROFILE = 'PROFILE/UPDATE_PERSONAL_PROFILE',
  SCHEDULE = 'PROFILE/SCHEDULE',
  ATHLETES_SCHEDULE = 'PROFILE/ATHLETES_SCHEDULE',
  GET_INFO = 'PROFILE/GET_INFO',
}

export interface IProfileState extends IBaseState {
  appointment_times?: IAppointmentTime[]
  profile?: IPersonalData
  athletesSchedules?: IPersonalAthletesSchedules
  info?: IUserInfo
}

export interface IPersonalData {
  address?: string
  birthdate?: string | null
  cep?: string
  city?: string
  crefs?: TypeCREF[]
  email?: string
  complement?: string
  name?: string
  gender?: TypeGenders
  instagram?: string
  last_name?: string
  plans?: IPersonalPlans[]
  phone_number?: string
  state?: string
}

export interface IAthletePlans {
  online: boolean
  in_person: boolean
  online_cost?: IPlanCosts
  presential_cost?: number
  extra_workouts?: IWorkoutData<number>[]
}

interface IPlanCosts {
  monthly: {
    active: boolean
    cost: number
  }
  quarterly: {
    active: boolean
    cost: number
  }
  semiannual: {
    active: boolean
    cost: number
  }
  yearly: {
    active: boolean
    cost: number
  }
}

export interface IWorkoutData<TCost> {
  name: string
  location: string
  cost: TCost
  days: TWeekdays[]
  start_time: string
  end_time: string
  id: string
}

export interface IPersonalPlans {
  online: boolean
  in_person: boolean
  online_cost?: IPlanCosts
  presential_cost?: number
  extra_workouts?: IWorkoutData<number>[]
}

export interface IFinancialPlan {
  id?: number
  personal?: number
  plan_type: TPlanType
  plan_model: TPlanModel
  value: TValue
  status: string
  has_subscriptions?: boolean
  has_active_subscriptions?: boolean
  plan_periodicity: TPlanPeriodicity
}

export interface IAppointmentTime {
  address?: string
  day: TWeekdays
  days?: TWeekdays[]
  end: string
  id?: number
  start: string
}

export interface ISchedulePayload {
  [index: number]: { day?: string; start?: string; end?: string }
}

export interface IScheduleResponse {
  [index: number]: { day?: string; start?: string; end?: string }
}

export interface IRecurringTimes {
  id?: number
  sun: boolean
  mon: boolean
  tue: boolean
  wed: boolean
  thu: boolean
  fri: boolean
  sat: boolean
}

export interface IAthletesSchedulesResults {
  id: number
  athlete: number
  date: string
  start: string
  end: string
  location: string
  recurring_schedule: IRecurringTimes
  athlete_full_name: string
  athlete_name: string
  athlete_active_subscription_end_date: string
  athlete_active_subscription_start_date: string
}

export interface IPersonalAppointmentTimes {
  count: number
  next: string
  previous: string
  results: IAppointmentTime[]
}

export interface IPersonalAthletesSchedules {
  count: number
  next: string
  previous: string
  results: IAthletesSchedulesResults[]
}

export interface IUserInfo {
  id?: number
  profile_pk?: number
  email: string
  is_active?: boolean
  firebase_uid?: string
  name?: string
  provider: EFirebaseProviderOptions
  type: string
  has_plans?: string
  has_appointment_time?: string
  has_completed_profile_data?: string
  subscription_id?: string
}
