// React
import { FC } from 'react'

// Components
import { InDevelopment } from 'views/general'

const Schedule: FC = () => {
  return <InDevelopment pagename="AGENDA" />
}

export default Schedule
