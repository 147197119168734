// Models
import { IAthleteInfoState } from 'storage/athleteInfo/models'
import { IAthleteOutletContext } from 'layouts/AthleteRegisterLayout'
import { IAthleteInfo } from 'services/athleteInfo/@types'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useEffect } from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useOutletContext } from 'react-router-dom'

// Misc
import { prepareAthleteGeneralToDisplay } from 'filters/athlete'
import { triggerUpdateAthlete } from 'storage/athleteInfo/duck'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import * as Styled from './styled'
import { Loading, TextArea } from 'heeds-ds'
import { FooterActionButton, NewContentBox } from 'components'

// Constants
const SECTION = {
  title: 'Comentários sobre o aluno',
  description:
    'Deixe aqui anotações que você considera como importantes. Não se preocupe, o comentário não será compartilhado com o aluno.',
}

export interface FormInputs {
  comment: string
  sendToAthlete: boolean
  toggle?: boolean
}

const AthleteComment: FC = () => {
  const { loading, info } = useSelector<IStore, IAthleteInfoState>(
    (state) => state.athleteInfo,
  )
  const { id, navigateToNextTab } = useOutletContext<IAthleteOutletContext>()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const formMethods = useForm<FormInputs>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    delayError: 800,
  })
  const { handleSubmit, reset } = formMethods

  const onSubmit = (data: FormInputs) => {
    const { comment } = data
    const payload: IAthleteInfo = {
      comment,
      id: id ? parseInt(id) : 0,
    }

    dispatch(
      triggerUpdateAthlete({
        ...payload,
        successCallback: () => navigateToNextTab(),
      }),
    )
  }

  useEffect(() => {
    if (info && info.id === parseInt(id)) {
      const formattedInfo = prepareAthleteGeneralToDisplay(info)
      reset(formattedInfo)
    }
  }, [id, info, reset])

  if (loading) {
    return <Loading active />
  }

  return (
    <FormProvider {...formMethods}>
      <Styled.FormContainer onSubmit={handleSubmit(onSubmit)}>
        <NewContentBox {...SECTION}>
          <TextArea
            placeholder="Escreva seus comentários sobre o aluno aqui."
            height="270px"
            margin="0"
            name="comment"
            scale="small"
            width={isDesktop ? '57rem' : ''}
          />
        </NewContentBox>

        <FooterActionButton
          loading={loading}
          onClick={() => navigate(-1)}
          primaryTitleButton="Salvar"
          secundaryTitleButton="Voltar"
          twoButtons
        />
      </Styled.FormContainer>
    </FormProvider>
  )
}

export default AthleteComment
