// Libraries
import styled, { css } from 'styled-components'

type InputProps = {
  color?: string
  disabled?: boolean
  scale?: 'small' | 'medium' | 'large'
  textArea?: boolean
  width?: string
}

type WrapperProps = {
  borderColor: string
}

enum EFontSizes {
  small = '14px',
  medium = '16px',
  large = '18px',
}

enum EPaddings {
  small = '12px 0px 12px 16px',
  medium = '15px 0px 15px 16px',
  large = '18px 0px 18px 16px',
}

enum EHeightSizes {
  small = 40,
  medium = 48,
  large = 57,
}

const InputStyle = css<InputProps>`
  border: none;
  caret-color: #202223;
  color: ${({ theme }) => theme.colors.text.default};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: ${({ scale }) => (scale ? EFontSizes[scale] : EFontSizes.medium)};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  width: ${({ width }) => width || '100%'};
  outline: none;
  padding: ${({ scale }) => (scale ? EPaddings[scale] : EPaddings.medium)};
  transition: all 0.3s ease;
  ::placeholder {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.text.disabled : theme.colors.text.subdued};
  }
  :disabled {
    background: ${({ theme }) => theme.colors.surface.disabled};
    color: ${({ theme }) => theme.colors.text.subdued};
  }
`

export const SimpleInput = styled.input<InputProps>`
  ${InputStyle};
  background-color: transparent;
`

export const IconWrapper = styled.div<InputProps>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const InputWrapperInner = styled.div`
  position: relative;
  width: 100%;
`

export const InputContainer = styled.div<WrapperProps>`
  background: ${({ theme }) => theme.colors.surface.default};
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 3px;
  display: flex;
  width: 100%;
  transition: all 0.3s ease;
`

export const OptionsContainer = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.1);
  left: 0;
  margin-top: 4px;
  max-height: 200px;
  overflow-y: scroll;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 2;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.icon.hovered};
    border-radius: 6px;
  }
`

export const Option = styled.div`
  cursor: pointer;

  padding: 12px 16px;
  :hover {
    background-color: #f5f7ff;
    p {
      font-weight: ${({ theme }) => theme.fontWeight.semiBold};
      color: #202223;
    }
  }
`

export const IconButton = styled.div<InputProps>`
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  height: ${({ scale }) =>
    scale ? EHeightSizes[scale] + 2 : EHeightSizes.medium + 2}px;
  text-align: center;
  padding: 0 8px;
  z-index: 1;
`
