// Models
import { IAction } from 'lib/redux/models'
import {
  IAppointmentTime,
  IPersonalAppointmentTimes,
  IPersonalAthletesSchedules,
  IPersonalData,
  IUserInfo,
  // ISchedulePayload,
  // IScheduleResponse,
} from '../models'
import { SagaIterator } from '@redux-saga/types'

// Libraries
import { all, call, put, takeLatest } from 'redux-saga/effects'

// Misc
import { failure, fulfill, request, success, Types } from '../duck'
import {
  getAllAthletesTimeSchedule,
  getPersonalAppointmentTimes,
  getPersonalProfile,
  createOrUpdatePersonalAppointmentTimes,
  updatePersonalProfile,
  getUserInfo,
} from 'services/profile'
import { triggerToastError, triggerToastSuccess } from 'storage/general/duck'

export function* getPersonalProfileSaga() {
  yield put(request())
  try {
    const response: IPersonalData = yield call(getPersonalProfile)

    if (response) {
      yield put(success({ profile: response }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* updatePersonalProfileSaga(action: IAction<IPersonalData>) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IPersonalData = yield call(
        updatePersonalProfile,
        action.payload,
      )

      if (response) {
        yield put(success({ profile: response }))
        yield put(triggerToastSuccess({ message: 'Seus dados foram salvos' }))
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* getPersonalAppointmentTimesSaga() {
  yield put(request())
  try {
    const response: IPersonalAppointmentTimes = yield call(
      getPersonalAppointmentTimes,
    )
    if (response) {
      yield put(success({ appointment_times: response.results }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* createOrUpdatePersonalAppointmentTimesSaga(
  action: IAction<IAppointmentTime[]>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IAppointmentTime[] = yield call(
        createOrUpdatePersonalAppointmentTimes,
        action.payload,
      )

      if (response) {
        yield put(success({ appointment_times: response }))
        yield put(
          triggerToastSuccess({ message: 'Seus horários foram salvos' }),
        )
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(
      triggerToastError({
        message: 'Por favor,de preencher todos os horários por completo.',
      }),
    )
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* getAllAthletesTimeScheduleSaga() {
  yield put(request())
  try {
    const response: IPersonalAthletesSchedules = yield call(
      getAllAthletesTimeSchedule,
    )

    if (response) {
      yield put(success({ athletesSchedules: response }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* getUserInfoSaga() {
  yield put(request())
  try {
    const response: IUserInfo = yield call(getUserInfo)

    if (response) {
      yield put(success({ info: response }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export default function* authSaga(): SagaIterator {
  yield all([
    takeLatest(Types.GET_PERSONAL_PROFILE, getPersonalProfileSaga),
    takeLatest(Types.UPDATE_PERSONAL_PROFILE, updatePersonalProfileSaga),
    takeLatest(
      Types.GET_PERSONAL_APPOINTMENT_TIME,
      getPersonalAppointmentTimesSaga,
    ),
    takeLatest(
      Types.CREATE_OR_UPDATE_PERSONAL_APPOINTMENT_TIME,
      createOrUpdatePersonalAppointmentTimesSaga,
    ),
    takeLatest(Types.ATHLETES_SCHEDULE, getAllAthletesTimeScheduleSaga),
    takeLatest(Types.GET_INFO, getUserInfoSaga),
  ])
}
