// Libraries
import { Body, Button } from 'heeds-ds'
import styled from 'styled-components'

export const Container = styled.div`
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  max-height: 89.4vh;
  margin-bottom: 1vh;
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar-track {
    margin: 80px 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin-right: 10px;
    min-width: 1034px;
  }
`

export const Title = styled(Body).attrs(() => ({
  type: 'copy1',
  align: 'center',
}))`
  margin: 34px 0 64px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin: 0px 0 49px;
  }
  position: relative;
  :after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.border.input};
    margin: 25px 0 24px;
    left: 0;
    top: 100%;
    margin-top: 16px;
  }
`

export const Subtitle = styled(Body).attrs(() => ({
  type: 'copy2',
}))`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    max-width: 817px;
    display: flex;
  }
`

export const PlanTypeContainer = styled.div`
  margin-bottom: 120px;
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: flex;
    margin-top: 64px;
    gap: 24px;
  }
`

export const SaveButton = styled(Button).attrs(() => ({}))`
  margin: 0 auto;
  margin-top: 108px;
`

export const PlanTypeTagHeader = styled.div`
  display: flex;
  gap: 16px;
`

export const FrequencyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 48px;
`

export const ButtonContainer = styled.div`
  width: calc(100% - 58px);
  backdrop-filter: blur(1.5px);
  position: absolute;
  bottom: 0px;
  padding: 0 0 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
