// Models
import { IGeneralState, IToastState } from 'storage/general/models'
import IStore from 'lib/redux/models'

// React
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
} from 'react'

// Libs
import { useDispatch, useSelector } from 'react-redux'

// Components
import { Toast } from 'heeds-ds'
import { cleanup, triggerToast } from 'storage/general/duck'

type ToastContext = {
  openToast: (toastData: IToastState) => void
}

const ToastContext = createContext<ToastContext | null>(null)

type Props = {
  children: ReactNode | ReactNode[]
}

const ToastProvider: FC<Props> = ({ children }) => {
  const { toast } = useSelector<IStore, IGeneralState>((state) => state.general)
  const dispatch = useDispatch()

  const openToast = useCallback(
    (toastData: IToastState) => {
      dispatch(triggerToast(toastData))
    },
    [dispatch],
  )

  useEffect(() => {
    if (toast?.type) {
      const unsubscribe = setTimeout(
        () => dispatch(cleanup()),
        toast.time || 5000,
      )

      return () => clearTimeout(unsubscribe)
    }
  }, [dispatch, toast])

  return (
    <ToastContext.Provider value={{ openToast }}>
      <Toast
        actionText={toast?.actionMessage}
        closeToast={() => dispatch(cleanup())}
        href={toast?.actionLink}
        show={!!toast?.type}
        text={toast?.message}
        variation={toast?.type}
        customTitle={toast?.customTitle}
      />
      {children}
    </ToastContext.Provider>
  )
}

const useToast = (): ToastContext => {
  const context = useContext(ToastContext)
  if (!context) {
    throw new Error('useToast should be used inside MenuProvider')
  }
  return context
}

export { ToastProvider, useToast }
