// React
import { FC, useContext, useLayoutEffect } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useMediaQuery, useMenu } from 'hooks'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { urls } from 'routes/paths'

// Components
import * as Styled from './styled'
import { LinkButton } from 'heeds-ds'

// Assets
import settings from 'assets/images/settings.svg'
import working from 'assets/images/working.svg'

type Props = {
  pagename: string
  setPagename?: (pagename: string) => void
}

const InDevelopment: FC<Props> = ({ pagename }) => {
  const { setPagename } = useMenu()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  useLayoutEffect(() => {
    if (pagename) {
      setPagename(pagename)
    }

    return () => setPagename('Dashboard')
  }, [pagename, setPagename])

  return (
    <Styled.Container backgroundImage={working}>
      <Styled.Content>
        <Styled.Settings src={settings} />
        <Styled.MainTitle>
          Essa funcionalidade está em construção!
        </Styled.MainTitle>
        <Styled.Subtext>
          Nosso time está trabalhando para que você possa ter a melhor
          experiência com o sistema.
          <br />
          <br />
          Aguarda só mais um pouquinho.
        </Styled.Subtext>
        <LinkButton
          margin={isDesktop ? '5.6rem 0 0' : '3.2rem 0 0'}
          size="small"
          to={urls.home}
          track={buttonClickTracking}
          trackName="navigate_to_home"
          variation="borderless"
        >
          VOLTAR PARA A PÁGINA INICIAL
        </LinkButton>
      </Styled.Content>
    </Styled.Container>
  )
}

export default InDevelopment
