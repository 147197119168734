// Libraries
import styled from 'styled-components'

type Props = {
  active?: boolean
}

export const Wrapper = styled.div<Props>`
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: all 0.1s ease;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  border-radius: inherit;
  background-color: rgba(43, 42, 99, 0.54);
  z-index: 11;
`
