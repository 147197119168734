// Libraries
import styled from 'styled-components'

// Components
import { Aligner, InputText, Select } from 'heeds-ds'

type Props = {
  span?: number
}

export const Input = styled(InputText).attrs((props) => ({
  displayError: props.displayError,
  margin: '0',
  scale: 'small',
}))``

export const SelectOption = styled(Select).attrs({
  margin: '0',
  scale: 'small',
})``

export const FieldContainer = styled(Aligner).attrs({
  align: 'flex-start',
  gap: '0.8rem',
})<Props>`
  grid-column-start: span ${({ span }) => span || 2};
`
