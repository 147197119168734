// React
import { FC } from 'react'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { useModal } from 'hooks'

// Components
import * as Styled from './styled'
import { Button } from 'heeds-ds'
import { Modal } from 'components/modals'
import { Subtitle, Title } from 'components/UI/Typography'

// Assets
import doneAll from 'assets/images/done-all.png'
import errorImage from 'assets/images/error.png'

type Props = {
  error?: boolean
}

const ModalAlert: FC<Props> = ({ error }) => {
  const { closeModal } = useModal()

  return (
    <Modal showExit={false}>
      {error ? (
        <Styled.Container data-testid="error">
          <Styled.Image src={errorImage} />
          <Title type="h2" margin="20px 0 16px 0">
            Algo deu errado
          </Title>
          <Subtitle type="sh3" margin="0 0px 28px 0px" align="center">
            Não foi possível salvar as alterações feitas.
          </Subtitle>
          <Button
            onClick={closeModal}
            size="small"
            track={buttonClickTracking}
            trackName="try_again_and_close_modal"
          >
            Tentar novamente
          </Button>
        </Styled.Container>
      ) : (
        <Styled.Container data-testid="success">
          <Styled.Image src={doneAll} />
          <Title type="h2" margin="20px 0 16px 0">
            Sucesso!
          </Title>
          <Subtitle type="sh3" margin="0 0 42px 0">
            Sua senha foi alterada
          </Subtitle>
          <Button
            onClick={closeModal}
            size="small"
            track={buttonClickTracking}
            trackName="continue_and_close_modal"
          >
            Continuar
          </Button>
        </Styled.Container>
      )}
    </Modal>
  )
}

export default ModalAlert
