// Models
import { IExerciseData, IExerciseState } from 'storage/exercise/models'
import { TMuscleGroupFilter } from 'models'
import IStore from 'lib/redux/models'

// React
import { FC } from 'react'

// Libraries
import { useSelector } from 'react-redux'

// Components
import * as Styled from './styled'
import { CommonList } from 'heeds-ds'
import { LoaderExerciseItem, Modal } from 'components'
import ExerciseSearchBar from 'blocks/dashboard/workout/ExerciseSearchBar'

type Props = {
  filters?: TMuscleGroupFilter[]
  inputValue: string
  loadMoreExercises: () => void
  onClickFilter: () => void
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  removeFilter: (label: string) => void
  renderExercise: (exercise: IExerciseData) => JSX.Element
}

const ModalExercises: FC<Props> = ({
  filters,
  inputValue,
  loadMoreExercises,
  onClickFilter,
  onSearchChange,
  removeFilter,
  renderExercise,
}) => {
  const { exercises } = useSelector<IStore, IExerciseState>(
    (state) => state.exercise,
  )

  return (
    <Modal>
      <Styled.ModalContainer data-testid="modal-exercices-container">
        <ExerciseSearchBar
          filters={filters}
          onClickFilter={onClickFilter}
          onSearchChange={onSearchChange}
          removeFilter={removeFilter}
          searchQuery={inputValue}
        />

        <CommonList<IExerciseData>
          columns={['']}
          data={exercises?.results || []}
          hasMore={!!exercises?.next}
          onEndReached={loadMoreExercises}
          renderItem={renderExercise}
          loaderComponent={<LoaderExerciseItem />}
          showHeader={false}
        />
      </Styled.ModalContainer>
    </Modal>
  )
}

export default ModalExercises
