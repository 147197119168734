// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body, Button } from 'heeds-ds'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 2.4rem 0;
    overflow-y: scroll;
  }
`

export const Container = styled(Aligner)`
  flex-direction: column;
  flex: 1;
  gap: 2.4rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 3.2rem 3.8rem 2.4rem 4rem;
  }
`

export const ContainerHeader = styled.div`
  align-items: center;
  display: flex;
  gap: 1.6rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 0 2.4rem;
  }
`

export const Header = styled(Aligner)`
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 0.8rem;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  column-gap: 1.6rem;
  flex-direction: column;
  padding: 1.6rem;
  row-gap: 0.8rem;
  z-index: 1;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    align-items: center;
    flex-direction: row;
  }
`

export const ModelNameLabel = styled(Body).attrs({
  type: 'copy4',
  weight: 600,
})`
  white-space: nowrap;
`

export const Content = styled(Aligner)`
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 0.8rem;
  box-shadow: 0px 4px 80px 0px #0000001a;
  flex-direction: column;
  flex: 1;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    border: 1px solid ${({ theme }) => theme.colors.border.input};
    box-shadow: none;
    max-height: calc(100vh - 16.6rem);
  }
`

export const ButtonContainer = styled.form`
  border-top: 1px solid ${({ theme }) => theme.colors.border.input};
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  padding: 1.6rem 4rem;
  position: sticky;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    background: ${({ theme }) => theme.colors.surface.default};
  }
`

export const CancelButton = styled(Button).attrs(() => ({
  cancel: true,
  margin: '0',
  variation: 'outlined',
}))``

export const NextButton = styled(Button).attrs(() => ({
  margin: '0',
}))``
