// Libraries
import { Skeleton } from 'heeds-ds'
import styled from 'styled-components'

export const LoadAvatar = styled(Skeleton)`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  flex-shrink: 0;
`

export const LoadContainer = styled.div`
  width: calc(100% - 4.8rem);
  background: ${({ theme }) => theme.colors.surface.default};
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 1.5rem;
  margin: 0px 2.4rem;
  padding: 1.6rem 2.4rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 0.5fr;
    gap: 0.2rem;
    margin: 0;
    padding: 16px 0;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    :not(:last-of-type) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.border.input};
    }
  }
`
