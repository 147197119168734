// React
import { FC } from 'react'

// Misc
import { buttonClickTracking } from 'utils/tracking'

// Components
import * as Styled from '../styled'
import { Button } from 'heeds-ds'

// Assets
import successImage from 'assets/images/done-all.png'

const NeedsConfirmation: FC = () => {
  const handleResendEmail = () => undefined

  return (
    <Styled.Container>
      <Styled.Image src={successImage} />

      <Styled.TitleS>Time Cadu crescendo!</Styled.TitleS>

      <Styled.SubtitleS>
        Falta só mais um passo. Confira seu email para ativação da conta.
      </Styled.SubtitleS>

      <Styled.SmallText>Não esqueça de olhar o spam!</Styled.SmallText>

      <Button
        onClick={handleResendEmail}
        size="small"
        track={buttonClickTracking}
        trackName="resend_confirmation_email"
      >
        Reenviar e-mail
      </Button>
    </Styled.Container>
  )
}

export default NeedsConfirmation
