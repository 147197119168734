// Libraries
import * as yup from 'yup'

// Libraries
import moment from 'moment'

// Misc
import { testIsAValidTime } from './@utils'

const _requiredAppointmentTimeSchema = yup.object().shape({
  id: yup.number().nullable(),
  day: yup.string(),
  start: yup.string().when('day', {
    is: (value?: string) => value && value !== '',
    then: (schema) => schema.required('Horário de início obrigatório'),
    otherwise: (schema) => schema,
  }),
  end: yup
    .string()
    .when('day', {
      is: (value?: string) => value && value !== '',
      then: (schema) => schema.required('Horário de término obrigatório'),
      otherwise: (schema) => schema,
    })
    .test(
      'hour',
      'A hora final deve ser maior que a inicial',
      testIsAValidTime,
    ),
})

const _optionalAppointmentTimeSchema = yup
  .object()
  .shape({
    id: yup.number().nullable(),
    day: yup.string(),
    start: yup.string(),
    end: yup.string(),
  })
  .nullable()

const _appointmentTimeBasedOnToogle = yup.object().when('toggle', {
  is: true,
  then: _optionalAppointmentTimeSchema,
  otherwise: _requiredAppointmentTimeSchema,
})

const _requiredAllDaysSchema = yup.object().shape({
  id: yup.number(),
  days: yup.array(yup.string()),
  start: yup.string().required('Horário de início obrigatório'),
  end: yup
    .string()
    .required('Horário de término obrigatório')
    .test(
      'hour',
      'A hora final deve ser maior que a inicial',
      testIsAValidTime,
    ),
})

const _optionalAllDaysSchema = yup.object().shape({
  id: yup.number(),
  days: yup.array(yup.string()),
  start: yup.string(),
  end: yup.string(),
})

const _allDaysTimeBasedOnToogle = yup.object().when('toggle', {
  is: true,
  then: _requiredAllDaysSchema,
  otherwise: _optionalAllDaysSchema,
})

export const commonScheduleSchema = {
  sun: _appointmentTimeBasedOnToogle,
  mon: _appointmentTimeBasedOnToogle,
  tue: _appointmentTimeBasedOnToogle,
  wed: _appointmentTimeBasedOnToogle,
  thu: _appointmentTimeBasedOnToogle,
  fri: _appointmentTimeBasedOnToogle,
  sat: _appointmentTimeBasedOnToogle,
  allDays: _allDaysTimeBasedOnToogle,
  toggle: yup.boolean(),
}

export const dateValidationSchema = yup
  .date()
  .transform((_, value) => {
    if (!value) return null

    const invalidDate =
      typeof value === 'string'
        ? value.replace(/[^a-zA-Z0-9]/g, '').length < 8
        : false

    return invalidDate
      ? yup.date.INVALID_DATE
      : moment(value, 'DD/MM/YYYY').toDate()
  })
  .typeError('Digite uma data válida')
