// Models
import { IAction } from 'lib/redux/models'
import { IAthleteOverview } from 'services/athleteOverview/@types'

// Libraries
import { SagaIterator } from 'redux-saga'
import { all, takeLatest } from 'redux-saga/effects'
import { call, put } from 'redux-saga/effects'

// Misc
import { Types, failure, fulfill, request, success } from '../duck'
import { getAthleteOverview } from 'services/athleteOverview'
import { triggerToastWarning } from 'storage/general/duck'
import { triggerLoadLatestWorkoutRoutine } from 'storage/workoutRoutine/duck'

export function* getAthleteOverviewSaga(action: IAction<number>) {
  yield put(request())
  try {
    if (action.payload) {
      const id = action.payload
      const response: IAthleteOverview = yield call(getAthleteOverview, id)
      yield put(triggerLoadLatestWorkoutRoutine({ athlete_pk: id }))

      yield put(success({ overview: response }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))

    if (err.message === '404') {
      yield put(triggerToastWarning({ customTitle: 'ID do atleta inválido' }))
    }
  } finally {
    yield put(fulfill())
  }
}
export default function* athleteSaga(): SagaIterator {
  yield all([takeLatest(Types.GET_OVERVIEW, getAthleteOverviewSaga)])
}
