// Models
import { EPlanModelToPortuguese } from 'models'
import { IAthleteInfoState } from 'storage/athleteInfo/models'
import { IAthletePlansState } from 'storage/athletePlans/models'
import { IWorkoutRoutineState } from 'storage/workoutRoutine/models'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useLayoutEffect } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

// Misc
import { getGoalIcon } from 'utils/functions'
import { intervalDateToString } from 'utils/helpers/date'
import { prepareWorkoutRoutineToDisplay } from 'filters/workouts'
import { cleanup, triggerLoadAthlete } from 'storage/athleteInfo/duck'
import { triggerLoadAthletePlans } from 'storage/athletePlans/duck'
import { useModal } from 'hooks'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import * as Styled from './styled'
import {
  Aligner,
  Avatar,
  Body,
  Icon,
  SimpleContainer,
  StylizedIcons,
} from 'heeds-ds'
import { ModalRoutineInfoMobile } from 'components/modals'

type Props = {
  compact?: boolean
  disabled?: boolean
}

const HeaderWorkoutRoutine: FC<Props> = ({ compact, disabled }) => {
  const { id = '' } = useParams()
  const {
    info,
    error: errorAthleteInfo,
    loading: loadingAthleteInfo,
  } = useSelector<IStore, IAthleteInfoState>((state) => state.athleteInfo)
  const { plans, loading: loadingPlans } = useSelector<
    IStore,
    IAthletePlansState
  >((state) => state.athletePlans)
  const { workoutRoutine } = useSelector<IStore, IWorkoutRoutineState>(
    (state) => state.workoutRoutine,
  )
  const { openModal, isVisible } = useModal()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const routine = workoutRoutine
    ? prepareWorkoutRoutineToDisplay(workoutRoutine)
    : undefined
  const hasDescription = workoutRoutine !== undefined
  const isCompact = compact || hasDescription

  useLayoutEffect(() => {
    if (
      id &&
      !loadingAthleteInfo &&
      !errorAthleteInfo &&
      (!info || info?.id !== Number(id))
    ) {
      const athlete_pk = Number(id)

      !loadingPlans && dispatch(triggerLoadAthlete({ id: athlete_pk }))
      !loadingAthleteInfo &&
        dispatch(triggerLoadAthletePlans({ id: athlete_pk }))
    }
    if (!loadingAthleteInfo && errorAthleteInfo === '404') {
      dispatch(cleanup())
      navigate(-1)
    }
  }, [
    dispatch,
    errorAthleteInfo,
    id,
    info,
    loadingAthleteInfo,
    loadingPlans,
    navigate,
  ])

  return (
    <Styled.Container
      compact={isCompact}
      data-testid="header-workout-routine-container"
      space={hasDescription}
    >
      <Styled.InfoContainer compact={isCompact}>
        {disabled && <Styled.DisabledBackground />}
        <Aligner align="center">
          <Avatar
            name={info?.name ?? ''}
            scale={!isDesktop || isCompact ? 'small' : 'medium'}
          />

          <Styled.InfoText compact={isCompact}>
            <Styled.Title compact={isCompact}>{info?.name || ''}</Styled.Title>

            <Body type="copy4" color={theme.colors.text.subdued}>
              {(plans?.[0]?.plan?.plan_model &&
                EPlanModelToPortuguese[plans?.[0]?.plan.plan_model]) ||
                ''}
            </Body>
          </Styled.InfoText>
        </Aligner>

        {hasDescription && !isDesktop && (
          <SimpleContainer
            data-testid="header-workout-routine-info-button"
            onClick={() => openModal('modal-exercise-info')}
          >
            <Icon iconName="info" color={theme.colors.icon.default} size={24} />
          </SimpleContainer>
        )}
      </Styled.InfoContainer>

      {workoutRoutine && isDesktop && (
        <Styled.InfoContainer compact={isCompact}>
          {disabled && <Styled.DisabledBackground />}
          <Aligner>
            <StylizedIcons iconName="genericWorkout" />

            <Styled.InfoText compact={isCompact}>
              <Styled.Title compact={isCompact}>{routine?.name}</Styled.Title>

              <Body type="copy4" color={theme.colors.text.subdued}>
                {intervalDateToString(
                  workoutRoutine?.start_date,
                  workoutRoutine?.end_date,
                )}
              </Body>
            </Styled.InfoText>
          </Aligner>

          <Aligner>
            <StylizedIcons iconName={getGoalIcon(routine?.goal)} />

            <Styled.InfoText compact={isCompact}>
              <Styled.Title compact={isCompact}>{routine?.goal}</Styled.Title>

              <Body type="copy4" color={theme.colors.text.subdued}>
                {routine?.difficulty}
              </Body>
            </Styled.InfoText>
          </Aligner>
        </Styled.InfoContainer>
      )}

      {isVisible === 'modal-exercise-info' && (
        <ModalRoutineInfoMobile workoutRoutine={workoutRoutine} />
      )}
    </Styled.Container>
  )
}

export default HeaderWorkoutRoutine
