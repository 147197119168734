// Libraries
import styled from 'styled-components'

type Props = {
  active?: boolean
  distance?: string
  margin?: string
  padding?: string
}

export const Container = styled.div<Props>`
  width: 100%;
  overflow-x: auto;
  margin: ${({ margin }) => margin || '0 0 2.4rem'};
  padding: ${({ padding }) => padding || '0 0 0.8rem'};
  display: flex;
  gap: 3.2rem;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const TabItem = styled.div<Props>`
  cursor: pointer;
  position: relative;
  min-width: 16rem;
  padding-bottom: ${({ distance }) => distance || '22px'};
  transition: all ease-in-out 0.2s;
  ::after {
    content: '';
    background-color: ${({ active, theme }) =>
      active
        ? theme.colors.action.secondaryDefault
        : theme.colors.background.selected};
    width: 100%;
    height: 5px;
    border-radius: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all ease-in-out 0.2s;
  }
`
