// React
import { FC, useContext, useRef } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { IconsType } from '../UI/Icons/values'

// Components
import {
  Action,
  ActionsContainer,
  ChildrenContainer,
  Container,
} from './styled'
import Icon from '../UI/Icons'

export type Action = {
  icon?: IconsType
  color?: string
  iconSize?: number
  onClick: () => void
}

type Props = {
  actions?: Action[]
  active?: boolean
  children?: React.ReactNode
  initial?: boolean
  margin?: string
  maxHeight?: string
  padding?: string
  shadow?: boolean
}

const Swipe: FC<Props> = ({
  actions,
  active,
  children,
  initial,
  margin,
  maxHeight,
  padding,
  shadow,
}) => {
  const refName = useRef<HTMLDivElement>(null)
  const theme = useContext(ThemeContext)

  const resetScrollPosition = () => {
    refName.current?.scrollTo?.({
      left: 0,
      behavior: 'smooth',
    })
  }

  const setFocus = () => {
    refName.current?.focus()
  }

  return (
    <Container
      active={active}
      data-testid="swipe"
      initial={initial}
      margin={margin}
      paddingSize={padding}
      onBlur={resetScrollPosition}
      onMouseLeave={resetScrollPosition}
      onTouchStart={setFocus}
      ref={refName}
      shadow={shadow}
      tabIndex={-1}
    >
      <ChildrenContainer>{children}</ChildrenContainer>
      {actions && actions.length > 0 && (
        <ActionsContainer
          active={active}
          data-testid="actions-container"
          maxHeight={maxHeight}
        >
          {actions.map((item, index) => {
            const { color, icon, iconSize, onClick } = item
            return (
              <Action
                actionColor={color}
                active={active}
                data-testid={icon}
                key={(icon || 'moreVertical') + index}
                onClick={() => {
                  onClick()
                  resetScrollPosition()
                }}
              >
                <Icon
                  color={theme.colors.icon.onPrimary}
                  iconName={icon || 'moreVertical'}
                  size={iconSize || 24}
                />
              </Action>
            )
          })}
        </ActionsContainer>
      )}
    </Container>
  )
}

export default Swipe
