/* eslint-disable no-case-declarations */
// Models
import {
  EFinancialSubscriptionActionTypes,
  IFinancialSubscriptionState,
} from '../models'
import { IAction } from 'lib/redux/models'
import { ISubscriptionDeactivatePayload } from 'services/financialSubscription/@types'

// ACTION TYPES
export const Types = {
  CLEANUP: EFinancialSubscriptionActionTypes.CLEANUP,
  FAILURE: EFinancialSubscriptionActionTypes.FAILURE,
  FULFILL: EFinancialSubscriptionActionTypes.FULFILL,
  REQUEST: EFinancialSubscriptionActionTypes.REQUEST,
  SUCCESS: EFinancialSubscriptionActionTypes.SUCCESS,
  SUBSCRIPTION_ACTIVATE:
    EFinancialSubscriptionActionTypes.SUBSCRIPTION_ACTIVATE,
  SUBSCRIPTION_DEACTIVATE:
    EFinancialSubscriptionActionTypes.SUBSCRIPTION_DEACTIVATE,
}

// INITIAL STATE
const initialState: IFinancialSubscriptionState = {}

// REDUCER
export default (
  state: IFinancialSubscriptionState = initialState,
  action?: IAction<unknown>,
): IFinancialSubscriptionState => {
  switch (action?.type) {
    case Types.CLEANUP:
      return {
        ...state,
        error: undefined,
        loading: undefined,
        refreshing: undefined,
        subscription: undefined,
      }
    case Types.FAILURE:
      return {
        ...state,
        error: action.payload as string,
      }
    case Types.FULFILL:
      return {
        ...state,
        loading: false,
      }
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.SUCCESS:
      return {
        ...state,
        ...(action?.payload as IFinancialSubscriptionState),
      }
    default:
      return state
  }
}

// ACTIONS
export const cleanup = (): IAction<undefined> => {
  return {
    type: Types.CLEANUP,
  }
}

export const failure = (payload: string): IAction<string> => {
  return {
    type: Types.FAILURE,
    payload,
  }
}

export const fulfill = (): IAction<unknown> => {
  return {
    type: Types.FULFILL,
  }
}

export const request = (): IAction<unknown> => {
  return {
    type: Types.REQUEST,
  }
}

export const success = (
  payload: IFinancialSubscriptionState,
): IAction<IFinancialSubscriptionState> => {
  return {
    type: Types.SUCCESS,
    payload,
  }
}

// CUSTOM ACTIONS
export const triggerDeactivateSubscription = (
  payload: ISubscriptionDeactivatePayload,
): IAction<ISubscriptionDeactivatePayload> => {
  return {
    type: Types.SUBSCRIPTION_DEACTIVATE,
    payload,
  }
}

export const triggerActivateSubscription = (
  payload: ISubscriptionDeactivatePayload,
): IAction<ISubscriptionDeactivatePayload> => {
  return {
    type: Types.SUBSCRIPTION_ACTIVATE,
    payload,
  }
}

export const actions = {
  cleanup,
  failure,
  fulfill,
  request,
  success,
  triggerDeactivateSubscription,
  triggerActivateSubscription,
}
