// Models
import { TAthleteCalendar } from 'models'

// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { groupBy } from 'lodash'

// Misc
import {
  addLeftZero,
  getMonthName,
  selectedWeekdayName,
} from 'utils/calendarUtils'
import { buttonClickTracking } from 'utils/tracking'

// Components
import { Aligner, Avatar, Body, IconButton, Subtitle } from 'heeds-ds'
import * as Styled from './styled'

type Props = {
  week: number
  isWeek?: boolean
  show: boolean
  athleteList: TAthleteCalendar[]
  selectedDate: number[]
  onClick?: () => void
  mobile?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  athleteListWeekFormat?: any[]
}

const TimesCard: FC<Props> = ({
  week,
  show,
  athleteList,
  selectedDate,
  onClick,
  mobile,
  athleteListWeekFormat,
  isWeek,
}) => {
  const theme = useContext(ThemeContext)
  const date = new Date()
  const selected = new Date(
    `${selectedDate[2]}-${selectedDate[1]}-${selectedDate[0]}`,
  )

  const athleteListFiltered = athleteList.filter((a) => {
    return (
      a.recurringTimes.includes(week) &&
      selected <= new Date(a.dueDate) &&
      selected >= new Date(a.initialDate)
    )
  })

  const athletesWithSameTimeFiltered = athleteListFiltered.filter(
    (el, index) => {
      return (
        athleteListFiltered[index]?.start ===
          athleteListFiltered[index + 1]?.start ||
        athleteListFiltered[index]?.start ===
          athleteListFiltered[index - 1]?.start
      )
    },
  )

  const splitSameAndDifferentTimeAthletes = (
    array1: TAthleteCalendar[],
    array2: TAthleteCalendar[],
  ) => {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return object1.id === object2.id
      })
    })
  }

  const splitTime = groupBy(athletesWithSameTimeFiltered, 'start')

  const handleAthletesWithSameTimes = () => {
    return Object.values(splitTime).map((times) => {
      const endTime = times.sort((a, b) => {
        return (
          parseFloat(b.end.replace('h', '.')) -
          parseFloat(a.end.replace('h', '.'))
        )
      })
      const location = times
        .map((location) => (location.new ? location.location : null))
        .filter((location) => {
          return location !== null
        })
        .toString()
      const list = {
        athletePk: times[0]?.athletePk,
        id: times.reduce((accumulator, name) => {
          if (name.new) {
            accumulator += name.id || 0
          }
          return accumulator
        }, 0),
        athlete: times
          .sort((a, b) => (a.new === b.new ? 0 : a.new ? -1 : 1))
          .map((item) => item.athlete),
        start: times[0]?.start,
        end: endTime[0]?.end,
        location: location ?? times[0]?.location,
        new: times
          .filter((item) => {
            return item.new === true
          })
          .map((item) => item.new)[0]
          ? true
          : false,
      }
      return list
    })
  }

  const handleAthletesWithDifferentTimes = splitSameAndDifferentTimeAthletes(
    athleteListFiltered,
    athletesWithSameTimeFiltered,
  ).map((item) => {
    const { athletePk, id, athlete, start, end, location } = item
    return {
      athletePk: athletePk,
      id: id,
      athlete: [athlete],
      start: start,
      end: end,
      location: location,
      new: item.new,
    }
  })

  const sameAndDifferentTimesAthletesList = [
    ...handleAthletesWithDifferentTimes,
    ...handleAthletesWithSameTimes(),
  ].sort((a, b) =>
    a.start && b.start
      ? parseFloat(a.start.replace('h', '.')) -
        parseFloat(b.start.replace('h', '.'))
      : 0,
  )

  return (
    <Styled.CardTimes distance={week} show={show} mobile={mobile}>
      {!mobile && (
        <Styled.CardHeader>
          <Body type="copy1" color={theme.colors.text.secondary}>
            {addLeftZero(selectedDate[0], 2) +
              ' de ' +
              getMonthName(selectedDate[1] - 1)[0].toUpperCase() +
              getMonthName(selectedDate[1] - 1).substring(1) +
              ' de ' +
              selectedDate[2]}
          </Body>
          <IconButton
            data-testid="closeTimeCard"
            iconName="close"
            onClick={onClick}
            size="small"
            track={buttonClickTracking}
            trackName="close_time_card"
          />
        </Styled.CardHeader>
      )}
      <Styled.AthleteListContainer>
        {isWeek
          ? athleteListWeekFormat?.map((item, indexTimes) => {
              const selectedWeek = selectedWeekdayName(
                item[0].weekDay === 7 ? 1 : item[0].weekDay,
              )
              return (
                <Styled.AthleteListContainerWeek key={indexTimes}>
                  <Styled.SelectedDayTitles>
                    <Styled.SelectedDay>
                      <Subtitle type="sh1" weight={800}>
                        {addLeftZero(item[0].day, 2)}
                      </Subtitle>
                      <Body type="copy4">
                        {selectedWeek?.toString().toLocaleUpperCase()[0]}
                        {selectedWeek?.slice(1, 3).replace('.', '')}
                      </Body>
                    </Styled.SelectedDay>
                    <Styled.TimesDisplay>
                      <Body
                        type="copy4"
                        weight={500}
                        color={theme.colors.text.default}
                      >
                        {item[0].day === date.getDate() ? 'Hoje' : ''}
                      </Body>
                      <Body type="copy4" weight={400} color={'#A4A3C1'}>
                        {item[0].athletes.length} horários agendados
                      </Body>
                    </Styled.TimesDisplay>
                  </Styled.SelectedDayTitles>
                  {item[0].athletes.map(
                    (itemAthletes: TAthleteCalendar, indexAthletes: number) => {
                      const sameTimeAsBefore =
                        item[0].athletes[indexAthletes]?.start ===
                        item[0].athletes[indexAthletes - 1]?.start
                      return (
                        <Styled.DayCardWrapper key={indexAthletes}>
                          {!sameTimeAsBefore ? (
                            <Styled.TimeLine>
                              <Body type="copy4" margin="0 0 16px 0">
                                {itemAthletes.start}
                              </Body>
                            </Styled.TimeLine>
                          ) : null}
                          <Styled.DayCardWeek
                            saved={itemAthletes.new}
                            borderRadius={'0 15px 15px 0'}
                          >
                            <Styled.DayCardTop>
                              <Avatar
                                scale="xXSmall"
                                name={itemAthletes.athlete.toString()}
                                margin="16px 4px 0 0"
                              />
                              <Body
                                type="copy1"
                                color={theme.colors.text.secondary}
                                weight={600}
                                margin={'16px 0 0 4px'}
                              >
                                {itemAthletes.athlete}
                              </Body>
                            </Styled.DayCardTop>
                            <Body
                              type="copy2"
                              color={theme.colors.text.secondary}
                              margin="11px 0 4px 0"
                            >
                              {mobile && itemAthletes.day + ','}
                              {itemAthletes.start} - {itemAthletes.end}
                            </Body>
                            <Body
                              type="copy2"
                              color={theme.colors.text.secondary}
                            >
                              {itemAthletes.location}
                            </Body>
                          </Styled.DayCardWeek>
                        </Styled.DayCardWrapper>
                      )
                    },
                  )}
                </Styled.AthleteListContainerWeek>
              )
            })
          : sameAndDifferentTimesAthletesList?.map((item) => {
              return (
                item?.id !== undefined && (
                  <Styled.DayCardWrapper key={item.id}>
                    <Styled.DayCard saved={item.new}>
                      <Styled.DayCardTop>
                        {item.athlete.length > 1 ? (
                          <Aligner>
                            <Aligner
                              flex="column"
                              margin="0px 0 0 0 "
                              gap="6px"
                            >
                              {item.athlete.map((names, index) => {
                                return (
                                  <Aligner
                                    key={index}
                                    margin="16px 0 0 0"
                                    justify="center"
                                    align="center"
                                  >
                                    <Body
                                      type="copy1"
                                      color={theme.colors.text.secondary}
                                      weight={600}
                                      margin="0 auto 0 0"
                                    >
                                      {names}
                                    </Body>
                                  </Aligner>
                                )
                              })}
                            </Aligner>
                          </Aligner>
                        ) : (
                          <Aligner
                            margin="16px 0 0 0"
                            justify="center"
                            align="center"
                          >
                            <Body
                              type="copy1"
                              color={theme.colors.text.secondary}
                              weight={600}
                              margin="0 auto 0 0"
                            >
                              {item.athlete}
                            </Body>
                          </Aligner>
                        )}
                      </Styled.DayCardTop>
                      <Body
                        type="copy2"
                        color={theme.colors.text.secondary}
                        margin="11px 0 4px 0"
                      >
                        {item.start} - {item.end}
                      </Body>
                      <Body type="copy2" color={theme.colors.text.secondary}>
                        {item.location}
                      </Body>
                    </Styled.DayCard>
                  </Styled.DayCardWrapper>
                )
              )
            })}
        <Styled.GradientBottom />
      </Styled.AthleteListContainer>
    </Styled.CardTimes>
  )
}

export default TimesCard
