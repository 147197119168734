// Models
import { IAction } from 'lib/redux/models'
import {
  IAthleteSchedules,
  IGetSchedulesPayload,
  IPostSchedulesFormattedPayload,
  ISchedule,
} from '../models'
import { SagaIterator } from '@redux-saga/types'

// Libraries
import { all, call, put, takeLatest } from 'redux-saga/effects'

// Misc
import { failure, fulfill, request, success } from '../duck'
import { triggerToastError, triggerToastSuccess } from 'storage/general/duck'
import {
  listAthleteSchedules,
  listPersonalSchedules,
  postAthleteSchedules,
} from 'services/athleteSchedule'
import { Types } from 'storage/athleteSchedule/duck'
import { IAthleteSchedule } from 'services/athleteSchedule/@types'

export function* getAthleteSchedulesSaga(
  action: IAction<IGetSchedulesPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { athlete, callback } = action.payload

      const response: IAthleteSchedules = yield call(
        listAthleteSchedules,
        athlete,
      )
      // Getting personal schedules to handle schedule conflicts
      const personalSchedules: IAthleteSchedule[] = yield call(
        listPersonalSchedules,
      )
      const filteredPersonalSchedules = personalSchedules.filter(
        (schedule) => schedule.athlete !== athlete,
      )

      if (response) {
        yield put(
          success({
            ...response,
            otherAthletesSchedules: filteredPersonalSchedules,
          }),
        )
        yield callback(response)
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* postAthleteSchedulesSaga(
  action: IAction<IPostSchedulesFormattedPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: ISchedule[] = yield call(
        postAthleteSchedules,
        action.payload,
      )

      if (response) {
        yield put(success({ schedules: response }))
        yield put(
          triggerToastSuccess({
            message: 'Seus horários foram adicionados ao calendário.',
          }),
        )
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
    yield put(triggerToastError({ message: err.message }))
  } finally {
    yield put(fulfill())
  }
}

export default function* scheduleSaga(): SagaIterator {
  yield all([
    takeLatest(Types.GET_ATHLETE_SCHEDULES, getAthleteSchedulesSaga),
    takeLatest(Types.POST_ATHLETE_SCHEDULES, postAthleteSchedulesSaga),
  ])
}
