// React
import { FC } from 'react'

// Components
import * as Styled from './styled'
import { Aligner } from 'heeds-ds'

// Assets
import EmptyFolder from 'assets/images/folder-2.svg'
import FullFolder from 'assets/images/folder-1.svg'

interface Props {
  files: number
  last_deposit: string
  onClick?: () => void
  title: string
  width?: string
}

const AssessmentCard: FC<Props> = ({
  files,
  last_deposit,
  onClick,
  title,
  width,
}) => {
  return (
    <Styled.Container onClick={onClick} width={width}>
      <Aligner gap="1.6rem">
        <Styled.FolderImage src={files ? FullFolder : EmptyFolder} />

        <Aligner flex="column" gap="0.8rem">
          <Styled.TitleText>{title}</Styled.TitleText>

          <Styled.DateText>
            {last_deposit ? `Último adicionado: ${last_deposit}` : ''}
          </Styled.DateText>
        </Aligner>
      </Aligner>

      <Styled.FilesText>
        {files} Arquivo{files === 1 ? '' : 's'}
      </Styled.FilesText>
    </Styled.Container>
  )
}

export default AssessmentCard
