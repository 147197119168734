// Models
import { IAuthState, IForgotPasswordPayload } from 'storage/auth/models'
import { TOutletContext } from 'layouts/AuthLayout'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useLayoutEffect } from 'react'

// Libraries
import { FormProvider } from 'react-hook-form'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useOutletContext } from 'react-router-dom'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { authOnlyEmailSchema } from 'schemas'
import { requestForgotPassword } from 'storage/auth/duck'
import { urls } from 'routes/paths'
import useGlobalForms from 'hooks/useGlobalForms'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import * as Styled from './styled'
import { Aligner, Button, FormButton, InputText, Loading } from 'heeds-ds'

// Assets
import backgroundImage from 'assets/images/signup-confirmation-background.svg'

export interface FormInputs {
  email: string
}

const ForgotPassword: FC = () => {
  const { loading } = useSelector<IStore, IAuthState>((state) => state.auth)
  const { setLayoutBackground, setBackgroundWidth } =
    useOutletContext<TOutletContext>()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const formMethods = useGlobalForms<FormInputs>({
    schema: authOnlyEmailSchema,
  })
  const {
    formState: { isValid },
    handleSubmit,
  } = formMethods

  const onSubmit = (data: FormInputs) => {
    const to = urls.forgotPasswordSuccess
    const payload: IForgotPasswordPayload = {
      email: data?.email,
      redirection: { navigate, to },
    }
    dispatch(requestForgotPassword(payload))
  }

  useLayoutEffect(() => {
    setLayoutBackground(backgroundImage)
    setBackgroundWidth('50rem')
  }, [setBackgroundWidth, setLayoutBackground])

  return (
    <>
      <Loading active={loading} />
      <Aligner flex="column" align="center" justify="center">
        <Styled.MainTitle>Esqueceu sua senha?</Styled.MainTitle>
        <Aligner
          width={isDesktop ? 'auto' : '310px'}
          align="center"
          justify="center"
        >
          <Styled.SubText>
            Digite o e-mail do seu cadastro <br /> para receber o link para
            redefinir a senha.
          </Styled.SubText>
        </Aligner>
      </Aligner>
      <FormProvider {...formMethods}>
        <Styled.Form onSubmit={handleSubmit(onSubmit)}>
          <InputText
            type="email"
            margin="0"
            name="email"
            label="Email"
            placeholder="Digite seu email"
            scale="small"
          />
          <FormButton
            disabled={!isValid}
            margin={
              isDesktop ? '5.6rem auto 3.2rem auto' : '7.2rem auto 4rem auto'
            }
            size="medium"
            track={buttonClickTracking}
            trackName="send_forgot_password_email"
            width={'100%'}
          >
            Enviar e-mail
          </FormButton>
        </Styled.Form>
      </FormProvider>
      <Button
        margin={isDesktop ? '0 auto 1rem auto' : '0 auto 6.5rem auto'}
        onClick={() => navigate(urls.login)}
        size="small"
        track={buttonClickTracking}
        trackName="navigate_to_login"
        variation="borderless"
        width="100%"
      >
        Entrar na minha conta
      </Button>
    </>
  )
}

export default ForgotPassword
