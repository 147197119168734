// Libraries
import styled, { css } from 'styled-components'

interface Props {
  width?: string
}

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: ${theme.breakpoints.desktopSmall}px) {
      align-items: center;
      justify-content: normal;
      padding: 3.5rem 4rem 0 4rem;
    }
  `}
`

export const Header = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.6rem 0.4rem 0.4rem;

    @media screen and (min-width: ${theme.breakpoints.desktopSmall}px) {
      padding: 0;
    }
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.desktopSmall}px) {
      max-width: 110rem;
      flex: 1;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
      gap: 10.4rem;
    }
  `}
`

export const LayoutImage = styled.img<Props>`
  ${({ theme, width }) => css`
    width: 100%;
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: -10;
    opacity: 0.4;
    margin-top: 6.4rem;

    @media screen and (min-width: ${theme.breakpoints.desktopSmall}px) {
      position: static;
      width: ${width || '50rem'};
      opacity: 1;
      z-index: 0;
      margin-top: 0;
    }
  `}
`

export const FormContainer = styled.div`
  ${({ theme }) => css`
    border-radius: 2.4rem 2.4rem 0 0;
    background-color: ${theme.colors.surface.default};
    padding: 4rem 2.4rem;
    box-shadow: 0px 4px 80px 0px rgba(0, 0, 0, 0.1);
    overflow-y: scroll;

    @media screen and (min-width: ${theme.breakpoints.desktopSmall}px) {
      overflow-y: hidden;
      border-radius: 0;
      flex: 1;
      max-width: 50rem;
      padding: 0;
      background-color: transparent;
      box-shadow: none;
    }
  `}
`
