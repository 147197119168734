//Models
import { TAthleteCalendar } from 'models'

// React
import { FC, useEffect, useState, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch } from 'react-redux'

// Misc
import { GenerateMonthDisplayCalendar } from 'utils/calendarUtils'
import { buttonClickTracking } from 'utils/tracking'
import { useModal } from 'hooks'
import useMediaQuery from 'heeds-ds/src/hooks/useMediaQuery'

// Components
import * as Styled from './styled'
import { Button, Icon } from 'heeds-ds'
import { Modal } from 'components/modals'
import MobileSchedule from './MobileSchedule'
import MonthView from './MonthView'
import WeekView from './WeekView'
import { getAllAthletesTimeSchedule } from 'storage/profile/duck'

type calendarType = {
  id: number
  isToday: boolean
  currentWeek: number
  day: number
  month: number
  weekDay: number
  year: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allAthletesList: any[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sameTimeList: any[]
}

type Props = {
  callback: (selected: string) => void
  startDate: string
  finishDate?: string
  savedAthletes: TAthleteCalendar[]
  children?: React.ReactNode | React.ReactNode[]
  loading?: boolean
  onlyButton?: boolean
  margin?: string
}

// TODO: refatorar totalmente esse componente: mal uso de typescript, codigo baguncado, duplicando requisicoes nas paginas com 'getAllAthletesTimeSchedule'
const Schedule: FC<Props> = ({
  callback,
  savedAthletes,
  startDate,
  children,
  loading,
  onlyButton,
  margin,
}) => {
  const date = new Date()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)
  const dispatch = useDispatch()
  const { openModal, isVisible } = useModal()

  const [initialDate, setInitialDate] = useState(
    `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
  )
  const [displayMode, setDisplayMode] = useState('month')
  const splitStartDate = initialDate.split('-')

  useEffect(() => {
    const sendBackDate = () => {
      callback && callback(initialDate)
    }
    sendBackDate()
  }, [initialDate, callback])

  const CalendarList = GenerateMonthDisplayCalendar(
    parseInt(splitStartDate[1]),
    parseInt(splitStartDate[0]),
  )

  const handleAllAthletes = (calendarDate: Date, week: number) => {
    const allAthletes = savedAthletes.sort((a, b) =>
      a.start && b.start
        ? parseFloat(a.start.replace('h', '.')) -
          parseFloat(b.start.replace('h', '.'))
        : 0,
    )
    const allAthletesList = allAthletes
      .map((itemSavedAthletes) => {
        const athleteDate = itemSavedAthletes.date
          ? new Date(itemSavedAthletes.date)
          : new Date()
        const athleteDueDate = itemSavedAthletes.dueDate
          ? new Date(itemSavedAthletes.dueDate)
          : new Date()
        const athleteList =
          athleteDate <= calendarDate &&
          calendarDate <= athleteDueDate &&
          itemSavedAthletes.recurringTimes.includes(week)
            ? {
                athlete: itemSavedAthletes.athlete,
                id: itemSavedAthletes.id || '',
                start: itemSavedAthletes.start,
                startCompare: parseFloat(
                  itemSavedAthletes.start.replace('h', '.'),
                ),
                end: itemSavedAthletes.end,
                endCompare: parseFloat(itemSavedAthletes.end.replace('h', '.')),
                initialDate: itemSavedAthletes.date,
                location: itemSavedAthletes.location,
                day: itemSavedAthletes.day,
                recurringTimes: itemSavedAthletes.recurringTimes,
                date: '',
                new: itemSavedAthletes.new,
              }
            : { athlete: '' }

        return athleteList
      })
      .filter(function (value) {
        return value.athlete !== ''
      })
      .sort((a, b) =>
        a.startCompare && b.startCompare ? a.startCompare - b.startCompare : 0,
      )
    return allAthletesList
  }

  const handleSameTimeAthletes = (calendarDate: Date, weekDay: number) => {
    const allAthletes = savedAthletes.sort((a, b) =>
      a.start && b.start
        ? parseFloat(a.start.replace('h', '.')) -
          parseFloat(b.start.replace('h', '.'))
        : 0,
    )
    const sameTimeList = allAthletes.map((item) => {
      return allAthletes
        .map((itemInner) => {
          const athleteDate = itemInner.date
            ? new Date(itemInner.date)
            : new Date()
          const athleteDueDate = itemInner.dueDate
            ? new Date(itemInner.dueDate)
            : new Date()
          return item.start === itemInner.start &&
            athleteDate <= calendarDate &&
            calendarDate <= athleteDueDate &&
            itemInner.recurringTimes.includes(weekDay)
            ? {
                athlete: itemInner.athlete,
                start: itemInner.start,
                end: itemInner.end,
                recurringTimes: itemInner.recurringTimes,
                initialDate: itemInner.date,
                startCompare: parseFloat(itemInner.start?.replace('h', '.')),
                endCompare: parseFloat(itemInner.end?.replace('h', '.')),
                leftSpace:
                  itemInner.start > item.start && itemInner.start < item.end,
                new: itemInner.new,
              }
            : { athlete: '' }
        })
        .filter(function (value) {
          return value.athlete !== ''
        })
    })
    const startTimeListFiltered = sameTimeList.filter((value, index) => {
      const _value = JSON.stringify(value)
      return (
        index ===
        sameTimeList.findIndex((sameTimeList) => {
          return JSON.stringify(sameTimeList) === _value
        })
      )
    })
    return startTimeListFiltered.filter((value) => {
      return value.length > 0
    })
  }

  const handleCurrentWeek = (index: number) => {
    const currentWeek =
      index >= 0 && index <= 6
        ? 1
        : index >= 7 && index <= 13
        ? 2
        : index >= 14 && index <= 20
        ? 3
        : index >= 21 && index <= 27
        ? 4
        : index >= 28 && index <= 34
        ? 5
        : 6
    return currentWeek
  }

  const handleCalendarListWithAthletes = () => {
    let calendarListWithAthletes: calendarType[] = []

    CalendarList.map((item, index) => {
      const calendarDate = new Date(`${item.year}-${item.month}-${item.day}`)
      const weekDay = item.weekDay
      const isToday =
        item.day === parseInt(splitStartDate[2]) &&
        item.month === parseInt(splitStartDate[1])
      const sameTimeList = handleSameTimeAthletes(calendarDate, weekDay)
      const allAthletesList = handleAllAthletes(calendarDate, weekDay)
      calendarListWithAthletes = [
        ...calendarListWithAthletes,
        {
          id: index,
          isToday: isToday,
          currentWeek: handleCurrentWeek(index),
          day: item.day,
          month: item.month,
          weekDay: item.weekDay,
          year: item.year,
          allAthletesList: allAthletesList,
          sameTimeList: sameTimeList,
        },
      ]
    })

    return calendarListWithAthletes
  }

  const handleScheduleDisplay = () => {
    return displayMode === 'month' ? (
      <MonthView
        finishDate={'2023-2-20'}
        fullScreenMode
        startDate={startDate}
        displayMode={(type) => setDisplayMode(type)}
        calendarList={handleCalendarListWithAthletes()}
        dateCallback={(date) => setInitialDate(date)}
        savedAthletes={savedAthletes}
        loading={loading}
      />
    ) : (
      displayMode === 'week' && (
        <WeekView
          fullScreenMode
          startDate={startDate}
          displayMode={(type) => setDisplayMode(type)}
          calendarList={handleCalendarListWithAthletes()}
          dateCallback={(date) => setInitialDate(date)}
          savedAthletes={savedAthletes}
          loading={loading}
        />
      )
    )
  }

  useEffect(() => {
    dispatch(getAllAthletesTimeSchedule())
  }, [dispatch])

  return isDesktop ? (
    <Styled.Container
      data-testid="schedule"
      onlyButton={onlyButton}
      margin={margin}
    >
      {onlyButton ? (
        <Button
          onClick={() => openModal('modal-calendar-desk')}
          track={buttonClickTracking}
          trackName="open_calendar_modal"
          size="xsmall"
        >
          <Icon iconName="calendar" color={theme.colors.background.default} />
          Ver calendário
        </Button>
      ) : (
        handleScheduleDisplay()
      )}
      {isVisible === 'modal-calendar-desk' && (
        <Modal showExit={false}>
          <Styled.ModalWrapper>{handleScheduleDisplay()}</Styled.ModalWrapper>
        </Modal>
      )}
    </Styled.Container>
  ) : (
    <Styled.Container data-testid="schedule">
      {onlyButton ? (
        <Button
          onClick={() => openModal('modal-calendar-mobile')}
          margin="40px 0"
          track={buttonClickTracking}
          trackName="open_calendar_modal"
          size="xsmall"
        >
          <Icon iconName="calendar" color={theme.colors.background.default} />
          Ver calendário
        </Button>
      ) : (
        <MobileSchedule
          dateCallback={(date) => setInitialDate(date)}
          savedAthletes={savedAthletes}
          startDate={startDate}
          finishDate={''}
          calendarList={handleCalendarListWithAthletes()}
        >
          {children}
        </MobileSchedule>
      )}
      {isVisible === 'modal-calendar-mobile' && (
        <Modal showExit={false}>
          <MobileSchedule
            modal
            dateCallback={(date) => setInitialDate(date)}
            savedAthletes={savedAthletes}
            startDate={startDate}
            finishDate={''}
            calendarList={handleCalendarListWithAthletes()}
          >
            {children}
          </MobileSchedule>
        </Modal>
      )}
    </Styled.Container>
  )
}

export default Schedule
