// Models
import {
  BodyVariationsTypes,
  SubtitleVariationsType,
  TextVariationsType,
  TitleVariationsTypes,
} from 'lib/styled-components/models'

// Libraries
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

type TypographyProps<TextType> = {
  align?: 'left' | 'center' | 'right' | 'justify'
  color?: string
  fontFamily?: string
  fontSize?: string
  margin?: string
  transform?: 'capitalize' | 'lowercase' | 'uppercase'
  type?: TextType
  weight?: number
  whiteSpace?: boolean
}

type GenericProps = {
  align?: 'left' | 'center' | 'right' | 'justify'
  color?: string
  fontFamily?: string
  fontSize?: string
  margin?: string
  transform?: 'capitalize' | 'lowercase' | 'uppercase'
  weight?: number
}

export const TextStyle = css<TypographyProps<TextVariationsType>>`
  text-align: ${({ align }) => align || 'left'};
  text-transform: ${({ transform }) => transform || 'none'};
  margin: ${({ margin }) => margin || '0'};
  font-weight: ${({ theme, weight }) => weight || theme.fontWeight.regular};
  color: ${({ theme, color }) => color || theme.colors.text.default};
  font-family: ${({ theme, fontFamily }) =>
    fontFamily || theme.fontFamily.roboto};
`

export const GenericTextStyle = css<GenericProps>`
  display: ${({ align }) => (align ? 'block' : 'inline')};
  text-align: ${({ align }) => align || 'left'};
  text-transform: ${({ transform }) => transform || 'none'};
  margin: ${({ margin }) => margin || '0'};
  font-weight: ${({ weight }) => weight || 'inherit'};
  color: ${({ theme }) => theme.colors.interactive.default};
  font-family: ${({ theme, fontFamily }) =>
    fontFamily || theme.fontFamily.roboto};
  :hover {
    color: ${({ theme }) => theme.colors.interactive.hovered};
  }
  :focus {
    color: ${({ theme }) => theme.colors.interactive.depressed};
  }
`

export const Title = styled.h1<TypographyProps<TitleVariationsTypes>>`
  ${TextStyle}
  font-size: ${({ theme, type, fontSize }) =>
    fontSize || (type ? theme.fontSize[type] : theme.fontSize.h1)};
  line-height: ${({ theme, type }) =>
    type ? theme.lineHeight[type] : theme.lineHeight.h1};
`

export const Subtitle = styled.h2<TypographyProps<SubtitleVariationsType>>`
  ${TextStyle}
  font-size: ${({ theme, type }) =>
    type ? theme.fontSize[type] : theme.fontSize.sh1};
  line-height: ${({ theme, type }) =>
    type ? theme.lineHeight[type] : theme.lineHeight.sh1};
`

export const Body = styled.p<TypographyProps<BodyVariationsTypes>>`
  ${TextStyle}
  font-size: ${({ theme, type }) =>
    type ? theme.fontSize[type] : theme.fontSize.copy1};
  line-height: ${({ theme, type }) =>
    type ? theme.lineHeight[type] : theme.lineHeight.copy1};
  white-space: ${({ whiteSpace }) => (whiteSpace ? 'nowrap' : 'normal')};
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GenericText = styled.p<TypographyProps<any>>`
  ${TextStyle}
  font-size: ${({ fontSize }) => fontSize || 'inherit'};
`

export const GenericAnchor = styled.a<GenericProps>`
  ${GenericTextStyle}
  text-decoration: none;
  font-size: ${({ fontSize }) => fontSize || 'inherit'};
`

export const GenericSpan = styled.span<GenericProps>`
  ${GenericTextStyle}
  font-size: ${({ fontSize }) => fontSize || 'inherit'};
`

export const GenericLink = styled(Link)<GenericProps>`
  ${GenericTextStyle}
  text-decoration: none;
  font-size: ${({ fontSize }) => fontSize || 'inherit'};
`

export const GenericTextAction = styled.a<GenericProps>`
  ${GenericTextStyle}
  font-size: ${({ theme, fontSize }) => fontSize || theme.fontSize.copy5};
`
