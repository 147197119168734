// Libraries
import * as NavRadix from '@radix-ui/react-navigation-menu'
import styled, { css, keyframes } from 'styled-components'
import { Link } from 'react-router-dom'

// Components
import { Body } from 'heeds-ds'

type ItemWrapper = {
  stacked?: boolean
}

type NavItem = {
  stacked?: boolean
  logout?: boolean
}

const navStyles = css<NavItem>`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.surface.default};
  gap: 0.8rem;

  :hover {
    p {
      color: ${({ theme }) => theme.colors.text.subdued};
    }
    svg {
      filter: invert(55%) sepia(37%) saturate(3577%) hue-rotate(330deg)
        brightness(92%) contrast(92%);
    }
  }

  &[data-active='true'] {
    svg {
      filter: invert(55%) sepia(37%) saturate(3577%) hue-rotate(330deg)
        brightness(92%) contrast(92%);
    }
  }

  ${({ logout, theme }) =>
    logout &&
    css`
      p {
        color: ${theme.colors.tag.critical};
      }
      :hover {
        p {
          color: ${theme.colors.tag.critical};
        }
        svg {
          filter: brightness(100%);
        }
      }
    `}

  /* STACKED: */
  ${({ stacked, theme }) =>
    stacked &&
    css`
      flex-direction: column;
      p {
        text-align: center;
      }

      &[data-active='true'] {
        p {
          color: ${theme.colors.text.default};
          font-weight: ${theme.fontWeight.bold};
        }
        ${IconWrapper} {
          background: ${theme.colors.badge.redBackground};
          box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.03);
        }

        :hover {
          ${IconWrapper} {
            background-color: ${theme.colors.surface.primaryHovered};
          }
        }
      }
    `}
`

export const ItemWrapper = styled(NavRadix.Item)<ItemWrapper>`
  position: relative;
  ${({ stacked, theme }) =>
    !stacked &&
    css`
      width: 100%;
      padding: 1.6rem 2.4rem;

      :not(:last-child) {
        border-bottom: 1px solid ${theme.colors.border.input};
      }

      @media screen and (min-width: ${({ theme }) =>
          theme.breakpoints.desktop}px) {
        padding: 0.8rem 1.6rem;
        :not(:last-child) {
          border-bottom: none;
        }
        :last-child {
          border-top: 1px solid ${theme.colors.border.input};
        }
      }
    `}
`

export const IconWrapper = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 12px;
`

/*
 * NAV ITEM
 */
export const NavLink = styled(NavRadix.Link)<NavItem>`
  text-decoration: none;
  appearance: none;
  ${navStyles}
`

export const NavItem = styled(Link)`
  text-decoration: none;
  appearance: none;
`

/*
 * SUBMENU
 */

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

export const SubmenuTrigger = styled(NavRadix.Trigger)`
  outline: none;
  border: none;
  ${navStyles}
`

export const Content = styled(NavRadix.Content)`
  position: absolute;
  left: 100%;
  width: 100vw;
  max-width: 290px;
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: 16px;
  box-shadow: 1px 2px 4px rgba(168, 168, 168, 0.25);
  padding: 0;
  animation: ${fadeIn} 140ms ease;
  z-index: 99;

  &[data-position='top'] {
    top: 0;
  }
  &[data-position='bottom'] {
    bottom: 0;
  }
`

export const ContentList = styled(NavRadix.List)`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Label = styled(Body).attrs({
  weight: 500,
})`
  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-size: ${theme.fontSize.copy4};
      font-weight: 600;
    }
  `}
`
