// Models
import { EWorkoutDifficulty, EWorkoutGoal } from 'models'
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'

// React
import { FC, useContext } from 'react'

// Lib
import { ThemeContext } from 'styled-components'
import { generatePath } from 'react-router-dom'

// Misc
import { getGoalIcon } from 'utils/functions'
import { urls } from 'routes/paths'
import momentBR from 'lib/moment'

// Components
import * as Styled from './styled'
import { Body } from 'components/UI/Typography'
import { Aligner, StylizedIcons } from 'heeds-ds'

type Props = {
  id: number
  routine?: IWorkoutRoutine
}

const AthleteRoutineCard: FC<Props> = ({ id, routine }) => {
  const theme = useContext(ThemeContext)
  const startDate =
    routine && momentBR(routine.start_date).format('DD [de] MMM')
  const endDate =
    routine && momentBR(routine.end_date).format('DD [de] MMM / YYYY')

  return (
    <Styled.Container
      to={generatePath(urls.workoutRoutine, { routine_id: routine?.id, id })}
    >
      <StylizedIcons iconName={getGoalIcon(routine?.goal)} />
      <Aligner flex="column" gap="0.8rem">
        <Body type="copy4" color={theme.colors.text.secondary} weight={600}>
          {routine?.name}
        </Body>
        <Body type="copy4" color={theme.colors.text.subdued}>
          {startDate} - {endDate}
        </Body>
        {routine?.goal && (
          <Body type="copy4" color={theme.colors.text.subdued}>
            {EWorkoutGoal[routine.goal]}
          </Body>
        )}
        {routine?.difficulty && (
          <Body type="copy4" color={theme.colors.text.subdued}>
            {EWorkoutDifficulty[routine.difficulty]}
          </Body>
        )}
      </Aligner>
    </Styled.Container>
  )
}

export default AthleteRoutineCard
