// React
import { FC } from 'react'

// Libs
import { Outlet } from 'react-router-dom'

// Misc
import { isWebviewAccess } from 'utils/functions'

// Components
import { Container, Content } from './styled'
import Menu from 'components/Menu'

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}

const DashboardLayout: FC<Props> = ({ children }) => {
  const isNativeApp = isWebviewAccess()
  return (
    <Container>
      <Menu />
      <Content showHeader={!isNativeApp} id="dashboard-content">
        {children || <Outlet />}
      </Content>
    </Container>
  )
}

export default DashboardLayout
