// Libraries
import { useForm, ValidationMode } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const DELAY_TIME = 800

type Props = {
  delay?: number
  schema: yup.AnyObjectSchema
  mode?: keyof ValidationMode
  reValidateMode?: 'onBlur' | 'onChange' | 'onSubmit'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useGlobalForms = <IFormInputs extends Record<any, any>>(props: Props) => {
  const {
    delay = DELAY_TIME,
    mode = 'onChange',
    reValidateMode,
    schema,
  } = props

  const form = useForm<IFormInputs>({
    resolver: yupResolver<yup.AnyObjectSchema>(schema),
    mode,
    reValidateMode,
    delayError: delay,
  })

  return form
}

export default useGlobalForms
