// React
import { FC } from 'react'

// Components
import * as Styled from './styled'
import Icon from '../UI/Icons'

type Props = {
  active?: boolean
  checkIcon?: boolean
  closeIcon?: boolean
  label: string
  onClick?: () => void
  scale?: 'large' | 'small'
}

const Chip: FC<Props> = ({
  active,
  checkIcon,
  closeIcon,
  label,
  onClick,
  scale,
}) => {
  return (
    <Styled.Container
      active={active}
      data-testid="chip-container"
      onClick={onClick}
      scale={scale}
      type="button"
    >
      {checkIcon && (
        <Icon testid="chip-check-icon" iconName="check" size={16} />
      )}

      <Styled.Text data-testid={`chip-label-${label}`} scale={scale}>
        {label}
      </Styled.Text>

      {closeIcon && (
        <Icon testid="chip-close-icon" iconName="close" size={16} />
      )}
    </Styled.Container>
  )
}

export default Chip
