// Models
import { TWeekdays } from 'models'
import { IAthleteSchedule, IAthleteSchedulesResponse } from './@types'
import {
  IAthleteSchedules,
  IPostSchedulesFormattedPayload,
  ISchedule,
} from 'storage/athleteSchedule/models'
import { IRecurringTimes } from 'storage/profile/models'

const _arrayToRecurringTimes = (array?: Array<keyof IRecurringTimes>) =>
  array?.reduce(
    (prev, cur) => ({ ...prev, [cur]: true }),
    {},
  ) as IRecurringTimes

const _recurringTimesToArray = (recurring?: IRecurringTimes) =>
  recurring &&
  (Object.entries(recurring)
    .filter(([, value]) => value === true)
    .map(([key]) => key) as TWeekdays[])

/*
 * FORMATTERS:
 */
export function formatAthleteSchedules(schedules?: IAthleteSchedule[]) {
  if (!schedules) return []

  const isRecurring =
    schedules.length === 1 &&
    Object.values(schedules[0].recurring_schedule ?? {}).filter(
      (day) => day === true,
    ).length > 1

  return schedules.flatMap(
    ({ end = '', id, recurring_schedule, start = '' }) => {
      if (!recurring_schedule) return []

      return {
        id,
        end: end.slice(0, 5),
        start: start.slice(0, 5),
        day: Object.keys(recurring_schedule).find((key) =>
          recurring_schedule
            ? recurring_schedule[key as keyof IRecurringTimes] === true
            : false,
        ),
        recurringSchedule: isRecurring
          ? _recurringTimesToArray(recurring_schedule)
          : undefined,
      }
    },
  ) as ISchedule[]
}

export function formatSchedulesResponse(
  response: IAthleteSchedulesResponse,
): IAthleteSchedules {
  const scheduleInfo = response.results[0]

  if (!scheduleInfo) return {}

  return {
    athlete: scheduleInfo.athlete,
    athleteName: scheduleInfo.athlete_name,
    athleteSubscriptionEndDate:
      scheduleInfo.athlete_active_subscription_end_date,
    athleteSubscriptionPlanType:
      scheduleInfo.athlete_active_subscription_plan_type,
    athleteSubscriptionStartDate:
      scheduleInfo.athlete_active_subscription_start_date,
    location: scheduleInfo.location,
    personal: scheduleInfo.personal,
    schedules: formatAthleteSchedules(response.results),
  }
}

export function formatAthleteSchedulesPayload(
  response: IPostSchedulesFormattedPayload,
) {
  return {
    athlete: response.athlete,
    scheduleList: response.schedules?.map(({ recurringSchedule, ...rest }) => ({
      athlete: response.athlete,
      location: response.location === '' ? null : response.location,
      recurring_schedule: _arrayToRecurringTimes(recurringSchedule),
      ...rest,
    })),
  }
}
