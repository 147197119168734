// Models
import { IAction } from 'lib/redux/models'
import { EAthleteOverviewActionTypes, IAthleteOverviewState } from '../models'

// ACTION TYPES
export const Types = {
  CLEANUP: EAthleteOverviewActionTypes.CLEANUP,
  FAILURE: EAthleteOverviewActionTypes.FAILURE,
  FULFILL: EAthleteOverviewActionTypes.FULFILL,
  REQUEST: EAthleteOverviewActionTypes.REQUEST,
  SUCCESS: EAthleteOverviewActionTypes.SUCCESS,
  GET_OVERVIEW: EAthleteOverviewActionTypes.GET_OVERVIEW,
}

// INITIAL STATE
const initialState: IAthleteOverviewState = {}

// REDUCER
export default (
  state: IAthleteOverviewState = initialState,
  action?: IAction<unknown>,
): IAthleteOverviewState => {
  switch (action?.type) {
    case Types.CLEANUP:
      return {
        ...state,
        error: undefined,
        loading: undefined,
        refreshing: undefined,
        overview: undefined,
      }
    case Types.FAILURE:
      return {
        ...state,
        error: action.payload as string,
      }
    case Types.FULFILL:
      return {
        ...state,
        loading: false,
      }
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.SUCCESS:
      return {
        ...state,
        error: undefined,
        ...(action?.payload as IAthleteOverviewState),
      }
    default:
      return state
  }
}

// ACTIONS
export const cleanup = (): IAction<undefined> => {
  return {
    type: Types.CLEANUP,
  }
}

export const failure = (payload: string): IAction<string> => {
  return {
    type: Types.FAILURE,
    payload,
  }
}

export const fulfill = (): IAction<unknown> => {
  return {
    type: Types.FULFILL,
  }
}

export const request = (): IAction<unknown> => {
  return {
    type: Types.REQUEST,
  }
}

export const success = (
  payload: IAthleteOverviewState,
): IAction<IAthleteOverviewState> => {
  return {
    type: Types.SUCCESS,
    payload,
  }
}

// CUSTOM ACTIONS
export const triggerLoadAthleteOverview = (
  payload: number,
): IAction<number> => {
  return {
    type: Types.GET_OVERVIEW,
    payload,
  }
}

export const actions = {
  cleanup,
  failure,
  fulfill,
  request,
  success,
  triggerLoadAthleteOverview,
}
