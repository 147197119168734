// React
import { FC, useCallback, useContext, useEffect, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Components
import { Container, Field, Symbol } from './styled'

type Props = {
  callback: (value: boolean) => void
  value?: string
}

enum EVariables {
  SIZE = 2,
  UPPER = 3,
  LOWER = 5,
  NUMBER = 7,
}

const VALIDATORS = [
  {
    symbol: '8+',
    name: 'caracteres',
    variable: EVariables.SIZE,
  },
  {
    symbol: 'AA',
    name: 'maiúscula',
    variable: EVariables.UPPER,
  },
  {
    symbol: 'Aa',
    name: 'minúscula',
    variable: EVariables.LOWER,
  },
  {
    symbol: '123',
    name: 'números',
    variable: EVariables.NUMBER,
  },
]

function getErrorProduct(value: string) {
  const hasValidSize = value.length >= 8 ? 1 : EVariables.SIZE
  const hasUppercase = value.toLowerCase() != value ? 1 : EVariables.UPPER
  const hasLowercase = value.toUpperCase() != value ? 1 : EVariables.LOWER
  const hasNumber = /[0-9]/.test(value) ? 1 : EVariables.NUMBER

  return hasValidSize * hasUppercase * hasLowercase * hasNumber
}

const PasswordValidator: FC<Props> = ({ value, callback }) => {
  const [validationProduct, setValidationProduct] = useState<number | null>(
    null,
  )

  const isValidField = useCallback(
    (variable: number) => {
      if (validationProduct) {
        return !!(validationProduct % variable)
      }
      return false
    },
    [validationProduct],
  )
  const theme = useContext(ThemeContext)

  const renderValidationFields = useCallback(() => {
    return VALIDATORS.map((item) => {
      const isValid = value && isValidField(item.variable)
      const color =
        validationProduct === null
          ? theme.colors.text.disabled
          : isValid
          ? theme.colors.text.success
          : theme.colors.text.critical

      return (
        <Field key={item.variable} color={color} data-testid={item.variable}>
          <Symbol>{item.symbol}</Symbol>
          {item.name}
        </Field>
      )
    })
  }, [isValidField, validationProduct, value, theme])

  useEffect(() => {
    if (value) {
      const product = getErrorProduct(value)
      setValidationProduct(product)
      if (product === 1) {
        callback(true)
      } else {
        callback(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Container data-testid="container">{renderValidationFields()}</Container>
  )
}

export default PasswordValidator
