// Models
import { IGetExercisesPayload, IGetExercisesResponse } from './@types'

// Misc
import { objectToQueryString } from 'lib/query-string'
import api from 'lib/axios/api'

export const loadExercises = async (
  payload: IGetExercisesPayload,
): Promise<IGetExercisesResponse> => {
  try {
    const query = payload
    const { data }: { data: IGetExercisesResponse } = await api.get(
      `exercises/`,
      {
        params: query,
        paramsSerializer: function (params) {
          return objectToQueryString(params)
        },
      },
    )

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}
