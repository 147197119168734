// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { useMediaQuery } from 'heeds-ds/src/hooks'

// Components
import * as Styled from './styled'
import { Aligner, Skeleton } from 'heeds-ds'

const Loader: FC = () => {
  const { breakpoints } = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.tablet}px)`)

  return (
    <Styled.LoadContainer data-testid="row">
      {isDesktop ? (
        <>
          <Aligner
            gap="1.6rem"
            align="center"
            width={'minmax(20rem, 1fr)'}
            padding="0 2.4rem"
          >
            <Styled.Icon />
            <Skeleton height="1.6rem" width="100%" />
          </Aligner>
          <Skeleton width="20rem" height="1.6rem" margin="0 0 0 2.4rem" />
          <Skeleton width="20rem" height="1.6rem" margin="0 0 0 2.4rem" />
          <Skeleton width="10rem" height="1.6rem" margin="0 0 0 2.4rem" />
          <Aligner width="5rem" />
        </>
      ) : (
        <>
          <Styled.Icon />
          <Aligner flex="column" gap="8px">
            <Skeleton width="18rem" height="1.6rem" />
            <Skeleton width="6rem" height="1.6rem" />
            <Skeleton width="7rem" height="1.6rem" />
            <Skeleton width="18rem" height="1.6rem" />
          </Aligner>
        </>
      )}
    </Styled.LoadContainer>
  )
}

export default Loader
