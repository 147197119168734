// Libraries
import styled, { css } from 'styled-components'

// Components
import { Aligner, Body } from 'heeds-ds'

type Props = {
  isAComment?: boolean
}

export const Card = styled(Aligner).attrs({
  gap: '2.4rem',
})``

export const Container = styled(Aligner).attrs({
  flex: 'column',
  gap: '3.2rem',
})`
  min-width: 40rem;
  padding: 2.4rem;
  width: 40rem;
`

export const RoutineTextBold = styled(Body).attrs({
  weight: 600,
})<Props>`
  ${({ isAComment, theme }) => css`
    color: ${theme.colors.text[isAComment ? 'subdued' : 'default']};
    font-size: ${theme.fontSize[isAComment ? 'copy4' : 'copy3']};
  `}
`
