// Models
import {
  IAthletePlan,
  ICreateAthletePlans,
  ILoadAthletePlans,
  IUpdateAthletePlans,
} from './@types'

// Misc
import api from 'lib/axios/api'

export const createAthletePlans = async (
  payload: ICreateAthletePlans,
): Promise<IAthletePlan> => {
  try {
    const { athlete_pk, plan } = payload
    const response = await api.post(`athletes/${athlete_pk}/plans/`, plan)
    const data: IAthletePlan = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const loadAthletePlans = async (
  payload: ILoadAthletePlans,
): Promise<IAthletePlan> => {
  try {
    const { id } = payload
    const { data }: { data: IAthletePlan } = await api.get(
      `financial/athletes/${id}/active-subscription/`,
    )
    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const updateAthletePlans = async (
  payload: IUpdateAthletePlans,
): Promise<IAthletePlan> => {
  try {
    const { plan_id, plan } = payload
    const response = await api.patch(`/plans/${plan_id}/`, plan)
    const data: IAthletePlan = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}
