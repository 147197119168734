// Libraries
import styled from 'styled-components'

// Components
import { Button } from 'heeds-ds'

export const ButtonContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.border.input};
  display: flex;
  justify-content: space-between;
  padding: 1.6rem 4rem;
  width: 100%;
`

export const CancelButton = styled(Button).attrs(() => ({
  cancel: true,
  margin: '0',
  size: 'xsmall',
  variation: 'outlined',
}))``

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  height: calc(100vh - 29.1rem);
  position: relative;
`

export const GoBackButton = styled(Button).attrs(() => ({
  margin: '0',
  size: 'xsmall',
  variation: 'outlined',
}))``

export const NextButton = styled(Button).attrs(() => ({
  margin: '0',
  size: 'xsmall',
}))``
