// Models
import { CustomRouteProps } from './models'

// Libs
import { Navigate } from 'react-router-dom'

export function AuthRoute({ element, isLoggedIn }: CustomRouteProps) {
  const redirect =
    new URLSearchParams(window.location.search).get('redirect') || ''

  if (isLoggedIn) {
    return <Navigate to={redirect || '/dashboard'} replace />
  }

  return element
}
