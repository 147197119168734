// React
import { FC, useRef, useEffect, useState } from 'react'

// Custom
import { Body } from '../UI/Typography'
import {
  Container,
  StepsBackground,
  StepBarBackground,
  StepsContainer,
  StepsWrapper,
  ProgressBar,
} from './styled'

type Option = {
  label: string
  path?: string
}

type Props = {
  steps?: Option[]
  progress?: boolean
  disabled?: boolean
}

// TODO: desutilizar esse componente e user apenas o TabsNavigator
const Steps: FC<Props> = ({ steps = [], progress, disabled }) => {
  const ref = useRef<HTMLInputElement>(null)
  const [active, setActive] = useState(0)

  useEffect(() => {
    ref.current && (ref.current.scrollLeft = 80 * active)
  }, [active])

  return (
    <Container>
      <StepsBackground ref={ref} progress={progress}>
        <StepsContainer data-testid="steps" progress={progress}>
          {steps?.map((path, index) => {
            return (
              <StepsWrapper
                to={path.path ? path.path : '/'}
                key={index}
                className={(isActive) => (isActive ? 'active' : '')}
                progress={progress}
                disabled={disabled}
                onClick={() => setActive(index)}
              >
                <Body type="copy5" weight={700}>
                  {path.label}
                </Body>
                {!progress && (
                  <StepBarBackground length={100} progress={progress}>
                    <ProgressBar />
                  </StepBarBackground>
                )}
              </StepsWrapper>
            )
          })}
        </StepsContainer>
        {progress && (
          <StepBarBackground
            length={(steps.length + 1) * 25}
            progress={progress}
          >
            <ProgressBar
              fill={((active + 1) / steps.length) * 100}
              progress={progress}
            />
          </StepBarBackground>
        )}
      </StepsBackground>
    </Container>
  )
}

export default Steps
