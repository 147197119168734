// Libraries
import styled, { css } from 'styled-components'

type StyledProps = {
  disabled?: boolean
  isFocused?: boolean
  margin?: string
  variation?: 'small' | 'medium' | 'large'
  width?: string
}

enum EPaddingSizes {
  small = '0.8rem 1.6rem',
  medium = '1.2rem 1.6rem',
  large = '1.6rem 1.6rem',
}

enum EFontSizes {
  small = '1.4rem',
  medium = '1.6rem',
  large = '1.8rem',
}

export const Container = styled.div<StyledProps>`
  align-items: center;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
  justify-content: space-between;

  ${({ disabled, isFocused, margin, theme, variation, width }) => css`
    background: ${theme.colors.surface[disabled ? 'disabled' : 'default']};
    box-shadow: ${isFocused
      ? `0px 0px 5px 0px ${theme.colors.text.secondary}`
      : '0px 4px 20px 0px rgba(99, 90, 108, 0.10)'};
    border: 1px solid ${theme.colors.icon[disabled ? 'disabled' : 'default']};
    font-family: ${theme.fontFamily.roboto};
    margin: ${margin || '1.6rem 0'};
    padding: ${EPaddingSizes[variation || 'medium']};
    width: ${width || '100%'};
    ${isFocused ? `outline: 1px solid ${theme.colors.icon.default}` : ''};
  `};
`

export const FilterButton = styled.button<StyledProps>`
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: 1rem;
  padding: 0;

  :disabled {
    cursor: auto;
  }
`

export const Input = styled.input<StyledProps>`
  border: none;
  flex-grow: 1;
  outline: none;
  width: 100%;

  ${({ theme, variation }) => css`
    color: ${theme.colors.text.default};
    font-family: ${theme.fontFamily.roboto};
    font-size: ${EFontSizes[variation || 'medium']};

    :disabled {
      background: none;
      color: ${theme.colors.text.disabled};

      ::placeholder {
        color: ${theme.colors.text.disabled};
      }
    }

    ::placeholder {
      color: ${theme.colors.text.subdued};
    }
  `};
`
