// React
import { FC, useContext } from 'react'

// Libraries
import { useNavigate } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { urls } from 'routes/paths'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import { Aligner, Button } from 'heeds-ds'
import * as Styled from '../styled'

// Assets
import linkOff from 'assets/images/link-off.svg'

const AlreadyConfirmed: FC = () => {
  const theme = useContext(ThemeContext)
  const navigate = useNavigate()
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  return (
    <Aligner flex="column">
      <Styled.Image
        src={linkOff}
        margin="0 0 3.2rem 0"
        width={!isDesktop ? '60px' : '98px'}
      />
      <Aligner flex="column" align="center">
        <Styled.TitleMain>
          Sua conta já foi <br /> confirmada
        </Styled.TitleMain>
        <Styled.SubTitleMain margin="4rem 0 0 0">
          Clique no botão abaixo e aproveite o sistema.
        </Styled.SubTitleMain>
      </Aligner>
      <Aligner flex="column" align="center" margin="5.6rem 0 0 0">
        {!isDesktop && (
          <Button
            onClick={() => window.open('cadu.fit://auth/login')}
            size={isDesktop ? 'small' : 'medium'}
            track={buttonClickTracking}
            trackName="open_app"
          >
            Ir para o aplicativo
          </Button>
        )}

        <Button
          onClick={() => navigate(urls.login)}
          size={isDesktop ? 'small' : 'medium'}
          track={buttonClickTracking}
          trackName="navigate_to_login"
          variation={isDesktop ? 'main' : 'borderless'}
        >
          {isDesktop ? 'Entrar na minha conta' : 'Entrar pelo navegador'}
        </Button>
      </Aligner>
    </Aligner>
  )
}

export default AlreadyConfirmed
