// Models
import { IAnamneseTemplate } from 'storage/assessmentAnamneseTemplate/models'

// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Components
import * as Styled from './styled'
import { Swipe } from 'heeds-ds'

export type Props = {
  anamneseTemplate: IAnamneseTemplate
  onClick: (anamneseId: string) => void
  openOptions: () => void
}

const Mobile: FC<Props> = ({ onClick, openOptions, anamneseTemplate }) => {
  const theme = useContext(ThemeContext)

  const { title, personal, id } = anamneseTemplate

  const showVisualize = title === 'Cuidados médicos' || title === 'Par-Q'

  return (
    <Swipe
      active
      actions={[
        {
          icon: showVisualize ? 'eye' : 'moreHorizontal',
          color: theme.colors.interactive.hovered,
          onClick: () => (showVisualize ? onClick(id) : openOptions()),
        },
      ]}
      padding="0.8rem 0"
    >
      <Styled.Container data-testid="routine-item" onClick={() => onClick(id)}>
        <Styled.Title>{title}</Styled.Title>

        <Styled.Creator>
          {`Criado ${personal !== 0 ? 'por você' : 'pelo Sistema'}`}
        </Styled.Creator>
      </Styled.Container>
    </Swipe>
  )
}

export default Mobile
