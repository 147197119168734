// Libraries
import styled, { css } from 'styled-components'

// Components
import { Body } from 'heeds-ds'

type Props = {
  compact?: boolean
  space?: boolean
}

const fontStyled = css<Props>`
  font-size: ${({ theme, compact }) =>
    compact ? theme.fontSize.copy3 : theme.fontSize.copy1};
  margin: ${({ compact }) => (compact ? '0 0 4px' : '0 0 8px')};
`

export const Container = styled.div<Props>`
  display: grid;
  padding: 24px 24px 16px;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    ${({ space }) => space && 'column-gap: 24px'};
    grid-template-columns: ${({ compact }) => (compact ? '280px 1fr' : '1fr')};
    padding: 0;
  }
`

export const InfoContainer = styled.div<Props>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: 15px;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  display: flex;
  justify-content: left;
  margin: 0;
  padding: 8px 24px;
  position: relative;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: ${({ compact }) => (compact ? '8px 24px' : '16px 32px')};
  }
`

export const InfoText = styled.div<Props>`
  margin-left: 16px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin-left: ${({ compact }) => (compact ? '16px' : '32px')};
  }
`

export const Title = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.default,
  margin: '0 0 4px',
  type: 'copy3',
  weight: 600,
}))`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    ${fontStyled}
  }
`

export const DisabledBackground = styled.div`
  background: ${({ theme }) => `${theme.colors.surface.subdued}66`};
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  z-index: 1;
`
