// Models
import { TButtonTrakingEvent } from '../../../models'

// React
import { useContext, forwardRef } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useLocation } from 'react-router-dom'

// Components
import BaseButton, { Props as ButtonProps } from '../BaseButton'
import LoadingSpinner from '../../LoadingSpinner'

enum EIconSizes {
  xsmall = 16,
  small = 16,
  medium = 24,
  large = 24,
}

export type Props = ButtonProps & {
  loading?: boolean
  track?: (event: TButtonTrakingEvent) => void
  trackName?: string
}

const FormButton = forwardRef<HTMLButtonElement, Props>(
  ({ track, trackName, ...props }, ref) => {
    const {
      children,
      loading,
      disabled,
      size = 'medium',
      variation = 'main',
    } = props
    const { search } = useLocation()
    const theme = useContext(ThemeContext)

    const iconColors = {
      main: theme.colors.text.onPrimary,
      outlined:
        theme.colors.action[
          disabled ? 'secondaryDisabled' : 'secondaryDefault'
        ],
      borderless: theme.colors.interactive[disabled ? 'disabled' : 'default'],
      success: theme.colors.icon.onPrimary,
      cancel: theme.colors.interactive.critical,
    }

    const tracking = () =>
      track?.({
        eventName: 'click',
        action: `click_form_${trackName ?? ''}`,
        location: window.location.pathname + search,
      })

    return (
      <BaseButton
        data-testid="formButton"
        ref={ref}
        tracking={tracking}
        type="submit"
        {...props}
      >
        {loading ? (
          <LoadingSpinner
            size={EIconSizes[size] + 6}
            color={iconColors[variation]}
          />
        ) : (
          children
        )}
      </BaseButton>
    )
  },
)
FormButton.displayName = 'FormButton'

export default FormButton
