// Models
import { TButtonTrakingEvent } from '../../../models'

// React
import { forwardRef } from 'react'

// Libraries
import { NavLinkProps, useLocation } from 'react-router-dom'

// Components
import { Props as ButtonProps } from '../BaseButton'
import { MainLink } from '../BaseButton/styled'

export type Props = ButtonProps &
  NavLinkProps & {
    track?: (event: TButtonTrakingEvent) => void
    trackName?: string
  }

const LinkButton = forwardRef<HTMLAnchorElement, Props>(
  ({ track, trackName, ...props }, ref) => {
    const { search } = useLocation()
    const { variation, children } = props

    const tracking = (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.stopPropagation()
      track?.({
        eventName: 'click',
        action: `click_link_${trackName}`,
        location: window.location.pathname + search,
      })
    }

    return (
      <MainLink
        ref={ref}
        variation={variation}
        data-testid="linkButton"
        onClick={tracking}
        {...props}
      >
        {children}
      </MainLink>
    )
  },
)
LinkButton.displayName = 'LinkButton'

export default LinkButton
