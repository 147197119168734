// Libraries
import styled from 'styled-components'

// Components
import { Body, IconButton } from 'heeds-ds'

export const Description = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.subdued,
  type: 'copy4',
}))``

export const PlayIconButton = styled(IconButton).attrs((props) => ({
  iconName: 'playCircle',
  margin: '0',
  ...props,
}))``

export const Title = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.secondary,
  type: 'copy4',
  weight: 700,
}))``
