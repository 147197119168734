/* eslint-disable @typescript-eslint/no-explicit-any */
// Models
import { AxiosError } from 'axios'
import { IAthleteInfo, IUpdateAthlete, TCreateAthletePayload } from './@types'

//
import api from 'lib/axios/api'

export const createAthlete = async (
  payload: TCreateAthletePayload,
): Promise<IAthleteInfo> => {
  try {
    const { data }: { data: IAthleteInfo } = await api.post(
      `athletes/`,
      payload,
    )
    return data
  } catch (error: any) {
    if (error?.response?.data?.email) {
      throw new Error(error.response.data.email)
    }
    throw new Error(error.message)
  }
}

export const loadAthleteInformation = async (
  id: number,
): Promise<IUpdateAthlete> => {
  try {
    const { data }: { data: IAthleteInfo } = await api.get(`athletes/${id}/`)
    return data
  } catch (error: unknown) {
    const err = error as AxiosError
    if (err.code === 'ERR_BAD_REQUEST') {
      throw new Error('404')
    }
    throw new Error(err.message)
  }
}

export const updateAthleteInformation = async (
  fullPayload: IUpdateAthlete,
): Promise<IUpdateAthlete> => {
  try {
    const { id, ...payload } = fullPayload
    const { data }: { data: IAthleteInfo } = await api.patch(
      `athletes/${id}/`,
      payload,
    )
    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}
