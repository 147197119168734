// Libraries
import styled from 'styled-components'

type OptionsProps = {
  alignment: 'row' | 'column'
  disabled?: boolean
  gap?: string
}

export const OptionsWrapper = styled.div<OptionsProps>`
  display: flex;
  flex-direction: ${({ alignment }) => alignment};
  gap: ${({ alignment, gap }) =>
    gap || (alignment === 'row' ? '3.2rem' : '2.4rem')};
  margin-top: 1.2rem;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
  flex-wrap: wrap;
`
