// Models
import { EFirebaseProviderOptions } from 'storage/auth/models'
import { TOutletContext } from 'layouts/AuthLayout'
import { TypeUser } from 'models'

// React
import { FC, useContext, useLayoutEffect } from 'react'

// Libraries
import { FormProvider } from 'react-hook-form'
import { ThemeContext } from 'styled-components'
import { useDispatch } from 'react-redux'
import { useNavigate, useOutletContext } from 'react-router-dom'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { authLoginSchema } from 'schemas'
import { triggerLogin } from 'storage/auth/duck'
import { urls } from 'routes/paths'
import useGlobalForms from 'hooks/useGlobalForms'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import * as Styled from './styled'
import {
  Aligner,
  Body,
  Icon,
  InputText,
  FormButton,
  GenericLink,
  SSOButton,
} from 'heeds-ds'

// Assets
import backgroundImage from 'assets/images/login-background.svg'

interface IFormInputs {
  email: string
  password: string
}

const Login: FC = () => {
  const { setLayoutBackground } = useOutletContext<TOutletContext>()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const methods = useGlobalForms<IFormInputs>({ schema: authLoginSchema })
  const {
    handleSubmit,
    formState: { isValid },
  } = methods

  const handlePageRedirection = () => {
    return urls.dashboard
  }

  const submitLogin = (
    provider: EFirebaseProviderOptions,
    data?: IFormInputs,
  ) => {
    const redirect =
      new URLSearchParams(window.location.search).get('redirect') || ''
    const to = Object.values(urls).includes(redirect)
      ? redirect
      : handlePageRedirection()

    const payload = {
      ...data,
      provider: provider,
      redirection: { navigate, to },
      type: 'personal' as TypeUser,
    }

    dispatch(triggerLogin(payload))
  }

  const onSubmit = (data: IFormInputs) => {
    submitLogin(EFirebaseProviderOptions.EMAIL, data)
  }

  useLayoutEffect(() => {
    setLayoutBackground(backgroundImage)
  }, [setLayoutBackground])

  return (
    <FormProvider {...methods}>
      <Aligner flex="column" padding={isDesktop ? '0 3.8rem' : '0'}>
        <Aligner gap="1.2rem" justify={isDesktop ? 'center' : 'space-between'}>
          <SSOButton
            onClick={() => submitLogin(EFirebaseProviderOptions.GOOGLE)}
            provider="google"
          />
          <SSOButton
            onClick={() => submitLogin(EFirebaseProviderOptions.FACEBOOK)}
            provider="facebook"
          />
          <SSOButton
            onClick={() => submitLogin(EFirebaseProviderOptions.APPLE)}
            provider="apple"
          />
        </Aligner>
        <Body
          type="copy5"
          align="center"
          color={theme.colors.text.subdued}
          margin={isDesktop ? '2.4rem 0' : '2.4rem 0 0'}
        >
          ou use seu e-mail
        </Body>
        <Styled.FormContainer onSubmit={handleSubmit(onSubmit)}>
          <InputText
            label="Email"
            placeholder="Digite seu email cadastrado"
            name="email"
            scale="small"
            type="email"
          />
          <InputText
            label="Senha"
            type="password"
            placeholder="Digite sua senha"
            name="password"
            scale="small"
          />
          <Aligner justify="flex-end">
            <GenericLink
              to={urls.forgotPassword}
              align="right"
              fontSize="1.4rem"
            >
              Esqueci minha senha
            </GenericLink>
          </Aligner>
          <FormButton
            disabled={!isValid}
            margin={isDesktop ? '8rem 0 0' : '4rem 0 0'}
            size="small"
            track={buttonClickTracking}
            trackName="login"
            width="100%"
          >
            Entrar
            <Icon
              iconName="arrowRight"
              color={theme.colors.background.default}
            />
          </FormButton>
        </Styled.FormContainer>
        <Body
          align="center"
          type="copy4"
          margin={isDesktop ? '10rem 0 0' : '2.4rem 0'}
        >
          Ainda não tem conta?
          <GenericLink to={urls.signup}> Inscreva-se agora</GenericLink>
        </Body>
      </Aligner>
    </FormProvider>
  )
}

export default Login
