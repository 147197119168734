// Models
import { Action } from 'heeds-ds/src/components/Swipe'
import { IAthleteInfoState } from 'storage/athleteInfo/models'
import { IAthletePlansState } from 'storage/athletePlans/models'
import { TSubscriptionStatus } from 'storage/financialSubscription/models'
import IStore from 'lib/redux/models'

// React
import React, { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { prepareAthletePlansToDisplay } from 'filters/athlete'
import { triggerDeactivateSubscription } from 'storage/financialSubscription/duck'
import { triggerToastSuccess } from 'storage/general/duck'
import { urls } from 'routes/paths'
import { useMediaQuery, useModal } from 'hooks'

// Components
import * as Styled from './styled'
import { Aligner, Avatar, Body, Icon, Swipe } from 'heeds-ds'
import { ModalConfirmation } from 'components/modals'

// Constants
const MODAL_PROPS = {
  active: {
    description: `Tem certeza que deseja desativar este aluno?`,
    longerDescription:
      'O status do aluno ficará como desativado para o sistema. Você poderá reativar esse aluno a qualquer momento.',
    title: 'Desativar Aluno',
    confirmTitle: 'Desativar',
  },
  inactive: {
    description: `Tem certeza que deseja reativar este aluno?`,
    title: 'Reativar Aluno',
    confirmTitle: 'Ativar',
  },
}

const successText = {
  active: 'desativado',
  inactive: 'ativado',
}

const FinancialAthleteHeader: FC = () => {
  const { id = '' } = useParams()
  const { info } = useSelector<IStore, IAthleteInfoState>(
    (state) => state.athleteInfo,
  )
  const { plans } = useSelector<IStore, IAthletePlansState>(
    (state) => state.athletePlans,
  )
  const { openModal } = useModal()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const status: TSubscriptionStatus = 'active'

  const handleChangeStatus = () => {
    const payload = {
      id: parseInt(id),
      successCallback: () => onSuccess(),
    }
    if (status === 'active') {
      dispatch(triggerDeactivateSubscription(payload))
    } else {
      // TODO: endpoint de ativação
    }
  }

  const onSuccess = () => {
    dispatch(
      triggerToastSuccess({
        customTitle: 'Sucesso',
        message: `Aluno "${info?.name}" foi ${successText[status]}.`,
      }),
    )
  }

  const statusButton = {
    active: 'Desativar Aluno',
    inactive: 'Ativar Aluno',
  }

  const optionsProps: Record<string, Action> = {
    active: {
      icon: 'userOff',
      color: theme.colors.text.critical,
      onClick: () => openModal('financial-athlete-modal'),
    },
    inactive: {
      icon: 'userAdd',
      color: theme.colors.interactive.default,
      onClick: () => openModal('financial-athlete-modal'),
    },
  }

  const plansToDisplay = plans && prepareAthletePlansToDisplay(plans[0])
  const hasExtraWorkouts =
    plansToDisplay &&
    plansToDisplay.extra_workouts &&
    plansToDisplay.extra_workouts.length > 0

  return (
    <React.Fragment>
      <Swipe active={!isDesktop} actions={[optionsProps[status]]}>
        <Aligner flex="column" gap="2.4rem">
          {isDesktop && (
            <Aligner justify="space-between">
              <Styled.GoBackButton
                onClick={() => navigate(urls.financial, { replace: true })}
                track={buttonClickTracking}
                trackName="navigate_to_financial_general"
              >
                <Icon
                  iconName="arrowBack"
                  color={theme.colors.interactive.default}
                />
                Voltar para perfil
              </Styled.GoBackButton>

              <Styled.DeactivatedButton
                onClick={() => openModal('financial-athlete-modal')}
                track={buttonClickTracking}
                trackName="open_deactivate_confirmation_modal"
              >
                {statusButton[status]}
              </Styled.DeactivatedButton>
            </Aligner>
          )}

          <Aligner gap="2.4rem">
            <Styled.AthleteInfo>
              <Avatar name={info?.name ?? ''} />

              <Aligner flex="column" gap="0.8rem" width="auto">
                {isDesktop ? (
                  <Styled.NameDesktop>{info?.name}</Styled.NameDesktop>
                ) : (
                  <Body color={theme.colors.text.secondary} weight={600}>
                    {info?.name}
                  </Body>
                )}

                <Aligner gap="0.2rem">
                  {isDesktop && (
                    <Body type="copy4" color={theme.colors.text.subdued}>
                      Vigência do Plano:
                    </Body>
                  )}

                  <Body
                    type="copy4"
                    weight={600}
                    color={theme.colors.text.subdued}
                  >
                    {plansToDisplay &&
                      ` ${plansToDisplay.start_date} a ${plansToDisplay.due_date}`}
                  </Body>
                </Aligner>
              </Aligner>

              {!isDesktop && (
                <Styled.InfoButton onClick={() => undefined}>
                  <Styled.InfoIcon />
                </Styled.InfoButton>
              )}
            </Styled.AthleteInfo>

            {isDesktop && (
              <Styled.Resume>
                <Aligner flex="column" gap="0.8rem">
                  {plansToDisplay && (
                    <React.Fragment>
                      <Styled.TextPlanType>
                        {plansToDisplay.plan_model}
                      </Styled.TextPlanType>

                      <Styled.TextPlanType>
                        {plansToDisplay.plan_type}
                      </Styled.TextPlanType>

                      {hasExtraWorkouts && (
                        <Styled.TextPlanType>
                          Treinos Extras
                        </Styled.TextPlanType>
                      )}
                    </React.Fragment>
                  )}
                </Aligner>

                <Aligner flex="column" gap="1.6rem" align="flex-end">
                  <Body
                    type="copy2"
                    weight={700}
                    color={theme.colors.text.secondary}
                  >
                    Valor do Plano
                  </Body>

                  <Body type="copy1" weight={600}>
                    {plansToDisplay?.total || 0}
                  </Body>
                </Aligner>
              </Styled.Resume>
            )}
          </Aligner>
        </Aligner>
      </Swipe>

      <ModalConfirmation
        {...MODAL_PROPS[status]}
        onConfirm={handleChangeStatus}
      />
    </React.Fragment>
  )
}

export default FinancialAthleteHeader
