//React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Components
import * as Styled from './styled'
import { Body, Icon, IconsType } from 'heeds-ds'
import { Modal } from 'components/modals'

export type TModalMoreOption = {
  active?: boolean
  color?: string
  disabled?: boolean
  icon?: IconsType
  label: string
  onClick: () => void
}

type Props = {
  options?: TModalMoreOption[]
}

const ModalMoreOptions: FC<Props> = ({ options }) => {
  const theme = useContext(ThemeContext)

  return (
    <Modal>
      <Styled.ContentWrapper data-testid="modalMoreOptions">
        {options?.map(
          ({ active, color, disabled, icon, label, onClick }, index) => {
            return (
              <Styled.OptionWrapper
                key={index}
                onClick={!disabled ? onClick : undefined}
                disabled={disabled}
              >
                {icon && (
                  <Icon
                    color={color || theme.colors.icon.default}
                    iconName={icon}
                    size={32}
                  />
                )}
                <Body
                  color={color || theme.colors.text.default}
                  weight={active ? 700 : 500}
                >
                  {label}
                </Body>
              </Styled.OptionWrapper>
            )
          },
        )}
      </Styled.ContentWrapper>
    </Modal>
  )
}

export default ModalMoreOptions
