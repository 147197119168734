// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body } from 'heeds-ds'

type Props = {
  active?: boolean
  disabled?: boolean
  errors?: boolean
}

export const Container = styled(Aligner).attrs(() => ({
  flex: 'column',
}))<Props>`
  border-radius: 15px;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  gap: 12px;
  position: relative;
  width: max-content;
`

export const DisabledBackground = styled.div`
  background: ${({ theme }) => `${theme.colors.surface.subdued}66`};
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  z-index: 1;
`

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
  width: fit-content;
`

export const IndicatorBar = styled.div<Props>`
  background: ${({ active, theme }) =>
    active ? theme.colors.icon.default : theme.colors.surface.neutralDefault};
  border-radius: 2px;
  height: 4px;
  width: 100%;
`

export const Input = styled.input.attrs(() => ({
  autoFocus: true,
  type: 'text',
}))`
  border: none;
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.fontSize.copy5};
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  outline: none;
  width: auto;
`

export const Label = styled(Body).attrs(() => ({
  type: 'copy5',
  weight: 700,
}))<Props>`
  white-space: nowrap;
  color: ${({ errors, theme }) =>
    theme.colors.text[errors ? 'critical' : 'secondary']};
`

export const LabelContainer = styled(Aligner).attrs(() => ({
  align: 'center',
  gap: '32px',
}))``
