// Libraries
import styled, { css } from 'styled-components'

type Props = {
  bottomDistance?: string
  columnsFormat?: string
  columnsGap?: string
  padding?: string
  rowsGap?: string
}

export const InfiniteScrollContainer = styled.div<Props>`
  position: relative;
  flex-grow: 1;
  min-height: 0;
  overflow-y: auto;
  padding-right: 0.8rem;

  .infine-scroll {
    display: flex;
    flex-direction: column;
    gap: ${({ rowsGap }) => rowsGap || '0'};
    margin-bottom: ${({ bottomDistance }) => bottomDistance || '2.4rem'};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 0;

    .infine-scroll {
      gap: ${({ rowsGap }) => rowsGap || '0.8rem'};
    }

    ::-webkit-scrollbar {
      display: none;
      padding: 0;
    }
  }
`

export const Header = styled.div<Props>`
  ${({ theme, columnsFormat, columnsGap }) => css`
    border-bottom: 1px solid ${theme.colors.border.input};
    display: grid;
    gap: ${columnsGap ?? '0'};
    grid-template-columns: ${columnsFormat ?? '2fr 1fr 1fr 1fr 1fr 0.5fr'};
    padding: 0;
    width: 100%;
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      grid-template-columns: ${columnsFormat ??
      'minmax(10.9rem, 2fr) minmax(6rem, 1fr) minmax(3.5rem, 1fr) minmax(7rem,1fr)minmax(5rem, 1fr) minmax(5rem, 0.5fr)'};
    }
  `}
`

export const Title = styled.h3<Props>`
  ${({ padding, theme }) => css`
    text-align: left;
    text-transform: none;
    margin: 0;
    font-weight: 700;
    padding: ${padding || 0};
    color: ${theme.colors.text.subdued};
    font-family: ${theme.fontFamily.roboto};
    font-size: ${theme.fontSize.copy4};
    line-height: ${theme.lineHeight.copy4};
  `}
`
