// Models
import { IAuthState, IForgotPasswordPayload } from 'storage/auth/models'
import { TOutletContext } from 'layouts/AuthLayout'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useLayoutEffect } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { requestForgotPassword } from 'storage/auth/duck'
import { urls } from 'routes/paths'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import * as Styled from './styled'
import { Aligner } from 'components/UI/Containers'
import { Body } from 'components/UI/Typography'
import { Button, LinkButton } from 'heeds-ds'
import { FeedbackImage } from 'components/UI/Image'

// Assets
import backgroundImage from 'assets/images/signup-confirmation-background.svg'
import emailSuccess from 'assets/images/email-success.png'

export interface FormInputs {
  email: string
}

const ForgotPasswordSuccess: FC = () => {
  const { userData } = useSelector<IStore, IAuthState>((state) => state.auth)
  const { setLayoutBackground } = useOutletContext<TOutletContext>()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)
  const dispatch = useDispatch()

  const handleResendEmail = () => {
    const payload: IForgotPasswordPayload = {
      email: userData?.email ?? '',
    }
    dispatch(requestForgotPassword(payload))
  }

  useLayoutEffect(() => {
    setLayoutBackground(backgroundImage)
  }, [setLayoutBackground])

  return (
    <>
      <Aligner justify="center" margin="0 0 3.2rem 0">
        <FeedbackImage src={emailSuccess} />
      </Aligner>
      <Aligner flex="column" align="center">
        <Styled.MainTitle>Sucesso!</Styled.MainTitle>
        <Styled.SubText>
          Um link de redefinição de senha foi enviado para o seu e-mail
          cadastrado.
        </Styled.SubText>
        <Body
          align="center"
          color={theme.colors.text.subdued}
          type={isDesktop ? 'copy1' : 'copy2'}
        >
          Não esqueça de olhar o spam!
        </Body>
      </Aligner>
      <Aligner flex="column" align="center">
        <Button
          margin="5.6rem 0 3.2rem 0"
          onClick={handleResendEmail}
          size="medium"
          track={buttonClickTracking}
          trackName="resend_password_reset_email"
          width="100%"
        >
          Reenviar e-mail
        </Button>
        <LinkButton
          margin="0"
          size="small"
          to={urls.login}
          track={buttonClickTracking}
          trackName="navigate_to_login"
          variation="borderless"
        >
          Entrar na minha conta
        </LinkButton>
      </Aligner>
    </>
  )
}

export default ForgotPasswordSuccess
