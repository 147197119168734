import styled from 'styled-components'

type CalendarProps = {
  distance?: number
  show?: boolean
  saved?: boolean
  sameTime?: boolean
  leftSpace?: boolean
  noMargin?: boolean
  firstAvatar?: boolean
  borderRadius?: string
  leftMargin?: number
  mobile?: boolean
}

export const AthleteListContainer = styled.div`
  overflow-y: scroll;
  height: 100%;
  ::-webkit-scrollbar {
    width: 2px;
    margin-left: 16px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 6px;
    margin-bottom: 20px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.icon.hovered};
    border-radius: 6px;
  }
  transition: all 0.4s ease;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding-bottom: 0px;
    margin-top: 24px;
    height: 90%;
    position: relative;
  }
`

export const GradientBottom = styled.div`
  position: sticky;
  left: 0;
  bottom: 10px;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.31) 0%, #fff 100%);
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-right: 5px;
`

export const CardTimes = styled.div<CalendarProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 24px;
  padding: 0 24px;
  width: 100%;
  z-index: 30;
  right: ${({ distance }) =>
    distance === 3
      ? '0'
      : distance === 4
      ? '0'
      : distance === 5
      ? '0'
      : distance === 6
      ? '0'
      : ''};
  left: ${({ distance }) => (distance === 0 && 1 && 2 ? '0' : 'auto')};
  margin: 0;
  bottom: 10px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    height: 511px;
    position: absolute;
    padding: 24px;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.3);
    width: 338px;
    margin-left: ${({ distance }) =>
      distance === 0
        ? 'calc((100%/7) + 70px)'
        : distance === 1
        ? 'calc((100%/7) * 2 + 70px)'
        : distance === 2
        ? 'calc((100%/7) * 3 + 70px)'
        : '0'};
    margin-right: ${({ distance }) =>
      distance === 3
        ? 'calc((100%/7) * 4)'
        : distance === 4
        ? 'calc((100%/7) * 3)'
        : distance === 5
        ? 'calc((100%/7) * 2)'
        : distance === 6 && 'calc((100%/7 + 10px))'};
  }
`

export const DayCard = styled.div<CalendarProps>`
  width: 95%;
  background-color: ${({ theme }) => theme.colors.surface.subdued};
  border-left: 2px solid
    ${({ saved, theme }) => theme.colors.text[saved ? 'secondary' : 'critical']};
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: ${({ noMargin }) => (noMargin ? '0 16px' : '0 16px 16px 16px')};
  margin: 0 auto ${({ noMargin, saved }) => (noMargin ? -1 : saved ? 0 : 14)}px
    auto;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 100%;
    margin: 0 auto ${({ noMargin }) => (noMargin ? -1 : 14)}px auto;
  }
`

export const DayCardWeek = styled.div<CalendarProps>`
  width: 95%;
  background: ${({ saved, theme }) =>
    saved
      ? theme.colors.surface.selectedHovered
      : theme.colors.surface.criticalSubdued};
  border-left: 2px solid
    ${({ saved, theme }) => theme.colors.text[saved ? 'secondary' : 'critical']};
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: ${({ borderRadius }) => borderRadius};
  margin: 0 auto 14px auto;
  padding: ${({ noMargin }) => (noMargin ? '0 16px' : '0 16px 16px 16px')};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 100%;
    margin: 0 auto ${({ noMargin }) => (noMargin ? -1 : 14)}px auto;
  }
`

export const DayCardTop = styled.div`
  display: flex;
  align-items: center;
`

export const DayCardWrapper = styled.div``

export const TimeLine = styled.div`
  position: relative;
  ::before {
    content: '';
    position: absolute;
    width: 85%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.border.input};
    right: 0;
    bottom: 50%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: none;
  }
`

export const SelectedDayTitles = styled.div`
  display: flex;
  padding: 32px 0px;
`

export const SelectedDay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const TimesDisplay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 8px;
  margin-left: 24px;
`

export const AthleteListContainerWeek = styled.div``
