// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body, Button } from 'heeds-ds'

type Props = {
  selected?: boolean
}

export const AddModelButton = styled(Button).attrs(() => ({
  margin: '0 0 0 auto',
  size: 'small',
  variation: 'borderless',
}))``

export const FadeFooter = styled.div`
  background: linear-gradient(
    360deg,
    rgba(250, 250, 250, 0.9) 20%,
    rgba(250, 250, 250, 0.4) 40%,
    rgba(250, 250, 250, 0) 100%
  );
  bottom: 0px;
  height: 60px;
  position: absolute;
  width: 100%;
`

export const ModelTag = styled(Aligner).attrs(() => ({
  align: 'center',
  justify: 'space-between',
  padding: '16px 16px 16px 24px',
}))<Props>`
  background: ${({ selected, theme }) =>
    selected ? theme.colors.surface.pressed : theme.colors.surface.default};
  border-radius: 15px;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  height: 72px;
`

export const RelativeDiv = styled.div`
  position: relative;
`

export const ScrollContainer = styled(Aligner).attrs(() => ({
  flex: 'column',
  gap: '32px',
  padding: '0 0 60px',
}))`
  height: calc(50vh + 2px);
  min-height: 473px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  }
`

export const Comment = styled(Body).attrs(() => ({
  type: 'copy4',
}))`
  overflow-wrap: break-word;
`
