// Models
import IStore, { IAction } from 'lib/redux/models'

// Libraries
import { combineReducers, Reducer } from 'redux'

// Misc
import assessmentAnamneseAnswerReducer from 'storage/assessmentAnamneseAnswer/duck'
import assessmentAnamneseTemplateReducer from 'storage/assessmentAnamneseTemplate/duck'
import assessmentPhysicalAssessmentReducer from 'storage/assessmentPhysicalAssessment/duck'
import athleteInfoReducer from 'storage/athleteInfo/duck'
import athleteOverviewReducer from 'storage/athleteOverview/duck'
import athletePlansReducer from 'storage/athletePlans/duck'
import athletesReducer from 'storage/athletes/duck'
import athleteScheduleReducer from 'storage/athleteSchedule/duck'
import authReducer from 'storage/auth/duck'
import exerciseReducer from 'storage/exercise/duck'
import financialReducer from 'storage/financial/duck'
import financialSubscriptionReducer from 'storage/financialSubscription/duck'
import generalReducer from 'storage/general/duck'
import profileReducer from 'storage/profile/duck'
import workoutModelReducer from 'storage/workoutModel/duck'
import workoutRoutineReducer from 'storage/workoutRoutine/duck'

const appReducer: Reducer<IStore, IAction<unknown>> = combineReducers({
  assessmentAnamneseAnswer: assessmentAnamneseAnswerReducer,
  assessmentAnamneseTemplate: assessmentAnamneseTemplateReducer,
  assessmentPhysicalAssessment: assessmentPhysicalAssessmentReducer,
  athleteInfo: athleteInfoReducer,
  athleteOverview: athleteOverviewReducer,
  athletePlans: athletePlansReducer,
  athletes: athletesReducer,
  athleteSchedule: athleteScheduleReducer,
  auth: authReducer,
  exercise: exerciseReducer,
  financial: financialReducer,
  financialSubscription: financialSubscriptionReducer,
  general: generalReducer,
  profile: profileReducer,
  workoutModel: workoutModelReducer,
  workoutRoutine: workoutRoutineReducer,
})

export default appReducer
