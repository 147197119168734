// Models
import { Props as BaseProps } from '../BaseBlock'

// React
import { FC } from 'react'

// Components
import BaseBlock from '../BaseBlock'
import { Aligner, InputText, TextArea } from 'heeds-ds'

type Props = Pick<BaseProps, 'name' | 'status' | 'margin' | 'dragRef'>

const TitleBlock: FC<Props> = ({ name, ...props }) => {
  return (
    <BaseBlock
      {...props}
      draggable={false}
      borderTop
      name={name}
      showOptions={false}
      type="title"
    >
      <Aligner flex="column" gap="2.4rem">
        <InputText
          displayError={false}
          label="Digite o título do formulário"
          margin="0"
          name={`${name}.title`}
          placeholder="Digite o nome do seu formulário"
          scale="small"
        />

        <TextArea
          displayError={false}
          label="Descrição"
          margin="0"
          name={`${name}.description`}
          placeholder="Você pode adicionar uma descrição se for necessário ao seu formulário"
          scale="small"
        />
      </Aligner>
    </BaseBlock>
  )
}

export default TitleBlock
