// Libraries
import * as Dropdown from '@radix-ui/react-dropdown-menu'
import styled, { css } from 'styled-components'

type ItemProps = {
  active?: boolean
  color?: string
  disabled?: boolean
}

export const Content = styled(Dropdown.Content)`
  min-width: 11rem;
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 4px;
  padding: 0;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  animation-name: slideDownAndFade;
  animation-duration: 500ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

export const Item = styled(Dropdown.Item)<ItemProps>`
  width: 100%;
  min-height: 4rem;
  padding: 1.2rem 1.6rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  user-select: none;
  outline: none;
  transition: all 0.1s ease-in-out;
  ${({ active, color, disabled, theme }) => css`
    background: ${active
      ? theme.colors.action.highlightSofblue
      : disabled
      ? theme.colors.surface.disabled
      : 'none'};
    color: ${color || theme.colors.text[active ? 'secondary' : 'default']};
    font-family: ${theme.fontFamily.roboto};
    font-size: ${theme.fontSize.copy4};
    font-weight: ${theme.fontWeight[active ? 'semiBold' : 'medium']};
    opacity: ${disabled ? '0.5' : '1'};

    :hover {
      background: ${theme.colors.badge.purpleBackground};
      color: ${color || theme.colors.text.secondary};
    }
  `};
`
