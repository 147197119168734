// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body, Button, IconButton, InputText } from 'heeds-ds'

export const Break = styled.hr`
  background-color: ${({ theme }) => theme.colors.border.input};
  border: none;
  height: 0.1rem;
  margin: 3.2rem 0;
  width: 100%;
`

export const Container = styled.form`
  padding: 5.4rem 0 5.6rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 3.2rem;
    width: 47rem;
  }
`

export const Content = styled(Aligner).attrs(() => ({
  flex: 'column',
}))`
  padding: 3.2rem 2.4rem 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 1.6rem 0rem 0;
  }
`

export const CopyButton = styled(IconButton).attrs(() => ({
  iconName: 'contentCopy',
  margin: '0',
  size: 'small',
}))``

export const Description = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.subdued,
  margin: '0 0 2.4rem',
  type: 'copy3',
}))`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 2rem;
    line-height: normal;
  }
`

export const Footer = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.subdued,
  margin: '0 0 2.4rem',
  type: 'copy3',
}))`
  margin: 0 auto;
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0 1.6rem 1.6rem;
  position: relative;

  ::after {
    background-color: ${({ theme }) => theme.colors.icon.disabled};
    content: '';
    height: 1.5px;
    opacity: 0.3;
    position: absolute;
    top: 100%;
    width: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    justify-content: flex-start;
    padding: 0;

    ::after {
      content: none;
    }
  }
`

export const InviteButton = styled(Button).attrs(() => ({
  margin: '1.2rem 0 4.4rem',
  variation: 'outlined',
  width: '100%',
}))``

export const LinkContainer = styled.div`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: 8px;
  display: flex;
  padding: 0.8rem 1.6rem;
  width: 100%;
`

export const LinkRow = styled.div`
  align-items: center;
  display: flex;
  gap: 2.4rem;
  margin: 1.2rem 0 4.4rem;
  padding: 0.8rem 0;
  width: 100%;
`

export const LinkText = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.subdued,
  type: 'copy4',
}))``

export const Title = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.secondary,
  type: 'copy2',
  weight: 600,
}))`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    color: ${({ theme }) => theme.colors.text.default};
    font-size: 2.4rem;
  }
`

export const InputName = styled(InputText).attrs(() => ({
  label: 'Nome do Aluno',
  margin: '0',
  name: 'name',
  placeholder: 'Digite aqui o nome do seu aluno',
  scale: 'small',
}))``

export const ItalicText = styled(Body).attrs(() => ({
  type: 'copy4',
  weight: '400',
}))`
  color: ${({ theme }) => theme.colors.text.subdued};
  font-style: italic;
  position: absolute;
  left: 10rem;
`

export const InputNameContainer = styled.div`
  position: relative;
`
