// Models
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'

// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { useMediaQuery, useModal } from 'hooks'

// Components
import * as Styled from './styled'
import { Button } from 'heeds-ds'
import { Modal } from 'components/modals'
import { RoutineInfo } from 'components'

type Props = {
  workoutRoutine?: IWorkoutRoutine
}

const ModalRoutineInfoMobile: FC<Props> = ({ workoutRoutine }) => {
  const { breakpoints } = useContext(ThemeContext)
  const { closeModal } = useModal()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)

  return (
    <Modal>
      <Styled.Container data-testid="modal-routine-info-container">
        {isDesktop && (
          <Styled.Header>
            <Styled.Title>Informações da rotina</Styled.Title>
            <Styled.Description>
              Você pode editar esses dados depois.
            </Styled.Description>
          </Styled.Header>
        )}

        {workoutRoutine && <RoutineInfo workoutRoutine={workoutRoutine} />}

        <Styled.FooterActions>
          <Button
            margin="0"
            onClick={closeModal}
            size={isDesktop ? 'xsmall' : 'small'}
            track={buttonClickTracking}
            trackName="close_routine_info_modal"
            width={isDesktop ? 'fit-content' : '100%'}
          >
            Entendi
          </Button>
        </Styled.FooterActions>
      </Styled.Container>
    </Modal>
  )
}

export default ModalRoutineInfoMobile
