// Libraries
import styled, { css } from 'styled-components'

// Components
import { Aligner, Body, Subtitle } from 'heeds-ds'

export const Container = styled(Aligner).attrs({
  align: 'center',
  flex: 'column',
})`
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.desktopSmall}px) {
    padding: 0 1.6rem 6.4rem;
  }
`

export const Form = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 0 6.4rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopSmall}px) {
    padding: 0;
  }
`

export const Image = styled.img`
  height: 6rem;
  margin: 0 0 3.2rem;
  object-fit: contain;
  width: 6rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktopSmall}px) {
    height: 9.8rem;
    width: 9.8rem;
  }
`

export const MainTitle = styled(Subtitle).attrs(() => ({
  align: 'center',
  type: 'sh1',
}))`
  ${({ theme }) => css`
    color: ${theme.colors.text.default};
    font-weight: ${theme.fontWeight.semiBold};
    margin: 0 0 5.6rem;

    @media screen and (min-width: ${theme.breakpoints.desktopSmall}px) {
      font-family: ${theme.fontFamily.barlow};
      font-size: ${theme.fontSize.h1};
      line-height: ${theme.lineHeight.h1};
      margin: 0 0 4rem;
    }
  `}
`

export const MainText = styled(Subtitle).attrs(() => ({
  align: 'center',
  type: 'sh3',
}))`
  ${({ theme }) => css`
    color: ${theme.colors.text.subdued};

    @media screen and (max-width: ${theme.breakpoints.desktopSmall}px) {
      font-size: ${theme.fontSize.copy1};
    }
  `}
`

export const SubText = styled(Body).attrs(() => ({
  align: 'center',
  type: 'copy2',
  margin: '0 0 5.6rem',
}))`
  ${({ theme }) => css`
    color: ${theme.colors.text.subdued};

    @media screen and (min-width: ${theme.breakpoints.desktopSmall}px) {
      font-size: ${theme.fontSize.copy1};
    }
  `}
`

export const InputButtonContainer = styled(Aligner).attrs({
  flex: 'column',
  align: 'center',
  width: '100%',
})`
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopSmall}px) {
    max-width: 40.2rem;
  }
`
