// Models
import { TMuscleGroupFilter } from 'models'

// React
import { FC, useLayoutEffect, useState } from 'react'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { useModal } from 'hooks'

// Components
import * as Styled from './styled'
import { Modal } from 'components/modals'

type Props = {
  filters: TMuscleGroupFilter[]
  handleClose?: () => void
  onChange: (filters: TMuscleGroupFilter[]) => void
}

const ModalMuscleGroups: FC<Props> = ({ filters, handleClose, onChange }) => {
  const { closeModal } = useModal()
  const [stateFilters, setStateFilters] = useState(filters)

  const onClickFilter = (label: string) => {
    setStateFilters((oldFilters) =>
      oldFilters.map((filter) => {
        if (filter.label === label) return { ...filter, active: !filter.active }
        return filter
      }),
    )
  }

  const onSave = () => {
    onChange(stateFilters)
    if (handleClose) return handleClose()
    closeModal()
  }

  useLayoutEffect(() => {
    setStateFilters(filters)
  }, [filters])

  return (
    <Modal onClose={handleClose}>
      <Styled.Container data-testid="modal-muscle-group-container">
        <Styled.Header>
          <Styled.Title>Grupos Musculares</Styled.Title>
        </Styled.Header>

        <Styled.Content>
          <Styled.Description>
            Selecione os grupos musculares desejados:
          </Styled.Description>

          <Styled.Filters>
            {stateFilters.map((filter) => {
              return (
                <Styled.FilterButton
                  active={filter.active}
                  data-testid={`modal-muscle-group-filter-${filter.label}`}
                  key={filter.label}
                  onClick={() => onClickFilter(filter.label)}
                >
                  {filter.label}
                </Styled.FilterButton>
              )
            })}
          </Styled.Filters>

          <Styled.SaveButton
            data-testid="modal-muscle-group-save-button"
            onClick={onSave}
            track={buttonClickTracking}
            trackName="update_muscle_group_filters"
          >
            Salvar
          </Styled.SaveButton>
        </Styled.Content>
      </Styled.Container>
    </Modal>
  )
}

export default ModalMuscleGroups
