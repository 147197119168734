// Libraries
import styled from 'styled-components'

type InputWrapperProps = {
  color?: string
  disabled?: boolean
  scale: 'small' | 'medium' | 'large'
}

type ContainerProps = {
  margin?: string
  width?: string
  justify?: string
}

enum EFontSizes {
  small = 1.4,
  medium = 1.6,
  large = 1.8,
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => (justify ? justify : 'space-between')};
  margin: ${({ margin }) => margin || '1.6rem 0'};
  position: relative;
  width: ${({ width }) => width || '100%'};
`

export const Hint = styled.p<InputWrapperProps>`
  color: ${({ color, theme }) => (color ? color : theme.colors.text.default)};
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: ${({ scale }) => EFontSizes[scale] - 0.2}rem;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  height: ${({ scale }) => EFontSizes[scale] - 0.2}rem;
  margin: 0.6rem 0 0 0;
`

export const Label = styled.label<InputWrapperProps>`
  margin: 0 0 0.8rem 0;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.text.disabled : theme.colors.text.default};
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: ${({ scale }) => EFontSizes[scale]}rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  display: flex;
`
