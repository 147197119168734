// react
import { FC, useContext, useEffect, useState } from 'react'

// Libs
import { ThemeContext } from 'styled-components'
import { useController, useFormContext } from 'react-hook-form'

// Misc
import useContentScroll from '../../../hooks/useContentScroll'

// Components
import {
  CreatableAutocomplete,
  SimpleAutocomplete,
} from '../@primitives/Autocomplete'
import { InputWrapper } from '../'

enum EFieldStates {
  default = 'default',
  error = 'critical',
  focus = 'focused',
  success = 'success',
}

type Option = {
  label: string
  value: string
}

type Props = {
  creatable?: boolean
  displayError?: boolean
  disabled?: boolean
  label?: string
  margin?: string
  name: string
  onMenuScrollToBottom?: () => void
  options?: Option[]
  placeholder?: string
  scale?: 'small' | 'medium' | 'large'
  width?: string
}

const Autocomplete: FC<Props> = ({
  creatable,
  displayError = true,
  disabled = false,
  label = '',
  margin = '',
  name,
  onMenuScrollToBottom,
  options = [],
  placeholder = 'Selecione uma opção:',
  scale = 'medium',
  width,
}) => {
  const theme = useContext(ThemeContext)
  useContentScroll(updateElementDistance)

  const [menuOpen, setMenuOpen] = useState(false)
  const [elementDistanceToBottom, setElementDistanceToBottom] = useState(250)
  const [fieldState, setFieldState] = useState(EFieldStates.default)
  const contentPosition = elementDistanceToBottom < 240 ? 'top' : 'bottom'

  const { control } = useFormContext()
  const {
    field: { value, onChange },
    fieldState: { isDirty, error },
  } = useController({ control, name })

  const COLORS_SCHEMA = {
    critical: theme.colors.text.critical,
    default: theme.colors.border.input,
    focused: theme.colors.text.default,
    success: theme.colors.text.success,
  }

  const props = {
    borderColor: COLORS_SCHEMA[fieldState],
    classNamePrefix: name.replaceAll(/\./g, '-'),
    disabled: disabled,
    formatCreateLabel: (value: string) => value,
    id: name,
    menuIsOpen: menuOpen,
    options: options,
    onChange: (selected: unknown) => onChange((selected as Option).value),
    onMenuOpen: () => setMenuOpen(true),
    onMenuClose: () => setMenuOpen(false),
    placeholder: placeholder,
    prefix: name.replaceAll(/\./g, '-'),
    scale: scale,
    value: options.find((el) => el.value === value),
  }

  function updateElementDistance() {
    if (menuOpen) {
      const elementYPos =
        document.getElementById(name)?.getBoundingClientRect().bottom ?? 0

      setElementDistanceToBottom(window.innerHeight - elementYPos - 20)
    }
  }

  useEffect(() => {
    switch (true) {
      case !!error:
        setFieldState(EFieldStates.error)
        break
      case isDirty:
        setFieldState(EFieldStates.success)
        break
      default:
        setFieldState(EFieldStates.default)
        break
    }
  }, [error, isDirty])

  return (
    <InputWrapper
      color={COLORS_SCHEMA[fieldState]}
      disabled={disabled}
      displayError={displayError}
      error={error?.message}
      margin={margin}
      label={label}
      size={scale}
      width={width}
    >
      {creatable ? (
        <CreatableAutocomplete {...props} position={contentPosition} />
      ) : (
        <SimpleAutocomplete
          {...props}
          onMenuScrollToBottom={onMenuScrollToBottom}
          position={contentPosition}
          noOptionsMessage={() => (
            <div className="rs_no-options">Sem opções</div>
          )}
        />
      )}
    </InputWrapper>
  )
}

export default Autocomplete
