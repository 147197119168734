// Libraries
import styled from 'styled-components'

// Components
import { Body } from 'heeds-ds'

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 1.5rem;
  box-shadow: 0px 2px 5px 0px rgba(43, 42, 99, 0.12);
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin: 0 2.4rem;
  padding: 2.4rem 1.6rem;
`

export const Creator = styled(Body).attrs(({ theme }) => ({
  color: theme.colors.text.subdued,
  type: 'copy3',
  weight: 600,
}))``

export const Title = styled(Body).attrs(({ theme }) => ({
  color: theme.colors.text.secondary,
  type: 'copy2',
  weight: 700,
}))``
