// React
import { ReactNode } from 'react'

// Libraries
import InfiniteScroll from 'react-infinite-scroll-component'

// Components
import * as Styled from './styled'

export type Props<T> = {
  bottomDistance?: string
  columns: string[]
  columnsFormat?: string
  columnsGap?: string
  data: T[]
  hasMore: boolean
  height?: string
  loaderComponent?: ReactNode | ReactNode[]
  onEndReached: () => void
  padding?: string
  renderItem: (item: T) => ReactNode
  rowsGap?: string
  scrollableDiv?: string
  showHeader?: boolean
}

const CommonList = <T extends object>({
  bottomDistance,
  columns,
  columnsFormat,
  columnsGap,
  data,
  hasMore,
  height,
  loaderComponent,
  onEndReached,
  padding,
  renderItem,
  rowsGap,
  scrollableDiv = 'scrollableDiv',
  showHeader = true,
}: Props<T>) => {
  return (
    <Styled.InfiniteScrollContainer
      bottomDistance={bottomDistance}
      id="scrollableDiv"
      rowsGap={rowsGap}
    >
      {showHeader && (
        <Styled.Header columnsFormat={columnsFormat} columnsGap={columnsGap}>
          {columns.map((title) => (
            <Styled.Title key={title} padding={padding}>
              {title}
            </Styled.Title>
          ))}
        </Styled.Header>
      )}
      <InfiniteScroll
        className="infine-scroll"
        dataLength={data.length}
        next={onEndReached}
        hasMore={hasMore}
        loader={loaderComponent}
        scrollThreshold="200px"
        scrollableTarget={scrollableDiv}
        height={height}
      >
        {data.map((item) => renderItem(item))}
      </InfiniteScroll>
    </Styled.InfiniteScrollContainer>
  )
}

export default CommonList
