// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body } from 'heeds-ds'

export const Container = styled(Aligner).attrs(() => ({
  flex: 'column',
  gap: '2.4rem',
  padding: '2.4rem',
}))`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 4rem 4.8rem 0 2.4rem;
  }
`

export const ContentFilters = styled(Aligner).attrs(() => ({
  flex: 'column',
  gap: '1rem',
  margin: '0.8rem 0 0',
  width: 'fit-content',
}))`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-direction: row;
  }
`

export const Title = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.secondary,
  margin: '0 0 0.6rem',
  type: 'copy3',
  weight: 600,
}))``
