// Models
import { IAthleteInfoState } from 'storage/athleteInfo/models'
import { IExerciseData } from 'storage/exercise/models'
import { IModelInput, TDownloadPDFModel, TRemoveModel } from 'models'
import { IWorkoutModelData } from 'storage/workoutModel/models'
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'
import IStore from 'lib/redux/models'

// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

//Misc
import { buttonClickTracking } from 'utils/tracking'

// Libraries
import { useSelector } from 'react-redux'

// Components
import * as Blocks from 'blocks/dashboard/workout'
import * as Styled from './styled'
import { Aligner, Icon } from 'heeds-ds'
import { HeaderWorkoutRoutine, PdfDownloadButton, WorkoutPDF } from 'components'

type Props = {
  downloadPDFModel: TDownloadPDFModel
  goBack: () => void
  handleAddModel: () => void
  handleEditModel: (modelId: number) => void
  onRemoveModel: TRemoveModel
  openExerciseGifModal: (exercise: IExerciseData) => void
  selectedModel: number
  setSelectedModel: (modelId: number) => void
  stateModels?: IModelInput[]
  workoutModels?: IWorkoutModelData[]
  workoutRoutine?: IWorkoutRoutine
}

const Desktop: FC<Props> = ({
  goBack,
  handleAddModel,
  openExerciseGifModal,
  selectedModel,
  stateModels,
  workoutModels,
  workoutRoutine,
  ...workoutRoutineInfoProps
}) => {
  const { info } = useSelector<IStore, IAthleteInfoState>(
    (state) => state.athleteInfo,
  )
  const theme = useContext(ThemeContext)

  const fileName = `${info?.name}-treino`
  const hasModels = stateModels && stateModels.length > 0

  return (
    <Aligner flex="column" gap="24px" padding="24px">
      <Styled.GoBackButton
        onClick={goBack}
        track={buttonClickTracking}
        trackName="navigate_to_athlete_routines"
      >
        <Icon iconName="arrowBack" color={theme.colors.interactive.default} />
        Voltar para rotinas atuais
      </Styled.GoBackButton>

      <Aligner flex="column" gap="32px">
        <Aligner gap="24px">
          <HeaderWorkoutRoutine />

          <Aligner width="fit-content">
            {workoutRoutine && (
              <PdfDownloadButton
                fileName={fileName}
                PDF={
                  <WorkoutPDF
                    data={{
                      routine: workoutRoutine || {},
                      workouts: workoutModels,
                    }}
                  />
                }
                size="small"
              />
            )}
          </Aligner>
        </Aligner>

        <Styled.ContentPage>
          <Styled.LeftColumn>
            <Blocks.WorkoutRoutineInfo
              {...workoutRoutineInfoProps}
              handleAddModel={handleAddModel}
              selectedModel={selectedModel}
              workoutModels={workoutModels}
              workoutRoutine={workoutRoutine}
            />
          </Styled.LeftColumn>

          <Styled.RightColumn>
            {hasModels && (
              <Blocks.WorkoutRoutineSets
                openExerciseGifModal={openExerciseGifModal}
                selectedModel={selectedModel}
                workoutModels={stateModels}
              />
            )}
          </Styled.RightColumn>

          {!hasModels && (
            <Styled.EmptyModels>
              <Styled.ZigZagArrow>
                <Styled.EmptyText>
                  Você precisa adicionar um treino para essa rotina.
                </Styled.EmptyText>

                <Styled.AddModelButton
                  onClick={handleAddModel}
                  track={buttonClickTracking}
                  trackName="navigate_to_create_or_edit_workout_models"
                >
                  <Icon
                    iconName="add"
                    color={theme.colors.interactive.default}
                  />
                  Adicionar Treino
                </Styled.AddModelButton>
              </Styled.ZigZagArrow>
            </Styled.EmptyModels>
          )}
        </Styled.ContentPage>
      </Aligner>
    </Aligner>
  )
}

export default Desktop
