// Libraries
import styled from 'styled-components'

export const Image = styled.img`
  border-top: 1px solid ${({ theme }) => theme.colors.border.input};
  padding: 2.4rem 3.2rem 8rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    min-height: 37.7rem;
    padding: 1.6rem 3.2rem;
    width: 50rem;
  }
`
