export enum EGeneralActionTypes {
  CLEANUP = 'GENERAL/CLEANUP',
  TRIGGER_TOAST = 'GENERAL/TRIGGER_TOAST',
}

export interface IGeneralState {
  toast?: IToastState
}

/* TOAST */
export type TToastVariations = 'success' | 'warning' | 'info' | 'error'

export interface IToastState {
  customTitle?: string
  actionLink?: string
  actionMessage?: string
  message?: string
  time?: number
  type?: TToastVariations
}
