// Models
import { FieldType } from 'components/WorkoutSetFormTag'
import { IExerciseData } from 'storage/exercise/models'
import {
  IFormInputs,
  IModelInput,
  IWorkoutSetInput,
  TFormValues,
  TMuscleGroupFilter,
  TSetSpecialSet,
  TSpecialSetInput,
} from 'models'

// React
import { FC, Fragment, useMemo } from 'react'

// Libraries
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { useModal } from 'hooks'

// Components
import * as Styled from './styled'
import {
  WorkoutModelsMobileHome,
  WorkoutModelsMobileSets,
} from 'views/dashboard/workout'

type Props = {
  addExerciseToWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    exercise: IExerciseData,
  ) => void
  addOrRemoveFieldFromWorkoutSetToExercise?: (
    modelId: string,
    workoutSetId: string,
    exerciseIndex: number,
    field: FieldType,
    add?: boolean,
  ) => void
  addWorkoutModel: (model: IModelInput) => void
  addWorkoutSet: (modelId: string, set: IWorkoutSetInput) => void
  changePage: (page: string) => void
  duplicateWorkoutModel: () => void
  filters: TMuscleGroupFilter[]
  inputValue: string
  loadMoreExercises: () => void
  methods: UseFormReturn<IFormInputs, TFormValues>
  mobilePage?: string | null
  onChangeFilters: (filters: TMuscleGroupFilter[]) => void
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  removeExerciseFromWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    exerciseIndex: number,
  ) => void
  removeFilter: (label: string) => void
  removeWorkoutSet: (modelId: string, workoutSetId: string) => void
  replicateWorkoutSetFields: (modelId: string, set: IWorkoutSetInput) => void
  selectedModelIndex: number
  setSpecialSet: TSetSpecialSet
  specialSet?: TSpecialSetInput
  stateModels: IModelInput[]
  updateWorkoutModel: (id: string, updatedModel: IModelInput) => void
  updateWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    updatedWorkoutSet: IWorkoutSetInput,
  ) => void
}

const Mobile: FC<Props> = ({
  addExerciseToWorkoutSet,
  addOrRemoveFieldFromWorkoutSetToExercise,
  addWorkoutModel,
  addWorkoutSet,
  duplicateWorkoutModel,
  methods,
  mobilePage,
  removeExerciseFromWorkoutSet,
  removeWorkoutSet,
  replicateWorkoutSetFields,
  setSpecialSet,
  specialSet,
  stateModels,
  updateWorkoutSet,
  ...commonProps
}) => {
  const { openModal } = useModal()
  const navigate = useNavigate()

  const {
    formState: { isValid },
  } = methods

  const isDisabled = specialSet !== undefined

  const reviewButtonIsDisabled =
    isDisabled ||
    (!isValid &&
      (!stateModels[0].name ||
        !Object.values(stateModels[0].workout_set).length))

  const hasEmptyModels = useMemo(
    () =>
      stateModels.some((model) =>
        Boolean(Object.values(model.workout_set ?? {}).length === 0),
      ),
    [stateModels],
  )

  const emptyFunction = () => undefined

  const renderPage = useMemo(() => {
    switch (mobilePage?.toString()) {
      case '2':
        return (
          <WorkoutModelsMobileSets
            {...commonProps}
            addExerciseToWorkoutSet={addExerciseToWorkoutSet}
            addOrRemoveFieldFromWorkoutSetToExercise={
              addOrRemoveFieldFromWorkoutSetToExercise
            }
            addWorkoutSet={addWorkoutSet}
            removeExerciseFromWorkoutSet={removeExerciseFromWorkoutSet}
            removeWorkoutSet={removeWorkoutSet}
            replicateWorkoutSetFields={replicateWorkoutSetFields}
            setSpecialSet={setSpecialSet}
            specialSet={specialSet}
            stateModels={stateModels}
            updateWorkoutSet={updateWorkoutSet}
          />
        )
      default:
        return (
          <WorkoutModelsMobileHome
            {...commonProps}
            addWorkoutModel={addWorkoutModel}
            addWorkoutSet={addWorkoutSet}
            duplicateWorkoutModel={duplicateWorkoutModel}
            removeWorkoutModel={emptyFunction}
            renameWorkoutModel={emptyFunction}
            selectWorkoutModel={emptyFunction}
            setSpecialSet={setSpecialSet}
            stateModels={stateModels}
            visibleHeader={false}
          />
        )
    }
  }, [
    addExerciseToWorkoutSet,
    addOrRemoveFieldFromWorkoutSetToExercise,
    addWorkoutModel,
    addWorkoutSet,
    commonProps,
    duplicateWorkoutModel,
    mobilePage,
    removeExerciseFromWorkoutSet,
    removeWorkoutSet,
    replicateWorkoutSetFields,
    setSpecialSet,
    specialSet,
    stateModels,
    updateWorkoutSet,
  ])

  return (
    <Fragment>
      <FormProvider {...methods}>
        {renderPage}
        <Styled.ButtonContainer>
          <Styled.CancelButton
            disabled={isDisabled}
            onClick={() => navigate(-1)}
            track={buttonClickTracking}
            trackName="navigate_to_athlete_routines"
          >
            Cancelar
          </Styled.CancelButton>

          <Styled.NextButton
            onClick={() =>
              openModal(hasEmptyModels ? 'empty-model' : 'go-to-review-modal')
            }
            disabled={reviewButtonIsDisabled}
            track={buttonClickTracking}
            trackName="update_state_and_navigate_to_workout_review"
          >
            Ir para revisão
          </Styled.NextButton>
        </Styled.ButtonContainer>
      </FormProvider>
    </Fragment>
  )
}

export default Mobile
