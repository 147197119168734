import { Aligner } from 'heeds-ds'
import styled from 'styled-components'

export const Container = styled(Aligner).attrs(() => ({
  padding: '2.4rem',
  gap: '3.2rem',
  flex: 'column',
}))`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    gap: 2.4rem;
    padding: 4rem 4.8rem 2.4rem 4rem;
  }
`
