// Models
import {
  IWorkoutListModelPayload,
  IWorkoutListModelResponse,
  IWorkoutCreateModelPayload,
  IWorkoutGetModelPayload,
  IWorkoutUpdateModelPayload,
  IWorkoutDeleteModelPayload,
  IWorkoutModelPayload,
  IWorkoutCreateModelTemplatePayload,
} from './@types'
import { IWorkoutModelData } from 'storage/workoutModel/models'

// Misc
import api from 'lib/axios/api'

export const listWorkoutModel = async (
  formData: IWorkoutListModelPayload,
): Promise<IWorkoutListModelResponse> => {
  const { routine_pk, ...params } = formData

  try {
    const response = await api.get(`workouts/routine/${routine_pk}/model/`, {
      params,
    })
    const data: IWorkoutListModelResponse = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const createWorkoutModel = async (
  formData: IWorkoutCreateModelPayload,
): Promise<IWorkoutModelData> => {
  const { routine_pk, ...restForm } = formData
  try {
    const response = await api.post(
      `workouts/routine/${routine_pk}/model/`,
      restForm,
    )
    const data: IWorkoutModelData = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const createModel = async (
  payload: IWorkoutCreateModelTemplatePayload,
): Promise<IWorkoutCreateModelTemplatePayload> => {
  try {
    const response = await api.post(`workouts/models/`, payload)
    const data: IWorkoutModelPayload = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const getListModel = async (
  formData: IWorkoutListModelPayload,
): Promise<IWorkoutModelData> => {
  try {
    const response = await api.get(`workouts/models/`, { params: formData })
    const data: IWorkoutModelData = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const getWorkoutModel = async (
  formData: IWorkoutGetModelPayload,
): Promise<IWorkoutModelData> => {
  const { model_pk } = formData
  try {
    const response = await api.get(`workouts/model/${model_pk}/`)
    const data: IWorkoutModelData = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const updateWorkoutModel = async (
  formData: IWorkoutUpdateModelPayload,
): Promise<IWorkoutModelData> => {
  const { model_pk, ...restForm } = formData
  try {
    const response = await api.put(`workouts/model/${model_pk}/`, restForm)
    const data: IWorkoutModelData = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const deleteWorkoutModel = async (
  formData: IWorkoutDeleteModelPayload,
): Promise<IWorkoutModelData> => {
  const { model_pk } = formData
  try {
    const response = await api.delete(`workouts/model/${model_pk}/`)

    const data: IWorkoutModelData = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}
