// Models
import { IFilter } from 'heeds-ds/src/components/Inputs/InputSearch'

// React
import { FC, useContext, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { useModal } from 'hooks'
import useMediaQuery from 'heeds-ds/src/hooks/useMediaQuery'

// Components
import * as Styled from './styled'
import { Aligner, Body, Button, Icon, InputSearch } from 'heeds-ds'
import { ModalInviteAthlete } from 'components'

// Constants
const FILTERS = [
  { label: 'Presencial', param: 'plan_model', value: 'in_person' },
  { label: 'Consultoria', param: 'plan_model', value: 'online' },
  { label: 'Ativo', param: 'subscription_status', value: 'active' },
  { label: 'Desativado', param: 'subscription_status', value: 'inactive' },
  { label: 'Convidado', param: 'subscription_status', value: 'invited' },
  { label: 'Mensal', param: 'plan_periodicity', value: 'monthly' },
  { label: 'Trimestral', param: 'plan_periodicity', value: 'quarterly' },
  { label: 'Semestral', param: 'plan_periodicity', value: 'semiannual' },
  { label: 'Anual', param: 'plan_periodicity', value: 'yearly' },
]

const WIDE_SCREEN_WIDTH = 1150

type Props = {
  addAthlete?: () => void
  athletesCount?: string
  hasSelectedFilters?: boolean
  isFilterSelected: (name: string, value: string) => boolean
  onClickFilter: (filter: IFilter) => void
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  searchQuery?: string
}

const Header: FC<Props> = ({
  addAthlete,
  athletesCount,
  hasSelectedFilters,
  isFilterSelected,
  onClickFilter,
  onSearchChange,
  searchQuery = '',
}: Props) => {
  const { isVisible, openModal } = useModal()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)
  const isWideScreen = useMediaQuery(`(min-width: ${WIDE_SCREEN_WIDTH}px)`)

  const [showFilterList, setShowFilterList] = useState(hasSelectedFilters)

  const showInviteModal = () => openModal('invite-athlete')

  const searchFilters = FILTERS

  const addButtonProps = {
    onClick: addAthlete,
    track: buttonClickTracking,
    trackName: 'navigate_to_create_athlete',
  }

  const shareButtonProps = {
    onClick: showInviteModal,
    track: buttonClickTracking,
    trackName: 'open_modal_athlete_invite',
  }

  return (
    <Styled.Container>
      <Aligner width="100%" align="flex-start" justify="space-between">
        <Styled.FilterContainer>
          <Aligner justify="space-between">
            <InputSearch
              margin="0"
              onChange={onSearchChange}
              onClick={() => setShowFilterList((prev) => !prev)}
              placeholder="Pesquisar alunos"
              scale="small"
              value={searchQuery}
              width={isDesktop ? '375px' : '100%'}
            />

            {isDesktop && (
              <Aligner
                justify="flex-end"
                align="flex-start"
                gap="2.4rem"
                width="content-fit"
              >
                {isWideScreen >= isDesktop ? (
                  <Button margin="0" size="small" {...addButtonProps}>
                    <Icon
                      iconName="add"
                      color={theme.colors.background.default}
                    />
                    Adicionar novo aluno
                  </Button>
                ) : (
                  <Styled.AddIconButton size="large" {...addButtonProps} />
                )}

                <Styled.ShareIconButton size="large" {...shareButtonProps} />
              </Aligner>
            )}
          </Aligner>

          {searchFilters && showFilterList && (
            <Styled.Modal>
              {searchFilters.map((filter) => {
                const active = isFilterSelected?.(
                  filter.param,
                  filter.value.toString(),
                )

                return (
                  <Styled.Item
                    data-testid={'filter-' + filter.label}
                    key={filter.value}
                    onClick={() => onClickFilter(filter)}
                    active={active}
                  >
                    {filter.label}
                  </Styled.Item>
                )
              })}
            </Styled.Modal>
          )}
        </Styled.FilterContainer>
      </Aligner>

      {!isDesktop && (
        <Aligner align="center" gap="2.4rem">
          <Body
            type="copy3"
            color={theme.colors.text.secondary}
            weight={700}
            margin="0 24px 0 0"
          >
            Alunos
          </Body>
          <Body type="copy5" color={theme.colors.text.subdued}>
            {athletesCount}
          </Body>

          <Styled.AddIconButton {...addButtonProps} />

          <Styled.ShareIconButton {...shareButtonProps} />
        </Aligner>
      )}

      {isVisible === 'invite-athlete' && <ModalInviteAthlete />}
    </Styled.Container>
  )
}

export default Header
