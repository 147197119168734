// Models
import { useFormContext } from 'react-hook-form'

// React
import { FC, useContext, useEffect, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { BRL } from 'lib/currency'
import { buttonClickTracking } from 'utils/tracking'
import { useMediaQuery } from 'heeds-ds/src/hooks'

// Components
import { Aligner, Body, Button, InputText } from 'heeds-ds'
import {
  Line,
  ValueButton,
} from 'views/dashboard/athlete/AthleteFinancial/styled'

interface Props {
  extraWorkoutPrice?: number
}

enum EPlanModelMultiply {
  monthly = 1,
  quarterly = 3,
  semiannual = 6,
  yearly = 12,
}

enum EDisplayValue {
  default_monthly = 'Consultoria online Mensal',
  default_quarterly = 'Consultoria online Trimestral',
  default_semiannual = 'Consultoria online Semestral',
  default_yearly = 'Consultoria online Anual',
  custom_monthly = 'Plano Personalizado online Mensal',
  custom_quarterly = 'Plano Personalizado Trimestral',
  custom_semiannual = 'Plano Personalizado online Semestral',
  custom_yearly = 'Plano Personalizado online Anual',
}

const Values: FC<Props> = ({ extraWorkoutPrice = 0 }) => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)
  const [discount, setDiscount] = useState(0)
  const [totalValue, setTotalValue] = useState(0)

  const { watch, setError, setValue } = useFormContext()
  const plan = watch('selected_plan')
  const initialDiscount = watch('discount')
  const frequency = watch('frequency')

  const mainPrice =
    plan?.label === 'hourly_rate'
      ? frequency
        ? plan?.value * frequency
        : plan?.value
      : plan?.value

  const planMultiply = Number(
    EPlanModelMultiply[watch('selected_plan.periodicity')],
  )

  const validDiscount = BRL(watch('discount')).value <= watch('value')

  const handleDiscount = () => {
    const initialDiscount = BRL(watch('discount')).value
    if (discount > 0) {
      setDiscount(0)
      setValue('value', watch('value') + initialDiscount)
      setValue('discount', 0)
    } else {
      if (validDiscount) {
        setDiscount(initialDiscount)
        setValue('value', watch('value') - initialDiscount)
      } else {
        setError('discount', {
          type: 'custom',
          message:
            'Desconto deve ser menor ou igual que o valor total do plano',
        })
      }
    }
  }

  useEffect(() => {
    setTotalValue(extraWorkoutPrice + Number(mainPrice))
    setValue('value', totalValue - discount)
  }, [discount, extraWorkoutPrice, mainPrice, setValue, totalValue, watch])

  useEffect(() => {
    if (initialDiscount > 0 && BRL(watch('discount')).value <= watch('value')) {
      setDiscount(initialDiscount)
    } else {
      setDiscount(0)
    }
  }, [initialDiscount, setValue, totalValue, watch])

  return (
    <Aligner flex="column" width={isDesktop ? '570px' : '100%'}>
      <Aligner
        align="center"
        justify="space-between"
        margin="0 0 15px 0"
        padding="0 10px 0 0"
      >
        <Body type="copy1" weight={700}>
          Valor total das aulas{' '}
        </Body>
        <Body type="copy1" weight={700}>
          {BRL(totalValue || '0').format()}
        </Body>
      </Aligner>
      <Aligner
        align="center"
        justify="space-between"
        margin="15px 0"
        padding="0 10px 0 0"
        gap="30px"
      >
        <Aligner width="50%">
          <Body
            type="copy2"
            weight={500}
            color={theme.colors.text.disabled}
            align="left"
          >
            {watch('selected_plan.label') === 'hourly_rate' && frequency
              ? `${frequency}  ${frequency === '1' ? 'aula' : 'aulas'}/mês`
              : EDisplayValue[
                  watch('selected_plan.label')?.replace(
                    /\d+/,
                    '',
                  ) as keyof typeof EDisplayValue
                ]}
          </Body>
        </Aligner>
        <Body
          type="copy2"
          weight={500}
          color={theme.colors.text.disabled}
          align="right"
        >
          {BRL(mainPrice).format()}
        </Body>
      </Aligner>
      {extraWorkoutPrice ? (
        <Aligner
          align="center"
          justify="space-between"
          margin="15px 0"
          padding="0 10px 0 0"
        >
          <Body type="copy2" weight={500} color={theme.colors.text.disabled}>
            Treinos extras
          </Body>
          <Body type="copy2" weight={500} color={theme.colors.text.disabled}>
            {BRL(extraWorkoutPrice || '0').format()}
          </Body>
        </Aligner>
      ) : null}
      <Line />
      {discount ? (
        <Aligner
          align="center"
          justify="space-between"
          margin="15px 0"
          padding="0 10px 0 0"
        >
          <Body color={theme.colors.text.disabled} type="copy2" weight={500}>
            Desconto
          </Body>
          <Body color={theme.colors.text.critical} type="copy1" weight={700}>
            - {BRL(discount || '0').format()}
          </Body>
        </Aligner>
      ) : null}
      <Line />
      <Aligner
        align="center"
        justify="space-between"
        margin="15px 0 0 0"
        padding="0 10px 0 0"
      >
        <Body type="copy1" weight={700} color={theme.colors.text.secondary}>
          {discount ? 'Total com desconto' : 'Total'}
        </Body>
        <Body type="copy1" weight={700} color={theme.colors.text.secondary}>
          {BRL(watch('value')).format()}
        </Body>
      </Aligner>
      {watch('instalmentPayment') === undefined ||
      watch('instalmentPayment') === '0' ? null : (
        <Aligner
          align="center"
          justify="space-between"
          margin="8px 0 15px 0"
          padding="0 10px 0 0"
        >
          <Body weight={500} type="copy1" color={theme.colors.text.subdued}>
            Parcelamento
          </Body>
          <Body weight={500} type="copy1" color={theme.colors.text.subdued}>
            {`${planMultiply}x de ${BRL(
              watch('value') / planMultiply,
            ).format()}`}
          </Body>
        </Aligner>
      )}
      <Aligner
        align="center"
        justify="space-between"
        margin="15px 0"
        padding={isDesktop ? '0 80px 0 0' : '0 20px 0 0'}
      >
        <InputText
          placeholder="Digite o valor do desconto"
          scale="small"
          name="discount"
          margin="0 46px 0.2rem 0"
          displayError
          mask="currency"
          disabled={!!discount}
        />
        <ValueButton>
          <Button
            margin="0 0 20px 0"
            onClick={() => handleDiscount()}
            track={buttonClickTracking}
            trackName={`${discount ? 'remove' : 'apply'}_discount`}
            variation="borderless"
          >
            {discount ? 'Remover' : 'Aplicar'}
          </Button>
        </ValueButton>
      </Aligner>
    </Aligner>
  )
}

export default Values
