// Libraries
import styled, { css } from 'styled-components'

// Components
import { Aligner, IconButton } from 'heeds-ds'

interface ItemProps {
  active?: boolean
}

export const AddIconButton = styled(IconButton).attrs((props) => ({
  iconName: 'add',
  margin: '0 0 0 auto',
  size: props.size || 'small',
  variation: 'main',
}))``

export const FilterContainer = styled.div`
  margin-bottom: 22px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin: 0;
  }
`

export const Container = styled(Aligner)`
  flex-direction: column;
  padding: 0 2.4rem;
  margin-bottom: 2.4rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin: 4.2rem 0 3.6rem;
    padding: 0;
  }
`

export const ShareIconButton = styled(IconButton).attrs((props) => ({
  iconName: 'share',
  margin: '0',
  size: props.size || 'small',
  variation: 'main',
}))``

export const Modal = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  transition: height 0.5s;
  white-space: nowrap;

  ::-webkit-scrollbar {
    display: none;
  }
`

export const Item = styled.button<ItemProps>`
  align-items: center;
  cursor: pointer;
  justify-content: center;
  margin: 0;
  padding: 0.8rem 1.6rem;

  ${({ active, theme }) => css`
    background-color: ${theme.colors.icon[active ? 'default' : 'onPrimary']};
    border: 1px solid
      ${active ? theme.colors.icon.default : theme.colors.border.input};
    border-radius: 16px;
    color: ${theme.colors.text[active ? 'onPrimary' : 'default']};
    display: flex;
    font-family: ${theme.fontFamily.roboto};
    font-size: ${theme.fontSize.copy4};

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      :hover {
        background-color: ${theme.colors.icon.hovered};
        border: 1px solid ${theme.colors.icon.hovered};
        color: ${theme.colors.text.onPrimary};
      }
    }
  `};
`
