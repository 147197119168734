// React
import { FC, useContext } from 'react'

import { ThemeContext } from 'styled-components'

import { useMediaQuery } from 'heeds-ds/src/hooks'

// Components
import * as Styled from './styled'
import { Aligner, Skeleton } from 'heeds-ds'

const Loader: FC = () => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  return (
    <Styled.LoadContainer data-testid="row">
      {isDesktop ? (
        <>
          <Aligner gap="1.6rem" align="center">
            <Styled.Icon />
            <Skeleton width="15.0rem" height="1.6rem" />
          </Aligner>
          <Aligner gap="8rem" margin="0 0 0 5.5rem">
            <Skeleton width="30rem" height="1.6rem" />
            <Skeleton width="15rem" height="1.6rem" />
            <Skeleton width="10rem" height="1.6rem" margin="0 0 0 10rem" />
            <Skeleton width="5rem" height="1.6rem" margin="0 0 0 15rem" />
          </Aligner>
        </>
      ) : (
        <>
          <Aligner flex="column" gap="8px">
            <Skeleton width="12rem" height="1.6rem" margin="0 0 0.8rem" />
            <Skeleton width="20rem" height="1.6rem" />
            <Skeleton width="6rem" height="1.6rem" />
            <Skeleton width="7rem" height="1.6rem" />
          </Aligner>
        </>
      )}
    </Styled.LoadContainer>
  )
}

export default Loader
