// Libraries
import styled from 'styled-components'

export const ContentHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.input};
  padding: 11px 16px 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: 60px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: row;
    margin-bottom: 0;
    padding: 0 16px;
  }
`

export const SearchContainer = styled.div`
  padding: 16px 8px;
  position: absolute;

  top: 100%;
  left: 16px;
  right: 16px;
  align-self: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 375px;
    padding: 0;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
  }
`
