// Libraries
import * as yup from 'yup'

// Misc
import { testFullName } from './@utils'

export const authAthleteSignupSchema = yup.object().shape({
  name: yup
    .string()
    .required('Digite o nome')
    .test('fullName', 'Digite seu nome completo', testFullName),
  email: yup.string().email('E-mail inválido').required('Digite o e-mail'),
  password: yup.string().required(''),
  readAndAccepted: yup.boolean().required('').isTrue(),
})

export const authForgotPasswordSchema = yup.object().shape({
  password: yup.string().required('Digite uma senha'),
})

export const authLoginSchema = yup.object().shape({
  email: yup.string().email('Email inválido').required('Digite seu email'),
  password: yup.string().required('Digite sua senha'),
})

export const authOnlyEmailSchema = yup.object().shape({
  email: yup.string().email('Email inválido').required('Digite o email'),
})

export const authSignupSchema = yup.object().shape({
  email: yup.string().email('Email inválido').required('Digite seu email'),
  password: yup.string().required('Digite sua senha'),
})
