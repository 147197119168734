// Models
import { IFinancialData, TPayment } from 'models'

// React
import { FC, useLayoutEffect } from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { athleteModalPaymentRegisterSchema } from 'schemas'
import { buttonClickTracking } from 'utils/tracking'
import { preparePaymentToPayload } from 'filters/financial'

// Components
import * as Styled from './styled'
import { DatePicker, InputText, RadioButton } from 'heeds-ds'
import { Modal } from 'components/modals'

// Constants
const DEFAULT_VALUES: IFormInputs = {
  due_date: '10/05/2023',
  payment_type: 'billet',
  value: 0,
}

interface IFormInputs {
  due_date: string
  payment_type: TPayment
  value: number
}

type Props = {
  financial?: IFinancialData
  visible: boolean
}

const ModalPayment: FC<Props> = ({ financial, visible }) => {
  const methods = useForm<IFormInputs>({
    defaultValues: { ...DEFAULT_VALUES, value: financial?.plan_value },
    delayError: 800,
    mode: 'onChange',
    resolver: yupResolver(athleteModalPaymentRegisterSchema),
    reValidateMode: 'onChange',
  })
  const { register, reset, handleSubmit } = methods

  const onSubmit = (formData: IFormInputs) => {
    const payload = preparePaymentToPayload(formData)

    // TODO: criar endpoint para adicionar pagamento
    console.log('🚀 ~ file: index.tsx:71 ~ onSubmit ~ payload:', payload)
  }

  useLayoutEffect(() => {
    if (visible) {
      reset({
        value: financial?.plan_value,
      })
    }
  }, [financial?.plan_value, reset, visible])

  return (
    <Modal showExit>
      <Styled.Container data-testid="modal-payment-container">
        <Styled.Header>
          <Styled.Title>Enviar lembrete</Styled.Title>
        </Styled.Header>

        <FormProvider {...methods}>
          <Styled.Form onSubmit={handleSubmit(onSubmit)}>
            <Styled.Subtitle>
              Escolha a forma do pagamento e o valor do aluno.
            </Styled.Subtitle>

            <RadioButton
              {...register('payment_type')}
              customLabel={['Boleto', 'Cartão', 'Pix', 'Dinheiro']}
              displayError={false}
              gap="1.6rem"
              margin="1.6rem 0 3.8rem"
              options={['billet', 'card', 'pix', 'cash']}
              refName={register('payment_type').ref}
              scale="small"
              textRadio
            />

            <InputText
              label="Valor da Mensalidade"
              margin="0 0 0.6rem"
              mask="currency"
              name="value"
              scale="small"
            />

            <DatePicker
              label="Data do Pagamento"
              margin="0"
              name="due_date"
              scale="small"
            />

            <Styled.SaveButton
              track={buttonClickTracking}
              trackName="create_financial_payment"
            >
              Confirmar
            </Styled.SaveButton>
          </Styled.Form>
        </FormProvider>
      </Styled.Container>
    </Modal>
  )
}

export default ModalPayment
