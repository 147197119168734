// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body, FormButton } from 'heeds-ds'

export const AddModelButton = styled(FormButton).attrs(() => ({
  size: 'small',
  variation: 'outlined',
}))``

export const EmptyContainer = styled(Aligner).attrs(() => ({
  align: 'center',
  flex: 'column',
  gap: '24px',
  justify: 'center',
  padding: '0 80px',
}))`
  height: 70vh;
`

export const EmptyText = styled(Body).attrs((props) => ({
  align: 'center',
  color: props.theme.colors.text.subdued,
  type: 'copy3',
}))``
