import { Input } from 'heeds-ds'
import styled from 'styled-components'

type InputWithIconProps = { width?: string }

export const QuestionWrapper = styled.div`
  width: 100%;
  padding: 2.4rem 0;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`

export const RequiredBadge = styled.span`
  font-size: 14px;
  font-style: italic;
  color: ${({ theme }) => theme.colors.text.critical};
`

export const InputText = styled(Input).attrs({
  scale: 'small',
  readonly: true,
})`
  width: 100%;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text.disabled};
  background-color: ${({ theme }) => theme.colors.surface.disabled};
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: 3px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: ${({ width }) => width || '100%'};
  }
`

export const InputWithIcon = styled.div<InputWithIconProps>`
  width: 100%;
  position: relative;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: ${({ width }) => width || '100%'};
  }
`

export const IconWrapper = styled.div`
  border: none;
  width: 4rem;
  height: 4rem;
  background-color: transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    fill: ${({ theme }) => theme.colors.icon.disabled};
  }
`

export const SelectBoxContainer = styled.div`
  width: 100%;
  display: grid;
  row-gap: 1.6rem;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

export const Label = styled.label`
  align-items: center;
  color: ${({ theme }) => theme.colors.text.disabled};
  cursor: pointer;
  display: flex;
  gap: 1.2rem;
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: ${({ theme }) => theme.fontSize.copy4};
  line-height: 24px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin: 0;
`
