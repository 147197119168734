// Libraries
import styled from 'styled-components'

import { Body, Title } from 'heeds-ds'

export const Bold = styled.span`
  font-weight: 700;
`

export const Content = styled.div`
  position: relative;
  width: 100%;
  padding: 2.4rem;
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: 15px;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: grid;
    grid-template-columns: 1fr min-content;
  }
`

export const ContentTitle = styled(Body).attrs({ type: 'copy2', weight: 700 })`
  margin-bottom: 2.4rem;
`

export const PlanBadge = styled.div`
  width: 12.5rem;
  border-radius: 30px;
  padding: 0.8rem 1.6rem;
  color: ${({ theme }) => theme.colors.badge.purpleText};
  background-color: ${({ theme }) => theme.colors.badge.purpleBackground};
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  font-family: ${({ theme }) => theme.fontFamily.roboto};
`

export const IconWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`

export const FinancialValue = styled(Title).attrs(({ theme }) => ({
  type: 'h2',
  color: theme.colors.text.secondary,
  fontFamily: theme.fontFamily.barlow,
  weight: 500,
}))`
  display: flex;
  align-items: center;
  gap: 16px;
`
