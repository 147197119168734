// Libraries
import styled, { css } from 'styled-components'

// Components
import { Aligner, Body } from 'heeds-ds'

type Props = {
  editMode?: boolean
  showBorder?: boolean
  visible?: boolean
}

export const Container = styled.div.attrs(() => ({
  draggable: false,
}))<Props>`
  border-radius: 2px;
  width: 100%;

  ${({ editMode, visible, theme, showBorder }) => css`
    background: ${theme.colors.surface.default};
    border-left: ${`${showBorder ? '2px' : 0} solid ${
      theme.colors.icon[editMode ? 'disabled' : 'default']
    }`};
    display: ${visible ? 'block' : 'none'};

    @media screen and (max-width: ${theme.breakpoints.desktop}px) {
      ${visible && 'box-shadow: 0px 4px 20px rgba(99, 90, 108, 0.1)'};
    }
  `}
`

export const Header = styled(Aligner).attrs({
  align: 'center',
  gap: '1.6rem',
  justify: 'space-between',
  padding: '0.8rem 1.6rem',
})``

export const DraggableArea = styled(Aligner).attrs({
  justify: 'center',
})`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 0.8rem 0;
  }
`

export const ChipContainer = styled.button`
  align-items: center;
  border-radius: 16px;
  cursor: pointer;
  display: inline-flex;
  gap: 16px;
  height: 3.4rem;
  justify-content: center;
  padding: 8px 16px;
  white-space: nowrap;

  ${({ theme }) => css`
    background: ${theme.colors.badge.purpleBackground};
    border: 1px solid ${theme.colors.badge.purpleText};

    :hover {
      background: ${theme.colors.action.highlightSofblue};
    }

    :focus {
      box-shadow: 0px 0px 5px 0px #2b2a63;
      outline: 1px solid ${theme.colors.badge.purpleText};
    }
  `}
`

export const ChipText = styled(Body).attrs({
  type: 'copy5',
  weight: 700,
})``
