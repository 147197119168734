// Models
import { TButtonTrakingEvent } from '../../../models'

// React
import { forwardRef } from 'react'

// Components
import BaseButton, { Props as ButtonProps } from '../BaseButton'
import { useLocation } from 'react-router-dom'

export type Props = ButtonProps & {
  track?: (event: TButtonTrakingEvent) => void
  trackName?: string
}

const Button = forwardRef<HTMLButtonElement, Props>(
  ({ track, trackName, ...props }, ref) => {
    const { search } = useLocation()

    const tracking = () =>
      track?.({
        eventName: 'click',
        action: `click_button_${trackName ?? ''}`,
        location: window.location.pathname + search,
      })

    return (
      <BaseButton
        ref={ref}
        data-testid="button"
        tracking={tracking}
        type="button"
        {...props}
      />
    )
  },
)
Button.displayName = 'Button'

export default Button
