// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body, Skeleton } from 'heeds-ds'

interface Props {
  answered?: boolean
}

export const LoadStatus = styled(Skeleton)`
  width: 13rem;
  height: 3.2rem;
  border-radius: 1.6rem;
  flex-shrink: 0;
`

export const LoadContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 1.5rem;
  box-shadow: 0px 2px 5px 0px rgba(43, 42, 99, 0.12);
  display: flex;
  gap: 1.6rem;
  padding: 2.4rem 1.6rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.input};
    cursor: pointer;
    display: grid;
    gap: 0.2rem;
    grid-template-columns: 2fr repeat(4, 1fr) 0.5fr;
    margin: 0;
    padding: 16px 0;
    transition: all 0.3s ease-in-out;
  }
`

export const StatusContainer = styled(Aligner).attrs({
  align: 'center',
  justify: 'center',
  gap: '0.8rem',
  padding: '0.8rem 1.6rem',
  width: 'fit-content',
})<Props>`
  background: ${({ answered, theme }) =>
    theme.colors.badge[`${answered ? 'green' : 'yellow'}Background`]};
  border-radius: 1.6rem;
`

export const StatusText = styled(Body).attrs({
  type: 'copy4',
  weight: 700,
})<Props>`
  color: ${({ answered, theme }) =>
    theme.colors.badge[`${answered ? 'green' : 'yellow'}Text`]};
  white-space: nowrap;
`
