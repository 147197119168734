// Libraries
import styled from 'styled-components'

type Props = {
  collapsed?: boolean
  collapsible?: boolean
  hasDescription?: boolean
  height?: string
  margin?: string
  mobileCard?: boolean
  padding?: string
  startsOpen?: boolean
}

export const Container = styled.div<Props>`
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: ${({ mobileCard }) => (mobileCard ? '15px' : '0')};
  box-shadow: ${({ mobileCard }) =>
    mobileCard ? '0px 3.5px 5.5px rgba(0, 0, 0, 0.02)' : ''};
  height: ${({ height }) => height || 'auto'};
  margin: ${({ margin }) => margin || '0'};
  max-height: ${({ collapsed, hasDescription }) =>
    collapsed ? (hasDescription ? 'none' : '68px') : '100%'};
  padding: 0px;
  transition: all 0.3s ease;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    border-radius: 15px;
    box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  }
`

export const Content = styled.div<Props>`
  display: ${({ collapsed }) => (collapsed ? 'none' : 'block')};
  max-height: ${({ collapsed }) => (collapsed ? '0' : '100%')};
  padding: ${({ mobileCard, padding }) =>
    mobileCard ? '0 24px 24px' : padding || '24px'};
  transition: all 0.3s ease;
  ${({ collapsed }) => collapsed && 'overflow: hidden'};
`

export const Description = styled.div`
  color: ${({ theme }) => theme.colors.text.disabled};
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: 14px;
  height: auto;
  line-height: 16px;
  margin-top: 8px;
  padding: 0;
`

export const IconContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: center;
  width: 20px;
`

export const TopContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  padding: ${({ collapsed, mobileCard }) =>
    mobileCard ? '16px 24px 0' : `24px 24px ${collapsed ? '24' : '0'}px`};
  width: 100%;
`
export const Header = styled.div<Props>`
  cursor: ${({ collapsible }) => (collapsible ? 'pointer' : 'default')};
  display: flex;
  justify-content: space-between;
  width: 100%;
`
