// Libraries
import styled from 'styled-components'

// Components
import { Body, Subtitle, Title } from 'heeds-ds'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const TitleMain = styled(Title).attrs(() => ({
  type: 'h1',
  weight: '600',
  fontFamily: 'Barlow',
  margin: '0 0 16px 0',
}))`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: ${({ theme }) => theme.fontSize.sh1};
  }
`

export const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  h2 {
    margin: 16px 0 24px 0;
    text-align: center;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    h2 {
      margin: 24px 0;
      max-width: 375px;
    }
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 56px 0 0 0;
  align-items: center;
  button {
    :nth-child(1) {
      margin-bottom: 30px;
    }
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: 56px;
  }
`

export const Image = styled.img`
  margin: 32px 0 32px 0;
  width: 52px;
  align-self: center;
  object-fit: contain;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 0 0 32px 0;
    width: 85px;
  }
`

export const SubtitleMain = styled(Subtitle).attrs(() => ({
  type: 'sh3',
  align: 'center',
}))`
  color: ${({ theme }) => theme.colors.text.subdued};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: ${({ theme }) => theme.fontSize.copy1};
    margin: 0 0 24px 0;
  }
`

export const BodyMain = styled(Body).attrs(() => ({
  align: 'center',
}))`
  color: ${({ theme }) => theme.colors.text.subdued};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: ${({ theme }) => theme.fontSize.copy2};
  }
`
