// Models
import { TAthleteCalendar } from 'models'

// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Components
import * as Styled from './styled'
import { Avatar, Body } from 'heeds-ds'

// Assets
import { timeObjectList } from 'utils/constants'

type Props = {
  isNewAthlete: boolean
  timePosition?: number
  start: string
  end: string
  avatarOrder?: TAthleteCalendar[]
  athlete?: string
  innerStart?: string
  innerEnd?: string
  innerList?: TAthleteCalendar[]
  leftSpace?: boolean
  sameTime?: boolean
}

const AthleteCard: FC<Props> = (props: Props) => {
  const {
    isNewAthlete,
    timePosition,
    start,
    end,
    avatarOrder,
    athlete,
    innerStart,
    innerEnd,
    innerList = [],
    leftSpace,
    sameTime,
  } = props

  const theme = useContext(ThemeContext)
  const timeList = timeObjectList

  return sameTime ? (
    <Styled.TimeCardWrapper saved>
      <Styled.TimeCard
        saved={isNewAthlete}
        sameTime
        timePosition={timePosition}
        timeListLength={timeList.length}
        startTime={timeList.indexOf(start) + 1.1}
        endTime={timeList.indexOf(end) + 0.9}
      >
        <Styled.AvatarContainer>
          {avatarOrder?.map((sameTime: TAthleteCalendar, indexSame: number) => {
            return (
              <Styled.AvatarInner key={indexSame} leftMargin={indexSame}>
                <Avatar
                  margin="4px 0 0 8px"
                  name={sameTime.athlete}
                  scale="xSmall"
                />
              </Styled.AvatarInner>
            )
          })}
        </Styled.AvatarContainer>
        <Styled.CounterContainer>
          <Body
            type="small"
            weight={500}
            color={theme.colors.text.secondary}
            margin="0 4px 0 0"
          >
            {innerList[innerList.length - 1].athlete}
          </Body>
          {innerList.length > 1 && (
            <Body
              type="small"
              weight={600}
              color={theme.colors.interactive.default}
            >
              + {innerList.length - 1}
            </Body>
          )}
        </Styled.CounterContainer>
        <Body
          type="small"
          weight={400}
          color={theme.colors.text.secondary}
          margin="0 0 0 8px"
        >
          {innerStart} -
          {innerEnd === '0h' ? '23h59' : innerList[innerList.length - 1]?.end}
        </Body>
      </Styled.TimeCard>
    </Styled.TimeCardWrapper>
  ) : (
    <Styled.TimeCardWrapper leftSpace={leftSpace}>
      <Styled.TimeCard
        saved={isNewAthlete}
        timePosition={timePosition}
        leftSpace={leftSpace}
        startTime={timeList.indexOf(start) + 1.1}
        endTime={timeList.indexOf(end) + 0.9}
      >
        <Styled.AvatarContainer>
          <Avatar margin="4px 0 0 8px" name={athlete ?? ''} scale="xSmall" />
        </Styled.AvatarContainer>
        <Styled.EllipsisText>{athlete}</Styled.EllipsisText>
        <Body
          type="copy4"
          weight={400}
          color={theme.colors.text.secondary}
          margin="0 0 0 8px"
        >
          {start} - {end === '0h' ? '23h59' : end}
        </Body>
      </Styled.TimeCard>
    </Styled.TimeCardWrapper>
  )
}

export default AthleteCard
