// Models
import { IAthleteInfoState } from 'storage/athleteInfo/models'
import IStore from 'lib/redux/models'

// React
import { FC, ReactNode, useEffect, useLayoutEffect, useState } from 'react'

// Libraries
import {
  generatePath,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { triggerLoadAthlete } from 'storage/athleteInfo/duck'
import { triggerToastWarning } from 'storage/general/duck'
import { urls } from 'routes/paths'
import { useMenu } from 'hooks'

// Components
import * as Styled from './styled'
import Profile from 'components/Profile'
import TabNavigator from 'components/TabNavigator'

export interface IAthleteOutletContext {
  id: string
  isEditing: boolean
  navigateToNextTab: (to?: string) => void
  navigateToPreviousTab: (to?: string) => void
  blockNavigation: (to?: string) => void
}

type Props = {
  children?: ReactNode | ReactNode[]
}

const AthleteRegisterLayout: FC<Props> = ({ children }) => {
  const { info, loading, error } = useSelector<IStore, IAthleteInfoState>(
    (state) => state.athleteInfo,
  )
  const { id = 'cadastro' } = useParams()
  const { pathname, state = {} } = useLocation()
  const { setPagename } = useMenu()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [blocked, setBlocked] = useState(false)

  const isEditing = id !== 'cadastro'
  const avoidStepsNavigation =
    !isEditing && pathname === generatePath(urls.athleteGeneral, { id })
  const steps = [
    { label: 'DADOS GERAIS', to: generatePath(urls.athleteGeneral, { id }) },
    { label: 'FINANCEIRO', to: generatePath(urls.athleteFinancial, { id }) },
    { label: 'HORÁRIOS', to: generatePath(urls.athleteSchedule, { id }) },
    { label: 'AVALIAÇÃO', to: generatePath(urls.athleteAssessment, { id }) },
    { label: 'COMENTÁRIOS', to: generatePath(urls.athleteComments, { id }) },
  ]

  const navigateToNextTab = (to?: string) => {
    const index = steps.findIndex((step) => step.to === pathname)
    const nextStep = steps[index + 1]
    const nextPath = nextStep?.to

    if (nextPath) {
      navigate(to || nextPath, { state })
    }
  }

  const navigateToPreviousTab = (to?: string) => {
    const index = steps.findIndex((step) => step.to === pathname)
    const previousStep = steps[index - 1]
    const previousPath = previousStep?.to

    if (previousPath) {
      navigate(to || previousPath, { state })
    }
  }

  const blockNavigation = (to?: string) => {
    const avoidStepsNavigation = pathname === generatePath(to || '', { id })
    setBlocked(avoidStepsNavigation)
  }

  useLayoutEffect(() => {
    setPagename('Alunos')

    return () => setPagename('Dashboard')
  }, [setPagename])

  useLayoutEffect(() => {
    if (id && info?.id !== Number(id) && id !== 'cadastro') {
      dispatch(triggerLoadAthlete({ id: Number(id) }))
    }
  }, [dispatch, id, info?.id])

  useEffect(() => {
    const invalidId = id !== 'cadastro' && isNaN(Number(id))
    // TODO: Implement notFound
    const notFound = id && loading === false && error === '404'

    if (invalidId || notFound) {
      dispatch(triggerToastWarning({ customTitle: 'ID do atleta inválido!' }))
      navigate(urls.athletes)
    }
  }, [dispatch, error, id, loading, navigate])

  return (
    <Styled.Container>
      <Profile name={isEditing && !loading ? info?.name : ''} />
      <Styled.TabsWrapper>
        <TabNavigator
          avoidNextNavigation={avoidStepsNavigation}
          blockNavigation={blocked}
          items={id !== 'cadastro' ? steps : [steps[0]]}
        />
      </Styled.TabsWrapper>
      <Styled.Content>
        {children || (
          <Outlet
            context={{
              isEditing,
              id,
              navigateToNextTab,
              navigateToPreviousTab,
              blockNavigation,
            }}
          />
        )}
      </Styled.Content>
    </Styled.Container>
  )
}

export default AthleteRegisterLayout
