import styled from 'styled-components'

type CalendarProps = {
  longerCalendar?: boolean
  fullScreen?: boolean
  outlined?: boolean
  display?: boolean
  saved?: boolean
  firstAvatar?: boolean
  leftMargin?: number
  isSelected?: boolean
}

export const CalendarContainer = styled.div<CalendarProps>`
  width: 100%;
  height: calc(100% - ${({ fullScreen }) => (fullScreen ? 84 : 0)}px);
  color: ${({ theme }) => theme.colors.text.secondary};
  position: relative;
  right: 0;
  top: 0;
  max-height: 737px;
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 16px 29px 14px;
  border-radius: 24px 24px 0 0;
  background-color: ${({ theme }) => theme.colors.surface.default};
`

export const Title = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

export const CalendarBody = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 737px;
`

export const WeekDays = styled.div`
  display: flex;
  width: 100%;
  height: 5%;
  align-items: center;
  background-color: ${({ theme }) =>
    theme.colors.surface.highlightSubduedHovered};
  p {
    margin: 6px 0px;
    text-align: center;
    width: calc(100% / 7);
  }
`

export const Days = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: 20px;
  height: 95%;
`

export const DayInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Day = styled.div<CalendarProps>`
  cursor: pointer;
  position: relative;
  width: calc(100% / 7);
  height: calc(100% / ${({ longerCalendar }) => (longerCalendar ? '6' : '5')});
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  padding: 7px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  :last-child {
    border-radius: 0 0 15px 0;
  }
`

export const DayContainer = styled.div<CalendarProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ isSelected }) => (isSelected ? '4px' : '0px')};
  margin-bottom: 2px;
`

export const DayWrapper = styled.div<CalendarProps>`
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.icon.default : theme.colors.surface.default};
  min-width: 20px;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin: -2 0 2px;
`

export const TimeColumn = styled.div<CalendarProps>`
  min-width: 55px;
  height: 100%;
  border-top: 1px solid
    ${({ theme }) => theme.colors.surface.highlightSubduedHovered};
  border-left: 1px solid ${({ theme }) => theme.colors.border.input};
  border-right: 1px solid ${({ theme }) => theme.colors.border.input};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: ${({ fullScreen }) =>
    fullScreen ? '0 0 0 24px' : '0 0 0 15px'};
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.surface.default};
`

export const CalendarBodyInner = styled.div`
  width: 100%;
`

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
`

export const DisplayTypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`

export const MoreContainer = styled.div<CalendarProps>`
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: ${({ theme }) => theme.colors.surface.default};
  min-height: 23px;
  width: 100%;
  padding: 4px 0 0 10px;
  z-index: 30;
`

export const AthleteNameAndTime = styled.div`
  z-index: 20;
`

export const AthleteNameCard = styled.div<CalendarProps>`
  display: flex;
  flex-direction: column;
  padding: 2px 8px;
  gap: 4px;
  width: 100%;
  height: 16px;
  background: ${({ saved, theme }) =>
    saved
      ? theme.colors.surface.criticalSubdued
      : theme.colors.surface.selectedHovered};
  border-left: 2px solid
    ${({ saved, theme }) =>
      saved ? theme.colors.text.critical : theme.colors.text.secondary};
  border-radius: 0px 4px 4px 0px;
  margin-bottom: 4px;
`

export const HeaderContainer = styled.div``

export const loadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
`
