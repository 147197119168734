// React
import { FC, ReactNode, useCallback, useContext, useLayoutEffect } from 'react'

// Libraries
import { Navigate, useNavigate } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

// Misc
import { urls } from 'routes/paths'
import { useMediaQuery, useMenu } from 'hooks'

// Components
import * as Styled from './styled'
import { Aligner, Body } from 'heeds-ds'

// Assets
import gymSVG from 'assets/images/icons/gym.svg'
import exerciseSVG from 'assets/images/icons/exercise_weights.svg'
import iNoteSVG from 'assets/images/icons/i_note_action.svg'

interface IModelsOptions {
  disabled?: boolean
  icon: ReactNode
  label: string
  path: string
}

const LibraryHome: FC = () => {
  const { setPagename } = useMenu()
  const navigate = useNavigate()
  const theme = useContext(ThemeContext)
  const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  const color = useCallback(
    (disabled?: boolean) =>
      theme.colors.text[disabled ? 'disabled' : 'default'],
    [theme.colors.text],
  )

  const LIBRARY_MODELS: Array<IModelsOptions> = [
    {
      icon: <Styled.CustomIcon src={gymSVG} />,
      label: 'Modelos de anamneses',
      path: urls.libraryAnamneseTemplates,
    },
    {
      disabled: true,
      icon: <Styled.CustomIcon disabled src={iNoteSVG} />,
      label: 'Modelos de avaliações',
      path: urls.library,
    },
    {
      disabled: true,
      icon: <Styled.ModelIcon color={color(true)} iconName="textSnippet" />,
      label: 'Modelos de treinos',
      path: urls.library,
    },
    {
      disabled: true,
      icon: <Styled.CustomIcon disabled src={exerciseSVG} />,
      label: 'Modelos de exercícios',
      path: urls.library,
    },
  ]

  useLayoutEffect(() => {
    if (!isTablet) setPagename('BIBLIOTECA')

    return () => setPagename('Dashboard')
  }, [isTablet, setPagename])

  if (isTablet) return <Navigate to={urls.libraryAnamneseTemplates} replace />

  return (
    <Styled.Container>
      {LIBRARY_MODELS.map(({ disabled, icon, label, path }) => (
        <Styled.CardContainer
          key={label}
          onClick={() => !disabled && navigate(path)}
        >
          <Aligner gap="1.2rem" align="center">
            {icon}

            <Body color={color(disabled)} weight={600}>
              {label}
            </Body>
          </Aligner>

          <Styled.ArrowIcon color={color(disabled)} />
        </Styled.CardContainer>
      ))}
    </Styled.Container>
  )
}

export default LibraryHome
