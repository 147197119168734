// Models
import { IExerciseData } from 'storage/exercise/models'
import {
  ESetTypeToPortuguese,
  IWorkoutSetInput,
  TSetSpecialSet,
  TSpecialSetInput,
  TWorkoutSetType,
} from 'models'

// React
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

// Libraries
import { useFormContext } from 'react-hook-form'

// Misc
import { buttonClickTracking } from 'utils/tracking'

// Components
import * as Styled from './styled'
import { Aligner, Button, DropdownMenu, Icon, IconButton } from 'heeds-ds'
import SetContent, { SetType } from './contents'

// Cosntants
import { SET_TYPE_OPTIONS } from 'utils/constants'

const fieldLabels = {
  cadence: 'Cadência',
  comment: 'Comentário',
  repetitions: 'Repetição',
  speed: 'Velocidade',
  time: 'Tempo',
  weight: 'Carga',
}

export type FieldType = keyof typeof fieldLabels

type Props = {
  addOrRemoveFieldFromWorkoutSetToExercise?: (
    modelId: string,
    workoutSetId: string,
    exerciseIndex: number,
    field: FieldType,
    add?: boolean,
  ) => void
  displayMode?: boolean
  modelId?: string
  modelIndex: number
  name: string
  onClickImage?: (exercise: IExerciseData) => void
  openExercisesModal?: () => void
  onRemove?: () => void
  readonly?: boolean
  removeExerciseFromWorkoutSet?: (exerciseIndex: number) => void
  replicateWorkoutSetFields?: (set: IWorkoutSetInput) => void
  setSpecialSet?: TSetSpecialSet
  showBorder?: boolean
  specialSet?: TSpecialSetInput
  uncheckAllRepeats?: () => void
  updateWorkoutSet?: (
    modelId: string,
    workoutSetId: string,
    updatedWorkoutSet: IWorkoutSetInput,
  ) => void
  visible?: boolean
  workoutSet: IWorkoutSetInput & { index?: number }
}

const WorkoutSetFormTag: FC<Props> = (props) => {
  const {
    addOrRemoveFieldFromWorkoutSetToExercise,
    name,
    onRemove,
    readonly,
    removeExerciseFromWorkoutSet,
    replicateWorkoutSetFields,
    setSpecialSet,
    showBorder,
    specialSet,
    uncheckAllRepeats,
    updateWorkoutSet,
    visible,
    workoutSet,
  } = props
  const { setValue, getFieldState, watch, resetField } = useFormContext()

  const [expandContent, setExpandContent] = useState(true)

  const { isDirty: someFieldIsDirty } = getFieldState(name)
  const { isDirty: repeatFieldIsDirty } = getFieldState(`${name}.repeat`)

  const blocked = specialSet && specialSet.id !== workoutSet.id
  const editMode = specialSet && specialSet.id === workoutSet.id
  const viewMode = readonly || blocked

  const currentWorkoutSet = useMemo(
    () =>
      specialSet?.id === workoutSet.id
        ? {
            ...specialSet,
            exercises: Object.values(specialSet.exercises),
          }
        : workoutSet,
    [specialSet, workoutSet],
  )

  const typeLabel =
    ESetTypeToPortuguese[currentWorkoutSet.type as TWorkoutSetType]

  const convertToType = (type: string) => {
    const maxExercises = {
      normal: 1,
      bi_set: 2,
      tri_set: 3,
    }

    return {
      ...workoutSet,
      type: type,
      exercises: [
        ...workoutSet.exercises.slice(
          0,
          maxExercises[(type as keyof typeof maxExercises) ?? 'normal'],
        ),
      ],
    }
  }

  const changeType = (type: string) => {
    const updatedWorkoutSet = convertToType(type as string)
    if (type !== 'normal') {
      setSpecialSet?.({
        ...updatedWorkoutSet,
        exercises: updatedWorkoutSet.exercises.reduce(
          (acc, exerc) => ({
            ...acc,
            [exerc.id]: exerc,
          }),
          {},
        ),
      })
    } else {
      props.modelId &&
        updateWorkoutSet?.(props.modelId, workoutSet.id, updatedWorkoutSet)
      setSpecialSet?.(undefined)
    }
  }

  const setOptions = SET_TYPE_OPTIONS.map(({ label, value }) => ({
    label,
    onClick: () => changeType(value),
  }))

  const addCustomField = useCallback(
    (field: FieldType, index: number, remove = false) => {
      const { modelId } = props
      if (!modelId) return

      addOrRemoveFieldFromWorkoutSetToExercise?.(
        modelId,
        workoutSet.id,
        index,
        field,
        !remove,
      )
      setValue(`${name}.exercises.${index}.${field}`, remove ? undefined : '')
    },
    [
      addOrRemoveFieldFromWorkoutSetToExercise,
      name,
      props,
      setValue,
      workoutSet.id,
    ],
  )

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault()
    setExpandContent((prev) => !prev)
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(`${name}.repeat`, event.target.checked)

    if (event.target.checked) {
      resetField(name, { defaultValue: watch(name) })
      const set = watch(name) as IWorkoutSetInput
      replicateWorkoutSetFields?.(set)
    } else {
      uncheckAllRepeats?.()
    }
  }

  const handleUpdateWorkoutSet = () => {
    if (!props.modelId || !specialSet) return
    const updatedExercises = Object.values(specialSet.exercises).map(
      (setToExercise, setIndex) => {
        return {
          ...setToExercise,
          ...watch(`${name}.exercises.${setIndex}`),
        }
      },
    )
    updateWorkoutSet?.(props.modelId, workoutSet.id, {
      ...workoutSet,
      type: specialSet.type,
      exercises: updatedExercises,
    })
    setSpecialSet?.(undefined)
  }

  const handleRemoveExerciseFromWorkoutSet = (exerciseIndex: number) => {
    if (specialSet) return removeExerciseFromWorkoutSet?.(exerciseIndex)
    setSpecialSet?.({
      ...workoutSet,
      exercises: workoutSet.exercises.reduce((acc, exerc, index) => {
        if (index === exerciseIndex) return acc
        return {
          ...acc,
          [exerc.id]: exerc,
        }
      }, {}),
    })
  }

  const contentProps = {
    ...props,
    addCustomField,
    fieldLabels,
    handleCheckboxChange,
    readonly: viewMode,
    removeExerciseFromWorkoutSet: handleRemoveExerciseFromWorkoutSet,
    workoutSet: currentWorkoutSet,
  }

  useEffect(() => {
    if (someFieldIsDirty && !repeatFieldIsDirty) {
      setValue(`${name}.repeat`, false)
    }
  }, [someFieldIsDirty, name, repeatFieldIsDirty, setValue])

  return (
    <Styled.Container
      data-testid="series-form-tag-container"
      editMode={blocked}
      showBorder={showBorder}
      visible={visible}
    >
      <Styled.DraggableArea>
        {/* TODO: Implementar funcionalidade de ordenação com drag and drop */}
        {/* <Icon iconName="dragIndicator" color={colors.icon.disabled} size={24} /> */}
      </Styled.DraggableArea>

      <Styled.Header>
        <DropdownMenu items={setOptions} disabled={viewMode}>
          <Styled.ChipContainer>
            <Styled.ChipText>{typeLabel}</Styled.ChipText>

            <Icon iconName="expandMore" />
          </Styled.ChipContainer>
        </DropdownMenu>

        {!viewMode && (
          <Aligner justify="flex-end" gap="1.6rem">
            <IconButton
              iconName={expandContent ? 'expandLess' : 'expandMore'}
              margin="0"
              onClick={handleClick}
              size="small"
              track={buttonClickTracking}
              trackName={`${expandContent ? 'hide' : 'show'}_workout_fields`}
            />

            <IconButton
              cancel
              data-testid="exercise-add-button"
              iconName="delete"
              margin="0"
              onClick={onRemove}
              size="small"
              track={buttonClickTracking}
              trackName="remove_workout_set"
            />
          </Aligner>
        )}
      </Styled.Header>

      <SetContent
        {...contentProps}
        visible={expandContent}
        type={currentWorkoutSet.type as SetType}
      />

      {editMode && (
        <Aligner justify="space-between" padding="1.6rem">
          <Button
            cancel
            margin="0"
            onClick={() => setSpecialSet?.(undefined)}
            size="small"
            track={buttonClickTracking}
            trackName="cancel_update_workout_set"
            variation="borderless"
          >
            Cancelar
          </Button>

          <Button
            margin="0"
            onClick={handleUpdateWorkoutSet}
            size="small"
            track={buttonClickTracking}
            trackName="update_workout_set"
            variation="borderless"
          >
            Salvar
          </Button>
        </Aligner>
      )}
    </Styled.Container>
  )
}

export default WorkoutSetFormTag
