// React
import { MouseEvent, forwardRef } from 'react'

// Components
import { MainButton } from './styled'

export type ButtonSizesType = 'xsmall' | 'small' | 'medium' | 'large'

export type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  cancel?: boolean
  margin?: string
  radius?: string
  size?: ButtonSizesType
  variation?: 'main' | 'outlined' | 'borderless' | 'success'
  width?: string
  tracking?: () => void
  onlyIcon?: boolean
}

const BaseButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { children, tracking, onClick, ...restProps } = props

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    tracking?.()
    onClick?.(event)
  }

  return (
    <MainButton
      ref={ref}
      data-testid="mainButton"
      onClick={handleClick}
      {...restProps}
    >
      {children}
    </MainButton>
  )
})
BaseButton.displayName = 'BaseButton'

export default BaseButton
