import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import interceptorRequest from '../jwt'

export const authApi: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 5000,
})

export const externalApi: AxiosInstance = axios.create({
  timeout: 5000,
})

const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 5000,
})

api.interceptors.request.use(async (config: AxiosRequestConfig) =>
  interceptorRequest(config),
)

export default api
