// Models
import { IGetAthletesPayload, IGetAthletesResponse } from './@types'

// Misc
import api from 'lib/axios/api'
import { objectToQueryString } from 'lib/query-string'

export const getAthletes = async (
  payload: IGetAthletesPayload,
): Promise<IGetAthletesResponse> => {
  try {
    const query = payload
    const response = await api.get(`athletes/`, {
      params: query,
      paramsSerializer: function (params) {
        return objectToQueryString(params)
      },
    })
    const data: IGetAthletesResponse = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}
