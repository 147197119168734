import { useLayoutEffect } from 'react'

function throttle(callbackFn: () => void, limit: number) {
  let wait = false
  return function () {
    if (!wait) {
      callbackFn()
      wait = true
      setTimeout(function () {
        wait = false
      }, limit)
    }
  }
}

const useContentScroll = (fn: () => void, id = 'content-root') => {
  const element = document.getElementById(id)

  useLayoutEffect(() => {
    if (!element && !fn) {
      return
    }
    element?.addEventListener('scroll', throttle(fn, 2200))

    return () => element?.removeEventListener('scroll', throttle(fn, 2200))
  }, [element, fn])
}

export default useContentScroll
