// Models
import { IBaseState } from 'lib/redux/models'
import { IPaginatedResponse } from 'models/api'
import { IAnamneseSection } from 'models/anamnese'

export enum EAnamneseAnswerActionTypes {
  CLEANUP = 'ANAMNESE_ANSWER/CLEANUP',
  FAILURE = 'ANAMNESE_ANSWER/FAILURE',
  FULFILL = 'ANAMNESE_ANSWER/FULFILL',
  REQUEST = 'ANAMNESE_ANSWER/REQUEST',
  SUCCESS = 'ANAMNESE_ANSWER/SUCCESS',
  CREATE = 'ANAMNESE_ANSWER/CREATE',
  CREATE_MULTIPLE = 'ANAMNESE_ANSWER/CREATE_MULTIPLE',
  DELETE = 'ANAMNESE_ANSWER/DELETE',
  GET = 'ANAMNESE_ANSWER/GET',
  LOAD = 'ANAMNESE_ANSWER/LOAD',
  LOAD_MORE = 'ANAMNESE_ANSWER/LOAD_MORE',
  PARTIAL_UPDATE = 'ANAMNESE_ANSWER/PARTIAL_UPDATE',
  SELECT_ANAMNESE = 'ANAMNESE_ANSWER/SELECT_ANAMNESE',
  SUCCESS_LOAD_MORE = 'ANAMNESE_ANSWER/SUCCESS_LOAD_MORE',
  UPDATE = 'ANAMNESE_ANSWER/UPDATE',
}

export interface IAnamneseAnswerState extends IBaseState, IPaginatedResponse {
  anamneseAnswer?: IAnamneseAnswer
  anamneseAnswers?: IAnamneseAnswer[]
}

export interface IAnamneseAnswer {
  answer_date?: string
  athlete: number
  creation_date?: string
  description?: string
  id: string
  personal: number
  sections: IAnamneseSection[]
  template_id: string
  title: string
}
