// Libraries
import styled from 'styled-components'

// Components
import { Body } from 'heeds-ds'

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  min-width: 45rem;
`

export const ScrollContainer = styled.div`
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
  padding: 0.8rem 2.4rem 0;
`

export const Title = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.secondary,
  margin: '0 0 2.4rem',
  type: 'copy3',
  weight: 700,
}))``
