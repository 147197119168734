// React
import { FC, useContext, useEffect, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useController, useFormContext } from 'react-hook-form'

// Components
import * as Styled from './styled'
import { Icon, InputWrapper } from '../..'

enum EIconSizes {
  small = 20,
  medium = 21.6,
  large = 25,
}

enum EFieldStates {
  default = 'default',
  error = 'critical',
  focus = 'focused',
  success = 'success',
}

type Option = {
  label: string
  value: string
}

type SelectAttrs = React.SelectHTMLAttributes<HTMLSelectElement>

type Props = SelectAttrs & {
  displayError?: boolean
  label?: string
  margin?: string
  name: string
  options: Option[]
  scale?: 'small' | 'medium' | 'large'
  width?: string
}

const Select: FC<Props> = ({
  displayError = true,
  disabled = false,
  label = '',
  margin = '',
  name,
  options = [],
  placeholder = 'Selecione uma opção:',
  scale = 'medium',
  width,
}) => {
  const theme = useContext(ThemeContext)

  const [fieldState, setFieldState] = useState(EFieldStates.default)

  const { control } = useFormContext()
  const {
    field: { onChange, value },
    fieldState: { isDirty, error },
  } = useController({ control, name })

  const COLORS_SCHEMA = {
    critical: theme.colors.text.critical,
    default: theme.colors.border.input,
    focused: theme.colors.text.default,
    success: theme.colors.text.success,
  }

  useEffect(() => {
    switch (true) {
      case !!error:
        setFieldState(EFieldStates.error)
        break
      case isDirty:
        setFieldState(EFieldStates.success)
        break
      default:
        setFieldState(EFieldStates.default)
        break
    }
  }, [error, isDirty])

  return (
    <InputWrapper
      color={COLORS_SCHEMA[fieldState]}
      disabled={disabled}
      displayError={displayError}
      error={error?.message}
      margin={margin}
      label={label}
      size={scale}
      width={width}
    >
      <Styled.Wrapper
        value={value}
        onValueChange={onChange}
        disabled={disabled}
      >
        <Styled.Trigger borderColor={COLORS_SCHEMA[fieldState]} scale={scale}>
          <Styled.Value aria-label={value} placeholder={placeholder} />
          <Styled.Icon>
            <Icon
              color={theme.colors.icon.default}
              iconName="expandMore"
              size={EIconSizes.small}
            />
          </Styled.Icon>
        </Styled.Trigger>

        <Styled.Content>
          <Styled.List>
            {options.map((option) => {
              return (
                <Styled.Option
                  key={option.value}
                  value={option.value}
                  textValue={option.label}
                >
                  <Styled.OptionText>{option.label}</Styled.OptionText>
                </Styled.Option>
              )
            })}
          </Styled.List>
        </Styled.Content>
      </Styled.Wrapper>
    </InputWrapper>
  )
}

export default Select
