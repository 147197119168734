// Models
import { IExerciseData } from 'storage/exercise/models'

// React
import { FC } from 'react'

// Components
import * as Styled from './styled'
import { Modal } from 'components'

interface IExerciseProps {
  exercise?: IExerciseData
  handleClose: () => void
}

const ModalExercise: FC<IExerciseProps> = ({ exercise, handleClose }) => {
  const title = exercise
    ? exercise.name[0].toUpperCase() + exercise?.name.slice(1)
    : ''
  return (
    <Modal maxWidth="54rem" onClose={handleClose} title={title}>
      <Styled.Image src={exercise?.file} />
    </Modal>
  )
}

export default ModalExercise
