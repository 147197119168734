// Models
import { IWorkoutRoutineState } from 'storage/workoutRoutine/models'
import { TRoutineStatus } from 'models'
import IStore from 'lib/redux/models'

// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useSelector } from 'react-redux'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { useMediaQuery } from 'hooks'

// Components
import * as Styled from './styled'
import { Aligner, Icon } from 'heeds-ds'
import { TabFilter } from 'components'

// Constants
const FILTERS: { label: string; value: TRoutineStatus }[] = [
  { label: 'Rotinas atuais', value: 'current' },
  { label: 'Rotinas futuras', value: 'scheduled' },
  { label: 'Histórico de rotinas', value: 'history' },
]

type Props = {
  activeTab?: TRoutineStatus
  onTabChange: (selectedTab: TRoutineStatus) => void
  openNewRoutineModal: () => void
}

const GlobalRoutineHeader: FC<Props> = ({
  activeTab,
  onTabChange,
  openNewRoutineModal,
}) => {
  const { workoutRoutines } = useSelector<IStore, IWorkoutRoutineState>(
    (state) => state.workoutRoutine,
  )
  const theme = useContext(ThemeContext)
  const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  const hasRoutines = Boolean(workoutRoutines?.length)

  return (
    <Styled.Container>
      <Aligner width={isTablet ? '50rem' : '100%'}>
        <TabFilter
          activeStatus={activeTab}
          filters={FILTERS}
          onClick={onTabChange}
        />
      </Aligner>

      {hasRoutines && isTablet && (
        <Styled.AddModelButton
          onClick={openNewRoutineModal}
          track={buttonClickTracking}
          trackName="navigate_to_create_routine"
        >
          <Icon iconName="add" color={theme.colors.background.default} />
          Criar nova rotina
        </Styled.AddModelButton>
      )}
    </Styled.Container>
  )
}

export default GlobalRoutineHeader
