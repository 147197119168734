// Libraries
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopSmall}px) {
    align-items: center;
    justify-content: normal;
    padding: 4rem 11.2rem;
    row-gap: 4rem;
  }
`

export const Content = styled.div`
  width: 100%;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopSmall}px) {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row-reverse;
    gap: 10.4rem;
    justify-content: center;
  }
`

export const FormContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: 2.4rem 2.4rem 0 0;
  box-shadow: 0px 4px 80px 0px rgba(0, 0, 0, 0.1);
  padding: 5.6rem 4.8rem 8rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopSmall}px) {
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    flex: 1;
    max-width: 42rem;
    padding: 0;
  }
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 2.4rem 0.4rem 0.4rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopSmall}px) {
    padding: 0;
  }
`

export const LayoutImage = styled.img`
  left: 0;
  object-fit: contain;
  opacity: 0.4;
  position: absolute;
  right: 0;
  top: 4.4rem;
  width: 100%;
  z-index: -10;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopSmall}px) {
    opacity: 1;
    position: static;
    width: 50rem;
    z-index: 0;
  }
`
