// Libraries
import styled from 'styled-components'

// Comopnents
import { Body, Button } from 'heeds-ds'

type Props = {
  active?: boolean
}

export const Container = styled.div`
  padding: 24px 0;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0 24px;
    width: 602px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px 56px;
`

export const FilterButton = styled.button.attrs(() => ({
  type: 'button',
}))<Props>`
  align-items: center;
  background: ${({ active, theme }) =>
    active ? theme.colors.icon.default : theme.colors.icon.onPrimary};
  border: 1px solid
    ${({ active, theme }) =>
      active ? theme.colors.border.default : theme.colors.border.input};
  border-radius: 15px;
  color: ${({ active, theme }) =>
    active ? theme.colors.text.onPrimary : theme.colors.text.subdued};
  cursor: pointer;
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: ${({ theme }) => theme.fontSize.copy4};
  gap: 8px;
  padding: 8px 16px;
  white-space: nowrap;
`

export const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 30px 16px 16px;
  position: relative;
  ::after {
    background-color: ${({ theme }) => theme.colors.icon.disabled};
    content: '';
    height: 1.5px;
    opacity: 0.3;
    position: absolute;
    top: 100%;
    width: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 32px 16px 16px;
    width: 100%;
  }
`

export const Description = styled(Body).attrs((props) => ({
  align: 'center',
  color: props.theme.colors.text.subdued,
  margin: '0 0 24px',
  type: 'copy3',
}))``

export const Title = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.secondary,
  type: 'copy1',
}))``

export const SaveButton = styled(Button).attrs(() => ({
  margin: '64px auto 24px',
  size: 'small',
}))``
