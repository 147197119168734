// Libraries
import styled, { css } from 'styled-components'

enum EFontSizes {
  small = '14px',
  medium = '16px',
  large = '18px',
}

enum EPaddings {
  small = '12px 0px 12px 16px',
  medium = '15px 0px 15px 16px',
  large = '18px 0px 18px 16px',
}

enum EPaddingsWithIcon {
  small = '12px 50px 12px 16px',
  medium = '15px 50px 15px 16px',
  large = '18px 50px 18px 16px',
}

type InputProps = {
  scale?: 'small' | 'medium' | 'large'
}

export const Input = styled.input<InputProps>`
  ${({ scale, theme }) => css`
    width: 100%;
    padding: ${scale ? EPaddings[scale] : EPaddings.medium};
    background-color: transparent;
    color: ${theme.colors.text.default};
    font-family: ${theme.fontFamily.roboto};
    font-size: ${scale ? EFontSizes[scale] : EFontSizes.medium};
    font-weight: ${theme.fontWeight.regular};
    transition: all 0.3s ease;
    caret-color: #202223;
    cursor: text;
    outline: none;
    border: none;

    &[aria-type='password'] {
      padding: ${scale ? EPaddingsWithIcon[scale] : EPaddingsWithIcon.medium};
    }
    ::placeholder {
      color: ${theme.colors.text.subdued};
    }
    :read-only {
      cursor: auto;
      pointer-events: none;
    }
    :disabled {
      cursor: not-allowed;
      background: ${theme.colors.surface.disabled};
      color: ${theme.colors.text.subdued};

      ::placeholder {
        color: ${theme.colors.text.disabled};
      }
    }
  `}
`
