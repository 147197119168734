// Models
import { ITokenDecoded } from 'models'
import { ITokensResponse } from 'storage/auth/models'

// Libraries
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import jwtDecode from 'jwt-decode'

// Misc
import { requestRefreshToken } from 'services/auth'

// Constants
const EXPIRE_FUDGE = 10
const STORAGE_KEY = `auth-tokens-${process.env.NODE_ENV}`
const HEADER = 'Authorization'
const HEADER_PREFIX = 'Bearer '

// Types
interface IRequest {
  resolve: (value?: unknown) => void
  reject: (reason?: unknown) => void
}

type RefreshTokenServiceType = (
  refreshToken: Token,
) => Promise<Token | ITokensResponse>

type Token = string

let isRefreshing = false
let promisesQueue: IRequest[] = []

export const setAuthTokens = (tokens: ITokensResponse) => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(tokens))
  window.dispatchEvent(new StorageEvent('storage', { key: STORAGE_KEY }))
}

const _getTimestampFromToken = (token: Token): number | undefined => {
  const decoded = jwtDecode<ITokenDecoded>(token)

  return decoded?.exp
}

const _getExpiresInTime = (token: Token): number => {
  const expiration = _getTimestampFromToken(token)

  if (!expiration) return -1

  return expiration - Date.now() / 1000
}

export const clearAuthTokens = () => {
  localStorage.removeItem(STORAGE_KEY)
  window.dispatchEvent(new StorageEvent('storage', { key: STORAGE_KEY }))
}

const _resetAuthReload = (): void => {
  clearAuthTokens()
}

const _setAccessToken = (token: Token): void => {
  const tokens = getAuthTokens()
  if (!tokens) {
    throw new Error(
      'Unable to update access token since there are not tokens currently stored',
    )
  }

  tokens.access = token
  setAuthTokens(tokens)
}

const _resolveQueue = (token?: Token): void => {
  promisesQueue.forEach((promise) => {
    promise.resolve(token)
  })

  promisesQueue = []
}

const _declineQueue = (error: Error): void => {
  promisesQueue.forEach((promise) => {
    promise.reject(error)
  })

  promisesQueue = []
}

export const getUserLoggedState = () => {
  const tokens = getAuthTokens()

  return !isTokenExpired(tokens?.refresh || '')
}

export const getAuthTokens = (): ITokensResponse | undefined => {
  const rawTokens = localStorage.getItem(STORAGE_KEY)
  if (!rawTokens) return

  try {
    return JSON.parse(rawTokens)
  } catch (error: unknown) {
    if (error instanceof SyntaxError) {
      error.message = `Failed to parse auth tokens: ${rawTokens}`
      throw error
    }
  }
}

const getAccessToken = (): Token | undefined => {
  const tokens = getAuthTokens()
  return tokens ? tokens.access : undefined
}

const getRefreshToken = (): Token | undefined => {
  const tokens = getAuthTokens()
  return tokens ? tokens.refresh : undefined
}

export const isTokenExpired = (token: Token): boolean => {
  if (!token) return true
  const expiresIn = _getExpiresInTime(token)
  return !expiresIn || expiresIn <= EXPIRE_FUDGE
}

const refreshExpiredToken = async (
  requestRefreshService: RefreshTokenServiceType,
): Promise<Token> => {
  const refreshToken = getRefreshToken()

  if (!refreshToken || isTokenExpired(refreshToken)) {
    _resetAuthReload()
    throw new Error('Refresh token unavailable or expired')
  }

  try {
    isRefreshing = true
    const newTokens = await requestRefreshService(refreshToken)

    if (typeof newTokens === 'object' && newTokens?.access) {
      setAuthTokens(newTokens)
      return newTokens.access
    } else if (typeof newTokens === 'string') {
      _setAccessToken(newTokens)
      return newTokens
    }

    throw new Error(
      'requestRefresh must either return a string or an object with an accessToken',
    )
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const err = error as AxiosError
      const status = err?.response?.status
      if (status === 401 || status === 422) {
        clearAuthTokens()
        throw new Error(
          `Got ${status} on token refresh; clearing both auth tokens`,
        )
      }
    }
    const err = error as Error
    throw new Error(`Failed to refresh auth token: ${err.message}`)
  } finally {
    isRefreshing = false
  }
}

const refreshTokenIfNeeded = async (
  requestRefresh: RefreshTokenServiceType,
): Promise<Token | undefined> => {
  let accessToken = getAccessToken()

  if (!accessToken || isTokenExpired(accessToken)) {
    accessToken = await refreshExpiredToken(requestRefresh)
  }

  return accessToken
}

const isRefreshingToken = (): boolean => {
  return isRefreshing
}

const addToQueue = async (
  requestConfig: AxiosRequestConfig,
): Promise<AxiosRequestConfig> => {
  return new Promise((resolve, reject) => {
    promisesQueue.push({ resolve, reject })
  })
    .then((token) => {
      if (requestConfig.headers) {
        requestConfig.headers[HEADER] = `${HEADER_PREFIX}${token}`
      }
      return requestConfig
    })
    .catch(Promise.reject)
}

const getNewAccessToken = async (): Promise<Token | undefined> => {
  try {
    const accessToken = await refreshTokenIfNeeded(requestRefreshToken)
    _resolveQueue(accessToken)

    return accessToken
  } catch (error: unknown) {
    if (error instanceof Error) {
      _declineQueue(error)
      _resetAuthReload()
      throw new Error(
        `Unable to refresh access token for request due to token refresh error: ${error.message}`,
      )
    }
  }
}

const interceptorRequest = async (
  requestConfig: AxiosRequestConfig,
): Promise<AxiosRequestConfig> => {
  if (!getRefreshToken()) {
    return requestConfig
  }

  if (isRefreshingToken()) {
    return addToQueue(requestConfig)
  }

  const accessToken = await getNewAccessToken()

  if (accessToken && requestConfig.headers) {
    requestConfig.headers[HEADER] = `${HEADER_PREFIX}${accessToken}`
  }
  return requestConfig
}

export default interceptorRequest
