// Libraries
import styled from 'styled-components'

type ConfirmationCardProps = {
  alert?: boolean
  open?: boolean
}

export const BodyContainer = styled.div`
  color: ${({ theme }) => theme.colors.text.subdued};
  font-size: ${({ theme }) => theme.fontSize.copy1};
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 2.6rem;
  padding: 1.6rem 0rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0;
    max-width: 41.6rem;
  }
`

export const Container = styled.form<ConfirmationCardProps>`
  padding: 0 2.4rem 8rem;
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 3.2rem;
    width: 47rem;
  }
`

export const CardTop = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 0 2.4rem;
  padding: 2.2rem 1.6rem 1.6rem;
  ::after {
    background-color: ${({ theme }) => theme.colors.border.input};
    content: '';
    height: 1px;
    top: 10.7rem;
    position: absolute;
    width: 100%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    justify-content: flex-start;
    margin: 0 0 1.6rem;
    padding: 0;
    ::after {
      width: 0;
    }
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 4rem;
  justify-content: space-between;
  padding: 3.2rem 0 0;
`
