// Libraries
import styled, { css } from 'styled-components'

// Components
import { Button, Icon } from 'heeds-ds'

export const GoBackButton = styled(Button).attrs(() => ({
  margin: '0',
  size: 'small',
  variation: 'borderless',
}))``

export const GoBackIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.colors.interactive.default,
  iconName: 'chevronLeft',
}))``

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 4rem;
  overflow-y: auto;
  padding: 2.4rem;

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      gap: 2.4rem;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: 4rem 4.8rem 0 4rem;
    }
  `}
`

export const ContainerBottom = styled.div`
  backdrop-filter: blur(1.5px);
  bottom: 0px;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 1.2rem 3.2rem 2.4rem 3.2rem;
  position: fixed;
  width: 100%;
`
