import styled from 'styled-components'

type ContainerProps = {
  active?: boolean
  disabled?: boolean
  margin?: string
  padding?: string
  borderPosition?: 'top' | 'right' | 'bottom' | 'left'
}

type OpenProps = {
  open?: boolean
}

export const Container = styled.div<ContainerProps>`
  background-color: ${({ theme }) => theme.colors.surface.default};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'initial')};
  margin: ${({ margin }) => margin || '0'};
  position: relative;
  width: 100%;
  ${({ borderPosition }) =>
    `border-${borderPosition}` || 'border-left'}: 4px solid
    ${({ active, disabled, theme }) =>
    active && !disabled
      ? theme.colors.icon.default
      : theme.colors.icon.disabled};
  :hover {
    ${({ borderPosition }) =>
      `border-${borderPosition}` || 'border-left'}: 4px solid
      ${({ disabled, theme }) =>
      !disabled ? theme.colors.icon.critical : theme.colors.icon.disabled};
  }
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  border-radius: 2px;
  padding: ${({ padding }) => padding || '16px 24px 16px 16px'};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  :hover {
    background-color: ${({ theme }) => theme.colors.background.default};
  }
`

export const CollapsableContainer = styled.div<OpenProps>`
  width: 100%;
  padding-top: ${({ open }) => (open ? '24px' : 0)};
  max-height: ${({ open }) => (open ? 'min-content' : 0)};
  overflow: hidden;
  transition: all 0.3s ease;
`
