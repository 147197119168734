// Models
import { Props as BaseProps, TPublicProps } from '../BaseBlock'

// React
import { FC } from 'react'

// Libraries
import { useFieldArray, useFormContext } from 'react-hook-form'

// Components
import * as Styled from './styled'
import { Aligner, InputText } from 'heeds-ds'
import BaseBlock from '../BaseBlock'
import EditableOption from '../EditableOption'

interface Props extends Pick<BaseProps, TPublicProps> {
  minOptions?: number
}

const SelectionBoxBlock: FC<Props> = ({ minOptions = 2, name, ...props }) => {
  const { control } = useFormContext()
  const { append, fields, remove } = useFieldArray({
    name: `${name}.allowed_values`,
    control,
  })

  const addField = () => append(`Opção ${fields.length + 1}`)

  const removeField = (fieldId: string) => {
    remove(fields.findIndex((field) => field.id === fieldId))
  }

  return (
    <BaseBlock {...props} mandatoryField name={name} type="select_box">
      <Aligner flex="column" gap="2.4rem">
        <InputText
          displayError={false}
          label="Digite sua pergunta"
          margin="0"
          name={`${name}.question`}
          placeholder="Sua pergunta para o aluno vem aqui"
          scale="small"
        />

        {fields.map(({ id }, index) => {
          return (
            <EditableOption
              key={id}
              name={`${name}.allowed_values.${index}`}
              onRemove={() => removeField(id)}
              showDelete={fields.length > minOptions}
              type="check"
            />
          )
        })}

        <Aligner align="center" gap="0.8rem">
          <Styled.SelectionIndicator />

          <Styled.AddField onClick={addField}>Adicionar opção</Styled.AddField>
        </Aligner>
      </Aligner>
    </BaseBlock>
  )
}

export default SelectionBoxBlock
