// Models
import { TToggleColors } from './index'

// Libraries
import styled from 'styled-components'

// Misc
import { theme } from '../../../../lib/styled-components/theme'

// Components
import { Body } from '../../../index'
import Icon from '../../../UI/Icons'

type ToggleProps = {
  active?: boolean
  radius?: string
  scale?: 'small' | 'medium' | 'large'
  width?: string
  isConflict?: boolean
  toggleColors?: TToggleColors
  moreConflict?: boolean
  slide?: boolean
}

enum EPaddings {
  small = '0.8rem 1.6rem',
  medium = '1.6rem',
  large = '1.6rem 2.4rem',
}

enum EFontSizes {
  small = 10,
  medium = 12,
  large = 16,
}

export const Container = styled.div`
  align-items: center;
  border: 1px solid #e2e8f0;
  border-radius: 15px;
  display: inline-flex;
  gap: 0.4rem;
  padding: 0.9rem 1.6rem;
  width: min-content;
`

export const Label = styled.span<ToggleProps>`
  color: ${({ active, theme }) =>
    (active && theme.colors.text.onPrimary) || theme.colors.text.default};
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: ${({ scale }) => EFontSizes[scale || 'medium']}px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 12px;
  text-transform: uppercase;
  white-space: nowrap;
`

export const Toggle = styled.button.attrs(() => ({
  type: 'button',
}))<ToggleProps>`
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${({ theme, toggleColors }) =>
    theme.colors.text[toggleColors?.background || 'onPrimary']};
  border: 1px solid
    ${({ theme, toggleColors }) =>
      theme.colors.border[toggleColors?.border || 'input']};
  border-radius: ${({ radius }) => radius || '15px'};
  display: inline-flex;
  gap: 0.4rem;
  padding: ${({ scale }) => EPaddings[scale || 'medium']};
  width: ${({ width }) => width || 'min-content'};
  margin-left: ${({ isConflict, active, slide }) =>
    isConflict && active && slide ? '-20%' : '0'};

  :disabled {
    background: ${({ active, theme }) =>
      active ? theme.colors.icon.disabled : theme.colors.icon.onPrimary};

    ${Label} {
      color: ${({ active, theme }) =>
        active ? theme.colors.text.onPrimary : theme.colors.text.disabled};
    }
  }

  :focus {
    box-shadow: 0px 0px 10px
      ${({ theme, isConflict, active }) =>
        isConflict && active
          ? theme.colors.icon.critical
          : theme.colors.text.secondary};
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopSmall}px) {
    margin-left: 0px;
    :hover {
      background: ${({ theme, toggleColors }) =>
        theme.colors.icon[toggleColors?.hoverBackground || 'onPrimary']};
      border: 1px solid
        ${({ theme, toggleColors }) =>
          theme.colors.icon[toggleColors?.hoverBorder || 'disabled']};

      ${Label} {
        color: ${({ active, theme }) =>
          active ? theme.colors.text.onPrimary : theme.colors.text.primary};
      }
    }
  }
`

export const ToggleContainer = styled.div<ToggleProps>`
  display: flex;
  align-items: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-direction: column;
  }
`

export const ConflictTextContainer = styled.div<ToggleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 116px;
    position: absolute;
    margin-top: 0px;
    top: 100%;
    height: 140px;
    width: ${({ moreConflict }) => (moreConflict ? '64px' : '120px')};
    align-items: flex-start;
  }
`

export const IconWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.icon.critical};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
  margin: 0px 8px 0px 16px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: none;
  }
`

export const ConflictIcon = styled(Icon).attrs(() => ({
  iconName: 'warningSolid',
  color: theme.colors.text.onPrimary,
}))``

export const ConflictText = styled(Body).attrs(() => ({
  type: 'copy4',
  color: theme.colors.text.critical,
}))`
  text-align: left;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    text-align: center;
  }
`
