// Models
import { EFinancialStatusToPortuguese } from 'models'
import { IFinancialData } from 'models'

// React
import React, { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { currencyMask } from 'utils/masks'
import { useModal } from 'hooks'

// Components
import * as Styled from './styled'
import { Aligner, Body, Swipe } from 'heeds-ds'
import { ModalMoreOptions } from 'components/modals'

type Props = {
  financial: IFinancialData
  onAddPayment: () => void
  onSendReminder: () => void
}

const FinancialItem: FC<Props> = ({
  financial,
  onAddPayment,
  onSendReminder,
}) => {
  const { due_date, payment_status, plan_value } = financial
  const { openModal } = useModal()
  const theme = useContext(ThemeContext)

  const statusColor = {
    paid: theme.colors.text.success,
    pendent: theme.colors.text.critical,
    due_date: theme.colors.text.primary,
  }

  const valueColor = {
    paid: theme.colors.text.default,
    pendent: theme.colors.text.critical,
    due_date: theme.colors.text.default,
  }

  return (
    <React.Fragment>
      <Swipe
        actions={[
          {
            color: theme.colors.interactive.hovered,
            icon: 'moreHorizontal',
            onClick: () => openModal('financial-item-modal'),
          },
        ]}
        active
        maxHeight="7.2rem"
      >
        <Styled.Container data-testid="financial-item-container">
          <Aligner flex="column" gap="0.8rem">
            <Body
              color={valueColor[payment_status]}
              data-testid="financial-item-plan-value"
              type="copy3"
              weight={600}
            >
              {currencyMask(`${plan_value}`)}
            </Body>

            <Body
              color={theme.colors.text.subdued}
              data-testid="financial-item-due-date"
              type="copy5"
            >
              Vencimento: {due_date}
            </Body>
          </Aligner>

          <Styled.StatusContainer status={payment_status}>
            <Body
              color={statusColor[payment_status]}
              data-testid="financial-item-payment-status"
              type="copy5"
              weight={600}
            >
              {EFinancialStatusToPortuguese[payment_status]}
            </Body>
          </Styled.StatusContainer>
        </Styled.Container>
      </Swipe>

      <ModalMoreOptions
        options={[
          {
            icon: 'add',
            label: 'Adicionar Pagamento',
            onClick: () => {
              onAddPayment()
            },
          },
          {
            icon: 'notificationsNone',
            label: 'Enviar lembrete',
            onClick: () => {
              onSendReminder()
            },
          },
        ]}
      />
    </React.Fragment>
  )
}

export default FinancialItem
