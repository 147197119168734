import styled from 'styled-components'

type ButtonProps = {
  width?: string
}

export const SocialButton = styled.button<ButtonProps>`
  background: ${({ theme }) => theme.colors.surface.default};
  width: ${({ width }) => width || '60px'};
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  flex: 1;
  order: 0;
  cursor: pointer;
  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  :active {
    background-color: ${({ theme }) => theme.colors.surface.pressed};
  }
  :focus {
    background-color: ${({ theme }) => theme.colors.surface.pressed};
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.surface.hovered};
  }
`
