// Models
import { IBaseState } from 'lib/redux/models'
import {
  IAthleteInfo,
  IUpdateAthlete,
  TCreateAthletePayload,
} from 'services/athleteInfo/@types'

export enum EAthleteInfoActionTypes {
  CLEANUP = 'ATHLETE_INFO/CLEANUP',
  FAILURE = 'ATHLETE_INFO/FAILURE',
  FULFILL = 'ATHLETE_INFO/FULFILL',
  REQUEST = 'ATHLETE_INFO/REQUEST',
  SUCCESS = 'ATHLETE_INFO/SUCCESS',
  CREATE_ATHLETE = 'ATHLETE_INFO/CREATE_ATHLETE',
  GET_ATHLETE = 'ATHLETE_INFO/GET_ATHLETE',
  UPDATE_ATHLETE = 'ATHLETE_INFO/UPDATE_ATHLETE',
}

export interface IAthleteInfoState extends IBaseState {
  info?: IAthleteInfo
}

export interface IGetAthletePayload {
  id: number
  successCallback?: (res: IAthleteInfo) => void
}

export interface IAthletePayload extends TCreateAthletePayload {
  successCallback?: (id?: number) => void
}

export interface IUpdateAthletePayload extends IUpdateAthlete {
  successCallback?: (id?: number) => void
}
