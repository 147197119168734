// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { useMediaQuery } from 'heeds-ds/src/hooks'

// Components
import * as Styled from './styled'
import { Skeleton } from 'heeds-ds'
import ProfileCard from 'components/ProfileCard'

const AthleteProfileLoader: FC = () => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  return (
    <Styled.Container>
      <Styled.ProfileSection>
        {isDesktop && <Skeleton height="44px" />}
        <ProfileCard loading={true} name={''} />
      </Styled.ProfileSection>

      <Styled.ProfileSection>
        <Styled.ContentLoader />
        <Styled.ContentLoader />
        <Styled.ContentLoader />
      </Styled.ProfileSection>
    </Styled.Container>
  )
}

export default AthleteProfileLoader
