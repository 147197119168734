import styled from 'styled-components'
import { Button, Icon } from 'heeds-ds'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding-top: 1.6rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 4rem 4.8rem 0 4rem;
  }
`

export const GoBackButton = styled(Button).attrs({
  margin: '0',
  size: 'small',
  variation: 'borderless',
})``

export const GoBackIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.colors.interactive.default,
  iconName: 'chevronLeft',
}))``
