// Models
import { IExerciseData } from 'storage/exercise/models'

// React
import { FC } from 'react'

// Misc
import { capitalizeFirstLetter } from 'utils/helpers/string'

// Components
import * as Styled from './styled'
import { Aligner } from 'heeds-ds'

interface Props {
  exercise: IExerciseData
  onClick?: (exercise: IExerciseData) => void
  size?: 'xsmall' | 'small'
}

const Exercise: FC<Props> = ({ exercise, onClick, size = 'xsmall' }) => {
  const { muscle, name } = exercise
  return (
    <Aligner align="center" gap="1.6rem">
      <Styled.PlayIconButton onClick={() => onClick?.(exercise)} size={size} />

      <Aligner flex="column" gap="0.4rem">
        <Styled.Title>{capitalizeFirstLetter(name)}</Styled.Title>

        <Styled.Description>{capitalizeFirstLetter(muscle)}</Styled.Description>
      </Aligner>
    </Aligner>
  )
}

export default Exercise
