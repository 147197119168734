// Libraries
import * as yup from 'yup'

// Misc
import { commonScheduleSchema, dateValidationSchema } from './general'
import { phoneRegex, timeRegex } from 'utils/regex'
import { testIsGreaterThanStartTime } from './@utils'

export const profileExtraPlanSchema = yup.object().shape({
  days: yup
    .array(yup.string())
    .min(1, 'Selecione os dias do treino')
    .required('Selecione os dias do treino'),
  name: yup.string().required('Digite um nome para o treino.'),
  location: yup.string().required('Digite o local do treino.'),
  start_time: yup
    .string()
    .matches(timeRegex, 'Digite um horário válido')
    .required('Digite o horário inicial.'),
  end_time: yup
    .string()
    .matches(timeRegex, 'Digite um horário válido')
    .test(
      'hour',
      'O horário final deve ser maior que o inicial',
      testIsGreaterThanStartTime,
    )
    .required('Digite o horário final.'),
  price: yup.string().nullable(),
})

export const profileInformationSchema = yup.object().shape({
  name: yup.string(),
  email: yup.string().email('Email inválido').notRequired(),
  phone_number: yup
    .string()
    .matches(phoneRegex, {
      message: 'Digite um número válido',
      excludeEmptyString: true,
    })
    .notRequired(),
  birthdate: dateValidationSchema.nullable(),
  gender: yup.string().nullable(),
  cref: yup.string().notRequired().nullable(),
  cep: yup.string().nullable(),
  city: yup.string().nullable(),
  state: yup.string().nullable(),
  address: yup.string().nullable(),
  complement: yup.string().nullable(),
  instagram: yup.string().nullable(),
})

export const profilePasswordSchema = yup.object().shape({
  newPassword: yup.string().required('Digite uma senha.'),
})

export const profilePlansSchema = yup.object().shape({
  custom_value: yup.string(),
  value: yup.number(),
})

export const profileScheduleSchema = yup.object().shape(commonScheduleSchema)
