// Models
import { IAuthState } from 'storage/auth/models'
import { TOutletContext } from 'layouts/AuthLayout'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useEffect, useLayoutEffect } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext, useParams } from 'react-router-dom'
import jwtDecode from 'jwt-decode'

// Misc
import { registerEmailConfirmation } from 'storage/auth/duck'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import * as Blocks from 'blocks/auth/SignupConfirmation'
import { Loading } from 'heeds-ds'

// Assets
import backgroundImage from 'assets/images/signup-confirmation-background.svg'

const ERROR_MESSAGES = {
  alreadyConfirmed: 'Email é inválido ou usuário já está ativado.',
  expired: 'Signature has expired',
}

type EmailToken = {
  exp: number
}

const SignupConfirmation: FC = () => {
  const { error, loading } = useSelector<IStore, IAuthState>(
    (state) => state.auth,
  )
  const { setLayoutBackground } = useOutletContext<TOutletContext>()
  const { token } = useParams()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const { exp }: EmailToken = jwtDecode(token ? token : '')
  const expirationDate = new Date(exp * 1000)
  const todaysDate = new Date()

  useLayoutEffect(() => {
    setLayoutBackground(backgroundImage)
  }, [isDesktop, setLayoutBackground])

  useEffect(() => {
    if (token) {
      dispatch(registerEmailConfirmation(token))
    }
  }, [dispatch, token])

  if (loading) {
    return <Loading active />
  }

  return (
    <>
      {error === ERROR_MESSAGES.alreadyConfirmed ? (
        <Blocks.AlreadyConfirmed />
      ) : todaysDate < expirationDate ? (
        <Blocks.SuccessfullyConfirmed />
      ) : (
        <Blocks.ExpiredConfirmation />
      )}
    </>
  )
}

export default SignupConfirmation
