// Libraries
import styled from 'styled-components'

type Props = {
  margin?: string
  trackColor?: string
  trackWidth?: number
  dashArray?: string
  dashOffset?: number
}

export const Container = styled.div<Props>`
  width: 100%;
`

export const Circle = styled.circle<Props>`
  cx: 50%;
  cy: 50%;
  r: 15.91549430918954;
  fill: none;
  stroke: ${({ trackColor, theme }) =>
    trackColor || theme.colors.background.default};
  stroke-width: ${({ trackWidth, theme }) =>
    trackWidth || theme.colors.background.default};
  stroke-dasharray: ${({ dashArray }) => dashArray || '100 0'};
  stroke-dashoffset: ${({ dashOffset }) => dashOffset || 25};
`

export const Text = styled.text`
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  text-anchor: middle;
`
