// Libraries
import styled from 'styled-components'

export const ScrollStyle = styled.div`
  .infinite-scroll-component {
    ::-webkit-scrollbar {
      background: ${({ theme }) => theme.colors.action.secondaryDisabled};
      border-radius: 6px;
      height: 0;
      margin-left: 1px;
      width: 0px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.action.secondaryDefault};
      border-radius: 6px;
    }

    @media screen and (min-width: ${({ theme }) =>
        theme.breakpoints.desktop}px) {
      ::-webkit-scrollbar {
        width: 6px;
      }
    }
  }
`
