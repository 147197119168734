// Models
import { ThemeContext } from 'styled-components'

// React
import { FC, useContext } from 'react'

// Misc
import { useMenu } from 'hooks/useMenu'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import * as Styled from './styled'
import { LogoMin } from 'components/UI/Image'
import Navbar from 'components/Navbar'

// Assets
import caduLogoMiniBeta from 'assets/images/logo-cadu-mini-beta.svg'

const Menu: FC = () => {
  const { closeMenu, visible } = useMenu()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  if (isDesktop) {
    return (
      <Styled.Sidebar>
        <LogoMin src={caduLogoMiniBeta} />
        <Navbar />
      </Styled.Sidebar>
    )
  }

  return (
    <>
      <Styled.Backdrop onClick={closeMenu} open={visible} />
      <Styled.Container open={visible}>
        <Styled.GestureBar onClick={closeMenu} onDragEnd={closeMenu} />

        <Navbar />
      </Styled.Container>
    </>
  )
}

export default Menu
