// Models
import { TAthleteCalendar } from 'models'

// React
import { FC, useState, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { getMonthName, weekDays } from 'utils/calendarUtils'

// Components
import * as Styled from './styled'
import { Body } from 'components/UI/Typography'
import { Aligner, Button, IconButton } from 'heeds-ds'
import TimesCard from '../TimeCard'

type MonthType = {
  allAthletesList: TAthleteCalendar[]
  currentWeek: number
  day: number
  id: number
  isToday: boolean
  month: number
  sameTimeList: TAthleteCalendar[]
  weekDay: number
  year: number
}

type calendarType = {
  id: number
  isToday: boolean
  currentWeek: number
  day: number
  month: number
  weekDay: number
  year: number
  allAthletesList: TAthleteCalendar[]
  sameTimeList: TAthleteCalendar[]
}

type Props = {
  dateCallback: (initialDate: string) => void
  children?: React.ReactNode | React.ReactNode[]
  savedAthletes: TAthleteCalendar[]
  startDate: string
  finishDate: string
  calendarList: calendarType[]
  modal?: boolean
}

const MobileSchedule: FC<Props> = (props: Props) => {
  const { dateCallback, startDate, calendarList, savedAthletes, children } =
    props
  const [startYear, startMonth, startDay] = startDate?.split('-') || ''
  const date = new Date()
  const theme = useContext(ThemeContext)
  const allAthletes = savedAthletes.sort(
    (a, b) =>
      parseInt(a.start.split('h').toString()) -
      parseInt(b.start.split('h').toString()),
  )

  const [entryDate, setEntryDate] = useState([
    parseInt(startDay) || date.getDate(),
    parseInt(startMonth) || date.getMonth() + 1,
    parseInt(startYear) || date.getFullYear(),
  ])
  const [entryDay, entryMonth, entryYear] = entryDate
  const [selectedDate, setSelectedDate] = useState(entryDate)
  const [selectedDay, selectedMonth, selectedYear] = selectedDate
  const todayIsSelected =
    selectedDay === date.getDate() &&
    entryMonth === date.getMonth() + 1 &&
    entryYear === date.getFullYear()
  const [selectedDayOfTheWeek, setSelectedDayOfTheWeek] = useState(
    new Date(startDate).getDay() || date.getDay(),
  )

  const handlePreviousNext = (previous?: boolean) => {
    setEntryDate([
      entryDay,
      previous ? entryMonth - 1 : entryMonth + 1,
      entryYear,
    ])
    if (entryMonth === 1 && previous) {
      setEntryDate([entryDay, 12, entryYear - 1])
      dateCallback && dateCallback(`${entryYear - 1}-12-1`)
    } else {
      previous &&
        dateCallback &&
        dateCallback(`${entryYear}-${entryMonth - 1}-1`)
    }
    if (entryMonth === 12 && !previous) {
      setEntryDate([entryDay, 1, entryYear + 1])
      dateCallback && dateCallback(`${entryYear + 1}-1-1`)
    } else {
      !previous &&
        dateCallback &&
        dateCallback(`${entryYear}-${entryMonth + 1}-1`)
    }
  }

  const handleDay = (
    day: number,
    month: number,
    year: number,
    weekDay: number,
  ) => {
    setSelectedDate([day, month, year])
    getMonthName(month)
    setSelectedDayOfTheWeek(weekDay)
    month === entryMonth &&
      dateCallback &&
      dateCallback(`${year}-${month}-${day}`)
  }

  const handleToday = () => {
    setSelectedDate([date.getDate(), date.getMonth() + 1, date.getFullYear()])
    setEntryDate([date.getDate(), date.getMonth() + 1, date.getFullYear()])
    dateCallback &&
      dateCallback(
        `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
      )
  }

  const renderHeader = () => {
    return (
      <Styled.Header>
        <Styled.HeaderTitleContainer>
          <Body type="copy1">Calendário</Body>
        </Styled.HeaderTitleContainer>
        <Aligner width="100%" align="space-between" justify="space-between">
          <Styled.DateContainer>
            <IconButton
              iconName="chevronLeft"
              onClick={() => handlePreviousNext(true)}
              size="small"
              track={buttonClickTracking}
              trackName="display_previous_month"
            />
            <Styled.Title>
              <Body
                type="copy3"
                weight={500}
                color={theme.colors.text.secondary}
              >
                {getMonthName(parseInt(startMonth) - 1)[0].toUpperCase() +
                  getMonthName(parseInt(startMonth) - 1).substring(1) +
                  ' de ' +
                  parseInt(startYear)}
              </Body>
            </Styled.Title>

            <IconButton
              iconName="chevronRight"
              onClick={() => handlePreviousNext()}
              size="small"
              track={buttonClickTracking}
              trackName="display_next_month"
            />
          </Styled.DateContainer>
          <Styled.DisplayTypeContainer outlined={!todayIsSelected}>
            <Button
              margin="0 auto 0 10px"
              onClick={handleToday}
              radius="15px"
              track={buttonClickTracking}
              trackName="show_today_in_calendar"
              variation="outlined"
            >
              <Body
                type="copy5"
                color={
                  theme.colors.text[todayIsSelected ? 'default' : 'subdued']
                }
              >
                Hoje
              </Body>
            </Button>
          </Styled.DisplayTypeContainer>
        </Aligner>
        <Styled.AddNewAthlete>{children}</Styled.AddNewAthlete>
      </Styled.Header>
    )
  }

  const renderMonthList = (month: MonthType[]) => {
    return (
      <Styled.CalendarBody>
        <Styled.CalendarBodyInner>
          <Styled.WeekDays>
            {weekDays().map((week, index) => {
              return (
                <Body
                  type="small"
                  key={index}
                  weight={index === selectedDayOfTheWeek ? 700 : 400}
                  color={
                    index === selectedDayOfTheWeek
                      ? theme.colors.text.secondary
                      : theme.colors.text.subdued
                  }
                >
                  {week.toString().toLocaleUpperCase().split('.')}
                </Body>
              )
            })}
          </Styled.WeekDays>
          <Styled.Days>
            {month?.map((item, index) => {
              const isSelected =
                item.day === selectedDay &&
                item.month === selectedMonth &&
                item.year === selectedYear
              return (
                <Styled.Day
                  key={index}
                  onClick={() =>
                    item.month === entryMonth && item.year === entryYear
                      ? handleDay(item.day, item.month, item.year, item.weekDay)
                      : null
                  }
                >
                  <Body
                    type="copy5"
                    weight={isSelected ? 700 : 400}
                    color={
                      isSelected
                        ? theme.colors.text.secondary
                        : theme.colors.text.subdued
                    }
                    margin="0 0 8px 0"
                  >
                    {item.month === entryMonth && item.year === entryYear
                      ? item.day
                      : null}
                  </Body>
                  <Styled.AthletesTag>
                    {item.allAthletesList
                      ?.slice(0, 3)
                      .map(
                        (
                          itemSavedAthlete: TAthleteCalendar,
                          savedIndex: number,
                        ) => {
                          return item.month === entryMonth &&
                            item.year === entryYear ? (
                            <Styled.Tag
                              key={savedIndex}
                              saved={!itemSavedAthlete.new}
                            ></Styled.Tag>
                          ) : null
                        },
                      )}
                  </Styled.AthletesTag>
                </Styled.Day>
              )
            })}
          </Styled.Days>
        </Styled.CalendarBodyInner>
      </Styled.CalendarBody>
    )
  }

  const calendarListFiltered = calendarList
    .filter(function (a) {
      return a.day >= selectedDay && a.month === entryMonth
    })
    .map((item) => {
      return [
        {
          athletes: item.allAthletesList,
          day: item.day,
          weekDay: item.weekDay,
        },
      ]
    })
    .slice(0, 7 - selectedDayOfTheWeek)

  return (
    <Styled.CalendarContainer data-testid="mobileSchedule">
      {renderHeader()}
      {renderMonthList(calendarList)}
      <TimesCard
        mobile
        isWeek
        week={selectedDayOfTheWeek}
        show={true}
        athleteList={allAthletes}
        athleteListWeekFormat={calendarListFiltered}
        selectedDate={selectedDate}
      />
    </Styled.CalendarContainer>
  )
}

export default MobileSchedule
