// Libraries
import styled from 'styled-components'

export const MenuOptions = styled.div`
  cursor: pointer;
  display: none;
  position: absolute;
  right: 8px;
  top: 16px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: block;
  }
`

export const Options = styled.div<{ visible: boolean }>`
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 4px;
  box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.1);
  color: ${({ theme }) => theme.colors.text.default};
  display: ${({ visible }) => (!visible ? 'none' : 'block')};
  position: absolute;
  right: 0;
  width: 110px;
  z-index: 10;
`

export const OptionItem = styled.div`
  align-items: center;
  border-radius: 4px;
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.copy4};
  gap: 10px;
  height: 50%;
  justify-content: start;
  padding: 12px 10px;
  width: 100%;
  :hover {
    background: ${({ theme }) => theme.colors.surface.highlightSubduedHovered};
  }
`
