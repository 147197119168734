// Libraries
import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

export const Container = styled.div`
  padding: 24px 57px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    bottom: auto;
    right: auto;
    left: auto;
    border-radius: 24px;
    h2 {
      width: 243px;
      text-align: center;
    }
  }
`

export const Image = styled.img`
  width: 60px;
  height: 60px;
  margin-top: 50px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: 0px;
  }
`
