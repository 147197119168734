// Models
import { IAuthState } from 'storage/auth/models'
import { TOutletContext } from 'layouts/AuthLayout'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useLayoutEffect } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useOutletContext } from 'react-router-dom'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { resendConfirmationEmail } from 'storage/auth/duck'
import { urls } from 'routes/paths'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import * as Styled from './styled'
import { Button } from 'heeds-ds'

// Assets
import backgroundImage from 'assets/images/signup-confirmation-background.svg'
import emailSuccess from 'assets/images/email-success.png'

const SignupSuccess: FC = () => {
  const { userData } = useSelector<IStore, IAuthState>((state) => state.auth)
  const { setLayoutBackground, setBackgroundWidth } =
    useOutletContext<TOutletContext>()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const handleResendEmail = () => {
    dispatch(resendConfirmationEmail(userData?.email ?? ''))
  }

  useLayoutEffect(() => {
    setLayoutBackground(backgroundImage)
    setBackgroundWidth('50rem')
  }, [isDesktop, setBackgroundWidth, setLayoutBackground])

  if (!userData) {
    return <Navigate replace to={urls.signup} />
  }

  return (
    <Styled.Container>
      <Styled.Image src={emailSuccess} />
      <Styled.TitlesContainer>
        <Styled.TitleMain>Seja bem-vindo!</Styled.TitleMain>
        <Styled.SubtitleMain>
          Um e-mail foi enviado para <br /> confirmação do seu cadastro.
        </Styled.SubtitleMain>
        <Styled.BodyMain>Não esqueça de olhar o spam!</Styled.BodyMain>
      </Styled.TitlesContainer>
      <Styled.ButtonContainer>
        <Button
          onClick={handleResendEmail}
          size="medium"
          track={buttonClickTracking}
          trackName="resend_registration_confirmation_email"
          variation="borderless"
        >
          Reenviar e-mail
        </Button>
      </Styled.ButtonContainer>
    </Styled.Container>
  )
}

export default SignupSuccess
