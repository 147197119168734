// Models
import { EPlanModelToPortuguese } from 'models'
import { IAthleteInfoState } from 'storage/athleteInfo/models'
import { IAthletePlansState } from 'storage/athletePlans/models'
import { IWorkoutRoutineState } from 'storage/workoutRoutine/models'
import IStore from 'lib/redux/models'

// React
import {
  FC,
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import {
  Outlet,
  generatePath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { cleanup, triggerLoadAthlete } from 'storage/athleteInfo/duck'
import { triggerListWorkoutsModel } from 'storage/workoutModel/duck'
import { triggerLoadAthletePlans } from 'storage/athletePlans/duck'
import { triggerLoadWorkoutRoutine } from 'storage/workoutRoutine/duck'
import { urls } from 'routes/paths'
import { useMediaQuery, useModal } from 'hooks'

// Components
import * as Styled from './styled'
import { Aligner, Avatar, IconButton, Loading } from 'heeds-ds'
import { ModalRoutineInfoMobile } from 'components'
import TabNavigator from 'components/TabNavigator'

export type WorkoutLayoutContext = {
  blocked: boolean
  setBlocked: React.Dispatch<React.SetStateAction<boolean>>
}

type Props = {
  children?: ReactNode | ReactNode[]
}

const WorkoutLayout: FC<Props> = ({ children }) => {
  const { id = '', routine_id = '' } = useParams()
  const {
    info,
    error: errorAthleteInfo,
    loading: loadingAthleteInfo,
  } = useSelector<IStore, IAthleteInfoState>((state) => state.athleteInfo)
  const { plans, loading: loadingPlans } = useSelector<
    IStore,
    IAthletePlansState
  >((state) => state.athletePlans)
  const { workoutRoutine } = useSelector<IStore, IWorkoutRoutineState>(
    (state) => state.workoutRoutine,
  )
  const { breakpoints } = useContext(ThemeContext)
  const { isVisible, openModal } = useModal()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const [blocked, setBlocked] = useState(true)

  const pageMobile = searchParams.get('etapa')

  const STEPS = useMemo(
    () => [
      {
        label: 'Criação da rotina',
        to: generatePath(
          routine_id ? urls.workoutRoutineEdit : urls.workoutRoutineCreate,
          { id, routine_id },
        ),
      },
      {
        label: 'Treinos',
        to: generatePath(urls.workoutRoutineModels, { id, routine_id }),
      },
      {
        label: 'Revisão',
        to: generatePath(urls.workoutRoutineReview, { id, routine_id }),
      },
    ],
    [id, routine_id],
  )

  const rules = useMemo(
    () => ({
      [STEPS[0].to]: false,
      [STEPS[1].to]: Boolean(routine_id === ''),
      [STEPS[2].to]: Boolean(blocked),
    }),
    [STEPS, blocked, routine_id],
  )

  const blockedIndexes = useMemo(
    () =>
      Object.values(rules)
        ?.map((rule, index) => {
          if (rule) return index
          return -1
        })
        ?.filter((rule) => rule > -1),
    [rules],
  )

  const planName = useMemo(() => plans?.[0]?.plan?.plan_model, [plans])

  useEffect(() => {
    if (!loadingAthleteInfo && errorAthleteInfo === '404') {
      dispatch(cleanup())
      navigate(-1)
    }
  }, [dispatch, errorAthleteInfo, loadingAthleteInfo, navigate])

  useLayoutEffect(() => {
    if (id && routine_id) {
      const athleteId = Number(id)
      const routine_pk = Number(routine_id)

      if (!info || info?.id !== athleteId) {
        dispatch(triggerLoadAthlete({ id: athleteId }))
        dispatch(triggerLoadAthletePlans({ id: athleteId }))
      }

      dispatch(triggerLoadWorkoutRoutine({ routine_pk }))
      dispatch(triggerListWorkoutsModel({ routine_pk }))
    }
  }, [dispatch, id, info, routine_id])

  if (loadingPlans || loadingAthleteInfo) {
    return <Loading active />
  }

  return (
    <Styled.Container>
      {(!pageMobile || pageMobile !== '2') && (
        <Styled.Header>
          <Styled.Profile>
            <Avatar name={info?.name ?? ''} scale={'xSmall'} />

            <Aligner gap="0.4rem" flex="column">
              <Styled.Name>{info?.name || ''}</Styled.Name>

              <Styled.Plan>
                {(planName && EPlanModelToPortuguese[planName]) || ''}
              </Styled.Plan>
            </Aligner>

            {pathname === STEPS[1].to && (
              <IconButton
                iconName="info"
                margin="0"
                onClick={() => openModal('routine_info_modal')}
              />
            )}
          </Styled.Profile>

          <Styled.Nav>
            <TabNavigator
              blockedIndexes={blockedIndexes}
              fontSize="copy3"
              items={STEPS}
              itemsPadding={isDesktop ? '0 0 2rem' : '0 1.6rem 2rem 0'}
              padding={isDesktop ? '2rem 2.4rem' : '1.6rem'}
              progress
              showBlockedIcon
            />
          </Styled.Nav>
        </Styled.Header>
      )}

      <Styled.Content>
        {children || (
          <Outlet
            context={{
              blocked,
              setBlocked,
            }}
          />
        )}
      </Styled.Content>

      {isVisible === 'routine_info_modal' && (
        <ModalRoutineInfoMobile workoutRoutine={workoutRoutine} />
      )}
    </Styled.Container>
  )
}

export default WorkoutLayout
