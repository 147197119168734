// Libraries
import styled from 'styled-components'

// Components
import { Body, Subtitle, Title } from 'heeds-ds'

type Props = {
  margin?: string
  width?: string
}

export const Image = styled.img<Props>`
  width: ${({ margin }) => margin || '85.75'}px;
  align-self: center;
  object-fit: contain;
  margin: ${({ margin }) => (margin ? margin : '0 0 32px 0')};
`

export const TitleMain = styled(Title).attrs({
  align: 'center',
})`
  line-height: 5rem;
  color: ${({ theme }) => theme.colors.text.default};
  font-family: ${({ theme }) => theme.fontFamily.barlow};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    line-height: 3rem;
    font-size: ${({ theme }) => theme.fontSize.sh1};
  }
`

export const SubTitleMain = styled(Subtitle).attrs({
  align: 'center',
  type: 'sh3',
})`
  color: ${({ theme }) => theme.colors.text.subdued};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: ${({ theme }) => theme.fontSize.copy1};
  }
`

export const BodyMain = styled(Body).attrs(() => ({
  align: 'center',
}))`
  color: ${({ theme }) => theme.colors.text.subdued};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: ${({ theme }) => theme.fontSize.copy2};
  }
`
