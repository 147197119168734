// Models
import { IFinancialAthlete, TFinancialNotices } from 'storage/financial/models'
import { TFinancialTagInfo } from 'models'

// Misc
import { theme } from 'lib/styled-components/theme'
import { IFinancialMetrics } from 'storage/financial/models'

export const INFO_ATHLETES: TFinancialTagInfo = {
  month: 'Dezembro',
  title: 'Alunos',
  value: '26',
  percent: 11.09,
}

export const INFO_VALUES: TFinancialTagInfo = {
  month: 'Dezembro',
  title: 'Valor estimado',
  value: 'R$ 3.640',
}

export const ATHLETES_NOTICES: TFinancialNotices[] = [
  {
    id: 1,
    avatar: 'file',
    name: 'Lisa',
    message: 'Lisa vence o plano amanhã.',
    created_date: '23/12/2022',
  },
  {
    id: 2,
    avatar: 'file',
    name: 'Adriana',
    message: 'Adriana está com plano vencido.',
    created_date: '21/12/2022',
  },
  {
    id: 3,
    avatar: 'file',
    name: 'Adriana',
    message: 'Adriana está com plano vencido.',
    created_date: '21/12/2022',
  },
  {
    id: 4,
    avatar: 'file',
    name: 'Lisa',
    message: 'Lisa vence o plano amanhã.',
    created_date: '18/12/2022',
  },
  {
    id: 5,
    avatar: 'file',
    name: 'Adriana',
    message: 'Lisa vence o plano amanhã.',
    created_date: '16/12/2022',
  },
  {
    id: 6,
    avatar: 'file',
    name: 'Adriana',
    message: 'Adriana está com plano vencido.',
    created_date: '03/12/2022',
  },
]

export const METRICS: IFinancialMetrics = {
  amount_due: 2160,
  amount_received: 1480,
  athletes: 26,
  athletes_percent: 11.09,
  notices: ATHLETES_NOTICES,
  reference_month: 'Dezembro',
}

export const RECEIVED_VALUES = [
  { label: 'Falta receber', color: theme.colors.icon.disabled, value: 2160 },
  { label: 'Recebido', color: theme.colors.text.secondary, value: 1480 },
]

export const ATHLETES: IFinancialAthlete[] = [
  {
    id: 1,
    due_date: '02/09/2022',
    name: 'Esthera Jackson',
    payment: 'card',
    plan_periodicity: 'monthly',
    plan_value: 140,
    payment_status: 'pendent',
    status: 'active',
  },
  {
    id: 2,
    due_date: '02/09/2022',
    name: 'Alexa Liras',
    payment: 'card',
    plan_periodicity: 'monthly',
    plan_value: 140,
    payment_status: 'due_date',
    status: 'active',
  },
  {
    id: 6,
    due_date: '02/09/2022',
    name: 'Laurent Michael',
    payment: 'card',
    plan_periodicity: 'monthly',
    plan_value: 140,
    payment_status: 'paid',
    status: 'active',
  },
  {
    id: 11,
    due_date: '02/09/2022',
    name: 'Freduardo Hill',
    payment: 'card',
    plan_periodicity: 'monthly',
    plan_value: 140,
    payment_status: 'paid',
    status: 'inactive',
  },
]
