// Models
import { TWorkoutPDF } from 'models'

// React
import React, { FC } from 'react'

//Libraries
import { Document, Image, Page, Text, View } from '@react-pdf/renderer'

// Misc
import { intervalDateToString } from 'utils/helpers/date'
import { prepareWorkoutRoutineToDisplay } from 'filters/workouts'

// Components
import { Styles } from './styled'

// Assets
import logoFull from 'assets/images/logo-cadu.png'

type Props = {
  data: TWorkoutPDF
}

const WorkoutPDF: FC<Props> = ({ data }) => {
  const { routine, workouts } = data
  const { difficulty, goal } = prepareWorkoutRoutineToDisplay(routine)

  const handleNullValue = (
    value: number | number[] | string,
    affix?: string,
  ) => {
    if (value === null || !value) return ' '
    return `${value} ${affix || ''}`
  }

  return (
    <Document>
      <Page style={Styles.body}>
        <View style={Styles.topLine} />

        <View style={Styles.header}>
          <Image style={Styles.image} src={logoFull} />

          <View>
            <Text style={Styles.copy4}>{routine?.name}</Text>

            <Text style={Styles.small}>
              {routine &&
                intervalDateToString(routine.start_date, routine.end_date)}
            </Text>
          </View>

          <View>
            <Text style={Styles.small}>{goal}</Text>
            <Text style={Styles.small}>{difficulty}</Text>
          </View>
        </View>

        {workouts?.map((model, index) => {
          return (
            <View key={index}>
              <View style={Styles.workoutTitle}>
                <Text style={Styles.copy4}>{model.name}</Text>
                <View style={Styles.line} />
              </View>

              {model.workout_set?.map((set, setIndex) => {
                return set.exercises.map((exercise, exerciseIndex) => {
                  return (
                    <View key={setIndex}>
                      <View style={Styles.TableWrapper}>
                        <View
                          style={{
                            width: 214,
                            flexDirection: 'column',
                            marginRight: 48,
                            marginBottom: 8,
                          }}
                        >
                          {setIndex === 0 && (
                            <Text style={Styles.smallTitle}>Exercícios</Text>
                          )}

                          <View
                            style={{
                              flexDirection: 'row',
                              gap: 16,
                            }}
                          >
                            {exerciseIndex === 0 && (
                              <Text style={Styles.smallBlue}>
                                {setIndex + 1}.
                              </Text>
                            )}
                            <Text
                              style={{
                                ...Styles.smallBlue,
                                marginLeft: exerciseIndex !== 0 ? 24 : 0,
                                marginTop: exerciseIndex !== 0 ? 4 : 0,
                              }}
                            >
                              {exercise?.exercise?.name}
                            </Text>
                          </View>

                          <View style={{ marginLeft: 24, marginTop: 4 }}>
                            <Text style={Styles.small}>{set?.comments}</Text>
                          </View>
                        </View>

                        <View
                          style={{
                            width: 50,
                            flexDirection: 'column',
                            marginBottom: 8,
                            alignItems: 'center',
                          }}
                        >
                          {setIndex === 0 && (
                            <Text style={Styles.smallTitle}>Série</Text>
                          )}

                          <Text style={Styles.smallBlack}>
                            {handleNullValue(set.quantity)}
                          </Text>
                        </View>

                        <View
                          style={{
                            width: 85,
                            flexDirection: 'column',
                            marginBottom: 8,
                            alignItems: 'center',
                          }}
                        >
                          {setIndex === 0 && (
                            <Text style={Styles.smallTitle}>Descanso</Text>
                          )}

                          <Text style={Styles.smallBlack}>
                            {handleNullValue(set.interval, 'seg')}
                          </Text>
                        </View>

                        <View
                          style={{
                            width: 85,
                            flexDirection: 'column',
                            marginBottom: 8,
                            alignItems: 'center',
                          }}
                        >
                          {setIndex === 0 && (
                            <Text style={Styles.smallTitle}>Repetição</Text>
                          )}

                          <Text style={Styles.smallBlack}>
                            {handleNullValue(exercise.repetitions)}
                          </Text>
                        </View>

                        <View
                          style={{
                            width: 50,
                            flexDirection: 'column',
                            marginBottom: 8,
                            alignItems: 'center',
                          }}
                        >
                          {setIndex === 0 && (
                            <Text style={Styles.smallTitle}>Carga</Text>
                          )}

                          <Text style={Styles.smallBlack}>
                            {handleNullValue(exercise.weight, 'kg')}
                          </Text>
                        </View>

                        <View
                          style={{
                            width: 85,
                            flexDirection: 'column',
                            marginBottom: 8,
                            alignItems: 'center',
                          }}
                        >
                          {setIndex === 0 && (
                            <Text style={Styles.smallTitle}>Cadência</Text>
                          )}

                          <Text style={Styles.smallBlack}>
                            {handleNullValue(exercise.cadence)}
                          </Text>
                        </View>
                      </View>

                      {model.workout_set?.length !== setIndex + 1 &&
                        set.exercises.length === exerciseIndex + 1 && (
                          <View style={Styles.workoutLineLines} />
                        )}
                    </View>
                  )
                })
              })}
            </View>
          )
        })}
      </Page>
    </Document>
  )
}

export default WorkoutPDF
