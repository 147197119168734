// Libraries
import { Content, PopoverTrigger } from '@radix-ui/react-popover'
import styled from 'styled-components'

enum EFontSizes {
  small = '14px',
  medium = '16px',
  large = '18px',
}

enum EPaddings {
  small = '12px 0px 12px 16px',
  medium = '15px 0px 15px 16px',
  large = '18px 0px 18px 16px',
}

enum EPaddingsWithIcon {
  small = '12px 50px 12px 16px',
  medium = '15px 50px 15px 16px',
  large = '18px 50px 18px 16px',
}

type InputProps = {
  color: string
  disabled?: boolean
  scale?: 'small' | 'medium' | 'large'
  ispassword?: 0 | 1
}

type IconWrapperProps = {
  disabled?: boolean
}

type WrapperProps = {
  borderColor: string
}

export const Input = styled.input<InputProps>`
  background-color: transparent;
  border: none;
  caret-color: #202223;
  color: ${({ theme }) => theme.colors.text.default};
  cursor: text;
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: ${({ scale }) => (scale ? EFontSizes[scale] : EFontSizes.medium)};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  width: 100%;
  outline: none;
  padding: ${({ scale, ispassword }) =>
    scale
      ? ispassword
        ? EPaddingsWithIcon[scale]
        : EPaddings[scale]
      : ispassword
      ? EPaddingsWithIcon.medium
      : EPaddings.medium};
  transition: all 0.3s ease;

  ::placeholder {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.text.disabled : theme.colors.text.subdued};
  }
  :disabled {
    cursor: not-allowed;
    background: ${({ theme }) => theme.colors.surface.disabled};
    color: ${({ theme }) => theme.colors.text.subdued};
  }
  ::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`

export const PopoverContent = styled(Content)`
  box-shadow: rgb(23 24 24 / 5%) -1px 0px 20px, rgb(0 0 0 / 15%) 0px 1px 5px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 2;

  &[data-state='open'][data-side='top'] {
    animation-name: slideDownAndFade;
  }
  &[data-state='open'][data-side='right'] {
    animation-name: slideLeftAndFade;
  }
  &[data-state='open'][data-side='bottom'] {
    animation-name: slideUpAndFade;
  }
  &[data-state='open'][data-side='left'] {
    animation-name: slideRightAndFade;
  }

  @keyframes slideUpAndFade {
    from {
      opacity: 0;
      transform: translateY(2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideRightAndFade {
    from {
      opacity: 0;
      transform: translateX(-2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideLeftAndFade {
    from {
      opacity: 0;
      transform: translateX(2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`

export const IconWrapper = styled(PopoverTrigger)<IconWrapperProps>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: none;
  width: 4rem;
  height: 4rem;
  background-color: transparent;
  position: absolute;
  top: 50%;
  right: 0.4rem;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const InputContainer = styled.div<WrapperProps>`
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 3px;
  display: flex;
  position: relative;
  transition: all 0.3s ease;
  &[aria-disabled='true'] {
    border: 1px solid ${({ theme }) => theme.colors.icon.disabled};
  }
`
