// Models
import { ChangeHandler, RefCallBack } from 'react-hook-form'

// React
import { FC, FormEvent, InputHTMLAttributes, useContext, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Components
import * as Styled from './styled'
import { Body } from '../../UI/Typography'
import InputWrapper from '../InputWrapper'

type EventType = FormEvent<HTMLInputElement>

type Props = InputHTMLAttributes<HTMLInputElement> & {
  column?: boolean
  customLabel?: string[]
  disabled?: boolean
  displayError?: boolean
  gap?: string
  hideOptions?: boolean
  justify?: string
  label?: string
  mandatory?: boolean
  margin?: string
  name?: string
  onChange: ChangeHandler
  options?: string[]
  refName: RefCallBack
  scale?: 'small' | 'medium' | 'large'
  textRadio?: boolean
  value?: string
}

const RadioButton: FC<Props> = (props: Props) => {
  const {
    column,
    customLabel,
    disabled,
    displayError,
    gap,
    hideOptions,
    justify = 'flex-start',
    label,
    mandatory,
    margin,
    name,
    onChange,
    options,
    refName,
    scale,
    textRadio = false,
    value,
  } = props
  const [stateValue, setStateValue] = useState('')

  const theme = useContext(ThemeContext)

  const handleOnChange = (event: EventType) => {
    setStateValue(event.currentTarget.value)
    onChange(event)
  }

  return (
    <InputWrapper
      disabled={disabled}
      displayError={displayError}
      justify={justify}
      label={label}
      mandatory={mandatory}
      margin={margin}
      size={scale}
      width={hideOptions ? '10' : '100%'}
    >
      <Styled.Wrapper
        data-testid="radioWrapper"
        textRadio={textRadio}
        column={column}
        gap={gap}
        scale={scale}
      >
        {options?.map((items, index) => {
          return (
            <Styled.WrapperInner key={index} radio textRadio={textRadio}>
              <Styled.Label htmlFor={items} disabled={disabled}>
                <Styled.Input
                  disabled={disabled}
                  data-testid="radioInput"
                  type="radio"
                  id={items}
                  value={items}
                  onChange={handleOnChange}
                  checked={stateValue === items}
                  ref={refName}
                  name={name}
                  textRadio={textRadio}
                />
                {textRadio ? (
                  <Styled.TextRadioWrapper
                    active={stateValue === items || value === items}
                    scale={scale}
                  >
                    <Body
                      type={scale === 'small' ? 'copy5' : 'copy4'}
                      color={
                        disabled
                          ? theme.colors.text.disabled
                          : stateValue === items || value === items
                          ? theme.colors.text.onPrimary
                          : theme.colors.text.subdued
                      }
                    >
                      {customLabel ? customLabel[index] : items}
                    </Body>
                  </Styled.TextRadioWrapper>
                ) : (
                  <Body
                    type="copy4"
                    color={
                      disabled
                        ? theme.colors.text.disabled
                        : theme.colors.text.default
                    }
                  >
                    {hideOptions ? null : items}
                  </Body>
                )}
              </Styled.Label>
            </Styled.WrapperInner>
          )
        })}
      </Styled.Wrapper>
    </InputWrapper>
  )
}

export default RadioButton
