export const mockIndividualList = [
  {
    name: 'Esthera Jackson',
    storage: [
      {
        label: 'Imagens',
        value: 44.2,
      },
      {
        label: 'Documentos',
        value: 44.2,
      },
    ],
    total: 88.4,
  },
  {
    name: 'Alexa Liras',
    storage: [
      {
        label: 'Imagens',
        value: 0,
      },
      {
        label: 'Documentos',
        value: 0,
      },
    ],
    total: 0,
  },
  {
    name: 'Laurent Michael',
    storage: [
      {
        label: 'Imagens',
        value: 0,
      },
      {
        label: 'Documentos',
        value: 0,
      },
    ],
    total: 0,
  },
  {
    name: 'Freduardo Hill',
    storage: [
      {
        label: 'Imagens',
        value: 44.2,
      },
      {
        label: 'Documentos',
        value: 44.2,
      },
    ],
    total: 88.4,
  },
  {
    name: 'Daniel Thomas',
    storage: [
      {
        label: 'Imagens',
        value: 0,
      },
      {
        label: 'Documentos',
        value: 0,
      },
    ],
    total: 0,
  },
  {
    name: 'Lisa Kudrow',
    storage: [
      {
        label: 'Imagens',
        value: 0,
      },
      {
        label: 'Documentos',
        value: 0,
      },
    ],
    total: 0,
  },
]

export const mockTotalList = [
  {
    label: 'Imagens',
    value: 0,
  },
  {
    label: 'Vídeos',
    value: 0,
  },
  {
    label: 'Documentos',
    value: 0,
  },
]

export const mockPlanInfo = {
  name: 'PersonalBasic',
  monthlyLimit: 200,
  storage: 30,
  monthlyCost: 59.9,
}
