// Libraries
import { Aligner, Body, Button } from 'heeds-ds'

// Components
import styled from 'styled-components'

export const AddButton = styled(Button).attrs(() => ({
  margin: '16px auto',
  size: 'small',
}))``

export const EmptySet = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.subdued,
  type: 'copy3',
  weight: props.theme.fontWeight.regular,
}))`
  max-width: 500px;
  text-align: center;
`

export const Container = styled(Aligner).attrs(() => ({
  flex: 'column',
  gap: '16px',
}))`
  flex-flow: 1;
  flex: 1;
  height: fit-content;
`

export const Tabs = styled(Aligner).attrs(() => ({
  gap: '16px',
  padding: '16px 24px',
}))`
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 15px;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  width: max-content;
`
