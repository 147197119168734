// Libraries
import styled from 'styled-components'

// Components
import { Body } from 'heeds-ds'

export const Container = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.surface.default};
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: 1.5rem;
  box-shadow: 0px 2px 5px 0px rgba(43, 42, 99, 0.12);
  display: flex;
  gap: 1.6rem;
  margin: 0rem 2.4rem;
  padding: 2.4rem 1.6rem;
`

export const Title = styled(Body).attrs(({ theme }) => ({
  color: theme.colors.text.secondary,
  type: 'copy2',
  weight: 700,
}))``
