// Models
import { IAnamneseQuestion } from 'models/anamnese'

// React
import { useCallback } from 'react'

// Components
import * as Styled from './styled'
import * as Answer from './@primitives'
import { Body } from 'heeds-ds'

const QuestionItem = ({
  question,
  required,
  type,
  allowed_values = [],
  answers = [],
}: IAnamneseQuestion) => {
  const renderAnswers = useCallback(() => {
    const answerProps = { allowed_values, answers }

    switch (type) {
      case 'date':
        return <Answer.DateAnswer {...answerProps} />

      case 'dropdown_list':
        return <Answer.DropdownList {...answerProps} />

      case 'measures':
        return <Answer.Measures {...answerProps} />

      case 'multiple_choice':
        return <Answer.MultipleChoices {...answerProps} />

      case 'select_box':
        return <Answer.SelectBox {...answerProps} />

      default:
        return <Answer.TextAnswer {...answerProps} />
    }
  }, [allowed_values, answers, type])

  return (
    <Styled.QuestionWrapper>
      <Body type="copy4">
        {question}{' '}
        {required && <Styled.RequiredBadge>(obrigatório)</Styled.RequiredBadge>}
      </Body>
      {renderAnswers()}
    </Styled.QuestionWrapper>
  )
}

export default QuestionItem
