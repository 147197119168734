// React
import { forwardRef } from 'react'

// Components
import * as Styled from './styled'

type RadioAttrs = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'type' | 'placeholder'
>

type Props = RadioAttrs & {
  'data-testid'?: string
  label?: string
  scale?: 'default' | 'large'
}

const Radio = forwardRef<HTMLInputElement, Props>(
  ({ label, scale = 'default', ...attributes }, ref) => (
    <Styled.Label aria-disabled={attributes.disabled}>
      <Styled.Radio scale={scale} ref={ref} {...attributes} />
      {label}
    </Styled.Label>
  ),
)
Radio.displayName = 'Radio'

export default Radio
