// Libraries
import styled from 'styled-components'

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1.6rem;
  max-height: 70vh;
  overflow-y: auto;
  padding: 54px 24px 56px;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 24px;
    width: 602px;
  }
`
