// Libraries
import { Body, Button } from 'heeds-ds'
import styled from 'styled-components'

type Props = {
  borderColor: 'critical' | 'default'
}

export const CancelButton = styled(Button).attrs(() => ({
  cancel: true,
  margin: '0',
  size: 'small',
  type: 'button',
  variation: 'outlined',
}))``

export const FormContainer = styled.form<Props>`
  border-left: 4px solid
    ${({ borderColor, theme }) => theme.colors.icon[borderColor]};
  border-radius: 2px;
  padding: 2.4rem 1.6rem 3.2rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 1.6rem 2.4rem 2.4rem;
  }
`

export const Title = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.secondary,
  type: 'copy2',
  weight: 600,
}))`
  padding: 0 0 2.4rem;
`
