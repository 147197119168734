import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  border-radius: 15px 15px 0 0;
  background-color: ${({ theme }) => theme.colors.surface.default};
  box-shadow: 0px 0px 15px 0px rgba(43, 42, 99, 0.16);
  padding: 0 3.2rem 4rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    max-width: 80rem;
    margin: 0 auto;
    border-radius: 8px;
    gap: 2.4rem;
    padding: 0 2.4rem 4rem;
  }
`

export const SectionWrapper = styled.div`
  width: 100%;
  padding: 3.2rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`
