// Libraries
import styled from 'styled-components'

// Components
import { Body } from 'heeds-ds'

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 15px;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  padding: 1.6rem 2.4rem;
  margin: 0 0 1.4rem;
  width: 100%;
`

export const Text = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.subdued,
  type: 'copy3',
  weight: 500,
}))``

export const Title = styled(Body).attrs(() => ({
  type: 'copy3',
  weight: 700,
}))``
