// Models
import { IBaseState } from 'lib/redux/models'
import { IGetAthletesResponse } from 'services/athletes/@types'

export enum EAthletesActionTypes {
  CLEANUP = 'ATHLETES/CLEANUP',
  FAILURE = 'ATHLETES/FAILURE',
  FULFILL = 'ATHLETES/FULFILL',
  REQUEST = 'ATHLETES/REQUEST',
  SUCCESS = 'ATHLETES/SUCCESS',
  SUCCESS_LOAD_MORE = 'ATHLETES/SUCCESS_LOAD_MORE',
  GET_ATHLETES = 'ATHLETES/GET_ATHLETES',
  GET_MORE_ATHLETES = 'ATHLETES/GET_MORE_ATHLETES',
  GET_ATHLETE_INFO = 'ATHLETES/GET_ATHLETE_INFO',
  UPDATE_ATHLETES = 'ATHLETES/UPDATE_ATHLETES',
}

export interface IAthletesState extends IBaseState {
  athletes?: IGetAthletesResponse
  athleteInfo?: IAthleteInfo
}

export interface IAthleteInfo {
  id: number
}
