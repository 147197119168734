import styled from 'styled-components'

type Props = {
  opacity?: boolean
}

export const Container = styled.div<Props>`
  display: flex;
  font-family: Arial;
  font-size: 40px;
  justify-content: center;
  opacity: ${({ opacity }) => (opacity ? 0.5 : 1)};
`
