// Models
import { TDisplayModes } from '..'

// React
import { FC } from 'react'

// Components
import * as Styled from '../styled'

// Constants
import { createYearsRange } from '../../../utils/helpers/date'

type Props = {
  displayMode: TDisplayModes
  displayingYear: number
  setDisplayMode: React.Dispatch<React.SetStateAction<TDisplayModes>>
  setDisplayingYear: React.Dispatch<React.SetStateAction<number>>
  startDate?: Date | null
  today: Date
}

const CalendarYarnPage: FC<Props> = ({
  displayMode,
  displayingYear,
  setDisplayMode,
  setDisplayingYear,
  startDate,
  today,
}) => {
  return (
    <>
      {displayMode === 'year' &&
        createYearsRange(displayingYear).map((year) => {
          const isSelected = year === startDate?.getFullYear()

          return (
            <Styled.CalendarItem
              aria-selected={isSelected}
              key={year.toString()}
              onClick={() => {
                setDisplayingYear(year)
                setDisplayMode('month')
              }}
              isToday={today.getFullYear() === year}
            >
              {year}
            </Styled.CalendarItem>
          )
        })}
    </>
  )
}

export default CalendarYarnPage
