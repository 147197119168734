import { Moment } from 'moment'

export function extractDate(date: string | null) {
  if (date?.includes('/')) return date?.split('/')

  if (date?.includes('-')) return date?.split('-').reverse()

  return ['0', '0', '0']
}

export function greaterThan(start_date: string, end_date: string) {
  const [startDay, startMonth, startYear] = start_date.split('/')
  const [endDay, endMonth, endYear] = end_date.split('/')
  const startDate = new Date(`${startYear}-${startMonth}-${startDay}`)
  const endDate = new Date(`${endYear}-${endMonth}-${endDay}`)
  return endDate > startDate
}

export function intervalDateToString(
  startDate: string | null,
  endDate: string | null,
) {
  if (!startDate && !endDate) return ''

  if (startDate && !endDate) {
    const start = new Date(
      `${extractDate(startDate).reverse().join('-')} 00:01`,
    )

    return `A partir de ${start.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    })}`
  }

  if (!startDate && endDate) {
    const end = new Date(`${extractDate(endDate).reverse().join('-')} 00:01`)

    return `Até ${end.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    })}`
  }

  const startString = extractDate(startDate).join('/')
  const endString = extractDate(endDate).join('/')

  return `${startString} - ${endString}`
}

export function removeYearFromDateString(date: Moment) {
  return date.format('LL').replace(/[a-z]{2} \d{4}/, '')
}
