// Libraries
import styled from 'styled-components'

export const InnerCard = styled.div`
  align-self: center;
  background: ${({ theme }) => theme.colors.surface.default};
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 1.5rem;
  margin: 0px 2.4rem;
  padding: 1.6rem 2.4rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;
`

export const TextBackground = styled.div`
  padding: 0.8rem 1.6rem;
  background: ${({ theme }) => theme.colors.surface.neutralSubdued};
  border-radius: 1.6rem;
`
