// React
import { FC } from 'react'

// Components
import {
  Background,
  Container,
  Label,
  LabelContainer,
  Progress,
} from './styled'

type Props = {
  margin?: string
  max: number
  maxLabel?: string
  unity?: string
  value: number
  valueLabel?: string
}

const ProgressBar: FC<Props> = (props: Props) => {
  const { margin, max, maxLabel, unity, value, valueLabel } = props

  const progress = (value * 100) / max

  return (
    <Container margin={margin} data-testid="container">
      <Background>
        <Progress value={progress} data-testid="progress" />
      </Background>
      {valueLabel && maxLabel && unity && (
        <LabelContainer>
          <Label data-testid="value-label">{`${value}${unity} ${valueLabel}`}</Label>
          <Label data-testid="max-label">{`${max}${unity} ${maxLabel}`}</Label>
        </LabelContainer>
      )}
    </Container>
  )
}

export default ProgressBar
