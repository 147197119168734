// Libraries
import styled, { css } from 'styled-components'

// Components
import { Body } from '../UI/Typography'

interface Props {
  active?: boolean
  scale?: 'small' | 'large'
}

enum EFontSizes {
  large = 'copy4',
  small = 'copy5',
}

enum EHeightSizes {
  large = '4.2rem',
  small = '3.4rem',
}

export const Container = styled.button<Props>`
  align-items: center;
  border-radius: 16px;
  cursor: pointer;
  display: inline-flex;
  gap: 16px;
  justify-content: center;
  padding: 8px 16px;
  white-space: nowrap;

  ${({ active, scale, theme }) => css`
    background: ${active
      ? theme.colors.badge.purpleBackground
      : theme.colors.surface.default};
    border: 1px solid ${theme.colors.badge.purpleText};
    height: ${EHeightSizes[scale || 'large']};

    :hover {
      background: ${theme.colors.action.highlightSofblue};
    }

    :focus {
      box-shadow: 0px 0px 5px 0px #2b2a63;
      outline: 1px solid ${theme.colors.badge.purpleText};
    }
  `}
`

export const Text = styled(Body).attrs<Props>(({ scale }) => ({
  type: EFontSizes[scale || 'large'],
  weight: 700,
}))<Props>`
  color: ${({ theme }) => theme.colors.badge.purpleText};
`
