// Models
import { EWorkoutSetFields, ISetToExerciseInput } from 'models'
import { IExerciseData } from 'storage/exercise/models'

// React
import { FC, useMemo } from 'react'

// Components
import * as Styled from './styled'
import { Aligner } from 'heeds-ds'
import { Exercise } from 'components'

interface Props {
  interval?: { measure?: string; value?: string }
  openGifModal?: (exercise: IExerciseData) => void
  quantity?: string
  setToExercise: ISetToExerciseInput
  type?: string
}

const WorkoutSetToExerciseReview: FC<Props> = ({
  interval,
  openGifModal,
  quantity,
  setToExercise,
  type,
}) => {
  const { id, exercise, ...fields } = setToExercise

  const quantityField = useMemo(
    () =>
      quantity ? (
        <Styled.FieldItem key="quantity">
          <Styled.Text header>{EWorkoutSetFields.quantity}</Styled.Text>
          <Styled.Text>{quantity}</Styled.Text>
        </Styled.FieldItem>
      ) : null,
    [quantity],
  )

  const intervalField = useMemo(
    () =>
      interval?.value ? (
        <Styled.FieldItem key="interval">
          <Styled.Text header>{EWorkoutSetFields.interval}</Styled.Text>
          <Styled.Text>{interval.value} seg</Styled.Text>
        </Styled.FieldItem>
      ) : null,
    [interval],
  )

  const renderSetToExerciseFields = useMemo(() => {
    const setToExerciseFields = Object.entries(fields)
      .filter(([, value]) => !!value)
      .map(([key, value], index) => {
        const maskedValue = key === 'weight' ? `${value} kg` : value
        return (
          <Styled.FieldItem key={id + index}>
            <Styled.Text header>
              {EWorkoutSetFields[key as keyof typeof EWorkoutSetFields]}
            </Styled.Text>
            <Styled.Text>{maskedValue}</Styled.Text>
          </Styled.FieldItem>
        )
      })

    return type === 'normal'
      ? [quantityField, intervalField, ...setToExerciseFields]
      : setToExerciseFields
  }, [fields, id, intervalField, quantityField, type])

  return (
    <Aligner flex="column" gap="1.6rem">
      <Exercise exercise={exercise} onClick={openGifModal} size="small" />

      <Styled.FieldsContainer>
        {renderSetToExerciseFields}
      </Styled.FieldsContainer>
    </Aligner>
  )
}

export default WorkoutSetToExerciseReview
