// Models
import { IBaseState } from 'lib/redux/models'
import { IPaginatedResponse } from 'models/api'

export enum EPhysicalAssessmentActionTypes {
  CLEANUP = 'PHYSICAL_ASSESSMENT/CLEANUP',
  FAILURE = 'PHYSICAL_ASSESSMENT/FAILURE',
  FULFILL = 'PHYSICAL_ASSESSMENT/FULFILL',
  REQUEST = 'PHYSICAL_ASSESSMENT/REQUEST',
  SUCCESS = 'PHYSICAL_ASSESSMENT/SUCCESS',
  CREATE = 'PHYSICAL_ASSESSMENT/CREATE',
  GET = 'PHYSICAL_ASSESSMENT/GET',
  UPDATE = 'PHYSICAL_ASSESSMENT/UPDATE',
  PARTIAL_UPDATE = 'PHYSICAL_ASSESSMENT/PARTIAL_UPDATE',
  DELETE = 'PHYSICAL_ASSESSMENT/DELETE',
  LOAD = 'PHYSICAL_ASSESSMENT/LOAD',
  LOAD_MORE = 'PHYSICAL_ASSESSMENT/LOAD_MORE',
  SUCCESS_LOAD_MORE = 'PHYSICAL_ASSESSMENT/SUCCESS_LOAD_MORE',
}

export interface IPhysicalAssessmentState
  extends IBaseState,
    IPaginatedResponse {
  physicalAssessment?: IPhysicalAssessment
  physicalAssessments?: IPhysicalAssessment[]
}

export interface IAssessment {
  type: string
  measurement_unit: string
  value: number
}

export interface IPhysicalAssessmentSection {
  name: string
  assessments: IAssessment[]
}

export interface IPhysicalAssessment {
  id: string
  title: string
  date?: string
  athlete: number
  physical_assessment: IPhysicalAssessmentSection[]
}
