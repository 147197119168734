// Models
import { IExerciseData } from 'storage/exercise/models'

// React
import { FC, useContext, useEffect, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { useMediaQuery } from 'hooks'

// Components
import * as Styled from './styled'
import { Aligner, IconButton } from 'heeds-ds'

interface Props {
  checked?: boolean
  endLine?: boolean
  exercise: IExerciseData
  margin?: string
  onClick: (exercise: IExerciseData) => void
  onClickImage?: (exercise: IExerciseData) => void
  padding?: string
  shadow?: boolean
}

const ExerciseItem: FC<Props> = ({
  checked,
  exercise,
  onClick,
  onClickImage,
  ...restContainerProps
}) => {
  const { name, muscle, thumbnail } = exercise
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const [clicked, setClicked] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    if (onClick) {
      setClicked(true)
      onClick(exercise)
    }
  }

  const handleClickImage = (event: React.MouseEvent) => {
    event.stopPropagation()
    onClickImage?.(exercise)
  }

  useEffect(() => {
    let timeout: NodeJS.Timeout

    if (clicked) timeout = setTimeout(() => setClicked(false), 2000)

    return () => clearTimeout(timeout)
  }, [clicked])

  return (
    <Styled.Container {...restContainerProps} data-testid="exercise-container">
      {checked && (
        <Aligner padding="0 8px 0 0" width="auto">
          <Styled.CheckIcon />
        </Aligner>
      )}

      <Styled.Media onClick={handleClickImage} thumbnail={thumbnail}>
        <Styled.PlayIcon size={isDesktop ? 24 : 20} />
      </Styled.Media>

      <Styled.Label>
        <Styled.Title>
          {name ? name[0].toUpperCase() + name.slice(1) : ''}
        </Styled.Title>

        <Styled.Description>
          {muscle ? muscle[0].toUpperCase() + muscle.slice(1) : ''}
        </Styled.Description>
      </Styled.Label>

      <IconButton
        data-testid="exercise-add-button"
        iconName={clicked ? 'check' : 'add'}
        margin="0"
        onClick={(event) => event && handleClick(event)}
        size="small"
        track={buttonClickTracking}
        trackName="add_exercise_to_workout_set"
        variation={clicked ? 'success' : 'outlined'}
      />
    </Styled.Container>
  )
}

export default ExerciseItem
