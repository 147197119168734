// React
import { FC } from 'react'

// Misc
import {
  createDatesInteval,
  getDatesDistance,
  WEEKDAYS_COLLECTION,
} from '../../../utils/helpers/date'

// Components
import * as Styled from '../styled'

type Props = {
  days: Date[]
  displayingMonth: number
  displayingYear: number
  endDate?: Date | null
  range?: boolean
  setDisplayingMonth: React.Dispatch<React.SetStateAction<number>>
  setDisplayingYear: React.Dispatch<React.SetStateAction<number>>
  setEndDate: React.Dispatch<React.SetStateAction<Date | undefined>>
  setStartDate: React.Dispatch<React.SetStateAction<Date | undefined>>
  startDate?: Date | null
  today: Date
}

const CalendarDayPage: FC<Props> = ({
  days,
  displayingMonth,
  displayingYear,
  endDate,
  range,
  setDisplayingMonth,
  setDisplayingYear,
  setEndDate,
  setStartDate,
  startDate,
  today,
}) => {
  const intevalDays =
    startDate && endDate && createDatesInteval(startDate, endDate)

  const handleClick = (isEdgeDay: boolean, day: Date) => {
    if (isEdgeDay) {
      const isOnNextMonth =
        getDatesDistance(new Date(displayingYear, displayingMonth), day) > 0
      const modifier = isOnNextMonth ? 1 : -1
      const auxDate = new Date(displayingYear, displayingMonth + 1 * modifier)

      setDisplayingMonth(auxDate.getMonth())
      setDisplayingYear(auxDate.getFullYear())
      return
    }

    if (range) {
      if (startDate && !endDate) {
        if (getDatesDistance(startDate, day) > 0) {
          setEndDate(day)
        } else {
          setStartDate(day)
        }
        return
      }

      if (startDate && endDate) {
        if (getDatesDistance(endDate, day) > 0) {
          setEndDate(day)
        } else {
          setStartDate(day)
          setEndDate(undefined)
        }
        return
      }
    }

    setStartDate(day)
  }

  return (
    <>
      {Object.values(WEEKDAYS_COLLECTION).map((weekday) => (
        <Styled.Weekday key={weekday.full}>{weekday.symbol}</Styled.Weekday>
      ))}

      {days.map((day) => {
        const isEdgeDay = day.getMonth() !== displayingMonth
        const isIncludedOnRange =
          range && intevalDays?.includes(day.toDateString())

        const isSelected = day.toDateString() === startDate?.toDateString()
        const isStartDate =
          range && day.toDateString() === startDate?.toDateString()
        const isEndDate =
          range && day.toDateString() === endDate?.toDateString()

        return (
          <Styled.DayItem
            aria-selected={isSelected || isEndDate}
            edge={isEdgeDay}
            endDay={isEndDate}
            key={day.toString()}
            interval={isIncludedOnRange}
            isToday={!(today > day) && !(today < day)}
            onClick={() => handleClick(isEdgeDay, day)}
            startDay={isStartDate}
          >
            {day.getDate()}
          </Styled.DayItem>
        )
      })}
    </>
  )
}

export default CalendarDayPage
