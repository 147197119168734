// React
import { ReactNode } from 'react'

// Components
import * as Styled from '../styled'

type Props = {
  isActive?: boolean
  children: ReactNode | ReactNode[]
  icon: ReactNode
  label?: string
  position?: 'top' | 'bottom'
}

const Submenu = ({
  children,
  icon,
  isActive,
  label,
  position = 'top',
}: Props) => {
  return (
    <Styled.ItemWrapper stacked>
      <Styled.SubmenuTrigger
        data-active={isActive}
        onClick={(event) => event.preventDefault()}
        stacked
      >
        <Styled.IconWrapper>{icon}</Styled.IconWrapper>

        <Styled.Label>{label}</Styled.Label>
      </Styled.SubmenuTrigger>

      <Styled.Content data-position={position}>
        <Styled.ContentList>{children}</Styled.ContentList>
      </Styled.Content>
    </Styled.ItemWrapper>
  )
}

export default Submenu
