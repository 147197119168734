// Models
import {
  EAnamneseQuestionTypeToPortuguese,
  TAnamneseQuestionType,
} from 'models'

// React
import { FC, useState } from 'react'

// Components
import * as Styled from './styled'
import { Aligner, Button, Icon } from 'heeds-ds'
import Modal from '../Modal'

// Assets
import { ICON_TYPES } from 'components/form/BaseBlock'

interface Props {
  onCancel: () => void
  onSelect: (type: TAnamneseQuestionType) => void
}

const ModalNewQuestion: FC<Props> = ({ onCancel, onSelect }) => {
  const [selected, setSelected] = useState<TAnamneseQuestionType>()

  return (
    <Modal showExit>
      <Styled.Header>
        <Styled.Title>Perguntas</Styled.Title>
      </Styled.Header>

      <Aligner padding="4rem 0 8rem" flex="column">
        <Styled.Description>
          Selecione qual tipo de pergunta você quer adicionar agora.
        </Styled.Description>

        <Styled.Scroll>
          {Object.entries(EAnamneseQuestionTypeToPortuguese).map(
            ([type, title]) => {
              const questionType = type as TAnamneseQuestionType
              const isActive = selected === questionType

              return (
                <Styled.Item
                  active={isActive}
                  key={type}
                  onClick={() => setSelected(questionType)}
                >
                  <Icon iconName={ICON_TYPES[questionType]} />

                  <Styled.Title active={isActive}>{title}</Styled.Title>
                </Styled.Item>
              )
            },
          )}
        </Styled.Scroll>

        <Aligner justify="space-between" gap="2.4rem" padding="2rem 2.4rem 0">
          <Button cancel onClick={onCancel} variation="borderless">
            Cancelar
          </Button>

          <Button
            disabled={!selected}
            onClick={() => selected && onSelect(selected)}
            width="100%"
          >
            Salvar
          </Button>
        </Aligner>
      </Aligner>
    </Modal>
  )
}

export default ModalNewQuestion
