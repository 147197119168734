// Libraries
import styled from 'styled-components'

// Components
import { Aligner } from '../../components/UI/Containers'

type TableProps = {
  color?: string
  hideLine?: boolean
  lineHeader?: boolean
  paginationGap?: number
  rowGap?: string
  height?: string
}

export const TableWrapper = styled(Aligner).attrs(() => ({
  flex: 'column',
  align: 'center',
}))`
  margin-bottom: 150px;
  position: relative;
`

export const TableContainer = styled.table<TableProps>`
  border-collapse: separate;
  border-spacing: ${({ rowGap }) => (rowGap ? `0 ${rowGap}` : 0)};
  width: 100%;
`

export const TableHead = styled.thead``

export const TableBody = styled.tbody<TableProps>`
  margin-top: 31px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: 0px;
  }

  tr {
    position: relative;
    :nth-child(1) {
      ::after {
        width: 0;
      }
    }
    ::after {
      content: '';
      position: absolute;
      width: 100%;
      height: ${({ hideLine }) => (hideLine ? '0px' : '1px')};
      background-color: ${({ theme }) => theme.colors.border.input};
      right: 0;
      bottom: 100%;
    }
  }
`

export const TableLine = styled.tr`
  position: relative;
  width: 0%;
  height: 1px;
`

export const LineSection = styled.th<TableProps>`
  padding: 4px 0;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: ${({ lineHeader }) => (lineHeader ? '0 0 8px 0' : '16px 0')};
  }
`

export const PaginationWrapper = styled.div<TableProps>`
  position: ${({ paginationGap }) => (paginationGap ? 'absolute' : '')};
  bottom: ${({ paginationGap }) => (paginationGap ? -paginationGap : '')}px;
`

export const Content = styled.div<TableProps>`
  text-align: left;
  text-transform: none;
  margin: 0;
  font-weight: 700;
  color: ${({ theme, color }) => color || theme.colors.text.default};
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: ${({ theme }) => theme.fontSize.copy4};
  line-height: ${({ theme }) => theme.lineHeight.copy4};
`

export const InfiniteScrollContainer = styled.div<TableProps>`
  height: ${({ height }) => height};
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.action.secondaryDisabled};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.action.secondaryDefault};
  }
`
