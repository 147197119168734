// Libraries
import styled from 'styled-components'

// Assets

export const Container = styled.div`
  width: 100%;
  height: 3.8rem;
  display: grid;
  grid-template-columns: repeat(4, min-content);
  column-gap: 16px;
  justify-content: center;
  align-content: center;
  align-items: space-between;
  user-select: none;
`

export const Field = styled.div`
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  color: ${({ color, theme }) => color || theme.colors.text.disabled};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
`

export const Symbol = styled.p`
  font-weight: 600;
  font-size: 1.4rem;
  margin: 0 0 6px 0;
`
