// React
import { FC, ReactNode, useContext, useState } from 'react'

// Libraries
import { Outlet } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

// Components
import * as Styled from './styled'
import { Aligner, LogoFull, LogoMin } from 'heeds-ds'

// Misc
import { useMediaQuery } from 'heeds-ds/src/hooks'

// Assets
import backgroundImage from 'assets/images/login-background.svg'
import logoFullBeta from 'assets/images/logo-cadu-beta.svg'
import logoMiniBeta from 'assets/images/logo-cadu-mini-beta.svg'

export type TOutletContext = {
  setBackgroundWidth: (arg: string) => void
  setLayoutBackground: (arg: string) => void
  setLayoutTitles: (arg: ReactNode) => void
  setShowLogo: (arg: boolean) => void
}

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}

const AuthLayout: FC<Props> = ({ children }) => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const [backgroundWidth, setBackgroundWidth] = useState()
  const [layoutBackground, setLayoutBackground] = useState(backgroundImage)
  const [layoutTitles, setLayoutTitles] = useState(null)
  const [showLogo, setShowLogo] = useState(true)

  return (
    <Styled.Container>
      <Styled.Header>
        {showLogo && isDesktop ? (
          <LogoFull src={logoFullBeta} />
        ) : (
          showLogo && <LogoMin src={logoMiniBeta} />
        )}
      </Styled.Header>

      <Styled.Content>
        {layoutTitles ? (
          <Aligner width="50%">{layoutTitles}</Aligner>
        ) : (
          <Styled.LayoutImage width={backgroundWidth} src={layoutBackground} />
        )}
        <Styled.FormContainer>
          {children || (
            <Outlet
              context={{
                setBackgroundWidth,
                setLayoutBackground,
                setLayoutTitles,
                setShowLogo,
              }}
            />
          )}
        </Styled.FormContainer>
      </Styled.Content>
    </Styled.Container>
  )
}

export default AuthLayout
