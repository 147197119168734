// Libraries
import styled from 'styled-components'

type ContainerProps = {
  backgroundImage?: string
}

export const LogoHeader = styled.img`
  align-self: start;
  height: 10.7rem;
  padding-left: 1.6rem;
  object-fit: contain;
  margin-top: 0rem;
`

export const LogoFooter = styled.img`
  align-self: end;
  height: 26.3rem;
  padding-right: 2.4rem;
  width: 23.1rem;
`

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 3.6rem - 20px);
  width: 100%;
  ::before {
    background-color: ${({ theme }) => theme.colors.background.default};
    position: absolute;
    bottom: 0px;
    top: 0px;
    right: 0px;
    left: 0px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    justify-content: center;
    max-width: 1366px;
    margin: 0 auto;
    min-height: 76.8rem;
    padding: 4rem 8%;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.background.default};
    background-image: url(${({ backgroundImage }) => backgroundImage || ''});
    background-position: right center;
    background-size: 46%;
    background-repeat: no-repeat;
    ::before {
      display: none;
    }
    & ${LogoHeader} {
      display: none;
    }
    & ${LogoFooter} {
      display: none;
    }
  }
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.text.default};
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: 4.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  text-align: center;
  margin: 4.4rem 0 0 0;
  line-height: ${({ theme }) => theme.lineHeight.h1};
`

export const Subtitle = styled.h1`
  color: ${({ theme }) => theme.colors.text.subdued};
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: ${({ theme }) => theme.fontSize.sh3};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: ${({ theme }) => theme.lineHeight.copy2};
  text-align: center;
  margin: 2.4rem 0 0 0;
`

export const Content = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;
  background: 'transparent';
  height: 52.4rem;
  padding: 4.1rem 7%;
  justify-content: center;
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    align-items: center;
    background-color: white;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.1);
    border-radius: 2.4rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    max-height: 59rem;
    max-width: 53rem;
    padding: 4rem 7%;
    position: relative;
    width: 55%;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 4rem 0 0 0;
  gap: 3.2rem;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    align-items: center;
    display: flex;
    gap: 3.2rem;
    flex-direction: row;
    margin: 4.8rem 0 0 0;
  }
`

export const AceessDisabled = styled.img`
  height: 7.2rem;
  width: 7.6rem;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    height: 8.9rem;
    width: 9.3rem;
    align-self: center;
    object-fit: contain;
  }
`

export const LogoImage = styled.img`
  height: 2.5rem;
  width: 2rem;
  align-self: center;
  object-fit: contain;
`

export const Button = styled.a`
  align-items: center;
  border: 0.1rem solid;
  background: white;
  border-radius: 0.7rem;
  border-color: ${({ theme }) => theme.colors.text.secondary};
  column-gap: 1rem;
  color: ${({ theme }) => theme.colors.text.secondary};
  cursor: pointer;
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: ${({ theme }) => theme.fontSize.copy3};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  flex-direction: 'row';
  padding: 0.8rem 1.2rem;
  justify-content: center;
  user-select: none;
  white-space: nowrap;
  text-decoration: none;
`
