// Libraries
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 2.4rem;
  background-color: transparent;
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  justify-items: center;
  align-items: center;
  gap: 1.6rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: none;
  }
`

export const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const Pagetitle = styled.h1`
  color: ${({ theme }) => theme.colors.text.default};
  font-family: ${({ theme }) => theme.fontFamily.barlow};
  font-size: 1.8rem;
  font-weight: 800;
  letter-spacing: 1.8px;
  text-align: center;
  text-transform: uppercase;
`
