// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body } from 'heeds-ds'

export const Container = styled(Aligner).attrs(() => ({
  flex: 'column',
  width: '100%',
}))`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 55.6rem;
  }
`

export const Header = styled(Aligner).attrs({
  flex: 'column',
})`
  gap: 1.6rem;
  padding: 1.6rem 2.4rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 0.4rem;
    padding: 2.4rem;
  }
`

export const Title = styled(Body).attrs({
  type: 'copy1',
  weight: 600,
})`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    text-align: center;
  }
`

export const Description = styled(Body).attrs(({ theme }) => ({
  color: theme.colors.text.subdued,
  type: 'copy2',
}))`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    text-align: center;
  }
`

export const FooterActions = styled(Aligner).attrs({
  align: 'center',
  gap: '2.4rem',
  justify: 'flex-end',
  padding: '2.4rem 3.2rem 8rem',
})`
  border-top: 1px solid ${({ theme }) => theme.colors.border.input};
  flex-direction: column-reverse;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: row;
    gap: 3.2rem;
    padding: 1.6rem 2.4rem;
  }
`
