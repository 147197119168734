// Libraries
import { ToggleGroup } from 'heeds-ds'
import styled from 'styled-components'

type Props = {
  newAthlete?: boolean
  margin?: string
}

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
`

export const Line = styled.div`
  background-color: ${({ theme }) => theme.colors.text.subdued};
  height: 2px;
  width: 8px;
  margin-bottom: 16px;
`

export const ModalTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  margin: 30px 0 32px;
  color: ${({ theme }) => theme.colors.text.secondary};
  svg {
    display: none;
  }
  ::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1.5px;
    top: 100%;
    background-color: ${({ theme }) => theme.colors.icon.disabled};
    opacity: 0.3;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 0 0 32px;
    padding: 32px 0px 0px 0px;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 406px;
    svg {
      display: block;
      position: absolute;
      right: 0;
      cursor: pointer;
    }
    ::after {
      width: 0%;
    }
  }
`

export const NewTimeContainer = styled.div`
  width: 100%;
  padding: 0 24px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    min-width: 500px;
  }
`

export const IconWrapper = styled.div<Props>`
  margin: ${({ margin }) => margin};
  cursor: pointer;
`

export const DaysToggle = styled(ToggleGroup).attrs((props) => ({
  color: props.theme.colors.icon.onPrimary,
}))`
  background-color: #f8f8;
  padding: 100px;
`
