// React
import { FC } from 'react'

// Misc
import { renderInitials } from 'utils/functions'

// Custom
import * as Styled from './styled'

enum EAvatarSizes {
  xXSmall = 20,
  xSmall = 40,
  small = 52,
  medium = 64,
  large = 80,
  xLarge = 100,
  xXLarge = 120,
}

enum EFontSizes {
  xXSmall = '1rem',
  xSmall = '1.4rem',
  small = '1.8rem',
  medium = '1.8rem',
  large = '3.6rem',
  xLarge = '3.6rem',
  xXLarge = '3.6rem',
}

type Sizes =
  | 'xXSmall'
  | 'xSmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xLarge'
  | 'xXLarge'

export type Props = React.ImgHTMLAttributes<HTMLImageElement> & {
  active?: boolean
  color?: string
  disabled?: boolean
  hoverMode?: boolean
  margin?: string
  name: string
  onClick?: () => void
  scale?: Sizes
}

const Avatar: FC<Props> = ({
  active,
  color,
  disabled,
  hoverMode,
  margin,
  name,
  onClick,
  scale = 'medium',
  ...props
}) => {
  return (
    <Styled.AvatarWrapper
      active={active}
      color={color}
      disabled={disabled}
      hoverMode={hoverMode}
      margin={margin}
      onClick={onClick}
      size={EAvatarSizes[scale]}
    >
      <Styled.Image size={EAvatarSizes[scale]} {...props} />
      <Styled.Initials color={color} fontSize={EFontSizes[scale]}>
        {renderInitials(name)}
      </Styled.Initials>
    </Styled.AvatarWrapper>
  )
}

export default Avatar
