// Libraries
import styled from 'styled-components'

type ConfirmationCardProps = {
  alert?: boolean
  open?: boolean
  width?: string
}

export const BodyContainer = styled.div`
  font-size: ${({ theme }) => theme.fontSize.copy1};
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.text.default};
  padding: 16px 64px;
  text-align: center;
  line-height: 26px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    text-align: left;
    padding: 0px;
    max-width: 416px;
  }
`

export const Container = styled.div<ConfirmationCardProps>`
  width: 100%;
  padding: 0 24px 80px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: ${({ width }) => width || '41.6rem'};
    padding: 32px;
  }
`

export const CardTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px 16px 16px 16px;
  margin: 0 0 16px;
  ::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.border.input};
    position: absolute;
    margin-top: 42px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0px;
    justify-content: flex-start;
    ::after {
      width: 0px;
    }
  }
`

export const ButtonsContainer = styled.div<{ vertical?: boolean }>`
  align-items: center;
  column-gap: 3.2rem;
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'column-reverse' : 'row')};
  justify-content: space-between;
  padding: 32px 0 0px;
  row-gap: 2.4rem;
`

export const LongerDescriptionContainer = styled.div`
  margin: 20px 40px 26px 40px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 368px;
    margin: 24px 0 34px 0;
  }
`
