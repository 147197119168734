// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body } from 'heeds-ds'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2.4rem;
  flex: 1;
`

export const SearchContainer = styled(Aligner).attrs(() => ({
  align: 'flex-start',
  gap: '16px',
}))`
  width: 100%;
`

export const CountExercises = styled(Body).attrs(({ theme }) => ({
  align: 'right',
  color: theme.colors.text.subdued,
  type: 'copy3',
  weight: 600,
}))`
  padding: 0 0 1.6rem;
`
