// Models
import { Props as BaseProps, TPublicProps } from '../BaseBlock'

// React
import { FC } from 'react'

// Components
import * as Styled from './styled'
import { Icon, InputText } from 'heeds-ds'
import BaseBlock from '../BaseBlock'

type Props = Pick<BaseProps, TPublicProps>

const DateBlock: FC<Props> = ({ name, ...props }) => {
  return (
    <BaseBlock {...props} mandatoryField name={name} type="date">
      <Styled.RelativeContainer>
        <InputText
          displayError={false}
          label="Digite sua pergunta"
          margin="0"
          name={`${name}.question`}
          placeholder="Sua pergunta para o aluno vem aqui"
          scale="small"
        />

        <Styled.AbsoluteContainer>
          <Icon iconName="calendar" size={20} />
        </Styled.AbsoluteContainer>
      </Styled.RelativeContainer>
    </BaseBlock>
  )
}

export default DateBlock
