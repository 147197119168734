// Models
import { TAthleteStatus } from 'models'

// React
import { FC, useContext } from 'react'

// Lib
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { calculateAge } from 'utils/functions'

// Components
import * as Styled from './styled'
import { Avatar, SimpleContainer } from 'heeds-ds'
import { Body, Subtitle } from 'components/UI/Typography'
import { StatusTag } from 'components'
import ProfileCardLoader from './loader'

enum EGender {
  M = 'Masculino',
  F = 'Feminino',
  O = 'Outro',
}

type Props = {
  info?: Record<string, string>
  loading?: boolean
  name: string
  photo?: string
  profileUrl?: string
  status?: TAthleteStatus
}

const FormattedTitles: Record<string, string> = {
  birthdate: 'Idade',
  gender: 'Sexo',
  phone_number: 'Telefone',
  email: 'Email',
  address: 'Endereço',
  instagram: 'Instagram',
  emergency_phone_number: 'Contato de emergência',
}

const ProfileCard: FC<Props> = ({
  info = {},
  loading = false,
  name,
  photo,
  profileUrl = '',
  status,
}) => {
  const theme = useContext(ThemeContext)
  const infoTitles = Object.keys(info)

  if (loading) {
    return <ProfileCardLoader />
  }

  return (
    <Styled.Container data-testid="container">
      <SimpleContainer>
        <StatusTag status={status} margin="0 auto 0.8rem auto" />
        <Avatar margin="0 auto" name={name} scale="xLarge" src={photo} />
      </SimpleContainer>
      <Subtitle data-testid="name" type="sh3" weight={700}>
        {name}
      </Subtitle>
      <Styled.Informations data-testid="profile-info">
        {infoTitles.map((title) => (
          <SimpleContainer key={title} data-testid={`info-${title}`}>
            <Body type="copy4" weight={600} margin="0 0 4px">
              {FormattedTitles[title]}
            </Body>
            <Body type="copy4" color={theme.colors.text.subdued}>
              {title === 'birthdate'
                ? `${calculateAge(info[title])} anos`
                : title === 'gender'
                ? EGender[info[title] as keyof typeof EGender] || '-'
                : `${info[title] || '-'}`}
            </Body>
          </SimpleContainer>
        ))}
      </Styled.Informations>
      <Styled.Button
        variation="borderless"
        to={profileUrl}
        state={{ origin: 'profile' }}
        size="small"
        track={buttonClickTracking}
        trackName="athlete_profile_edit"
      >
        Editar perfil
      </Styled.Button>
    </Styled.Container>
  )
}

export default ProfileCard
