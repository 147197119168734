// Libraries
import styled, { css } from 'styled-components'

// Components
import { Aligner, Body, Subtitle } from 'heeds-ds'

export const AlignButtons = styled(Aligner).attrs(() => ({
  gap: '1.6rem',
  justify: 'center',
}))`
  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.desktopSmall}px) {
      gap: 3.6rem;
    }
  `}
`

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const Image = styled.img`
  ${({ theme }) => css`
    margin: 0 0 3.2rem;
    width: 6rem;

    @media screen and (min-width: ${theme.breakpoints.desktopSmall}px) {
      width: 9.8rem;
    }
  `}
`

export const ImageStores = styled.img`
  height: 2.6rem;
  width: 2.6rem;
`

export const SmallText = styled(Body).attrs((props) => ({
  align: 'center',
  color: props.theme.colors.text.subdued,
  margin: '0 0 5.6rem',
  type: 'copy2',
}))`
  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.desktopSmall}px) {
      font-size: ${theme.fontSize.copy1};
      line-height: ${theme.lineHeight.copy1};
    }
  `}
`

export const SubtitleS = styled(Body).attrs((props) => ({
  align: 'center',
  color: props.theme.colors.text.subdued,
  margin: '0 0 2.4rem',
}))`
  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.desktopSmall}px) {
      font-size: ${theme.fontSize.sh3};
    }
  `}
`

export const TitleS = styled(Subtitle).attrs(() => ({
  align: 'center',
  type: 'sh1',
  margin: '0 0 2.4rem',
  weight: 600,
}))`
  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.desktopSmall}px) {
      font-family: ${theme.fontFamily.barlow};
      font-size: ${theme.fontSize.h1};
      line-height: 5rem;
      margin: 0 0 4rem;
    }
  `}
`
