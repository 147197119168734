import styled, { css } from 'styled-components'

type DropdownProps = {
  margin?: string
  selected?: boolean
  scale?: 'small' | 'medium' | 'large'
  width?: string
}

enum EFontSizes {
  small = '14px',
  medium = '16px',
  large = '18px',
}

enum EPaddings {
  small = '12px 0px 12px 16px',
  medium = '15px 0px 15px 16px',
  large = '18px 0px 18px 16px',
}

enum EHeightSizes {
  small = 40,
  medium = 48,
  large = 57,
}

const DefaultFontStyle = css<DropdownProps>`
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: ${({ scale }) => (scale ? EFontSizes[scale] : EFontSizes.medium)};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`

const ItemStyle = css<DropdownProps>`
  align-items: center;
  color: ${({ theme }) => theme.colors.text.default};
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: ${({ scale }) => (scale ? EPaddings[scale] : EPaddings.medium)};
`

export const Container = styled.div<DropdownProps>`
  ${DefaultFontStyle}
  position: relative;
  margin: ${({ margin }) => margin || '0'};
  width: ${({ width }) => (width ? width : '100%')};
  filter: ${({ selected }) =>
    selected ? 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05))' : 'none'};
`

export const Header = styled.div<DropdownProps>`
  position: relative;
  width: ${({ width }) => width || '100%'};
`

export const Input = styled.input`
  ${DefaultFontStyle}
  align-items: center;
  background: ${({ theme }) => theme.colors.surface.default};
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.text.default};
  display: flex;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  justify-content: space-between;
  text-align: left;
  width: 100%;
  padding: ${({ scale }) => (scale ? EPaddings[scale] : EPaddings.medium)};
  :focus-visible {
    border: 1px solid ${({ theme }) => theme.colors.border.input};
  }
  outline: none;
`

export const IconButton = styled.button<DropdownProps>`
  background: none;
  border: none;
  cursor: pointer;
  height: ${({ scale }) =>
    scale ? EHeightSizes[scale] + 2 : EHeightSizes.medium + 2}px;
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: center;
  padding: 0 8px;
  z-index: 1;
`

export const Modal = styled.div<DropdownProps>`
  background: ${({ theme }) => theme.colors.surface.default};
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  max-height: 192px;
  overflow-y: auto;
  position: absolute;
  top: ${({ scale }) =>
    scale ? EHeightSizes[scale] + 1 : EHeightSizes.medium + 1}px;
  width: 100%;
  z-index: 1;
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) =>
    theme.colors.surface.neutralHovered + ' ' + theme.colors.surface.pressed};
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.surface.pressed};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.surface.neutralHovered};
    border-radius: 3px;
  }
`

export const Item = styled.p`
  ${DefaultFontStyle}
  ${ItemStyle}
  :hover {
    background-color: ${({ theme }) =>
      theme.colors.surface.highlightSubduedHovered};
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`

export const NotFoundItem = styled.p`
  ${ItemStyle}
`
