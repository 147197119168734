// Models
import {
  EPhysicalAssessmentActionTypes,
  IPhysicalAssessment,
  IPhysicalAssessmentState,
} from '../models'
import { IAction } from 'lib/redux/models'
import {
  ILoadPhysicalAssessmentsPayload,
  ILoadPhysicalAssessmentsResponse,
  IUpdatePhysicalAssessmentPayload,
  TCreatePhysicalAssessmentPayload,
} from 'services/assessmentPhysicalAssessment/@types'

// ACTION TYPES
export const Types = {
  CLEANUP: EPhysicalAssessmentActionTypes.CLEANUP,
  FAILURE: EPhysicalAssessmentActionTypes.FAILURE,
  FULFILL: EPhysicalAssessmentActionTypes.FULFILL,
  REQUEST: EPhysicalAssessmentActionTypes.REQUEST,
  SUCCESS: EPhysicalAssessmentActionTypes.SUCCESS,
  CREATE: EPhysicalAssessmentActionTypes.CREATE,
  GET: EPhysicalAssessmentActionTypes.GET,
  UPDATE: EPhysicalAssessmentActionTypes.UPDATE,
  PARTIAL_UPDATE: EPhysicalAssessmentActionTypes.PARTIAL_UPDATE,
  DELETE: EPhysicalAssessmentActionTypes.DELETE,
  LOAD: EPhysicalAssessmentActionTypes.LOAD,
  LOAD_MORE: EPhysicalAssessmentActionTypes.LOAD_MORE,
  SUCCESS_LOAD_MORE: EPhysicalAssessmentActionTypes.SUCCESS_LOAD_MORE,
}

// INITIAL STATE
const initialState: IPhysicalAssessmentState = {}

// REDUCER
export default (
  state: IPhysicalAssessmentState = initialState,
  action?: IAction<unknown>,
): IPhysicalAssessmentState => {
  switch (action?.type) {
    case Types.CLEANUP:
      return {
        ...state,
        error: undefined,
        loading: undefined,
        refreshing: undefined,
        physicalAssessment: undefined,
        physicalAssessments: undefined,
      }
    case Types.FAILURE:
      return {
        ...state,
        error: action.payload as string,
      }
    case Types.FULFILL:
      return {
        ...state,
        loading: false,
      }
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.SUCCESS:
      return {
        ...state,
        error: undefined,
        ...(action?.payload as IPhysicalAssessmentState),
      }
    case Types.SUCCESS_LOAD_MORE: {
      const { results, ...paginatedParams } =
        action.payload as ILoadPhysicalAssessmentsResponse
      const physicalAssessmentsFromState = state.physicalAssessments || []
      return {
        ...state,
        ...paginatedParams,
        physicalAssessments: [...physicalAssessmentsFromState, ...results],
      }
    }
    default:
      return state
  }
}

// ACTIONS
export const cleanup = (): IAction<undefined> => {
  return {
    type: Types.CLEANUP,
  }
}

export const failure = (payload: string): IAction<string> => {
  return {
    type: Types.FAILURE,
    payload,
  }
}

export const fulfill = (): IAction<unknown> => {
  return {
    type: Types.FULFILL,
  }
}

export const request = (): IAction<unknown> => {
  return {
    type: Types.REQUEST,
  }
}

export const success = (
  payload: IPhysicalAssessmentState,
): IAction<IPhysicalAssessmentState> => {
  return {
    type: Types.SUCCESS,
    payload,
  }
}

// CUSTOM ACTIONS
export const triggerCreatePhysicalAssessment = (
  payload: TCreatePhysicalAssessmentPayload,
): IAction<TCreatePhysicalAssessmentPayload> => {
  return {
    type: Types.CREATE,
    payload,
  }
}

export const triggerGetPhysicalAssessment = (
  payload: number,
): IAction<number> => {
  return {
    type: Types.GET,
    payload,
  }
}

export const triggerUpdatePhysicalAssessment = (
  payload: IPhysicalAssessment,
): IAction<IPhysicalAssessment> => {
  return {
    type: Types.UPDATE,
    payload,
  }
}

export const triggerPartialUpdatePhysicalAssessment = (
  payload: IUpdatePhysicalAssessmentPayload,
): IAction<IUpdatePhysicalAssessmentPayload> => {
  return {
    type: Types.PARTIAL_UPDATE,
    payload,
  }
}

export const triggerDeletePhysicalAssessment = (
  payload: number,
): IAction<number> => {
  return {
    type: Types.DELETE,
    payload,
  }
}

export const triggerLoadPhysicalAssessments = (
  payload: ILoadPhysicalAssessmentsPayload,
): IAction<ILoadPhysicalAssessmentsPayload> => {
  return {
    type: Types.LOAD,
    payload,
  }
}

export const triggerLoadMorePhysicalAssessments = (
  payload: ILoadPhysicalAssessmentsPayload,
): IAction<ILoadPhysicalAssessmentsPayload> => {
  return {
    type: Types.LOAD_MORE,
    payload,
  }
}

export const successLoadMore = (
  payload: ILoadPhysicalAssessmentsResponse,
): IAction<ILoadPhysicalAssessmentsResponse> => {
  return {
    type: Types.SUCCESS_LOAD_MORE,
    payload,
  }
}
