// Models
import { IGenericOption, TFormBlockType } from 'models'

// React
import { FC, ReactNode, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { useMediaQuery } from 'hooks'

// Components
import * as Styled from './styled'
import { Aligner, Body, IconsType, Swipe, Switch } from 'heeds-ds'

// Constants
export const ICON_TYPES: Record<TFormBlockType, IconsType> = {
  date: 'calendar',
  dropdown_list: 'expandCircleDown',
  measures: 'straighten',
  multiple_choice: 'radioButtonChecked',
  section: 'horizontalSplit',
  select_box: 'checkBox',
  text: 'textSnippet',
  title: 'title',
}

export const TITLE_TYPES: Record<TFormBlockType, string> = {
  date: 'Data',
  dropdown_list: 'Lista suspensa',
  measures: 'Resposta em medidas',
  multiple_choice: 'Múltipla escolha',
  section: 'Seção',
  select_box: 'Caixa de seleção',
  text: 'Resposta em texto',
  title: 'Título',
}

export type TPublicProps =
  | 'dragRef'
  | 'margin'
  | 'name'
  | 'onDuplicate'
  | 'onRemove'
  | 'status'

export type TBaseBlockStatus = 'press' | 'drag' | 'default'

export interface Props {
  borderTop?: boolean
  children?: ReactNode
  draggable?: boolean
  dragRef?: React.ForwardedRef<HTMLDivElement>
  mandatoryField?: boolean
  margin?: string
  name: string
  onDuplicate?: () => void
  onRemove?: () => void
  showOptions?: boolean
  status?: TBaseBlockStatus
  type?: TFormBlockType
}

const BaseBlock: FC<Props> = ({
  borderTop,
  children,
  draggable = true,
  dragRef,
  mandatoryField,
  margin,
  name,
  onDuplicate,
  onRemove,
  showOptions = true,
  status = 'default',
  type = 'text',
}) => {
  const theme = useContext(ThemeContext)
  const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  const prefix = `base-block-${name.replaceAll('.', '-')}`

  const swipeOptions: IGenericOption[] = [
    {
      icon: 'contentCopy',
      onClick: () => onDuplicate?.(),
    },
    {
      icon: 'delete',
      color: theme.colors.interactive.critical,
      onClick: () => onRemove?.(),
    },
  ]

  return (
    <Swipe
      active={!isTablet && showOptions}
      actions={swipeOptions}
      margin={margin}
    >
      <Styled.Container
        data-testid={`${prefix}-container`}
        borderTop={borderTop}
        status={status}
      >
        {draggable && (
          <Styled.DragContainer ref={dragRef}>
            <Styled.DragIcon />
          </Styled.DragContainer>
        )}

        <Styled.Header>
          <Aligner gap="0.8rem" align="center">
            <Styled.TypeIcon
              iconName={ICON_TYPES[type]}
              testid={`${prefix}-icon`}
            />

            <Styled.TypeTitle data-testid={`${prefix}-title`}>
              {TITLE_TYPES[type]}
            </Styled.TypeTitle>
          </Aligner>

          {mandatoryField && (
            <Aligner gap="1.6rem" align="center" width="fit-content">
              <Body type="copy4" weight={600}>
                Obrigatório
              </Body>

              <Switch name={`${name}.required`} />
            </Aligner>
          )}
        </Styled.Header>

        {children}

        {isTablet && showOptions && (
          <Aligner
            data-testid={`${prefix}-buttons`}
            gap="1.6rem"
            justify="flex-end"
            padding="2.4rem 0 0 "
          >
            <Styled.DuplicateButton
              onClick={onDuplicate}
              track={buttonClickTracking}
              trackName={`duplicate_block_${name}`}
            />

            <Styled.DeleteButton
              onClick={onRemove}
              track={buttonClickTracking}
              trackName={`delete_block_${name}`}
            />
          </Aligner>
        )}
      </Styled.Container>
    </Swipe>
  )
}

export default BaseBlock
