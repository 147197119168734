// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body, Button, Icon, Subtitle } from 'heeds-ds'

export const AthleteInfo = styled(Aligner).attrs(() => ({
  gap: '3.2rem',
  padding: '1.6rem 2.4rem 1.6rem 3.2rem',
  align: 'center',
}))`
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 15px;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    gap: 4rem;
    padding: 2.4rem 3.2rem;
  }
`

export const DeactivatedButton = styled(Button).attrs(() => ({
  cancel: true,
  margin: '0',
  size: 'small',
  variation: 'borderless',
}))``

export const GoBackButton = styled(Button).attrs(() => ({
  margin: '0',
  size: 'small',
  type: 'button',
  variation: 'borderless',
}))``

export const InfoButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  cursor: pointer;
  border: none;
  background: none;
  margin: 0 0 0 auto;
`

export const InfoIcon = styled(Icon).attrs((props) => ({
  iconName: 'info',
  size: 24,
  color: props.theme.colors.icon.default,
}))``

export const NameDesktop = styled(Subtitle).attrs((props) => ({
  type: 'sh3',
  weight: 700,
  color: props.theme.colors.text.secondary,
}))``

export const Resume = styled(Aligner).attrs(() => ({
  justify: 'space-between',
  padding: '1.6rem 2.4rem 1.6rem 3.2rem',
  width: '43.7rem',
}))`
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 15px;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  min-width: 43.7rem;
  padding: 2.4rem 3.2rem 2.4rem 2.4rem;
`

export const TextPlanType = styled(Body).attrs((props) => ({
  type: 'copy5',
  color: props.theme.colors.text.subdued,
  weight: 600,
}))``
