// Models
import { IFinancialData } from 'models'

// React
import React, { FC, useContext, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { useMediaQuery, useModal } from 'hooks'

// Components
import { List, Table } from 'heeds-ds'
import { ContentBox, FinancialItem, FinancialRow } from 'components'
import { ModalPayment, ModalSendReminder } from 'components/modals'

// Constants
const COLUMNS = [
  { title: 'PLANO' },
  { title: 'VALOR DA MENSALIDADE' },
  { title: 'DATA DE PAGAMENTO' },
  { title: 'FORMA DE PAGAMENTO' },
  { title: 'DATA DE VENCIMENTO' },
  { title: 'STATUS' },
  { title: 'AÇÕES' },
]

type Props = {
  financials: IFinancialData[]
}

const FinancialAthleteList: FC<Props> = ({ financials }) => {
  const { openModal } = useModal()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const [selected, setSelected] = useState<IFinancialData>()
  const [paymentSelected, setPaymentSelected] = useState<IFinancialData>()

  return (
    <React.Fragment>
      {isDesktop ? (
        <ContentBox title="Histórico">
          <Table<IFinancialData>
            columns={COLUMNS}
            data={financials}
            listLength={6}
            pageSize={6}
            renderRow={(financial) => {
              return (
                <FinancialRow
                  key={financial.id}
                  financial={financial}
                  onAddPayment={() => setPaymentSelected(financial)}
                  onSendReminder={() => {
                    setSelected(financial),
                      openModal('financial-athleteList-modal')
                  }}
                />
              )
            }}
          />
        </ContentBox>
      ) : (
        <List<IFinancialData>
          data={financials}
          keyExtractor={(financial) => `${financial.id}`}
          renderItem={(financial) => {
            return (
              <FinancialItem
                financial={financial}
                onAddPayment={() => setPaymentSelected(financial)}
                onSendReminder={() => {
                  setSelected(financial),
                    openModal('financial-athleteList-modal')
                }}
              />
            )
          }}
        />
      )}

      <ModalPayment
        financial={paymentSelected}
        visible={paymentSelected !== undefined}
      />

      <ModalSendReminder
        financial={selected}
        visible={selected !== undefined}
      />
    </React.Fragment>
  )
}

export default FinancialAthleteList
