// Libraries
import styled from 'styled-components'

// Components
import { Body, Icon } from 'heeds-ds'

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 24px 16px 24px;
  height: 50vh;
  overflow: auto;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: row;
    gap: 48px;
    min-width: 900px;
    height: auto;
  }
`

export const FormLeft = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 50%;
  }
`

export const FormRight = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 50%;
    margin-top: 0;
  }
`

export const Label = styled(Body).attrs(() => ({
  type: 'copy4',
  weight: 600,
}))`
  margin-bottom: 8px;
`

export const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: flex-start;
`

export const Text = styled(Body).attrs(() => ({
  type: 'copy4',
  weight: 400,
}))``

export const FileInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 8px 0 0 0;
`

export const FileInputPreview = styled.img`
  width: 82px;
  height: 82px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.icon.default};
  opacity: 0.5;
  object-fit: fill;
`

export const IconContainer = styled.div`
  width: 82px;
  height: 82px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.icon.default};
  opacity: 0.5;
  padding: 20px;
  align-items: center;
  justify-content: center;
`

export const PlayIcon = styled(Icon).attrs(({ theme }) => ({
  iconName: 'playCircle',
  size: 40,
  color: theme.colors.surface.default,
}))``

export const TextSmall = styled(Body).attrs(() => ({
  type: 'copy5',
  weight: 400,
}))``

export const BlueText = styled(Body).attrs(() => ({
  type: 'copy4',
  weight: 600,
}))`
  color: ${({ theme }) => theme.colors.interactive.default};
`
