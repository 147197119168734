// Libraries
import styled from 'styled-components'

type Props = {
  disabled?: boolean
}

export const ContentWrapper = styled.div`
  padding: 4.6rem 0 8rem 0;
  position: relative;
`

export const OptionWrapper = styled.div<Props>`
  align-items: center;
  display: flex;
  gap: 2.4rem;
  padding: 2.4rem 4rem;
  position: relative;
  text-decoration: none;
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.input};
  }
`
