// Models
import { FieldType } from 'components/WorkoutSetFormTag'
import { IAthleteInfoState } from 'storage/athleteInfo/models'
import { IExerciseData } from 'storage/exercise/models'
import {
  IModelInput,
  IWorkoutSetInput,
  TRemoveModel,
  TSetSpecialSet,
} from 'models'
import { IWorkoutModelData } from 'storage/workoutModel/models'
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'
import { TModalMoreOption } from 'components/modals/ModalMoreOptions'
import IStore from 'lib/redux/models'

// React
import React, {
  FC,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { toPascalCase } from 'utils/helpers/string'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { useMenu, useModal } from 'hooks'

// Components
import * as Styled from './styled'
import { Aligner, Button, Icon, Swipe } from 'heeds-ds'
import {
  ContentBox,
  ModalMoreOptions,
  PdfDownloadButton,
  WorkoutPDF,
  WorkoutSetFormTag,
} from 'components'

type Props = {
  addOrRemoveFieldFromWorkoutSetToExercise?: (
    modelId: string,
    workoutSetId: string,
    exerciseIndex: number,
    field: FieldType,
    add?: boolean,
  ) => void
  downloadPDFModel: (modelId: string) => void
  handleAddModel: () => void
  handleEditModel: (modelIndex: number) => void
  onRemoveModel: TRemoveModel
  openExerciseGifModal: (exercise: IExerciseData) => void
  setSpecialSet?: TSetSpecialSet
  stateModels?: IModelInput[]
  updateWorkoutSet?: (
    modelId: string,
    workoutSetId: string,
    updatedWorkoutSet: IWorkoutSetInput,
  ) => void
  workoutModels?: IWorkoutModelData[]
  workoutRoutine?: IWorkoutRoutine
}

const Mobile: FC<Props> = ({
  downloadPDFModel,
  handleAddModel,
  handleEditModel,
  onRemoveModel,
  openExerciseGifModal,
  stateModels,
  workoutModels,
  workoutRoutine,
  ...props
}) => {
  const { info } = useSelector<IStore, IAthleteInfoState>(
    (state) => state.athleteInfo,
  )
  const { setPagename } = useMenu()
  const { setValue } = useFormContext()
  const { closeModal, isVisible, openModal } = useModal()
  const theme = useContext(ThemeContext)

  const [selectedModel, setSelectedModel] = useState<number | null>(null)

  const hasModels = stateModels && stateModels.length > 0

  const options: TModalMoreOption[] = [
    {
      icon: 'pictureAsPdf',
      label: 'PDF do treino',
      onClick: () => {
        selectedModel !== null &&
          workoutModels &&
          downloadPDFModel(workoutModels?.[selectedModel].id)
        closeModal()
      },
    },
    {
      icon: 'edit',
      label: 'Editar',
      onClick: () => {
        selectedModel !== null && handleEditModel(selectedModel)
        closeModal()
      },
    },
    {
      icon: 'delete',
      label: 'Excluir',
      onClick: () => {
        selectedModel !== null &&
          workoutRoutine &&
          onRemoveModel(
            stateModels?.[selectedModel].id ?? '',
            workoutRoutine.id,
          )
      },
      color: theme.colors.text.critical,
    },
  ]

  const renderStateModels = useMemo(
    () =>
      stateModels?.map((stateModel, modelIndex) => {
        return (
          <Swipe
            key={stateModel.id}
            active
            actions={[
              {
                icon: 'moreHorizontal',
                color: theme.colors.interactive.hovered,
                onClick: () => {
                  setSelectedModel(modelIndex)
                  openModal('workout-routine-mobile-modal')
                },
              },
            ]}
          >
            <ContentBox
              collapsible
              mobileCard
              padding="24px 0"
              startsOpen={modelIndex === 0}
              title={toPascalCase(stateModel.name)}
            >
              <Aligner flex="column" gap="16px">
                {Object.values(stateModel.workout_set)?.map(
                  (stateSet, workoutSetIndex) => {
                    const fieldName = `models.${modelIndex}.workout_set.${stateSet.id}`

                    setValue(`${fieldName}`, {
                      comments: stateSet.comments || '',
                      quantity: stateSet.quantity || '',
                      interval: stateSet.interval || '',
                      exercises: stateSet.exercises,
                    })

                    const setProps = {
                      ...props,
                      displayMode: true,
                      name: fieldName,
                      readonly: true,
                      visible: true,
                      onClickImage: openExerciseGifModal,
                      workoutSetId: stateSet.id,
                      modelIndex,
                      workoutSet: {
                        ...stateSet,
                        index: workoutSetIndex,
                      },
                      showBorder: true,
                    }

                    return <WorkoutSetFormTag {...setProps} key={stateSet.id} />
                  },
                )}
              </Aligner>
            </ContentBox>
          </Swipe>
        )
      }),
    [
      openExerciseGifModal,
      openModal,
      props,
      setValue,
      stateModels,
      theme.colors.interactive.hovered,
    ],
  )

  useLayoutEffect(() => {
    hasModels ? setPagename(workoutRoutine?.name ?? '') : setPagename('Treinos')

    return () => setPagename('Dashboard')
  }, [hasModels, setPagename, workoutRoutine])

  return (
    <React.Fragment>
      {!hasModels && (
        <Styled.EmptyContainer>
          <Styled.EmptyText>
            Você precisa adicionar um treino para essa rotina.
          </Styled.EmptyText>

          <Styled.AddModelButton
            onClick={handleAddModel}
            track={buttonClickTracking}
            trackName="navigate_to_create_or_edit_workout_models"
          >
            <Icon iconName="add" color={theme.colors.text.secondary} />
            Adicionar Treino
          </Styled.AddModelButton>
        </Styled.EmptyContainer>
      )}

      <Aligner flex="column" gap="16px" padding="24px">
        {workoutRoutine && hasModels && (
          <Aligner justify="center">
            <PdfDownloadButton
              PDF={
                <WorkoutPDF
                  data={{
                    routine: workoutRoutine,
                    workouts: workoutModels,
                  }}
                />
              }
              fileName={info?.name + ' treino '}
              margin="0 0 8px"
              size="small"
            />
          </Aligner>
        )}

        {renderStateModels}

        {stateModels?.length ? (
          <Button
            margin="2.4rem auto"
            onClick={handleAddModel}
            size="small"
            track={buttonClickTracking}
            trackName="navigate_to_create_or_edit_workout_models"
            variation="borderless"
          >
            <Icon color={theme.colors.interactive.default} iconName="add" />
            Adicionar novo treino
          </Button>
        ) : null}

        {isVisible === 'workout-routine-mobile-modal' && (
          <ModalMoreOptions options={options} />
        )}
      </Aligner>
    </React.Fragment>
  )
}

export default Mobile
