// React
import { FC } from 'react'

// Component
import LoadingSpinner from '../LoadingSpinner'
import { Wrapper } from './styled'

type Props = {
  active?: boolean
}

const Loading: FC<Props> = ({ active }) => (
  <Wrapper active={active}>
    <LoadingSpinner size={55} />
  </Wrapper>
)

export default Loading
