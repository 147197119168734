// React
import { FC } from 'react'

// Libraries
import { useController, useFormContext } from 'react-hook-form'

// Components
import { OptionsWrapper } from './styled'
import InputWrapper from '../InputWrapper'
import Radio from '../@primitives/Radio'

type Option = {
  label: string
  value: string
}

type Props = {
  alignment?: 'row' | 'column'
  checked?: string
  disabled?: boolean
  displayError?: boolean
  justify?: string
  label?: string
  mandatory?: boolean
  margin?: string
  name: string
  options: Option[]
  scale?: 'small' | 'medium' | 'large'
}

const RadioGroup: FC<Props> = (props: Props) => {
  const {
    alignment = 'row',
    disabled,
    displayError,
    justify = 'flex-start',
    label,
    mandatory,
    margin,
    name,
    options,
    scale,
  } = props
  const { control } = useFormContext()
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name })

  return (
    <InputWrapper
      disabled={disabled}
      displayError={displayError}
      justify={justify}
      label={label}
      mandatory={mandatory}
      margin={margin}
      size={scale}
      error={error?.message}
    >
      <OptionsWrapper
        alignment={alignment}
        disabled={disabled}
        data-testid="options-list"
      >
        {options.map((option) => (
          <Radio
            checked={option.value === value}
            data-testid={`option-${option.value}`}
            disabled={disabled}
            key={option.value}
            label={option.label}
            onChange={onChange}
            scale={scale !== 'large' ? 'default' : 'large'}
            value={option.value}
          />
        ))}
      </OptionsWrapper>
    </InputWrapper>
  )
}

export default RadioGroup
