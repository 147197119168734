// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body } from 'heeds-ds'

export const CheckLabel = styled.label`
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: ${({ theme }) => theme.colors.text.subdued};
  font-weight: 400;
`

export const CheckRow = styled(Aligner).attrs(() => ({
  gap: '1.6rem',
  padding: '2.4rem 0 5.6rem',
  align: 'center',
}))`
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopSmall}px) {
    padding: 2.4rem 0 4rem;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 4rem 2.4rem 8rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopSmall}px) {
    align-items: center;
    justify-content: normal;
    padding: 4rem 13.6rem 4rem 11.2rem;
    row-gap: 4rem;
  }
`

export const Content = styled.div`
  width: 100%;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopSmall}px) {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row-reverse;
    gap: 3.2rem;
    justify-content: space-between;
  }
`

export const Description = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.subdued,
  margin: '0 0 2.4rem',
  type: 'copy3',
}))``

export const FormContainer = styled.form`
  flex: 1;
  max-width: 40.2rem;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.desktopSmall - 1}px) {
    background-color: ${({ theme }) => theme.colors.surface.default};
    border-radius: 1.5rem;
    box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
    padding: 3.2rem 2.4rem 4rem;
    max-width: 100%;
  }
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1.6rem 0.4rem 4rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopSmall}px) {
    padding: 0;
  }
`

export const HeaderTitle = styled(Body).attrs((props) => ({
  align: 'center',
  color: props.theme.colors.text.secondary,
  margin: '0 0 2.4rem',
  weight: 700,
}))``

export const LayoutImage = styled.img`
  width: 50rem;
  z-index: 0;
`

export const Link = styled.a.attrs(() => ({
  target: '_blank',
}))`
  color: ${({ theme }) => theme.colors.interactive.default};
  text-decoration: none;
`

export const Title = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.secondary,
  weight: 800,
  type: 'copy4',
}))``
