// Libs
import styled from 'styled-components'

// Components
import { LinkButton, Skeleton } from 'heeds-ds'

export const AvatarSkeleton = styled(Skeleton)`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 0 auto;
`

export const Button = styled(LinkButton)`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 6.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.interactive.default};
  font-size: 1.4rem;
  font-weight: 700;
  text-decoration: none;
  font-family: ${({ theme }) => theme.fontFamily.roboto};
`

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  background-color: ${({ theme }) => theme.colors.surface.default};
  padding: 1.6rem 2.4rem 6.4rem;
  border-radius: 15px;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
`

export const Informations = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`
