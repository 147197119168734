// Models
import { IAction } from 'lib/redux/models'
import { IAnamneseAnswer } from '../models'
import {
  ICreateAnamneseAnswersPayload,
  IDeleteAnamneseAnswerPayload,
  ILoadAnamneseAnswersPayload,
  ILoadAnamneseAnswersResponse,
  IUpdateAnamneseAnswerPayload,
} from 'services/assessmentAnamneseAnswer/@types'

// Libraries
import { SagaIterator } from 'redux-saga'
import { all, takeLatest } from 'redux-saga/effects'
import { call, put } from 'redux-saga/effects'

// Misc
import {
  createAnamneseAnswers,
  deleteAnamneseAnswer,
  getAnamneseAnswer,
  getAnamneseAnswers,
  partilaUpdateAnamneseAnswer,
  updateAnamneseAnswer,
} from 'services/assessmentAnamneseAnswer'
import {
  failure,
  fulfill,
  request,
  success,
  successLoadMore,
  Types,
} from '../duck'
import { triggerToastError } from 'storage/general/duck'

export function* createAnamneseAnswersSaga(
  action: IAction<ICreateAnamneseAnswersPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, ...payload } = action.payload
      yield call(createAnamneseAnswers, payload)
      window.postMessage('ANAMNESE_SEND_SUCCESS')

      successCallback?.()
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
    window.postMessage('ANAMNESE_SEND_FAILURE')
  } finally {
    yield put(fulfill())
  }
}

export function* getAnamneseAnswerSaga(action: IAction<number>) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IAnamneseAnswer = yield call(
        getAnamneseAnswer,
        action.payload,
      )

      yield put(success({ anamneseAnswer: response }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* updateAnamneseAnswerSaga(action: IAction<IAnamneseAnswer>) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IAnamneseAnswer = yield call(
        updateAnamneseAnswer,
        action.payload,
      )

      yield put(success({ anamneseAnswer: response }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* partialUpdateAnamneseAnswerSaga(
  action: IAction<IUpdateAnamneseAnswerPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IAnamneseAnswer = yield call(
        partilaUpdateAnamneseAnswer,
        action.payload,
      )

      yield put(success({ anamneseAnswer: response }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* deleteAnamneseAnswerSaga(
  action: IAction<IDeleteAnamneseAnswerPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, id } = action.payload

      yield call(deleteAnamneseAnswer, id)

      successCallback?.()
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* loadAnamneseAnswersSaga(
  action: IAction<ILoadAnamneseAnswersPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { results, ...paginateResponse }: ILoadAnamneseAnswersResponse =
        yield call(getAnamneseAnswers, action.payload)

      yield put(success({ ...paginateResponse, anamneseAnswers: results }))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* loadMoreAnamneseAnswersSaga(
  action: IAction<ILoadAnamneseAnswersPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: ILoadAnamneseAnswersResponse = yield call(
        getAnamneseAnswers,
        action.payload,
      )

      yield put(successLoadMore(response))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export default function* anamneseAnswerSaga(): SagaIterator {
  yield all([
    takeLatest(Types.CREATE, createAnamneseAnswersSaga),
    takeLatest(Types.DELETE, deleteAnamneseAnswerSaga),
    takeLatest(Types.GET, getAnamneseAnswerSaga),
    takeLatest(Types.LOAD, loadAnamneseAnswersSaga),
    takeLatest(Types.LOAD_MORE, loadMoreAnamneseAnswersSaga),
    takeLatest(Types.PARTIAL_UPDATE, partialUpdateAnamneseAnswerSaga),
    takeLatest(Types.UPDATE, updateAnamneseAnswerSaga),
  ])
}
