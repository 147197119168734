// Libraries
import styled from 'styled-components'

export const SelectTagWrapper = styled.div`
  position: relative;
  margin: 0 0 16px 0;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 0;
  }
`

export const TagWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  margin: 0 0 5.2rem 0;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 0;
  }
`

export const TagOptionsWrapper = styled.div`
  display: none;
  position: absolute;
  top: 8px;
  right: 24px;
  gap: 24px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: flex;
  }
`
