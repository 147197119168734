// React
import { FC } from 'react'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { toSnakeCase } from 'utils/functions'

// Components
import * as Styled from './styled'
import { Button, FormButton } from 'heeds-ds'

type Props = {
  disabled?: boolean
  form?: string
  loading?: boolean
  primaryTitleButton?: string
  secundaryTitleButton?: string
  twoButtons?: boolean
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void
}

const FooterActionButton: FC<Props> = ({
  disabled,
  form,
  loading,
  primaryTitleButton,
  secundaryTitleButton,
  onClick,
  twoButtons,
}) => {
  return (
    <Styled.ContainerBottom critical={twoButtons}>
      {twoButtons && (
        <Button
          cancel={secundaryTitleButton === 'Cancelar'}
          margin="0"
          onClick={onClick}
          size="small"
          track={buttonClickTracking}
          trackName={`secondary_footer_${toSnakeCase(secundaryTitleButton)}`}
          variation="outlined"
        >
          {secundaryTitleButton}
        </Button>
      )}
      <FormButton
        track={buttonClickTracking}
        trackName={`primary_footer_${toSnakeCase(primaryTitleButton)}`}
        disabled={disabled}
        form={form}
        loading={loading}
        margin={twoButtons ? '0' : '0 auto'}
        size="small"
        width={!twoButtons ? '18.7rem' : ''}
      >
        {primaryTitleButton}
      </FormButton>
    </Styled.ContainerBottom>
  )
}

export default FooterActionButton
