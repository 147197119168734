// Models
import { IAction } from 'lib/redux/models'
import {
  EProfileActionTypes,
  IAppointmentTime,
  IPersonalData,
  IPersonalPlans,
  IProfileState,
} from '../models'

// ACTION TYPES
export const Types = {
  SCHEDULE: EProfileActionTypes.SCHEDULE,
  CLEANUP: EProfileActionTypes.CLEANUP,
  FAILURE: EProfileActionTypes.FAILURE,
  FULFILL: EProfileActionTypes.FULFILL,
  REQUEST: EProfileActionTypes.REQUEST,
  SUCCESS: EProfileActionTypes.SUCCESS,
  GET_INFO: EProfileActionTypes.GET_INFO,
  GET_PERSONAL_PROFILE: EProfileActionTypes.GET_PERSONAL_PROFILE,
  GET_PERSONAL_APPOINTMENT_TIME:
    EProfileActionTypes.GET_PERSONAL_APPOINTMENT_TIME,
  CREATE_OR_UPDATE_PERSONAL_APPOINTMENT_TIME:
    EProfileActionTypes.CREATE_OR_UPDATE_PERSONAL_APPOINTMENT_TIME,
  CREATE_PERSONAL_PROFILE: EProfileActionTypes.CREATE_PERSONAL_PROFILE,
  UPDATE_PERSONAL_PROFILE: EProfileActionTypes.UPDATE_PERSONAL_PROFILE,
  ATHLETES_SCHEDULE: EProfileActionTypes.ATHLETES_SCHEDULE,
}

// INITIAL STATE
const initialState: IProfileState = {}

// REDUCER
export default (
  state: IProfileState = initialState,
  action?: IAction<unknown>,
): IProfileState => {
  switch (action?.type) {
    case Types.CLEANUP:
      return {
        ...state,
        error: undefined,
        loading: undefined,
        refreshing: undefined,
        profile: undefined,
      }
    case Types.FAILURE:
      return {
        ...state,
        error: action.payload as string,
      }
    case Types.FULFILL:
      return {
        ...state,
        loading: false,
      }
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.SUCCESS:
      return {
        ...state,
        ...(action?.payload as IProfileState),
      }
    default:
      return state
  }
}

// BASE ACTIONS
export const cleanup = (): IAction<undefined> => {
  return {
    type: Types.CLEANUP,
  }
}

export const failure = (payload: string): IAction<string> => {
  return {
    type: Types.FAILURE,
    payload,
  }
}

export const fulfill = (): IAction<undefined> => {
  return {
    type: Types.FULFILL,
  }
}

export const request = (): IAction<undefined> => {
  return {
    type: Types.REQUEST,
  }
}

export const success = (payload: IProfileState): IAction<IProfileState> => {
  return {
    type: Types.SUCCESS,
    payload,
  }
}

// CUSTOM ACTIONS
export const getPersonalProfile = (): IAction<undefined> => {
  return {
    type: Types.GET_PERSONAL_PROFILE,
  }
}

export const getAllAthletesTimeSchedule = (): IAction<undefined> => {
  return {
    type: Types.ATHLETES_SCHEDULE,
  }
}

export const getPersonalAppointmentTimes = (): IAction<IAppointmentTime[]> => {
  return {
    type: Types.GET_PERSONAL_APPOINTMENT_TIME,
  }
}

export const createOrUpdatePersonalAppointmentTimes = (
  payload: IAppointmentTime[],
): IAction<IAppointmentTime[]> => {
  return {
    type: Types.CREATE_OR_UPDATE_PERSONAL_APPOINTMENT_TIME,
    payload,
  }
}

export const updatePersonalProfile = (
  payload: IPersonalData,
): IAction<IPersonalData> => {
  return {
    type: Types.UPDATE_PERSONAL_PROFILE,
    payload,
  }
}

export const updatePersonalPlans = (
  payload: IPersonalPlans[],
): IAction<IPersonalData> => {
  return {
    type: Types.UPDATE_PERSONAL_PROFILE,
    payload: { plans: payload },
  }
}

export const triggerUserInfo = (): IAction<undefined> => {
  return {
    type: Types.GET_INFO,
  }
}

export const actions = {
  cleanup,
  failure,
  fulfill,
  request,
  success,
  getPersonalProfile,
  getPersonalAppointmentTimes,
  createOrUpdatePersonalAppointmentTimes,
  updatePersonalProfile,
  updatePersonalPlans,
  getAllAthletesTimeSchedule,
  triggerUserInfo,
}
