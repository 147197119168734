// Models
import { IAction } from 'lib/redux/models'
import {
  EAthletePlansActionTypes,
  IAthletePlansState,
  ICreateAthletePlansPayload,
  ILoadAthletePlansPayload,
  IUpdateAthletePlansPayload,
} from '../models'

// ACTION TYPES
export const Types = {
  CLEANUP: EAthletePlansActionTypes.CLEANUP,
  FAILURE: EAthletePlansActionTypes.FAILURE,
  FULFILL: EAthletePlansActionTypes.FULFILL,
  REQUEST: EAthletePlansActionTypes.REQUEST,
  SUCCESS: EAthletePlansActionTypes.SUCCESS,
  LOAD_PLANS: EAthletePlansActionTypes.LOAD_PLANS,
  CREATE_PLANS: EAthletePlansActionTypes.CREATE_PLANS,
  UPDATE_PLANS: EAthletePlansActionTypes.UPDATE_PLANS,
}

// INITIAL STATE
const initialState: IAthletePlansState = {}

// REDUCER
export default (
  state: IAthletePlansState = initialState,
  action?: IAction<unknown>,
): IAthletePlansState => {
  switch (action?.type) {
    case Types.CLEANUP:
      return {
        ...state,
        error: undefined,
        loading: undefined,
        refreshing: undefined,
        plans: undefined,
      }
    case Types.FAILURE:
      return {
        ...state,
        error: action.payload as string,
      }
    case Types.FULFILL:
      return {
        ...state,
        loading: false,
      }
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.SUCCESS:
      return {
        ...state,
        ...(action?.payload as IAthletePlansState),
      }
    default:
      return state
  }
}

// ACTIONS
export const cleanup = (): IAction<undefined> => {
  return {
    type: Types.CLEANUP,
  }
}

export const failure = (payload: string): IAction<string> => {
  return {
    type: Types.FAILURE,
    payload,
  }
}

export const fulfill = (): IAction<unknown> => {
  return {
    type: Types.FULFILL,
  }
}

export const request = (): IAction<unknown> => {
  return {
    type: Types.REQUEST,
  }
}

export const success = (
  payload: IAthletePlansState,
): IAction<IAthletePlansState> => {
  return {
    type: Types.SUCCESS,
    payload,
  }
}

// CUSTOM ACTIONS
export const triggerCreateAthletePlans = (
  payload: ICreateAthletePlansPayload,
): IAction<ICreateAthletePlansPayload> => {
  return {
    type: Types.CREATE_PLANS,
    payload,
  }
}

export const triggerLoadAthletePlans = (
  payload: ILoadAthletePlansPayload,
): IAction<ILoadAthletePlansPayload> => {
  return {
    type: Types.LOAD_PLANS,
    payload,
  }
}

export const triggerUpdateAthletePlans = (
  payload: IUpdateAthletePlansPayload,
): IAction<IUpdateAthletePlansPayload> => {
  return {
    type: Types.UPDATE_PLANS,
    payload,
  }
}

export const actions = {
  cleanup,
  failure,
  fulfill,
  request,
  success,
  triggerCreateAthletePlans,
  triggerLoadAthletePlans,
  triggerUpdateAthletePlans,
}
