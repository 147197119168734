// Models
import { TDisplayModes } from '..'

// React
import { FC } from 'react'

// Components
import * as Styled from '../styled'

// Constants
import { MONTHS_COLLECTION } from '../../../utils/helpers/date'

type Props = {
  displayingYear: number
  setDisplayingMonth: React.Dispatch<React.SetStateAction<number>>
  setDisplayMode: React.Dispatch<React.SetStateAction<TDisplayModes>>
  startDate?: Date | null
  today: Date
}

const CalendarMonthPage: FC<Props> = ({
  displayingYear,
  setDisplayingMonth,
  setDisplayMode,
  startDate,
  today,
}) => {
  return (
    <>
      {Object.keys(MONTHS_COLLECTION).map((monthString) => {
        const month = parseInt(monthString)
        const isSelected =
          month === startDate?.getMonth() &&
          displayingYear === startDate?.getFullYear()

        return (
          <Styled.CalendarItem
            aria-selected={isSelected}
            isToday={
              displayingYear === today.getFullYear() &&
              month === today.getMonth()
            }
            key={month.toString()}
            onClick={() => {
              setDisplayingMonth(month)
              setDisplayMode('day')
            }}
          >
            {MONTHS_COLLECTION[month].short}
          </Styled.CalendarItem>
        )
      })}
    </>
  )
}

export default CalendarMonthPage
