// Models
import { IAction } from 'lib/redux/models'
import { EGeneralActionTypes, IGeneralState, IToastState } from '../models'

// ACTION TYPES
export const Types = {
  CLEANUP: EGeneralActionTypes.CLEANUP,
  TRIGGER_TOAST: EGeneralActionTypes.TRIGGER_TOAST,
}

// INITIAL STATE
const initialState: IGeneralState = {}

// REDUCER
export default (
  state: IGeneralState = initialState,
  action?: IAction<unknown>,
): IGeneralState => {
  switch (action?.type) {
    case Types.CLEANUP:
      return initialState
    case Types.TRIGGER_TOAST:
      return {
        ...state,
        toast: action?.payload as IToastState,
      }
    default:
      return state
  }
}

// ACTIONS
export const cleanup = (): IAction<null> => {
  return {
    type: Types.CLEANUP,
  }
}

export const triggerToast = (payload: IToastState): IAction<IToastState> => {
  return {
    type: Types.TRIGGER_TOAST,
    payload,
  }
}

export const triggerToastError = (
  payload: IToastState,
): IAction<IToastState> => {
  return {
    type: Types.TRIGGER_TOAST,
    payload: { ...payload, type: 'error' },
  }
}

export const triggerToastInfo = (
  payload: IToastState,
): IAction<IToastState> => {
  return {
    type: Types.TRIGGER_TOAST,
    payload: { ...payload, type: 'info' },
  }
}

export const triggerToastSuccess = (
  payload: IToastState,
): IAction<IToastState> => {
  return {
    type: Types.TRIGGER_TOAST,
    payload: { ...payload, type: 'success' },
  }
}

export const triggerToastWarning = (
  payload: IToastState,
): IAction<IToastState> => {
  return {
    type: Types.TRIGGER_TOAST,
    payload: { ...payload, type: 'warning' },
  }
}

export const actions = {
  cleanup,
  triggerToast,
  triggerToastError,
  triggerToastInfo,
  triggerToastSuccess,
  triggerToastWarning,
}
