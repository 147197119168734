import './lib/sentry'
import Amplitude from 'amplitude-js'
import React from 'react'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga4'

import App from './App'
import reportWebVitals from './reportWebVitals'

const GA_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
GA_ID && ReactGA.initialize(GA_ID)

const AmplitudeID = process.env.REACT_APP_AMPLITUDE_API_KEY
AmplitudeID && Amplitude.getInstance().init(AmplitudeID)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
