// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Button } from 'heeds-ds'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  width: 100%;
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  gap: 1.6rem;
  overflow-y: auto;
`

export const RightContent = styled(Aligner).attrs({
  flex: 'column',
  gap: '1.6rem',
})`
  flex: 1;
  padding: 2.4rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    overflow-y: scroll;
  }
`

export const ButtonContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.border.input};
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  padding: 1.6rem 4rem;
  position: sticky;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    background: ${({ theme }) => theme.colors.surface.default};
  }
`

export const CancelButton = styled(Button).attrs({
  cancel: true,
  margin: '0',
  size: 'xsmall',
  variation: 'outlined',
})``
