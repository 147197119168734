// Models
import { IFinancialMetrics } from 'storage/financial/models'
import {
  ICreateFinancialExtraPlanPayload,
  ICreateFinancialPlanPayload,
  IGetFinancialExtraPlanPayload,
  IGetFinancialPlanPayload,
  ICreateFinancialSubscription,
  IFinancialSubscription,
  IGetFinancialSubscription,
  IListFinancialExtraPlansPayload,
  ILoadFinancialAthletesPayload,
  ILoadFinancialAthletesResponse,
  ILoadFinancialMetricsPayload,
  IPartialUpdateFinancialExtraPlanPayload,
  IPartialUpdateIFinancialPlan,
  IGetFinancialPlan,
} from './@types'
import { IFinancialPlan, IWorkoutData } from 'storage/profile/models'

// Misc
import api from 'lib/axios/api'

export const loadFinancialAthletes = async (
  formData: ILoadFinancialAthletesPayload,
): Promise<ILoadFinancialAthletesResponse> => {
  try {
    const { personal_pk, ...params } = formData
    const response = await api.get(`financial/athletes/${personal_pk}`, {
      params,
    })
    const data: ILoadFinancialAthletesResponse = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const loadFinancialMetrics = async (
  formData: ILoadFinancialMetricsPayload,
): Promise<IFinancialMetrics> => {
  try {
    const { personal_pk } = formData
    const response = await api.get(`financial/metrics/${personal_pk}`)
    const data: IFinancialMetrics = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const createFinancialExtraPlan = async (
  formData: ICreateFinancialExtraPlanPayload,
): Promise<IWorkoutData<number>> => {
  try {
    const { profile_pk, ...restData } = formData
    const response = await api.post(
      `financial/personal/${profile_pk}/plans-extras/`,
      restData,
    )
    const data: IWorkoutData<number> = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const listFinancialExtraPlans = async (
  formData: IListFinancialExtraPlansPayload,
): Promise<IWorkoutData<number>[]> => {
  try {
    const { profile_pk, ...params } = formData
    const response = await api.get(
      `financial/personal/${profile_pk}/plans-extras/`,
      { params },
    )
    const data: IWorkoutData<number>[] = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const getFinancialExtraPlan = async (
  formData: IGetFinancialExtraPlanPayload,
): Promise<IWorkoutData<number>> => {
  try {
    const { id } = formData
    const response = await api.get(`financial/personal/${id}/plans-extras/`)
    const data: IWorkoutData<number> = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const updateFinancialExtraPlan = async (
  formData: IWorkoutData<number>,
): Promise<IWorkoutData<number>> => {
  try {
    const { id, ...restData } = formData
    const response = await api.put(`financial/plans-extras/${id}/`, restData)
    const data: IWorkoutData<number> = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const partialUpdateFinancialExtraPlan = async (
  formData: IPartialUpdateFinancialExtraPlanPayload<number>,
): Promise<IWorkoutData<number>> => {
  try {
    const { id, ...restData } = formData
    const response = await api.patch(`financial/plans-extras/${id}/`, restData)
    const data: IWorkoutData<number> = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const deleteFinancialExtraPlan = async (
  formData: IGetFinancialExtraPlanPayload,
): Promise<IWorkoutData<number>> => {
  try {
    const { id } = formData
    const response = await api.delete(`financial/plans-extras/${id}/`)
    const data: IWorkoutData<number> = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

// Plans endPoints
export const getFinancialPlan = async (
  formData: ICreateFinancialPlanPayload,
): Promise<IGetFinancialPlanPayload> => {
  try {
    const { profile_pk } = formData
    const { data }: { data: IGetFinancialPlanPayload } = await api.get(
      `financial/personal/${profile_pk}/plans/`,
    )

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const createFinancialPlan = async (
  formData: ICreateFinancialPlanPayload,
): Promise<IFinancialPlan> => {
  try {
    const { profile_pk, plans } = formData
    const response = await api.post(
      `financial/personal/${profile_pk}/plans/`,
      plans,
    )
    const data: IFinancialPlan = response.data
    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const activateFinancialPlan = async (
  formData: IGetFinancialPlan,
): Promise<IFinancialPlan> => {
  try {
    const { id } = formData
    const { data }: { data: IFinancialPlan } = await api.patch(
      `financial/plans/${id}/activate/`,
    )
    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const deactivateFinancialPlan = async (
  formData: IGetFinancialPlan,
): Promise<IFinancialPlan> => {
  try {
    const { id } = formData
    const { data }: { data: IFinancialPlan } = await api.patch(
      `financial/plans/${id}/deactivate/`,
    )
    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const deleteFinancialPlan = async (
  formData: IGetFinancialPlan,
): Promise<IFinancialPlan> => {
  try {
    const { id } = formData
    const { data }: { data: IFinancialPlan } = await api.delete(
      `financial/plans/${id}/`,
    )
    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const createFinancialSubscription = async (
  formData: ICreateFinancialSubscription,
): Promise<IFinancialSubscription> => {
  try {
    const { personal } = formData
    const { data }: { data: IFinancialSubscription } = await api.post(
      `financial/personal/${personal}/subscription/`,
      formData,
    )
    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const updateFinancialPlan = async (
  formData: ICreateFinancialPlanPayload,
): Promise<IFinancialPlan> => {
  try {
    const { profile_pk, plans } = formData
    const response = await api.put(`financial/plans/${profile_pk}/`, plans)
    const data: IFinancialPlan = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const partialUpdateFinancialPlan = async (
  formData: IPartialUpdateIFinancialPlan,
): Promise<IPartialUpdateIFinancialPlan> => {
  try {
    const { id, ...restData } = formData
    const response = await api.patch(`financial/plans/${id}/`, restData)
    const data: IPartialUpdateIFinancialPlan = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const getFinancialSubscription = async (
  formData: IGetFinancialSubscription,
): Promise<ICreateFinancialSubscription> => {
  try {
    const { personal_pk } = formData
    const { data }: { data: ICreateFinancialSubscription } = await api.get(
      `financial/personal/${personal_pk}/subscription/`,
    )
    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const updateFinancialSubscription = async (
  formData: ICreateFinancialSubscription,
): Promise<IFinancialSubscription> => {
  try {
    const { id } = formData
    const { data }: { data: IFinancialSubscription } = await api.put(
      `financial/subscription/${id}/`,
      formData,
    )
    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}
