// Models
import { IconsType } from 'heeds-ds'
import { IExerciseData } from 'storage/exercise/models'
import { TPlanPeriodicity } from 'services/financial/@types'
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'
import { IWorkoutModelData } from 'storage/workoutModel/models'

export * from './anamnese'

export enum EAthleteStatusToPortuguese {
  active = 'Ativo',
  inactive = 'Desativado',
  invited = 'Convidado',
}

export enum EFinancialStatusToPortuguese {
  paid = 'Pago',
  pendent = 'Pendente',
  due_date = 'A Vencer',
}

export enum EMuscleGroupToPortuguese {
  WAI = 'Cintura',
  NEC = 'Trapézio',
  LOA = 'Braços Inferioes',
  CHE = 'Peitoral',
  UPA = 'Braços Superiores',
  BAC = 'Costas',
  SHO = 'Ombros',
  LOL = 'Pernas Inferiores',
  UPL = 'Pernas Superiores',
  CAR = 'Cardio',
}

export enum ESetTypeToPortuguese {
  ascending_set = '',
  bi_set = 'BiSet',
  cluster_set = 'Cluster Set',
  compound_set = '',
  concentric_set = '',
  descending_set = '',
  drop_set = 'DropSet',
  eccentric_set = '',
  forced_rep_set = '',
  german_volume_training_set = '',
  giant_set = '',
  negative_set = '',
  normal = 'Simples',
  post_exhaustion_set = '',
  pre_exhaustion_set = '',
  pyramid_set = '',
  rest_pause_set = 'Rest Pause',
  reverse_pyramid_set = '',
  strength_curve_set = '',
  strip_set = '',
  super_set = 'Super Set',
  tri_set = 'TriSet',
  wavy_load_set = '',
}

export enum EPaymentTypeToPortuguese {
  card = 'Cartão',
  billet = 'Boleto',
  pix = 'Pix',
  cash = 'Dinheiro',
  empty = '-',
}

export enum EPlanModelToPortuguese {
  in_person = 'Presencial',
  online = 'Consultoria',
}

export enum EPlanTypeToPortuguese {
  monthly = 'Mensal',
  quarterly = 'Trimestral',
  semiannual = 'Semestral',
  yearly = 'Anual',
}

export enum EWorkoutDifficulty {
  ST = 'Iniciante',
  IN = 'Intermediário',
  AD = 'Avançado',
}

export enum EWorkoutGoal {
  HT = 'Hipertrofia',
  FR = 'Redução de Gordura',
  MD = 'Definição Muscular',
  QL = 'Qualidade de Vida',
  PC = 'Condicionamento Físico',
  IP = 'Prevenção de Lesões',
  FT = 'Treinamento Funcional',
  OT = 'Outros',
}

export enum EWorkoutGoalIcon {
  HT = 'hypertrophy',
  FR = 'fatLoss',
  MD = 'Definition',
  QL = 'lifeQuality',
  PC = 'genericWorkout',
  IP = 'injuryPrevention',
  FT = 'functionalTraining',
  OT = 'genericWorkout',
}

export enum EWorkoutSetFields {
  cadence = 'Cadência',
  comment = 'Comentário',
  interval = 'Descanso',
  quantity = 'Série',
  repetitions = 'Repetição',
  speed = 'Velocidade',
  time = 'Tempo',
  weight = 'Carga',
}

export interface IFormInputs {
  models: IModelInput[] | []
}

export interface IFinancialData {
  id: number
  plan_periodicity: TPlanPeriodicity
  plan_value: number
  payday?: string
  payment?: TPayment
  due_date: string
  payment_status: TPaymentStatus
}

export interface IModelInput {
  id: string
  name: string
  workout_routine?: number
  workout_set: Record<string, IWorkoutSetInput>
}

export interface ISetToExerciseInput {
  id: string
  cadence?: string
  exercise: IExerciseData
  repetitions: string
  weight?: string
  speed?: string
  time?: string
  distance?: string
  comment?: string
}

export interface TField {
  type: TFieldType
  unity?: string
  value: string
}

export type TFieldType =
  | 'cadence'
  | 'comment'
  | 'distance'
  | 'interval'
  | 'quantity'
  | 'repetition'
  | 'speed'
  | 'time'
  | 'weight'

export interface IGenericOption {
  active?: boolean
  color?: string
  disabled?: boolean
  icon?: IconsType
  iconSize?: number
  label?: string
  onClick: () => void
}

export interface ITokenDecoded {
  token_type: string
  exp: number
  iat: number
  jti: string
}

export interface IWorkoutSetInput {
  id: string
  comments?: string
  exercises: ISetToExerciseInput[]
  interval?: {
    value?: string
    measure?: string
  }
  name?: string
  quantity?: string
  type: string
  repeat?: boolean
}

export type Route = {
  path: string
  private?: boolean
  element: React.FC
}

export type TAthleteCalendar = {
  id?: number
  athlete: string
  athletePk: number
  day: string
  date: string
  start: string
  end: string
  location: string
  recurringTimes: number[]
  new: boolean
  endCompare: number
  startCompare: number
  initialDate: string
  dueDate: string | Date
  athlete_name?: string
  athlete_active_subscription_end_date?: string
  athlete_active_subscription_start_date?: string
}

export type TAthleteStatus = 'active' | 'inactive' | 'invited'

export type TDownloadPDFModel = (modelId: string) => void

export type TFinancialAmountReceivedData = {
  color: string
  label: string
  value: number
}

export type TFinancialStatus = 'due_date' | 'paid' | 'pendent'

export type TFinancialTagInfo = {
  title: string
  value: string
  month: string
  percent?: number
}

export type TFormValues =
  | string
  | number
  | IExerciseData
  | IModelInput[]
  | ISetToExerciseInput[]
  | IWorkoutSetInput[]

export type TModalIconVariation =
  | 'delete'
  | 'error'
  | 'info'
  | 'success'
  | 'warning'

export type TMuscleGroupFilter = {
  active: boolean
  name: string
  label: string
}

export type TPayment = 'billet' | 'card' | 'pix' | 'cash'

export type TPaymentStatus = 'due_date' | 'paid' | 'pendent'

export type TPlanModel = 'in_person' | 'online'

export type TPlanType =
  | 'bundle'
  | 'custom'
  | 'default'
  | 'hourly_rate'
  | 'on_demand'

export type TRemoveModel = (modelId: string, routineId: number) => void

export type TRoutineDificulty = 'ST' | 'IN' | 'AD'

export type TRoutineGoal = 'HT' | 'FR' | 'MD' | 'QL' | 'PC' | 'IP' | 'FT' | 'OT'

export type TRoutineOrdering = 'name' | '-name' | 'start_date' | '-start_date'

export type TRoutineStatus = 'current' | 'scheduled' | 'history'

export type TSpecialSetInput = Omit<IWorkoutSetInput, 'exercises'> & {
  index?: number
  exercises: Record<string, ISetToExerciseInput>
}

export type TSetSpecialSet = React.Dispatch<
  React.SetStateAction<TSpecialSetInput | undefined>
>

export type TValue = {
  standard?: number
  custom?: { [key: number]: number }
}

export type TWeekdays = 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat'

export type TWorkoutPDF = {
  routine: IWorkoutRoutine
  workouts?: IWorkoutModelData[]
}

export type IStatementOfResponsibilityPDF = {
  name: string
  cref: string
}

export type TWorkoutSetType =
  | 'normal'
  | 'bi_set'
  | 'tri_set'
  | 'drop_set'
  | 'giant_set'
  | 'super_set'
  | 'pyramid_set'
  | 'reverse_pyramid_set'
  | 'strip_set'
  | 'compound_set'
  | 'pre_exhaustion_set'
  | 'post_exhaustion_set'
  | 'cluster_set'
  | 'rest_pause_set'
  | 'negative_set'
  | 'forced_rep_set'
  | 'eccentric_set'
  | 'concentric_set'
  | 'strength_curve_set'
  | 'german_volume_training_set'
  | 'ascending_set'
  | 'descending_set'
  | 'wavy_load_set'

export type TypeCREF = {
  register: string
}

export type TypeGenders = 'M' | 'F' | 'O'

export type TypeUser = 'personal' | 'athlete'
