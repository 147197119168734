// Libraries
import styled, { css } from 'styled-components'

import { Title } from 'components/UI/Typography'

export const CheckContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 100%;
  padding: 3.2rem 0 0;
  justify-content: space-between;
  gap: 1.6rem;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    min-width: 28.3rem;
  }
`

export const MainTitle = styled(Title).attrs(() => ({
  align: 'center',
}))`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.roboto};
    font-size: ${theme.fontSize.sh1};
    font-weight: ${theme.fontWeight.medium};
    line-height: 3.2rem;
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-family: ${theme.fontFamily.barlow};
      font-size: ${theme.fontSize.h3};
      font-weight: ${theme.fontWeight.medium};
      line-height: ${theme.lineHeight.h3};
    }
  `}
`
