// Models
import { CustomRouteProps } from './models'

// Libs
import { Navigate } from 'react-router-dom'

// Misc
import { clearAuthTokens } from 'lib/axios/jwt'
import { isWebviewAccess } from 'utils/functions'

// Components
import Header from 'components/Header'

export function PrivateRoute({ element, isLoggedIn }: CustomRouteProps) {
  const isNativeApp = isWebviewAccess()
  if (isLoggedIn) {
    return (
      <>
        {!isNativeApp && <Header />}
        {element}
      </>
    )
  } else {
    clearAuthTokens()

    return (
      <Navigate
        to={{ pathname: '/login', search: window.location.pathname }}
        replace
      />
    )
  }
}
