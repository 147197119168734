// React & React Native
import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useState,
} from 'react'

type MenuContext = {
  closeMenu: () => void
  openMenu: () => void
  toggleMenu: () => void
  visible: boolean
  pagename: string
  setPagename: (arg: string) => void
}

type Props = {
  children: ReactNode | ReactNode[]
}

const MenuContext = createContext<MenuContext | null>(null)

const MenuProvider: FC<Props> = ({ children }: Props) => {
  const [visible, setVisible] = useState(false)
  const [pagename, setPagename] = useState('Dashboard')

  const openMenu = () => {
    setVisible(true)
  }

  const closeMenu = () => {
    setVisible(false)
  }

  const toggleMenu = () => {
    setVisible((prev) => !prev)
  }

  return (
    <MenuContext.Provider
      value={{
        closeMenu,
        openMenu,
        toggleMenu,
        visible,
        pagename,
        setPagename,
      }}
    >
      {children}
    </MenuContext.Provider>
  )
}

const useMenu = (): MenuContext => {
  const context = useContext(MenuContext)
  if (!context) {
    throw new Error('useMenu should be used inside MenuProvider')
  }
  return context
}

export { useMenu, MenuProvider }
