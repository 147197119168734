// Models
import { EPlanModelToPortuguese, TRoutineStatus } from 'models'
import { IAthleteInfoState } from 'storage/athleteInfo/models'
import { IAthletePlansState } from 'storage/athletePlans/models'
import IStore from 'lib/redux/models'

// React
import React, { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useSelector } from 'react-redux'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { useMediaQuery } from 'hooks'

// Components
import * as Styled from './styled'
import { Aligner, Avatar, Icon, IconButton } from 'heeds-ds'
import { TabFilter } from 'components'

// Constants
const FILTERS: { label: string; value: TRoutineStatus }[] = [
  { label: 'Rotinas atuais', value: 'current' },
  { label: 'Rotinas futuras', value: 'scheduled' },
  { label: 'Histórico de rotinas', value: 'history' },
]

type Props = {
  activeTab?: TRoutineStatus
  handleNavigateToCreation: () => void
  onTabChange: (selectedTab: TRoutineStatus) => void
}

const RoutineHeader: FC<Props> = ({
  activeTab,
  handleNavigateToCreation,
  onTabChange,
}) => {
  const { info } = useSelector<IStore, IAthleteInfoState>(
    (state) => state.athleteInfo,
  )
  const { plans } = useSelector<IStore, IAthletePlansState>(
    (state) => state.athletePlans,
  )
  const theme = useContext(ThemeContext)
  const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  return (
    <React.Fragment>
      <Styled.Topbar>
        {isTablet && (
          <Styled.Block>
            <Avatar
              name={info?.name ?? ''}
              scale={isTablet ? 'small' : 'xSmall'}
            />

            <Aligner flex="column" gap="0.4rem">
              <Styled.Title>{info?.name}</Styled.Title>

              <Styled.Type>
                {(plans?.[0]?.plan?.plan_model &&
                  EPlanModelToPortuguese[plans[0].plan.plan_model]) ||
                  ''}
              </Styled.Type>
            </Aligner>
          </Styled.Block>
        )}

        <Aligner>
          <TabFilter
            activeStatus={activeTab}
            filters={FILTERS}
            onClick={onTabChange}
          />
        </Aligner>

        {isDesktop && (
          <Styled.AddModelButton
            onClick={handleNavigateToCreation}
            track={buttonClickTracking}
            trackName="navigate_to_create_routine"
          >
            <Icon iconName="add" color={theme.colors.background.default} />
            Adicionar rotina
          </Styled.AddModelButton>
        )}

        {isTablet && !isDesktop && (
          <IconButton
            iconName="add"
            size="large"
            variation="main"
            onClick={handleNavigateToCreation}
            track={buttonClickTracking}
            trackName="navigate_to_create_routine"
          />
        )}
      </Styled.Topbar>
    </React.Fragment>
  )
}

export default RoutineHeader
