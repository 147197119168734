// Libraries
import styled, { css } from 'styled-components'

// Components
import { Skeleton } from 'heeds-ds'

export const Icon = styled(Skeleton)`
  border-radius: 50%;
  flex-shrink: 0;
  height: 52px;
  width: 52px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    height: 42px;
    width: 42px;
  }
`

export const LoadContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.surface.default};
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 1.5rem;
  display: flex;
  gap: 1.6rem;
  padding: 1.6rem 2.4rem;
  width: calc(100% - 4.8rem);

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: grid;
    gap: 0;
    grid-template-columns:
      minmax(20rem, 1fr) minmax(24rem, 1fr) minmax(20rem, 1fr)
      minmax(1rem, 0.5fr) minmax(0.8rem, 0.35fr);
    padding: 1.6rem 0;
    transition: all 0.3s ease-in-out;
    width: 100%;
    ${({ theme }) => css`
      background: ${theme.colors.surface.default};
      border-bottom: 1px solid ${theme.colors.border.input};
    `}
  }
`
