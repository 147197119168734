import { ButtonSizesType } from '.'

import styled, { css } from 'styled-components'

import { NavLink } from 'react-router-dom'

type ButtonProps = {
  cancel?: boolean
  disabled?: boolean
  margin?: string
  onlyIcon?: boolean
  radius?: string
  reverseIcon?: boolean
  size?: ButtonSizesType
  variation?: string
  width?: string
}

enum ButtonFontSizes {
  xsmall = '14px',
  small = '18px',
  medium = '20px',
  large = '24px',
}

enum ButtonPaddingsOnlyIcon {
  xsmall = '6px',
  small = '8px',
  medium = '10px',
  large = '12px',
}

export const ButtonStyle = css<ButtonProps>`
  border-radius: ${({ radius }) => radius || '8px'};
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  margin: ${({ margin }) => margin || '10px 0'};
  display: flex;
  flex-direction: ${({ reverseIcon }) => (reverseIcon ? 'row-reverse' : 'row')};
  column-gap: 10px;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ size }) => ButtonFontSizes[size ?? 'medium']};
  line-height: 2.8rem;
  outline: none;
  text-decoration: none;
  transition: all ease-in-out 0.2s;
  user-select: none;
  white-space: nowrap;
  padding: ${({ size, onlyIcon }) =>
    onlyIcon ? ButtonPaddingsOnlyIcon[size ? size : 'medium'] : '8px 24px'};
  width: ${({ width }) => width || 'fit-content'};
  :disabled {
    cursor: not-allowed;
  }
`

export const PrimaryButton = css<ButtonProps>`
  ${ButtonStyle}
  color: ${({ theme }) => theme.colors.text.onPrimary};
  border-color: ${({ theme, cancel }) =>
    cancel
      ? theme.colors.interactive.critical
      : theme.colors.action.secondaryDefault};
  background-color: ${({ theme, cancel }) =>
    cancel
      ? theme.colors.interactive.critical
      : theme.colors.action.secondaryDefault};
  :hover {
    border-color: ${({ theme, cancel }) =>
      cancel ? theme.colors.interactive.critical : theme.colors.icon.subdued};
    background-color: ${({ theme, cancel }) =>
      cancel ? theme.colors.interactive.critical : theme.colors.icon.subdued};
  }
  :active {
    border-color: ${({ theme, cancel }) =>
      cancel
        ? theme.colors.interactive.critical
        : theme.colors.action.secondaryPressed};
    background-color: ${({ theme, cancel }) =>
      cancel
        ? theme.colors.interactive.critical
        : theme.colors.action.secondaryPressed};
  }
  :focus-visible {
    border-color: ${({ theme, cancel }) =>
      cancel ? theme.colors.interactive.critical : theme.colors.border.input};
    box-shadow: 0px 0px 5px 0px ${({ theme }) => theme.colors.text.secondary};
  }
  :disabled {
    border-color: ${({ theme, cancel }) =>
      cancel
        ? theme.colors.interactive.criticalDisabled
        : theme.colors.action.secondaryDisabled};
    background-color: ${({ theme, cancel }) =>
      cancel
        ? theme.colors.interactive.criticalDisabled
        : theme.colors.action.secondaryDisabled};
  }
`

export const SecondaryButton = css<ButtonProps>`
  ${ButtonStyle}
  border-color: ${({ theme, cancel }) =>
    cancel
      ? theme.colors.interactive.critical
      : theme.colors.action.secondaryDefault};
  color: ${({ theme, cancel }) =>
    cancel
      ? theme.colors.interactive.critical
      : theme.colors.action.secondaryDefault};
  background-color: ${({ theme }) => theme.colors.surface.disabled};
  :hover {
    border-color: ${({ theme, cancel }) =>
      cancel ? theme.colors.interactive.critical : theme.colors.icon.subdued};
    background-color: ${({ theme }) => theme.colors.surface.disabled};
  }
  :active {
    border-color: ${({ theme, cancel }) =>
      cancel
        ? theme.colors.interactive.critical
        : theme.colors.action.secondaryPressed};
    background-color: ${({ theme }) => theme.colors.surface.selectedDefault};
  }
  :focus-visible {
    box-shadow: 0px 0px 5px 0px ${({ theme }) => theme.colors.text.secondary};
  }
  :disabled {
    color: ${({ theme, cancel }) =>
      cancel
        ? theme.colors.interactive.criticalDisabled
        : theme.colors.action.secondaryDisabled};
    border-color: ${({ theme, cancel }) =>
      cancel
        ? theme.colors.interactive.criticalDisabled
        : theme.colors.action.secondaryDisabled};
    background-color: ${({ theme }) => theme.colors.surface.disabled};
  }
`

export const TertiaryButton = css<ButtonProps>`
  ${ButtonStyle}
  color: ${({ theme, cancel }) =>
    cancel
      ? theme.colors.interactive.critical
      : theme.colors.interactive.default};
  background-color: transparent;
  :hover {
    color: ${({ theme, cancel }) =>
      cancel
        ? theme.colors.interactive.criticalHovered
        : theme.colors.interactive.hovered};
    svg path {
      fill: ${({ theme, cancel }) =>
        cancel
          ? theme.colors.interactive.criticalHovered
          : theme.colors.interactive.hovered};
    }
  }
  :active {
    color: ${({ theme, cancel }) =>
      cancel
        ? theme.colors.interactive.critical
        : theme.colors.interactive.depressed};
    svg path {
      fill: ${({ theme }) => theme.colors.interactive.depressed};
    }
  }
  :focus-visible {
    border-color: ${({ theme, cancel }) =>
      theme.colors.interactive[cancel ? 'critical' : 'default']};
  }
  :disabled {
    color: ${({ theme, cancel }) =>
      cancel
        ? theme.colors.interactive.criticalDisabled
        : theme.colors.interactive.disabled};
    svg path {
      fill: ${({ theme, cancel }) =>
        cancel
          ? theme.colors.interactive.criticalDisabled
          : theme.colors.interactive.disabled};
    }
  }
`

export const SuccessButton = css<ButtonProps>`
  ${ButtonStyle}
  color: ${({ theme }) => theme.colors.icon.onPrimary};
  background-color: ${({ theme }) => theme.colors.icon.success};
  border-color: ${({ theme }) => theme.colors.icon.success};
`

export const MainButton = styled.button<ButtonProps>`
  ${({ variation }) =>
    variation === 'outlined'
      ? SecondaryButton
      : variation === 'borderless'
      ? TertiaryButton
      : variation === 'success'
      ? SuccessButton
      : PrimaryButton}
`

export const MainLink = styled(NavLink)<ButtonProps>`
  ${({ variation }) =>
    variation === 'outlined'
      ? SecondaryButton
      : variation === 'borderless'
      ? TertiaryButton
      : variation === 'success'
      ? SuccessButton
      : PrimaryButton}
`
