// Libraries
import styled, { css } from 'styled-components'

// Components
import { Aligner, Body, Icon } from 'heeds-ds'

interface Props {
  type?: 'radio' | 'check'
}

export const ClickArea = styled(Aligner).attrs({
  margin: '0 0.8rem 0 auto',
  width: 'fit-content',
})`
  cursor: pointer;
`

export const DeleteIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.colors.icon.critical,
  iconName: 'delete',
}))``

export const Input = styled.input.attrs({ type: 'text' })`
  background: none;
  border: none;
  outline: none;
  padding: 0;
  width: 100%;

  ${({ theme }) => css`
    font-family: ${theme.fontFamily.roboto};
    font-size: ${theme.fontSize.copy2};

    :focus {
      box-shadow: inset 0 -1px ${theme.colors.text.default};
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: ${theme.fontSize.copy4};
    }
  `}
`

export const NumericIndicator = styled(Body).attrs(({ theme }) => ({
  color: theme.colors.text.subdued,
  margin: '0 0.6rem 0 0',
  type: 'copy4',
}))``

export const SelectionIndicator = styled.div<Props>`
  border-radius: ${({ type }) => (type === 'radio' ? '50%' : '0.4rem')};
  min-height: 1.6rem;
  min-width: 1.6rem;
  outline: 1px solid ${({ theme }) => theme.colors.icon.default};
`
