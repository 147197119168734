import styled from 'styled-components'

type Props = {
  newAthlete?: boolean
  margin?: string
}
export const IconWrapper = styled.div<Props>`
  margin: ${({ margin }) => margin};
  cursor: pointer;
`
export const ConflictCard = styled.div<Props>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  max-width: 338px;
  background: ${({ theme }) => theme.colors.surface.default};
  border: 1px solid
    ${({ newAthlete, theme }) =>
      theme.colors.icon[newAthlete ? 'default' : 'critical']};
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  cursor: pointer;
`

export const AvatarWarper = styled.div``
