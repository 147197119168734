// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body } from 'heeds-ds'

type Props = {
  $shadow?: boolean
  expanded?: boolean
}

export const Badge = styled.div`
  background: ${({ theme }) => theme.colors.badge.purpleBackground};
  border-radius: 1.6rem;
  padding: 0.8rem 1.6rem;
  width: fit-content;
`

export const BadgeText = styled(Body).attrs(({ theme }) => ({
  color: theme.colors.badge.purpleText,
  type: 'copy4',
  weight: 600,
}))``

export const Container = styled(Aligner)<Props>`
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: ${({ expanded }) => (expanded ? 0.8 : 1.6)}rem;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  box-shadow: ${({ $shadow }) =>
    $shadow ? '0px 0px 15px 0px #2B2A6326;' : 'none'};
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    border-radius: 1.6rem;
    box-shadow: none;
  }
`

export const Content = styled(Aligner).attrs({
  flex: 'column',
})<Props>`
  display: ${({ expanded }) => (expanded ? 'flex' : 'none')};

  :not(:first-child) {
    border-top: 1px solid ${({ theme }) => theme.colors.border.input};
  }
`

export const Header = styled(Aligner).attrs({
  align: 'center',
  gap: '1.6rem',
  justify: 'space-between',
  padding: '1.6rem',
})`
  position: relative;
`

export const MuscleGroups = styled(Body).attrs(({ theme }) => ({
  color: theme.colors.text.subdued,
  type: 'copy4',
}))`
  max-width: calc(100% - 10.4rem);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ActionButtons = styled(Aligner).attrs({
  align: 'center',
  gap: '1.6rem',
  width: 'fit-content',
})`
  position: absolute;
  right: 1.6rem;
`

export const Title = styled(Body).attrs({
  type: 'copy3',
  weight: 600,
})`
  width: fit-content;
`

export const WorkoutSet = styled(Aligner).attrs({
  flex: 'column',
})`
  gap: 1.6rem;
  padding: 1.6rem 1.6rem 2.4rem;

  :not(:first-child) {
    border-top: 1px solid ${({ theme }) => theme.colors.border.input};
  }
`
