import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled(Link)`
  text-decoration: none;
  min-width: 270px;
  width: 100%;
  max-width: 320px;
  padding: 2.4rem;
  background-color: ${({ theme }) => theme.colors.surface.default};
  display: flex;
  gap: 1.6rem;
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
`
