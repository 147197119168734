// Libraries
import styled from 'styled-components'

// Components
import { Body } from 'heeds-ds'

export const AddField = styled(Body).attrs(({ theme }) => ({
  color: theme.colors.interactive.default,
  type: 'copy2',
  weight: 500,
}))`
  cursor: pointer;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: ${({ theme }) => theme.fontSize.copy4};
  }
`

export const NumericIndicator = styled(Body).attrs(({ theme }) => ({
  color: theme.colors.text.subdued,
  margin: '0 0.6rem 0 0',
  type: 'copy4',
}))``
