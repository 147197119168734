// Libraries
import styled, { css } from 'styled-components'

// Components
import { Aligner, Body } from 'heeds-ds'

interface Props {
  active?: boolean
}

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 2.2rem 1.6rem 1.6rem;
  position: relative;

  ::after {
    background-color: ${({ theme }) => theme.colors.icon.disabled};
    content: '';
    height: 1.5px;
    opacity: 0.3;
    position: absolute;
    top: 100%;
    width: 100%;
  }
`

export const Title = styled(Body).attrs({
  type: 'copy1',
  weight: 600,
})<Props>`
  color: ${({ active, theme }) =>
    theme.colors.text[active ? 'onPrimary' : 'default']};
`

export const Description = styled(Body).attrs(({ theme }) => ({
  type: 'copy1',
  color: theme.colors.text.subdued,
}))`
  padding: 0 2.4rem 1.6rem;
`

export const Item = styled(Aligner).attrs({
  align: 'center',
  gap: '1.6rem',
  padding: '2.4rem',
})<Props>`
  border-radius: 0.8rem;
  box-shadow: 0px 4px 20px 0px rgba(99, 90, 108, 0.1);

  ${({ active, theme }) => css`
    background: ${active
      ? theme.colors.icon.default
      : theme.colors.surface.default};
    border: 1px solid ${({ theme }) => theme.colors.border.input};

    path {
      fill: ${theme.colors.icon[active ? 'onPrimary' : 'default']};
    }
  `}
`

export const Scroll = styled(Aligner).attrs({
  flex: 'column',
  gap: '1.6rem',
})`
  height: 50vh;
  overflow-y: scroll;
  padding: 1.6rem 2.4rem 2rem;
`
