// React
import { FC, useContext, useEffect, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useController, useFormContext } from 'react-hook-form'

// Components
import * as Styled from './styled'
import { InputWrapper } from '../..'

type Props = {
  disabled?: boolean
  displayError?: boolean
  height?: string
  label?: string
  margin?: string
  name: string
  placeholder?: string
  mandatory?: boolean
  scale?: 'small' | 'medium' | 'large'
  width?: string
}

enum EFieldStates {
  default = 'default',
  error = 'critical',
  focus = 'focused',
  success = 'success',
}

const TextArea: FC<Props> = (props) => {
  const {
    displayError = true,
    disabled,
    height,
    name,
    placeholder,
    scale = 'medium',
    ...restWrapperProps
  } = props
  const theme = useContext(ThemeContext)

  const [fieldState, setFieldState] = useState(EFieldStates.default)
  const [focused, setFocused] = useState(false)

  const { control } = useFormContext()
  const {
    field: { onBlur, onChange, value, ref },
    fieldState: { isDirty, error },
  } = useController({ control, name, defaultValue: '' })

  const COLORS_SCHEMA = {
    critical: theme.colors.text.critical,
    default: theme.colors.border.input,
    focused: theme.colors.text.default,
    success: theme.colors.text.success,
  }

  const handleBlur = () => {
    onBlur()
    setFocused(false)
  }

  const handleFocus = () => {
    setFocused(true)
    setFieldState(EFieldStates.focus)
  }

  useEffect(() => {
    switch (true) {
      case !!error:
        setFieldState(EFieldStates.error)
        break
      case focused:
        setFieldState(EFieldStates.focus)
        break
      case isDirty:
        setFieldState(EFieldStates.success)
        break
      default:
        setFieldState(EFieldStates.default)
        break
    }
  }, [disabled, error, focused, isDirty])

  return (
    <InputWrapper
      {...restWrapperProps}
      color={COLORS_SCHEMA[fieldState]}
      disabled={disabled}
      displayError={displayError}
      error={error?.message}
      name={name}
      size={scale}
    >
      <Styled.InputContainer
        aria-disabled={disabled}
        borderColor={COLORS_SCHEMA[fieldState]}
      >
        <Styled.Input
          height={height}
          disabled={disabled}
          id={name}
          ref={ref}
          value={value}
          name={name}
          onBlur={handleBlur}
          onChange={onChange}
          onFocus={handleFocus}
          placeholder={placeholder}
          data-testid="textArea"
          color={COLORS_SCHEMA[fieldState]}
          scale={scale}
        />
      </Styled.InputContainer>
    </InputWrapper>
  )
}

export default TextArea
