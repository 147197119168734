// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body, Button, Icon } from 'heeds-ds'

export const AddIcon = styled(Icon).attrs((props) => ({
  color: props.theme.colors.text.onPrimary,
  iconName: 'add',
}))``

export const BoldText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`

export const ButtonGroup = styled(Aligner).attrs(() => ({
  align: 'center',
  flex: 'column',
  gap: '2.4rem',
}))`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-direction: row;
    gap: '3.2rem';
    justify-content: center;
  }
`

export const CommonButton = styled(Button).attrs((props) => ({
  margin: '0',
  size: 'small',
  ...props,
}))``

export const Container = styled(Aligner).attrs(() => ({
  align: 'center',
  flex: 'column',
}))`
  height: 100%;
  padding: 0 4.8rem;
  position: relative;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 0;
  }
`

export const Content = styled(Aligner).attrs(() => ({
  align: 'center',
  justify: 'center',
  flex: 'column',
}))`
  height: 100%;
  gap: 4.8rem;
`

export const Header = styled(Aligner).attrs(() => ({
  align: 'center',
  justify: 'space-between',
}))`
  background: white;
  border-radius: 1.5rem;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
  padding: 1.7rem 2.4rem;
  position: absolute;
  top: 2.4rem;
`

export const Name = styled(Body).attrs((props) => ({
  margin: '0 0 0 0.8rem',
  type: 'copy1',
  weight: 500,
  whiteSpace: true,
  ...props,
}))``

export const ShareIcon = styled(Icon).attrs((props) => ({
  color: props.theme.colors.interactive.default,
  iconName: 'share',
}))``

export const Text = styled(Body).attrs((props) => ({
  align: 'center',
  color: props.theme.colors.text.subdued,
  type: 'copy1',
  weight: 500,
}))``
