// React
import { FC } from 'react'

// Components
import { Spinner, SpinnerContainer } from './styled'

type Props = {
  color?: string
  size?: number
}

const LoadingSpinner: FC<Props> = ({ color, size = 24 }) => {
  return (
    <SpinnerContainer>
      <Spinner size={size} color={color} thickness={size / 8} />
    </SpinnerContainer>
  )
}

export default LoadingSpinner
