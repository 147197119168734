// Models
import { Icon } from 'heeds-ds'
import { BodyVariationsTypes } from 'lib/styled-components/models'

// Libraries
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

interface IItemProps {
  fill?: boolean
  fontSize?: BodyVariationsTypes
  itemsPadding?: string
  padding?: string
  progress?: boolean
  scrollAtRight?: boolean
}

export const Container = styled.div<IItemProps>`
  display: flex;
  gap: ${({ progress }) => (progress ? 0 : 4)}rem;
  overflow-x: auto;
  padding: ${({ padding }) => padding || '0 2.4rem'};

  ::after {
    content: '';
    display: ${({ scrollAtRight }) => (scrollAtRight ? 'none' : '')};
    background: linear-gradient(91deg, rgba(255, 255, 255, 0) 0%, #fff 61.62%);
    height: 4rem;
    position: absolute;
    right: 5.9px;
    top: 18rem;
    width: 9.3rem;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 100%;
    padding: ${({ padding }) => padding || '4rem 3.2rem 3.2rem 6.4rem'};
    gap: ${({ progress }) => (progress ? 0 : 2.4)}rem;
  }
`

export const TabItem = styled(Link).attrs({
  replace: true,
})<IItemProps>`
  ${({ fill, fontSize, itemsPadding, theme }) => css`
    align-items: center;
    color: ${theme.colors.text[fill ? 'tertiary' : 'subdued']};
    display: flex;
    flex: 1;
    font-family: ${theme.fontFamily.roboto};
    font-size: ${theme.fontSize[fontSize || 'copy3']};
    font-weight: ${theme.fontWeight.bold};
    gap: 1.6rem;
    line-height: 1.9rem;
    padding: ${itemsPadding || '0.8rem 0.8rem 0.8rem 0'};
    position: relative;
    text-decoration: none;
    user-select: none;
    white-space: nowrap;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-size: ${theme.fontSize[fontSize || 'copy4']};
      line-height: 1.4rem;
      padding: ${itemsPadding || '0 0 1.6rem'};
    }

    ::after {
      content: '';
      position: absolute;
      height: 0.4rem;
      width: 100%;
      left: 0;
      bottom: 0;
      border-radius: 4px;
      background-color: ${fill
        ? theme.colors.action.secondaryDefault
        : theme.colors.surface.neutralDefault};
    }
    :not(:last-child) {
      ::after {
        width: ${fill ? '105%' : '100%'};
      }
    }

    &[aria-current='page'] {
      color: ${theme.colors.text.secondary};
      ::after {
        background-color: ${theme.colors.action.secondaryDefault};
      }
    }

    &[aria-disabled='true'] {
      pointer-events: none;
    }
  `}
`

export const LockIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.colors.icon.disabled,
  iconName: 'lock',
}))``
