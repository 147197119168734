// Models
import {
  EAnamneseAnswerActionTypes,
  IAnamneseAnswer,
  IAnamneseAnswerState,
} from '../models'
import { IAction } from 'lib/redux/models'
import {
  ICreateAnamneseAnswersPayload,
  IDeleteAnamneseAnswerPayload,
  ILoadAnamneseAnswersPayload,
  ILoadAnamneseAnswersResponse,
  IUpdateAnamneseAnswerPayload,
} from 'services/assessmentAnamneseAnswer/@types'

// ACTION TYPES
export const Types = {
  CLEANUP: EAnamneseAnswerActionTypes.CLEANUP,
  FAILURE: EAnamneseAnswerActionTypes.FAILURE,
  FULFILL: EAnamneseAnswerActionTypes.FULFILL,
  REQUEST: EAnamneseAnswerActionTypes.REQUEST,
  SUCCESS: EAnamneseAnswerActionTypes.SUCCESS,
  CREATE: EAnamneseAnswerActionTypes.CREATE,
  GET: EAnamneseAnswerActionTypes.GET,
  UPDATE: EAnamneseAnswerActionTypes.UPDATE,
  PARTIAL_UPDATE: EAnamneseAnswerActionTypes.PARTIAL_UPDATE,
  DELETE: EAnamneseAnswerActionTypes.DELETE,
  LOAD: EAnamneseAnswerActionTypes.LOAD,
  LOAD_MORE: EAnamneseAnswerActionTypes.LOAD_MORE,
  SELECT_ANAMNESE: EAnamneseAnswerActionTypes.SELECT_ANAMNESE,
  SUCCESS_LOAD_MORE: EAnamneseAnswerActionTypes.SUCCESS_LOAD_MORE,
}

// INITIAL STATE
const initialState: IAnamneseAnswerState = {}

// REDUCER
export default (
  state: IAnamneseAnswerState = initialState,
  action?: IAction<unknown>,
): IAnamneseAnswerState => {
  switch (action?.type) {
    case Types.CLEANUP:
      return {
        ...state,
        error: undefined,
        loading: undefined,
        refreshing: undefined,
        anamneseAnswer: undefined,
        anamneseAnswers: undefined,
      }
    case Types.FAILURE:
      return {
        ...state,
        error: action.payload as string,
      }
    case Types.FULFILL:
      return {
        ...state,
        loading: false,
      }
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.SUCCESS:
      return {
        ...state,
        error: undefined,
        ...(action?.payload as IAnamneseAnswerState),
      }
    case Types.SUCCESS_LOAD_MORE: {
      const { results, ...paginatedParams } =
        action.payload as ILoadAnamneseAnswersResponse
      const answersFromState = state.anamneseAnswers || []
      return {
        ...state,
        ...paginatedParams,
        anamneseAnswers: [...answersFromState, ...results],
      }
    }
    case Types.SELECT_ANAMNESE:
      return {
        ...state,
        anamneseAnswer: action.payload as IAnamneseAnswer,
      }
    default:
      return state
  }
}

// ACTIONS
export const cleanup = (): IAction<undefined> => {
  return {
    type: Types.CLEANUP,
  }
}

export const failure = (payload: string): IAction<string> => {
  return {
    type: Types.FAILURE,
    payload,
  }
}

export const fulfill = (): IAction<unknown> => {
  return {
    type: Types.FULFILL,
  }
}

export const request = (): IAction<unknown> => {
  return {
    type: Types.REQUEST,
  }
}

export const success = (
  payload: IAnamneseAnswerState,
): IAction<IAnamneseAnswerState> => {
  return {
    type: Types.SUCCESS,
    payload,
  }
}

// CUSTOM ACTIONS
export const selectAnamneseAnswer = (
  payload: IAnamneseAnswer,
): IAction<IAnamneseAnswer> => {
  return {
    type: Types.SELECT_ANAMNESE,
    payload,
  }
}

export const successLoadMore = (
  payload: ILoadAnamneseAnswersResponse,
): IAction<ILoadAnamneseAnswersResponse> => {
  return {
    type: Types.SUCCESS_LOAD_MORE,
    payload,
  }
}

export const triggerCreateAnamneseAnswers = (
  payload: ICreateAnamneseAnswersPayload,
): IAction<ICreateAnamneseAnswersPayload> => {
  return {
    type: Types.CREATE,
    payload,
  }
}

export const triggerGetAnamneseAnswer = (payload: string): IAction<string> => {
  return {
    type: Types.GET,
    payload,
  }
}

export const triggerUpdateAnamneseAnswer = (
  payload: IAnamneseAnswer,
): IAction<IAnamneseAnswer> => {
  return {
    type: Types.UPDATE,
    payload,
  }
}

export const triggerPartialUpdateAnamneseAnswer = (
  payload: IUpdateAnamneseAnswerPayload,
): IAction<IUpdateAnamneseAnswerPayload> => {
  return {
    type: Types.PARTIAL_UPDATE,
    payload,
  }
}

export const triggerDeleteAnamneseAnswer = (
  payload: IDeleteAnamneseAnswerPayload,
): IAction<IDeleteAnamneseAnswerPayload> => {
  return {
    type: Types.DELETE,
    payload,
  }
}

export const triggerLoadAnamneseAnswers = (
  payload: ILoadAnamneseAnswersPayload,
): IAction<ILoadAnamneseAnswersPayload> => {
  return {
    type: Types.LOAD,
    payload,
  }
}

export const triggerLoadMoreAnamneseAnswers = (
  payload: ILoadAnamneseAnswersPayload,
): IAction<ILoadAnamneseAnswersPayload> => {
  return {
    type: Types.LOAD_MORE,
    payload,
  }
}
