// Libraries
import styled from 'styled-components'

type Props = {
  critical?: boolean
}

export const ContainerBottom = styled.div<Props>`
  backdrop-filter: blur(1.5px);
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  padding: 1.2rem 3.7rem 2.3rem 3.7rem;
  position: fixed;
  right: 0;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 1.2rem 6.4rem;
    right: 4.8rem;
    width: calc(100% - 96px - 9.6rem);
  }
`
