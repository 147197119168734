// React
import { FC } from 'react'

// Components
import * as Styled from './styled'
import { Aligner, Skeleton } from 'heeds-ds'

const LoaderExerciseItem: FC = () => {
  return (
    <Styled.Container>
      <Aligner gap="1.6rem">
        <Styled.LoadAvatar />

        <Aligner flex="column" gap="0.4rem" justify="center">
          <Skeleton width="10rem" height="1.6rem" />

          <Skeleton width="8rem" height="1.4rem" />
        </Aligner>
      </Aligner>

      <Skeleton width="3.6rem" height="3.6rem" />
    </Styled.Container>
  )
}

export default LoaderExerciseItem
