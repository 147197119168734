// Libraries
import { AvatarImage, Root, Fallback } from '@radix-ui/react-avatar'
import styled, { css } from 'styled-components'

type Props = {
  active?: boolean
  color?: string
  disabled?: boolean
  fontSize?: string
  hoverMode?: boolean
  margin?: string
  onClick?: () => void
  size?: number
}

const hoverModeStyle = css<Props>`
  border-color: ${({ active, color, theme }) =>
    active && color ? color : theme.colors.border.input};

  :hover {
    border-color: ${({ color, theme }) => color || theme.colors.text.secondary};
    box-shadow: 0px 0px 10px rgba(43, 42, 99, 0.3);
  }
`

export const AvatarWrapper = styled(Root)<Props>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.surface.default};
  border: 2px solid
    ${({ color, theme }) => color || theme.colors.text.secondary};
  border-radius: 50%;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  display: flex;
  filter: drop-shadow(0px 2.66667px 2.66667px rgba(0, 0, 0, 0.15));
  height: ${({ size }) => size || 20}px;
  justify-content: center;
  margin: ${({ margin }) => margin || 0};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  min-width: ${({ size }) => size || 20}px;
  width: ${({ size }) => size || 20}px;
  ${({ hoverMode }) => (hoverMode ? hoverModeStyle : '')};
`

export const Image = styled(AvatarImage)<Props>`
  border-radius: 50%;
  height: 100%;
  object-fit: contain;
  width: 100%;
`

export const Initials = styled(Fallback)<Props>`
  -webkit-user-select: none;
  -ms-user-select: none;
  color: ${({ color, theme }) => color || theme.colors.text.secondary};
  font-family: ${({ theme }) => theme.fontFamily.barlow};
  font-size: ${({ fontSize }) => fontSize || '2rem'};
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
  margin: ${({ margin }) => margin || '0'};
  user-select: none;
`
