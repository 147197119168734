// Libraries
import styled from 'styled-components'

export const FormContainer = styled.form`
  display: grid;
  width: 100%;
`

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    grid-template-columns: 70%;
    grid-gap: 12px 24px;
  }
`
export const CREFContainer = styled.div`
  align-items: flex-end;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 80px;
`

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: 15px;
  padding: 0 0 6.5rem;
`
