// Models
import { IFinancialExtraPlan } from 'services/financial/@types'

// React
import { FC, useContext, useState } from 'react'

// Libs
import { ThemeContext } from 'styled-components'
import { useDispatch } from 'react-redux'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { renderDaysInFrequency } from 'utils/functions'
import { triggerDeleteProfileExtraPlan } from 'storage/financial/duck'
import { useMediaQuery } from 'hooks'

// Components
import * as Block from 'blocks/dashboard/profile/extraWorkouts'
import * as Styled from './styled'
import { Aligner, Icon, IconButton, Swipe } from 'heeds-ds'

interface Props {
  extraWorkout?: IFinancialExtraPlan<string>
  onCancel: () => void
  onEdit?: boolean
  setOnEdit: () => void
}

// TODO: Tests
const ExtraPlanTag: FC<Props> = ({
  extraWorkout,
  onCancel,
  onEdit,
  setOnEdit,
}) => {
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  const [showIcon, setShowIcon] = useState(false)

  const handleEdit = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation()
    setOnEdit()
  }

  const handleRemove = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation()
    extraWorkout &&
      dispatch(triggerDeleteProfileExtraPlan({ id: extraWorkout.id }))
  }

  return (
    <Swipe
      key={extraWorkout?.name}
      active={!isTablet}
      actions={[
        {
          color: theme.colors.interactive.default,
          icon: 'edit',
          onClick: handleEdit,
        },
        {
          color: theme.colors.interactive.criticalDepressed,
          icon: 'delete',
          onClick: handleRemove,
        },
      ]}
    >
      <Styled.Container
        mode={onEdit ? 'edit' : 'normal'}
        onMouseOver={() => isTablet && setShowIcon(true)}
        onMouseLeave={() => setShowIcon(false)}
      >
        {!onEdit ? (
          <>
            <Aligner flex="column" gap="0.4rem">
              <Styled.Title>{extraWorkout?.name}</Styled.Title>

              <Styled.Location>{extraWorkout?.location}</Styled.Location>

              <Styled.Description>
                {renderDaysInFrequency(extraWorkout?.days)},{' '}
                {extraWorkout?.start_time} às {extraWorkout?.end_time}
              </Styled.Description>
            </Aligner>

            <Aligner align="center" justify="center" gap="24px">
              <Styled.Price>{extraWorkout?.price}</Styled.Price>

              {showIcon && (
                <Aligner gap="1.6rem" align="center">
                  <IconButton
                    iconName="edit"
                    onClick={handleEdit}
                    size="small"
                    track={buttonClickTracking}
                    trackName="open_edit_extra_plan_form"
                    variation="outlined"
                  />

                  <Styled.IconButton onClick={handleRemove}>
                    <Icon
                      iconName="delete"
                      color={theme.colors.icon.critical}
                    />
                  </Styled.IconButton>
                </Aligner>
              )}
            </Aligner>
          </>
        ) : (
          <Block.ProfileExtraWorkoutsForm
            borderColor="critical"
            extraPlan={extraWorkout}
            list={[]}
            onCancel={() => onCancel()}
          />
        )}
      </Styled.Container>
    </Swipe>
  )
}

export default ExtraPlanTag
