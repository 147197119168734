// Libraries
import styled from 'styled-components'

// Components
import { Body, FormButton } from 'heeds-ds'

export const Container = styled.div`
  padding: 2.4rem 0;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 2.4rem 2.4rem 4rem;
    width: 54.8rem;
  }
`

export const Description = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.subdued,
  margin: '0 0 3.2rem',
  type: 'copy3',
}))``

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 3.2rem 2.4rem 8rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 3.2rem 0 4rem;
  }
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 3rem 1.6rem 1.6rem;
  position: relative;
  ::after {
    background-color: ${({ theme }) => theme.colors.icon.disabled};
    content: '';
    height: 1.5px;
    opacity: 0.3;
    position: absolute;
    top: 100%;
    width: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0 1.6rem 1.6rem;
  }
`

export const BodyMessage = styled.textarea.attrs(() => ({}))`
  background: ${({ theme }) => theme.colors.surface.disabled};
  border: none;
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.text.disabled};
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: ${({ theme }) => theme.fontSize.copy4};
  height: 17rem;
  line-height: 1.6rem;
  margin: 1rem 0 0;
  max-height: 17rem;
  outline: none;
  padding: 1.6rem 1.6rem 0;
  resize: none;
`

export const SaveButton = styled(FormButton).attrs(() => ({
  margin: '5.6rem auto 0',
  size: 'small',
}))`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 8rem auto 0;
  }
`

export const Subtitle = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.subdued,
  type: 'copy4',
}))``

export const Title = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.secondary,
  type: 'copy1',
}))``
