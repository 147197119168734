// React
import { FC } from 'react'

// Misc
import { buttonClickTracking } from 'utils/tracking'

// Components
import * as Styled from '../styled'
import { Button } from 'heeds-ds'

// Assets
import errorImage from 'assets/images/email-fail.png'

const ExpiredConfirmation: FC = () => {
  const handleResendEmail = () => undefined

  return (
    <Styled.Container>
      <Styled.Image src={errorImage} />

      <Styled.TitleS>E-mail expirado</Styled.TitleS>

      <Styled.SubtitleS>
        O link de confirmação que foi enviado para o seu e-mail expirou.
      </Styled.SubtitleS>

      <Styled.SmallText>
        Clique em reenviar e-mail aqui embaixo para receber um novo.
      </Styled.SmallText>

      <Button
        onClick={handleResendEmail}
        size="small"
        track={buttonClickTracking}
        trackName="resend_confirmation_email"
      >
        Reenviar e-mail
      </Button>
    </Styled.Container>
  )
}

export default ExpiredConfirmation
