// Libraries
import styled from 'styled-components'

// Components
import { Aligner } from 'heeds-ds'

export const CustomFieldMenu = styled(Aligner).attrs({
  align: 'flex-start',
  justify: 'center',
})`
  grid-column-start: span 3;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    grid-column-start: span 1;
    justify-content: flex-start;
  }
`
