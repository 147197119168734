// Models
import { TModalIconVariation } from 'models'

//React
import { FC } from 'react'

// Components
import * as Styled from '../styled'

type Props = {
  description?: string
  icon?: TModalIconVariation
  title?: string
}

const Header: FC<Props> = ({ description, icon, title }: Props) => {
  return (
    <Styled.Header>
      {icon && (
        <Styled.IconBackground variation={icon}>
          <Styled.HeaderIcon variation={icon} />
        </Styled.IconBackground>
      )}
      {title && <Styled.HeaderTitle>{title}</Styled.HeaderTitle>}
      {description && (
        <Styled.HeaderDescription>{description}</Styled.HeaderDescription>
      )}
    </Styled.Header>
  )
}

export default Header
