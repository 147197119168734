// Models
import {
  IFormInputs,
  IModelInput,
  ISetToExerciseInput,
  IWorkoutSetInput,
  TRoutineDificulty,
  TRoutineGoal,
} from 'models'
import { IWorkoutModelData } from 'storage/workoutModel/models'
import { IWorkoutModelPayload } from 'services/workoutModel/@types'
import {
  IWorkoutRoutine,
  IWorkoutRoutinePresentation,
} from 'storage/workoutRoutine/models'
import { IWorkoutRoutineFormData } from 'views/dashboard/workout/WorkoutCreateRoutine'
import { TPrepareWorkoutsRoutinePayload } from './models'

// Libraries
import moment from 'moment'

// Misc
import { dateFormatEnglishToPortuguese, uid } from 'utils/functions'

// Constants
const GOAL_DISPLAY: Record<TRoutineGoal, string> = {
  HT: 'Hipertrofia',
  FR: 'Redução de Gordura',
  MD: 'Definição Muscular',
  QL: 'Qualidade de Vida',
  PC: 'Condicionamento Físico',
  IP: 'Prevenção de Lesões',
  FT: 'Treinamento Funcional',
  OT: 'Outros',
}

const DIFFICULTY_DISPLAY: Record<TRoutineDificulty, string> = {
  ST: 'Iniciante',
  IN: 'Intermediário',
  AD: 'Avançado',
}

const _parseToString = (value?: number | number[]) => {
  if (!value) return undefined
  return `${value}`
}

const _removeMask = (value?: string) => {
  if (!value) return undefined
  return value.replace(/( seg)|( kg)/, '')
}

export const prepareWorkoutRoutineToPayload: TPrepareWorkoutsRoutinePayload = (
  data: IWorkoutRoutineFormData,
) => {
  const { start_date, end_date, goal, difficulty, ...dataRest } = data

  const newRoutine = {
    ...dataRest,
    goal: goal as TRoutineGoal,
    difficulty: difficulty as TRoutineDificulty,
    start_date: start_date ? moment(start_date).format('YYYY-MM-DD') : null,
    end_date: end_date ? moment(end_date).format('YYYY-MM-DD') : null,
  }

  return newRoutine
}

export const prepareWorkoutRoutineToDisplay = (
  data: IWorkoutRoutine,
): IWorkoutRoutinePresentation => {
  const { start_date, end_date, goal, difficulty, ...dataRest } = data

  const newRoutine = {
    ...dataRest,
    goal: GOAL_DISPLAY[goal],
    difficulty: DIFFICULTY_DISPLAY[difficulty],
    start_date: dateFormatEnglishToPortuguese(start_date),
    end_date: dateFormatEnglishToPortuguese(end_date),
  }

  return newRoutine
}

export const prepareModelsToRedux = (
  data: IFormInputs,
  stateModels: IModelInput[],
) => {
  const updatedStateModels: IModelInput[] = stateModels?.map(
    (stateModel, modelIndex) => {
      const modelFormData = data.models[modelIndex]

      const updatedStateSets = Object.values(stateModel.workout_set)?.map(
        (stateSet) => {
          const setFormData = modelFormData.workout_set?.[stateSet.id]

          const updatedStateSetToExercises = stateSet.exercises?.map(
            (stateSetToExercise, setToExerciseIndex) => {
              const setToExerciseFormData =
                setFormData?.exercises?.[setToExerciseIndex]

              return {
                ...stateSetToExercise,
                repetitions: setToExerciseFormData?.repetitions,
                weight: _removeMask(setToExerciseFormData?.weight),
                cadence: setToExerciseFormData?.cadence,
                speed: setToExerciseFormData?.speed,
                time: setToExerciseFormData?.time,
                comment: setToExerciseFormData?.comment,
              }
            },
          )

          return {
            ...stateSet,
            comments: setFormData?.comments,
            interval: {
              ...setFormData?.interval,
              value: _removeMask(setFormData?.interval?.value),
            },
            quantity: setFormData?.quantity,
            repeat: setFormData?.repeat,
            exercises: updatedStateSetToExercises,
          }
        },
      )

      return {
        ...stateModel,
        name: modelFormData.name,
        workout_set: updatedStateSets.reduce(
          (acc, stateSet) => ({ ...acc, [stateSet.id]: stateSet }),
          {},
        ),
      }
    },
  )

  return updatedStateModels
}

export const prepareModelsToDisplay = (models: IWorkoutModelData[]) => {
  const updatedModels: IModelInput[] = models.map((stateModel) => {
    const updatedSet: IWorkoutSetInput[] =
      stateModel.workout_set?.map((stateSet) => {
        const updatedExercises: ISetToExerciseInput[] = stateSet.exercises.map(
          (stateExercise) => {
            const { repetitions, comment } = stateExercise

            return {
              ...stateExercise,
              id: uid(),
              cadence: _parseToString(stateExercise.cadence),
              repetitions,
              weight: _parseToString(stateExercise.weight),
              speed: _parseToString(stateExercise.speed),
              time: _parseToString(stateExercise.time),
              comment,
            }
          },
        )

        return {
          ...stateSet,
          id: stateSet.id,
          interval: { value: _parseToString(stateSet.interval) },
          quantity: _parseToString(stateSet.quantity),
          exercises: updatedExercises,
        }
      }) || []

    return {
      ...stateModel,
      id: `${stateModel.id}`,
      workout_set: updatedSet.reduce(
        (acc, stateSet) => ({ ...acc, [`${stateSet.id}`]: stateSet }),
        {},
      ),
    }
  })

  return updatedModels
}

export const prepareModelsToPayload = (workoutModels: IModelInput[]) => {
  const updatedModels: IWorkoutModelPayload[] = workoutModels.map((model) => {
    const updatedSet = Object.values(model.workout_set).map((set) => {
      const updatedExercises = set.exercises.map((exercises) => {
        const { cadence, exercise, repetitions, weight } = exercises

        return {
          repetitions: repetitions || undefined,
          weight: Number(weight?.replace(' kg', '') || '.'),
          cadence: Number(cadence || '.'),
          exercise: exercise.id,
        }
      })

      const { comments, interval, quantity, type, id } = set

      return {
        id: id.slice(0, 3) === 'NEW' ? undefined : id,
        comments,
        quantity: Number(quantity || '.') || undefined,
        interval: Number(interval?.value?.replace(' seg', '') || '.'),
        exercises: updatedExercises,
        type,
      }
    })

    const { id, name, workout_routine } = model

    return {
      id: id.slice(0, 3) === 'NEW' ? undefined : id,
      name,
      workout_routine,
      workout_set: updatedSet,
    }
  })

  return updatedModels
}
