// Models
import { FieldType } from '..'
import { IExerciseData } from 'storage/exercise/models'
import { IWorkoutSetInput, TSpecialSetInput } from 'models'

// React
import { ChangeEvent, FC } from 'react'

// Components
import MultiSetContent from './MultiSetContent'
import NormalSetContent from './NormalSetContent'

export type SetType = 'normal' | 'bi_set' | 'tri_set' | 'super_set'

export type SetContentProps = {
  addCustomField: (field: FieldType, index: number, remove?: boolean) => void
  displayMode?: boolean
  fieldLabels: Record<FieldType, string>
  handleCheckboxChange: (event: ChangeEvent<HTMLInputElement>) => void
  modelIndex: number
  name: string
  onClickImage?: (exercise: IExerciseData) => void
  onRemove?: () => void
  openExercisesModal?: () => void
  readonly?: boolean
  removeExerciseFromWorkoutSet?: (exerciseIndex: number) => void
  replicateWorkoutSetFields?: (set: IWorkoutSetInput) => void
  showBorder?: boolean
  specialSet?: TSpecialSetInput
  type: SetType
  visible?: boolean
  workoutSet: IWorkoutSetInput & { index?: number }
}

const SetContent: FC<SetContentProps> = ({ type, ...props }) => {
  const contents: Record<SetType, JSX.Element> = {
    normal: <NormalSetContent {...props} />,
    bi_set: <MultiSetContent {...props} amountOfExercise={2} />,
    tri_set: <MultiSetContent {...props} amountOfExercise={3} />,
    super_set: <MultiSetContent {...props} amountOfExercise={10} />,
  }

  return contents[type]
}

export default SetContent
