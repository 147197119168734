// React
import { forwardRef } from 'react'

// Components
import * as Styled from './styled'

type InputAttrs = React.InputHTMLAttributes<HTMLInputElement>

type Props = InputAttrs & {
  'data-testid'?: string
  scale?: 'small' | 'medium' | 'large'
}

const Input = forwardRef<HTMLInputElement, Props>(
  ({ scale = 'medium', ...attributes }, ref) => (
    <Styled.Input ref={ref} scale={scale} {...attributes} />
  ),
)
Input.displayName = 'Input'

export default Input
