// Libraries
import styled from 'styled-components'

// Components
import { Body, Subtitle } from 'heeds-ds'

type ConfirmationCardProps = {
  alert?: boolean
  open?: boolean
  width?: string
}

export const ModalTitle = styled(Subtitle).attrs(() => ({
  type: 'sh2',
  weight: 600,
}))`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: ${({ theme }) => theme.fontSize.copy1};
  }
`

export const Container = styled.div<ConfirmationCardProps>`
  width: 100%;
  padding: 0 24px 80px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 32px;
  }
`

export const CardTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px 16px 16px 16px;
  margin: 0 0 16px;
  ::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.border.input};
    position: absolute;
    margin-top: 42px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0px;
    justify-content: flex-start;
    ::after {
      width: 0px;
    }
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding: 32px 0 0px;
`

export const LongerDescriptionContainer = styled.div`
  margin: 20px 40px 26px 40px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 368px;
    margin: 24px 0 34px 0;
  }
`

export const AnamneseTemplateList = styled.div`
  height: 14.5rem;
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
`

export const EllipsisText = styled(Body).attrs(() => ({
  type: 'copy3',
  weight: '800',
}))`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.text.secondary};
`
