// Libraries
import styled from 'styled-components'

// Components
import { Body, Button, IconButton } from 'heeds-ds'

type ConfirmationCardProps = {
  alert?: boolean
  open?: boolean
  width?: string
}

export const Container = styled.div<ConfirmationCardProps>`
  width: 100%;
  padding: 0 24px 50px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 32px;
    min-width: 470px;
    max-width: 470px;
  }
`

export const CardTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px 16px 16px 16px;
  margin: 0 0 16px;
  ::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.border.input};
    position: absolute;
    margin-top: 42px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0px;
    justify-content: flex-start;
    ::after {
      width: 0px;
    }
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 36px;
`

export const TopButtonCotainer = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.colors.border.input};
  padding-bottom: 24px;
`

export const LongerDescriptionContainer = styled.div`
  margin: 20px 40px 26px 40px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 368px;
    margin: 24px 0 34px 0;
  }
`

export const AthleteList = styled.div`
  height: 32rem;
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const AthleteNameContainer = styled.label`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 75%;
`

export const EllipsisText = styled(Body).attrs(() => ({
  type: 'copy4',
  weight: '800',
}))`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.text.secondary};
`

export const LinkRow = styled.div`
  align-items: center;
  display: flex;
  gap: 2.4rem;
  margin: 0.8rem 0;
  padding: 0.8rem 0;
  width: 100%;
`

export const LinkContainer = styled.div`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: 8px;
  display: flex;
  padding: 0.8rem 1.6rem;
  width: 100%;
`

export const LinkText = styled(Body).attrs((props) => ({
  color: props.theme.colors.text.subdued,
  type: 'copy4',
}))``

export const CopyButton = styled(IconButton).attrs(() => ({
  iconName: 'contentCopy',
  margin: '0',
  size: 'small',
}))``

export const InviteButton = styled(Button).attrs(() => ({
  variation: 'outlined',
  width: '100%',
}))``
