// React
import { ReactNode } from 'react'

// Libraries
import InfiniteScroll from 'react-infinite-scroll-component'

// Components
import * as Styled from './styled'
import { Aligner } from '../../components/UI/Containers'

export interface Props<TGeneric> {
  data: TGeneric[]
  onEndReached?: () => void
  keyExtractor: (item: TGeneric) => string
  listLength?: number
  listFooterComponent?: ReactNode | ReactNode[]
  listEmptyComponent?: ReactNode | ReactNode[]
  renderItem: (item: TGeneric) => ReactNode | ReactNode[]
  height?: string
}

const List = <TGeneric,>(props: Props<TGeneric>) => {
  const {
    data,
    onEndReached,
    keyExtractor,
    listLength = 10000,
    listFooterComponent,
    listEmptyComponent,
    renderItem,
    height,
  } = props

  const fetchMoreData = () => {
    onEndReached && onEndReached()
  }

  return (
    <Styled.ScrollStyle>
      <InfiniteScroll
        dataLength={data.length}
        next={fetchMoreData}
        hasMore={data.length < listLength}
        height={height}
        loader={listFooterComponent}
        scrollThreshold={0.8}
      >
        <Aligner flex="column" data-testid="listComponent" width="auto">
          {data.length === 0 ? (
            <Aligner data-testid="listEmpty">{listEmptyComponent}</Aligner>
          ) : (
            data.map((item) => {
              const key = keyExtractor(item)
              return <Aligner key={key}>{renderItem(item)}</Aligner>
            })
          )}
        </Aligner>
      </InfiniteScroll>
    </Styled.ScrollStyle>
  )
}

export default List
