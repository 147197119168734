// Models
import { Props as ButtonProps } from 'heeds-ds/src/components/Button/BaseButton'
import { TModalIconVariation } from 'models'

//React
import React, { FC } from 'react'

// Libraries
import * as Dialog from '@radix-ui/react-dialog'

// Misc
import { useModal } from 'hooks'

// Components
import * as Styled from './styled'
import { Footer, Header } from './primitives'
import { IconButton } from 'heeds-ds'

type buttonProps = ButtonProps & {
  name: string
}

type Props = {
  children?: React.ReactNode
  description?: string
  icon?: TModalIconVariation
  maxWidth?: string
  onClose?: () => void
  primaryButton?: buttonProps
  secondaryButton?: buttonProps
  showExit?: boolean
  title?: string
}

const Modal: FC<Props> = ({
  children,
  description,
  icon,
  maxWidth,
  onClose,
  primaryButton,
  secondaryButton,
  showExit = true,
  title,
}: Props) => {
  const { isVisible, closeModal, triggerAnimation } = useModal()

  const handleClose = () => {
    onClose?.()
    closeModal()
  }

  const hasHeader = title || description || icon
  const hasFooter = primaryButton || secondaryButton

  return (
    <Dialog.Root open={Boolean(isVisible)} onOpenChange={handleClose}>
      <Dialog.Portal>
        <Styled.DialogOverlay />
        <Styled.DialogContent
          $maxWidth={maxWidth}
          $triggerAnimation={triggerAnimation}
        >
          {showExit && (
            <Styled.CloseButton asChild onClick={handleClose}>
              <IconButton iconName="close" size="small" margin="0" />
            </Styled.CloseButton>
          )}

          <Styled.GestureBarContainer onClick={handleClose}>
            <Styled.GestureBar />
          </Styled.GestureBarContainer>

          {hasHeader && (
            <Header description={description} icon={icon} title={title} />
          )}

          {children}

          {hasFooter && (
            <Footer
              onClose={handleClose}
              primaryButton={primaryButton}
              secondaryButton={secondaryButton}
            />
          )}
        </Styled.DialogContent>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default Modal
