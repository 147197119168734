// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useController, useFormContext } from 'react-hook-form'

// Misc
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import * as Styled from './styled'
import InputTimeRange from 'components/InputTimeRange'
import { Aligner, Icon } from 'heeds-ds'

type Props = {
  label?: string
  name: string
  width?: string
  conflict?: boolean
  onConflict?: () => void
}

const ScheduleDayCard: FC<Props> = ({
  label = '',
  name,
  conflict,
  onConflict,
  width,
}) => {
  const { control, watch } = useFormContext()
  const {
    field: { onChange, value },
  } = useController({ control, name: `${name}.day` })
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  return (
    <Aligner align="center">
      <Styled.Container
        active={!!watch(`${name}.day`)}
        data-testid="ScheduleDay"
        width={width}
        conflict={conflict}
      >
        {!isDesktop ? (
          <Styled.Content>
            <Styled.MediumCheckbox
              name={`${name}.day`}
              id={name}
              inputValue={name}
            />
            <Styled.WarperSimpleToggle>
              <Styled.SimpleToggle
                label={label}
                handleClick={(isActive) => onChange(isActive ? name : '')}
                toggleValue={name}
                value={value}
                isConflict={conflict}
                width="100%"
                onConflict={onConflict}
              />
            </Styled.WarperSimpleToggle>
          </Styled.Content>
        ) : (
          <Styled.SimpleToggle
            label={label}
            handleClick={(isActive) => onChange(isActive ? name : '')}
            toggleValue={name}
            value={value}
            isConflict={conflict}
            onConflict={onConflict}
          />
        )}
        <InputTimeRange
          disabled={!watch(`${name}.day`)}
          nameStart={`${name}.start`}
          nameEnd={`${name}.end`}
          placeholderStart={`Horário de início`}
          placeholderEnd={`Horário de Término`}
        />
      </Styled.Container>
      {conflict && (
        <Styled.ConflictWarper>
          {!isDesktop && (
            <Styled.IconWarper>
              <Icon
                iconName="warningSolid"
                color={theme.colors.icon.onPrimary}
              />
            </Styled.IconWarper>
          )}
          <Styled.ConflictText>Conflito de horário</Styled.ConflictText>
        </Styled.ConflictWarper>
      )}
    </Aligner>
  )
}

export default ScheduleDayCard
