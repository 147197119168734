// Models
import { Route as TRoute } from 'models'

// React
import { useLayoutEffect, useState } from 'react'

// Libs
import { Navigate, Route, Routes } from 'react-router-dom'

// Components
import * as Layout from 'layouts'
import { AuthRoute } from './AuthRoute'
import { PrivateRoute } from './PrivateRoute'
import NotFound from 'views/general/404'

// Misc
import {
  athleteAssessments,
  athleteRegister,
  auth,
  authAthlete,
  dashboard,
  general,
  library,
  profile,
  settings,
  webview,
  workoutRoutine,
} from './paths'
import { getUserLoggedState } from 'lib/axios/jwt'

// Constants
const STORAGE_KEY = `auth-tokens-${process.env.NODE_ENV}`

function AppRouter() {
  const [isLoggedIn, setIsLoggedIn] = useState(getUserLoggedState())

  const routesRenderer = (route: TRoute) => (
    <Route element={<route.element />} path={route.path} key={route.path} />
  )

  useLayoutEffect(() => {
    function storageEventHandler(event: StorageEvent) {
      if (event.key === STORAGE_KEY) {
        const isLogged = getUserLoggedState()
        setIsLoggedIn(isLogged)
      }
    }

    window.addEventListener('storage', storageEventHandler)
    return () => {
      window.removeEventListener('storage', storageEventHandler)
    }
  }, [isLoggedIn])

  return (
    <Routes>
      <Route
        element={
          <AuthRoute element={<Layout.AuthLayout />} isLoggedIn={isLoggedIn} />
        }
        path="/"
      >
        {auth.map(routesRenderer)}
      </Route>

      <Route
        element={
          <AuthRoute element={<webview.element />} isLoggedIn={isLoggedIn} />
        }
        path={webview.path}
      />

      {general.map(routesRenderer)}

      {authAthlete.map(routesRenderer)}

      <Route
        element={
          <PrivateRoute
            element={<Layout.DashboardLayout />}
            isLoggedIn={isLoggedIn}
          />
        }
        path="/dashboard"
      >
        {dashboard.map(routesRenderer)}

        <Route element={<Layout.ProfileLayout />} path="/dashboard/perfil">
          {profile.map(routesRenderer)}
        </Route>

        <Route
          element={<Layout.ConfigurationLayout />}
          path="/dashboard/perfil"
        >
          {settings.map(routesRenderer)}
        </Route>

        <Route
          element={<Layout.AthleteRegisterLayout />}
          path="/dashboard/alunos"
        >
          {athleteRegister.map(routesRenderer)}
        </Route>

        <Route element={<Layout.WorkoutLayout />} path="/dashboard/alunos">
          {workoutRoutine.map(routesRenderer)}
        </Route>

        <Route path="/dashboard/alunos">
          {athleteAssessments.map(routesRenderer)}
        </Route>

        <Route element={<Layout.LibraryLayout />} path="/dashboard/biblioteca">
          {library.map(routesRenderer)}
        </Route>

        <Route element={<NotFound />} path="*"></Route>
      </Route>
      <Route path="*" element={<Navigate to="/404" replace />}></Route>
      <Route path="/404" element={<NotFound />}></Route>
    </Routes>
  )
}

export default AppRouter
