//Models
import { TButtonSizes } from '../../models'

// Libraries
import styled from 'styled-components'

type Props = {
  size?: TButtonSizes
}

enum ButtonSizes {
  xsmall = '40px',
  small = '45px',
  medium = '50px',
  large = '55px',
}

enum IconMargin {
  xsmall = '6px',
  small = '8px',
  medium = '10px',
  large = '12px',
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 0;
`

export const InnerContainer = styled.div<Props>`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  button {
    margin: 0;
    padding: 0;
    height: ${({ size }) => (size ? ButtonSizes[size] : ButtonSizes.medium)};
    width: ${({ size }) => (size ? ButtonSizes[size] : ButtonSizes.medium)};
    border-radius: 100px;
  }
  svg {
    align-self: flex-end;
    margin-bottom: ${({ size }) =>
      size ? IconMargin[size] : IconMargin.medium};
  }
`

export const IconWrapper = styled.div`
  cursor: pointer;
  margin-top: 2px;
`

export const ButtonWrapper = styled.div``
