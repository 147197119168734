// Models
import { Props as BaseProps, TPublicProps } from '../BaseBlock'

// React
import { FC } from 'react'

// Components
import BaseBlock from '../BaseBlock'
import { Aligner, InputText } from 'heeds-ds'

type Props = Pick<BaseProps, TPublicProps>

const TextBlock: FC<Props> = ({ name, ...props }) => {
  return (
    <BaseBlock {...props} mandatoryField name={name} type="text">
      <Aligner flex="column" gap="2.4rem">
        <InputText
          displayError={false}
          label="Digite sua pergunta"
          margin="0"
          name={`${name}.question`}
          placeholder="Sua pergunta para o aluno vem aqui"
          scale="small"
        />
      </Aligner>
    </BaseBlock>
  )
}

export default TextBlock
