// Models
import { IAction } from 'lib/redux/models'
import {
  EExerciseActionTypes,
  IExerciseListPayload,
  IExerciseState,
} from '../models'
import { IGetExercisesResponse } from 'services/exercise/@types'

// ACTION TYPES
export const Types = {
  CLEANUP: EExerciseActionTypes.CLEANUP,
  FAILURE: EExerciseActionTypes.FAILURE,
  FULFILL: EExerciseActionTypes.FULFILL,
  REQUEST: EExerciseActionTypes.REQUEST,
  SUCCESS: EExerciseActionTypes.SUCCESS,
  GET_EXERCISES: EExerciseActionTypes.GET_EXERCISES,
  GET_MORE_EXERCISES: EExerciseActionTypes.GET_MORE_EXERCISES,
  SUCCESS_LOAD_MORE: EExerciseActionTypes.SUCCESS_LOAD_MORE,
}

// INITIAL STATE
const initialState: IExerciseState = {}

// REDUCER
export default (
  state: IExerciseState = initialState,
  action?: IAction<unknown>,
): IExerciseState => {
  switch (action?.type) {
    case Types.CLEANUP:
      return {
        ...state,
        error: undefined,
        loading: undefined,
        refreshing: undefined,
      }
    case Types.FAILURE:
      return {
        ...state,
        error: action.payload as string,
      }
    case Types.FULFILL:
      return {
        ...state,
        loading: false,
      }
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.SUCCESS:
      return {
        ...state,
        ...(action?.payload as IExerciseState),
      }
    case Types.SUCCESS_LOAD_MORE: {
      const { results, ...paginatedPayload } =
        action.payload as IGetExercisesResponse
      const exercisesFromState = state.exercises?.results || []
      const exercisesFromPayload = results || []

      return {
        ...state,
        exercises: {
          ...paginatedPayload,
          results: [...exercisesFromState, ...exercisesFromPayload],
        },
      }
    }
    default:
      return state
  }
}

// BASE ACTIONS
export const cleanup = (): IAction<undefined> => {
  return {
    type: Types.CLEANUP,
  }
}

export const failure = (payload: string): IAction<string> => {
  return {
    type: Types.FAILURE,
    payload,
  }
}

export const fulfill = (): IAction<undefined> => {
  return {
    type: Types.FULFILL,
  }
}

export const request = (): IAction<undefined> => {
  return {
    type: Types.REQUEST,
  }
}

export const success = (payload: IExerciseState): IAction<IExerciseState> => {
  return {
    type: Types.SUCCESS,
    payload,
  }
}

// CUSTOM ACTIONS
export const successLoadMore = (
  payload: IGetExercisesResponse,
): IAction<IGetExercisesResponse> => {
  return {
    type: Types.SUCCESS_LOAD_MORE,
    payload,
  }
}

export const triggerLoadExercises = (
  payload: IExerciseListPayload,
): IAction<IExerciseListPayload> => {
  return {
    type: Types.GET_EXERCISES,
    payload,
  }
}

export const triggerLoadMoreExercises = (
  payload: IExerciseListPayload,
): IAction<IExerciseListPayload> => {
  return {
    type: Types.GET_MORE_EXERCISES,
    payload,
  }
}

export const actions = {
  cleanup,
  failure,
  fulfill,
  request,
  success,
  successLoadMore,
  triggerLoadExercises,
  triggerLoadMoreExercises,
}
