// React
import React, { FC, useContext, useMemo, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Components
import * as Styled from './styled'
import { Icon } from '../..'

export type IFilter = {
  label: string
  param: string
  value: string
}

type Props = {
  disabled?: boolean
  margin?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: () => void
  placeholder?: string
  scale?: 'small' | 'medium' | 'large'
  value: string
  width?: string
}

const InputSearch: FC<Props> = ({
  disabled = false,
  margin,
  onChange,
  onClick,
  placeholder = 'Pesquise aqui...',
  scale = 'medium',
  value,
  width,
}) => {
  const theme = useContext(ThemeContext)

  const [focused, setFocused] = useState(false)

  const iconColor = useMemo(
    () => theme.colors.icon[disabled ? 'disabled' : 'default'],
    [disabled, theme.colors.icon],
  )

  return (
    <Styled.Container
      disabled={disabled}
      data-testid="component"
      isFocused={focused}
      margin={margin}
      variation={scale}
      width={width}
    >
      <Icon color={iconColor} iconName="search" size={24} />

      <Styled.Input
        data-testid="input"
        disabled={disabled}
        inputMode="search"
        onBlur={() => setFocused(false)}
        onChange={onChange}
        onFocus={() => setFocused(true)}
        placeholder={placeholder}
        value={value}
        variation={scale}
      />

      {onClick && (
        <Styled.FilterButton
          data-testid="show-filters-button"
          disabled={disabled}
          onClick={onClick}
        >
          <Icon color={iconColor} iconName="filter" size={24} />
        </Styled.FilterButton>
      )}
    </Styled.Container>
  )
}

export default InputSearch
