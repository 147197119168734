// Models
import { IAthleteInfoState } from 'storage/athleteInfo/models'
import { IAthletePlansState } from 'storage/athletePlans/models'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useEffect, useLayoutEffect } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Navigate, useParams, useSearchParams } from 'react-router-dom'

// Misc
import { triggerLoadAthlete } from 'storage/athleteInfo/duck'
import { triggerLoadAthletePlans } from 'storage/athletePlans/duck'
import { urls } from 'routes/paths'
import { useMediaQuery, useMenu } from 'hooks'

// Components
import * as Block from 'blocks/dashboard/financial'
import * as Styled from './styled'
import { Loading, RadioButton } from 'heeds-ds'

// Assets
import { FINANCIALS } from './mock'

const FinancialAthlete: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { id = '' } = useParams()
  const {
    error,
    info,
    loading: loadingInfo,
  } = useSelector<IStore, IAthleteInfoState>((state) => state.athleteInfo)
  const { loading: loadingPlans, plans } = useSelector<
    IStore,
    IAthletePlansState
  >((state) => state.athleteInfo)
  const { setPagename } = useMenu()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const methods = useForm({
    defaultValues: { filterDays: '', filterStatus: '' },
  })
  const { register, watch } = methods

  const daysField = watch('filterDays')
  const statusField = watch('filterStatus')
  const daysParam = searchParams.get('days')
  const statusParam = searchParams.get('status')

  useEffect(() => {
    if (daysField) {
      const query = searchParams

      if (daysParam) {
        if (daysParam) query.set('days', daysField)
        else query.delete('days')
      } else {
        if (daysField) query.append('days', daysField)
      }

      setSearchParams(query)
    }
  }, [daysField, daysParam, searchParams, setSearchParams])

  useEffect(() => {
    if (statusField) {
      const query = searchParams

      if (statusParam) {
        if (statusParam) query.set('status', statusField)
        else query.delete('status')
      } else {
        if (statusField) query.append('status', statusField)
      }

      setSearchParams(query)
    }
  }, [statusField, statusParam, searchParams, setSearchParams])

  useLayoutEffect(() => {
    if (!info || info.id !== parseInt(id)) {
      dispatch(triggerLoadAthlete({ id: parseInt(id) }))
    }
    if (!plans) {
      dispatch(triggerLoadAthletePlans({ id: parseInt(id) }))
    }
  }, [dispatch, id, info, plans])

  useLayoutEffect(() => {
    setPagename('Financeiro')

    return () => setPagename('Dashboard')
  }, [setPagename])

  if (id && parseInt(id) === info?.id && error === '404') {
    return <Navigate to={urls.financial} replace />
  }

  if (loadingInfo || loadingPlans) {
    return <Loading active />
  }

  return (
    <Styled.Container>
      <Block.FinancialAthleteHeader />

      <Styled.ContentFilters>
        {!isDesktop && <Styled.Title>Filtro</Styled.Title>}

        <RadioButton
          {...register('filterDays')}
          refName={register('filterDays').ref}
          customLabel={['30 dias', '3 meses', '6 meses', '1 ano']}
          options={['monthly', 'quarterly', 'seminanual', 'anual']}
          textRadio
          margin="0"
          scale="small"
          gap={isDesktop ? '0.8rem' : '2.4rem'}
          displayError={false}
        />

        <RadioButton
          {...register('filterStatus')}
          refName={register('filterStatus').ref}
          customLabel={['Pendente', 'Pago', 'A Vencer']}
          options={['pendent', 'pay', 'due_date']}
          textRadio
          margin="0"
          scale="small"
          gap={isDesktop ? '0.8rem' : '2.4rem'}
          displayError={false}
        />
      </Styled.ContentFilters>

      <Block.FinancialAthleteList financials={FINANCIALS} />
    </Styled.Container>
  )
}

export default FinancialAthlete
