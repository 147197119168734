// Models
import { IAthleteSchedule } from 'services/athleteSchedule/@types'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useLayoutEffect } from 'react'

// Libraries
import { Navigate, generatePath, useParams } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { daysName, hourMinuteFormat } from 'utils/functions'
import { triggerLoadAthleteOverview } from 'storage/athleteOverview/duck'
import { triggerToastWarning } from 'storage/general/duck'
import { urls } from 'routes/paths'
import { useMediaQuery, useMenu } from 'hooks'

// Components
import * as Blocks from 'blocks/dashboard/athlete/AthleteProfile'
import * as Styled from './styled'
import { Body, Icon, LinkButton } from 'heeds-ds'
import AthleteHoursCard from 'components/AthleteHoursCard'
import AthleteProfileLoader from './loader'
import ProfileCard from 'components/ProfileCard'

const USER_INFO_KEYS = [
  'birthdate',
  'gender',
  'phone_number',
  'email',
  'address',
  'instagram',
  'emergency_phone_number',
]

const formatScheduleList = (schedules: IAthleteSchedule[]) => {
  return schedules.flatMap((schedule) => {
    const end = hourMinuteFormat(schedule.end ?? '')
    const start = hourMinuteFormat(schedule.start ?? '')
    const days = Object.fromEntries(
      Object.entries(schedule.recurring_schedule ?? {}).filter(
        ([key]) => key !== 'id',
      ),
    )

    const recurringDays = Object.keys(days) as Array<keyof typeof daysName>

    return recurringDays.flatMap((recurring) =>
      schedule.recurring_schedule &&
      schedule.recurring_schedule[recurring] === true
        ? {
            location: schedule.location ?? '',
            end,
            start,
            day: daysName[recurring],
          }
        : [],
    )
  })
}

const AthleteProfile: FC = () => {
  const { id } = useParams()
  const { athleteOverview, workoutRoutine } = useSelector<IStore, IStore>(
    (state) => state,
  )

  const { setPagename } = useMenu()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const { error, overview, loading } = athleteOverview
  const { latestWorkoutRoutine, loading: loadingWorkouts } = workoutRoutine

  const profileInfo = Object.fromEntries(
    Object.entries(overview ?? {})
      .filter(([key]) => USER_INFO_KEYS.includes(key))
      .sort(
        (a, b) => USER_INFO_KEYS.indexOf(a[0]) - USER_INFO_KEYS.indexOf(b[0]),
      ),
  )

  useLayoutEffect(() => {
    if (id) {
      dispatch(triggerLoadAthleteOverview(parseInt(id)))
    }
  }, [id, dispatch])

  useLayoutEffect(() => {
    setPagename('Alunos')

    return () => setPagename('Dashboard')
  }, [setPagename])

  if (
    (id && parseInt(id) === overview?.id && error === '404') ||
    isNaN(parseInt(id ?? ''))
  ) {
    dispatch(triggerToastWarning({ customTitle: 'ID do atleta inválido!' }))

    return <Navigate to={urls.athletes} replace />
  }

  if (loading || loadingWorkouts || parseInt(id ?? '') !== overview?.id) {
    return <AthleteProfileLoader />
  }

  return (
    <Styled.Container>
      <Styled.ProfileSection>
        {isDesktop && (
          <LinkButton
            margin="0"
            size="small"
            variation="borderless"
            to={urls.athletes}
            track={buttonClickTracking}
            trackName="back_to_athletes_list"
          >
            <Icon
              iconName="arrowBack"
              color={theme.colors.interactive.default}
            />
            Voltar para alunos
          </LinkButton>
        )}
        <ProfileCard
          status={overview.status}
          info={profileInfo}
          name={overview.name}
          profileUrl={generatePath(urls.athleteGeneral, {
            id,
          })}
        />
      </Styled.ProfileSection>

      <Styled.ProfileSection>
        <Styled.Content>
          <Styled.ContentTitle>Horários Agendados</Styled.ContentTitle>
          {!!overview.schedules?.length && (
            <Styled.HorizontalContainer>
              {formatScheduleList(overview.schedules)?.map((hour) => (
                <AthleteHoursCard key={hour.day} {...hour} />
              ))}
            </Styled.HorizontalContainer>
          )}
          {!overview.schedules?.length && (
            <>
              <Body
                type="copy3"
                align="center"
                margin="3.2rem auto"
                color={theme.colors.text.disabled}
              >
                Esse aluno não possui um plano ativo.
              </Body>
              <LinkButton
                margin="0 auto"
                size="xsmall"
                variation="borderless"
                to={generatePath(urls.athleteSchedule, { id })}
                track={buttonClickTracking}
                trackName="athlete_profile_empty_schedule_link"
              >
                Preencher horários
              </LinkButton>
            </>
          )}
        </Styled.Content>

        <Blocks.Financial overview={overview} />

        <Blocks.ProfileTabs
          athleteId={overview.id}
          comments={overview.comment}
          lastRoutine={latestWorkoutRoutine}
        />
      </Styled.ProfileSection>
    </Styled.Container>
  )
}

export default AthleteProfile
