// Models
import { EWorkoutDifficulty } from 'models'
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'
import { TMenuDropdownOption } from 'heeds-ds/src/models'

// React
import { FC } from 'react'

// Misc
import { intervalDateToString } from 'utils/helpers/date'

// Components
import * as Styled from './styled'
import { Avatar, DropdownMenu, IconButton } from 'heeds-ds'

export type Props = {
  onClick: () => void
  onEdit: () => void
  onRemove: () => void
  routine: IWorkoutRoutine
  routinesList?: boolean
}

const Desktop: FC<Props> = ({ routine, onClick, onEdit, onRemove }) => {
  const { name, start_date, end_date, athlete_name, difficulty } = routine

  const options: TMenuDropdownOption[] = [
    {
      icon: 'edit',
      label: 'Editar',
      onClick: onEdit,
    },
    {
      color: 'red',
      icon: 'delete',
      label: 'Excluir',
      onClick: onRemove,
    },
  ]

  return (
    <Styled.Row data-testid="row" onClick={onClick}>
      <Styled.Cell
        align="center"
        data-testid="columnAthleteInformation"
        gap="8px"
      >
        <Avatar name={athlete_name ?? ''} scale="xSmall" />

        <Styled.Text>{athlete_name}</Styled.Text>
      </Styled.Cell>

      <Styled.Cell align="center" data-testid="columnName">
        <Styled.Text>{name}</Styled.Text>
      </Styled.Cell>

      <Styled.Cell data-testid="columnPeriod">
        <Styled.Text>{intervalDateToString(start_date, end_date)}</Styled.Text>
      </Styled.Cell>

      <Styled.Cell data-testid="columnDifficulty">
        <Styled.Text>{EWorkoutDifficulty[difficulty]}</Styled.Text>
      </Styled.Cell>

      <Styled.Cell onClick={(e) => e.stopPropagation()}>
        <DropdownMenu items={options}>
          <IconButton size="small" iconName="moreVertical" margin="0" />
        </DropdownMenu>
      </Styled.Cell>
    </Styled.Row>
  )
}

export default Desktop
