// Models
import { IAction } from 'lib/redux/models'
import {
  EAuthActionTypes,
  IAuthState,
  IForgotPasswordPayload,
  ILoginPayload,
  INewPasswordPayload,
  IRegisterPayload,
  IResetPasswordPayload,
  IUserInvitePayload,
  TokenType,
} from '../models'

//Misc
import { clearAuthTokens } from 'lib/axios/jwt/index'

// ACTION TYPES
export const Types = {
  CLEANUP: EAuthActionTypes.CLEANUP,
  FAILURE: EAuthActionTypes.FAILURE,
  FULFILL: EAuthActionTypes.FULFILL,
  REQUEST: EAuthActionTypes.REQUEST,
  SUCCESS: EAuthActionTypes.SUCCESS,
  NEW_PASSWORD: EAuthActionTypes.NEW_PASSWORD,
  LOGOUT: EAuthActionTypes.LOGOUT,
  REGISTER_EMAIL_CONFIRMATION: EAuthActionTypes.REGISTER_EMAIL_CONFIRMATION,
  REQUEST_FORGOT_PASSWORD: EAuthActionTypes.REQUEST_FORGOT_PASSWORD,
  RESET_PASSWORD: EAuthActionTypes.RESET_PASSWORD,
  TRIGGER_REGISTER: EAuthActionTypes.TRIGGER_REGISTER,
  TRIGGER_LOGIN: EAuthActionTypes.TRIGGER_LOGIN,
  TRIGGER_USER_INVITE: EAuthActionTypes.TRIGGER_USER_INVITE,
  RESEND_CONFIRMATION_EMAIL: EAuthActionTypes.RESEND_CONFIRMATION_EMAIL,
  VALIDATE_RESET_TOKEN: EAuthActionTypes.VALIDATE_RESET_TOKEN,
  VALIDATE_FIREBASE_TOKEN: EAuthActionTypes.VALIDATE_FIREBASE_TOKEN,
}

// INITIAL STATE
const initialState: IAuthState = {}

// REDUCER
export default (
  state: IAuthState = initialState,
  action?: IAction<unknown>,
): IAuthState => {
  switch (action?.type) {
    case Types.CLEANUP:
      return {
        ...state,
        error: undefined,
        isResetTokenValid: undefined,
        loading: undefined,
        refreshing: undefined,
        resetPasswordSuccess: undefined,
        newPasswordSuccess: undefined,
        success: undefined,
        alert: undefined,
      }
    case Types.FAILURE:
      return {
        ...state,
        error: action.payload as string,
      }
    case Types.FULFILL:
      return {
        ...state,
        loading: false,
      }
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.SUCCESS:
      return {
        ...state,
        ...(action?.payload as IAuthState),
      }
    case Types.LOGOUT:
      return initialState
    default:
      return state
  }
}

// BASE ACTIONS
export const failure = (payload: string): IAction<string> => {
  return {
    type: Types.FAILURE,
    payload,
  }
}

export const fulfill = (): IAction<undefined> => {
  return {
    type: Types.FULFILL,
  }
}

export const request = (): IAction<undefined> => {
  return {
    type: Types.REQUEST,
  }
}

export const success = (payload: IAuthState): IAction<IAuthState> => {
  return {
    type: Types.SUCCESS,
    payload,
  }
}

// CUSTOM ACTIONS
export const cleanup = (): IAction<undefined> => {
  return {
    type: Types.CLEANUP,
  }
}

export const logout = (): IAction<undefined> => {
  clearAuthTokens()

  return {
    type: Types.LOGOUT,
  }
}

export const registerEmailConfirmation = (payload: string): IAction<string> => {
  return {
    type: Types.REGISTER_EMAIL_CONFIRMATION,
    payload,
  }
}

export const requestForgotPassword = (
  payload: IForgotPasswordPayload,
): IAction<IForgotPasswordPayload> => {
  return {
    type: Types.REQUEST_FORGOT_PASSWORD,
    payload,
  }
}

export const resendConfirmationEmail = (payload: string): IAction<string> => {
  return {
    type: Types.RESEND_CONFIRMATION_EMAIL,
    payload,
  }
}

export const resetPassword = (
  payload: IResetPasswordPayload,
): IAction<IResetPasswordPayload> => {
  return {
    type: Types.RESET_PASSWORD,
    payload,
  }
}

export const newPassword = (
  payload: INewPasswordPayload,
): IAction<INewPasswordPayload> => {
  return {
    type: Types.NEW_PASSWORD,
    payload,
  }
}

export const triggerRegister = (
  payload: IRegisterPayload,
): IAction<IRegisterPayload> => {
  return {
    type: Types.TRIGGER_REGISTER,
    payload,
  }
}
export const triggerLogin = (
  payload: ILoginPayload,
): IAction<ILoginPayload> => {
  return {
    type: Types.TRIGGER_LOGIN,
    payload,
  }
}

export const triggerUserInvite = (
  payload: IUserInvitePayload,
): IAction<IUserInvitePayload> => {
  return {
    type: Types.TRIGGER_USER_INVITE,
    payload,
  }
}

export const triggerLogout = (): IAction<undefined> => {
  clearAuthTokens()
  return {
    type: Types.LOGOUT,
  }
}

export const validatePasswordToken = (
  payload: TokenType,
): IAction<TokenType> => {
  return {
    type: Types.VALIDATE_RESET_TOKEN,
    payload,
  }
}

export const actions = {
  cleanup,
  failure,
  fulfill,
  logout,
  registerEmailConfirmation,
  newPassword,
  request,
  requestForgotPassword,
  resendConfirmationEmail,
  resetPassword,
  success,
  triggerLogout,
  validatePasswordToken,
}
