// Libraries
import styled from 'styled-components'

type ItemProps = {
  margin?: string
}

type PlanProps = {
  area?: string
}

export const FormContainer = styled.form`
  width: 100%;
  display: grid;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    grid-row-gap: 24px;
  }
`

export const ItemList = styled.div<ItemProps>`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ margin }) => margin || '24px'};
  width: 100%;
`

export const Label = styled.div`
  color: ${({ theme }) => theme.colors.text.subdued};
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: ${({ theme }) => theme.fontSize.copy4};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`

export const Circle = styled.div`
  background-color: ${({ theme }) => theme.colors.background.default};
  border-radius: 50%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  height: 120px;
  width: 120px;
`

export const TooltipContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: center;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.copy4};
  line-height: 16px;
`

export const TooltipTitle = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  padding-bottom: 16px;
`

export const TooltipLabel = styled.div`
  color: ${({ theme }) => theme.colors.text.subdued};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  display: flex;
  align-items: center;
`

export const LabelLegend = styled.div`
  background-color: ${({ theme, color }) => color || theme.colors.icon.default};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
`

export const PlanInfo = styled.div<PlanProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  ${({ area }) => (area ? `grid-area: ${area}` : '')};
  font-size: ${({ theme }) => theme.fontSize.copy4};
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`

export const PlanInfoTitle = styled.div`
  color: ${({ theme }) => theme.colors.text.subdued};
`

export const PlanInfoDescription = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
`

export const PlanContainer = styled.div`
  display: grid;
  grid-gap: auto;
  grid-row-gap: 32px;
  width: 100%;
  grid-template-areas: 'plan cost' 'limit storage' 'button button';
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    grid-template-areas: 'plan plan limit storage cost button';
  }
`
