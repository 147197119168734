// Models
import { IBaseState } from 'lib/redux/models'
import {
  IFinancialExtraPlan,
  IFinancialPlan,
  IGetFinancialPlanPayload,
  IFinancialSubscription,
  IGetFinancialSubscription,
  ILoadFinancialAthletesResponse,
  TPlanPeriodicity,
} from 'services/financial/@types'
import { TSubscriptionStatus } from 'storage/financialSubscription/models'

export enum EFinancialActionTypes {
  CLEANUP = 'FINANCIAL/CLEANUP',
  FAILURE = 'FINANCIAL/FAILURE',
  FULFILL = 'FINANCIAL/FULFILL',
  REQUEST = 'FINANCIAL/REQUEST',
  SUCCESS = 'FINANCIAL/SUCCESS',
  SUCCESS_LOAD_MORE = 'FINANCIAL/SUCCESS_LOAD_MORE',
  GET_ATHLETES = 'FINANCIAL/GET_ATHLETES',
  GET_METRICS = 'FINANCIAL/GET_METRICS',
  GET_MORE_ATHLETES = 'FINANCIAL/GET_MORE_ATHLETES',
  CREATE_EXTRA_PLAN = 'FINANCIAL/CREATE_EXTRA_PLAN',
  GET_PLAN = 'FINANCIAL/GET_PLAN',
  CREATE_PLAN = 'FINANCIAL/CREATE_PLAN',
  ACTIVATE_PLAN = 'FINANCIAL/ACTIVATE_PLAN',
  DEACTIVATE_PLAN = 'FINANCIAL/DEACTIVATE_PLAN',
  REMOVE_PLANS = 'FINANCIAL/REMOVE_PLANS',
  UPDATE_PLAN = 'FINANCIAL/UPDATE_EXTRA_PLAN',
  PARTIAL_UPDATE_PLAN = 'FINANCIAL/PARTIAL_UPDATE_PLAN',
  DELETE_PLAN = 'FINANCIAL/DELETE_PLAN',
  GET_EXTRA_PLAN = 'FINANCIAL/GET_EXTRA_PLAN',
  GET_EXTRA_PLANS = 'FINANCIAL/GET_EXTRA_PLANS',
  UPDATE_EXTRA_PLAN = 'FINANCIAL/UPDATE_EXTRA_PLAN',
  PARTIAL_UPDATE_EXTRA_PLAN = 'FINANCIAL/PARTIAL_UPDATE_EXTRA_PLAN',
  DELETE_EXTRA_PLAN = 'FINANCIAL/DELETE_EXTRA_PLAN',
  ADD_EXTRA_PLANS_STATE = 'FINANCIAL/ADD_EXTRA_PLANS_STATE',
  DELETE_EXTRA_PLANS_STATE = 'FINANCIAL/DELETE_EXTRA_PLANS_STATE',
  UPDATE_EXTRA_PLANS_STATE = 'FINANCIAL/UPDATE_EXTRA_PLANS_STATE',
  CREATE_SUBSCRIPTION = 'FINANCIAL/CREATE_SUBSCRIPTION',
  UPDATE_SUBSCRIPTION = 'FINANCIAL/UPDATE_SUBSCRIPTION',
  GET_SUBSCRIPTION = 'FINANCIAL/GET_SUBSCRIPTION',
}

export type TPayment = 'billet' | 'card' | 'pix'

export type TPaymentStatus = 'due_date' | 'paid' | 'pendent'

export interface IFinancialState extends IBaseState {
  athletes?: ILoadFinancialAthletesResponse
  metrics?: IFinancialMetrics
  extraPlan?: IFinancialExtraPlan<number>
  extraPlans?: Array<IFinancialExtraPlan<number>>
  plan?: IFinancialPlan
  planList?: IGetFinancialPlanPayload
  subscription?: IFinancialSubscription
  subscriptionList?: IGetFinancialSubscription
}

export interface IFinancialAthlete {
  id: number
  name: string
  plan_periodicity: TPlanPeriodicity
  payment: TPayment
  due_date: string
  payment_status: TPaymentStatus
  status: TSubscriptionStatus
  plan_value: number
}

export interface IFinancialMetrics {
  athletes: number
  athletes_percent: number
  reference_month: string
  amount_received: number
  amount_due: number
  notices: TFinancialNotices[]
}

export type TFinancialNotices = {
  id: number
  avatar?: string
  name: string
  message: string
  created_date: string
}
