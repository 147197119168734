// Libraries
import styled, { css } from 'styled-components'

enum EFontSizes {
  small = '14px',
  medium = '16px',
  large = '18px',
}

enum EPaddings {
  small = '12px 0px 12px 16px',
  medium = '15px 0px 15px 16px',
  large = '18px 0px 18px 16px',
}

enum EPaddingsWithIcon {
  small = '12px 50px 12px 16px',
  medium = '15px 50px 15px 16px',
  large = '18px 50px 18px 16px',
}

type InputProps = {
  color: string
  disabled?: boolean
  scale?: 'small' | 'medium' | 'large'
  ispassword?: 0 | 1
}

type IconWrapperProps = {
  disabled?: boolean
}

type WrapperProps = {
  borderColor: string
  borderLess?: boolean
}

const InputStyle = css<InputProps>`
  border: none;
  caret-color: #202223;
  color: ${({ theme }) => theme.colors.text.default};
  cursor: text;
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: ${({ scale }) => (scale ? EFontSizes[scale] : EFontSizes.medium)};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  width: 100%;
  outline: none;
  padding: ${({ scale, ispassword }) =>
    scale
      ? ispassword
        ? EPaddingsWithIcon[scale]
        : EPaddings[scale]
      : ispassword
      ? EPaddingsWithIcon.medium
      : EPaddings.medium};
  transition: all 0.3s ease;
  ::placeholder {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.text.disabled : theme.colors.text.subdued};
  }
  :disabled {
    cursor: not-allowed;
    background: ${({ theme }) => theme.colors.surface.disabled};
    color: ${({ theme }) => theme.colors.text.subdued};
  }
`

export const Input = styled.input<InputProps>`
  ${InputStyle};
  background-color: transparent;
`

export const IconWrapper = styled.div<IconWrapperProps>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const InputContainer = styled.div<WrapperProps>`
  border: ${({ borderLess }) => (borderLess ? '0' : '1')}px solid
    ${({ borderColor }) => borderColor};
  border-radius: 3px;
  display: flex;
  position: relative;
  transition: all 0.3s ease;
  background-color: ${({ theme }) => theme.colors.surface.default};

  &[aria-disabled='true'] {
    border: 1px solid ${({ theme }) => theme.colors.icon.disabled};
  }
`
