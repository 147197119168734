// Models
import { FieldType } from 'components/WorkoutSetFormTag'
import { IExerciseData } from 'storage/exercise/models'
import {
  IFormInputs,
  IModelInput,
  ISetToExerciseInput,
  IWorkoutSetInput,
  TFormValues,
  TSpecialSetInput,
} from 'models'

// React
import React, { useState } from 'react'

// Libs
import { useFieldArray, useForm, UseFormReturn } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { getModelName, uid } from 'utils/functions'
import { workoutModelsSchema } from 'schemas'

type WorkoutModelsFormContext = {
  addExerciseToWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    exercise: IExerciseData,
  ) => void
  addOrRemoveFieldFromWorkoutSetToExercise: (
    modelId: string,
    workoutSetId: string,
    exerciseIndex: number,
    field: FieldType,
    add?: boolean,
  ) => void
  addWorkoutModel: (model: IModelInput) => void
  addWorkoutSet: (modelId: string, newSet: IWorkoutSetInput) => void
  duplicateWorkoutModel: () => void
  methods: UseFormReturn<IFormInputs, TFormValues>
  removeExerciseFromWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    exerciseIndex: number,
  ) => void
  removeWorkoutModel: (modelId: string) => void
  removeWorkoutSet: (modelId: string, workoutSetId: string) => void
  replicateWorkoutSetFields: (
    modelId: string,
    setToCopy: IWorkoutSetInput,
  ) => void
  selectedModelIndex: number
  setSelectedModelIndex: React.Dispatch<React.SetStateAction<number>>
  setSpecialSet: React.Dispatch<
    React.SetStateAction<TSpecialSetInput | undefined>
  >
  setStateModels: React.Dispatch<React.SetStateAction<IModelInput[]>>
  specialSet: TSpecialSetInput | undefined
  stateModels: IModelInput[]
  updateWorkoutModel: (modelId: string, updatedModel: IModelInput) => void
  updateWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    updatedWorkoutSet: IWorkoutSetInput,
  ) => void
}

const useWorkoutModelsForm = (
  stateModels: IModelInput[],
  setStateModels: React.Dispatch<React.SetStateAction<IModelInput[]>>,
): WorkoutModelsFormContext => {
  const [selectedModelIndex, setSelectedModelIndex] = useState(0)
  const [specialSet, setSpecialSet] = useState<TSpecialSetInput>()

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(workoutModelsSchema),
    delayError: 800,
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { models: stateModels },
  })
  const { control, setValue, watch } = methods
  const { append, fields, remove } = useFieldArray({
    control,
    name: `models`,
  })

  const formValues = watch()

  const _returnModelAndIndex = (modelId: string) => {
    const index = stateModels.findIndex((model) => model.id === modelId)

    return {
      index,
      workoutModel: { ...stateModels[index] },
    }
  }

  const addWorkoutModel = (model: IModelInput) => {
    append(model)
    setStateModels([...stateModels, model])

    stateModels && setSelectedModelIndex(stateModels.length)
  }

  const duplicateWorkoutModel = () => {
    const { name, workout_set } = stateModels[selectedModelIndex]
    const field = fields[selectedModelIndex]
    const fieldCopy = { ...field, id: 'NEW' + uid(), name: `Cópia de ${name}` }

    append(fieldCopy)

    setStateModels([
      ...stateModels,
      { ...fieldCopy, workout_set: { ...workout_set } },
    ])

    stateModels && setSelectedModelIndex(stateModels.length)
  }

  const removeWorkoutModel = (modelId: string) => {
    const routineId = stateModels[0].workout_routine
    let updateModels = stateModels.filter((model) => model.id !== modelId)

    remove(stateModels.findIndex((model) => model.id === modelId))

    if (updateModels.length === 0) {
      updateModels = [
        {
          id: 'NEW' + uid(),
          name: getModelName(0),
          workout_set: {},
          workout_routine: routineId,
        },
      ]
      append(updateModels)
    }

    setStateModels(updateModels)
    setSelectedModelIndex(Math.max(updateModels.length - 1, 0))
  }

  const updateWorkoutModel = (modelId: string, updatedModel: IModelInput) => {
    const { index } = _returnModelAndIndex(modelId)
    const updatedModels = [...stateModels]
    updatedModels[index] = { ...updatedModel }

    setStateModels(updatedModels)
  }

  const copyWorkoutSetFields = (
    set: IWorkoutSetInput,
    setToCopy: IWorkoutSetInput,
  ) => {
    const { interval, quantity, exercises } = setToCopy

    if (setToCopy.id === set.id) return setToCopy

    return {
      ...set,
      interval,
      quantity,
      repeat: true,
      exercises: set.exercises.map((exercise) => ({
        ...exercise,
        repetitions: exercises[0].repetitions,
        cadence: exercises[0].cadence,
      })),
    }
  }

  const addWorkoutSet = (modelId: string, newSet: IWorkoutSetInput) => {
    const { index, workoutModel } = _returnModelAndIndex(modelId)

    const setsWithRepeatIsTrue = Object.values(
      formValues.models[index]?.workout_set || {},
    )?.filter((set) => set.repeat)

    const validSet = setsWithRepeatIsTrue?.length
      ? copyWorkoutSetFields(newSet, setsWithRepeatIsTrue[0])
      : newSet

    updateWorkoutModel(modelId, {
      ...workoutModel,
      workout_set: {
        ...workoutModel.workout_set,
        [newSet.id]: { ...validSet },
      },
    })
    setValue(`models.${index}.workout_set.${newSet.id}`, validSet)
  }

  const replicateWorkoutSetFields = (
    modelId: string,
    setToCopy: IWorkoutSetInput,
  ) => {
    const { index, workoutModel } = _returnModelAndIndex(modelId)

    const updatedWorkoutSets = Object.values(workoutModel.workout_set).reduce(
      (acc, set) => {
        return { ...acc, [set.id]: copyWorkoutSetFields(set, setToCopy) }
      },
      {},
    )

    updateWorkoutModel(modelId, {
      ...workoutModel,
      workout_set: updatedWorkoutSets,
    })
    setValue(`models.${index}.workout_set`, updatedWorkoutSets)
  }

  const removeWorkoutSet = (modelId: string, workoutSetId: string) => {
    const { workoutModel } = _returnModelAndIndex(modelId)
    const updatedWorkoutSet = { ...workoutModel.workout_set }
    delete updatedWorkoutSet[workoutSetId]

    updateWorkoutModel(modelId, {
      ...workoutModel,
      workout_set: updatedWorkoutSet,
    })
  }

  const updateWorkoutSet = (
    modelId: string,
    workoutSetId: string,
    updatedWorkoutSet: IWorkoutSetInput,
  ) => {
    const { workoutModel } = _returnModelAndIndex(modelId)
    const updatedWorkoutSets = Object.values(workoutModel.workout_set)
      .map((workoutSet) =>
        workoutSet.id === workoutSetId ? updatedWorkoutSet : workoutSet,
      )
      .reduce((acc, set) => ({ ...acc, [set.id]: set }), {})
    updateWorkoutModel(modelId, {
      ...workoutModel,
      workout_set: updatedWorkoutSets,
    })
  }

  const addExerciseToWorkoutSet = (
    modelId: string,
    workoutSetId: string,
    exercise: IExerciseData,
  ) => {
    const newExercise = {
      id: uid(),
      weight: '',
      repetitions: '',
      exercise,
    }

    if (specialSet) {
      const setLength = Object.values(specialSet.exercises).length
      switch (specialSet.type) {
        case 'bi_set':
          if (setLength === 2) return
          break
        case 'tri_set':
          if (setLength === 3) return
          break
        default:
          break
      }
      return setSpecialSet({
        ...specialSet,
        exercises: { ...specialSet.exercises, [newExercise.id]: newExercise },
      })
    }

    const { workoutModel } = _returnModelAndIndex(modelId)
    const workoutSetIndex = Object.values(workoutModel.workout_set).findIndex(
      (model) => model.id === workoutSetId,
    )
    const workoutSet = workoutModel.workout_set[workoutSetIndex]
    updateWorkoutSet(modelId, workoutSet.id, {
      ...workoutSet,
      exercises: [...workoutSet.exercises, newExercise],
    })
  }

  const addOrRemoveFieldFromWorkoutSetToExercise = (
    modelId: string,
    workoutSetId: string,
    exerciseIndex: number,
    field: FieldType,
    add = true,
  ) => {
    const { workoutModel } = _returnModelAndIndex(modelId)
    const workoutSet = workoutModel.workout_set[workoutSetId]

    const updatedExercises = workoutSet.exercises?.map((setTo, setToIndex) => {
      if (exerciseIndex === setToIndex) {
        if (add) {
          setTo[field] = ''
        } else {
          delete setTo[field]
        }
      }
      return setTo
    })

    updateWorkoutSet(modelId, workoutSet.id, {
      ...workoutSet,
      exercises: [...updatedExercises],
    })
  }

  const removeExerciseFromWorkoutSet = (
    modelId: string,
    workoutSetId: string,
    exerciseIndex: number,
  ) => {
    if (specialSet) {
      const specialExercises: Record<string, ISetToExerciseInput> =
        Object.values(specialSet.exercises).reduce((acc, stateSet, index) => {
          if (index !== exerciseIndex)
            return { ...acc, [stateSet.id]: stateSet }
          return acc
        }, {})
      return setSpecialSet({
        ...specialSet,
        exercises: specialExercises,
      })
    }

    const { workoutModel } = _returnModelAndIndex(modelId)
    const workoutSet = workoutModel.workout_set[workoutSetId]

    updateWorkoutSet(modelId, workoutSetId, {
      ...workoutSet,
      exercises: workoutSet.exercises.filter(
        (_exercise, index) => index !== exerciseIndex,
      ),
    })
  }

  return {
    addExerciseToWorkoutSet,
    addOrRemoveFieldFromWorkoutSetToExercise,
    addWorkoutModel,
    addWorkoutSet,
    duplicateWorkoutModel,
    methods,
    removeExerciseFromWorkoutSet,
    removeWorkoutModel,
    removeWorkoutSet,
    replicateWorkoutSetFields,
    selectedModelIndex,
    setSelectedModelIndex,
    setSpecialSet,
    setStateModels,
    specialSet,
    stateModels,
    updateWorkoutModel,
    updateWorkoutSet,
  }
}

export { useWorkoutModelsForm }
