// Models
import { useController, useFormContext } from 'react-hook-form'

// React
import React, { FC, useContext, useMemo, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Components
import * as Styled from './styled'
import InputCheckbox from '../@primitives/InputCheckbox'

type Props = {
  disabled?: boolean
  id: string
  inputValue?: string
  isCheckboxGroup?: boolean
  label?: string
  margin?: string
  name: string
  size?: 'large' | 'medium'
}

const Checkbox: FC<Props> = (props) => {
  const {
    disabled,
    id,
    inputValue,
    isCheckboxGroup = false,
    label,
    margin,
    name,
    size = 'medium',
  } = props
  const [isFocused, setIsFocused] = useState(false)

  const { control } = useFormContext()
  const {
    field: { onChange, ref, value },
  } = useController({
    name,
    control,
    defaultValue: isCheckboxGroup ? [] : false,
  })
  const theme = useContext(ThemeContext)
  const isChecked = useMemo(
    () => (Array.isArray(value) ? value.includes(inputValue) : !!value),
    [inputValue, value],
  )

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventValue = event.target.checked

    if (isCheckboxGroup) {
      const newCheckedValue = eventValue
        ? [...value, inputValue]
        : value.filter((item: string) => item !== inputValue)

      onChange(newCheckedValue)
    } else if (inputValue) {
      onChange(eventValue ? inputValue : '')
    } else {
      onChange(eventValue)
    }
  }

  return (
    <Styled.Container margin={margin}>
      <Styled.Label
        aria-disabled={disabled}
        color={theme.colors.text.secondary}
        data-testid="label"
        htmlFor={id}
        weight={theme.fontWeight.extraBold}
      >
        <InputCheckbox
          aria-checked={isChecked}
          aria-disabled={disabled}
          checked={isChecked}
          data-testid="input"
          disabled={disabled}
          isFocused={isFocused}
          id={id}
          name={name}
          onChange={handleCheckboxChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          ref={ref}
          role="checkbox"
          scale={size}
          value={inputValue}
        />
        {label}
      </Styled.Label>
    </Styled.Container>
  )
}

export default Checkbox
