// Libraries
import styled from 'styled-components'
import { theme } from 'lib/styled-components/theme'

// Components
import { Body } from 'heeds-ds'

type CalendarProps = {
  saved?: boolean
}

export const MoreContainer = styled.div`
  position: absolute;
  bottom: 2px;
  left: 8px;
  z-index: 22;
  :hover {
    display: none;
  }
`

export const ToolTip = styled.div`
  display: none;
  position: absolute;
  background-color: #ffffff;
  bottom: 90px;
  left: 0;
  padding: 16px 26px;
  z-index: 1000;
  width: 161px;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.3);
  :hover {
    display: block;
  }
`

export const AthleteNameAndTime = styled.div`
  z-index: 20;
  width: 100%;
  :hover {
    ${ToolTip} {
      display: block;
    }
  }
`

export const EllipsisText = styled(Body).attrs(() => ({
  type: 'small',
  color: theme.colors.text.secondary,
  weight: '800',
}))`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const AthleteNameCard = styled.div<CalendarProps>`
  display: flex;
  flex-direction: column;
  padding: 2px 8px;
  gap: 4px;
  width: 100%;
  background: ${({ saved, theme }) =>
    saved
      ? theme.colors.surface.selectedHovered
      : theme.colors.surface.criticalSubdued};
  border-left: 2px solid
    ${({ saved, theme }) =>
      saved ? theme.colors.text.secondary : theme.colors.text.critical};
  border-radius: 0px 4px 4px 0px;
  margin-bottom: 4px;
`

export const ToolTipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
