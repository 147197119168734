// Models
import { BodyVariationsTypes } from 'lib/styled-components/models'

// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Custom
import { Body } from '../'
import { Container, TabItem } from './styled'

type Props = {
  active?: string
  distanceOfBar?: string
  fontSize?: BodyVariationsTypes
  margin?: string
  padding?: string
  setActive: (tab: string) => void
  tabs: string[]
}

const Tabs: FC<Props> = ({
  active,
  distanceOfBar,
  fontSize,
  margin,
  padding,
  setActive,
  tabs,
}) => {
  const theme = useContext(ThemeContext)

  return (
    <Container data-testid="container" margin={margin} padding={padding}>
      {tabs.map((tab) => {
        const isSelected = tab === active
        return (
          <TabItem
            key={tab}
            active={isSelected}
            distance={distanceOfBar}
            onClick={() => setActive(tab)}
            data-testid={`tab-${tab}`}
          >
            <Body
              type={fontSize || 'copy5'}
              weight={700}
              color={
                isSelected
                  ? theme.colors.text.secondary
                  : theme.colors.text.subdued
              }
            >
              {tab}
            </Body>
          </TabItem>
        )
      })}
    </Container>
  )
}

export default Tabs
