// Models
import { EAthletesActionTypes, IAthletesState } from '../models'
import { IAction } from 'lib/redux/models'
import {
  IAthleteItem,
  IGetAthletesPayload,
  IGetAthletesResponse,
} from 'services/athletes/@types'

// ACTION TYPES
export const Types = {
  CLEANUP: EAthletesActionTypes.CLEANUP,
  FAILURE: EAthletesActionTypes.FAILURE,
  FULFILL: EAthletesActionTypes.FULFILL,
  REQUEST: EAthletesActionTypes.REQUEST,
  SUCCESS: EAthletesActionTypes.SUCCESS,
  SUCCESS_LOAD_MORE: EAthletesActionTypes.SUCCESS_LOAD_MORE,
  UPDATE_ATHLETES: EAthletesActionTypes.UPDATE_ATHLETES,
  GET_ATHLETES: EAthletesActionTypes.GET_ATHLETES,
  GET_MORE_ATHLETES: EAthletesActionTypes.GET_MORE_ATHLETES,
}

// INITIAL STATE
const initialState: IAthletesState = {}

// REDUCER
export default (
  state: IAthletesState = initialState,
  action?: IAction<unknown>,
): IAthletesState => {
  switch (action?.type) {
    case Types.CLEANUP:
      return {
        ...state,
        error: undefined,
        loading: undefined,
        refreshing: undefined,
        athletes: undefined,
      }
    case Types.FAILURE:
      return {
        ...state,
        error: action.payload as string,
      }
    case Types.FULFILL:
      return {
        ...state,
        loading: false,
      }
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.SUCCESS:
      return {
        ...state,
        ...(action?.payload as IAthletesState),
      }
    case Types.SUCCESS_LOAD_MORE: {
      const loadMorePayload = action.payload as IGetAthletesResponse
      const loadMoreAthletesFromState = state.athletes?.results || []
      const loadMoreAthletesFromPayload = loadMorePayload.results || []
      return {
        ...state,
        athletes: {
          ...loadMorePayload,
          results: [
            ...loadMoreAthletesFromState,
            ...loadMoreAthletesFromPayload,
          ],
        },
      }
    }
    case Types.UPDATE_ATHLETES: {
      const updateAthletesPayload = action?.payload as IAthleteItem
      const updateAthletesAthletesFromState = state.athletes?.results || []
      return {
        ...state,
        athletes: {
          ...state.athletes,
          results: [updateAthletesPayload, ...updateAthletesAthletesFromState],
        },
      }
    }
    default:
      return state
  }
}

// ACTIONS
export const cleanup = (): IAction<undefined> => {
  return {
    type: Types.CLEANUP,
  }
}

export const failure = (payload: string): IAction<string> => {
  return {
    type: Types.FAILURE,
    payload,
  }
}

export const fulfill = (): IAction<unknown> => {
  return {
    type: Types.FULFILL,
  }
}

export const request = (): IAction<unknown> => {
  return {
    type: Types.REQUEST,
  }
}

export const success = (payload: IAthletesState): IAction<IAthletesState> => {
  return {
    type: Types.SUCCESS,
    payload,
  }
}

export const successLoadMore = (
  payload: IGetAthletesResponse,
): IAction<IGetAthletesResponse> => {
  return {
    type: Types.SUCCESS_LOAD_MORE,
    payload,
  }
}

// CUSTOM ACTIONS
export const updateAthletes = (
  payload: IAthleteItem,
): IAction<IAthleteItem> => {
  return {
    type: Types.UPDATE_ATHLETES,
    payload,
  }
}

export const triggerLoadAthletes = (
  payload: IGetAthletesPayload,
): IAction<IGetAthletesPayload> => {
  return {
    type: Types.GET_ATHLETES,
    payload,
  }
}

export const triggerLoadMoreAthletes = (
  payload: IGetAthletesPayload,
): IAction<IGetAthletesPayload> => {
  return {
    type: Types.GET_MORE_ATHLETES,
    payload,
  }
}

export const actions = {
  cleanup,
  failure,
  fulfill,
  request,
  success,
  successLoadMore,
  triggerLoadAthletes,
  triggerLoadMoreAthletes,
  updateAthletes,
}
