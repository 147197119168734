// React
import { createContext, FC, ReactNode, useContext, useState } from 'react'

type ModalContext = {
  closeModal: () => void
  isVisible?: string
  triggerAnimation?: boolean
  openModal: (id: string) => void
  setVisible: (id: string) => void
  toggleModal: (id: string) => void
}

type Props = {
  children: ReactNode | ReactNode[]
}

const ModalContext = createContext<ModalContext | null>(null)

const useModal = (): ModalContext => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('useModal should be used inside ModalProvider')
  }
  return context
}

const ModalProvider: FC<Props> = ({ children }: Props) => {
  const [visible, setVisible] = useState<string>()
  const [triggerAnimation, setTriggerAnimation] = useState(true)

  function openModal(id: string) {
    setTriggerAnimation(true)
    setVisible(id)
  }

  function closeModal() {
    setTriggerAnimation(false)
    setTimeout(() => {
      setVisible('')
    }, 160)
  }

  function toggleModal(id: string) {
    setVisible(id === visible ? '' : id)
  }

  return (
    <ModalContext.Provider
      value={{
        closeModal,
        isVisible: visible,
        triggerAnimation,
        openModal,
        setVisible,
        toggleModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export { useModal, ModalContext, ModalProvider }
