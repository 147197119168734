// Models
import { TRoutineStatus } from 'models'

// React
import { FC } from 'react'

// Components
import * as Styled from './styled'

type Props = {
  activeStatus?: TRoutineStatus
  filters: { label: string; value: TRoutineStatus }[]
  onClick: (value: TRoutineStatus) => void
}

const TabFilter: FC<Props> = ({
  activeStatus = 'current',
  filters,
  onClick,
}) => {
  const handleClick = (index: TRoutineStatus) => {
    onClick?.(index)
  }

  return (
    <Styled.Container data-testid="tabfilter-container">
      {filters.map((filter) => {
        return (
          <Styled.Tab
            data-testid={`tab-${filter.label.replaceAll(' ', '-')}`}
            key={filter.value}
            onClick={() => handleClick(filter.value)}
          >
            <Styled.Label active={filter.value === activeStatus}>
              {filter.label}
            </Styled.Label>

            <Styled.Line active={filter.value === activeStatus} />
          </Styled.Tab>
        )
      })}
    </Styled.Container>
  )
}

export default TabFilter
