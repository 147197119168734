// Libraries
import styled from 'styled-components'

import { Body, Skeleton } from 'heeds-ds'

export const Bold = styled.span`
  font-weight: 700;
`

export const Container = styled.div`
  display: flex;
  padding: 2.4rem;
  align-items: center;
  justify-content: center;
  gap: 3.2rem;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background.default};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    min-height: calc(100vh - 92px);
    display: grid;
    grid-template-columns: minmax(25%, 300px) 1fr;
    flex-direction: row;
    align-items: initial;
    justify-content: initial;
    padding: 4rem 4.8rem 0 4rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    min-height: 100vh;
  }
`

export const Content = styled.div<{ columns?: boolean }>`
  position: relative;
  width: 100%;
  padding: 2.4rem;
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: 15px;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
`

export const ContentLoader = styled(Skeleton)`
  border-radius: 15px;
  min-height: 140px;
`

export const ContentTitle = styled(Body).attrs({ type: 'copy2', weight: 700 })`
  margin-bottom: 2.4rem;
`

export const ProfileSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  overflow: scroll hidden;
`

export const HorizontalContainer = styled.div`
  width: 100%;
  gap: 2.4rem;
  display: inline-flex;
  overflow-x: scroll;
  padding-bottom: 0.8rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    scroll-snap-type: x mandatory;
    div {
      scroll-snap-align: start;
    }
  }
`

export const Tab = styled.button`
  outline: none;
  border: none;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text.secondary};
`
