// Models
import { FieldType } from 'components/WorkoutSetFormTag'
import { IExerciseData } from 'storage/exercise/models'
import {
  IFormInputs,
  IModelInput,
  IWorkoutSetInput,
  TSetSpecialSet,
  TSpecialSetInput,
} from 'models'

// React
import {
  FC,
  Fragment,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useFormContext } from 'react-hook-form'

// Misc
import useMediaQuery from 'hooks/useMediaQuery'
import { buttonClickTracking } from 'utils/tracking'

// Components
import * as Styled from './styled'
import { Aligner, Icon } from 'heeds-ds'
import { WorkoutSetFormTag } from 'components'

type Props = {
  updateWorkoutModel: (modelId: string, updatedModel: IModelInput) => void
  addOrRemoveFieldFromWorkoutSetToExercise?: (
    modelId: string,
    workoutSetId: string,
    exerciseIndex: number,
    field: FieldType,
    add?: boolean,
  ) => void
  changePage?: (page: string) => void
  formIndex: number
  onEditSpecialSet?: (workoutSet: TSpecialSetInput) => void
  openExerciseGifModal: (exercise: IExerciseData) => void
  openExercisesModal?: () => void
  openWorkoutModelTemplateModal?: () => void
  removeExerciseFromWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    exerciseIndex: number,
  ) => void
  removeWorkoutSet: (modelId: string, workoutSetId: string) => void
  replicateWorkoutSetFields?: (modelId: string, set: IWorkoutSetInput) => void
  selectedModelIndex?: number
  setSpecialSet: TSetSpecialSet
  specialSet?: TSpecialSetInput
  stateModels?: IModelInput[]
  updateWorkoutSet?: (
    modelId: string,
    workoutSetId: string,
    updatedWorkoutSet: IWorkoutSetInput,
  ) => void
}

const WorkoutSets: FC<Props> = ({
  formIndex,
  openExerciseGifModal,
  removeExerciseFromWorkoutSet,
  removeWorkoutSet,
  replicateWorkoutSetFields,
  selectedModelIndex,
  specialSet,
  stateModels,
  openWorkoutModelTemplateModal,
  ...props
}) => {
  const { breakpoints } = useContext(ThemeContext)
  const anchorRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)
  const theme = useContext(ThemeContext)

  const newLength = useMemo(() => {
    return [
      Object.keys(stateModels?.[selectedModelIndex ?? 0]?.workout_set ?? {})
        .length,
      selectedModelIndex ?? 0,
    ]
  }, [selectedModelIndex, stateModels])

  const [oldLength, setOldLength] = useState(newLength)

  const { setValue, unregister } = useFormContext<IFormInputs>()

  const isATemplateView = location.pathname.split('/')[2] === 'biblioteca'
  const hasModels = stateModels?.length
  const selectedModelFound =
    hasModels &&
    stateModels?.filter((_, index) => index === selectedModelIndex).length > 0

  const removeSet = (setId: string) => {
    if (stateModels) {
      const modelId = stateModels[formIndex].id
      removeWorkoutSet(modelId, setId)
      unregister(`models.${formIndex}.workout_set.${setId}`)
    }
  }

  const removeExerciseFromSet = (exerciseIndex: number, setId: string) => {
    if (stateModels) {
      const modelId = stateModels[formIndex].id
      removeExerciseFromWorkoutSet(modelId, setId, exerciseIndex)
      unregister(
        `models.${formIndex}.workout_set.${
          Object.values(stateModels[formIndex].workout_set).length - 1
        }`,
      )
    }
  }

  const uncheckAllRepeats = () => {
    if (stateModels && selectedModelIndex !== undefined) {
      const model = stateModels?.[selectedModelIndex]

      const updatedSet = Object.values(model.workout_set).reduce(
        (acc, set) => ({ ...acc, [set.id]: { ...set, repeat: false } }),
        {},
      )

      setValue(`models.${selectedModelIndex}.workout_set`, updatedSet)
    }
  }

  useEffect(() => {
    stateModels?.map((stateModel, modelIndex) => {
      setValue(`models.${modelIndex}.name`, stateModel.name)

      Object.values(stateModel.workout_set)?.forEach((stateSet) => {
        if (stateSet.type !== 'normal') {
          setValue(`models.${modelIndex}.workout_set.${stateSet.id}`, {
            ...stateSet,
            quantity: stateSet.quantity || '',
            interval: { value: stateSet?.interval?.value || '' },
            exercises: stateSet.exercises.map((setToExercise) => {
              return {
                ...setToExercise,
                weight: setToExercise?.weight || '',
                repetitions: setToExercise.repetitions || '',
              }
            }),
          })
        }
      })
    })
  }, [setValue, stateModels])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!specialSet && isDesktop)
        anchorRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }, 100)

    return () => clearTimeout(timeout)
  }, [isDesktop, specialSet])

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      if (oldLength[0] < newLength[0] && oldLength[1] === newLength[1])
        anchorRef?.current?.scrollIntoView({ behavior: 'smooth' })

      setOldLength(newLength)
    }, 100)

    return () => clearTimeout(timeout)
  }, [oldLength, newLength])

  return (
    <Styled.Container data-testid="workout-sets-desktop-container">
      {!hasModels || !selectedModelFound || newLength[0] === 0 ? (
        <Aligner
          justify="center"
          padding="160px 0 0"
          flex="column"
          align="center"
          gap="32px"
        >
          {isATemplateView ? (
            <Styled.EmptySet>
              {isDesktop
                ? 'Você agora pode escolher os exercícios, filtrar os métodos e adicionar quantos treinos quiser.'
                : 'Volte para a tela anterior e continue a escolher os exercícios.'}
            </Styled.EmptySet>
          ) : (
            <Fragment>
              <Styled.EmptySet>
                Você agora pode escolher os exercícios, filtrar os métodos e
                adicionar quantos treinos quiser.
              </Styled.EmptySet>
              <Styled.EmptySet>
                Também é possível selecionar um treino já criado diretamente da
                biblioteca.
              </Styled.EmptySet>
              <Styled.AddButton
                onClick={openWorkoutModelTemplateModal}
                ref={buttonRef}
                track={buttonClickTracking}
                trackName="open_add-new-model-moda"
              >
                <Icon iconName="add" color={theme.colors.icon.onPrimary} />
                Adicionar treino da biblioteca
              </Styled.AddButton>
            </Fragment>
          )}
        </Aligner>
      ) : (
        hasModels &&
        stateModels?.map((stateModel, modelIndex) => {
          return Object.values(stateModel.workout_set)?.map((stateSet) => {
            const prefixName = `models.${modelIndex}.workout_set.${stateSet.id}`

            const onRemove = () => removeSet(stateSet.id)

            const removeExercise = (exerciseIndex: number) =>
              removeExerciseFromSet(exerciseIndex, stateSet.id)

            const replicateField = (set: IWorkoutSetInput) =>
              replicateWorkoutSetFields?.(stateModel.id, set)

            return (
              <WorkoutSetFormTag
                {...props}
                displayMode
                key={stateSet.id}
                modelId={stateModel.id}
                modelIndex={modelIndex}
                name={prefixName}
                onClickImage={openExerciseGifModal}
                onRemove={onRemove}
                removeExerciseFromWorkoutSet={removeExercise}
                replicateWorkoutSetFields={replicateField}
                showBorder
                specialSet={specialSet}
                uncheckAllRepeats={uncheckAllRepeats}
                visible={modelIndex === selectedModelIndex}
                workoutSet={stateSet}
              />
            )
          })
        })
      )}

      <Aligner ref={anchorRef} />
    </Styled.Container>
  )
}

export default WorkoutSets
