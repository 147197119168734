// React
import { FC, useContext } from 'react'

// Components
import { Aligner, DatePicker, Select } from 'heeds-ds'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { useMediaQuery } from 'hooks'
import { useFormContext } from 'react-hook-form'

enum EPeriodicity {
  default_monthly = 1,
  default_quarterly = 3,
  default_semiannual = 6,
  default_yearly = 12,
  custom_monthly = 1,
  custom_quarterly = 3,
  custom_semiannual = 6,
  custom_yearly = 12,
}

const PlanDates: FC = () => {
  const { watch } = useFormContext()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)
  const plan = watch('selected_plan')
  const autoRenovation = watch('autoRenovation')

  const hourlyRate =
    watch('plan_type') === 'hourly_rate' && watch('plan_model') === 'in_person'
  const periodicity = plan?.label.replace(/\d+/, '')

  const dayOptions = Array.from({ length: 31 }, (_, index) => ({
    label: (index + 1).toString().padStart(2, '0'),
    value: (index + 1).toString().padStart(2, '0'),
  }))

  const instalmentOptions = [
    { label: 'Preço à vista (sem parcelamento)', value: '0' },
    {
      label: `Parcelamento em ${EPeriodicity[periodicity]}x`,
      value: `${EPeriodicity[periodicity]}`,
    },
  ]

  const monthlyQuantity = () => {
    return Array.from({ length: 28 }, (_, i) => ({
      label: (i + 1).toString(),
      value: (i + 1).toString(),
    }))
  }

  const spread =
    watch('instalmentPayment') === undefined ||
    watch('instalmentPayment') === '0'
      ? false
      : true

  const handleDisplayDueDateDateFormat = () => {
    if (plan?.label === 'hourly_rate' && !autoRenovation) {
      return true
    }
    if (plan?.label !== 'hourly_rate' && !spread) {
      return true
    }
    if (plan?.periodicity === 'monthly' && plan?.label !== 'hourly_rate') {
      return true
    }
    return false
  }

  const displayInstalmentPayment = !(
    plan?.periodicity === undefined ||
    plan?.periodicity === 'monthly' ||
    hourlyRate
  )

  return (
    <Aligner flex="column" width={isDesktop ? '570px' : '100%'}>
      {hourlyRate && (
        <Select
          margin="0 0 16px 0"
          label="Selecione a quantidade de aulas por mês"
          placeholder="5, 10 aulas..."
          name={'frequency'}
          options={monthlyQuantity()}
          scale="small"
        />
      )}
      <DatePicker
        label="Data de início do plano"
        margin="0 0 16px 0"
        name="start_date"
        placeholder="00/00/0000"
        scale="small"
      />
      {displayInstalmentPayment && (
        <Select
          margin="0 0 16px 0"
          label="Parcelamento do plano"
          placeholder="Selecione quantas parcelas"
          name={'instalmentPayment'}
          options={instalmentOptions}
          scale="small"
        />
      )}
      {handleDisplayDueDateDateFormat() ? (
        <DatePicker
          label="Data do vencimento do pagamento"
          margin="0 0 16px 0"
          name="due_date_date"
          placeholder="Selecione o dia"
          scale="small"
        />
      ) : (
        <Select
          label="Dia do pagamento"
          margin="0 0 16px 0"
          placeholder="Selecione o dia"
          name={'due_date_day'}
          options={dayOptions}
          scale="small"
          width="100%"
        />
      )}
    </Aligner>
  )
}

export default PlanDates
