import styled from 'styled-components'

export const LogoFull = styled.img.attrs({
  draggable: false,
})`
  width: 140px;
  object-fit: contain;
`

export const LogoMin = styled.img.attrs({
  draggable: false,
})`
  width: 5rem;
  object-fit: contain;
`

export const FeedbackImage = styled.img`
  width: 5.6rem;
  height: 4.5rem;
  object-fit: contain;
  @media (min-width: ${({ theme }) => theme.breakpoints.desktopSmall}px) {
    width: 9.1rem;
    height: 7.35rem;
  }
`
