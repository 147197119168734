// Libs
import { theme } from 'lib/styled-components/theme'
import styled from 'styled-components'

type Props = {
  background: keyof typeof theme.colors.badge
  margin?: string
}

export const Container = styled.div<Props>`
  width: min-content;
  padding: 8px 16px;
  margin: ${({ margin }) => margin};
  background: ${({ theme, background }) => theme.colors.badge[background]};
  border-radius: 16px;
  font-family: ${({ theme }) => theme.fontFamily.roboto};
`
