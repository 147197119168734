// Models
import { TButtonEventTracking, TEventTracking } from './@types'

// Libraries
import Amplitude from 'amplitude-js'
import ReactGA from 'react-ga4'

// LIBRARIES TRACK EVENT
const googleAnalyticsTrack: TEventTracking = (name, data) => {
  if (!process.env.REACT_APP_FIREBASE_MEASUREMENT_ID) return

  ReactGA.event(name, data)
}

const amplitudeTrack: TEventTracking = (name, data) => {
  if (!process.env.REACT_APP_AMPLITUDE_API_KEY) return

  Amplitude.getInstance().logEvent(name, data)
}

const LIBRARIES: Record<string, TEventTracking> = {
  GA4: googleAnalyticsTrack,
  amplitude: amplitudeTrack,
}

// TRACK EVENT
export const trackEvent: TEventTracking = (name, data) => {
  Object.values(LIBRARIES).forEach((library) => library(name, data))
}

// CUSTOM EVENTS
export const buttonClickTracking: TButtonEventTracking = ({
  action,
  eventName,
  location,
}) => {
  const [pathname, search] = location.split('?')
  const params = search?.match(/\w+-\w+-\w+=|\w+-\w+=|\w+=/g)?.join('')

  trackEvent(eventName, {
    action,
    location: `${pathname.split(/\d+/).join('id')}${
      params ? '?' + params : ''
    }`,
  })
}
