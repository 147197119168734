// Models
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'

// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { intervalDateToString } from 'utils/helpers/date'
import { prepareWorkoutRoutineToDisplay } from 'filters/workouts'

// Components
import * as Styled from './styled'
import { Aligner, Swipe } from 'heeds-ds'

export type Props = {
  onClick: (routineId: number) => void
  openOptions: () => void
  routine: IWorkoutRoutine
}

const Mobile: FC<Props> = ({ onClick, openOptions, routine }) => {
  const theme = useContext(ThemeContext)

  const { goal, name, difficulty } = prepareWorkoutRoutineToDisplay(routine)
  const { start_date, end_date } = routine

  const handleClick = () => onClick(routine.id)

  return (
    <Swipe
      actions={[
        {
          icon: 'moreHorizontal',
          color: theme.colors.interactive.hovered,
          onClick: openOptions,
        },
      ]}
      active
    >
      <Styled.Container data-testid="routine-item" onClick={handleClick}>
        <Aligner flex="column" gap="0.8rem" justify="center">
          {name && <Styled.Title>{name}</Styled.Title>}

          {(start_date || end_date) && (
            <Styled.Text>
              {intervalDateToString(start_date, end_date)}
            </Styled.Text>
          )}

          {goal && <Styled.Text>{goal}</Styled.Text>}

          {difficulty && <Styled.Text>{difficulty}</Styled.Text>}
        </Aligner>
      </Styled.Container>
    </Swipe>
  )
}

export default Mobile
