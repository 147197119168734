// Libraries
import styled from 'styled-components'

// Components
import { Aligner, Body } from 'heeds-ds'

export const Container = styled(Aligner).attrs({
  flex: 'column',
})`
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: 1.5rem;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  gap: 4rem;
  height: fit-content;
  padding: 2.4rem;
  width: ${({ width }) => width || '100%'};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 33.8rem;
    padding: 2.4rem 2.4rem 2.4rem 1.6rem;
  }
`

export const FolderImage = styled.img`
  height: 4.2rem;
  width: 4.2rem;
`

export const FilesText = styled(Body).attrs(({ theme }) => ({
  align: 'right',
  color: theme.colors.text.subdued,
  type: 'copy3',
  weight: 600,
}))``

export const DateText = styled(Body).attrs(({ theme }) => ({
  color: theme.colors.text.subdued,
  type: 'copy5',
}))``

export const TitleText = styled(Body).attrs({
  type: 'copy2',
  weight: 700,
})``
