// Libraries
import { SagaIterator } from 'redux-saga'
import { all, fork } from 'redux-saga/effects'

// Misc
import assessmentAnamneseAnswerSagas from 'storage/assessmentAnamneseAnswer/saga'
import assessmentAnamneseTemplateSagas from 'storage/assessmentAnamneseTemplate/saga'
import assessmentPhysicalAssessmentSagas from 'storage/assessmentPhysicalAssessment/saga'
import athleteInfoSagas from 'storage/athleteInfo/saga'
import athleteOverviewSagas from 'storage/athleteOverview/saga'
import athletePlansSagas from 'storage/athletePlans/saga'
import athleteScheduleSagas from 'storage/athleteSchedule/saga'
import athletesSagas from 'storage/athletes/saga'
import authSagas from 'storage/auth/saga'
import exerciseSagas from 'storage/exercise/saga'
import financialSagas from 'storage/financial/saga'
import financialSubscriptionSagas from 'storage/financialSubscription/saga'
import profileSagas from 'storage/profile/saga'
import workoutModelSagas from 'storage/workoutModel/saga'
import workoutRoutineSagas from 'storage/workoutRoutine/saga'

export default function* rootSaga(): SagaIterator {
  yield all([
    fork(assessmentAnamneseAnswerSagas),
    fork(assessmentAnamneseTemplateSagas),
    fork(assessmentPhysicalAssessmentSagas),
    fork(athleteInfoSagas),
    fork(athleteOverviewSagas),
    fork(athletePlansSagas),
    fork(athleteScheduleSagas),
    fork(athletesSagas),
    fork(authSagas),
    fork(exerciseSagas),
    fork(financialSagas),
    fork(financialSubscriptionSagas),
    fork(profileSagas),
    fork(workoutModelSagas),
    fork(workoutRoutineSagas),
  ])
}
