// Libraries
import styled from 'styled-components'

type ContainerProps = {
  backgroundImage?: string
}

export const Alert = styled.img`
  height: 42px;
  width: 47px;
`

export const ContainerLogo = styled.div`
  display: flex;
  justify-content: center;
  z-index: 1;
`

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 3.6rem - 20px);
  justify-content: space-between;
  width: 100%;
  ::before {
    content: '';
    background-color: ${({ theme }) => theme.colors.background.default};
    background-image: url(${({ backgroundImage }) => backgroundImage || ''});
    background-position: top 100px center;
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    opacity: 0.34;
    bottom: 0px;
    top: 70px;
    right: 0px;
    left: 0px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    justify-content: flex-start;
    max-width: 1366px;
    margin: 0 auto;
    min-height: 768px;
    padding: 40px 8%;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.background.default};
    background-image: url(${({ backgroundImage }) => backgroundImage || ''});
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 46%;
    flex-direction: row;
    ::before {
      display: none;
      top: 0px;
    }
    & ${ContainerLogo} {
      display: none;
    }
  }
`

export const Content = styled.div`
  align-items: center;
  border-radius: 2.4rem 2.4rem 0 0;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 524px;
  padding: 6.4rem 7%;
  justify-content: start;
  width: 100%;
  z-index: 1;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    background-color: ${({ theme }) => theme.colors.background.default};
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: 100%;
    justify-content: center;
    max-width: 57rem;
    padding: 4rem 3%;
    position: relative;
    width: 55%;
    & ${Alert} {
      display: none;
    }
  }
`

export const Error = styled.img`
  height: 372px;
  width: 500px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    height: 298px;
    width: 400px;
  }
`
export const Logo = styled.img`
  height: 80px;
  width: 80px;
  z-index: 1;
`
