// Libraries
import styled from 'styled-components'

// Components
import { Body } from 'heeds-ds'

export const AddField = styled(Body).attrs(({ theme }) => ({
  color: theme.colors.interactive.default,
  type: 'copy2',
  weight: 500,
}))`
  cursor: pointer;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: ${({ theme }) => theme.fontSize.copy4};
  }
`

export const SelectionIndicator = styled.div`
  border-radius: 50%;
  min-height: 1.6rem;
  min-width: 1.6rem;
  outline: 1px solid ${({ theme }) => theme.colors.icon.default};
`
