// Models
import { IAuthState } from 'storage/auth/models'
import IStore from 'lib/redux/models'

// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { generatePath, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { urls } from 'routes/paths'
import { useMediaQuery, useModal } from 'hooks'

// Components
import * as Styled from './styled'
import { Aligner, Tooltip } from 'heeds-ds'
import { ModalInviteAthlete } from 'components'

// Constants
const TOOLTIP_TEXT =
  'Envie o link de cadastro para o seu aluno preencher os dados. Você receberá uma notificação quando o cadastro for concluído.'

const EmptyState: FC = () => {
  const { userData } = useSelector<IStore, IAuthState>(({ auth }) => auth)
  const { openModal } = useModal()
  const navigate = useNavigate()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const navigateToAthleteCreation = () =>
    navigate(generatePath(urls.athleteGeneral, { id: 'cadastro' }))

  return (
    <Styled.Container>
      {isDesktop && (
        <Styled.Header>
          <Aligner align="center">
            <Styled.Name>Olá, </Styled.Name>
            <Styled.Name weight={700}>{userData?.name}</Styled.Name>
          </Aligner>
        </Styled.Header>
      )}

      <Styled.Content>
        <Styled.Text>
          Você ainda não cadastrou nenhum aluno ao seu perfil.
        </Styled.Text>

        <Styled.ButtonGroup>
          <Styled.CommonButton
            onClick={navigateToAthleteCreation}
            track={buttonClickTracking}
            trackName="navigate_to_create_athlete"
          >
            <Styled.AddIcon />
            Cadastrar aluno
          </Styled.CommonButton>

          <Tooltip content={TOOLTIP_TEXT} side="top">
            <Styled.CommonButton
              onClick={() => openModal('invite-athlete-modal')}
              track={buttonClickTracking}
              trackName="open_modal_to_send_invite"
              variation="borderless"
            >
              <Styled.ShareIcon />
              Enviar convite
            </Styled.CommonButton>
          </Tooltip>
        </Styled.ButtonGroup>
      </Styled.Content>

      <ModalInviteAthlete />
    </Styled.Container>
  )
}

export default EmptyState
