// Components
import * as Styled from './styled'
import { Skeleton } from 'heeds-ds'

const ProfileCardLoader = () => {
  return (
    <Styled.Container data-testid="loader">
      <Styled.AvatarSkeleton />
      <Skeleton height="26px" />
      <Styled.Informations>
        <Skeleton height="36px" />
        <Skeleton height="36px" />
        <Skeleton height="36px" />
        <Skeleton height="36px" />
        <Skeleton height="36px" />
        <Skeleton height="36px" />
        <Skeleton height="36px" />
      </Styled.Informations>
    </Styled.Container>
  )
}

export default ProfileCardLoader
