import styled, { keyframes } from 'styled-components'

type SpinnerProps = {
  color?: string
  size?: number
  thickness?: number
}

export const SpinnerAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Spinner = styled.div<SpinnerProps>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border: ${({ thickness }) => thickness}px solid
    ${({ theme, color }) => color || theme.colors.background.default};
  border-top: ${({ thickness }) => thickness}px solid transparent;
  border-radius: 50%;
  animation: ${SpinnerAnimation} 0.8s linear infinite;
`

export const SpinnerContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  margin: 2px 24px;
`
