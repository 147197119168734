// React
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react'

// Libraries
import * as Popover from '@radix-ui/react-popover'
import { ThemeContext } from 'styled-components'
import { useController, useFormContext } from 'react-hook-form'
import MaskedInput, { conformToMask } from 'react-text-mask'

// Misc
import { formatToDateObject } from '../../../utils/helpers/date'
import { maskCollection } from '../../../utils/mask'

// Components
import * as Styled from './styled'
import { Calendar, Icon, InputWrapper } from '../..'

enum EIconSizes {
  small = 20,
  medium = 21.6,
  large = 25,
}

enum EFieldStates {
  default = 'default',
  error = 'critical',
  focus = 'focused',
  success = 'success',
}

type Props = {
  disabled?: boolean
  displayError?: boolean
  label?: string
  margin?: string
  name: string
  placeholder?: string
  mandatory?: boolean
  scale?: 'small' | 'medium' | 'large'
  width?: string
}

// TODO: fazer testes
const DatePicker: FC<Props> = (props) => {
  const {
    disabled,
    displayError = true,
    name,
    placeholder,
    scale = 'medium',
    ...restWrapperProps
  } = props
  const inputRef = useRef<MaskedInput>(null)
  const divRef = useRef<HTMLDivElement>(null)
  const theme = useContext(ThemeContext)

  const [fieldState, setFieldState] = useState(EFieldStates.default)
  const [focused, setFocused] = useState(false)

  const { control } = useFormContext()
  const {
    field: { onBlur, onChange, value },
    fieldState: { isDirty, error },
  } = useController({ control, name, defaultValue: '' })

  const maskProps = maskCollection.date
  const maskedValue = useMemo(
    () =>
      maskProps.mask && value
        ? conformToMask(value, maskProps.mask).conformedValue
        : value,
    [maskProps.mask, value],
  )

  const COLORS_SCHEMA = {
    critical: theme.colors.text.critical,
    default: theme.colors.border.input,
    focused: theme.colors.text.default,
    success: theme.colors.text.success,
  }

  const handleBlur = () => {
    onBlur()
    setFocused(false)
  }

  const handleFocus = () => {
    setFocused(true)
    setFieldState(EFieldStates.focus)
  }

  useEffect(() => {
    if (error) {
      inputRef.current?.inputElement.focus()
    }
  }, [error])

  useEffect(() => {
    switch (true) {
      case !!error:
        setFieldState(EFieldStates.error)
        break
      case focused:
        setFieldState(EFieldStates.focus)
        break
      case isDirty:
        setFieldState(EFieldStates.success)
        break
      default:
        setFieldState(EFieldStates.default)
        break
    }
  }, [disabled, error, focused, isDirty])

  return (
    <Popover.Root>
      <InputWrapper
        {...restWrapperProps}
        color={COLORS_SCHEMA[fieldState]}
        disabled={disabled}
        displayError={displayError}
        error={error?.message}
        name={name}
        size={scale}
      >
        <Popover.Anchor asChild ref={divRef}>
          <Styled.InputContainer
            aria-disabled={disabled}
            borderColor={COLORS_SCHEMA[fieldState]}
          >
            <MaskedInput
              {...maskProps}
              disabled={disabled}
              id={name}
              ref={inputRef}
              name={name}
              onBlur={handleBlur}
              onChange={onChange}
              onFocus={handleFocus}
              placeholder={placeholder}
              value={maskedValue}
              render={(ref, props) => (
                <Styled.Input
                  {...props}
                  data-testid="input"
                  inputMode="numeric"
                  ref={ref as (instance: HTMLInputElement | null) => void}
                  color={COLORS_SCHEMA[fieldState]}
                  scale={scale}
                />
              )}
            />
            <Styled.IconWrapper data-testid="icon" disabled={disabled}>
              <Icon
                iconName="calendar"
                color={
                  disabled
                    ? theme.colors.icon.disabled
                    : theme.colors.icon.default
                }
                size={EIconSizes[scale]}
              />
            </Styled.IconWrapper>
          </Styled.InputContainer>
        </Popover.Anchor>
      </InputWrapper>
      <Popover.Portal container={divRef.current}>
        <Styled.PopoverContent forceMount align="end" sideOffset={3}>
          <Calendar
            inputDate={formatToDateObject(maskedValue)}
            setInputDate={(date) =>
              onChange(date?.toLocaleString('pt-br', { dateStyle: 'short' }))
            }
          />
        </Styled.PopoverContent>
      </Popover.Portal>
    </Popover.Root>
  )
}

export default DatePicker
