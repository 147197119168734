// Models
import { EDaysName } from 'utils/functions'
import { IAppointmentTime, IProfileState } from 'storage/profile/models'
import { TWeekdays } from 'models'
import IStore from 'lib/redux/models'

//React
import { FC, useContext, useEffect, useLayoutEffect } from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import {
  getPersonalAppointmentTimes,
  createOrUpdatePersonalAppointmentTimes,
} from 'storage/profile/duck'
import {
  prepareAppointmentTimesToDisplay,
  prepareAppointmentTimesToPayload,
} from 'filters/profile'
import { profileScheduleSchema } from 'schemas'
import { urls } from 'routes/paths'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import * as Styled from './styled'
import { Body } from 'components/UI/Typography'
import {
  FooterActionButton,
  InputTimeRange,
  NewContentBox,
  ScheduleDayTag,
} from 'components'
import { Switch, ToggleGroup } from 'heeds-ds'

// Consts
import { WEEKDAYS } from 'utils/calendarUtils'

const OPTIONS = [
  { label: 'Segunda', value: 'mon' },
  { label: 'Terça', value: 'tue' },
  { label: 'Quarta', value: 'wed' },
  { label: 'Quinta', value: 'thu' },
  { label: 'Sexta', value: 'fri' },
  { label: 'Sábado', value: 'sat' },
  { label: 'Domingo', value: 'sun' },
]

const DEFAULT_FORM_VALUES: IFormFields = {
  toggle: true,
  allDays: {
    day: ['mon', 'tue', 'wed', 'thu', 'fri'],
    start: '',
    end: '',
  },
}

const SCHEDULE_DESCRIPTION =
  'Nesse passo, você vai selecionar os dias que você trabalha e em seguida o horário que começa os seus agendamentos do dia.'

export interface IFormFields {
  sun?: IAppointmentTime
  mon?: IAppointmentTime
  tue?: IAppointmentTime
  wed?: IAppointmentTime
  thu?: IAppointmentTime
  fri?: IAppointmentTime
  sat?: IAppointmentTime
  allDays: Omit<IAppointmentTime, 'day'> & { day?: TWeekdays[] }
  toggle?: boolean
}

const ProfileSchedule: FC = () => {
  const { appointment_times } = useSelector<IStore, IProfileState>(
    ({ profile }) => profile,
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const formMethods = useForm<IFormFields>({
    delayError: 800,
    mode: 'onChange',
    resolver: yupResolver(profileScheduleSchema),
    reValidateMode: 'onChange',
    defaultValues: DEFAULT_FORM_VALUES,
  })
  const { handleSubmit, reset, watch } = formMethods

  const toggleActive = watch('toggle')

  const onSubmit = (data: IFormFields) => {
    const payload: IAppointmentTime[] = prepareAppointmentTimesToPayload(data)

    dispatch(createOrUpdatePersonalAppointmentTimes(payload))
    navigate(urls.profilePlans)
  }

  useEffect(() => {
    if (toggleActive && !appointment_times?.length) reset(DEFAULT_FORM_VALUES)
  }, [appointment_times?.length, reset, toggleActive])

  useEffect(() => {
    reset(
      appointment_times?.length
        ? prepareAppointmentTimesToDisplay(appointment_times)
        : DEFAULT_FORM_VALUES,
    )
  }, [appointment_times, reset])

  useLayoutEffect(() => {
    dispatch(getPersonalAppointmentTimes())
  }, [dispatch])

  return (
    <Styled.Container>
      <FormProvider {...formMethods}>
        <Styled.FormContainer onSubmit={handleSubmit(onSubmit)}>
          <NewContentBox
            title="Horários de Trabalho"
            description={SCHEDULE_DESCRIPTION}
          >
            <Styled.FormInner>
              <Styled.Content active={toggleActive}>
                <Styled.ContainerInputTime active={toggleActive}>
                  <Styled.AlignMobile>
                    <Switch name="toggle" />

                    <Body type={isDesktop ? 'copy3' : 'copy2'} whiteSpace>
                      Mesmo horário todos os dias
                    </Body>
                  </Styled.AlignMobile>

                  {toggleActive && (
                    <InputTimeRange
                      nameStart="allDays.start"
                      nameEnd="allDays.end"
                      placeholderStart={`Horário de início`}
                      placeholderEnd={`Horário de Término`}
                    />
                  )}
                </Styled.ContainerInputTime>

                <Styled.Align active={toggleActive}>
                  {toggleActive ? (
                    <ToggleGroup
                      alignment={!isDesktop ? 'column' : undefined}
                      gap={!isDesktop ? '2.4rem' : '0.8rem'}
                      margin={!isDesktop ? '0' : '0.4rem 0 0'}
                      name={'allDays.day'}
                      options={OPTIONS}
                      optionSize={!isDesktop ? '100%' : '8.8rem'}
                      type="multiple"
                    />
                  ) : (
                    WEEKDAYS.map((day) => {
                      return (
                        <ScheduleDayTag
                          key={day}
                          label={EDaysName[day]}
                          name={day}
                        />
                      )
                    })
                  )}
                </Styled.Align>
              </Styled.Content>
            </Styled.FormInner>
          </NewContentBox>

          <FooterActionButton primaryTitleButton="Salvar" />
        </Styled.FormContainer>
      </FormProvider>
    </Styled.Container>
  )
}

export default ProfileSchedule
