// Libraries
import styled from 'styled-components'

export const InfoContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 1.2rem;
  justify-content: left;
  padding-left: 1.6rem;
  width: 80%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: flex;
    padding: 0rem;
    flex-direction: column;
    justify-content: center;
    gap: 0.8rem;
  }
`
