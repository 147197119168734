// Models
import {
  IWorkoutRoutinesPayload,
  IWorkoutRoutinesResponse,
  IWorkoutCreateRoutinePayload,
  IWorkoutDeleteRoutinePayload,
  IWorkoutGetRoutinePayload,
  IWorkoutLatestWorkoutPayload,
  IWorkoutPartialUpdateRoutinePayload,
  IWorkoutUpdateRoutinePayload,
} from './@types'
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'

// Misc
import { objectToQueryString } from 'lib/query-string'
import api from 'lib/axios/api'

export const loadWorkoutRoutines = async (
  formData: IWorkoutRoutinesPayload,
): Promise<IWorkoutRoutinesResponse> => {
  try {
    const query = formData
    const { data }: { data: IWorkoutRoutinesResponse } = await api.get(
      `workouts/routine/`,
      {
        params: query,
        paramsSerializer: function (params) {
          return objectToQueryString(params)
        },
      },
    )

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const createWorkoutRoutine = async (
  formData: IWorkoutCreateRoutinePayload,
): Promise<IWorkoutRoutine> => {
  try {
    const { data }: { data: IWorkoutRoutine } = await api.post(
      `workouts/routine/`,
      formData,
    )

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const getWorkoutRoutine = async (
  formData: IWorkoutGetRoutinePayload,
): Promise<IWorkoutRoutine> => {
  const { routine_pk } = formData
  try {
    const { data }: { data: IWorkoutRoutine } = await api.get(
      `workouts/routine/${routine_pk}/`,
    )

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const updateWorkoutRoutine = async (
  formData: IWorkoutUpdateRoutinePayload,
): Promise<IWorkoutRoutine> => {
  const { routine_pk, ...restForm } = formData
  try {
    const { data }: { data: IWorkoutRoutine } = await api.put(
      `workouts/routine/${routine_pk}/`,
      restForm,
    )

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const partialUpdateWorkoutRoutine = async (
  formData: IWorkoutPartialUpdateRoutinePayload,
): Promise<IWorkoutRoutine> => {
  const { routine_pk, ...restForm } = formData
  try {
    const { data }: { data: IWorkoutRoutine } = await api.patch(
      `workouts/routine/${routine_pk}/`,
      restForm,
    )

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const deleteWorkoutRoutine = async (
  formData: IWorkoutDeleteRoutinePayload,
): Promise<IWorkoutRoutine> => {
  const { routine_pk } = formData
  try {
    const { data }: { data: IWorkoutRoutine } = await api.delete(
      `workouts/routine/${routine_pk}/`,
    )

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const getLatestWorkoutRoutine = async (
  formData: IWorkoutLatestWorkoutPayload,
): Promise<IWorkoutRoutine> => {
  const { athlete_pk } = formData
  try {
    const { data }: { data: IWorkoutRoutine } = await api.get(
      `workouts/latest-workout/${athlete_pk}/`,
    )

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}
