import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  gap: 1.6rem;
  grid-template-columns: 1fr;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    gap: 2.4rem;
    grid-template-columns: 29.8rem minmax(44rem, 1fr) 1fr;
  }
`
