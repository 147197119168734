// Models
import { IAction } from 'lib/redux/models'
import {
  EAthleteInfoActionTypes,
  IAthletePayload,
  IAthleteInfoState,
  IGetAthletePayload,
} from '../models'

// ACTION TYPES
export const Types = {
  CLEANUP: EAthleteInfoActionTypes.CLEANUP,
  FAILURE: EAthleteInfoActionTypes.FAILURE,
  FULFILL: EAthleteInfoActionTypes.FULFILL,
  REQUEST: EAthleteInfoActionTypes.REQUEST,
  SUCCESS: EAthleteInfoActionTypes.SUCCESS,
  CREATE_ATHLETE: EAthleteInfoActionTypes.CREATE_ATHLETE,
  GET_ATHLETE: EAthleteInfoActionTypes.GET_ATHLETE,
  UPDATE_ATHLETE: EAthleteInfoActionTypes.UPDATE_ATHLETE,
}

// INITIAL STATE
const initialState: IAthleteInfoState = {}

// REDUCER
export default (
  state: IAthleteInfoState = initialState,
  action?: IAction<unknown>,
): IAthleteInfoState => {
  switch (action?.type) {
    case Types.CLEANUP:
      return {
        ...state,
        error: undefined,
        loading: undefined,
        refreshing: undefined,
        info: undefined,
      }
    case Types.FAILURE:
      return {
        ...state,
        error: action.payload as string,
      }
    case Types.FULFILL:
      return {
        ...state,
        loading: false,
      }
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.SUCCESS:
      return {
        ...state,
        error: undefined,
        ...(action?.payload as IAthleteInfoState),
      }
    default:
      return state
  }
}

// ACTIONS
export const cleanup = (): IAction<undefined> => {
  return {
    type: Types.CLEANUP,
  }
}

export const failure = (payload: string): IAction<string> => {
  return {
    type: Types.FAILURE,
    payload,
  }
}

export const fulfill = (): IAction<unknown> => {
  return {
    type: Types.FULFILL,
  }
}

export const request = (): IAction<unknown> => {
  return {
    type: Types.REQUEST,
  }
}

export const success = (
  payload: IAthleteInfoState,
): IAction<IAthleteInfoState> => {
  return {
    type: Types.SUCCESS,
    payload,
  }
}

// CUSTOM ACTIONS
export const triggerCreateAthlete = (
  payload: IAthletePayload,
): IAction<IAthletePayload> => {
  return {
    type: Types.CREATE_ATHLETE,
    payload,
  }
}

export const triggerLoadAthlete = (
  payload: IGetAthletePayload,
): IAction<IGetAthletePayload> => {
  return {
    type: Types.GET_ATHLETE,
    payload,
  }
}

export const triggerUpdateAthlete = (
  payload: IAthletePayload,
): IAction<IAthletePayload> => {
  return {
    type: Types.UPDATE_ATHLETE,
    payload,
  }
}

export const actions = {
  cleanup,
  failure,
  fulfill,
  request,
  success,
  triggerCreateAthlete,
  triggerLoadAthlete,
  triggerUpdateAthlete,
}
