// Libraries
import styled from 'styled-components'

export const ButtonContainer = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: flex;
    gap: 3.2rem;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  background-color: #fafafa;
  flex-grow: 1;
  overflow-y: auto;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0 4rem;
  }
`

export const Content = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 2.4rem 3.2rem 0;
    border-radius: 15px 15px 0;
    background-color: white;
    box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
  }
`
