import { Aligner } from 'heeds-ds'
import styled from 'styled-components'

export const ScrollWorkoutSets = styled(Aligner).attrs(() => ({
  flex: 'column',
  gap: '16px',
}))`
  min-height: 471px;
  max-height: 50vh;
  overflow-y: scroll;
`
