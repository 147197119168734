/* eslint-disable @typescript-eslint/no-explicit-any */
// React
import { useState } from 'react'

const useDebounceFunction = <F extends (...args: any) => any>(
  fn: F,
  delay?: number,
) => {
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>()

  const debounced = (...args: any) => {
    clearTimeout(timeoutId)
    const id = setTimeout(() => fn(...args), delay)
    setTimeoutId(id)
  }

  return debounced as (...args: Parameters<F>) => ReturnType<F>
}

export default useDebounceFunction
