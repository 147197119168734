// Libraries
import styled from 'styled-components'

type ConfirmationCardProps = {
  open?: boolean
  alert?: boolean
}

export const BodyContainer = styled.div`
  font-size: ${({ theme }) => theme.fontSize.copy2};
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.text.default};
  padding: 16px 64px;
  text-align: center;
  line-height: 26px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    text-align: left;
    padding: 0px;
  }
`

export const Container = styled.div<ConfirmationCardProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 2.4rem 8rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 51.4rem;
    padding: 3.2rem;
  }
`

export const CardTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px 16px 16px 16px;
  margin: 0 0 32px;
  ::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.border.input};
    position: absolute;
    margin-top: 42px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0px;
    justify-content: flex-start;
    margin: 0 0 16px;
    ::after {
      width: 0px;
    }
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding: 48px 0 0px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 32px 0 0px;
  }
`

export const LongerDescriptionContainer = styled.div`
  margin: 20px 40px 26px 40px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 368px;
    margin: 24px 0 34px 0;
  }
`

export const TopIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 32px auto;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 0 0 24px 0;
  }
`

export const IconWrapper = styled.div`
  padding: 11px 5px 5px 11px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff1f1;
`

export const CloseIconWrapper = styled.div`
  cursor: pointer;
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: flex;
  }
`
