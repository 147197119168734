// React
import { FC, useMemo } from 'react'

// Components
import * as Styled from './styled'

type ToggleAttrs = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'>

type Props = ToggleAttrs & {
  'data-testid'?: string
  conflictMessage?: string
  handleClick?: (active: boolean) => void
  hasConflictBeforeOrAfter?: boolean
  isConflict?: boolean
  ignoreConflict?: boolean
  label?: string
  onConflict?: () => void
  radius?: string
  scale?: 'small' | 'medium' | 'large'
  toggleValue: string | string[]
  value?: string | readonly string[]
  width?: string
}

export type TToggleColors = {
  background: 'critical' | 'secondary' | 'onPrimary'
  border: 'criticalDefault' | 'default' | 'input'
  hoverBackground: 'critical' | 'hovered' | 'onPrimary'
  hoverBorder: 'critical' | 'hovered' | 'disabled'
}

const ToggleButton: FC<Props> = ({
  conflictMessage,
  handleClick,
  hasConflictBeforeOrAfter,
  label,
  onConflict,
  ignoreConflict,
  radius,
  scale = 'medium',
  toggleValue,
  value,
  width,
  isConflict,
  ...attributes
}) => {
  const isChecked = useMemo(
    () =>
      Array.isArray(value)
        ? value.includes(toggleValue)
        : value === toggleValue,
    [toggleValue, value],
  )

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isConflict && isChecked && !ignoreConflict) {
      return onConflict?.()
    }
    event.stopPropagation()
    handleClick?.(!isChecked)
  }

  const getBackgroundColor = (
    active: boolean,
    conflict?: boolean,
  ): TToggleColors => {
    if (active) {
      if (conflict)
        return {
          background: 'critical',
          border: 'criticalDefault',
          hoverBackground: 'critical',
          hoverBorder: 'critical',
        }
      return {
        background: 'secondary',
        border: 'default',
        hoverBackground: 'hovered',
        hoverBorder: 'hovered',
      }
    }
    return {
      background: 'onPrimary',
      border: 'input',
      hoverBackground: 'onPrimary',
      hoverBorder: 'disabled',
    }
  }

  return (
    <Styled.ToggleContainer>
      <Styled.Toggle
        active={isChecked}
        aria-pressed={isChecked}
        onClick={onClick}
        radius={radius}
        scale={scale}
        width={width}
        isConflict={isConflict}
        toggleColors={getBackgroundColor(isChecked, isConflict)}
        slide={!!conflictMessage}
        {...attributes}
      >
        <Styled.Label active={isChecked} scale={scale}>
          {label}
        </Styled.Label>
      </Styled.Toggle>
      {isConflict && isChecked && conflictMessage && (
        <Styled.ConflictTextContainer moreConflict={hasConflictBeforeOrAfter}>
          <Styled.IconWrapper>
            <Styled.ConflictIcon />
          </Styled.IconWrapper>
          <Styled.ConflictText>{conflictMessage}</Styled.ConflictText>
        </Styled.ConflictTextContainer>
      )}
    </Styled.ToggleContainer>
  )
}

export default ToggleButton
