// React
import { FC } from 'react'

// Components
import { InDevelopment } from 'views/general'

// Assets

const AthleteAssessmentPhysicalAssessments: FC = () => {
  return <InDevelopment pagename="Avaliações Físicas" />
}

export default AthleteAssessmentPhysicalAssessments
