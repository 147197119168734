import { useSearchParams } from 'react-router-dom'

const usePagination = (
  count = 0,
  maxLength = 0,
  boundaryCount = 0,
  siblingCount = 0,
) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page')

  const pageList = Array.from(Array(Math.ceil(count) + 1).keys()).slice(1)

  const handleDisplayPages = () => {
    let pages: number[] = []
    if (pageList?.length <= (maxLength || 7)) {
      return (pages = pageList)
    }
    if (
      pageList?.length > (maxLength || 7) &&
      parseInt(page || '1') >= 1 &&
      parseInt(page || '1') <= 3
    ) {
      return (pages = [
        ...pageList.slice(0, (maxLength && maxLength - 2) || 5),
        -1,
        ...pageList.slice(pageList?.length - 1, pageList?.length),
      ])
    }
    if (
      pageList?.length > (maxLength || 7) &&
      parseInt(page || '1') >= 4 &&
      parseInt(page || '1') <= pageList?.length - 3
    ) {
      return (pages = [
        ...pageList.slice(0, boundaryCount || 1),
        -1,
        ...pageList.slice(
          parseInt(page || '1') - ((siblingCount && siblingCount) || 2),
          parseInt(page || '1') + ((siblingCount && siblingCount) || 2) - 1,
        ),
        -1,
        ...pageList.slice(
          pageList?.length - ((boundaryCount && boundaryCount) || 1),
          pageList?.length,
        ),
      ])
    }
    if (
      pageList?.length > (maxLength || 7) &&
      parseInt(page || '1') >= pageList?.length - 2
    ) {
      return (pages = [
        ...pageList.slice(0, 1),
        -1,
        ...pageList.slice(
          pageList?.length - (maxLength ? maxLength - 2 : 5),
          pageList?.length,
        ),
      ])
    }
    return pages
  }

  const pages = handleDisplayPages()

  const previousPage = (page: number) => {
    page > 0 && setSearchParams({ page: page.toString() })
  }

  const nextPage = (page: number) => {
    page <= pageList.length && setSearchParams({ page: page.toString() })
  }

  const setPageParam = (page: number) => {
    setSearchParams({ page: page.toString() })
  }

  return { previousPage, nextPage, setPageParam, page, pages }
}

export default usePagination
