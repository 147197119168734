import styled from 'styled-components'

enum ECheckboxSizes {
  large = '2.4rem',
  medium = '1.8rem',
}

type CommonProps = {
  color?: string
  margin?: string
  weight?: number
}

type CheckboxProps = CommonProps & {
  size: 'large' | 'medium'
  focused?: boolean
}

export const Checkbox = styled.div<CheckboxProps>`
  width: ${({ size }) => ECheckboxSizes[size]};
  height: ${({ size }) => ECheckboxSizes[size]};
  border: 2px solid
    ${({ focused, theme }) =>
      focused ? theme.colors.focusedDefault : theme.colors.icon.default};
  border-radius: 3px;
  transition: all 0.1s ease-in-out;
  position: relative;

  :hover {
    border: 2px solid ${({ theme }) => theme.colors.icon.hovered};
  }

  &[aria-checked='true'] {
    background-color: ${({ focused, theme }) =>
      focused ? theme.colors.focusedDefault : theme.colors.icon.default};

    :hover {
      background-color: ${({ theme }) => theme.colors.icon.hovered};
    }
  }

  &[aria-disabled='true'] {
    pointer-events: none;
    border: 2px solid ${({ theme }) => theme.colors.icon.disabled};

    &[aria-checked='true'] {
      background-color: ${({ theme }) => theme.colors.icon.disabled};
    }
  }
`

export const Container = styled.div<CommonProps>`
  position: relative;
  margin: ${({ margin }) => margin || '0'};
  width: fit-content;
`

export const IconWrapper = styled.div<CheckboxProps>`
  width: ${({ size }) => ECheckboxSizes[size]};
  height: ${({ size }) => ECheckboxSizes[size]};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const Input = styled.input`
  appearance: none;
  opacity: 0;
  position: absolute;
`

export const Label = styled.label<CommonProps>`
  align-items: center;
  color: ${({ theme, color }) => color || theme.colors.text.default};
  cursor: pointer;
  display: flex;
  gap: 1.2rem;
  font-family: ${({ theme }) => theme.fontFamily.roboto};
  font-size: ${({ theme }) => theme.fontSize.copy4};
  line-height: 24px;
  font-weight: ${({ theme, weight }) => weight || theme.fontWeight.regular};
  margin: 0;

  &[aria-disabled='true'] {
    color: ${({ theme }) => theme.colors.text.disabled};
    cursor: not-allowed;
  }
`
