// React
import { FC, useContext, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { useMediaQuery } from 'hooks'

// Custom
import * as Styled from './styled'
import { Icon } from 'heeds-ds'

type Props = {
  children?: React.ReactNode | React.ReactNode[]
  collapsible?: boolean
  description?: React.ReactNode
  height?: string
  margin?: string
  maxWidth?: string
  padding?: string
  startsOpen?: boolean
  title?: React.ReactNode | React.ReactNode[]
}

const NewContentBox: FC<Props> = ({
  children,
  collapsible,
  description,
  height,
  margin,
  maxWidth,
  padding,
  startsOpen = false,
  title,
}) => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)
  const [collapsed, setCollapsed] = useState(!startsOpen)

  const toggleContent = (event: React.MouseEvent) => {
    if (!isDesktop) {
      setCollapsed((prev) => !prev)
      event.stopPropagation()
      !collapsible && setCollapsed((prev: boolean) => !prev)
    }
  }

  return (
    <Styled.Container
      data-testid="container"
      hasDescription={description !== undefined}
      height={height}
      margin={margin}
      padding={padding}
    >
      <Styled.TopContainer data-testid="tapContainer" margin={margin}>
        <Styled.Header collapsed={collapsed} onClick={toggleContent}>
          <Styled.Title data-testid="body">{title}</Styled.Title>

          {collapsible && (
            <Icon
              color={theme.colors.icon.default}
              iconName={collapsed ? 'expandMore' : 'expandLess'}
            />
          )}
        </Styled.Header>
        {description && <Styled.Description>{description}</Styled.Description>}
      </Styled.TopContainer>

      <Styled.Content
        data-testid="content"
        collapsed={!isDesktop && collapsible && collapsed}
        startsOpen={!isDesktop && startsOpen}
        maxWidth={maxWidth}
      >
        {children}
      </Styled.Content>
    </Styled.Container>
  )
}

export default NewContentBox
