import styled from 'styled-components'

type Props = {
  onlyButton?: boolean
  margin?: string
}

export const Container = styled.div<Props>`
  user-select: none;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: ${({ onlyButton }) => (onlyButton ? '' : 'center')};
  justify-content: center;
  width: 100%;
  max-height: 100vh;
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 15px;
  margin: ${({ margin }) => (margin ? margin : 0)};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding-bottom: 52px;
    box-shadow: ${({ onlyButton }) =>
      onlyButton ? '' : '0px 3.5px 5.5px rgba(0, 0, 0, 0.02)'};
    min-height: ${({ onlyButton }) => (onlyButton ? '' : '520px')};
  }
`

export const ModalWrapper = styled.div`
  width: 85vw;
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  height: 95vh;
  max-height: 737px;
`
