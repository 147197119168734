// Libraries
import styled from 'styled-components'

// Components
import { Body, Icon } from 'heeds-ds'

type Props = {
  error?: boolean
  isDragging?: boolean
}

export const FileInput = styled.input`
  display: none;
`

export const FileLabel = styled.label<Props>`
  border: 1px dashed
    ${({ theme, error }) =>
      error ? theme.colors.icon.critical : theme.colors.icon.default};
  padding: 16px 34px;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme, isDragging, error }) =>
    isDragging
      ? theme.colors.surface.hovered
      : error
      ? theme.colors.badge.redBackground
      : 'transparent'};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 24px 14px;
  }
  cursor: pointer;
`

export const DropZone = styled.div`
  cursor: pointer;
  position: relative;
`

export const ErrorContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  margin: 6px 0 0 0;
`
export const IconError = styled(Icon).attrs(({ theme }) => ({
  iconName: 'circleX',
  color: theme.colors.icon.critical,
}))``

export const TextError = styled(Body).attrs(() => ({
  type: 'copy5',
}))`
  color: ${({ theme }) => theme.colors.icon.critical};
`
