// Models
import { TMenuDropdownOption } from '../../models'

// React
import { ReactNode, useContext } from 'react'

// Libraries
import * as Dropdown from '@radix-ui/react-dropdown-menu'
import { ThemeContext } from 'styled-components'

// Components
import * as Styled from './styled'
import { Icon } from '../'

type Props = {
  children: ReactNode | ReactNode[]
  disabled?: boolean
  items: TMenuDropdownOption[]
}

const DropdownMenu = ({ children, disabled, items }: Props) => {
  const theme = useContext(ThemeContext)

  return (
    <Dropdown.Root modal={false}>
      <Dropdown.Trigger
        asChild
        onClick={(e) => e.stopPropagation()}
        disabled={disabled}
      >
        {children}
      </Dropdown.Trigger>

      <Dropdown.Portal>
        <Styled.Content align="end" sideOffset={4}>
          {items.map(({ label, onClick, active, color, disabled, icon }) => {
            return (
              <Styled.Item
                active={active}
                color={color}
                disabled={disabled}
                key={label}
                onClick={!disabled ? onClick : undefined}
              >
                {icon && (
                  <Icon
                    iconName={icon}
                    color={color || theme.colors.icon.default}
                  />
                )}
                {label}
              </Styled.Item>
            )
          })}
        </Styled.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  )
}

export default DropdownMenu
