import styled from 'styled-components'

type ContainerProps = {
  flex?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  gap?: string
  align?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-evenly'
  justify?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-evenly'
  margin?: string
  padding?: string
  width?: string
}

type SkeletonProps = {
  width?: string
  height?: string
  margin?: string
}

export const SimpleContainer = styled.div``

export const Aligner = styled.div<ContainerProps>`
  width: ${({ width }) => width || '100%'};
  display: flex;
  gap: ${({ gap }) => gap || '0'};
  flex-direction: ${({ flex }) => flex || 'row'};
  align-items: ${({ align }) => align || 'initial'};
  justify-content: ${({ justify }) => justify || 'initial'};
  margin: ${({ margin }) => margin || 0};
  padding: ${({ padding }) => padding || 0};
`

export const Skeleton = styled.div<SkeletonProps>`
  width: ${({ width }) => width ?? '100%'};
  height: ${({ height }) => height ?? '100%'};
  margin: ${({ margin }) => margin || 0};
  background: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`
