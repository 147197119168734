// Libs
import Creatable from 'react-select/creatable'
import Select from 'react-select'
import styled, { css } from 'styled-components'

enum EPaddings {
  small = '1rem 1.2rem',
  medium = '1.2rem 1.6rem',
  large = '1.8rem 1.6rem',
}

type Props = {
  borderColor: string
  disabled?: boolean
  scale: 'small' | 'medium' | 'large'
  width?: number
  position: 'bottom' | 'top'
  classNamePrefix?: string | null
}

const Styles = css<Props>`
  width: 100%;

  ${({ borderColor, disabled, position, classNamePrefix, scale, theme }) => css`
    .${classNamePrefix}__control {
      border: 1px solid ${borderColor};
      background-color: ${theme.colors.surface.default};
      color: ${theme.colors.text.default};
      font-family: ${theme.fontFamily.roboto};
      font-size: 1.4rem;
      font-weight: ${theme.fontWeight.regular};
      border-radius: 3px;
      height: 4rem;

      &--is-focused {
        box-shadow: 0px 0px 5px ${theme.colors.action.secondaryDefault};
        border: 1px solid ${borderColor};
      }
    }
    .${classNamePrefix}__indicator-separator {
      display: none;
    }
    .${classNamePrefix}_value-container {
      padding: ${EPaddings[scale]};

      .${classNamePrefix}_placeholder {
        color: ${disabled
          ? theme.colors.text.disabled
          : theme.colors.text.subdued};
      }
    }
    .${classNamePrefix}__menu {
      margin-top: 0.4rem;
      margin-bottom: 0.4rem;
      z-index: 50;
      background-color: ${theme.colors.surface.default};
      border-radius: 4px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      transform-origin: top;
      animation: slideDownAndFade 0.1s ease-out;

      ${position === 'top' &&
      `
        top: auto;
        bottom: 100%;
        transform-origin: bottom;
      `}

      @keyframes slideDownAndFade {
        from {
          opacity: 0;
          transform: scaleY(10%);
        }
        to {
          opacity: 1;
          transform: scaleY(100%);
        }
      }
    }
    .${classNamePrefix}__option {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 0.4rem;
      padding: 1.2rem 1.6rem;
      font-family: ${theme.fontFamily.roboto};
      font-size: 1.4rem;
      font-weight: ${theme.fontWeight.regular};
      color: ${theme.colors.text.default};

      &--is-selected,
      &--is-focused {
        font-weight: ${theme.fontWeight.bold};
        background-color: ${theme.colors.surface.highlightSubduedHovered};
      }
    }
    .${classNamePrefix}_no-options {
      font-family: ${theme.fontFamily.roboto};
      font-size: 1.4rem;
      font-weight: ${theme.fontWeight.regular};
      color: ${theme.colors.text.disabled};
    }
  `}
  * {
    ::-webkit-scrollbar {
      width: 4px;
    }
  }
`

export const AutocompleteCreate = styled(Creatable)`
  ${Styles};
`
export const Autocomplete = styled(Select)`
  ${Styles};
`
