// Models
import {
  EAthleteScheduleActionTypes,
  IAthleteScheduleState,
  IGetSchedulesPayload,
  IPostSchedulesFormattedPayload,
} from '../models'
import { IAction } from 'lib/redux/models'

// ACTION TYPES
export const Types = {
  CLEANUP: EAthleteScheduleActionTypes.CLEANUP,
  FAILURE: EAthleteScheduleActionTypes.FAILURE,
  FULFILL: EAthleteScheduleActionTypes.FULFILL,
  REQUEST: EAthleteScheduleActionTypes.REQUEST,
  SUCCESS: EAthleteScheduleActionTypes.SUCCESS,
  POST_ATHLETE_SCHEDULES: EAthleteScheduleActionTypes.POST_ATHLETE_SCHEDULES,
  GET_ATHLETE_SCHEDULES: EAthleteScheduleActionTypes.GET_ATHLETE_SCHEDULES,
}

// INITIAL STATE
const initialState: IAthleteScheduleState = {}

// REDUCER
export default (
  state: IAthleteScheduleState = initialState,
  action?: IAction<unknown>,
): IAthleteScheduleState => {
  switch (action?.type) {
    case Types.CLEANUP:
      return {
        ...state,
        error: undefined,
        loading: undefined,
        refreshing: undefined,
      }
    case Types.FAILURE:
      return {
        ...state,
        error: action.payload as string,
      }
    case Types.FULFILL:
      return {
        ...state,
        loading: false,
      }
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.SUCCESS:
      return {
        ...state,
        ...(action?.payload as IAthleteScheduleState),
      }
    default:
      return state
  }
}

// ACTIONS
export const cleanup = (): IAction<undefined> => {
  return {
    type: Types.CLEANUP,
  }
}

export const failure = (payload: string): IAction<string> => {
  return {
    type: Types.FAILURE,
    payload,
  }
}

export const fulfill = (): IAction<unknown> => {
  return {
    type: Types.FULFILL,
  }
}

export const request = (): IAction<unknown> => {
  return {
    type: Types.REQUEST,
  }
}

export const success = (
  payload: IAthleteScheduleState,
): IAction<IAthleteScheduleState> => {
  return {
    type: Types.SUCCESS,
    payload,
  }
}

// CUSTOM ACTIONS
export const triggerGetAthleteSchedules = (
  payload: IGetSchedulesPayload,
): IAction<IGetSchedulesPayload> => {
  return {
    type: Types.GET_ATHLETE_SCHEDULES,
    payload,
  }
}

export const triggerPostAthleteSchedules = (
  payload: IPostSchedulesFormattedPayload,
): IAction<IPostSchedulesFormattedPayload> => {
  return {
    type: Types.POST_ATHLETE_SCHEDULES,
    payload,
  }
}
