// Libraries
import styled, { css, keyframes } from 'styled-components'

// Components
import * as Tooltip from '@radix-ui/react-tooltip'

interface ArrowProps {
  baseColor?: string
  height?: number
  width?: number
}

interface ContentProps {
  baseColor?: string
  borderRadius?: string
  padding?: string
  shadow?: string
  width?: string
}

const slideDownAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const Arrow = styled(Tooltip.Arrow)<ArrowProps>`
  fill: ${({ baseColor, theme }) =>
    baseColor || theme.colors.background.default};
`

export const Content = styled(Tooltip.Content)<ContentProps>`
  ${({ baseColor, borderRadius, padding, shadow, theme, width }) => css`
    animation: ${slideDownAndFade} 0.4s;
    background-color: ${baseColor || theme.colors.background.default};
    border-radius: ${borderRadius || '0.8rem'};
    box-shadow: ${shadow || '0px 4px 50px rgba(0, 0, 0, 0.3)'};
    font-family: ${theme.fontFamily.roboto};
    font-size: ${theme.fontSize.copy4};
    max-width: ${width || '31rem'};
    padding: ${padding || '1.6rem'};
    width: ${width || 'fit-content'};
    will-change: transform, opacity;
  `}
`

export const Provider = Tooltip.Provider

export const Portal = Tooltip.Portal

export const Root = Tooltip.Root

export const Trigger = styled(Tooltip.Trigger)`
  font-family: ${({ theme }) => theme.fontFamily.roboto};
`
