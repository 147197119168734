// Models
import { IExerciseData } from 'storage/exercise/models'
import { IModelInput } from 'models'

// React
import { FC, useContext, useMemo } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useFormContext } from 'react-hook-form'

// Misc
import { useMediaQuery } from 'hooks'

// Components
import * as Styled from './styled'
import { Aligner } from 'heeds-ds'
import { ContentBox, WorkoutSetFormTag } from 'components'

type Props = {
  openExerciseGifModal: (exercise: IExerciseData) => void
  selectedModel: number
  workoutModels?: IModelInput[]
}

const WorkoutRoutineSets: FC<Props> = ({
  openExerciseGifModal,
  selectedModel,
  workoutModels,
}) => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const { setValue } = useFormContext()

  const renderWorkoutModels = useMemo(
    () =>
      workoutModels?.map((stateModel, modelIndex) => {
        if (modelIndex === selectedModel) {
          return Object.values(stateModel.workout_set)?.map((stateSet) => {
            const fieldName = `models.${modelIndex}.workout_set.${stateSet.id}`

            setValue(`${fieldName}`, {
              quantity: stateSet.quantity || '',
              interval: stateSet.interval || '',
              comments: stateSet.comments || '',
              exercises: stateSet.exercises?.map((setToExercise) => {
                return {
                  ...setToExercise,
                  weight: setToExercise.weight || '',
                  repetitions: setToExercise.repetitions || '',
                }
              }),
            })

            return (
              <WorkoutSetFormTag
                key={stateSet.id}
                workoutSet={stateSet}
                modelIndex={modelIndex}
                displayMode
                name={fieldName}
                onClickImage={openExerciseGifModal}
                onRemove={() => undefined}
                readonly
                showBorder={isDesktop}
                visible
              />
            )
          })
        }
      }),
    [isDesktop, openExerciseGifModal, selectedModel, setValue, workoutModels],
  )

  return (
    <ContentBox padding="0 24px 24px">
      <Aligner flex="column">
        <Styled.ScrollWorkoutSets>
          {renderWorkoutModels}
        </Styled.ScrollWorkoutSets>
      </Aligner>
    </ContentBox>
  )
}

export default WorkoutRoutineSets
