// Libraries
import styled from 'styled-components'

// Misc
import { theme } from 'lib/styled-components/theme'

// Components
import { Body, Icon, LinkButton } from 'heeds-ds'

type Props = {
  backgroundImage?: string
  open?: boolean
}

export const Container = styled.div<Props>`
  align-items: flex-start;
  background: white;
  border-radius: 0.8rem;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  box-shadow: 0px 2px 15px 0px rgba(43, 42, 99, 0.07);
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 2.4rem;
  width: 100%;
  z-index: 1;
  justify-content: center;
  position: relative;
  background-color: ${({ theme }) => theme.colors.badge.purpleText};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${({ backgroundImage }) => backgroundImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.1;
    z-index: 1;
  }

  & > * {
    position: relative;
    z-index: 2;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    max-width: 35rem;
  }
`

export const Title = styled(Body).attrs(() => ({
  type: 'copy1',
  weight: 600,
}))`
  color: ${({ theme }) => theme.colors.text.onPrimary};
  margin-bottom: 8px;
`

export const Text = styled(Body).attrs(() => ({
  type: 'copy4',
  weight: 500,
}))`
  color: ${({ theme }) => theme.colors.text.onPrimary};
`
export const ButtonContainer = styled.div`
  margin: 0 auto;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin: 0 0 0 auto;
  }
`

export const ModalContainer = styled.div`
  width: auto;
  transition: all 1s ease-in-out;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 712px;
  }
`

export const ModalContent = styled.div`
  margin: 32px 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 40rem;
  overflow-y: auto;
  padding: 0 24px;
`

export const ArrowIcon = styled(Icon)``

export const ArrowUp = styled.div`
  display: none;
`

export const ArrowDown = styled.div`
  display: none;
`

export const ExpandableCardHeader = styled.summary<Props>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  cursor: pointer;
  margin-bottom: 0px;
`

export const ExpandableCard = styled.details`
  padding: 20px 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  transition: height 1s ease;

  &[open] ${ExpandableCardHeader} {
    margin-bottom: 16px;
  }

  &[open] ${ArrowUp} {
    display: flex;
  }

  &:not([open]) ${ArrowDown} {
    display: flex;
  }
`

export const WhatsappButton = styled(LinkButton).attrs(() => ({
  size: 'xsmall',
}))`
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: auto;
  }
`

export const IconContainer = styled.div`
  padding: 6px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  margin: 0 0 0 auto;
`

export const WhatsappIcon = styled(Icon).attrs(() => ({
  iconName: 'whatsApp',
  color: theme.colors.text.onPrimary,
}))``

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.border.input};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-direction: row;
    justify-content: flex-end;
    gap: 32px;
  }
`

export const ModalTitle = styled(Body).attrs(() => ({
  weight: 600,
}))`
  padding: 12px 24px 24px 24px;
`

export const ModalCardTitle = styled(Body).attrs(() => ({
  type: 'copy2',
  weight: 500,
}))``

export const ModalCardText = styled(Body).attrs(() => ({
  type: 'copy3',
  weight: 400,
}))``
