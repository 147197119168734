// Models
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'

// React
import { FC, useMemo } from 'react'

// Misc
import { getGoalIcon } from 'utils/functions'
import { intervalDateToString } from 'utils/helpers/date'
import { prepareWorkoutRoutineToDisplay } from 'filters/workouts'

// Components
import * as Styled from './styled'
import { Aligner, StylizedIcons } from 'heeds-ds'

interface Props {
  workoutRoutine?: IWorkoutRoutine
}

const RoutineInfo: FC<Props> = ({ workoutRoutine }) => {
  const routine = useMemo(
    () =>
      workoutRoutine
        ? prepareWorkoutRoutineToDisplay(workoutRoutine)
        : undefined,
    [workoutRoutine],
  )

  return (
    <Styled.Container>
      <Styled.Card>
        <StylizedIcons size={40} viewBox={40} iconName="dumbbell" />

        <Aligner flex="column" gap="0.4rem">
          <Styled.RoutineTextBold>{routine?.name}</Styled.RoutineTextBold>

          <Styled.RoutineTextBold>
            {intervalDateToString(
              routine?.start_date ?? '',
              routine?.end_date ?? '',
            )}
          </Styled.RoutineTextBold>
        </Aligner>
      </Styled.Card>

      <Styled.Card>
        <StylizedIcons
          iconName={getGoalIcon(routine?.goal)}
          size={40}
          viewBox={40}
        />

        <Aligner flex="column" gap="0.4rem">
          <Styled.RoutineTextBold>{routine?.goal}</Styled.RoutineTextBold>

          <Styled.RoutineTextBold>{routine?.difficulty}</Styled.RoutineTextBold>
        </Aligner>
      </Styled.Card>

      <Styled.Card>
        <StylizedIcons size={40} viewBox={40} iconName="comment" />

        <Aligner flex="column" gap="0.4rem">
          <Styled.RoutineTextBold isAComment>
            Comentários
          </Styled.RoutineTextBold>

          <Styled.RoutineTextBold>{routine?.comments}</Styled.RoutineTextBold>
        </Aligner>
      </Styled.Card>
    </Styled.Container>
  )
}

export default RoutineInfo
